import { TripDetails } from "@hopper-b2b/types";

import { ITripDetailsByTripId } from "../reducer";

export const getSliceFareDetails = (
  tripDetailsById: ITripDetailsByTripId,
  fareTrips: any
): TripDetails | null => {
  if (!fareTrips.length) return null;
  let sliceDetails: TripDetails | null = null;
  sliceDetails = { ...tripDetailsById[fareTrips[0].trip] } as TripDetails;
  sliceDetails.fareDetails = [];
  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsById[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });
  return sliceDetails as TripDetails;
};
