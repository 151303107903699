import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { TripCategory, Dealness, Prediction } from "@hopper-b2b/types";
import {
  PricePredictionCard,
  PredictionForecast,
  IconName,
  IconComponent,
  Slot,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getTripCategory } from "../../../../../search/reducer/selectors";
import { predictionSelector } from "../../../../reducer/selectors";
import { PricePredictionBar } from "../PricePredictionBar";

interface MobilePricePredictionContentProps {
  onClose: () => void;
}

export const MobilePricePredictionContent = ({
  onClose,
}: MobilePricePredictionContentProps) => {
  const prediction = useSelector(predictionSelector);
  const tripCategory = useSelector(getTripCategory);
  const { t, formatFiatCurrency } = useI18nContext();

  const getForecastedPrice = (prediction: Prediction) => {
    if (prediction) {
      const key =
        prediction.dealness === Dealness.Wait ? "minPrice" : "maxPrice";
      const displayText = prediction.pricePrediction[key]?.display || "";
      if (displayText) {
        return (
          <Typography className="content" variant="h4">
            {displayText}
          </Typography>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    (
      <Slot
        id="mobile-flight-price-prediction-content"
        prediction={prediction}
      />
    ) || (
      <Box className="mobile-price-prediction-full">
        {prediction && (
          <>
            <PricePredictionBar
              prediction={prediction}
              onOpenPrediction={onClose}
            />
            <PricePredictionCard
              className="current-price"
              title={t("pricePrediction.currentPrice")}
              subtitle={
                tripCategory === TripCategory.ONE_WAY
                  ? t("oneWayPerTraveler")
                  : t("roundTripPerTraveler")
              }
              content={
                <Typography variant="h5">
                  <span className="current-price-lowestprice">
                    <b>
                      {formatFiatCurrency(prediction?.lowestPrice.fiat, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </b>
                  </span>
                </Typography>
              }
            />
          </>
        )}

        {prediction && !!getForecastedPrice(prediction) && (
          <PricePredictionCard
            className="forecasted-price"
            title={
              prediction.dealness === Dealness.Wait
                ? t("pricePrediction.forecastedLowest")
                : t("pricePrediction.forecastedHighest")
            }
            icon={
              <IconComponent
                className={
                  prediction.dealness === Dealness.Wait
                    ? "forecasted-lowest-icon"
                    : "forecasted-highest-icon"
                }
                name={
                  prediction.dealness === Dealness.Wait
                    ? IconName.DecreaseReviewed
                    : IconName.IncreasedReviewed
                }
              />
            }
            content={getForecastedPrice(prediction)}
            subtitle={
              tripCategory === TripCategory.ONE_WAY
                ? t("oneWayPerTraveler")
                : t("roundTripPerTraveler")
            }
            isForecastedPrice
          />
        )}
        {prediction && prediction.predictionCopy && (
          <Box className="prediction-forecast-section">
            <Typography variant="h4" className="prediction-forecast-header">
              {t("pricePrediction.header")}
            </Typography>
            <PredictionForecast
              className="b2b"
              intervals={prediction.predictionCopy.intervals}
            />
          </Box>
        )}
      </Box>
    )
  );
};
