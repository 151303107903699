import { Box, Typography } from "@material-ui/core";

import { useI18nContext } from "@hopper-b2b/i18n";
import { Icon, IconName } from "@hopper-b2b/ui";
import { CurrencyFormatters } from "@hopper-b2b/utilities";
import clsx from "clsx";
import "./styles.scss";

export interface IPricingRowProps {
  amount?: number;
  className?: string;
  currency?: string;
  isTravelCredit?: boolean;
  isTravelOffer?: boolean;
  label: string;
  rowIcon?: IconName;
}

const PricingRow = (props: IPricingRowProps): JSX.Element => {
  const {
    amount,
    className = "fees",
    currency,
    isTravelCredit,
    isTravelOffer,
    label,
    rowIcon,
  } = props;

  const { t } = useI18nContext();

  return (
    <Box className={clsx("price-line", className)}>
      <Typography className="label" variant="subtitle2">
        {rowIcon && (
          <Icon
            className={clsx("price-line-icon", {
              "wallet-item": isTravelOffer || isTravelCredit,
            })}
            name={rowIcon}
          />
        )}
        {label}
      </Typography>
      <Typography
        className={clsx("value", {
          "wallet-item": isTravelCredit || isTravelOffer,
        })}
        variant="subtitle2"
      >
        {amount != null ? (
          <>{CurrencyFormatters.get(currency).format(amount)}</>
        ) : (
          <Typography className="price-unavail" variant="body2">
            {t("priceUnavailable")}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default PricingRow;
