import { FilteredFare } from "@hopper-b2b/types";
import { DesktopInfiniteFlightsProps } from "../DesktopInfiniteFlights";
import { FlightComponent } from "../FlightComponent";

export type DesktopInfiniteFlightListComponentProps =
  DesktopInfiniteFlightsProps & {
    filteredFare: FilteredFare;
    index: number;
  };

export const DesktopInfiniteFlightsListComponent = (
  props: DesktopInfiniteFlightListComponentProps
) => {
  const { filteredFare, flights, flightsToRender, index } = props;
  const { fare, flight } = filteredFare;

  const flightSliceId = flight.slice;
  const flightSlice = flights?.slices[flightSliceId];
  if (!flightSlice) {
    return null;
  }
  const fareId = fare.example?.fare || fare.id;

  return (
    <FlightComponent
      {...props}
      key={`flight-${fareId}`}
      slice={flightSlice}
      index={index}
      flight={flight}
      selectedFare={fare}
      flightsToShow={flightsToRender}
    />
  );
};
