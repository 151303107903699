import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import "./styles.scss";

export interface ILoadingScreenProps {
  loading: boolean;
  averageLoadingTime?: number;
  className?: string;
  loadingSteps?: Array<{
    title: string;
  }>;
  onResetRenderLoadingSteps?: (renderLoadingSteps: boolean) => void;
}

const LoadingScreen = ({
  loading,
  averageLoadingTime = 2, // default to 2 seconds
  className,
  loadingSteps,
  onResetRenderLoadingSteps,
}: ILoadingScreenProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  // at the 70% mark, slow down the loading speed
  const slowDownAt = 70;

  const averageLoadingTimeInMs = averageLoadingTime * 1000;

  const setTimeoutIncrement = useMemo(() => {
    if (progress < slowDownAt) {
      return Math.floor(averageLoadingTimeInMs / 1000);
    }
    return Math.floor(averageLoadingTimeInMs / 500);
  }, [averageLoadingTimeInMs, progress]);

  useEffect(() => {
    if (loadingSteps.length > stepIndex + 1) {
      if (
        Math.floor(progress) ===
        Math.floor((100 / loadingSteps.length) * (stepIndex + 1))
      ) {
        setStepIndex(stepIndex + 1);
      }
    }
  }, [loading, loadingSteps, progress, setProgress, stepIndex]);

  useEffect(() => {
    if (loading && progress < 90) {
      setTimeout(() => {
        setProgress(progress + 0.1);
      }, setTimeoutIncrement);
    } else if (Math.floor(progress) === 100) {
      if (onResetRenderLoadingSteps) {
        onResetRenderLoadingSteps(false);
      }
    } else if (!loading) {
      setTimeout(() => {
        setProgress(progress + 0.1);
      }, 1);
    } else {
      // we're past 90% but we're not done, slow it down
      setTimeout(() => {
        setProgress(progress + 0.05);
      }, setTimeoutIncrement);
    }
  }, [loading, onResetRenderLoadingSteps, progress, setTimeoutIncrement]);

  // Reset progress when component is removed from DOM
  useEffect(() => {
    return () => {
      setProgress(0);
      setStepIndex(0);
    };
  }, []);

  return (
    <div
      className={clsx(
        className,
        loadingSteps?.length > 1 ? "multiple-steps" : "",
        "loading-screen-with-progress",
        "nubank",
        { desktop: !matchesMobile, mobile: matchesMobile }
      )}
    >
      <div className={clsx("loading-screen-with-progress-content-wrapper")}>
        <div
          className={clsx("loading-screen-with-progress-content")}
          style={{
            bottom: `${-4 + 5 * stepIndex}rem`,
            transition: "bottom 0.5s linear",
          }}
        >
          {loadingSteps.map((s, i) => (
            <p
              key={`progress-title-${i}`}
              className={clsx("loading-screen-with-progress-title", {
                currentStep: stepIndex === i,
                previousStep: stepIndex === i + 1,
                hiddenStep: stepIndex > i + 1 || stepIndex < i,
              })}
            >
              {s.title}
            </p>
          ))}
        </div>
        <div className="loading-screen-with-progress-bar-container">
          <div
            className="loading-screen-with-progress-bar"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
