import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink, CloseButtonIcon } from "@hopper-b2b/ui";

type ClosePDPButtonProps = {
  onClick: () => void;
};

export const ClosePDPButton = ({ onClick }: ClosePDPButtonProps) => {
  const { t } = useI18nContext();
  return (
    <ActionLink
      className="price-drop-protection-close-button"
      onClick={onClick}
      content={
        <img
          src={CloseButtonIcon}
          alt={t("close.imageAlt")}
          className="close-button-icon"
        />
      }
      label={t("close.button")}
      showTappableArea={true}
    />
  );
};
