import {
  Lodging,
  Suggestion,
  WalletOfferApplicabilityResponse,
} from "@b2bportal/lodging-api";
import { type SortOption, type ViewOption } from "@hopper-b2b/lodging-utils";
import type {
  ICentroid,
  ILodgingAvailabilityState,
  LoadingLodgings,
  PriceRange,
} from "../reducer";
import * as actionTypes from "./constants";

export interface ISetLoading {
  type: actionTypes.SET_LOADING;
  value: LoadingLodgings;
}

export const setLoading = (value: LoadingLodgings) => ({
  type: actionTypes.SET_LOADING,
  value,
});

export interface ISetAvailabilityLodgings {
  type: actionTypes.SET_AVAILABILITY_LODGINGS;
  lodgings: Lodging[];
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate"
  >;
  place: Suggestion;
  offers?: WalletOfferApplicabilityResponse;
}
export const setAvailabilityLodgings = (
  lodgings: Lodging[],
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate"
  >,
  place?: Suggestion,
  offers?: WalletOfferApplicabilityResponse
) => ({
  type: actionTypes.SET_AVAILABILITY_LODGINGS,
  lodgings: lodgings,
  stayValues: stayValues,
  place: place,
  offers: offers,
});

export interface ISetMapInfo {
  type: actionTypes.SET_MAP_INFO;
  location?: number[];
  bounds?: number[];
}
export const setMapInfo = (location?: number[], bounds?: number[]) => ({
  type: actionTypes.SET_MAP_INFO,
  location: location,
  bounds: bounds,
});

export interface ISetIsFirstRender {
  type: actionTypes.SET_IS_FIRST_RENDER;
}
export const setIsFirstRender = () => ({
  type: actionTypes.SET_IS_FIRST_RENDER,
});

export interface ISetStayValues {
  type: actionTypes.SET_STAY_VALUES;
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate"
  >;
}

export const setStayValues = (
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate"
  >
) => ({
  type: actionTypes.SET_STAY_VALUES,
  stayValues: stayValues,
});

export interface IAddAvailabilityLodgings {
  type: actionTypes.ADD_AVAILABILITY_LODGINGS;
  lodgings: Lodging[];
  offers?: WalletOfferApplicabilityResponse;
  prepend?: boolean;
}

export const addAvailabilityLodgings = (
  lodgings: Lodging[],
  offers?: WalletOfferApplicabilityResponse,
  prepend?: boolean
) => ({
  type: actionTypes.ADD_AVAILABILITY_LODGINGS,
  lodgings,
  offers,
  prepend,
});

export interface IFetchPlace {
  type: actionTypes.FETCH_PLACE;
  query: string;
}

export const fetchPlace = (query: string) => ({
  type: actionTypes.FETCH_PLACE,
  query: query,
});

export interface ISetFilterFreeCancellation {
  type: actionTypes.SET_FILTER_FREE_CANCELLATION;
  value: boolean;
}

export const setFilterFreeCancellation = (value: boolean) => ({
  type: actionTypes.SET_FILTER_FREE_CANCELLATION,
  value: value,
});

export interface ISetFilterStarRating {
  type: actionTypes.SET_FILTER_STAR_RATING;
  value: number[];
}

export const setFilterStarRating = (value: number[]) => ({
  type: actionTypes.SET_FILTER_STAR_RATING,
  value: value,
});

export interface ISetFilterUserRating {
  type: actionTypes.SET_FILTER_USER_RATING;
  value: number;
}

export const setFilterUserRating = (value: number) => ({
  type: actionTypes.SET_FILTER_USER_RATING,
  value: value,
});

export interface ISetFilterAmenities {
  type: actionTypes.SET_FILTER_AMENITIES;
  value: string[];
}

export const setFilterAmenities = (value: string[]) => ({
  type: actionTypes.SET_FILTER_AMENITIES,
  value: value,
});

export interface ISetFilterAmenitiesType {
  type: actionTypes.SET_FILTER_AMENITIES_TYPE;
  value: boolean;
}

export const setFilterAmenitiesType = (value: boolean) => ({
  type: actionTypes.SET_FILTER_AMENITIES_TYPE,
  value: value,
});

export interface ISetFilterPrice {
  type: actionTypes.SET_FILTER_PRICE;
  value: PriceRange;
}

export const setFilterPriceRange = (
  value: Pick<PriceRange, "min" | "max">
) => ({
  type: actionTypes.SET_FILTER_PRICE,
  value: value,
});

export interface IResetFilters {
  type: actionTypes.RESET_FILTERS;
  value: PriceRange;
}

export const resetFilters = () => ({
  type: actionTypes.RESET_FILTERS,
});

export interface ISetSort {
  type: actionTypes.SET_SORT;
  value: SortOption;
}

export const setSort = (value: SortOption) => ({
  type: actionTypes.SET_SORT,
  value,
});

export interface ISetView {
  type: actionTypes.SET_VIEW;
  value: ViewOption;
}

export const setView = (value: ViewOption) => ({
  type: actionTypes.SET_VIEW,
  value,
});

export interface ISetPlace {
  type: actionTypes.SET_PLACE;
  value: Suggestion;
}

export const setPlace = (value: Suggestion) => ({
  type: actionTypes.SET_PLACE,
  value,
});

export interface ISetGuests {
  type: actionTypes.SET_GUESTS;
  value: { adults: number; children: number[] };
}

export const setGuests = (value: { adults: number; children: number[] }) => ({
  type: actionTypes.SET_GUESTS,
  value,
});

export interface ISetRooms {
  type: actionTypes.SET_ROOMS;
  value: number;
}

export const setRooms = (value: number) => ({
  type: actionTypes.SET_ROOMS,
  value,
});

export interface ISetCentroid {
  type: actionTypes.SET_CENTROID;
  value: ICentroid;
}

export const setCentroid = (value: ICentroid) => ({
  type: actionTypes.SET_CENTROID,
  value,
});

export type LodgingAvailabilityActions =
  | ISetLoading
  | ISetAvailabilityLodgings
  | IAddAvailabilityLodgings
  | IFetchPlace
  | ISetFilterFreeCancellation
  | ISetFilterStarRating
  | ISetFilterUserRating
  | ISetFilterPrice
  | ISetFilterAmenities
  | ISetFilterAmenitiesType
  | IResetFilters
  | ISetSort
  | ISetView
  | ISetPlace
  | ISetCentroid
  | ISetStayValues
  | ISetGuests
  | ISetRooms
  | ISetMapInfo
  | ISetIsFirstRender;
