import { AirChfarOffer } from "@b2bportal/air-chfar-api";
import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  ChfarTrackingEvents,
  ChfarTrackingPageSelected,
  FintechCoverageType,
  FintechProductType,
} from "@hopper-b2b/types";
import {
  B2BSpinner,
  DetailHalfSheet,
  FintechPurchase,
  LoadingIndicator,
} from "@hopper-b2b/ui";
import {
  combineTrackingProperties,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientContext } from "../../../App";
import { useUpdateQueryParams } from "../../shop/hooks";
import { setSelectedChfarOffer } from "../actions/actions";
import {
  getChfarOffers,
  getChfarTrackingProperties,
  getSelectedChfarOffer,
  getSelectedChfarTrackingProperties,
  isChfarOffersLoading,
  isChfarOffersValid,
} from "../reducer";
import "./styles.scss";

const OPEN_CHFAR_DETAILS = "open_chfar_details";

interface ChfarOfferProps {
  goToNextStep?: (replace?: boolean) => void;
  hideContinueButton?: boolean;
}

export const ChfarOffer = ({
  goToNextStep,
  hideContinueButton,
}: ChfarOfferProps) => {
  const isLoading = useSelector(isChfarOffersLoading);
  const isValid = useSelector(isChfarOffersValid(true));

  if (!isValid) {
    goToNextStep?.(true);
  }

  return isLoading ? (
    <ChfarLoading />
  ) : isValid ? (
    <ChfarContent
      hideContinueButton={hideContinueButton}
      goToNextStep={goToNextStep}
    />
  ) : null;
};

export const ChfarContent = ({
  goToNextStep,
  hideContinueButton,
}: ChfarOfferProps) => {
  const { t, formatCurrency, brand } = useI18nContext();
  const clientContext = useContext(ClientContext);

  const { setHeader } = useUberBridge();

  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  const [openLearnMore, setOpenLearnMore] = useState<boolean>(false);

  const chfarOffers = useSelector(getChfarOffers);
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  const chfarTrackingProperties = useSelector(getChfarTrackingProperties);
  const selectedChfarTrackingProperties = useSelector(
    getSelectedChfarTrackingProperties
  );

  const noCoverageSelected =
    selectedChfarOffer && Object.keys(selectedChfarOffer).length === 0;

  const handleSelect = useCallback(
    (offer?: AirChfarOffer) => {
      dispatch(setSelectedChfarOffer(offer));
    },
    [dispatch]
  );

  const onOpenLearnMore = useCallback(() => {
    setOpenLearnMore(true);
    trackEvent({
      eventName: ChfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: ChfarTrackingPageSelected.OFFER },
    });
  }, []);

  // Clear selection on load
  useEffect(() => {
    handleSelect(selectedChfarOffer);
    updateQueryParams(
      {
        [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedChfarOffer?.quoteId,
      },
      true
    );
  }, [handleSelect, updateQueryParams, selectedChfarOffer]);

  useEffect(() => {
    if (brand?.hasDefaultFintechSelection && chfarOffers.length > 0) {
      handleSelect(chfarOffers[0]);
    }
  }, [chfarOffers, handleSelect, brand]);

  useEffect(() => {
    trackEvent({
      eventName: ChfarTrackingEvents.VIEWED_CHFAR,
      ...combineTrackingProperties([chfarTrackingProperties]),
    });
  }, [chfarTrackingProperties]);

  useEffect(() => {
    setHeader({
      customButtons: [
        {
          icon: "CIRCLEI",
          action: {
            name: OPEN_CHFAR_DETAILS,
            callback: onOpenLearnMore,
          },
        },
      ],
      title: "",
    });
  }, [onOpenLearnMore, setHeader, t]);

  const handleGoToNext = useCallback(() => {
    updateQueryParams(
      {
        [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedChfarOffer?.quoteId,
      },
      true
    );

    goToNextStep?.();
  }, [goToNextStep, selectedChfarOffer, updateQueryParams]);

  const selectNoCoverage = useCallback(() => {
    handleSelect({} as AirChfarOffer);
  }, [handleSelect]);

  const declineCoverage = useCallback(() => {
    selectNoCoverage();
    trackEvent({
      eventName: ChfarTrackingEvents.DECLINED_CHFAR,
      ...combineTrackingProperties([chfarTrackingProperties]),
    });
    handleGoToNext();
  }, [selectNoCoverage, chfarTrackingProperties, handleGoToNext]);

  const addCoverage = useCallback(() => {
    trackEvent({
      eventName: ChfarTrackingEvents.ADDED_CHFAR,
      ...combineTrackingProperties([selectedChfarTrackingProperties]),
    });
    handleGoToNext();
  }, [handleGoToNext, selectedChfarTrackingProperties]);

  const options = useMemo(() => {
    return [
      {
        value: FintechCoverageType.Full,
        title: t("chOffer.fullCoverage"),
        cost: formatCurrency(
          chfarOffers?.[0]?.premiumAmount?.fiat.value
            ? chfarOffers?.[0]?.premiumAmount?.fiat.value
            : 0,
          {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }
        ),
        description: [
          t("chOffer.fullCoverageText1"),
          t("chOffer.fullCoverageText2"),
        ],
        selected: selectedChfarOffer?.coveragePercentage === "100",
        onClick: () => handleSelect(chfarOffers?.[0]),
        ctaLabel: t("acceptCta"),
      },
      {
        value: FintechCoverageType.None,
        title: t("chOffer.noCoverage"),
        cost: formatCurrency(0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        description: [t("chOffer.noCoverageText")],
        selected: noCoverageSelected,
        onClick: selectNoCoverage,
        ctaLabel: t("declineCta"),
      },
    ];
  }, [
    chfarOffers,
    formatCurrency,
    handleSelect,
    noCoverageSelected,
    selectNoCoverage,
    selectedChfarOffer?.coveragePercentage,
    t,
  ]);

  return (
    <div className="">
      <FintechPurchase
        className="cfar"
        options={options}
        headerTitle={t("chOffer.title")}
        headerSubtitle={t("chOffer.subtext")}
        headerImgSrc={clientContext.assets && clientContext.assets["clock"]}
        clientAssets={clientContext.assets}
        ctaLabel={t(
          selectedChfarOffer && !noCoverageSelected
            ? "chOffer.coverageButtonText"
            : "chOffer.declineCoverageButtonText"
        )}
        disabled={selectedChfarOffer === undefined}
        handleContinue={
          hideContinueButton
            ? undefined
            : noCoverageSelected
            ? declineCoverage
            : addCoverage
        }
      />
      <DetailHalfSheet
        open={openLearnMore}
        onClose={() => setOpenLearnMore(false)}
        description={<I18nMarkup tKey={"chOffer.learnMoreDescription"} />}
        productType={FintechProductType.CfarPurchase}
        descriptionAdditional={t("chOffer.learnMoreDescription2")}
        header={t("chOffer.learnMoreHeader")}
        title={t("chOffer.learnMoreTitle")}
      />
    </div>
  );
};

const ChfarLoading = () => {
  const { t } = useI18nContext();
  return (
    <LoadingIndicator
      className="chfar-loader"
      indicatorSize={"small"}
      indicator={B2BSpinner}
      message={t("loading")}
    />
  );
};
