import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FareDetails,
  SELECTED_FLIGHT,
  VIEWED_FLIGHT_LIST,
  VIEWED_SLICE,
  mapAlgomerchTexts,
} from "@hopper-b2b/types";
import { NoResults } from "@hopper-b2b/ui";
import { useApiConfigSelector, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { ClientContext } from "../../../../App";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { useUpdateFlightShopStep } from "../../hooks";
import { FlightShopStep } from "../../reducer";
import { FlightAlgomerchModal } from "../FlightAlgomerchModal";
import { FlightFindMoreResults } from "./components/FlightFindMoreResults";
import FlightList from "./components/FlightList/FlightList";
import { MobileFilterFareDetails } from "./components/MobileFilterFareDetails";
import { MobileFlightDetailsModal } from "./components/MobileFlightDetailsModal";
import { PriceFreezeRefund } from "./components/PriceFreezeRefund";
import SkeletonFlight from "./components/SkeletonFlight";
import * as constants from "./constants";
import { FlightListConnectorProps } from "./container";
import "./styles.scss";

export interface IFlightListProps
  extends FlightListConnectorProps,
    RouteComponentProps {
  onCompleteFareSelect?: (selectedFare: {
    tripId: string;
    fareId: string;
  }) => void;
  isInPriceFreezePurchase?: boolean;
  isInSimilarFlights?: boolean;
}

export enum ModalTypes {
  AlgomerchModal,
  MobileFlightDetails,
}

type IOpenModal = ModalTypes | false;

export const MOBILE_OFFSET_SCROLL = 300;
export const DESKTOP_OFFSET_SCROLL = 250;

export const FlightListContainer = ({
  isReturn,
  isOneWay,
  sortedFlightIds,
  tripSummariesLoading,
  hasTripSummariesError,
  tripDetailsLoading,
  fetchTripDetails,
  tripIdsByReturnSlice,
  setChosenOutgoingSlice,
  setChosenReturnSlice,
  flightShopProgress,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setFlightNumberFilter,
  setAirportFilter,
  selectedTrip,
  viewedForecastProperties,
  selectedOutgoingSliceProperties,
  selectedReturnSliceProperties,
  resetAll,
  maxFlightPrice,
  setOpenCalendarModal,
  flightInfoById,
  onCompleteFareSelect,
  isInPriceFreezePurchase,
  isInSimilarFlights,
  fetchTripSummariesV3,
}: IFlightListProps) => {
  const { featureFlag } = useContext(ClientContext);
  const history = useHistory();
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();

  const updateFlightShopStep = useUpdateFlightShopStep();

  const disableDateChange = isInPriceFreezePurchase || isInSimilarFlights;
  const [flightsToShow, setFlightsToShow] = useState<string[]>([]);
  const [selectedFareId, setSelectedFareId] = useState("");
  const [expandedFlight, setExpandedFlight] = useState("");
  const [selectedTripId, setSelectedTripId] = useState("");
  const [selectedSliceId, setSelectedSliceId] = useState("");
  const [selectedFare, setSelectedFare] = useState<FareDetails | undefined>(
    undefined
  );

  const [openModal, setOpenModal] = useState<IOpenModal>(false);
  const [selectedAlgomerchTag, setSelectedAlgomerchTag] =
    useState<AlgomerchTag>(AlgomerchTag.Cheapest);

  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);
  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedAlgomerchTag(
      (selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest
    );
  };

  const onFlightRowFareClick = (fareId: string) => {
    setSelectedFareId(fareId);
  };

  const selectFare = useCallback(
    (tripId: string, sliceId: string, fare?: FareDetails) => {
      setExpandedFlight("");
      if (isReturn) {
        setChosenReturnSlice({
          returnFareId: fare?.id || "",
          returnSliceId: sliceId,
          returnFareRating: fare?.slices[1].fareShelf?.rating,
          tripId: tripIdsByReturnSlice ? tripIdsByReturnSlice[sliceId] : "",
        });
        trackEvent(
          {
            eventName: SELECTED_FLIGHT,
            properties: {
              ...selectedReturnSliceProperties,
            },
          },
          apiConfig
        );
      } else {
        if (!isOneWay) {
          updateFlightShopStep(FlightShopStep.ChooseReturn);
        }
        setAirportFilter([]);
        setFlightNumberFilter([]);
        setChosenOutgoingSlice({
          outgoingFareId: fare?.id || "",
          outgoingSliceId: sliceId,
          outgoingFareRating: featureFlag?.enableUpsellFareMix
            ? undefined
            : fare?.slices[0].fareShelf?.rating,
          tripId: tripId,
          // whenever selecting a different departure flight, reset return ids
          resetReturnIds:
            selectedTrip?.outgoingFareId !== fare?.id ||
            selectedTrip?.outgoingSliceId !== sliceId,
        });
        resetAll(maxFlightPrice);
        trackEvent(
          {
            eventName: SELECTED_FLIGHT,
            properties: {
              ...selectedOutgoingSliceProperties,
            },
          },
          apiConfig
        );
      }
    },
    [
      apiConfig,
      featureFlag?.enableUpsellFareMix,
      isOneWay,
      isReturn,
      maxFlightPrice,
      resetAll,
      selectedOutgoingSliceProperties,
      selectedReturnSliceProperties,
      selectedTrip?.outgoingFareId,
      selectedTrip?.outgoingSliceId,
      setAirportFilter,
      setChosenOutgoingSlice,
      setChosenReturnSlice,
      setFlightNumberFilter,
      updateFlightShopStep,
      tripIdsByReturnSlice,
    ]
  );

  const handleOnCompleteFareSelect = useCallback(
    (tripId: string, fareId: string) => {
      if (onCompleteFareSelect && (isReturn || isOneWay)) {
        onCompleteFareSelect({ tripId, fareId });
      }
    },
    [isOneWay, isReturn, onCompleteFareSelect]
  );

  const onModalContinue = () => {
    selectFare(selectedTripId, selectedSliceId, selectedFare);
    handleOnCompleteFareSelect(selectedTripId, selectedFare?.id ?? "");
    setOpenModal(false);
  };

  const onExpandItem = useCallback(
    (tripId: string) => {
      if (tripId === expandedFlight) {
        setExpandedFlight("");
      } else {
        fetchTripDetails(tripId);
        setExpandedFlight(tripId);

        const flightInfo = flightInfoById(tripId);
        trackEvent(
          {
            eventName: VIEWED_SLICE,
            properties: {
              ...viewedForecastProperties,
              fare_class: flightInfo
                ? isReturn
                  ? flightInfo?.tripFares[0]?.fareShelf?.returning
                      ?.shortBrandName
                  : flightInfo?.tripFares[0]?.fareShelf?.outgoing
                      ?.shortBrandName
                : "",
            },
          },
          apiConfig
        );
      }
    },
    [
      apiConfig,
      expandedFlight,
      fetchTripDetails,
      flightInfoById,
      isReturn,
      viewedForecastProperties,
    ]
  );

  const setFetchMoreData = useCallback(() => {
    const newPageSize = flightsToShow.length + constants.SHOW_MORE_NUM;
    return setTimeout(
      () => setFlightsToShow(sortedFlightIds.slice(0, newPageSize)),
      500
    );
  }, [flightsToShow.length, sortedFlightIds]);
  const mobileSelectFare = (
    tripId: string,
    sliceId: string,
    fare?: FareDetails
  ) => {
    setSelectedSliceId(sliceId);
    setSelectedTripId(tripId);
    setSelectedFare(fare);
    setOpenModal(ModalTypes.MobileFlightDetails);
  };

  const onFareSelectClick = useCallback(
    (tripId: string, sliceId: string, fare?: FareDetails) => {
      if (matchesMobile) {
        mobileSelectFare(tripId, sliceId, fare);
      } else {
        selectFare(tripId, sliceId, fare);
        handleOnCompleteFareSelect(tripId, fare?.id ?? "");
      }
    },
    [handleOnCompleteFareSelect, matchesMobile, selectFare]
  );

  useEffect(() => {
    if (!tripSummariesLoading && sortedFlightIds.length > 0) {
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_LIST,
          properties: { ...viewedForecastProperties },
        },
        apiConfig
      );
    }
  }, [
    apiConfig,
    sortedFlightIds.length,
    tripSummariesLoading,
    viewedForecastProperties,
  ]);

  useEffect(() => {
    if (flightShopProgress === FlightShopStep.ChooseReturn) {
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_LIST,
          properties: { ...viewedForecastProperties },
        },
        apiConfig
      );
    }
  }, [apiConfig, flightShopProgress, viewedForecastProperties]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (sortedFlightIds.length > 0) {
      setFlightsToShow(
        sortedFlightIds.slice(0, constants.INITIAL_RESULT_SET_SIZE)
      );
    } else {
      setFlightsToShow([]);
    }
    return clearTimeout(setFetchMoreData());
  }, [setFetchMoreData, sortedFlightIds]);

  const renderFlightShopFooter = () => {
    if (isInSimilarFlights) {
      return <PriceFreezeRefund />;
    } else {
      return <FlightFindMoreResults />;
    }
  };

  const noFlightsString = useMemo(() => {
    return hasAppliedFareClassFilter
      ? t("fareClassFlightsNotFound")
      : hasAppliedNonFareclassFilter
      ? t("filteredFlightsNotFound")
      : t("noFlightsAvailable");
  }, [hasAppliedFareClassFilter, hasAppliedNonFareclassFilter, t]);

  return (
    <>
      <Box
        className={clsx(
          "flight-list",
          { "flight-list-skeleton": tripSummariesLoading },
          { mobile: matchesMobile }
        )}
      >
        <MobileFilterFareDetails />
        {tripSummariesLoading ? (
          <SkeletonFlight
            expandedFlight={expandedFlight}
            onExpandItem={onExpandItem}
          />
        ) : flightsToShow.length ? (
          <FlightList
            expandedFlight={expandedFlight}
            flightInfoById={flightInfoById}
            flightsToShow={flightsToShow}
            isReturn={isReturn}
            onExpandItem={onExpandItem}
            onFareSelectClick={onFareSelectClick}
            setFetchMoreData={setFetchMoreData}
            sortedFlightIds={sortedFlightIds}
            onFlightRowFareClick={onFlightRowFareClick}
            tripDetailsLoading={tripDetailsLoading}
            selectedFareId={selectedFareId}
            handleClickAlgomerchTag={handleClickAlgomerchTag}
            setOpenModal={setOpenModal}
          />
        ) : hasTripSummariesError ? (
          <Box className="no-results-container">
            <NoResults
              className="flight-list-no-results"
              title={t("genericShopErrorTitle")}
              subtitle={t("genericShopErrorSubtitle")}
            />
            <button
              onClick={() => fetchTripSummariesV3(history, matchesMobile)}
              className={"reload-button"}
            >
              <I18nMarkup tKey="genericShopErrorButton" />
            </button>
          </Box>
        ) : (
          <Box className="no-results-container">
            <NoResults
              className="flight-list-no-results"
              title={t("noFlightsFound")}
              subtitle={noFlightsString}
            />
            {!disableDateChange && (
              <Button
                onClick={() => setOpenCalendarModal(true)}
                className={clsx("change-dates-button", "b2b")}
              >
                <I18nMarkup tKey="changeDates" />
              </Button>
            )}
          </Box>
        )}
      </Box>
      {!tripSummariesLoading &&
        sortedFlightIds.length === flightsToShow.length &&
        sortedFlightIds.length !== 0 &&
        (hasAppliedFareClassFilter ||
          hasAppliedNonFareclassFilter ||
          isInSimilarFlights) &&
        renderFlightShopFooter()}
      <FlightAlgomerchModal
        selectedCategory={selectedAlgomerchTag}
        setSelectedCategory={setSelectedAlgomerchTag}
        openModal={openModal === ModalTypes.AlgomerchModal}
        onClose={() => setOpenModal(false)}
      />
      <MobileFlightDetailsModal
        openModal={openModal === ModalTypes.MobileFlightDetails}
        onClose={() => setOpenModal(false)}
        departure={!isReturn}
        tripId={selectedTripId}
        fareDetails={selectedFare}
        onClick={onModalContinue}
        buttonText={t("continue")}
      />
    </>
  );
};
