import { Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SadBunny } from "@hopper-b2b/ui";
import styles from "./EmptyState.module.scss";

interface Props {
  resetFilters?: () => void;
}

export function EmptyState({ resetFilters }: Props) {
  const { t } = useI18nContext();

  return (
    <div className={clsx(styles.EmptyState, "EmptyState")}>
      <img src={SadBunny} alt="" />
      <Typography variant="h4">{t("emptyStateTitle")}</Typography>
      <Typography>
        {resetFilters
          ? t("emptyStateDescription")
          : t("emptyStateDescriptionSearch")}
      </Typography>
      {resetFilters ? (
        <Button color="primary" variant="contained" onClick={resetFilters}>
          {t("clearAllFilters")}
        </Button>
      ) : null}
    </div>
  );
}
