import { Airport } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FareDetails, TripDetails } from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "../ActionLink";
import { FlightDetailsSummary } from "../FlightDetailsSummary";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import { MixedCabinToolTip } from "../MixedCabinToolTip";
import { MobileFloatingButton } from "../MobileFloatingButton";
import { MobilePopoverCard } from "../MobilePopoverCard";

import styles from "./MobileFlightDetailsHalfSheet.module.scss";

export interface IMobileFlightDetailsHalfSheetProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails | undefined;
  openModal: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  onClick?: () => void;
  secondaryButtonOnClick?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  className?: string;
  plusDays?: number;
  isMixedCabinClass?: boolean;
  airports?: { [key: string]: Airport };
  assets?: Record<string, any>;
}

export const FlightDetailsModalButton = (props: {
  buttonText: string;
  onClick?: () => void;
}) => {
  const { buttonText, onClick } = props;
  if (!onClick) return null;
  return (
    <MobileFloatingButton
      className="mobile-flight-details-modal-button"
      onClick={onClick}
      wrapperClassName="b2b"
      wide
    >
      {buttonText}
    </MobileFloatingButton>
  );
};

export const MobileFlightDetailsHalfSheet = ({
  title = "Departure details",
  airports,
  assets,
  buttonText,
  className,
  departure,
  fareDetails,
  isMixedCabinClass,
  onClick,
  onClose,
  openModal,
  plusDays,
  secondaryButtonOnClick,
  secondaryButtonText,
  tripDetails,
}: IMobileFlightDetailsHalfSheetProps) => {
  const { t } = useI18nContext();

  if (fareDetails === undefined) return null;
  const fareIndex = fareDetails.slices.findIndex((slice) =>
    departure ? slice.outgoing : !slice.outgoing
  );
  const fare = fareDetails.slices[fareIndex];

  return tripDetails && fareDetails ? (
    <MobilePopoverCard
      className={clsx(
        styles["mobile-flight-details-half-sheet-root"],
        className
      )}
      contentClassName={clsx(
        "mobile-flight-details-half-sheet-content",
        buttonText && onClick ? "has-continue" : null
      )}
      open={openModal}
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={
            <IconComponent
              ariaLabel={t("modalClose.ariaLabel")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label={t("modalClose.label")}
          onClick={onClose}
        />
      }
      onClose={onClose}
      fullScreen={false}
    >
      <Box
        className={clsx(
          "mobile-flight-details-half-sheet-container",
          className
        )}
      >
        <Typography className="mobile-flight-details-modal-title" variant="h5">
          {title}
        </Typography>
        <Box className="flight-details-summary-container">
          <FlightDetailsSummary
            className="review-itinerary-flight-details"
            segments={
              departure
                ? tripDetails.slices[0].segmentDetails
                : tripDetails.slices[tripDetails.slices.length - 1]
                    .segmentDetails
            }
            departureTime={
              departure
                ? tripDetails.slices[0].departureTime
                : tripDetails.slices[tripDetails.slices.length - 1]
                    .departureTime
            }
            planeInfo={
              fare?.amenitiesUtas?.amenities?.aircraft.info.displayText || ""
            }
            fareClass={fare?.fareShelf?.shortBrandName || ""}
            showTitle={false}
            plusDays={plusDays}
            fareSlice={fare}
            isMixedCabinClass={isMixedCabinClass}
          />
          {isMixedCabinClass && <MixedCabinToolTip showDivider={false} />}
        </Box>
      </Box>
      {buttonText && onClick ? (
        <ActionButton
          className="mobile-flight-details-button-container"
          onClick={onClick}
          disabled={false}
          message={buttonText}
          disableElevation={true}
        />
      ) : null}
    </MobilePopoverCard>
  ) : null;
};
