import { TripCategory } from "@hopper-b2b/types";
import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  setDepartureDate,
  setDestination,
  setMobileSearchProgress,
  setOrigin,
  setReturnDate,
} from "../../../search/actions/actions";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getPassengersTotal,
  getReturnDate,
  getTripCategory,
} from "../../../search/reducer/selectors";
import {
  setOpenCalendarModal,
  setOpenLocationModal,
} from "../../actions/actions";
import {
  flightShopTypeSelector,
  openCalendarModalSelector,
  openLocationModalSelector,
} from "../../reducer/selectors";
import { FlightShopProgressHeader } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
  origin: getOrigin(state),
  destination: getDestination(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  flightShopType: flightShopTypeSelector(state),
  openCalendarModal: openCalendarModalSelector(state),
  openLocationModal: openLocationModalSelector(state),
  passengerCount: getPassengersTotal(state),
});

const mapDispatchToProps = {
  setOpenCalendarModal,
  setOpenLocationModal,
  setOrigin,
  setDestination,
  setReturnDate,
  setDepartureDate,
  setMobileSearchProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopProgressHeaderConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopProgressHeader = connector(
  FlightShopProgressHeader
);
