import { HotelItinerary } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getHotelStayDuration } from "@hopper-b2b/utilities";
import { Box, Divider, Typography } from "@material-ui/core";
import "./styles.scss";

interface IPaymentSummaryProps {
  hotel: HotelItinerary;
}

export const PaymentSummary = ({ hotel }: IPaymentSummaryProps) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const { reservation, paymentBreakdown } = hotel;
  const { pricing } = reservation;
  const { payNowTotal, rooms, taxes, feeBreakdown, tripTotal } = pricing;
  const additionalFees = feeBreakdown?.total;
  const paymentSummaryContent = paymentBreakdown?.payments.reduce(
    (labels, payment) => {
      switch (payment.PaymentLineItem) {
        case "ApplePay":
          break;
        case "UserCard":
          labels.cardLabel = payment.cardNumberDisplay;
          labels.cardValue = {
            value: payment.amount.amount,
            currencyCode: payment.amount.currency,
          };
          break;
        case "TravelWallet": {
          const amount = {
            value: payment.amount.amount * -1,
            currencyCode: payment.amount.currency,
          };
          switch (payment.TravelWallet) {
            case "Credit":
              labels.rewards.push({
                order: 1,
                label: t("breakdown.creditApplied"),
                amount,
              });
              break;
            case "Offer":
              labels.rewards.push({
                order: 1,
                label: t("promotionDiscountText"),
                amount,
              });
              break;
          }
          break;
        }
        case "Rewards": {
          labels.rewards.push({
            order: 2,
            label: t("pointsApplied"),
            amount: {
              value: payment.amount.fiatValue.amount * -1,
              currencyCode: payment.amount.fiatValue.currency,
            },
          });
          break;
        }
      }
      return labels;
    },
    { rewards: [] } as {
      cardLabel?: string;
      cardValue?: { value: number; currencyCode: string };
      rewards: {
        order: number;
        label: string;
        amount: { value: number; currencyCode: string };
      }[];
    }
  );

  const renderPaymentForTravelers = () => {
    return (
      <Box className="hotel-payment-container">
        <Box className="hotel-payment-details-container">
          <Box className="hotel-pricing-row">
            <Typography variant="body2">
              {`${t("room")} x ${t("night", {
                count: getHotelStayDuration(hotel),
              })}`}
            </Typography>
            <Typography variant="body2">
              {formatFiatCurrency(rooms.fiat)}
            </Typography>
          </Box>
          <Box className="hotel-pricing-row">
            <Typography variant="body2">{t("taxesAndFees")}</Typography>
            <Typography variant="body2">
              {formatFiatCurrency(taxes.fiat)}
            </Typography>
          </Box>
          {additionalFees && additionalFees.value !== 0 && (
            <Box className="hotel-pricing-row">
              <Typography variant="body2">
                {t("additionalServiceFee")}
              </Typography>
              <Typography variant="body2">
                {formatFiatCurrency(additionalFees)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderTripTotal = () => {
    return (
      <Box className="trip-total-row">
        <Typography variant="body1" className="trip-total-text">
          {t("tripTotal")}
        </Typography>
        <Typography variant="body1" className="trip-total-text">
          {formatFiatCurrency(tripTotal.fiat)}
        </Typography>
      </Box>
    );
  };

  const renderRewards = () => {
    return paymentSummaryContent.rewards
      .sort((a, b) => a.order - b.order)
      .map((reward, index) => (
        <Box key={index} className="rewards-container">
          <Typography variant="body2">{reward.label}</Typography>
          <Typography variant="body1" className="rewards-points">
            {formatFiatCurrency(reward.amount)}
          </Typography>
        </Box>
      ));
  };

  const renderPayment = () => {
    return paymentSummaryContent?.cardLabel &&
      paymentSummaryContent.cardValue ? (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <Typography variant="body2" className="payment-title">
            {t("tripsPaymentMethodTitle")}
          </Typography>
        </Box>
        <Box className="payment-card-details">
          <Typography variant="body2" className="payment-card-details">
            {paymentSummaryContent.cardLabel}
          </Typography>
          <Typography variant="body1" className="payment-card-amount">
            {formatFiatCurrency(paymentSummaryContent.cardValue)}
          </Typography>
        </Box>
      </Box>
    ) : null;
  };

  const renderPaidRow = () => {
    return (
      <Box className="hotel-paid-details-container">
        <Typography variant="body2" className="price-bold-row">
          {t("paidAtBooking")}
        </Typography>
        <Typography variant="body2" className="price-bold-row">
          {formatFiatCurrency(payNowTotal.fiat)}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className="hotel-payment-summary-content apac">
      {renderPaymentForTravelers()}
      <Divider className="hotel-pricing-divider" />
      {renderTripTotal()}
      {renderRewards()}
      <Divider className="hotel-pricing-divider" />
      {renderPayment()}
      {renderPaidRow()}
    </Box>
  );
};
