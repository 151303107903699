import {
  CartFulfillErrorModal,
  CartFulfillEventType,
  cartFulfillSelectors,
  CartQuoteEventType,
  CartSelectors,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState } from "@hopper-b2b/types";
import { useHistory } from "react-router";
import { TEvent } from "../../events";
import { PATH_HOTELS_SEARCH } from "../../../../utils/urlPaths";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { LoadingScreen } from "../../../../components/Slots";
import {
  Payment,
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { LodgingError, LodgingErrorEnum } from "@b2bportal/lodging-api";
import { NupayPaymentError } from "../../../../checkout/states/payments/types";
import { ErrorScreen } from "../../../../components/ErrorScreen";

export const ConnectedCartFulfillComponent = () => {
  const { t } = useI18nContext();

  const loadingSteps = [
    {
      title: t("scheduleFulfillLoading.step1Title"),
    },
    {
      title: t("scheduleFulfillLoading.step2Title"),
    },
    {
      title: t("scheduleFulfillLoading.step3Title"),
    },
  ];

  const callState = useSelector(cartFulfillSelectors.getCartFulfillCallState);
  const errorState = useSelector(cartFulfillSelectors.getCartFulfillError);

  return errorState ? (
    <MappedErrorScreen />
  ) : (
    <LoadingScreen
      loading={callState === CallState.InProcess}
      averageLoadingTime={getEnvVariables("cartFulfillLoadingTime") as number}
      className="schedule-fulfill-loading"
      loadingSteps={loadingSteps}
    />
  );
};

export const MappedErrorScreen = () => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();
  const cartErrorModal = useSelector(cartFulfillSelectors.getCartFulfillError);
  const open = useSelector(cartFulfillSelectors.getOpenCartFulfillErrorModal);

  const clearError = () => send(CartFulfillEventType.CLEAR_CART_FULFILL_ERROR);

  const searchAgain = () => {
    clearError();
    history.push(PATH_HOTELS_SEARCH);
  };
  const updateTravelers = () => {
    clearError();
    send(CartFulfillEventType.OPEN_PASSENGER_PICKER);
  };
  // const goToConfirmation = () => {
  //   clearError();
  //   send(CartFulfillEventType.GO_TO_BOOKING_CONFIRMATION);
  // };

  const allowedQuoteRetry = useSelector(CartSelectors.getAllowQuoteRetry);
  const retryQuote = () => {
    clearError();
    send(CartQuoteEventType.RETRY_QUOTE);
  };

  const retryQuoteOrSearchAgain = {
    ...(allowedQuoteRetry
      ? {
          primaryButtonText: t("checkoutError.hotelTryAgain"),
          primaryOnClick: retryQuote,
          secondaryButtonText: t("checkoutError.findNewHotel"),
          secondaryOnClick: searchAgain,
        }
      : {
          primaryButtonText: t("checkoutError.findNewHotel"),
          primaryOnClick: searchAgain,
        }),
  };
  const DEFAULT_ERROR_MODEL_PROPS = {
    title: t("checkoutError.genericErrorTitle"),
    subtitle: t("checkoutError.genericErrorSubtitle"),
    className: "error",
    ...retryQuoteOrSearchAgain,
  };

  const getLodgingError = (lodgingError: LodgingError) => {
    switch (lodgingError.LodgingError) {
      case LodgingErrorEnum.CheckInMinimumAgeNotMet:
      case LodgingErrorEnum.MinimumCheckInAgeNotMet:
        return {
          title: t("checkoutError.hotelCheckInMinimumAgeNotMetTitle"),
          subtitle: t("checkoutError.hotelCheckInMinimumAgeNotMetSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case LodgingErrorEnum.PaymentFailed:
        return {
          title: t("checkoutError.hotelPaymentFailedTitle"),
          subtitle: t("checkoutError.hotelPaymentFailedSubtitle"),
          primaryButtonText: t("checkoutError.hotelTryAgain"),
          primaryOnClick: retryQuote,
          className: "warn",
        };
      case LodgingErrorEnum.InvalidQuote:
        return {
          title: t("checkoutError.hotelInvalidQuoteTitle"),
          subtitle: t("checkoutError.hotelInvalidQuoteSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "timeout",
        };
      case LodgingErrorEnum.RateNotAvailable:
        return {
          title: t("checkoutError.hotelRateNotAvailableTitle"),
          subtitle: t("checkoutError.hotelRateNotAvailableSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case LodgingErrorEnum.LackOfInventory:
      case LodgingErrorEnum.OfferNotFound:
        return {
          title: t("checkoutError.hotelLackOfInventoryTitle"),
          subtitle: t("checkoutError.hotelLackOfInventorySubtitle"),
          primaryButtonText: t("checkoutError.selectAnotherRoom"),
          primaryOnClick: searchAgain,
          className: "warn",
        };
      case LodgingErrorEnum.LodgingErrorCode:
      case LodgingErrorEnum.ProductNotFound:
      case LodgingErrorEnum.ProviderNotDefined:
      case LodgingErrorEnum.QuoteExpired:
      case LodgingErrorEnum.ServiceError:
      case LodgingErrorEnum.Unavailable:
      case LodgingErrorEnum.Unspecified:
      case LodgingErrorEnum.ClientNotDefined:
      default:
        // default cover LodgingError that should not occur in PriceQuote
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getProductError = (error: ProductError) => {
    switch (error.value.type) {
      case Product.Hotel: {
        const lodgingHotel = error.value.value as LodgingError;
        return getLodgingError(lodgingHotel);
      }
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };
  const getNupayPaymentError = (error: NupayPaymentError) => {
    switch (error) {
      case NupayPaymentError.GetUserFailure:
      case NupayPaymentError.OpenSessionFailure:
      case NupayPaymentError.QuoteIdMismatch:
      case NupayPaymentError.UnknownFailure:
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getPaymentError = (error: PaymentError) => {
    switch (error.value.type) {
      case Payment.NupayPayment:
        return getNupayPaymentError(error.value.value as NupayPaymentError);
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getErrorModalProps = (
    type?: CartFulfillErrorModal,
    data?: PurchaseError[]
  ) => {
    switch (type) {
      case CartFulfillErrorModal.FailedPolling: {
        const head = data?.[0]; // todo: we probably want to have some sort of priority order on errors?
        switch (head?.Error) {
          case PurchaseErrorEnum.NoAvailability:
            return {
              title: t("checkoutError.noAvailabilityTitle"),
              subtitle: t("checkoutError.noAvailabilitySubtitle"),
              primaryButtonText: t("checkoutError.searchAgain"),
              primaryOnClick: searchAgain,
              className: "error",
            };
          case PurchaseErrorEnum.PaymentError:
            return getPaymentError(head as PaymentError);
          case PurchaseErrorEnum.ProductError: // todo. Missing `Error` attribute
            return getProductError(head as ProductError);
          case PurchaseErrorEnum.ExpiredFulfillToken:
            return {
              title: t("checkoutError.noAvailabilityTitle"),
              subtitle: t("checkoutError.noAvailabilitySubtitle"),
              primaryButtonText: t("checkoutError.searchAgain"),
              primaryOnClick: searchAgain,
              className: "error",
            };
          default:
            // default case covers
            // - PurchaseErrorEnum.AuthError
            // - PurchaseErrorEnum.ExpiredQuoteToken
            // - PurchaseErrorEnum.FraudAutoReject
            // - PurchaseErrorEnum.InActivity
            // - PurchaseErrorEnum.InvalidSession
            // - PurchaseErrorEnum.NoPaymentsProvided
            // - PurchaseErrorEnum.NonDistinctPayments
            // - PurchaseErrorEnum.ErrorCode (because this isn't standardized enough yet)
            return DEFAULT_ERROR_MODEL_PROPS;
        }
      }
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };
  const errorModalProps = getErrorModalProps(
    cartErrorModal?.type,
    cartErrorModal?.data as PurchaseError[]
  );

  return <ErrorScreen {...errorModalProps} />;
};
