import clsx from "clsx";
import { ButtonWrap } from "@hopper-b2b/ui";
import { ReactComponent as Chevron } from "../../assets/client/chevron.svg";
import { ReactComponent as Close } from "../../assets/client/close.svg";
import styles from "./styles.module.scss";

export interface GenericHeaderProps {
  title: string;
  onBack: () => void;
  close?: boolean;
}

export function GenericHeader({
  title,
  onBack,
  close = false,
}: GenericHeaderProps) {
  return (
    <div className={clsx(styles.GenericHeader, "GenericHeader")}>
      <ButtonWrap className={styles.GenericHeaderBack} onClick={onBack}>
        {close ? <Close /> : <Chevron />}
      </ButtonWrap>
      <h2 className={styles.GenericHeaderTitle}>{title}</h2>
    </div>
  );
}
