import clsx from "clsx";
import { NonResizableImage } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Carousel } from "@hopper-b2b/ui";

export interface GalleryGridProps {
  carouselIndex: number;
  imageUrlsArray: string[];
  filteredMedia: NonResizableImage[];
  handleImageOnClick: (index: number) => void;
}

export function GalleryGrid({
  carouselIndex,
  imageUrlsArray,
  filteredMedia,
  handleImageOnClick,
}: GalleryGridProps) {
  const { t } = useI18nContext();

  return (
    <div className={clsx("GalleryGrid")}>
      {carouselIndex !== null ? (
        <div className="lodging-mobile-carousel">
          <Carousel
            imageUrlsArray={imageUrlsArray}
            hideArrows
            initialIndex={carouselIndex}
          />
        </div>
      ) : null}
      <div
        className={clsx(
          "expanded-gallery-content",
          carouselIndex !== null ? "docked" : ""
        )}
      >
        <div className={clsx("expanded-gallery-grid", "mobile")}>
          {filteredMedia.map((image, index) => {
            return (
              <img
                key={index}
                className={clsx("expanded-gallery-thumbnail", "mobile", {
                  selected: index === carouselIndex,
                })}
                alt={t("hotelImageAltWithCategory", {
                  category: image.category,
                })}
                src={image.url}
                onClick={() => handleImageOnClick(index)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
