import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getDestination,
  getOrigin,
  getDestinationCategoriesLoading,
  getOriginCategoriesLoading,
  getCalendarVisited,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getTripCategory,
  getPassengersTotal,
} from "../../../../reducer";
import { LocationSearchWithDropdowns } from "./component";

const mapDispatchToProps = {
  populateSearchUrlParams: actions.populateSearchUrlParams,
};
const mapStateToProps = (state: IStoreState) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const readyToContinue = origin && destination;
  return {
    readyToContinue,
    loading:
      !!getOriginCategoriesLoading(state) ||
      !!getDestinationCategoriesLoading(state),
    calendarVisited: getCalendarVisited(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    tripCategory: getTripCategory(state),
    passengerCount: getPassengersTotal(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSearchWithDropdownsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedLocationSearchWithDropdowns = connector(
  LocationSearchWithDropdowns
);
