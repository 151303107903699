import { useI18nContext } from "@hopper-b2b/i18n";
import { Button, Typography } from "@material-ui/core";
import { copyTextFromWebview } from "../../../utils/nubankHandoff";

export const TripsConfirmationCopyAndGoButton = ({
  className,
  locator,
  redirectUrl,
  "aria-label": ariaLabel,
}: {
  className?: string;
  locator: string;
  redirectUrl: string;
  onClick: () => void;
  "aria-label": string;
}) => {
  const { t } = useI18nContext();

  const handleCopyAndGo = () => {
    copyTextFromWebview(locator);
    window.open(redirectUrl, "_blank");
  };

  return (
    <Button
      className={className}
      onClick={handleCopyAndGo}
      aria-label={ariaLabel}
    >
      <Typography variant="body2" className="copy-text">
        {t("copyAndGo")}
      </Typography>
    </Button>
  );
};
