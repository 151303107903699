import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FareclassShelfBrandName,
  getFlightGridFares,
  IFlightGridFares,
  mapAlgomerchTexts,
  TagType,
} from "@hopper-b2b/types";
import {
  B2BFlightCard,
  FlightGridRow,
  FlightShopRow,
  TriangleIcon,
} from "@hopper-b2b/ui";
import { formatInterval, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FlightAlgomerchModal } from "../../../FlightAlgomerchModal";
import { FlightListInfoConnectorProps } from "./container";

import "./styles.scss";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}
interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export interface IFlightListInfoContentProps
  extends IFlightListInfoBaseProps,
    FlightListInfoConnectorProps {
  onClick?: () => void;
  onFareClick: (fareId: string, sliceId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}
const FARE_GRID_TAG_LIMIT = 2;

export const FlightListInfoContent = ({
  isReturn,
  outgoingFareRating,
  tripSummary,
  onClick,
  fareDetails,
  isRoundTrip,
  onFareClick,
  maxPriceFilter,
  fareClassFilter,
  rewardsKey,
  isExpanded,
}: IFlightListInfoContentProps) => {
  const { formatFiatCurrency, t } = useI18nContext();
  const { matchesMediumDesktopOnly, matchesMobile } = useDeviceTypes();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedTagValue, setSelectedTagValue] = useState<AlgomerchTag>(
    AlgomerchTag.Cheapest
  );
  const [gridFares, setGridFares] = useState<IFlightGridFares>({
    basic: null,
    standard: null,
    enhanced: null,
    premium: null,
    luxury: null,
  });

  useEffect(() => {
    if (fareDetails) {
      setGridFares(
        getFlightGridFares(
          fareDetails,
          isReturn ? outgoingFareRating : undefined,
          isReturn,
          rewardsKey
        )
      );
    }
  }, [fareDetails, isReturn, outgoingFareRating, rewardsKey]);

  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedTagValue((selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest);
  };

  if (!tripSummary) return null;
  const flightInfo =
    isReturn && tripSummary.returningSlice
      ? tripSummary.returningSlice
      : tripSummary.outgoingSlice;
  const tripId = tripSummary.tripId;

  const renderGridDesktopFlightListInfo = () => {
    return (
      <FlightGridRow
        key={tripId}
        isRoundTrip={isRoundTrip}
        className={clsx("flight-grid-row", `row-${tripId}`)}
        airlineCode={flightInfo.segmentDetails[0].airlineCode}
        airlineName={flightInfo.segmentDetails[0].airlineName}
        fareClassFilter={fareClassFilter}
        selectedMaxPrice={maxPriceFilter}
        fares={gridFares}
        departureTime={flightInfo.departureTime}
        arrivalTime={flightInfo.arrivalTime}
        originCode={flightInfo.originCode}
        destinationCode={flightInfo.destinationCode}
        duration={formatInterval(
          dayjs(flightInfo.arrivalTime).diff(
            dayjs(flightInfo.departureTime),
            "minute",
            true
          )
        )}
        layoverString={
          flightInfo.stops === 0
            ? t("stopDetails.nonstop")
            : t("stopDetails.stop", { count: flightInfo.stops })
        }
        onFareClick={(fareId) => onFareClick(fareId, flightInfo.id)}
        algomerchModalOpen={openModal}
        onAlgomerchInfoClick={(label: string) => {
          handleClickAlgomerchTag(label);
          setOpenModal(true);
        }}
        tagsLimit={FARE_GRID_TAG_LIMIT}
        isExpanded={isExpanded}
        fareCardClassName="b2b"
        type="content"
      />
    );
  };

  const renderRowDesktopFlightListInfo = () => {
    const selectedFareClass = Object.keys(fareClassFilter).find(
      (fare) => fareClassFilter[fare]
    );
    let [displayFare] = fareDetails;
    if (selectedFareClass) {
      const selectedFare = gridFares[selectedFareClass];
      displayFare =
        fareDetails.find((fare) => fare.fareId === selectedFare?.fareId) ||
        displayFare;
    }
    const tags = [
      displayFare.fareScore.isBest ? AlgomerchTag.BestFlight : null,
      displayFare.fareScore.isCheapest ? AlgomerchTag.Cheapest : null,
      displayFare.fareScore.isBestQuality ? AlgomerchTag.BestQuality : null,
      displayFare.fareScore.isFastest ? AlgomerchTag.Fastest : null,
    ]
      .filter((t): t is AlgomerchTag => t !== null)
      .map((value) => ({ value, type: TagType.Algomerch }));

    const segments = flightInfo.segmentDetails;

    return (
      <>
        <FlightShopRow
          airlineCode={flightInfo.segmentDetails[0].airlineCode}
          airlineName={flightInfo.segmentDetails[0].airlineName}
          arrivalTime={segments[segments.length - 1].arrivalTime}
          className={clsx("small-flight-shop-row", "b2b")}
          currentPriceText={formatFiatCurrency(displayFare.amount.fiat)}
          rewardsPriceText={
            rewardsKey && displayFare.amount.rewards[rewardsKey]
              ? `${Math.ceil(
                  displayFare.amount.rewards[rewardsKey].value
                ).toLocaleString()} ${
                  displayFare.amount.rewards[rewardsKey].currency
                }`
              : undefined
          }
          departureTime={segments[0].departureTime}
          destinationCode={flightInfo.destinationCode}
          duration={formatInterval(
            dayjs(flightInfo.arrivalTime).diff(
              dayjs(flightInfo.departureTime),
              "minute",
              true
            )
          )}
          key={tripId}
          layoverString={
            flightInfo.stops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: flightInfo.stops })
          }
          onClickTag={(label: string) => {
            handleClickAlgomerchTag(label);
            setOpenModal(true);
          }}
          originCode={flightInfo.originCode}
          tags={tags}
          tagsLimit={FARE_GRID_TAG_LIMIT}
        />
        <TriangleIcon
          className={clsx("expand-flight-row-icon", {
            "is-expanded": isExpanded,
          })}
          aria-label="triangle icon"
        />
      </>
    );
  };

  const renderMobileFlightListInfo = () => {
    const selectedFareClass = Object.keys(fareClassFilter).find(
      (fare) => fareClassFilter[fare]
    );
    return (
      <Box className="flight-card-wrapper">
        <B2BFlightCard
          flightInfo={flightInfo}
          fareDetails={fareDetails}
          isReturn={isReturn}
          selectedFareClass={selectedFareClass as FareclassShelfBrandName}
          airlineName={flightInfo.segmentDetails[0].airlineName}
          rewardsKey={rewardsKey}
          onClickTag={(label: string) => {
            handleClickAlgomerchTag(label);
            setOpenModal(true);
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Box className={"flight-list-info-root"} onClick={onClick}>
        {matchesMobile
          ? renderMobileFlightListInfo()
          : matchesMediumDesktopOnly
          ? renderRowDesktopFlightListInfo()
          : renderGridDesktopFlightListInfo()}
      </Box>
      <FlightAlgomerchModal
        selectedCategory={selectedTagValue}
        setSelectedCategory={setSelectedTagValue}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: IFlightListInfoProps) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={"flight-list-info-root"}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return null;
  }
};
