import { DoneInvokeEvent } from "xstate";
import {
  CreditPaymentMethod,
  DebitPaymentMethod,
  InstallmentPlan,
} from "./api/getPaymentMethods";
import { IPaymentEventResponse } from "../../types/paymentEvents";
export enum PaymentEvent {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  ERROR = "ERROR",

  SELECT_INSTALLMENT = "SELECT_INSTALLMENT",
  SELECT_CREDIT = "SELECT_CREDIT",
  SELECT_DEBIT = "SELECT_DEBIT",
  ADD_PAYMENT_TO_FULFILL = "ADD_PAYMENT_TO_FULFILL",
  SET_PRE_AUTH_CHECK = "SET_PRE_AUTH_CHECK",
  SET_PRE_AUTH_CHECK_ERROR = "SET_PRE_AUTH_CHECK_ERROR",
  GO_TO_CREDIT = "GO_TO_CREDIT",
  GO_TO_CREDIT_REVIEW = "GO_TO_CREDIT_REVIEW",
  GO_TO_PAYMENT_METHOD = "GO_TO_PAYMENT_METHOD",
  GO_TO_LOADING = "GO_TO_LOADING",
  TRY_AGAIN = "TRY_AGAIN",
}

export interface NextEvent {
  type: PaymentEvent.NEXT;
}
export interface PreviousEvent {
  type: PaymentEvent.PREVIOUS;
}

export interface SelectCreditEvent {
  type: PaymentEvent.SELECT_CREDIT;
  creditPayment: CreditPaymentMethod;
}
export interface SelectDebitEvent {
  type: PaymentEvent.SELECT_DEBIT;
  debitPayment: DebitPaymentMethod;
}

export interface AddPaymentToFulfill {
  type: PaymentEvent.ADD_PAYMENT_TO_FULFILL;
}

export interface SetPreAuthCheck
  extends DoneInvokeEvent<{
    hasAuth: boolean;
    redirectUrl?: string;
  }> {
  type: PaymentEvent.SET_PRE_AUTH_CHECK;
}

export interface GenericPaymentError {
  type: PaymentEvent.ERROR;
  error: unknown;
}

export interface SetCreditInstallment {
  type: PaymentEvent.SELECT_INSTALLMENT;
  installmentPlan: InstallmentPlan;
}

export interface GoToCreditReview {
  type: PaymentEvent.GO_TO_CREDIT_REVIEW;
}

export interface GoToPaymentMethodSelectStep {
  type: PaymentEvent.GO_TO_PAYMENT_METHOD;
}

export interface GoToCreditInstallmentStep {
  type: PaymentEvent.GO_TO_CREDIT;
}

export interface GoToLoadingStep {
  type: PaymentEvent.GO_TO_LOADING;
}

export interface TryAgainEvent {
  type: PaymentEvent.TRY_AGAIN;
}

export type NubankPaymentEvents =
  | AddPaymentToFulfill
  | GoToCreditInstallmentStep
  | GoToCreditReview
  | GoToPaymentMethodSelectStep
  | NextEvent
  | PreviousEvent
  | SelectCreditEvent
  | SelectDebitEvent
  | SetCreditInstallment
  | SetPreAuthCheck
  | TryAgainEvent
  | GoToLoadingStep
  // Transition based on nubank handoff
  | IPaymentEventResponse;
