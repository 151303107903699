import { useCallback, useMemo, useState } from "react";
import { RadioDropdown, uniquePhoneCodesWithCountryCode } from "@hopper-b2b/ui";
import { PhoneNumberValidatorEnum } from "@hopper-b2b/types";
import { formatPhoneNumber } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import ChevronDown from "../../../assets/client/chevron-down.svg";
import ChevronWhite from "../../../assets/client/darkMode/chevron-down-white.svg";
import InputTextField from "../../InputTextField";
import { SelectWithHalfSheet } from "../../SelectWithHalfSheet";
import "./styles.scss";
import { useDarkModePreferred } from "../../../utils/colors";

export const nubankPhoneRegex = /\(?\d{2}\)?\s?9\d{4}[- ]?\d{4}$/;

interface IContactInfoFormProps {
  phone: string;
  countryCode: string;
  email: string;
  onPhoneNumberChanged: (
    number: string,
    countryDialCode: string,
    customIsValid?: (number: string) => PhoneNumberValidatorEnum
  ) => void;
  onEmailChanged: (email: string) => void;
  emailError: string;
  phoneError: string;
  setCountryCode: (code: string) => void;
}

const ContactInfoForm = ({
  phone,
  countryCode,
  email,
  onPhoneNumberChanged,
  onEmailChanged,
  emailError,
  phoneError,
  setCountryCode,
}: IContactInfoFormProps) => {
  const { t, language } = useI18nContext();
  const [baseNumber, setBaseNumber] = useState(phone);
  const isDarkMode = useDarkModePreferred();

  const phoneNumberValidator = useCallback(
    (number: string) => {
      // Nubank wants numbers that are of Brazil's area code
      // to be in the format of (AA) 9NNNN-NNNN
      const specificCountryCode = "+55";

      if (number.length < 9) {
        return PhoneNumberValidatorEnum.NOT_VALIDATED;
      }
      if (countryCode === specificCountryCode) {
        if (!nubankPhoneRegex.test(number)) {
          return PhoneNumberValidatorEnum.CUSTOM_ERROR;
        } else {
          return PhoneNumberValidatorEnum.VALID;
        }
      }
      return PhoneNumberValidatorEnum.NOT_VALIDATED;
    },
    [countryCode]
  );

  const phoneNumberChangedHandler = useCallback(
    (number: string) => {
      const num = number.replace(/\D/g, "");
      setBaseNumber(num);
      onPhoneNumberChanged(num, countryCode, phoneNumberValidator);
    },
    [countryCode, onPhoneNumberChanged, phoneNumberValidator]
  );

  const currCountryCode = uniquePhoneCodesWithCountryCode.find(
    (code) => code.areaCode === countryCode.substring(1)
  ).countryCode;

  const regionNames = useMemo(() => {
    return new Intl.DisplayNames([language], { type: "region" });
  }, [language]);

  const formattedCountryCodeList = useMemo(() => {
    return uniquePhoneCodesWithCountryCode
      .map((code) => {
        return {
          value: `+${code.areaCode}`,
          label: `${regionNames.of(code.countryCode)} (+${code.areaCode})`,
        };
      })
      .sort((a, b) =>
        a.value === "+55"
          ? -1
          : b.value === "+55"
          ? 1
          : a.label > b.label
          ? 1
          : -1
      );
  }, [regionNames]);

  return (
    <>
      <div className="contact-info-form-header">
        <h2>{t("contactInformationDetails")}</h2>
        <p>{t("contactInfoSubtitle")}</p>
      </div>
      {/* country code */}
      <SelectWithHalfSheet
        label={t("countryCode")}
        selected={`${regionNames.of(currCountryCode)} (${countryCode})`}
        className={"country-code-halfsheet input-field"}
        chevronSrc={isDarkMode ? ChevronWhite : ChevronDown}
        ctaLabel={t("confirm")}
        modalContent={
          <RadioDropdown
            dropdownLabel={t("countryCode")}
            options={formattedCountryCodeList}
            setOption={(option) => setCountryCode(option)}
            selected={countryCode}
            showDropdownContentOnly={true}
          />
        }
      />
      {/* phone number */}
      <InputTextField
        label={t("phoneNumber")}
        type="tel"
        value={formatPhoneNumber(baseNumber, countryCode)}
        error={!!phoneError}
        errorText={phoneError}
        placeholder={t("contactInformationPhoneNumberPlaceholder")}
        onChange={phoneNumberChangedHandler}
      />
      {/* email */}
      <InputTextField
        label={t("email")}
        type="text"
        value={email}
        error={!!emailError}
        placeholder={t("contactInformationEmailPlaceholder")}
        onChange={onEmailChanged}
      />
    </>
  );
};

export default ContactInfoForm;
