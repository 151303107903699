import { CartFulfillContext, CartFulfillInitialContext } from "./CartFulfill";
import { CartQuoteContext, CartQuoteInitialContext } from "./CartQuote";
import { CartUpdateContext, CartUpdateInitialContext } from "./CartUpdate";
import { CartState } from "./types";

export interface CartContext {
  [CartState.cartQuote]: CartQuoteContext;
  [CartState.cartUpdate]: CartUpdateContext;
  [CartState.cartFulfill]: CartFulfillContext;
}

export const getCartInitialContext = (): CartContext => ({
  [CartState.cartQuote]: { ...CartQuoteInitialContext },
  [CartState.cartUpdate]: { ...CartUpdateInitialContext },
  [CartState.cartFulfill]: { ...CartFulfillInitialContext },
});
