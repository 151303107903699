import { useI18nContext } from "@hopper-b2b/i18n";
import {
  hasHTSConnectSupport,
  useEnableRemoveRecommendSort,
} from "@hopper-b2b/utilities";
import { FlightModuleFeatureFlag } from "@hopper-b2b/types";
import { RadioDropdown } from "../RadioDropdown";

interface ISortOptionSelectionProps {
  featureFlag?: FlightModuleFeatureFlag;
  showDropdownContentOnly?: boolean;
  sortOption: any; // TODO: Update lodging type - SortOption
  setSortOption: (sortOption: any) => void; // TODO: Update lodging type - SortOption
  sortOptions?: { value: any; label: string }[]; // TODO: Update lodging type - SortOption
}

export const SortOptionSelection = ({
  sortOption,
  setSortOption,
  showDropdownContentOnly,
  sortOptions,
}: ISortOptionSelectionProps) => {
  const enableRemoveRecommendSort = useEnableRemoveRecommendSort();
  const isHTSConnect = hasHTSConnectSupport();
  const { t } = useI18nContext();

  const filteredOptions = enableRemoveRecommendSort
    ? sortOptions.filter(
        (option) =>
          !["stopAndDepartureTime", "fareScore"].includes(option.value)
      )
    : sortOptions;

  const getDropdownLabel = (): string => {
    const label =
      filteredOptions.find((option) => option.value === sortOption)?.label ||
      "";
    return isHTSConnect ? `${t("searchFilter.sortHeader")} ${label}` : label;
  };

  return (
    <RadioDropdown
      dropdownLabel={getDropdownLabel()}
      options={filteredOptions.map((option) => {
        return {
          value: option.value,
          label: option.label,
        };
      })}
      setOption={(option) => setSortOption(option)} // TODO: Update lodging type - "option as SortOption"
      selected={sortOption}
      groupAriaLabel={t("chooseSortOption")}
      buttonAriaLabel={t("changeSortOption")}
      popoverClassName="b2b"
      showDropdownContentOnly={showDropdownContentOnly}
    />
  );
};
