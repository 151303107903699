import { FtcType, UtasPolicy, UtasPolicyHeadline } from "@hopper-b2b/types";

export const getFtcType = (policy: UtasPolicy | undefined): FtcType | null => {
  switch ((policy?.headline ?? "").toLowerCase()) {
    case UtasPolicyHeadline.ForFree.toLowerCase():
      return FtcType.FtcNoFees;
    case UtasPolicyHeadline.AssignedForAFee.toLowerCase():
    case UtasPolicyHeadline.ForAFee.toLowerCase():
      return policy?.fee?.price && policy.fee.price > 0
        ? FtcType.FtcWithFees
        : FtcType.FtcNoFees;
    case UtasPolicyHeadline.NonRefundable.toLowerCase():
    case UtasPolicyHeadline.NotAllowed.toLowerCase():
      return FtcType.NoFtc;
    default:
      return null;
  }
};
