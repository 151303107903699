import {
  ContactEventType,
  ContactInformation,
  ContactSelectors,
  IContactInfo,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
  CartSelectors,
  LodgingSelectors,
  parseLodgingQueryString,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useContext, useMemo } from "react";
import { TEvent } from "../../events";

import { useHistory } from "react-router";
import {
  getClientAssets,
  getStateValue,
} from "../../../flight-checkout/selectors";
import { nubankPhoneRegex } from "../../../flight-checkout/components/ContactInformation";
import "./styles.scss";
import { parsePhoneNumber } from "@hopper-b2b/utilities";
import { decodeEmailAndPhone } from "../../../../checkout/helpers";
import { UserContext } from "../../../../App";
import { removeLocalStorageTrackingEvents } from "@hopper-b2b/lodging";

export const ConnectedContactInformation = (props) => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();

  const step = useSelector(getStateValue);
  const clientAssets = useSelector(getClientAssets);
  const contactInfo = useSelector(ContactSelectors.getContactInformation);
  const { sessionInfo } = useContext(UserContext);

  // TODO: Remove logic below when lodging re-quote is supported
  const isContactInfoChanged = useMemo(async () => {
    const { email: parsedEmail, phone: parsedPhone } =
      parseLodgingQueryString(history);
    if (parsedEmail && parsedPhone) {
      const { decodedEmail, decodedPhone } = await decodeEmailAndPhone({
        email: parsedEmail,
        phone: parsedPhone,
        userId: sessionInfo?.userInfoResponse?.userId,
      });
      const {
        countryCode: parsedCountryCode,
        nationalNumber: parsedNationalNumber,
      } = parsePhoneNumber(decodedPhone);

      const { phoneNumber, countryCode, email } = contactInfo;

      return (
        decodedEmail !== email ||
        parsedCountryCode !== countryCode.substring(1) || // Strip "+" from country code
        parsedNationalNumber !== phoneNumber
      );
    } else {
      return false;
    }
  }, [history, sessionInfo?.userInfoResponse?.userId, contactInfo]);

  const isCartLocked = useSelector(CartSelectors.getCartQuoteIsLocked);
  const availabilityLink = useSelector(LodgingSelectors.getAvailabilityLink);
  const localStorageTrackingEventsKey = useSelector(
    LodgingSelectors.getLocalStorageKey
  );

  const handleContinue = useCallback(() => {
    if (isCartLocked && isContactInfoChanged) {
      history.replace(availabilityLink);
    } else {
      send(ContactEventType.NEXT);
    }
  }, [availabilityLink, history, isCartLocked, isContactInfoChanged, send]);

  const handleGoBack = useCallback(() => {
    // clear local storage since we're going back to shop
    removeLocalStorageTrackingEvents(localStorageTrackingEventsKey);
    if (isCartLocked) {
      history.push(availabilityLink);
    } else {
      history.goBack();
    }
  }, [availabilityLink, history, isCartLocked, localStorageTrackingEventsKey]);

  const onContactInfoChange = useCallback(
    (newContactInfo: IContactInfo) =>
      send({ type: ContactEventType.CHANGE, contactInfo: newContactInfo }),
    [send]
  );

  return (
    <ContactInformation
      {...props}
      contactInfo={contactInfo}
      onGoBack={handleGoBack}
      onContinueClick={handleContinue}
      onContactInfoChange={onContactInfoChange}
      className="b2b"
      fullScreenWithBanner={true}
      clientAssets={clientAssets}
      step={step}
      headerTitle={t("contactInformation")}
      headerElement={t("contactInformation")}
      phoneRegex={nubankPhoneRegex}
      checkPhoneMinLength
      minPhoneLength={9}
    />
  );
};
