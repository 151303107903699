import { Box } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { History } from "history";
import clsx from "clsx";

import { FlightSearchButton } from "../../../SearchButton";
import { MobileCalendarPickerConnectorProps } from "./container";
import { MonthAndDatePicker, MonthAndDatePickerType } from "../../../index";
import { PATH_SHOP } from "../../../../../../utils/urlPaths";
import "./styles.scss";

interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  departure: Date | null;
  returnDate: Date | null;
  focusedMonthIndex: number;
  onComplete?: () => void;
  isHistoryPushDisabled?: boolean;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    readyToSearch,
    focusedMonthIndex,
    onComplete,
    setDepartureDate,
    setReturnDate,
    departure,
    returnDate,
    isHistoryPushDisabled,
  } = props;
  const { t } = useI18nContext();

  return (
    <Box className="mobile-calendar-picker-root">
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        startDate={departure}
        endDate={returnDate}
      />
      {readyToSearch && (
        <FlightSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={t("mobileSearchButton")}
          onClick={(history: History) => {
            !isHistoryPushDisabled && history.push(PATH_SHOP);
            onComplete && onComplete();
          }}
        />
      )}
    </Box>
  );
};
