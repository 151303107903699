import { FlightKey, WatchAlertView } from "@b2bportal/air-price-watch-api";
import {
  AirportRegion,
  Flights,
  OffersResponse,
  RegionType,
  TrackingProperties,
} from "@b2bportal/air-shopping-api";
import { PriceFreezeOfferData } from "@b2bportal/price-freeze-api";
import {
  CallState,
  FLIGHT_SHOP_TYPE,
  FlightShopType,
  GetSimilarFlightsResponse,
  OfferWithSuggested,
  PRICE_FREEZE_ID_QUERY_PARAM,
  Prediction,
  SliceStopCountFilter,
  TransferResponse,
  TripDetails,
  TripSummary,
  IMonthBucket,
  FlightShopDefaultSort,
  IFlightListData,
} from "@hopper-b2b/types";
import { History } from "history";
import { FareclassOptionFilter } from "../../search/reducer";

import { FlightShopStep, SortOption } from "../reducer";
import * as actionTypes from "./constants";

// Getters
export interface IFetchTripSummaries {
  type: actionTypes.FETCH_TRIP_SUMMARIES;
  history: History;
  isMobile?: boolean;
}

export interface IFetchTripSummariesForPrediction {
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION;
  history: History;
  isMobile?: boolean;
}

export interface IShopParams {
  origin: AirportRegion;
  destination: AirportRegion;
  originType: RegionType;
  destinationType: RegionType;
  departureDate: string;
  returnDate?: string;
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  stopsOption: SliceStopCountFilter;
  fareclassOptionFilter: FareclassOptionFilter;
}

export const fetchTripSummaries = (
  history: History,
  isMobile?: boolean
): IFetchTripSummaries => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES,
  history,
  isMobile,
});

export const fetchTripSummariesForPrediction = (
  history: History,
  isMobile?: boolean
): IFetchTripSummariesForPrediction => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION,
  history,
  isMobile,
});

export interface IFetchTripDetails {
  type: actionTypes.FETCH_TRIP_DETAILS;
  tripId: string;
}

export const fetchTripDetails = (tripId: string): IFetchTripDetails => ({
  type: actionTypes.FETCH_TRIP_DETAILS,
  tripId,
});

export interface IFetchSelectedTripPriceFreezeOffer {
  type: actionTypes.FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER;
  fareId: string;
  tripId: string;
  departureDate: Date | null;
}

export const fetchSelectedTripPriceFreezeOffer = (
  fareId: string,
  tripId: string,
  departureDate: Date | null
): IFetchSelectedTripPriceFreezeOffer => ({
  type: actionTypes.FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER,
  fareId,
  tripId,
  departureDate,
});

// Setters
export interface ISetTripSummaries {
  type: actionTypes.SET_TRIP_SUMMARIES;
  tripSummaries: TripSummary[];
}

export const setTripSummaries = (
  tripSummaries: TripSummary[]
): ISetTripSummaries => ({
  type: actionTypes.SET_TRIP_SUMMARIES,
  tripSummaries,
});

export interface ISetTripSummariesError {
  type: actionTypes.SET_TRIP_SUMMARIES_ERROR;
}

export const setTripSummariesError = (): ISetTripSummariesError => ({
  type: actionTypes.SET_TRIP_SUMMARIES_ERROR,
});

export interface IRerunPrediction {
  type: actionTypes.RERUN_PREDICTION;
}

export const setRerunPrediction = (): IRerunPrediction => ({
  type: actionTypes.RERUN_PREDICTION,
});

export interface ISetPrediction {
  type: actionTypes.SET_PREDICTION;
  prediction: Prediction;
}

export const setPrediction = (prediction: Prediction): ISetPrediction => ({
  type: actionTypes.SET_PREDICTION,
  prediction,
});

export interface ISetPriceFreezeOffer {
  type: actionTypes.SET_PRICE_PRICE_FREEZE_OFFER;
  payload: OfferWithSuggested | null;
}

export const setPriceFreezeOffer = (
  payload: OfferWithSuggested | null
): ISetPriceFreezeOffer => ({
  type: actionTypes.SET_PRICE_PRICE_FREEZE_OFFER,
  payload,
});

export interface ISetPredictionError {
  type: actionTypes.SET_PREDICTION_ERROR;
}

export const setPredictionError = (): ISetPredictionError => ({
  type: actionTypes.SET_PREDICTION_ERROR,
});

export interface ISetFlightShopOffers {
  type: actionTypes.SET_OFFERS;
  offers: OffersResponse;
}

export const setFlightShopOffers = (
  offers: OffersResponse
): ISetFlightShopOffers => ({
  type: actionTypes.SET_OFFERS,
  offers,
});

export interface ISetTripDetails {
  type: actionTypes.SET_TRIP_DETAILS;
  tripDetails: TripDetails;
}

export const setTripDetails = (tripDetails: TripDetails): ISetTripDetails => ({
  type: actionTypes.SET_TRIP_DETAILS,
  tripDetails,
});

export interface ISetChosenOutgoingSliceParams {
  outgoingSliceId: string;
  outgoingFareId: string;
  tripId: string;
  outgoingFareRating?: number;
  resetReturnIds?: boolean;
}

export interface ISetChosenOutgoingSlice {
  type: actionTypes.SET_CHOSEN_OUTGOING_SLICE;
  outgoingSliceId: string;
  outgoingFareId: string;
  tripId: string;
  outgoingFareRating?: number;
  resetReturnIds?: boolean;
}

export const setChosenOutgoingSlice = ({
  outgoingSliceId,
  outgoingFareId,
  tripId,
  outgoingFareRating,
  resetReturnIds,
}: ISetChosenOutgoingSliceParams): ISetChosenOutgoingSlice => ({
  type: actionTypes.SET_CHOSEN_OUTGOING_SLICE,
  outgoingSliceId,
  outgoingFareId,
  tripId,
  outgoingFareRating,
  resetReturnIds,
});

export interface ISetChosenReturnSliceParams {
  tripId: string;
  returnSliceId: string;
  returnFareId: string;
  returnFareRating?: number;
}

export interface ISetChosenReturnSlice {
  type: actionTypes.SET_CHOSEN_RETURN_SLICE;
  tripId: string;
  returnFareId: string;
  returnSliceId: string;
  returnFareRating?: number;
}

export const setChosenReturnSlice = ({
  returnSliceId,
  returnFareId,
  returnFareRating,
  tripId,
}: ISetChosenReturnSliceParams): ISetChosenReturnSlice => ({
  type: actionTypes.SET_CHOSEN_RETURN_SLICE,
  returnFareId,
  returnSliceId,
  returnFareRating,
  tripId,
});

export interface ISetFlightShopProgress {
  type: actionTypes.SET_FLIGHT_SHOP_PROGRESS;
  progress: FlightShopStep;
}

export const setFlightShopProgress = (
  progress: FlightShopStep
): ISetFlightShopProgress => ({
  type: actionTypes.SET_FLIGHT_SHOP_PROGRESS,
  progress,
});

export interface ISetRenderLoadingSteps {
  type: actionTypes.SET_RENDER_LOADING_STEPS;
  renderLoadingSteps: boolean;
}

export const setRenderLoadingSteps = (
  renderLoadingSteps: boolean
): ISetRenderLoadingSteps => ({
  type: actionTypes.SET_RENDER_LOADING_STEPS,
  renderLoadingSteps,
});

export interface ISetSelectedTripPriceFreezeOffer {
  type: actionTypes.SET_SELECTED_TRIP_PRICE_FREEZE_OFFER;
  offer: PriceFreezeOfferData;
}

export const setSelectedTripPriceFreezeOffer = (
  offer: PriceFreezeOfferData
): ISetSelectedTripPriceFreezeOffer => ({
  type: actionTypes.SET_SELECTED_TRIP_PRICE_FREEZE_OFFER,
  offer,
});

export interface ISetSortOption {
  type: actionTypes.SET_SORT_OPTION;
  sortOption: SortOption;
}

export const setSortOption = (sortOption: SortOption): ISetSortOption => ({
  type: actionTypes.SET_SORT_OPTION,
  sortOption,
});

export interface IFlightShopOverwriteQueryParams {
  [FLIGHT_SHOP_TYPE]?: FlightShopType;
  [PRICE_FREEZE_ID_QUERY_PARAM]?: string;
  flightShopProgress?: FlightShopStep;
}

export interface IPopulateFlightShopQueryParams {
  type: actionTypes.POPULATE_FLIGHT_SHOP_QUERY_PARAMS;
  history: History;
  prevPath?: string;
  useHistoryPush?: boolean;
  // note: this prop is used to bypass stateDiffersFromQueryParams
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
}

export const populateFlightShopQueryParams = (args: {
  history: History;
  prevPath?: string;
  useHistoryPush?: boolean;
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
}): IPopulateFlightShopQueryParams => ({
  type: actionTypes.POPULATE_FLIGHT_SHOP_QUERY_PARAMS,
  ...args,
});

export interface ICreateWatch {
  type: actionTypes.CREATE_WATCH;
  email: string;
}

export const createWatch = (email: string): ICreateWatch => ({
  type: actionTypes.CREATE_WATCH,
  email,
});

export interface ISetCreateWatchCallState {
  type: actionTypes.SET_CREATE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setCreateWatchCallState = (
  callState: CallState
): ISetCreateWatchCallState => ({
  type: actionTypes.SET_CREATE_WATCH_CALL_STATE,
  callState,
});

export interface IDeleteWatch {
  type: actionTypes.DELETE_WATCH;
}

export const deleteWatch = (): IDeleteWatch => ({
  type: actionTypes.DELETE_WATCH,
});

export interface ISetDeleteWatchCallState {
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setDeleteWatchCallState = (
  callState: CallState
): ISetDeleteWatchCallState => ({
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE,
  callState,
});

export interface IUpdateWatch {
  type: actionTypes.UPDATE_WATCH;
  alertKey: FlightKey;
  email: string;
}

export const updateWatch = (
  alertKey: FlightKey,
  email: string
): IUpdateWatch => ({
  type: actionTypes.UPDATE_WATCH,
  alertKey,
  email,
});

export interface ISetUpdateWatchCallState {
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setUpdateWatchCallState = (
  callState: CallState
): ISetUpdateWatchCallState => ({
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE,
  callState,
});

export interface IListWatches {
  type: actionTypes.LIST_WATCHES;
}

export const listWatches = (): IListWatches => ({
  type: actionTypes.LIST_WATCHES,
});

export interface ISetListWatchesCallState {
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE;
  callState: CallState;
}

export const setListWatchesCallState = (
  callState: CallState
): ISetListWatchesCallState => ({
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE,
  callState,
});

export interface ISetWatches {
  type: actionTypes.SET_WATCHES;
  watches: WatchAlertView[];
}

export const setWatches = (watches: WatchAlertView[]): ISetWatches => ({
  type: actionTypes.SET_WATCHES,
  watches,
});

export interface ISetCalendarBuckets {
  type: actionTypes.SET_CALENDAR_BUCKETS;
  months: IMonthBucket[];
  prices: string[];
}
export const setCalendarBuckets = (
  months: IMonthBucket[],
  prices: string[]
) => ({
  type: actionTypes.SET_CALENDAR_BUCKETS,
  months,
  prices,
});
export interface ISetOpenCalendarModal {
  type: actionTypes.SET_OPEN_CALENDAR_MODAL;
  openCalendarModal: boolean;
}

// Rename setOpenCalendarModal
export const setOpenCalendarModal = (
  openCalendarModal: boolean
): ISetOpenCalendarModal => ({
  type: actionTypes.SET_OPEN_CALENDAR_MODAL,
  openCalendarModal,
});

export interface ISetOpenLocationModal {
  type: actionTypes.SET_OPEN_LOCATION_MODAL;
  openLocationModal: boolean;
}

export const setOpenLocationModal = (
  openLocationModal: boolean
): ISetOpenLocationModal => ({
  type: actionTypes.SET_OPEN_LOCATION_MODAL,
  openLocationModal,
});

export interface ISetFlightShopType {
  type: actionTypes.SET_FLIGHT_SHOP_TYPE;
  flightShopType: FlightShopType;
}

export const setFlightShopType = (
  flightShopType: FlightShopType
): ISetFlightShopType => ({
  type: actionTypes.SET_FLIGHT_SHOP_TYPE,
  flightShopType,
});

export interface IFetchSimilarFlights {
  type: actionTypes.FETCH_SIMILAR_FLIGHTS;
  history?: History;
  id: string;
  refreshPriceFreeze?: boolean;
}

export const fetchSimilarFlights = (args: {
  history?: History;
  id: string;
  refreshPriceFreeze?: boolean;
}): IFetchSimilarFlights => {
  const { refreshPriceFreeze = true } = args;

  return {
    type: actionTypes.FETCH_SIMILAR_FLIGHTS,
    ...args,
    refreshPriceFreeze,
  };
};

export interface ISetSimilarFlightsResponse {
  type: actionTypes.SET_SIMILAR_FLIGHTS_RESPONSE;
  response: Omit<GetSimilarFlightsResponse, "trips">;
  callState: CallState;
}

export const setSimilarFlightsResponse = (args: {
  response: Omit<GetSimilarFlightsResponse, "trips">;
  callState: CallState;
}): ISetSimilarFlightsResponse => ({
  type: actionTypes.SET_SIMILAR_FLIGHTS_RESPONSE,
  ...args,
});

export interface ISetFetchSimilarFlightsCallStateFailed {
  type: actionTypes.SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED;
}

export const setFetchSimilarFlightsCallStateFailed =
  (): ISetFetchSimilarFlightsCallStateFailed => ({
    type: actionTypes.SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
  });

export interface IFetchTransferToSimilarFlights {
  type: actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS;
  history: History;
  id: string;
  tripId: string;
  fareId: string;
}

export const fetchTransferToSimilarFlights = (args: {
  history: History;
  id: string;
  tripId: string;
  fareId: string;
}): IFetchTransferToSimilarFlights => ({
  type: actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS,
  ...args,
});

export interface ISetTransferToSimilarFlightsResponse {
  type: actionTypes.SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE;
  response: TransferResponse;
  callState: CallState;
}

export const setTransferToSimilarFlightsResponse = (args: {
  response: TransferResponse;
  callState: CallState;
}): ISetTransferToSimilarFlightsResponse => ({
  type: actionTypes.SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE,
  ...args,
});

export interface ISetFetchTransferToSimilarFlightsCallStateFailed {
  type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED;
}

export const setFetchTransferToSimilarFlightsCallStateFailed =
  (): ISetFetchTransferToSimilarFlightsCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
  });

export interface ISetFetchTransferToSimilarFlightsCallStateNotCalled {
  type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED;
}

export const setFetchTransferToSimilarFlightsCallStateNotCalled =
  (): ISetFetchTransferToSimilarFlightsCallStateNotCalled => ({
    type: actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED,
  });

export interface IResetSelectedTrip {
  type: actionTypes.RESET_SELECTED_TRIP;
}

export const resetSelectedTrip = (): IResetSelectedTrip => ({
  type: actionTypes.RESET_SELECTED_TRIP,
});

// Tied to https://app.launchdarkly.com/uber/staging/features/uber-air-fintech-selection/targeting
export interface ISetDeclineAllFintechOptions {
  type: actionTypes.SET_DECLINE_FINTECH_OPTIONS;
  declineAllFintechOptions: boolean;
}

export const setDeclineAllFintechOptions = (
  declineAllFintechOptions: boolean
): ISetDeclineAllFintechOptions => ({
  type: actionTypes.SET_DECLINE_FINTECH_OPTIONS,
  declineAllFintechOptions,
});

export interface ISetChfarId {
  type: actionTypes.SET_CHFAR_ID;
  chfarId: string;
}

export const setChfarId = (chfarId: string): ISetChfarId => ({
  type: actionTypes.SET_CHFAR_ID,
  chfarId,
});

export interface ISetFlightShoptDefaultSort {
  type: actionTypes.SET_FLIGHT_SHOP_DEFAULT_SORT;
  defaultSort: FlightShopDefaultSort;
}

export const setFlightShopDefaultSort = (
  defaultSort: FlightShopDefaultSort
): ISetFlightShoptDefaultSort => ({
  type: actionTypes.SET_FLIGHT_SHOP_DEFAULT_SORT,
  defaultSort,
});

export interface ISetShopTrackingProperties {
  type: actionTypes.SET_SHOP_TRACKING_PROPERTIES;
  trackingProperties: TrackingProperties;
}

export const setShopTrackingProperties = (
  trackingProperties: TrackingProperties
): ISetShopTrackingProperties => ({
  type: actionTypes.SET_SHOP_TRACKING_PROPERTIES,
  trackingProperties,
});

export interface ISetTopFlightsIds {
  type: actionTypes.SET_TOP_FLIGHTS_IDS;
  flightsToRender: IFlightListData[];
}

export const setTopFlights = (flightsToRender): ISetTopFlightsIds => ({
  type: actionTypes.SET_TOP_FLIGHTS_IDS,
  flightsToRender,
});

export type FlightShopActions =
  | IFetchTripSummaries
  | IFetchTripSummariesForPrediction
  | IFetchTripDetails
  | ISetPredictionError
  | ISetPrediction
  | IRerunPrediction
  | ISetTripSummaries
  | ISetTripSummariesError
  | ISetTripDetails
  | ISetChosenOutgoingSlice
  | ISetChosenReturnSlice
  | ISetFlightShopProgress
  | ISetRenderLoadingSteps
  | ISetSortOption
  | ICreateWatch
  | ISetCreateWatchCallState
  | IListWatches
  | ISetListWatchesCallState
  | IUpdateWatch
  | ISetUpdateWatchCallState
  | IDeleteWatch
  | ISetDeleteWatchCallState
  | ISetWatches
  | ISetCalendarBuckets
  | ISetOpenCalendarModal
  | ISetOpenLocationModal
  | ISetPriceFreezeOffer
  | IFetchSimilarFlights
  | ISetSimilarFlightsResponse
  | ISetFetchSimilarFlightsCallStateFailed
  | IFetchTransferToSimilarFlights
  | ISetTransferToSimilarFlightsResponse
  | ISetFetchTransferToSimilarFlightsCallStateFailed
  | ISetFetchTransferToSimilarFlightsCallStateNotCalled
  | ISetFlightShopType
  | IResetSelectedTrip
  | ISetFlights
  | IFetchTripSummariesV2
  | IFetchTripSummariesForPredictionV2
  | IFetchTripSummariesV3
  | IFetchFlightsV4
  | ISetSelectedTripPriceFreezeOffer
  | IFetchSelectedTripPriceFreezeOffer
  | ISetDeclineAllFintechOptions
  | ISetChfarId
  | ISetFlightShopOffers
  | ISetFlightShoptDefaultSort
  | ISetShopTrackingProperties
  | ISetTopFlightsIds;

// ===== shop v2

export interface IFetchTripSummariesV2 {
  type: actionTypes.FETCH_TRIP_SUMMARIES_V2;
  history: History;
  isMobile?: boolean;
}

export interface IFetchTripSummariesForPredictionV2 {
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2;
  history: History;
  isMobile?: boolean;
}

export const fetchTripSummariesV2 = (
  history: History,
  isMobile?: boolean
): IFetchTripSummariesV2 => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_V2,
  history,
  isMobile,
});

export const fetchTripSummariesForPredictionV2 = (
  history: History,
  isMobile?: boolean
): IFetchTripSummariesForPredictionV2 => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2,
  history,
  isMobile,
});

// ===== shop v3
export interface IFetchTripSummariesV3 {
  type: actionTypes.FETCH_TRIP_SUMMARIES_V3;
  history: History;
  isMobile?: boolean;
  isIataEnabled?: boolean;
}

export const fetchTripSummariesV3 = (
  history: History,
  isMobile?: boolean,
  isIataEnabled?: boolean
): IFetchTripSummariesV3 => ({
  type: actionTypes.FETCH_TRIP_SUMMARIES_V3,
  history,
  isMobile,
  isIataEnabled,
});

export interface IFetchFlightsV4 {
  type: actionTypes.FETCH_FLIGHTS_V4;
  history: History;
  isMobile?: boolean;
  isIataEnabled?: boolean;
  controller?: AbortController;
}

export const fetchFlightsV4 = (
  history: History,
  isMobile?: boolean,
  isIataEnabled?: boolean,
  controller?: AbortController
): IFetchFlightsV4 => ({
  type: actionTypes.FETCH_FLIGHTS_V4,
  history,
  isMobile,
  isIataEnabled,
  controller,
});

//TODO: Types
export interface ISetFlights {
  type: actionTypes.SET_FLIGHTS;
  flights: Flights;
}

export const setFlights = (flights: any): ISetFlights => ({
  type: actionTypes.SET_FLIGHTS,
  flights,
});
