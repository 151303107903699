import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { SliceStopCountFilter } from "@hopper-b2b/types";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getHasSetStopsOption,
  getHasSetArrivalTimeRange,
  getHasSetDepartureTimeRange,
  getHasSetAirportFilter,
  getHasSetAirlineFilter,
  getHasSetFlightNumberFilter,
  getStopsOptionFilter,
} from "../../../../reducer/selectors";
import { initialFilterOptions } from "../../../../reducer";
import {
  hasSetMaxPriceFilterSelectorV2,
  maxFlightPriceSelectorV2,
} from "../../../../../shop/reducer/selectors";
import { actions } from "../../../../actions";
import {
  IRerunPrediction,
  setRerunPrediction,
} from "../../../../../shop/actions/actions";
import { AppliedFilterTags } from "./component";

interface IStateProps {
  filters: { [key: string]: boolean };
  data: { maxFlightPrice: number };
  appliedStopsFilter: SliceStopCountFilter;
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    filters: {
      stops: getHasSetStopsOption(state),
      airline: getHasSetAirlineFilter(state),
      price: hasSetMaxPriceFilterSelectorV2(state),
      departure: getHasSetDepartureTimeRange(state),
      arrival: getHasSetArrivalTimeRange(state),
      airport: getHasSetAirportFilter(state),
      flightNumber: getHasSetFlightNumberFilter(state),
    },
    data: {
      maxFlightPrice: maxFlightPriceSelectorV2(state),
    },
    appliedStopsFilter: getStopsOptionFilter(state),
  };
};

type ResetFunctions =
  | {
      [key: string]: () => void;
    }
  | {
      price: (maxPrice: number) => void;
    };

interface IDispatchProps {
  setRerunPrediction: () => IRerunPrediction;
  reset: ResetFunctions;
  resetAll: (maxPrice: number) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setRerunPrediction: () => dispatch(setRerunPrediction()),
    reset: {
      // TODO: add apiConfig here
      stops: () =>
        dispatch(actions.setStopsOption(initialFilterOptions.stopsOption)),
      airline: () =>
        dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter)),
      price: (maxPrice: number) =>
        dispatch(actions.setMaxPriceFilter(maxPrice)),
      airport: () =>
        dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter)),
      flightNumber: () =>
        dispatch(
          actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
        ),
      departure: () => {
        dispatch(
          actions.setOutboundDepartureTimeRange(
            initialFilterOptions.outboundDepartureTimeRange
          )
        );
        dispatch(
          actions.setReturnDepartureTimeRange(
            initialFilterOptions.returnDepartureTimeRange
          )
        );
      },
      arrival: () => {
        dispatch(
          actions.setOutboundArrivalTimeRange(
            initialFilterOptions.outboundArrivalTimeRange
          )
        );
        dispatch(
          actions.setReturnArrivalTimeRange(
            initialFilterOptions.returnArrivalTimeRange
          )
        );
      },
    },
    resetAll: (maxPrice: number) => {
      // TODO: add apiConfig here
      dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
      dispatch(
        actions.setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
      dispatch(actions.setMaxPriceFilter(maxPrice));
      dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
      dispatch(
        actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
      );
      dispatch(
        actions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        actions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnArrivalTimeRange(
          initialFilterOptions.returnArrivalTimeRange
        )
      );
    },
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps) => {
  const maxFlightPrice = stateProps.data.maxFlightPrice;

  return {
    ...stateProps,
    ...dispatchProps,
    resets: {
      reset: {
        ...dispatchProps.reset,
        price: () => dispatchProps.reset.price(maxFlightPrice),
      },
      resetAll: () => dispatchProps.resetAll(maxFlightPrice),
    },
  };
};

export type AppliedFilterTagsProps = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export const ConnectedAppliedFilterTags = connector(AppliedFilterTags);
