import {
  FlightClientAssetProps,
  FlightModuleFeatureFlag,
} from "@hopper-b2b/types";
import { SlotProvider, Slots } from "@hopper-b2b/ui";
import { FeatureFlagsContextProps } from "@hopper-b2b/utilities";
import { colors, Theme } from "@material-ui/core";
import { lazy, useContext, useMemo } from "react";
import { UserContext } from "../../App";
import { flightCheckoutProps } from "../../modules/flight-checkout";
import { apiConfig } from "../../utils/urlPaths";
import { darkModeIcons, lightModeIcons } from "./sharedIcons";
import ClientLogo from "../../assets/client/client-logo.svg";
// TODO: Check why lodging is still importing these styles
import "../../nubank.index.scss";
import { useDarkModePreferred } from "../../utils/colors";

const FlightsApp = lazy(() => import("@hopper-b2b/flights"));

interface INubankRoute {
  theme: Theme;
  featureFlags: FeatureFlagsContextProps;
  slots: Slots;
}

const FlightsRouteComponent = ({
  theme,
  featureFlags,
  slots,
}: INubankRoute) => {
  const { sessionInfo } = useContext(UserContext);
  const isDarkMode = useDarkModePreferred();

  const flightAppClientAssets: FlightClientAssetProps = useMemo(
    () => ({
      logo: ClientLogo,
      sessionInfo: sessionInfo,
      featureFlag: {
        enableA11yMode: true,
      } as FlightModuleFeatureFlag,
      assets: isDarkMode ? darkModeIcons : lightModeIcons,
    }),
    [featureFlags.enableDarkMode, sessionInfo]
  );

  return (
    <SlotProvider slots={slots}>
      <FlightsApp
        checkoutProps={{ ...flightCheckoutProps, sessionInfo }}
        apiConfig={apiConfig}
        clientAssets={flightAppClientAssets}
        featureFlags={featureFlags}
        colors={colors}
        theme={theme}
      />
    </SlotProvider>
  );
};

export default FlightsRouteComponent;
