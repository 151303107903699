import { ButtonWrap, MobileFloatingSection } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ReactComponent as CloseIcon } from "../../../../../assets/client/close.svg";
import { ReactComponent as InvalidPaymentImage } from "../../../../../assets/client/invalid-payment.svg";
import styles from "./styles.module.scss";
import { closeNubankWebview } from "../../../../../utils/nubankHandoff";

export const InvalidPaymentScreen = () => {
  const { t } = useI18nContext();

  const handleGoToNuTravel = () => {
    closeNubankWebview();
  };

  return (
    <div className={styles.invalidPaymentScreen}>
      <div className="nupay-header">
        <ButtonWrap className="close-button" onClick={handleGoToNuTravel}>
          <CloseIcon className="arrow-back-icon" />
        </ButtonWrap>
      </div>
      <div className="content">
        <InvalidPaymentImage className="invalid-payment" />
        <h1 className="title">{t("nupay.invalidPaymentTitle")}</h1>
        <p className="subtitle">{t("nupay.invalidPaymentSubheader")}</p>
      </div>
      <MobileFloatingSection
        primaryButton={{
          children: t("nupay.invalidPaymentButton"),
          onClick: handleGoToNuTravel,
        }}
      />
    </div>
  );
};
