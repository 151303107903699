import { getObjectKeysAsObject } from "@hopper-b2b/checkout";
import { trackViewPayment } from "./trackViewPayment";
import { checkAuthStatusService } from "./checkAuthStatus";
import { fetchPaymentMethodsService } from "./fetchPaymentMethods";
import { notifyFailedEnrollment } from "./notifyFailedEnrollment";
import { notifySuccessfulEnrollment } from "./notifySuccessfulEnrollment";

export const NubankPaymentServices = {
  checkAuthStatusService,
  fetchPaymentMethodsService,
  notifyFailedEnrollment,
  notifySuccessfulEnrollment,
  trackViewPayment,
};

export const NubankPaymentServiceTypes = getObjectKeysAsObject(
  NubankPaymentServices
);
