import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  TripCategory,
  VIEWED_AMENITY_REVIEW,
} from "@hopper-b2b/types";

import { GlobeBunnies, Slot } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getSliceIndex,
  tenantFlagsEnabled,
  useEnableCfar,
  useEnableChfar,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash-es";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import { ClientContext } from "../../../../App";
import { getSelectedCfarOffer } from "../../../cfar/reducer";
import { getSelectedChfarOffer } from "../../../chfar/reducer";
import { useGoToCheckout } from "../../hooks";
import { FlightPricingSummarySection } from "../FlightPricingSummarySection";
import { getRestrictions } from "../FlightShopReviewDetails/component";
import { getRewardsText } from "../FlightShopReviewItinerary/utils";
import { RestrictionsSection } from "../RestrictionsSection";
import { MobileFareDetailsConnectorProps } from "./container";
import "./styles.scss";

export interface IMobileFareDetailsProps
  extends MobileFareDetailsConnectorProps,
    RouteComponentProps {}

export const MobileFareDetails = (props: IMobileFareDetailsProps) => {
  const {
    fareDetails,
    tripDetails,
    rewardsKey,
    isOneWay,
    history,
    returnDate,
    departureDate,
    airports,
    isMultiTicket,
    viewedAmenityReviewProperties,
    tripTotalIncludingOffers,
  } = props;
  const [isHackerFare, setIsHackerFare] = useState(isMultiTicket);
  const clientContext = useContext(ClientContext);
  const { postEvent } = useUberBridge();

  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;

  const goToCheckout = useGoToCheckout();

  const { t, formatFiatCurrency } = useI18nContext();

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  useEffect(() => {
    setIsHackerFare(isMultiTicket);
  }, [isMultiTicket]);

  useEffect(() => {
    // Hide footer on Fare Details screen - cannot update HIDDEN_FOOTER_PATHS because we are still in flight shop
    const footer = document.querySelector(".generic-footer") as HTMLElement;
    if (footer) footer.style.display = "none";
    return () => {
      if (footer) footer.style.display = "";
    };
  }, []);

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_AMENITY_REVIEW,
      properties: viewedAmenityReviewProperties,
    });
    postEvent(VIEWED_AMENITY_REVIEW);
  });

  const pricing = useMemo(
    () => fareDetails?.paxPricings[0].pricing,
    [fareDetails?.paxPricings]
  );

  const rewardText = getRewardsText({ rewardsKey, pricing });
  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);
  const customerSupport = tenantFlagsEnabled.CustomerSupport;
  const showFlightCFAR = useEnableCfar();
  const showFlightChfar = useEnableChfar();
  const cfarSelected = showFlightCFAR && !isEmpty(selectedCfarOffer);
  const chfarSelected = showFlightChfar && !isEmpty(selectedChfarOffer);

  const departureRestrictions = useMemo(() => {
    if (fareDetails) {
      return getRestrictions({
        fareDetails,
        sliceIndex: firstSliceIndex,
        translate: t,
        customerSupport,
        cfarSelected,
        chfarSelected,
      });
    }
    return null;
  }, [
    fareDetails,
    firstSliceIndex,
    t,
    customerSupport,
    cfarSelected,
    chfarSelected,
  ]);

  const returnRestrictions = useMemo(() => {
    if (fareDetails && !isOneWay) {
      return getRestrictions({
        fareDetails,
        sliceIndex: secondSliceIndex,
        translate: t,
        customerSupport,
        cfarSelected,
        chfarSelected,
      });
    }
    return null;
  }, [
    fareDetails,
    isOneWay,
    secondSliceIndex,
    t,
    customerSupport,
    cfarSelected,
    chfarSelected,
  ]);

  if (!tripDetails || !fareDetails || !fareDetails.paxPricings) {
    history.push(history.location);
    return null;
  }

  return (
    <Box
      className={clsx("fare-details-container", "mobile-fare-details", {
        "fare-details-container-empty-restrictions":
          (departureRestrictions?.length === 0 &&
            returnRestrictions?.length === 0) ||
          isHopper,
        "margin-bottom": isHopper,
      })}
    >
      <Box className="fare-details-banner-container">
        {clientContext.itineraryReviewBackground && (
          <img
            src={clientContext.itineraryReviewBackground}
            alt="fare details banner background"
            className="fare-details-banner-background"
          />
        )}
        <Box
          className={clsx("fare-details-overlay-text", {
            "combination-flight-text": isMultiTicket,
          })}
        >
          <div className={clsx("fare-details-overlay-text-content")}>
            <Typography className="title">
              {isMultiTicket
                ? t("flightShopReview.multiTicketTitle")
                : t("flightShopReview.nonMultiTicketTitle")}
            </Typography>
            <Typography className="subtitle">
              {isMultiTicket
                ? t("flightShopReview.multiTicketSubtitle")
                : t("flightShopReview.nonMultiTicketSubtitle")}
            </Typography>
          </div>
          <div className={clsx("fare-details-overlay-text-image")}>
            <img
              src={GlobeBunnies}
              alt="Celebratory bunnies with mobile phone and globe"
              className={clsx("fare-details-overlay-text-image-bunny")}
            />
          </div>
        </Box>
        {isMultiTicket ? (
          <Slot
            id="flight-shop-review-multiticket-banner"
            copy={t("flightShopReview.multiTicketBanner")}
          />
        ) : null}
      </Box>
      <RestrictionsSection
        fareDetails={fareDetails}
        tripDetails={tripDetails}
        isOneWay={isOneWay}
        returnDate={returnDate}
        departureDate={departureDate}
        airports={airports}
        departureRestrictions={departureRestrictions}
        returnRestrictions={returnRestrictions}
        isHackerFare={isHackerFare}
      />
      {!isHopper && (
        <Box className="flight-shop-fare-details-pricing-section">
          <Box className="price-text">
            {clientContext?.assets?.card ? (
              <img src={clientContext?.assets?.card} alt="Credit card icon" />
            ) : (
              <FontAwesomeIcon
                icon={faCreditCard as IconProp}
                className="credit-card-icon"
              />
            )}
            <Slot
              id="flight-mobile-fare-details-trip-price"
              component={
                <Typography className="content" variant="body1">
                  {formatFiatCurrency(tripTotalIncludingOffers, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  {rewardText && (
                    <>
                      <span className="separator">/</span>
                      <span className="fare-reward">{rewardText}</span>
                    </>
                  )}
                </Typography>
              }
            />
          </Box>
          <Typography className="description-text">
            {t(isOneWay ? "oneWayPerTraveler" : "roundTripPerTraveler")}
          </Typography>
        </Box>
      )}
      <Slot
        id="flight-shop-cta-section"
        totalPrice={tripTotalIncludingOffers}
        discountedPrice={
          fareDetails.paxPricings[0].pricing?.totalWithDiscount?.fiat
        }
        tripCategory={isOneWay ? TripCategory.ONE_WAY : TripCategory.ROUND_TRIP}
        onClick={goToCheckout}
        component={
          <FlightPricingSummarySection hidePrice onClick={goToCheckout} />
        }
      />
    </Box>
  );
};
