import {
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CHFAR_ID_QUERY_PARAM,
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  FLIGHT_SHOP_TYPE,
  FlightSearchQueryParams,
  FlightShopType,
  PRICE_FREEZE_ID_QUERY_PARAM,
  SliceStopCountFilter,
  TripCategory,
  UTM_SOURCE_QUERY_PARAM,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import * as H from "history";
import queryStringParser from "query-string";
import { RegionType } from "@b2bportal/air-shopping-api";
import { FlightShopStep } from "../reducer";

export interface IFlightShopParsedQuery {
  origin: string;
  destination: string;
  originType: string | RegionType;
  destinationType: string | RegionType;
  departureDate: Date | null;
  returnDate: Date | null;
  tripCategory: TripCategory;
  noLCC: boolean;
  stopsOption: string;
  flightShopProgress: number;
  isFromFlightWatch?: boolean;
  [FlightSearchQueryParams.ADULT_COUNT]: number;
  [FlightSearchQueryParams.CHILD_COUNT]: number;
  [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]: number;
  [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]: number;
  [FLIGHT_SHOP_TYPE]?: FlightShopType;
  [PRICE_FREEZE_ID_QUERY_PARAM]?: string;
  [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]?: string;
  [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]?: string;
  [CHFAR_ID_QUERY_PARAM]?: string;
  [UTM_SOURCE_QUERY_PARAM]?: string;
}

export const parseQueryString = (
  history: H.History
): IFlightShopParsedQuery => {
  const queryString = history?.location?.search || "";
  return parseQueryFromString(queryString);
};

export const parseQueryFromString = (
  queryString: string
): IFlightShopParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  return {
    ...parsedQueryStringPrimitive,
    tripCategory: parsedQueryStringPrimitive.tripCategory as TripCategory,
    origin: parsedQueryStringPrimitive.origin as string,
    destination: parsedQueryStringPrimitive.destination as string,
    originType: parsedQueryStringPrimitive.originType as string,
    destinationType: parsedQueryStringPrimitive.destinationType as string,
    departureDate: parsedQueryStringPrimitive.departureDate
      ? dayjs(parsedQueryStringPrimitive.departureDate as string).toDate()
      : null,
    returnDate: parsedQueryStringPrimitive.returnDate
      ? dayjs(parsedQueryStringPrimitive.returnDate as string).toDate()
      : null,
    noLCC: (parsedQueryStringPrimitive.noLCC as string) === "true",
    stopsOption: parsedQueryStringPrimitive.stopsOption as SliceStopCountFilter,
    flightShopProgress: Number(
      parsedQueryStringPrimitive.flightShopProgress ??
        // TODO: Change to FlightShopStep.PricePrediction when prediction is back in
        FlightShopStep.ChooseDeparture
    ),
    [FlightSearchQueryParams.ADULT_COUNT]: parsedQueryStringPrimitive[
      FlightSearchQueryParams.ADULT_COUNT
    ]
      ? parseInt(
          parsedQueryStringPrimitive[
            FlightSearchQueryParams.ADULT_COUNT
          ] as string
        )
      : null,
    [FlightSearchQueryParams.CHILD_COUNT]: parsedQueryStringPrimitive[
      FlightSearchQueryParams.CHILD_COUNT
    ]
      ? parseInt(
          parsedQueryStringPrimitive[
            FlightSearchQueryParams.CHILD_COUNT
          ] as string
        )
      : null,
    [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]: parsedQueryStringPrimitive[
      FlightSearchQueryParams.INFANT_IN_LAP_COUNT
    ]
      ? parseInt(
          parsedQueryStringPrimitive[
            FlightSearchQueryParams.INFANT_IN_LAP_COUNT
          ] as string
        )
      : null,
    [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]: parsedQueryStringPrimitive[
      FlightSearchQueryParams.INFANT_IN_SEAT_COUNT
    ]
      ? parseInt(
          parsedQueryStringPrimitive[
            FlightSearchQueryParams.INFANT_IN_SEAT_COUNT
          ] as string
        )
      : null,
    isFromFlightWatch:
      (parsedQueryStringPrimitive.isFromFlightWatch as string) === "true" ||
      undefined,
    [FLIGHT_SHOP_TYPE]: parsedQueryStringPrimitive[
      FLIGHT_SHOP_TYPE
    ] as FlightShopType,
    [PRICE_FREEZE_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
      PRICE_FREEZE_ID_QUERY_PARAM
    ] as string,
    [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]:
      parsedQueryStringPrimitive.cfarOfferQuoteId as string,
    [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]:
      parsedQueryStringPrimitive.chfarOfferQuoteId as string,
    [CHFAR_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
      CHFAR_ID_QUERY_PARAM
    ] as string,
    [UTM_SOURCE_QUERY_PARAM]: parsedQueryStringPrimitive[
      UTM_SOURCE_QUERY_PARAM
    ] as string,
  };
};

export const pushToPathWithExistingQueryParams = (
  history: H.History,
  pathname: string,
  params: any,
  replace?: boolean
) => {
  const existingParams = parseQueryString(history);
  const newParams = {
    ...existingParams,
    departureDate: existingParams.departureDate
      ? dayjs(existingParams.departureDate).format("YYYY-MM-DD")
      : undefined,
    returnDate: existingParams.returnDate
      ? dayjs(existingParams.returnDate).format("YYYY-MM-DD")
      : undefined,
    ...params,
  };
  const newSimilarFlightsParams = {
    flightShopProgress: existingParams.flightShopProgress,
    [FLIGHT_SHOP_TYPE]: existingParams[FLIGHT_SHOP_TYPE],
    [PRICE_FREEZE_ID_QUERY_PARAM]: existingParams[PRICE_FREEZE_ID_QUERY_PARAM],
    [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]:
      existingParams[CFAR_OFFER_QUOTE_ID_QUERY_PARAM],
    [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]:
      existingParams[CHFAR_OFFER_QUOTE_ID_QUERY_PARAM],
    ...params,
  };
  const isSimilarFlights =
    newParams[FLIGHT_SHOP_TYPE] === FlightShopType.PRICE_FREEZE_EXERCISE;

  if (replace) {
    history.replace({
      pathname,
      search: queryStringParser.stringify(
        isSimilarFlights ? newSimilarFlightsParams : newParams
      ),
    });
  } else {
    history.push({
      pathname,
      search: queryStringParser.stringify(
        isSimilarFlights ? newSimilarFlightsParams : newParams
      ),
    });
  }
};
