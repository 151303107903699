import { useCallback } from "react";
import { useHistory } from "react-router";
import {
  ContactInformation,
  ContactSelectors,
  useCheckoutStateSelector as useSelector,
  useCheckoutSend,
  ContactEventType,
  IContactInfo,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";

import { TEvent } from "../events";
import { getClientAssets, getStateValue } from "../selectors";
import { ConnectedPriceBreakdownContent } from "./PriceBreakdownDropdown";
import { ConnectedBreadcrumbs } from "./Breadcrumbs";

export const nubankPhoneRegex = /\(?\d{2}\)?\s?9\d{4}[- ]?\d{4}$/;

export const ConnectedContactInformation = (props) => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();

  const step = useSelector(getStateValue);
  const clientAssets = useSelector(getClientAssets);

  const contactInfo = useSelector(ContactSelectors.getContactInformation);

  const handleContinue = () => send(ContactEventType.NEXT);

  const handleGoBack = () => {
    history.goBack();
  };

  const onContactInfoChange = useCallback(
    (newContactInfo: IContactInfo) =>
      send({ type: ContactEventType.CHANGE, contactInfo: newContactInfo }),
    [send]
  );

  return (
    <ContactInformation
      {...props}
      contactInfo={contactInfo}
      onGoBack={handleGoBack}
      onContinueClick={handleContinue}
      onContactInfoChange={onContactInfoChange}
      className="b2b"
      fullScreenWithBanner={true}
      desktopRightContent={<ConnectedPriceBreakdownContent />}
      desktopTopContent={<ConnectedBreadcrumbs />}
      clientAssets={clientAssets}
      step={step}
      headerTitle={t("contactInformation")}
      headerElement={t("contactInformation")}
      phoneRegex={nubankPhoneRegex}
      checkPhoneMinLength
      minPhoneLength={9}
    />
  );
};
