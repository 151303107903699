import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  getDateFormat,
  getPreferMonthDayYearFormat,
  useI18nContext,
  useI18nCountry,
} from "@hopper-b2b/i18n";
import { Passport } from "@b2bportal/air-booking-api";
import { CountryCode } from "@hopper-b2b/types";
import { RadioDropdown } from "@hopper-b2b/ui";
import {
  getDateTimeWithFormat,
  parseDayMonthAndYear,
  parseMonthDayAndYear,
  specialCharacterRegex,
} from "@hopper-b2b/utilities";
import Chevron from "../../../../assets/client/chevron.svg";
import InputTextField from "../../../../components/InputTextField";
import { SelectWithHalfSheet } from "../../../../components/SelectWithHalfSheet";
import emojiFlags from "emoji-flags";

export interface IPassportInfoFormProps {
  className?: string;
  isMobile?: boolean;
  title: string;
  subtitle: string;
  subtitle2?: string;
  passportInfo?: Passport;
  onPassportInfoChange: (passportInfo: Passport) => void;
  passportExpiryError?: string;
  setPassportExpiryError: (error: string) => void;
  passportNumberError?: string;
  setPassportNumberError: (error: string) => void;
  departureDate?: Date | null | undefined;
  returnDate?: Date | null | undefined;
}

export const PassportInfoForm = ({
  title,
  subtitle,
  subtitle2,
  passportInfo,
  onPassportInfoChange,
  className,
  isMobile,
  passportExpiryError,
  setPassportExpiryError,
  passportNumberError,
  setPassportNumberError,
  departureDate,
  returnDate,
}: IPassportInfoFormProps) => {
  const { t, brand } = useI18nContext();
  const { getAllCountryNames } = useI18nCountry();

  const preferedDateFormat = getDateFormat();
  const preferMonthDayYearFormat = getPreferMonthDayYearFormat();

  const [countryOfIssue, setCountryOfIssue] = useState<CountryCode>(
    passportInfo?.countryOfIssue ?? brand.preferredCountryCode
  );
  const [number, setPassportNum] = useState(passportInfo?.number || "");
  const [expiration, setExpiration] = useState(
    passportInfo?.expiration
      ? dayjs(passportInfo?.expiration)
          .format(preferedDateFormat)
          .replace("-", "/")
      : ""
  );

  const countryOptions = useMemo(() => {
    const options = Array.from(Object.entries(getAllCountryNames())).map(
      ([code, label]) => ({
        label,
        value: code,
        icon: (
          <span className="checkout-country-option-flag-icon">
            {emojiFlags.countryCode(code).emoji}
          </span>
        ),
      })
    );
    // Replace with toSorted once we migrate to es2023+
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [getAllCountryNames]);

  useEffect(() => {
    if (number !== passportInfo?.number) {
      setPassportNum(passportInfo?.number || "");
    }
    if (expiration !== passportInfo?.expiration) {
      // On first render - set input value to existing passenger passport expiry date with formatting
      if (passportInfo?.expiration.includes("-")) {
        setExpiration(
          dayjs(passportInfo?.expiration)
            .format(preferedDateFormat)
            .replace("-", "/")
        );
      } else {
        setExpiration(passportInfo?.expiration || "");
      }
    }
    if (countryOfIssue !== passportInfo?.countryOfIssue) {
      setCountryOfIssue(
        passportInfo?.countryOfIssue || brand.preferredCountryCode
      );
    }
  }, [brand, countryOfIssue, expiration, number, passportInfo]);

  const onPassportExpiryChanged = useCallback(
    (passportExpiry: string) => {
      const { year, month, day, strLength } = preferMonthDayYearFormat
        ? parseMonthDayAndYear(passportExpiry)
        : parseDayMonthAndYear(passportExpiry);

      const formattedPassportExpiry = preferMonthDayYearFormat
        ? month +
          (strLength > 2 ? "/" : "") +
          day +
          (strLength > 4 ? "/" : "") +
          year
        : day +
          (strLength > 2 ? "/" : "") +
          month +
          (strLength > 4 ? "/" : "") +
          year;

      onPassportInfoChange({
        countryOfIssue,
        number,
        expiration: formattedPassportExpiry,
      });
    },
    [countryOfIssue, number, onPassportInfoChange]
  );

  useEffect(() => {
    if (number) {
      if (/\s/.test(number)) {
        setPassportNumberError(t("noWhitespaceError"));
      } else if (!specialCharacterRegex.test(number)) {
        setPassportNumberError(t("noSpecialCharactersError"));
      } else {
        setPassportNumberError("");
      }
    }
  }, [number, setPassportNumberError, t]);

  useEffect(() => {
    if (expiration) {
      const expiryDateDayjs = getDateTimeWithFormat(
        expiration,
        preferedDateFormat
      );
      const { month, day } = preferMonthDayYearFormat
        ? parseMonthDayAndYear(expiration)
        : parseDayMonthAndYear(expiration);

      if (!expiryDateDayjs.isValid() || !expiryDateDayjs.isAfter(dayjs())) {
        setPassportExpiryError(t("enterAValidExpiry"));
      } else if (
        Number(month) !== expiryDateDayjs.month() + 1 ||
        Number(day) !== expiryDateDayjs.date()
      ) {
        setPassportExpiryError(t("enterAValidExpiry"));
      } else if (
        (returnDate &&
          expiryDateDayjs?.subtract(90, "day").isBefore(returnDate)) ||
        expiryDateDayjs?.subtract(90, "day").isBefore(departureDate)
      ) {
        setPassportExpiryError(t("passportExpires"));
      } else {
        setPassportExpiryError("");
      }
    }
  }, [departureDate, expiration, returnDate, setPassportExpiryError, t]);

  const countryOfIssueLabel = useMemo(
    () =>
      countryOptions?.find((option) => option.value === countryOfIssue)?.label,
    [countryOfIssue]
  );

  return (
    <Box
      className={clsx("passport-info-form-container", className, {
        mobile: isMobile,
      })}
    >
      <Box className="passport-info-form-description">
        <Typography variant="subtitle1" className="title">
          {title}
        </Typography>
        <Typography variant="subtitle2" className="subtitle">
          {subtitle}
        </Typography>
        {subtitle2 && (
          <Typography variant="subtitle2" className="subtitle2">
            {subtitle2}
          </Typography>
        )}
      </Box>
      <form className={clsx("passport-info-form", className)}>
        <SelectWithHalfSheet
          label={t("countryOfIssue")}
          selected={countryOfIssueLabel}
          className="passport-info-field"
          chevronSrc={Chevron}
          modalContent={
            <RadioDropdown
              dropdownLabel={t("countryOfIssue")}
              options={countryOptions}
              setOption={(value: string) => {
                setCountryOfIssue(value);
                onPassportInfoChange({
                  countryOfIssue: value,
                  number,
                  expiration,
                });
              }}
              selected={countryOfIssue}
              showDropdownContentOnly={true}
            />
          }
        />
        <InputTextField
          label={t("passportNumber")}
          value={number}
          error={!!passportNumberError}
          errorText={passportNumberError}
          onChange={(value: string) => {
            setPassportNum(value);
            onPassportInfoChange({
              countryOfIssue,
              number: value,
              expiration,
            });
          }}
        />
        <InputTextField
          label={t("expiration")}
          value={expiration}
          onChange={(value: string) => {
            setExpiration(value);
            onPassportExpiryChanged(value);
          }}
          error={!!passportExpiryError}
          errorText={passportExpiryError}
          placeholder={t("expiration")}
        />
      </form>
    </Box>
  );
};
