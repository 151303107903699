import {
  Branding,
  ChatAvailability,
  ClientName,
  ContactSupportConfig,
  FlightShopDefaultSort,
  I18nNamespace,
} from "@hopper-b2b/types";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.None,
  displaySupportNumber: "+5540200185",
  supportNumber: "40200185",
  intlSupportNumber: "551120390650",
};

export const branding: Branding = {
  default: "pt",
  contactSupport,
  merchantPayments: "",
  clientName: ClientName.NUBANK,
  currencies: [
    {
      locale: "pt-BR",
      code: "BRL",
      symbol: "R$",
      highInflationCurrency: true,
    },
  ],
  supportedLanguages: [
    { key: "pt", name: "Portuguese", label: "Portuguese" },
    {
      key: "es",
      name: "Español",
      label: "Español",
    },
    { key: "en", name: "English", label: "English" },
  ],
  adaChat: {
    answerIds: {
      delayOrMissedConnection: "6418b6f2d98bdd47409e0705",
    },
  },
  lodgingCardPriceRounding: 2,
  flightShopDefaultSort: FlightShopDefaultSort.TagsThenPrice,
  [I18nNamespace.translation]: {
    pt: {
      portalName: "Nubank com Hopper",
      urls: {
        termsAndConditions: "",
        privacyPolicy: "",
      },
      preferredCountryCode: "BR",
      preferredAreaCode: "+55",
      calendarMonthFormat: "MMMM YYYY",
      calendarDateLabelFormat: "DD MMM YY",
      timeFormat: "HH:mm",
      customDateTimeFormats: {
        tripsList: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "pt-BR",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
        hotelSummary: (date: Date) =>
          formatDateTime(date, "pt-BR", DateTimeFormatStyle.FullDate),
        calendar: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "pt-BR",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de |\.)+/g, "");
        },
        shopEditButton: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "pt-BR",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
        fareDetails: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "pt-BR",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
      },
    },
    en: {
      portalName: "Nubank with Hopper",
      urls: {
        termsAndConditions: "",
        privacyPolicy: "",
      },
      preferredCountryCode: "BR",
      preferredAreaCode: "+55",
      calendarMonthFormat: "MMMM YYYY",
      timeFormat: "HH:mm",
      customDateTimeFormats: {
        tripsList: (date: Date) =>
          formatDateTime(
            date,
            "en",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          ),
        hotelSummary: (date: Date) =>
          formatDateTime(date, "en", DateTimeFormatStyle.FullDate),
        calendar: (date: Date) =>
          formatDateTime(
            date,
            "en",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          ),
        shopEditButton: (date: Date) =>
          formatDateTime(
            date,
            "en",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          ),
        faredDetails: (date: Date) =>
          formatDateTime(
            date,
            "en",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          ),
      },
    },
    es: {
      portalName: "Nubank con Hopper",
      urls: {
        termsAndConditions: "",
        privacyPolicy: "",
      },
      supportNumber: "18000930219",
      displaySupportNumber: "1 800-093-0219",
      preferredCountryCode: "BR",
      preferredAreaCode: "+55",
      calendarMonthFormat: "MMMM YYYY",
      timeFormat: "HH:mm",
      customDateTimeFormats: {
        tripsList: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "es",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
        hotelSummary: (date: Date) =>
          formatDateTime(date, "es", DateTimeFormatStyle.FullDate),
        calendar: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "es",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
        shopEditButton: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "es",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
        fareDetails: (date: Date) => {
          const formattedDate = formatDateTime(
            date,
            "es",
            DateTimeFormatStyle.Custom({
              day: "2-digit",
              month: "short",
              year: "2-digit",
            })
          );
          return formattedDate.replace(/(de|\.)+/g, "");
        },
      },
    },
  },
};
