/* eslint-disable no-case-declarations */
import { delay, put, putResolve, select, take } from "redux-saga/effects";
import { pollQuote } from "../../../../api/v1/book/book-flow/pollQuote";
import { IStoreState } from "../../../../reducers/types";
import {
  getPaymentRequest,
  getSession,
  getTripPricing,
  getFlightBookType,
  getIsSimilarFlightsEnabled,
} from "../../reducer";
import { currentPriceFreezeSelector } from "../../../freeze/reducer/selectors";
import {
  GetPaymentPending,
  GetPaymentFailed,
  GetPaymentResultEnum,
  PaymentQuoteValidationDataSucceeded,
  PaymentQuoteResult,
  InvalidPassengers,
  PassengersResponseEnum,
  CustomerValidationDataEnum,
  PaymentPriceQuoteDataEnum,
  CallState,
  RewardsPrice,
  GetPriceFreezeResponse,
  GetPriceFreezeRequestEnum,
  FlightBookType,
  PaymentErrorEnum,
} from "@hopper-b2b/types";
import Logger from "../../../../helpers/Logger";
import {
  setPollQuoteCallStateFailure,
  setPollQuoteCallStateSuccess,
  setQuote,
  setQuotedRewardsTotal,
} from "../../actions/actions";
import {
  setPriceFreezeQuoteData,
  setPriceFreeze,
} from "../../../freeze/actions/actions";
import { actions } from "../../actions";
import { fetchSimilarFlights } from "../../../shop/actions/actions";
import {
  SET_SIMILAR_FLIGHTS_RESPONSE,
  SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
} from "../../../shop/actions/constants";
import { getPriceFreeze } from "../../../../api/v1/price-freeze/getPriceFreeze";
import { convertUsdToRewards } from "../../../../api/v1/rewards/convertUsdToRewards";
import {
  getSelectedAccountReferenceId,
  getRewardsAccounts,
} from "../../../rewards/reducer";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* pollQuoteSaga({
  agentFee,
  pollQuoteOnly,
}: actions.IPollQuote) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const apiConfig = state[flightApiConfigStoreKey];

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [
      2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000, 30000,
    ];
    let pollFailed = false;
    let index = 0;

    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const priceQuoteCheckedResponse: PaymentQuoteResult = yield pollQuote(
        sessionToken,
        apiConfig
      );

      switch (priceQuoteCheckedResponse.result) {
        case GetPaymentResultEnum.Failed:
          pollFailed = true;
          const failedResponse = priceQuoteCheckedResponse as GetPaymentFailed;
          if (failedResponse.errors.length > 0) {
            const flightBookType = getFlightBookType(state);
            switch (flightBookType) {
              case FlightBookType.PRICE_FREEZE_EXERCISE:
                const isSimilarFlightsEnabled =
                  getIsSimilarFlightsEnabled(state);

                if (isSimilarFlightsEnabled) {
                  // note: when it's on PF exercise, check for NoAvailability error code,
                  // and call /pricefreeze/similarflight/list endpoint to check for similar-flight availability
                  const noAvailabilityError = failedResponse.errors.find(
                    (error) => error.code === PaymentErrorEnum.NoAvailability
                  );

                  if (noAvailabilityError) {
                    // note: /pricefreeze/get is called on start, so this field should already be populated
                    const priceFreezeId =
                      currentPriceFreezeSelector(state)?.priceFreeze.id ?? "";

                    yield put(
                      fetchSimilarFlights({
                        id: priceFreezeId,
                        refreshPriceFreeze: false,
                      })
                    );
                    // note: wait for fetchSimilarFlights to finish
                    yield take([
                      SET_SIMILAR_FLIGHTS_RESPONSE,
                      SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
                    ]);
                  }
                }
                break;
              default:
                break;
            }

            yield putResolve(
              setPollQuoteCallStateFailure(failedResponse.errors)
            );
            return;
          } else {
            yield putResolve(setPollQuoteCallStateFailure([]));
            throw new Error(
              "Price quote checked response returned an error and the given error code is not handled."
            );
          }
        case GetPaymentResultEnum.Pending:
          const priceQuoteResponsePoll =
            priceQuoteCheckedResponse as GetPaymentPending;
          switch (priceQuoteResponsePoll.result) {
            case GetPaymentResultEnum.Failed:
              pollFailed = true;
              yield putResolve(setPollQuoteCallStateFailure([]));
              break;
            default:
              continue;
          }
          break;
        case GetPaymentResultEnum.Succeeded:
          const priceQuoteResponse =
            priceQuoteCheckedResponse as PaymentQuoteValidationDataSucceeded;
          switch (priceQuoteResponse.result) {
            case GetPaymentResultEnum.Succeeded:
              switch (priceQuoteResponse.validation.CustomerValidationData) {
                case CustomerValidationDataEnum.FlightCustomerValidationData:
                  const passengerResponse =
                    priceQuoteResponse.validation.passengerValidation;
                  switch (passengerResponse.PassengersResponse) {
                    case PassengersResponseEnum.InvalidPassengers:
                      // ! PUSH TO PASSENGER INFORMATION
                      const errors = (passengerResponse as InvalidPassengers)
                        .errors;
                      yield put(actions.setPassengersInvalid(errors));
                      yield put(
                        actions.setPassengerValidationCallStateFailure()
                      );
                      yield putResolve(setPollQuoteCallStateFailure([]));
                      break;
                    case PassengersResponseEnum.PassengersOk:
                      // ! NOTHING TO DO HERE
                      yield put(actions.setPassengersValid());
                      yield put(
                        actions.setPassengerValidationCallStateSuccess()
                      );
                      break;
                  }
                  break;
                default:
                  break;
              }

              let readyToProceedPayment = false;
              switch (priceQuoteResponse.quote.PaymentPriceQuoteData) {
                case PaymentPriceQuoteDataEnum.FlightPriceQuoteData:
                  const quote = priceQuoteResponse.quote.flightQuoteData;
                  const amountToPay =
                    quote.itinerary.sellingPricing.totalPricing.total.fiat
                      .value;
                  const estimate =
                    getTripPricing(state)?.totalPricing.total.fiat.value;
                  readyToProceedPayment = amountToPay === estimate;

                  yield putResolve(setQuote(quote));
                  const accountId = getSelectedAccountReferenceId(state);
                  const accounts = getRewardsAccounts(state) || [];

                  const flightBookType = getFlightBookType(state);

                  // yield putResolve(
                  //   setPriceFreeze({
                  //     priceFreeze,
                  //     priceFreezeCallState: CallState.Success,
                  //   })
                  // );
                  break;
                case PaymentPriceQuoteDataEnum.PriceFreezeFlightPriceQuoteData:
                  const priceFreezeQuote = priceQuoteResponse.quote.quoteData;
                  // TODO: handle readyToProceedPayment logic properly
                  readyToProceedPayment = true;

                  yield putResolve(setPriceFreezeQuoteData(priceFreezeQuote));
                  break;
                default:
                  break;
              }

              yield putResolve(setPollQuoteCallStateSuccess());
              if (readyToProceedPayment) {
                const paymentRequest = getPaymentRequest(state);
                if (paymentRequest && !pollQuoteOnly) {
                  yield put(actions.schedulePayment(paymentRequest, agentFee));
                }
              } else {
                yield put(
                  actions.setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                  })
                );
                yield put(actions.setSelectedRewardsAccountReferenceId(null));
              }
              return;
            default:
              pollFailed = true;
              yield putResolve(setPollQuoteCallStateFailure([]));
              throw new Error("Price Quote Failed");
          }
      }
      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    Logger.debug(e);
    yield putResolve(setPollQuoteCallStateFailure([]));
  }
}
