import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { HotelItinerary, HotelItinerarySummary } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LoadingFailure, Slot, StatusTag } from "@hopper-b2b/ui";

import { Box, Divider, Link, Typography } from "@material-ui/core";
import { fetchHotelReservation } from "../../../../api/v1/itinerary/fetchHotelReservation";
import { ItineraryDate } from "../ItineraryDate/component";
import { HotelCardConnectorProps } from "./container";
import "./styles.scss";
import { HotelItineraryState, TagColors } from "@hopper-b2b/types";

export const DATE_FORMAT = "ddd, MMM D";

interface IHotelCardProps
  extends HotelCardConnectorProps,
    RouteComponentProps<unknown, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  hotel: HotelItinerarySummary;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  reservationDetails?: HotelItinerary;
  fetchReservationError?: boolean;
}

export const HotelCard = ({
  history,
  hotel,
  isMobile,
  isPastTrips,
  populateTripQueryParams,
  setSelectedHotel,
  onExpandCard,
  expandedCard,
  setOpenModal,
  reservationDetails,
  fetchReservationError,
}: IHotelCardProps) => {
  const { t } = useI18nContext();
  const [fetchHotelReservationError, setFetchHotelReservationError] = useState(
    fetchReservationError
  );

  const handleHotelCardSelect = useCallback(() => {
    fetchHotelReservation(hotel.reservationBookingId)
      .then((res) => {
        populateTripQueryParams(history, {
          tripId: hotel.reservationBookingId,
        });
        setSelectedHotel(res);
      })
      .catch((error) => {
        setFetchHotelReservationError(true);
      });
  }, [history, hotel, populateTripQueryParams, setSelectedHotel]);

  const titleTag = useMemo(() => {
    const { ReservationState: reservationState } = hotel.state;
    const isCancelled = [
      HotelItineraryState.Canceled,
      HotelItineraryState.CancelationFailure,
      "Cancelled",
    ].includes(reservationState);
    if (isPastTrips) {
      return {
        label: isCancelled ? t("cancelled") : t("completedItinerary"),
        type: TagColors.GREY,
        iconName: undefined,
        tooltipCopy: undefined,
      };
    }
  }, [hotel.state, isPastTrips, t]);

  return (
    <Box className={clsx({ mobile: isMobile }, "hotel-list", "apac")}>
      {fetchHotelReservationError ? (
        <Box className="my-trips-desktop-card-load-failure">
          <LoadingFailure
            title={t("tripSearch.errorTitle")}
            message={t("tripSearch.errorMessage")}
            showImage
          />
        </Box>
      ) : (
        <Box className="my-trips-desktop-card hotel-card desktop">
          <Box className="mytrips-hotel-icon-container">
            <Slot id="mytrips-hotel-icon" />
            {!!titleTag && (
              <StatusTag className="title-status-tag" tagInfo={titleTag} />
            )}
          </Box>
          <Box className="hotel-card-icon-title">
            <Typography className="summary-label" variant="h2">
              {hotel.lodgingSummary?.name}
            </Typography>
          </Box>
          <Box
            id={hotel.reservationBookingId}
            key={hotel.reservationBookingId}
            className={clsx("hotel-trip-container", {
              mobile: isMobile,
            })}
          >
            <Box className="hotel-trip-details-content">
              <Box className="hotel-trip-dates-details">
                <ItineraryDate
                  label={t("checkin")}
                  date={hotel.checkInDate}
                  hideTime
                />
                <ItineraryDate
                  label={t("checkout")}
                  date={hotel.checkOutDate}
                  hideTime
                />
              </Box>
              <Box className="hotel-trip-metadata-details">
                <Typography className="label">{t("confirmation")}</Typography>
                <Typography className="reservation-id">
                  {hotel.reservationId}
                </Typography>
              </Box>
            </Box>
            <Divider className="hotel-trip-details-divider" />
            <Box className="hotel-links-container">
              <Link
                component="button"
                onClick={(event: React.MouseEvent) => {
                  handleHotelCardSelect();
                }}
              >
                <Typography className="hotel-view-details-link" variant="body2">
                  {t("viewDetails")}
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
