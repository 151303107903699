import {
  ChildMachineConfig,
  ParentState,
  SeatSelectionState,
} from "@checkout/types";
import { GuardTypes } from "./guards";
import { ServiceTypes } from "./services";
import { ActionTypes } from "./actions";
import { SeatEventTypes } from "./events";

export const getMachineState = (config: ChildMachineConfig) => ({
  initial: SeatSelectionState.route,
  entry: ActionTypes.setSeatMapResponse,
  states: {
    [SeatSelectionState.route]: {
      always: [
        {
          target: SeatSelectionState.loading,
          cond: GuardTypes.hasSeatMapAvailability,
        },
        {
          target: config.nextState,
        },
      ],
    },
    [SeatSelectionState.loading]: {
      invoke: {
        src: ServiceTypes.fetchSeatMapService,
        onDone: {
          actions: ActionTypes.setSeatMap,
          target: SeatSelectionState.idle,
        },
        onError: {
          actions: ActionTypes.setSeatError,
          target: config.nextState,
        },
      },
    },
    [SeatSelectionState.idle]: {
      on: {
        [SeatEventTypes.SELECT_SEATS]: {
          actions: [
            ActionTypes.selectSeat,
            ActionTypes.resetSkippedSeatSelection,
          ],
        },
        [SeatEventTypes.NEXT]: {
          actions: ActionTypes.resetEditSeatsFromReview,
          target:
            config.nextState ||
            `#${config.parentMachineId}.${ParentState.review}`,
        },
        [SeatEventTypes.SKIP_SEAT_SELECTION]: {
          actions: [
            ActionTypes.setSkipSeatSelection,
            ActionTypes.resetSelectedSeats,
          ],
        },
        [SeatEventTypes.PREVIOUS]:
          config.previousState ||
          `#${config.parentMachineId}.${ParentState.passengerInformation}`,
      },
    },
    [SeatSelectionState.error]: {
      on: {
        [SeatEventTypes.NEXT]: config.nextState,
        [SeatEventTypes.CLEAR_SEAT_ERROR]: {
          actions: ActionTypes.clearSeatError,
        },
      },
    },
  },

  always: {
    actions: ActionTypes.setSeatsVisited,
    cond: GuardTypes.notVisitedSeats,
  },
});
