import { Box, Button, Typography } from "@material-ui/core";
import { AlgomerchTag } from "@hopper-b2b/types";
import clsx from "clsx";

import "./styles.scss";
import AirlineIcon from "../../assets/icons/flight/algomerch_airline.svg";
import FareClassIcon from "../../assets/icons/flight/algomerch_fareclass.svg";
import PriceIcon from "../../assets/icons/flight/algomerch_price.svg";
import DurationIcon from "../../assets/icons/flight/algomerch_duration.svg";
import StopsIcon from "../../assets/icons/flight/algomerch_stops.svg";
import CloseButtonIcon from "../../assets/icons/common/close-button.svg";
import { MobilePopoverCard } from "../MobilePopoverCard";
import { DesktopPopupModal } from "../DesktopPopupModal";
import { ActionLink } from "../ActionLink";

export interface IAlgomerchInfoProps {
  className?: string;
  scoreFactorTitles: ScoreFactorTitles;
  algomerchTitles: AlgomerchTitles;
  selectedCategory: AlgomerchTag;
  setSelectedCategory: (RecommendationLabel: AlgomerchTag) => void;
  onClick?: () => void;
}

export interface ScoreFactorTitles {
  airline: string;
  fareClass: string;
  price: string;
  duration: string;
  stops: string;
}

export type AlgomerchTitles = {
  [key in AlgomerchTag]: AlgomerchTitleType;
};

type AlgomerchTitleType = {
  title: string;
  description: string;
  factors: string;
};

const mapTagClass: { [key in AlgomerchTag]: string } = {
  BestFlight: "best-flight",
  Cheapest: "cheapest",
  BestQuality: "best-quality",
  Fastest: "fastest",
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AlgomerchInfo = ({
  className,
  scoreFactorTitles,
  algomerchTitles,
  selectedCategory,
  setSelectedCategory,
  onClick,
}: IAlgomerchInfoProps) => {
  const onAlgomerchClick = (category: AlgomerchTag) => {
    setSelectedCategory(category);
  };

  const renderAlgomerchInfo = () => {
    return (
      <Box className="algomerch-categories">
        <Box className="algomerch-wrapper">
          <Box
            className={clsx(
              mapTagClass[selectedCategory],
              "airline",
              "category"
            )}
          >
            <Typography className="title" variant={"subtitle2"}>
              <img src={AirlineIcon} alt="Airline Icon" />
              {scoreFactorTitles.airline}
            </Typography>
          </Box>
        </Box>
        <Box className="algomerch-wrapper">
          <Box
            className={clsx(
              mapTagClass[selectedCategory],
              "fare-class",
              "category"
            )}
          >
            <Typography className="title" variant="subtitle2">
              <img src={FareClassIcon} alt="Fare Class Icon" />
              {scoreFactorTitles.fareClass}
            </Typography>
          </Box>
        </Box>
        <Box className="algomerch-wrapper">
          <Box
            className={clsx(mapTagClass[selectedCategory], "price", "category")}
          >
            <Typography className="title" variant="subtitle2">
              <img src={PriceIcon} alt="Price Icon" />
              {scoreFactorTitles.price}
            </Typography>
          </Box>
        </Box>
        <Box className="algomerch-wrapper">
          <Box
            className={clsx(
              mapTagClass[selectedCategory],
              "duration",
              "category"
            )}
          >
            <Typography className="title" variant="subtitle2">
              <img src={DurationIcon} alt="Duration Icon" />
              {scoreFactorTitles.duration}
            </Typography>
          </Box>
        </Box>
        <Box className="algomerch-wrapper">
          <Box
            className={clsx(mapTagClass[selectedCategory], "stops", "category")}
          >
            <Typography className="title" variant="subtitle2">
              <img src={StopsIcon} alt="Stops Icon" />
              {scoreFactorTitles.stops}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={clsx(className, "algomerch-info")} onClick={onClick}>
      {renderAlgomerchInfo()}
      <Box className="algomerch-buttons">
        <Button
          className={clsx("algomerch-button", {
            selected: selectedCategory === AlgomerchTag.Cheapest,
          })}
          onClick={() => onAlgomerchClick(AlgomerchTag.Cheapest)}
        >
          {algomerchTitles[AlgomerchTag.Cheapest].title}
        </Button>
        <Button
          className={clsx("algomerch-button", {
            selected: selectedCategory === AlgomerchTag.BestFlight,
          })}
          onClick={() => onAlgomerchClick(AlgomerchTag.BestFlight)}
        >
          {algomerchTitles[AlgomerchTag.BestFlight].title}
        </Button>
        <Button
          className={clsx("algomerch-button", {
            selected: selectedCategory === AlgomerchTag.Fastest,
          })}
          onClick={() => onAlgomerchClick(AlgomerchTag.Fastest)}
        >
          {algomerchTitles[AlgomerchTag.Fastest].title}
        </Button>
        <Button
          className={clsx("algomerch-button", {
            selected: selectedCategory === AlgomerchTag.BestQuality,
          })}
          onClick={() => onAlgomerchClick(AlgomerchTag.BestQuality)}
        >
          {algomerchTitles[AlgomerchTag.BestQuality].title}
        </Button>
      </Box>
      <Box className="footer-description">
        <Typography className="subtitle" variant="subtitle2">
          <span
            dangerouslySetInnerHTML={{
              __html: algomerchTitles[selectedCategory].description,
            }}
          ></span>
        </Typography>
        <Typography className="subtitle" variant="subtitle2">
          <span
            dangerouslySetInnerHTML={{
              __html: algomerchTitles[selectedCategory].factors,
            }}
          ></span>
        </Typography>
      </Box>
    </Box>
  );
};

interface IAlgomerchModalProps extends IAlgomerchInfoProps {
  openModal: boolean;
  onClose: () => void;
  algomerchInfoTitle?: string;
  isMobile?: boolean;
}

export const AlgomerchModal = ({
  className,
  algomerchInfoTitle = "How are flights scored?",
  scoreFactorTitles,
  algomerchTitles,
  selectedCategory,
  openModal,
  isMobile,
  setSelectedCategory,
  onClick,
  onClose,
}: IAlgomerchModalProps) => {
  const renderAlgomerchInfo = () => {
    return (
      <>
        <Box className="algomerch-info-title-container">
          <Typography className="algomerch-info-title" variant="h6">
            {algomerchInfoTitle}
          </Typography>
        </Box>
        <AlgomerchInfo
          className={className}
          scoreFactorTitles={scoreFactorTitles}
          algomerchTitles={algomerchTitles}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onClick={onClick}
        />
      </>
    );
  };

  return (
    <>
      {isMobile && (
        <MobilePopoverCard
          className={clsx("mobile-algomerch-modal-root", className)}
          open={openModal}
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<img src={CloseButtonIcon} alt="close button icon" />}
              label="Close"
              onClick={onClose}
            />
          }
          onClose={onClose}
        >
          {renderAlgomerchInfo()}
        </MobilePopoverCard>
      )}
      {!isMobile && (
        <DesktopPopupModal
          className={clsx("desktop-algomerch-modal-root", className)}
          open={openModal}
          onClose={onClose}
          invisibleBackdrop={false}
        >
          {renderAlgomerchInfo()}
        </DesktopPopupModal>
      )}
    </>
  );
};
