import { useI18nContext } from "@hopper-b2b/i18n";
import { RadioDropdown } from "@hopper-b2b/ui";
import {
  hasHTSConnectSupport,
  useEnableRemoveRecommendSort,
} from "@hopper-b2b/utilities";
import { useContext } from "react";
import { ClientContext } from "../../../../../../App";
import { SortOption } from "../../../../reducer";
import { SortOptionSelectionConnectorProps } from "./container";

interface ISortOptionSelectionProps extends SortOptionSelectionConnectorProps {
  showDropdownContentOnly?: boolean;
}

interface ISortOptions {
  label: string;
  shortLabel: string;
  // value - Does not need i18n, used in the radio group only
  value: SortOption;
}

export const SortOptionSelection = (props: ISortOptionSelectionProps) => {
  const { sortOption, setSortOption, showDropdownContentOnly } = props;

  const featureFlag = useContext(ClientContext).featureFlag;

  const enableRemoveRecommendSort = useEnableRemoveRecommendSort();
  const isHTSConnect = hasHTSConnectSupport();
  const { t } = useI18nContext();

  const sortOptions: ISortOptions[] = [
    {
      label: t("sortOption.recommended"),
      shortLabel: t("sortOption.recommended"),
      value: featureFlag?.enableJetBlueFlightSort
        ? "stopAndDepartureTime"
        : "fareScore",
    },
    {
      label: t("sortOption.price"),
      shortLabel: t("sortOption.priceShortened"),
      value: "price",
    },
    {
      label: t("sortOption.depart"),
      shortLabel: t("depart"),
      value: "departureTime",
    },
    {
      label: t("sortOption.arrive"),
      shortLabel: t("sortOption.arriveShortened"),
      value: "arrivalTime",
    },
    {
      label: t("sortOption.stops"),
      shortLabel: t("sortOption.stopsShortened"),
      value: "stops",
    },
    {
      label: t("sortOption.duration"),
      shortLabel: t("sortOption.durationShortened"),
      value: "duration",
    },
  ];

  const filteredOptions = enableRemoveRecommendSort
    ? sortOptions.filter(
        (option) =>
          !["stopAndDepartureTime", "fareScore"].includes(option.value)
      )
    : sortOptions;

  const getDropdownLabel = (): string => {
    const label =
      filteredOptions.find((option) => option.value === sortOption)
        ?.shortLabel || "";
    return isHTSConnect ? `${t("searchFilter.sortHeader")} ${label}` : label;
  };

  return (
    <RadioDropdown
      dropdownLabel={getDropdownLabel()}
      options={filteredOptions.map((option) => {
        return {
          value: option.value,
          label: option.label,
        };
      })}
      setOption={(option) => setSortOption(option as SortOption)}
      selected={sortOption}
      groupAriaLabel={t("chooseSortOption")}
      buttonAriaLabel={t("changeSortOption")}
      popoverClassName="b2b"
      showDropdownContentOnly={showDropdownContentOnly}
    />
  );
};
