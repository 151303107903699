import { MouseEvent, useCallback } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import {
  AlgomerchTag,
  FareDetails,
  FareScore,
  FlightTags,
  getTags,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import clsx from "clsx";
import { FlightsFareSlice } from "@b2bportal/air-shopping-api";
import {
  I18nMarkup,
  Trans,
  useI18nContext,
  useI18nCurrency,
} from "@hopper-b2b/i18n";
import styles from "./styles.module.scss";

interface IFareDetailsItemProps {
  fare: FareDetails;
  index: number;
  className?: string;
  isOutgoing: boolean;
  inExchange?: boolean;
  isMobile?: boolean;
  selectedFareDetails?: FareDetails;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
  onFareClicked: (
    e: Event | MouseEvent,
    fare: FareDetails,
    index: number
  ) => void;
}

const FareDetailsItem = ({
  fare,
  index,
  className,
  isOutgoing,
  inExchange,
  isMobile,
  selectedFareDetails,
  getFlightShopFareSlice,
  onFareClicked,
}: IFareDetailsItemProps) => {
  const { t } = useI18nContext();
  const { formatFiatCurrency } = useI18nCurrency();
  const fareIndex = fare.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );
  const fareSlice = fare.slices[fareIndex];
  const isOneWay = !fare.slices.some((slice) => !slice.outgoing);

  const shopFareSlice = getFlightShopFareSlice(fare.id);

  const getTagText = (fareScore: FareScore | FlightTags) => {
    const tagLimit = 2;
    const tagSeperator = ", ";
    const tags = getTags(fareScore);
    const filteredTags = tags
      .filter((tag: AlgomerchTag) => tag !== AlgomerchTag.Fastest)
      .map((tag: AlgomerchTag) => t(mapI18nAlgomerchText[tag]))
      .slice(0, tagLimit);
    return filteredTags.join(tagSeperator);
  };
  const tagText = shopFareSlice ? getTagText(shopFareSlice.tags) : "";
  const tagLength =
    shopFareSlice && shopFareSlice.tags
      ? getTagText(shopFareSlice.tags).split(",").length
      : 1;

  const handleFareSelect = useCallback(
    (e: Event | MouseEvent) => {
      onFareClicked(e, fare, index);
    },
    [fare, index, onFareClicked]
  );

  const {
    baseAmount: {
      fiat: { currencyCode: baseCurr },
    },
    total,
  } = fare.paxPricings[0].pricing;
  let totalPriceString = formatFiatCurrency({
    currencyCode: baseCurr,
    value: 0,
  });

  if (total) {
    const {
      fiat: { currencyCode, currencySymbol, value },
    } = total;

    totalPriceString = formatFiatCurrency(
      { currencyCode, value },
      { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    );

    if (inExchange) {
      // exchange shop returns prices as deltas from original flight
      if (value >= 0) totalPriceString = `+${totalPriceString}`;
      else
        totalPriceString = t("exchangeable.shop.minDelta", {
          currencySymbol,
        });
    }
  }

  return (
    <Box
      key={`trip-fare-details-${index}`}
      className={clsx(
        `trip-fare-details-${index}`,
        "trip-fare-details",
        styles.fareDetailItem
      )}
    >
      <Box className="fare-details-fare-class-container">
        <Button
          className={clsx(
            selectedFareDetails?.id === fare.id
              ? "selected-fare-button"
              : "select-fare-button",
            "mini",
            className
          )}
          onClick={handleFareSelect}
          variant="contained"
        >
          <div>
            <div className="fare-class">
              {`${fareSlice.fareShelf?.shortBrandName}`}
            </div>
            <Box
              className={clsx("fare-details-title", {
                "with-tag": tagText,
                "two-tags": tagLength === 2,
                mobile: isMobile,
              })}
            >
              <Typography variant="body1" className="fare-price">
                <Trans
                  i18nKey={
                    isOneWay
                      ? "flightCard.pricePerPerson"
                      : "flightCard.pricePerRoundTripPerPerson"
                  }
                  components={[<strong />]}
                  values={{
                    price: totalPriceString,
                  }}
                />
              </Typography>
            </Box>
          </div>
        </Button>
      </Box>
    </Box>
  );
};

export default FareDetailsItem;
