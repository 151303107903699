import { useI18nContext } from "@hopper-b2b/i18n";
import { useUberBridge } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { ReactElement, useCallback, useEffect } from "react";

import { SEAT_MAP_OPTIONS, STYLE_STRING } from "./constants";
import Frame from "./Frame";
import { ISeatSelectionDialogInternalProps } from "./types";

const CLOSE_SEAT_SELECTION = "close_seat_selection";

const Page = ({
  seatMapHtml,
  setSelectedSeats,
  segmentIndex,
  seatMapOptions = SEAT_MAP_OPTIONS,
  styleString = STYLE_STRING,
  setOpen,
  onEditMode,
}: ISeatSelectionDialogInternalProps): ReactElement<ISeatSelectionDialogInternalProps> => {
  const { t } = useI18nContext();
  const { setHeader } = useUberBridge();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    const title = t("seats.chooseSeats");
    try {
      setHeader({
        title: title,
        customButtons: [
          {
            icon: "X",
            action: {
              name: CLOSE_SEAT_SELECTION,
              callback: closeModal,
            },
          },
        ],
      });
    } catch {
      //nothing to do
    }
  }, [closeModal, setHeader, t]);

  return (
    <div className={clsx("seat-map-wrapper-page")}>
      <Frame
        seatMapHtml={seatMapHtml}
        segmentIndex={segmentIndex}
        onEditMode={onEditMode}
        styleString={styleString}
        seatMapOptions={seatMapOptions}
        setOpen={setOpen}
        setSelectedSeats={setSelectedSeats}
      />
    </div>
  );
};

export default Page;
