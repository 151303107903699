import { RadioDropdown } from "@hopper-b2b/ui";
import { RadioOption } from "@hopper-b2b/types";
import Chevron from "../../../assets/client/chevron.svg";
import { SelectWithHalfSheet } from "../../SelectWithHalfSheet";
import "./styles.scss";

export interface AddTravelerFormSelectProps {
  options: RadioOption[];
  label: string;
  setOption: (option: RadioOption["value"]) => void;
  selected: RadioOption | null;
  className: string;
  error?: string;
  required?: boolean;
}

const AddTravelerFormSelect = ({
  options,
  label,
  setOption,
  selected,
  className,
  error,
  required,
}: AddTravelerFormSelectProps) => (
  <SelectWithHalfSheet
    label={label}
    selected={selected?.value ? (selected.label as string) : null}
    className={className}
    chevronSrc={Chevron}
    error={error}
    required={required}
    modalContent={
      <RadioDropdown
        dropdownLabel={label}
        options={options}
        setOption={setOption}
        selected={selected?.value}
        showDropdownContentOnly={true}
      />
    }
  />
);

export default AddTravelerFormSelect;
