import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  useCheckoutStateSelector as useSelector,
  CartSelectors,
  useCheckoutSend,
} from "@hopper-b2b/checkout";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, MobileFloatingSection } from "@hopper-b2b/ui";

import {
  getCreditPaymentMethod,
  getDebitPaymentMethod,
  getMaximumCreditInstallment,
  getSelectedPaymentMethod,
  getDestinationCityName,
} from "../../../../states/payments/selectors";
import { PaymentEvent } from "../../../../states";
import { ReactComponent as ArrowBack } from "../../../../../assets/client/arrow-back.svg";
import { ReactComponent as NuPayLogo } from "../../../../../assets/client/nupay-logo.svg";
import { ReactComponent as Warning } from "../../../../../assets/client/warning-outline.svg";
import {
  DebitPaymentMethod,
  NubankPaymentMethod,
  PaymentMethodsResponse,
} from "../../../../states/payments/api/getPaymentMethods";
import { TermsAndConditionsContext } from "../../../../../components/TermsAndConditionsWrapper";

import styles from "./styles.module.scss";
import { RadioListItem } from "../../../../../components/RadioListItem";
import { ConfirmPaymentModal } from "../ConfirmPaymentModal";
import { trackEvent } from "@hopper-b2b/api";
import { CHOOSE_PAYMENT } from "@hopper-b2b/types";
import clsx from "clsx";

export const PaymentMethodSelect = () => {
  const { t, formatFiatCurrency } = useI18nContext();
  const { setOpenModal: setTermsModal } = useContext(TermsAndConditionsContext);

  const history = useHistory();

  const send = useCheckoutSend();
  const balance = useSelector(CartSelectors.getRemainingBalance);
  // TODO: Find a better way to do the below
  const selectedCityName = useSelector(getDestinationCityName);
  const creditPaymentMethod = useSelector(getCreditPaymentMethod);
  const debitPaymentMethod = useSelector(getDebitPaymentMethod);
  const maxCreditInstallment = useSelector(getMaximumCreditInstallment);
  const selectedPayment = useSelector(getSelectedPaymentMethod);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] =
    useState<PaymentMethodsResponse | null>(selectedPayment || null);

  const handleContinue = () => {
    const isCreditSelected =
      selectedOption?.paymentMethodType === NubankPaymentMethod.NuCredit;
    const isDebitSelected =
      selectedOption?.paymentMethodType === NubankPaymentMethod.NuDebit;

    trackEvent({
      eventName: CHOOSE_PAYMENT,
      properties: { payment_method: selectedOption?.paymentMethodType },
    });

    if (isCreditSelected) {
      send({ type: PaymentEvent.SELECT_CREDIT, creditPayment: selectedOption });
    }
    if (isDebitSelected) {
      send({
        type: PaymentEvent.SELECT_DEBIT,
        debitPayment: selectedOption as DebitPaymentMethod,
      });
      setOpenModal(true);
    }
  };

  useEffect(() => {
    trackEvent({
      eventName: "viewed_banner",
      properties: {
        banner_name: "nubank_uv_mastercard_warning",
        screen: "nupay_selection",
      },
    });
  }, []);

  const creditRowLabel = useMemo(() => {
    if (!creditPaymentMethod) {
      return (
        <>
          <span className="label">{t("nupay.invalidCreditLabel")}</span>
          <br />
          <span className="label-subheader">
            {t("nupay.invalidCreditSubheader")}
          </span>
        </>
      );
    }
    return t("nupay.paymentSelectCredit", {
      installments: maxCreditInstallment,
    });
  }, [creditPaymentMethod, maxCreditInstallment, t]);

  const debitRowLabel = useMemo(() => {
    if (!debitPaymentMethod) {
      return (
        <>
          <span className="label">{t("nupay.paymentSelectDebit")}</span>
          <br />
          <span className="label-subheader">
            {t("nupay.invalidDebitSubheader")}
          </span>
        </>
      );
    }
    return t("nupay.paymentSelectDebit");
  }, [debitPaymentMethod, t]);

  // Format strings for city labels with multiple airports
  // London, United Kingdom (6 Airports) -> London, United Kingdom
  const cityNameRegex = /(.*)\(.*\)/gm;
  const formatDestinationCity = (label: string) =>
    label && cityNameRegex.test(label)
      ? label.replace(cityNameRegex, "$1").trim()
      : label;

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={styles.paymentMethodSelect}>
        <div className="nupay-header">
          <ButtonWrap className="back-button" onClick={handleGoBack}>
            <ArrowBack className="arrow-back-icon" />
          </ButtonWrap>
          <NuPayLogo className="nupay-logo" />
        </div>
        <div className="payment-method-content">
          <div className="title-container">
            <h1 className="header">{t("nupay.paymentSelectHeader")}</h1>
            <h2 className="header-price">
              {balance ? formatFiatCurrency(balance?.fiat) : "R$ 0"}
            </h2>
            <p>
              <I18nMarkup
                tKey={"nupay.paymentSelectSubtitle"}
                replacements={{
                  destination: formatDestinationCity(selectedCityName),
                }}
              />
            </p>
          </div>
          <div className="option-list">
            {creditPaymentMethod ? (
              <RadioListItem
                isSelected={
                  selectedOption?.paymentMethodType ===
                  NubankPaymentMethod.NuCredit
                }
                value={creditPaymentMethod}
                onSelect={setSelectedOption}
                isDisabled={!creditPaymentMethod}
                id={"credit-list-item"}
                label={creditRowLabel}
              />
            ) : null}
            {debitPaymentMethod ? (
              <RadioListItem
                isSelected={
                  selectedOption?.paymentMethodType ===
                  NubankPaymentMethod.NuDebit
                }
                value={debitPaymentMethod}
                onSelect={setSelectedOption}
                isDisabled={!debitPaymentMethod}
                id={"debit-list-item"}
                label={debitRowLabel}
              />
            ) : null}
          </div>
        </div>
        <div
          className={clsx(
            "nupay-warning-insurance-container",
            selectedOption ? "selected" : null
          )}
        >
          <div className="nupay-card">
            <div className="nupay-card-icon-container">
              <Warning className="nupay-card-icon" />
            </div>
            <div className="nupay-card-content">
              <p className="subtitle">
                {t("nupay.mastercardBlackCoverageSubtitle")}
              </p>
              <p className="title">{t("nupay.mastercardBlackCoverage")}</p>
              <p className="description">
                {t("nupay.mastercardBlackCoverageDescription")}
              </p>
            </div>
          </div>
        </div>
        <MobileFloatingSection
          primaryButton={{
            children:
              selectedOption?.paymentMethodType === NubankPaymentMethod.NuCredit
                ? t("nupay.paymentSelectCreditButton")
                : t("nupay.confirmPaymentButton"),
            onClick: handleContinue,
            disabled: !selectedOption,
          }}
          description={
            selectedOption ? (
              <p className="floating-description">
                {selectedOption?.paymentMethodType ===
                NubankPaymentMethod.NuCredit ? (
                  t("nupay.paymentSelectCreditDisclaimer")
                ) : (
                  <Trans
                    i18nKey="nupay.termsAndConditionsDisclaimer"
                    components={[
                      <ButtonWrap
                        className="payments-terms-link"
                        onClick={() => setTermsModal(true)}
                      />,
                    ]}
                  />
                )}
              </p>
            ) : null
          }
        />
      </div>
      <ConfirmPaymentModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  );
};
