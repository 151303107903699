import {
  CalendarBuckets,
  IdEnum,
  Suggestion,
  TripFilterEnum,
  TripType,
} from "@b2bportal/air-shopping-api";
import { fetchCalendar } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IMonthBucket,
  IPassengerCounts,
  TripCategory,
} from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import { PATH_FLIGHTS_SHOP, PATH_HOME } from "@hopper-b2b/utilities";
import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import LocationSvg from "../../../assets/icons/Location.svg";
import { useIsCalendarPredictionEnabled } from "../../context";
import { transformDateBuckets } from "../../utils/dateBuckets";
import { flightShopQueryBuilder } from "../../utils/flightShopQueryBuilder";
import { CalendarPickerButton } from "../common/CalendarPickerButton";
import { FlightLocationAutoComplete } from "./FlightLocationAutocomplete";
import { TravelerPicker } from "./TravelerPicker/TravelerPicker";
import { TripCategoryPicker } from "./TripCategoryPicker";

const initialPaxCount: IPassengerCounts = {
  adultsCount: 1,
  childrenCount: 0,
  infantsInSeatCount: 0,
  infantsOnLapCount: 0,
};

export type FlightSearchFormProps = {
  tripCategory?: TripCategory;
  origin?: Suggestion;
  destination?: Suggestion;
  departureDate?: string;
  returnDate?: string;
  paxCount?: IPassengerCounts;
};

export interface IIcons {
  location?: string;
  originIcon?: string;
  destinationIcon?: string;
  endIcon?: string;
}

export type FlightSearchFormHandlers = {
  onDepDateChange?: (date: Date) => void;
  onDestChange?: (dest: Suggestion) => void;
  onOriginChange?: (origin: Suggestion) => void;
  onPaxChange?: (pax: IPassengerCounts) => void;
  onRetDateChange?: (date: Date) => void;
  onTripCatChange?: (tripCat: TripCategory) => void;
};

export type FlightSearchProps = {
  changeHandlers?: FlightSearchFormHandlers;
  icons?: IIcons;
  disabled?: boolean;
  hidePassengerPicker?: boolean;
  hideTripCategoryToggle?: boolean;
  enableCalendarPrediction?: boolean;
  initialValues?: FlightSearchFormProps;
  lgDirection?: "row" | "column";
  onOpen?: () => void;
  onSearch?: (
    searchUrl: string,
    searchFormProps: FlightSearchFormProps
  ) => void;
  openDatesModal?: boolean;
  onSetOpenCalendarModal?: (value: boolean) => void;
  dateFormat?: string;
};

export const FlightSearch = ({
  changeHandlers,
  disabled,
  hidePassengerPicker,
  hideTripCategoryToggle,
  enableCalendarPrediction: enableCalendarPredictionProps,
  initialValues,
  lgDirection = "row",
  onOpen,
  onSearch,
  icons,
  openDatesModal,
  onSetOpenCalendarModal,
  dateFormat,
}: FlightSearchProps) => {
  const { t } = useI18nContext();
  const enableCalendarPredictionFlag = useIsCalendarPredictionEnabled();

  const enableCalendarPrediction =
    enableCalendarPredictionProps ?? enableCalendarPredictionFlag;

  const [tripCategory, setTripCategory] = useState(
    initialValues?.tripCategory ?? TripCategory.ROUND_TRIP
  );

  const [origin, setOrigin] = useState<Suggestion>(initialValues?.origin);
  const [destination, setDestination] = useState<Suggestion>(
    initialValues?.destination
  );

  const [paxCount, setPaxCount] = useState<IPassengerCounts>({
    adultsCount:
      initialValues?.paxCount?.adultsCount ?? initialPaxCount.adultsCount,
    childrenCount:
      initialValues?.paxCount?.childrenCount ?? initialPaxCount.childrenCount,
    infantsInSeatCount:
      initialValues?.paxCount?.infantsInSeatCount ??
      initialPaxCount.infantsInSeatCount,
    infantsOnLapCount:
      initialValues?.paxCount?.infantsOnLapCount ??
      initialPaxCount.infantsOnLapCount,
  });

  const [departureDate, setDepartureDate] = useState<string>(
    initialValues?.departureDate ?? null
  );
  const [returnDate, setReturnDate] = useState<string>(
    initialValues?.returnDate ?? null
  );

  const [monthsBucket, setMonthsBucket] = useState<IMonthBucket[]>();
  const [priceBucket, setPriceBucket] = useState<string[]>([]);

  const isRow = lgDirection === "row";

  const originCode = useMemo(
    () => (origin?.id?.Id === IdEnum.Flight ? origin?.id?.code : undefined),
    [origin]
  );
  const destinationCode = useMemo(
    () =>
      destination?.id?.Id === IdEnum.Flight ? destination?.id?.code : undefined,
    [destination]
  );

  const isSearchReady = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? originCode?.code && destinationCode?.code && departureDate
      : originCode?.code &&
          destinationCode?.code &&
          departureDate &&
          returnDate;
  }, [departureDate, destinationCode, originCode, returnDate, tripCategory]);

  const headerTitle = useMemo(
    () =>
      tripCategory === TripCategory.ONE_WAY
        ? t("searchControl.calendarOneWay")
        : t("searchControl.calendarRoundTrip"),
    [t, tripCategory]
  );

  const handleDepartureDate = useCallback(
    (date: Date) => {
      if (date) {
        setDepartureDate(date.toString());
        if (returnDate) {
          setReturnDate(undefined);
        }
      }

      if (changeHandlers?.onDepDateChange) {
        changeHandlers.onDepDateChange(date);
      }
    },
    [changeHandlers, returnDate]
  );

  const handleDestinationChange = useCallback(
    (dest: Suggestion) => {
      setDestination(dest);

      if (changeHandlers?.onDestChange) {
        changeHandlers.onDestChange(dest);
      }
    },
    [changeHandlers]
  );

  const handleOriginChange = useCallback(
    (origin: Suggestion) => {
      setOrigin(origin);

      if (changeHandlers?.onOriginChange) {
        changeHandlers.onOriginChange(origin);
      }
    },
    [changeHandlers]
  );

  const handleReturnDate = useCallback(
    (date: Date) => {
      if (date) {
        setReturnDate(date.toString());
      }

      if (changeHandlers?.onRetDateChange) {
        changeHandlers.onRetDateChange(date);
      }
    },
    [changeHandlers]
  );

  const updatePaxCount = useCallback(
    (guestCount: IPassengerCounts) => {
      setPaxCount(guestCount);

      if (changeHandlers?.onPaxChange) {
        changeHandlers.onPaxChange(guestCount);
      }
    },
    [changeHandlers]
  );

  const updateTripCategory = useCallback(
    (tripCategory: TripCategory) => {
      setTripCategory(tripCategory);
      if (tripCategory === TripCategory.ONE_WAY && returnDate) {
        setReturnDate(undefined);
      }
      if (changeHandlers?.onTripCatChange) {
        changeHandlers.onTripCatChange(tripCategory);
      }
    },
    [changeHandlers, returnDate]
  );

  useEffect(() => {
    const updateCalendarBuckets = async () => {
      const req: CalendarBuckets = {
        route: {
          origin: originCode,
          destination: destinationCode,
        },
        tripType:
          tripCategory === TripCategory.ONE_WAY
            ? TripType.one_way
            : TripType.round_trip,
        filter: {
          TripFilter: TripFilterEnum.NoFilter,
        },
      };
      const res = await fetchCalendar(req);

      const monthBuckets = transformDateBuckets(res.departureDateBuckets);
      const priceBuckets = res.departureDateBuckets.reduce((acc, bucket) => {
        acc.push(bucket.legend);
        return acc;
      }, []);

      setPriceBucket(priceBuckets);
      setMonthsBucket(monthBuckets);
    };

    if (enableCalendarPrediction && originCode && destinationCode) {
      updateCalendarBuckets();
    }
  }, [originCode, destinationCode, tripCategory, enableCalendarPrediction]);

  const searchUrl = useMemo(() => {
    const shopQuery = flightShopQueryBuilder({
      origin: originCode?.code,
      destination: destinationCode?.code,
      departureDate,
      returnDate,
      isOneWay: tripCategory === TripCategory.ONE_WAY,
      paxCount,
    });
    return `${PATH_FLIGHTS_SHOP}?${shopQuery}`;
  }, [
    departureDate,
    destinationCode,
    originCode,
    paxCount,
    returnDate,
    tripCategory,
  ]);

  const handleSearch = useCallback(() => {
    const searchFormProps: FlightSearchFormProps = {
      tripCategory,
      destination,
      origin,
      departureDate,
      returnDate,
      paxCount,
    };
    onSearch
      ? onSearch(searchUrl, searchFormProps)
      : (window.location.href = searchUrl);
  }, [
    departureDate,
    destination,
    onSearch,
    origin,
    paxCount,
    returnDate,
    searchUrl,
    tripCategory,
  ]);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className="flight-search-controls"
    >
      {hidePassengerPicker && hideTripCategoryToggle ? null : (
        <Grid item>
          <Grid container direction="row" spacing={2}>
            {hidePassengerPicker ? null : (
              <Grid item xs={12} sm="auto">
                <TravelerPicker
                  paxCount={paxCount}
                  disabled={disabled}
                  updatePaxCount={updatePaxCount}
                />
              </Grid>
            )}
            {hideTripCategoryToggle ? null : (
              <Grid item xs={12} sm="auto">
                <TripCategoryPicker
                  tripCategory={tripCategory}
                  disabled={disabled}
                  onChange={updateTripCategory}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs>
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12} sm={6} lg={isRow ? 3 : 6}>
            <FlightLocationAutoComplete
              id="origin"
              defaultValue={origin}
              label={t?.("searchControl.whereFrom")}
              onChange={handleOriginChange}
              onOpen={onOpen}
              disabled={disabled}
              endIcon={icons?.endIcon}
              icon={
                <img
                  src={icons?.originIcon || icons?.location || LocationSvg}
                  alt=""
                  className="autocomplete-start-icon"
                />
              }
              additionalSearchControl={{
                activeControl: "origin",
                destination: destinationCode?.code || undefined,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={isRow ? 3 : 6}>
            <FlightLocationAutoComplete
              id="destination"
              defaultValue={destination}
              label={t?.("searchControl.whereTo")}
              onChange={handleDestinationChange}
              onOpen={onOpen}
              disabled={disabled}
              endIcon={icons?.endIcon}
              icon={
                <img
                  src={icons?.destinationIcon || icons?.location || LocationSvg}
                  alt=""
                  className="autocomplete-start-icon"
                />
              }
              additionalSearchControl={{
                activeControl: "destination",
                origin: originCode?.code || undefined,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} lg={isRow ? 4 : 9}>
            <CalendarPickerButton
              returnDate={returnDate ? new Date(returnDate) : null}
              departureDate={departureDate ? new Date(departureDate) : null}
              tripCategory={tripCategory}
              setDepartureDate={handleDepartureDate}
              setReturnDate={handleReturnDate}
              trackingProperties={{
                origin: origin?.label || "",
                destination: destination?.label || "",
                tripCategory: tripCategory,
              }}
              months={monthsBucket}
              prices={priceBucket}
              headerTitle={headerTitle}
              disabled={disabled}
              openDatesModal={openDatesModal}
              onSetOpenCalendarModal={onSetOpenCalendarModal}
              dateFormat={dateFormat}
            />
          </Grid>

          <Grid item xs={12} sm={3} lg={isRow ? 2 : 3}>
            <ActionButton
              onClick={handleSearch}
              className="search-flight"
              fullWidth
              disabled={disabled || !isSearchReady}
              size="large"
              message={t?.("searchButton")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
