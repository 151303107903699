import {
  ChildMachineConfig,
  ParentState,
  PassengerInformationChildState,
  PassengerInformationState,
} from "../../../index";
import { ServiceTypes } from "./services";
import { ActionTypes } from "./actions";
import { PassengerEventTypes } from "./events";
import { GuardTypes } from "./guards";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.passengerInformation,
  initial: PassengerInformationState.loading,
  states: {
    [PassengerInformationState.loading]: {
      initial: PassengerInformationChildState.fetch,
      states: {
        [PassengerInformationChildState.fetch]: {
          invoke: {
            src: ServiceTypes.fetchUserPassengersService,
            onDone: {
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.loading}.${PassengerInformationChildState.route}`,
              actions: [
                ActionTypes.setUserPassengers,
                ActionTypes.setSingleDefaultAdult,
              ],
            },
            onError: {
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.error}`,
              actions: ActionTypes.setPassengersError,
            },
          },
        },
        [PassengerInformationChildState.route]: {
          always: [
            {
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
              cond: GuardTypes.haveAnyPassengersLoaded,
            },
            {
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
            },
          ],
        },
      },
    },
    [PassengerInformationState.passengerPicker]: {
      initial: PassengerInformationChildState.idle,
      states: {
        [PassengerInformationChildState.idle]: {},
        [PassengerInformationChildState.delete]: {
          invoke: {
            src: ServiceTypes.deleteUserPassengersService,
            onDone: {
              actions: ActionTypes.handleDeletePassenger,
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.idle}`,
            },
            onError: {
              actions: ActionTypes.setPassengersError,
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.error}`,
            },
          },
        },
      },
      on: {
        [PassengerEventTypes.SELECT_PASSENGER]: {
          actions: ActionTypes.selectPassenger,
        },
        [PassengerEventTypes.NEXT]: {
          target: config.nextState,
          cond: GuardTypes.hasAnySelectedPassengers,
        },
      },
    },
    [PassengerInformationState.passengerForm]: {
      initial: PassengerInformationChildState.route,
      states: {
        [PassengerInformationChildState.route]: {
          always: [
            {
              cond: GuardTypes.hasCurrentUserPassenger,
              target: PassengerInformationChildState.edit,
            },
            {
              target: PassengerInformationChildState.new,
            },
          ],
        },
        [PassengerInformationChildState.edit]: {
          invoke: {
            src: ServiceTypes.trackEditTravelers,
            onDone: { target: PassengerInformationChildState.idle },
            onError: { target: PassengerInformationChildState.idle },
          },
        },
        [PassengerInformationChildState.new]: {
          invoke: {
            src: ServiceTypes.trackNewTraveler,
            onDone: { target: PassengerInformationChildState.idle },
            onError: { target: PassengerInformationChildState.idle },
          },
        },
        [PassengerInformationChildState.idle]: {},
        [PassengerInformationChildState.update]: {
          invoke: {
            src: ServiceTypes.updateUserPassengerService,
            onDone: {
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
              actions: [
                ActionTypes.setUserPassengers,
                ActionTypes.selectPassenger,
              ],
            },
            onError: {
              target: `#${ParentState.passengerInformation}.${PassengerInformationState.error}`,
              actions: ActionTypes.setPassengersError,
            },
          },
        },
      },
      on: {
        [PassengerEventTypes.UPDATE_PASSENGER]: {
          target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.update}`,
        },
        [PassengerEventTypes.PREVIOUS]: {
          actions: ActionTypes.goPrevious,
        },
      },
    },
    [PassengerInformationState.error]: {},
  },
  on: {
    [PassengerEventTypes.DELETE_PASSENGER]: {
      target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.delete}`,
    },
    [PassengerEventTypes.SET_CURRENT_PASSENGER]: {
      actions: ActionTypes.setCurrentPassenger,
    },
    [PassengerEventTypes.CLEAR_PASSENGER_INFORMATION_ERROR]: {
      actions: ActionTypes.clearPassengerInformationError,
      target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
    },
    [PassengerEventTypes.OPEN_PASSENGER_PICKER]: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,

    [PassengerEventTypes.OPEN_PASSENGER_FORM]: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,

    [PassengerEventTypes.PREVIOUS]: {
      target: config?.previousState || ParentState.contactInformation,
    },
    [PassengerEventTypes.NEXT]: {
      target: config.nextState,
    },
    [PassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER]: {
      actions: ActionTypes.setCurrentPassenger,
      target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
    },
  },
});
