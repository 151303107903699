import { FlightShopType, TripCategory } from "@hopper-b2b/types";
import {
  useEnableCfar,
  useEnableCfarHackerFare,
  useEnableChfar,
  useEnableChfarHackerFare,
  useEnableDisruptionHackerFare,
  useEnableFintechSelection,
  useEnableMissedConnection,
  useEnableScheduleChange,
  useEnableWallet,
  useHideFareDetails,
  useHideReviewItitinerary,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { populateFlightBookQueryParams } from "../../book/actions/actions";
import { isCfarOffersValid } from "../../cfar/reducer";
import { isChfarOffersValid } from "../../chfar/reducer";
import {
  isMissedConnectionValid,
  isScheduleChangeValid,
} from "../../disruption/reducer";
import { getTripCategory } from "../../search/reducer";
import { setFlightShopProgress } from "../actions/actions";
import {
  FlightShopStep,
  flightShopProgressSelector,
  flightShopTypeSelector,
  isReturnFareHackerFareSelector,
} from "../reducer";
import { pushToPathWithExistingQueryParams } from "../utils/parseQueryString";

export const useUpdateQueryParams = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(
    (params: any, replace = false, newPathname?: string) => {
      pushToPathWithExistingQueryParams(
        history,
        newPathname || pathname,
        params,
        replace
      );
    },
    [history, pathname]
  );
};

export const useUpdateFlightShopStep = () => {
  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  return useCallback(
    (
      flightShopProgress?: FlightShopStep,
      replace = false,
      newPathname?: string
    ) => {
      if (flightShopProgress !== undefined) {
        dispatch(setFlightShopProgress(flightShopProgress));
      }

      updateQueryParams({ flightShopProgress }, replace, newPathname);
    },
    [dispatch, updateQueryParams]
  );
};

export const useGoToCheckout = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  return useCallback(
    (replace?: boolean) =>
      dispatch(
        populateFlightBookQueryParams({ history, replace, preserveQuery: true })
      ),
    [dispatch, history]
  );
};

const useShowScheduleChangeStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const enableScheduleChange = useEnableScheduleChange();
  const hasValidScheduleChangeOffer = useSelector(isScheduleChangeValid(true));
  const enableFintechSelection = useEnableFintechSelection();

  // HackerFare flags
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const enableDisruptionHackerFare = useEnableDisruptionHackerFare();

  const isHackerFareEnabledWithDisruption = isReturnFareHackerFare
    ? enableDisruptionHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  return useMemo(
    () =>
      !enableFintechSelection &&
      enableScheduleChange &&
      hasValidScheduleChangeOffer &&
      defaultFlightShop &&
      isHackerFareEnabledWithDisruption,
    [
      enableFintechSelection,
      enableScheduleChange,
      hasValidScheduleChangeOffer,
      defaultFlightShop,
      isHackerFareEnabledWithDisruption,
    ]
  );
};

const useShowMissedConnectionStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const enableMissedConnection = useEnableMissedConnection();
  const hasValidMissedConnectionOffer = useSelector(
    isMissedConnectionValid(true)
  );
  const enableFintechSelection = useEnableFintechSelection();

  // HackerFare flags
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const enableDisruptionHackerFare = useEnableDisruptionHackerFare();

  const isHackerFareEnabledWithDisruption = isReturnFareHackerFare
    ? enableDisruptionHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  return useMemo(
    () =>
      !enableFintechSelection &&
      enableMissedConnection &&
      hasValidMissedConnectionOffer &&
      defaultFlightShop &&
      isHackerFareEnabledWithDisruption,
    [
      enableFintechSelection,
      enableMissedConnection,
      hasValidMissedConnectionOffer,
      defaultFlightShop,
      isHackerFareEnabledWithDisruption,
    ]
  );
};
const useShowCfarStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const showFlightCfar = useEnableCfar();
  const hasValidCfarOffers = useSelector(isCfarOffersValid(true));
  const enableFintechSelection = useEnableFintechSelection();

  // HackerFare flags
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const enableCfarHackerFare = useEnableCfarHackerFare();

  const isHackerFareEnabledWithCfar = isReturnFareHackerFare
    ? enableCfarHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  return useMemo(
    () =>
      !enableFintechSelection &&
      showFlightCfar &&
      hasValidCfarOffers &&
      defaultFlightShop &&
      isHackerFareEnabledWithCfar,
    [
      enableFintechSelection,
      hasValidCfarOffers,
      showFlightCfar,
      defaultFlightShop,
      isHackerFareEnabledWithCfar,
    ]
  );
};

const useShowChfarStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const showFlightChfar = useEnableChfar();
  const hasValidChfarOffers = useSelector(isChfarOffersValid(true));
  const enableFintechSelection = useEnableFintechSelection();

  // HackerFare flags
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const enableChfarHackerFare = useEnableChfarHackerFare();

  const isHackerFareEnabledWithChfar = isReturnFareHackerFare
    ? enableChfarHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  return useMemo(
    () =>
      !enableFintechSelection &&
      showFlightChfar &&
      hasValidChfarOffers &&
      defaultFlightShop &&
      isHackerFareEnabledWithChfar,
    [
      enableFintechSelection,
      showFlightChfar,
      hasValidChfarOffers,
      defaultFlightShop,
      isHackerFareEnabledWithChfar,
    ]
  );
};

const useWalletOfferStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const enableWallet = useEnableWallet();
  const authenticated = useIsSessionAuthenticated();

  return useMemo(
    () => enableWallet && defaultFlightShop && authenticated,
    [enableWallet, defaultFlightShop, authenticated]
  );
};

export const useGoToNextStep = () => {
  const tripCategory = useSelector(getTripCategory);
  const isRoundTrip = tripCategory === TripCategory.ROUND_TRIP;

  const shopType = useSelector(flightShopTypeSelector);
  const isChfarExerciseShop = shopType === FlightShopType.CHFAR_EXERCISE;
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const showScheduleChangeStep = useShowScheduleChangeStep();
  const showMissedConnectionStep = useShowMissedConnectionStep();
  const showCfarStep = useShowCfarStep();
  const showChfarStep = useShowChfarStep();

  const showWalletOfferStep = useWalletOfferStep();

  const hideReviewItinerary = useHideReviewItitinerary();
  const hideFareDetails = useHideFareDetails();

  const enableFintechSelection =
    useEnableFintechSelection() && defaultFlightShop;

  const updateFlightShopStep = useUpdateFlightShopStep();
  const goToCheckout = useGoToCheckout();

  const currentFlightShopStep = useSelector(flightShopProgressSelector);

  const flightShopSteps = useMemo(() => {
    const steps = [FlightShopStep.ChooseDeparture];

    if (isRoundTrip) steps.push(FlightShopStep.ChooseReturn);

    if (!hideReviewItinerary) steps.push(FlightShopStep.ReviewItinerary);

    if (enableFintechSelection) steps.push(FlightShopStep.FintechSelection);

    if (showScheduleChangeStep) steps.push(FlightShopStep.ScheduleChange);
    if (showMissedConnectionStep) steps.push(FlightShopStep.MissedConnection);

    if (showCfarStep) steps.push(FlightShopStep.CfarOffers);
    if (showChfarStep) steps.push(FlightShopStep.ChfarOffer);
    if (showWalletOfferStep) steps.push(FlightShopStep.WalletOffers);

    if (!hideFareDetails) steps.push(FlightShopStep.FareDetails);

    return steps;
  }, [
    enableFintechSelection,
    hideFareDetails,
    hideReviewItinerary,
    isRoundTrip,
    showCfarStep,
    showChfarStep,
    showMissedConnectionStep,
    showScheduleChangeStep,
    showWalletOfferStep,
  ]);

  return useCallback(
    (replace = false) => {
      const currentFlightStepIndex = flightShopSteps.findIndex(
        (step: FlightShopStep) => step === currentFlightShopStep
      );
      const nextFlightShopStep =
        currentFlightStepIndex !== -1
          ? flightShopSteps[currentFlightStepIndex + 1]
          : undefined;

      if (nextFlightShopStep !== undefined) {
        return updateFlightShopStep(nextFlightShopStep, replace);
      } else {
        return goToCheckout(replace);
      }
    },
    [currentFlightShopStep, flightShopSteps, goToCheckout, updateFlightShopStep]
  );
};
