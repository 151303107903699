import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useCallback } from "react";
import "./styles.scss";
import clsx from "clsx";

export type DeclineAllFintechOptionsProps = {
  title: string;
  description: string;
  checked?: boolean;
  disabled: boolean;
  setChecked?: (checked: boolean) => void;
};

export const DeclineAllFintechOptions = ({
  title,
  description,
  checked,
  disabled,
  setChecked,
}: DeclineAllFintechOptionsProps) => {
  const onChange = useCallback(
    (_event, checked: boolean) => {
      setChecked(checked);
    },
    [setChecked]
  );

  return (
    <Box
      my={2}
      pr={4}
      className={clsx("fintech-selection-card", {
        "fintech-selection-card--decline-card": true,
        selected: checked,
      })}
    >
      <FormControlLabel
        labelPlacement="start"
        disabled={disabled}
        control={
          <Checkbox
            className="checkbox"
            checked={checked}
            onChange={onChange}
            color="default"
          />
        }
        label={
          <Box mr={2} className="fintech-label">
            <Box>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {title}
              </Typography>
              <Typography variant="caption" className="fintech-description">
                {description}
              </Typography>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
