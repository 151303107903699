import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";

import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  IFlightSearchCriteria,
  SEARCH_CALENDAR_CHANGED,
  TripCategory,
  VIEW_CALENDAR,
} from "@hopper-b2b/types";
import { ActionButton, DesktopPopupModal } from "@hopper-b2b/ui";
import { getEnvVariables, useUberBridge } from "@hopper-b2b/utilities";

import { PATH_SHOP } from "../../../../utils/urlPaths";
import { setRenderLoadingSteps } from "../../../shop/actions/actions";
import { actions } from "../../actions";
import { MonthAndDatePicker, MonthAndDatePickerType } from "../index";

import "./styles.scss";

export interface CalendarTrackingProperties {
  origin: string;
  destination: string;
  tripCategory: TripCategory;
}

interface ICalendarPickerProps {
  open?: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  closePopup?: () => void;
  disabled: boolean;
  tripCategory: TripCategory;
  focusedMonthIndex?: number;
  onComplete?: () => void;
  isHistoryPushDisabled?: boolean;
  isMobile?: boolean;
  modal?: boolean;
  calendarVisited: boolean;
  setCalendarVisited: (calendarVisited: boolean) => void;
  populateSearchUrlParams: (
    params: IFlightSearchCriteria,
    history: History
  ) => void;
  trackingProperties: CalendarTrackingProperties;
  a11yMode?: boolean;
}

export const CalendarPicker = (props: ICalendarPickerProps) => {
  const {
    open,
    closePopup,
    disabled,
    departureDate,
    returnDate,
    tripCategory,
    focusedMonthIndex,
    onComplete,
    isHistoryPushDisabled,
    isMobile = false,
    modal = false,
    setDepartureDate,
    setReturnDate,
    calendarVisited,
    setCalendarVisited,
    trackingProperties,
    a11yMode = false,
  } = props;
  const { t } = useI18nContext();
  const { postEvent } = useUberBridge();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const headerTitle = useMemo(
    () =>
      tripCategory === TripCategory.ONE_WAY
        ? t("searchControl.calendarOneWay")
        : t("searchControl.calendarRoundTrip"),
    [t, tripCategory]
  );

  const calendarClassName = useMemo(
    () => (tripCategory === TripCategory.ONE_WAY ? "one-way" : "roundtrip"),
    [tripCategory]
  );

  const onDatesSelect = useCallback(() => {
    onComplete ? onComplete() : closePopup?.();
  }, [closePopup, onComplete]);

  useEffect(() => {
    if (!calendarVisited) setCalendarVisited(true);
    trackEvent({
      eventName: VIEW_CALENDAR,
      properties: trackingProperties,
    });
    postEvent(VIEW_CALENDAR, {
      destination: trackingProperties.destination,
      origin: trackingProperties.origin,
      tripCategory: trackingProperties.tripCategory,
    });
  }, []);

  const readyToSearch = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? departureDate !== null
      : tripCategory === TripCategory.ROUND_TRIP
      ? returnDate !== null && departureDate !== null
      : false;
  }, [tripCategory, departureDate, returnDate]);

  const setStartDate = useCallback(
    (value: Date | null) => {
      if (!modal) {
        dispatch(
          actions.populateSearchUrlParams({ departureDate: value }, history)
        );

        dispatch(actions.setMissingDate(false));
        // populateSearchUrlParams({ departureDate: value }, history);
      }
      return setDepartureDate(value);
    },
    [dispatch, modal, setDepartureDate, history]
  );

  const setEndDate = useCallback(
    (value: Date | null) => {
      if (!modal) {
        dispatch(
          actions.populateSearchUrlParams({ returnDate: value }, history)
        );

        dispatch(actions.setMissingDate(false));
        // populateSearchUrlParams({ returnDate: value }, history);
      }
      return setReturnDate(value);
    },
    [dispatch, modal, setReturnDate, history]
  );

  const onSearchMobileClick = useCallback(() => {
    if (readyToSearch) {
      trackEvent({ eventName: SEARCH_CALENDAR_CHANGED, properties: undefined });
      !isHistoryPushDisabled && history.push(`${PATH_SHOP}${search}`);
      dispatch(
        setRenderLoadingSteps(
          [ClientName.UBER, ClientName.NUBANK].includes(
            getEnvVariables("clientName") as ClientName
          )
        )
      );
      dispatch(actions.setMissingDate(false));
      onComplete && onComplete();
    } else {
      dispatch(actions.setMissingDate(true));
    }
  }, [
    dispatch,
    history,
    isHistoryPushDisabled,
    onComplete,
    readyToSearch,
    search,
  ]);

  return isMobile ? (
    <Box className="mobile-calendar-picker-root">
      <MonthAndDatePicker
        className={clsx(`b2b ${calendarClassName}`, {
          "search-ready": readyToSearch,
          "a11y-active": a11yMode,
          modal,
        })}
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={departureDate}
        endDate={returnDate}
      />
      <ActionButton
        className={clsx("mobile-calendar-picker-search-button", "b2b", {
          "ready-to-search": readyToSearch,
        })}
        onClick={onSearchMobileClick}
        message={modal ? t("mobileSearchButtonPopup") : t("mobileSearchButton")}
        {...(getEnvVariables("clientName") !== ClientName.UBER && {
          disabled: !readyToSearch,
        })}
      />
    </Box>
  ) : (
    <DesktopPopupModal
      open={Boolean(open)}
      onClose={closePopup}
      className={clsx(
        "desktop-calendar-picker-popup-root",
        "desktop-flight-calendar-picker-popup-root",
        "flights-module",
        calendarClassName
      )}
      contentClassName="desktop-calendar-picker-wrapper"
      invisibleBackdrop={false}
    >
      <MonthAndDatePicker
        className={`b2b-flights ${calendarClassName}`}
        viewType={MonthAndDatePickerType.Horizontal}
        startDate={departureDate}
        endDate={returnDate}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        header={headerTitle}
      />
      <Button
        onClick={onDatesSelect}
        disabled={disabled}
        className="select-dates-button"
        variant="contained"
      >
        {t("searchControl.calendarPicker")}
      </Button>
    </DesktopPopupModal>
  );
};
