import { MachineConfig } from "xstate";
import { Event, TEvent } from "./events";
import { LodgingMachineContext } from "./types";
import { getInitialLodgingMachineContext } from "./initialContext";
import {
  CartUpdateState,
  getCartFulfillMachine,
  getCartQuoteMachine,
  getCartUpdateMachine,
  getContactInformationMachine,
  getLodgingResumeMachineState,
  getPassengerInformationMachine,
  getWalletMachine,
  LodgingActionTypes,
  ParentState,
  PassengerActionTypes,
  PassengerEventTypes,
  PassengerInformationChildState,
  PassengerInformationState,
  ResumeEvent,
  trackPollFailure,
  WalletActionTypes,
  WalletState,
} from "@hopper-b2b/checkout";
import { ActionTypes } from "./actions";
import { getNubankPaymentMachine } from "../../checkout/states";
import { LodgingCheckoutTrackingEvents } from "@hopper-b2b/types";
import { NubankPaymentEvent } from "../../checkout";
import {
  NubankPaymentChildState,
  NubankPaymentState,
} from "../../checkout/states/payments/types";
import { GuardTypes } from "./guards";
import { getReviewMachine } from "./states/Review/machine";

export const lodgingMachineId = "nubank-lodging-checkout";

export const lodgingStateMachine: MachineConfig<
  LodgingMachineContext,
  any,
  TEvent
> = {
  schema: {
    context: getInitialLodgingMachineContext(),
    events: {} as TEvent,
  },

  // Machine identifier
  id: lodgingMachineId,

  // Initial state
  initial: ParentState.resume,

  // State definition
  states: {
    [ParentState.resume]: getLodgingResumeMachineState({
      parentMachineId: lodgingMachineId,
      nextState: `#${lodgingMachineId}.${ParentState.loading}`,
    }),
    [ParentState.loading]: {
      entry: ActionTypes.setInitialContactInformation,
      always: [
        {
          target: `#${lodgingMachineId}.${ParentState.passengerInformation}`,
          cond: GuardTypes.contactContinue,
        },
        {
          target: `#${lodgingMachineId}.${ParentState.contactInformation}`,
        },
      ],
    },
    [ParentState.contactInformation]: {
      ...getContactInformationMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.passengerInformation}`,
        nextStateFromReview: `#${lodgingMachineId}.${ParentState.cartQuote}`,
      }),
    },
    [ParentState.passengerInformation]: {
      ...getPassengerInformationMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.cartQuote}`,
        nextStateFromReview: `#${lodgingMachineId}.${ParentState.cartQuote}`,
      }),
    },
    [ParentState.cartQuote]: {
      entry: LodgingActionTypes.addLodgingProduct,
      ...getCartQuoteMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.wallet}`,
      }),
    },
    [ParentState.wallet]: {
      ...getWalletMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.cartUpdate}`,
        // TODO: Figure out what to do in failed state
        failedState: `#${lodgingMachineId}.${ParentState.review}`,
      }),
    },
    [ParentState.cartUpdate]: {
      ...getCartUpdateMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.review}`,
        // TODO: Figure out what to do in failed state
        failedState: `#${lodgingMachineId}.${ParentState.review}`,
      }),
    },
    [ParentState.review]: {
      ...getReviewMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.payment}`,
        previousState: `#${lodgingMachineId}.${ParentState.passengerInformation}`,
      }),
    },
    [ParentState.payment]: {
      ...getNubankPaymentMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.cartFulfill}`,
      }),
    },
    [ParentState.cartFulfill]: {
      ...getCartFulfillMachine({
        parentMachineId: lodgingMachineId,
        nextState: `#${lodgingMachineId}.${ParentState.bookingConfirmation}`,
        trackPollFailureAction: trackPollFailure({
          eventName: LodgingCheckoutTrackingEvents.hotel_complete_buy,
          getAdditionalProperties: (context) =>
            context[ParentState.featureFlags].enableWallet
              ? {
                  offer_status: context[ParentState.wallet].offerStatus,
                }
              : {},
        }),
      }),
    },
    [ParentState.bookingConfirmation]: {},
  },
  on: {
    // Passenger Events
    [PassengerEventTypes.SELECT_PASSENGER]: {
      actions: PassengerActionTypes.selectPassenger,
    },
    [PassengerEventTypes.UPDATE_PASSENGER]: {
      target: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.update}`,
    },
    [PassengerEventTypes.DELETE_PASSENGER]: {
      target: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.delete}`,
    },
    [PassengerEventTypes.SET_CURRENT_PASSENGER]: {
      actions: PassengerActionTypes.setCurrentPassenger,
    },
    [PassengerEventTypes.CLEAR_PASSENGER_INFORMATION_ERROR]: {
      actions: PassengerActionTypes.clearPassengerInformationError,
      target: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
    },
    [PassengerEventTypes.OPEN_PASSENGER_PICKER]: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,

    [PassengerEventTypes.OPEN_PASSENGER_FORM]: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,

    [PassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER]: {
      actions: PassengerActionTypes.setCurrentPassenger,
      target: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
    },

    [NubankPaymentEvent.GO_TO_PAYMENT_ENROLLMENT_SUCCESS]: `#${lodgingMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.success}`,
    [NubankPaymentEvent.GO_TO_PAYMENT_ENROLLMENT_FAILURE]: `#${lodgingMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.failure}`,
    [Event.GO_TO_BOOKING_CONFIRMATION]: `#${lodgingMachineId}.${ParentState.bookingConfirmation}`,

    // Review events
    [Event.GO_TO_CART_FULFILL]: `#${lodgingMachineId}.${ParentState.cartFulfill}`,

    // Wallet events
    [Event.REMOVE_SELECTED_OFFER]: {
      target: `#${lodgingMachineId}.${ParentState.wallet}.${WalletState.removeDiscount}`,
    },
    [Event.SET_SELECTED_OFFER]: {
      actions: WalletActionTypes.addDiscounts,
      target: `#${lodgingMachineId}.${ParentState.cartUpdate}.${CartUpdateState.update}`,
    },

    // Events below are used for browser navigation
    [ResumeEvent.GO_TO_PASSENGER_SELECT]: `#${lodgingMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.idle}`,
    [Event.GO_TO_CONTACT_INFORMATION]: `#${lodgingMachineId}.${ParentState.contactInformation}`,
    [Event.GO_TO_CART_QUOTE]: `#${lodgingMachineId}.${ParentState.cartQuote}`,
    [Event.GO_TO_REVIEW]: `#${lodgingMachineId}.${ParentState.review}`,
    [Event.GO_TO_PAYMENT]: `#${lodgingMachineId}.${ParentState.payment}`,
    [Event.GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT]: `#${lodgingMachineId}.${ParentState.payment}.${NubankPaymentState.selectCreditOrDebit}`,
    [Event.GO_TO_PAYMENT_SELECT_CREDIT]: `#${lodgingMachineId}.${ParentState.payment}.${NubankPaymentState.credit}`,
    [Event.GO_TO_PAYMENT_SELECT_REVIEW]: `#${lodgingMachineId}.${ParentState.payment}.${NubankPaymentState.review}`,
  },
};
