import { Box, Dialog, Slide } from "@material-ui/core";
import { TripDetails, FareDetails } from "@hopper-b2b/types";
import { ActionButton, ButtonWrap, CloseButtonIcon } from "@hopper-b2b/ui";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import clsx from "clsx";

import { FlightShopReviewDetails } from "../../../index";
import "./styles.scss";
import { getSliceIndex } from "@hopper-b2b/utilities";
import { Airport } from "@b2bportal/air-shopping-api";

interface IDesktopFlightReviewDetailsPopoverProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMultiTicket: boolean;
  openPopover: boolean;
  onClose?: () => void;
  onClickEdit?: () => void;
  airports: { [key: string]: Airport };
  isMixedCabinClass?: boolean;
}

export const DesktopFlightReviewDetailsPopover = (
  props: IDesktopFlightReviewDetailsPopoverProps
) => {
  const {
    departure,
    tripDetails,
    fareDetails,
    isMultiTicket,
    openPopover,
    onClose,
    onClickEdit,
    airports,
    isMixedCabinClass,
  } = props;
  const { t } = useI18nContext();
  const sliceIndex = getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];

  const renderReviewDetails = () => {
    return (
      <Box className="desktop-flight-review-details-root">
        <Box className="desktop-flight-review-details-container">
          <Box className="header-section">
            <Box
              className="header-text"
              id="review-flight-details-header"
              tabIndex={-1}
            >
              <I18nMarkup
                tKey={
                  departure
                    ? "flightShopReview.outboundCardHeader"
                    : "flightShopReview.returnCardHeader"
                }
                replacements={{
                  location: airports[tripSlice.destinationCode]
                    ? airports[tripSlice.destinationCode].cityName
                    : tripSlice.destinationName,
                  date: dayjs(tripSlice.departureTime).format("ddd, MMM D"),
                }}
              />
            </Box>
            {!!onClickEdit && (
              <ActionButton
                className="change-flight-button-wrapper"
                buttonClassName="change-flight-button"
                defaultStyle="h4r-secondary"
                message={t("flightShopReview.changeFlight")}
                onClick={onClickEdit}
              />
            )}
          </Box>
          <Box className="content-section">
            <FlightShopReviewDetails
              departure={departure}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              isMultiTicket={isMultiTicket}
              isMixedCabinClass={isMixedCabinClass}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      hideBackdrop={false}
      TransitionComponent={Slide}
      TransitionProps={{
        // See https://mui.com/material-ui/transitions/#performance-amp-seo
        unmountOnExit: true,
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of TransitionProps to include direction.
        direction: "up",
        /* eslint-enable */
      }}
      className={clsx(
        "desktop-popover-card-root",
        "desktop-flight-review-details-popup"
      )}
      onClick={(event) => {
        event.stopPropagation();
      }}
      role="dialog"
      aria-labelledby="review-flight-details-header"
      open={openPopover}
      onClose={onClose}
      invisibleBackdrop={false}
    >
      <Box className={clsx("desktop-popover-card-top-button", "right")}>
        <ButtonWrap aria-label="Close" onClick={onClose}>
          <img src={CloseButtonIcon} alt={t("close.imageAlt")} />
        </ButtonWrap>
      </Box>
      {renderReviewDetails()}
    </Dialog>
  );
};
