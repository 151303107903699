import { Box, Grid, Radio, Typography } from "@material-ui/core";
import styles from "./FintechProduct.module.scss";
import { FintechProductOption } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

interface FintechProductProps {
  options: FintechProductOption[];
}

export const FintechSelection = ({ options }: FintechProductProps) => {
  const { t } = useI18nContext();

  const lastIndex = options.length - 1;
  const declinedOffer = options[lastIndex];

  return (
    <Grid container className={styles.fintechSelectionContainer}>
      {options.slice(0, -1).map((option) => (
        <Grid
          xs={11}
          lg={5}
          key={option.title}
          className={styles.fintechSelection}
          onClick={option.onClick}
        >
          {option.recommended && (
            <Box className={styles.recommended}>{option.recommended}</Box>
          )}
          <Grid container onClick={option.onClick}>
            <Grid item xs={2} lg={2}>
              <Radio color="primary" checked={option.selected} />
            </Grid>
            <Grid item xs={10} lg={10} className={styles.option}>
              <Typography className={styles.title}>{option.title}</Typography>
              <Box mt={1}>
                {option.cost ? (
                  <>
                    <Typography
                      component="span"
                      className={styles.cost}
                      style={{ fontWeight: 700 }}
                    >
                      {option.cost}
                    </Typography>
                    <Typography component="span" className={styles.traveler}>
                      /{t?.("traveler")}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    component="span"
                    style={{ fontWeight: 400 }}
                    className={styles.traveler}
                  >
                    {option.description}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {declinedOffer && (
        <Grid
          container
          onClick={declinedOffer.onClick}
          className={styles.declineSelection}
        >
          <Grid item xs={2} lg={1}>
            <Radio color="primary" checked={declinedOffer.selected} />
          </Grid>
          <Grid item xs={10} lg={11} className={styles.option}>
            <Typography className={styles.title}>
              {declinedOffer.title}
            </Typography>
            <Box mt={1}>
              {declinedOffer.cost ? (
                <>
                  <Typography
                    component="span"
                    className={styles.cost}
                    style={{ fontWeight: 700 }}
                  >
                    {declinedOffer.cost}
                  </Typography>
                  <Typography component="span" className={styles.traveler}>
                    /{t?.("traveler")}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
