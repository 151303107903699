import {
  SingleTravelItinerary,
  TravelItineraryEnum,
} from "@b2bportal/air-booking-api";
import { ExchangeScenarioEnum } from "@b2bportal/air-exchange-api";
import { TripCategory } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { last } from "lodash-es";

import { IStoreState } from "../../../reducers/types";
import { FetchState } from "../reducer/policy";

export const getPolicyStore = (state: IStoreState) =>
  state.flightExchange.policy;
export const getAirlines = (state: IStoreState) =>
  getPolicyStore(state).airlines;

export const getAirports = (state: IStoreState) =>
  getPolicyStore(state).airports;

export const getExchangePolicy = (state: IStoreState) =>
  getPolicyStore(state).exchangePolicy;

export const getContextLoading = (state: IStoreState) => {
  const { itineraryFetchState, policyFetchState } = getPolicyStore(state);

  return Boolean(
    itineraryFetchState !== FetchState.Done &&
      policyFetchState !== FetchState.Done
  );
};

export const getIsExchangeable = (state: IStoreState) => {
  const { ExchangeScenario: scenario } = getExchangeScenario(state) ?? {};

  return scenario === ExchangeScenarioEnum.Exchangeable;
};

export const getIsFtc = (state: IStoreState) =>
  getPolicyStore(state).exchangePolicy?.ExchangeScenario ===
  ExchangeScenarioEnum.Ftc;

export const getItinerary = (state: IStoreState) =>
  getPolicyStore(state).itinerary;

export const getHasCfar = (state: IStoreState) =>
  Boolean(getItinerary(state)?.ancillaries.cfar);

export const getHasChfar = (state: IStoreState) =>
  Boolean(getItinerary(state)?.ancillaries.chfar);

export const getReservationId = (state: IStoreState) =>
  getItinerary(state)?.bookedItinerary.id;

export const getTravelItinerary = (
  state: IStoreState
): SingleTravelItinerary | null => {
  const { itinerary } = getPolicyStore(state);

  if (itinerary) {
    const { travelItinerary } = getPolicyStore(state).itinerary.bookedItinerary;

    if (
      travelItinerary.TravelItinerary ===
      TravelItineraryEnum.SingleTravelItinerary
    ) {
      return travelItinerary;
    }

    return travelItinerary.travelItineraries[0];
  }

  return null;
};

export const getItineraryDepDate = (state: IStoreState) => {
  const tItinerary = getTravelItinerary(state);

  if (tItinerary) {
    const { updatedDeparture, zonedUpdatedDeparture } =
      tItinerary.slices[0].segments[0];

    return updatedDeparture ?? zonedUpdatedDeparture;
  }

  return "";
};

export const getItineraryDest = createSelector(
  getTravelItinerary,
  (tItinerary) => last(tItinerary?.slices[0].segments)?.destination
);

export const getItineraryDestName = createSelector(
  getAirports,
  getItineraryDest,
  (airports, dest) => airports[dest?.locationCode]?.name
);

export const getItineraryOrigin = createSelector(
  getTravelItinerary,
  (tItinerary) => tItinerary?.slices[0].segments[0].origin
);

export const getItineraryOriginName = createSelector(
  getAirports,
  getItineraryOrigin,
  (airports, origin) => airports[origin?.locationCode]?.name
);

export const getItineraryPNR = (state: IStoreState) =>
  getTravelItinerary(state)?.locators?.agent.unscopedValue;

export const getItineraryRetDate = (state: IStoreState) => {
  const tItinerary = getTravelItinerary(state);

  if (tItinerary?.slices.length > 1) {
    const returnSlice = last(tItinerary.slices);

    if (returnSlice) {
      const { updatedDeparture, zonedUpdatedDeparture } =
        returnSlice.segments[0];

      return updatedDeparture ?? zonedUpdatedDeparture;
    }
  }

  return "";
};

export const getItinerarySlices = (state: IStoreState) =>
  getTravelItinerary(state)?.slices ?? [];

export const getPrevOutboundSlice = (state: IStoreState) =>
  getItinerarySlices(state)[0];

export const getPrevReturnSlice = (state: IStoreState) =>
  getItinerarySlices(state)[1];

export const getItineraryTripType = (state: IStoreState) =>
  getItinerarySlices(state).length > 1
    ? TripCategory.ROUND_TRIP
    : TripCategory.ONE_WAY;

export const getChangeFee = (state: IStoreState) => {
  const policy = getExchangePolicy(state);

  if (policy && "changeFee" in policy) {
    return policy.changeFee;
  }

  return { amount: 0, currency: "" };
};

export const getOriginalExchangeFee = createSelector(
  getExchangePolicy,
  (policy) => {
    if (policy && "originalChangeFee" in policy) {
      return policy.originalChangeFee;
    }

    return { amount: 0, currency: "" };
  }
);

export const getExchangeScenario = (state: IStoreState) =>
  getPolicyStore(state)?.exchangePolicy || null;

export const getPassengers = (state: IStoreState) =>
  getItinerary(state)?.bookedItinerary.passengers;

export const getItineraryPaxPricing = (state: IStoreState) =>
  getItinerary(state)?.bookedItinerary.sellingPricing.pricingByPassenger;

export const getPayments = (state: IStoreState) =>
  getItinerary(state)?.bookedItinerary.paymentBreakdown?.payments ?? [];

export const getAncillariesPayment = (state: IStoreState) =>
  getTotalPricing(state)?.ancillaries;

export const getSeatPayments = (state: IStoreState) =>
  getItinerary(state)?.bookedItinerary.seats?.seats;

export const getTravelCredit = (state: IStoreState) =>
  getItinerary(state)?.travelCredit;

export const getTotalPricing = (state: IStoreState) =>
  getItinerary(state)?.bookedItinerary.sellingPricing.totalPricing;

export const getSubTotalPayment = (state: IStoreState) =>
  getTotalPricing(state)?.subtotal;

export const getWaiveRebookingFee = createSelector(
  getIsFtc,
  getHasCfar,
  getHasChfar,
  (isFTC, hasCfar, hasChfar) =>
    Boolean((isFTC && hasCfar) || (!isFTC && hasChfar))
);
