import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, MobileFloatingSection } from "@hopper-b2b/ui";

import { ReactComponent as TimeoutImage } from "../../assets/client/timeout.svg";
import { ReactComponent as CloseIcon } from "../../assets/client/close.svg";
import { closeNubankWebview } from "../../utils/nubankHandoff";
import "./styles.scss";

const ExpiredSession = () => {
  const { t } = useI18nContext();
  const handleStartNewSearch = () => {
    closeNubankWebview();
  };

  return (
    <div className="nubank-invalid-session-container">
      <div className="header">
        <ButtonWrap className="back-button" onClick={handleStartNewSearch}>
          <CloseIcon className="close-icon" />
        </ButtonWrap>
      </div>
      <div className="content">
        <TimeoutImage className="timeout-image" />
        <h1>{t("expiredSessionModal.title")}</h1>
        <h4>{t("expiredSessionModal.description")}</h4>
      </div>
      <MobileFloatingSection
        primaryButton={{
          children: t("expiredSessionModal.confirm"),
          onClick: handleStartNewSearch,
        }}
      />
    </div>
  );
};

export default ExpiredSession;
