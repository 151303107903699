import { RewardsAccount } from "@hopper-b2b/types";

import * as actionTypes from "./constants";

export const setIsFirstLaunch = (
  isFirstLaunch: boolean
): ISetIsFirstLaunch => ({
  type: actionTypes.SET_IS_FIRST_LAUNCH,
  isFirstLaunch,
});

export interface ISetIsFirstLaunch {
  type: actionTypes.SET_IS_FIRST_LAUNCH;
  isFirstLaunch: boolean;
}

export const setAgentEmail = (agentEmail: string): ISetAgentEmail => ({
  type: actionTypes.SET_AGENT_EMAIL,
  agentEmail,
});

export interface ISetAgentEmail {
  type: actionTypes.SET_AGENT_EMAIL;
  agentEmail: string;
}

export const fetchRewardsAccounts = (): IFetchRewardsAccounts => ({
  type: actionTypes.FETCH_REWARDS_ACCOUNTS,
});

export interface IFetchRewardsAccounts {
  type: actionTypes.FETCH_REWARDS_ACCOUNTS;
}

export const fetchRewardsAccountsCallStateFailed =
  (): IFetchRewardsAccountsCallStateFailed => ({
    type: actionTypes.SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED,
  });

export interface IFetchRewardsAccountsCallStateFailed {
  type: actionTypes.SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED;
}

export const setRewardsAccounts = (
  rewardsAccounts: RewardsAccount[]
): ISetRewardsAccounts => ({
  type: actionTypes.SET_REWARDS_ACCOUNTS,
  rewardsAccounts,
});

export interface ISetRewardsAccounts {
  type: actionTypes.SET_REWARDS_ACCOUNTS;
  rewardsAccounts: RewardsAccount[];
}

export const setSelectedAccountReferenceId = (
  selectedAccountReferenceId: string | null
): ISetSelectedAccountReferenceId => ({
  type: actionTypes.SET_SELECTED_ACCOUNT_REFERENCE_ID,
  selectedAccountReferenceId,
});

export interface ISetSelectedAccountReferenceId {
  type: actionTypes.SET_SELECTED_ACCOUNT_REFERENCE_ID;
  selectedAccountReferenceId: string | null;
}

export type FlightRewardsActions =
  | IFetchRewardsAccounts
  | IFetchRewardsAccountsCallStateFailed
  | ISetRewardsAccounts
  | ISetSelectedAccountReferenceId
  | ISetIsFirstLaunch
  | ISetAgentEmail;
