import {
  IconComponent,
  IconName,
  MobileFloatingSection,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { Fragment } from "react";
import { useContext } from "react";
import { ClientContext } from "../../../../../App";
import { FintechLearnMore } from "./FintechLearnMore";
import "./styles.scss";
import { Product } from "@b2bportal/purchase-api";
import { DisruptionProductType } from "@hopper-b2b/types";

interface Item {
  icon: string;
  item: string;
}
interface Content {
  description: string;
  reasons: Item[];
  buttonText: string;
}

export interface FintechHalfSheetProps {
  open: boolean;
  header: string;
  onClose: () => void;
  content: Content;
  onAddProduct: () => void;
  product: Product | DisruptionProductType;
}

export const FintechHalfSheet = ({
  open,
  header,
  onClose,
  content,
  onAddProduct,
  product,
}: FintechHalfSheetProps) => {
  const { assets } = useContext(ClientContext);

  const { description, reasons, buttonText } = content;

  const handleOnClick = () => {
    onAddProduct();
    onClose();
  };
  return (
    <MobilePopoverCard
      key={header}
      centered
      open={open}
      onClose={onClose}
      fullScreen={false}
      className="fintech-half-sheet"
      headerElement={header}
      topLeftButton={
        <IconButton onClick={onClose}>
          <IconComponent name={IconName.Close} />
        </IconButton>
      }
    >
      <Box p={2}>
        <Typography>
          {description}
          <FintechLearnMore product={product} />
        </Typography>
        {reasons.map((reason: Item, index: number) => (
          <Fragment key={reason.item}>
            <Box p={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <img alt="fintech-reason-icon" src={assets?.[reason.icon]} />
                </Grid>
                <Grid item xs>
                  <Typography>{reason.item}</Typography>
                </Grid>
              </Grid>
            </Box>
            {index < reasons.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Box>
      {/* Spacer for floating button */}
      <Box height={64} p={2} />
      <MobileFloatingSection
        primaryButton={{
          children: buttonText,
          onClick: handleOnClick,
          className: "",
          wrapperClassName: "",
        }}
      />
    </MobilePopoverCard>
  );
};
