import { ActionTypes } from "./actions";
import { GuardTypes } from "./guards";
import { GuardTypes as ReviewGuardTypes } from "../Review/guards";
import { ContactEventType } from "./events";
import { ServiceTypes } from "./services";
import {
  ChildMachineConfig,
  GenericChildState,
  ParentState,
} from "../../../types/common";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.contactInformation,
  initial: GenericChildState.idle,
  always: {
    actions: ActionTypes.setContactVisited,
    cond: GuardTypes.notVisitedContact,
  },
  states: {
    [GenericChildState.idle]: {},
    [GenericChildState.saving]: {
      invoke: {
        src: ServiceTypes.trackAddContactEvent,
        onDone: [
          {
            cond: ReviewGuardTypes.visitedReview,
            target: config.nextStateFromReview || ParentState.cartQuote,
          },
          {
            target: config.nextState || ParentState.passengerInformation,
          },
        ],
        onError: [
          {
            cond: ReviewGuardTypes.visitedReview,
            target: config.nextStateFromReview || ParentState.cartQuote,
          },
          {
            target: config.nextState || ParentState.passengerInformation,
          },
        ],
      },
    },
  },
  on: {
    [ContactEventType.CHANGE]: {
      actions: ActionTypes.setContactInfo,
    },
    [ContactEventType.NEXT]: `#${config.parentMachineId}.${ParentState.contactInformation}.${GenericChildState.saving}`,
  },
});
