import { useI18nContext } from "@hopper-b2b/i18n";
import { GenericHeader } from "../../GenericHeader";

export interface GalleryHeaderProps {
  onBack: () => void;
  close?: boolean;
  title?: string;
}

export function GalleryHeader({
  onBack,
  close = false,
  title,
}: GalleryHeaderProps) {
  const { t } = useI18nContext();

  return (
    <GenericHeader
      title={title || t("photoGallery")}
      onBack={onBack}
      close={close}
    />
  );
}
