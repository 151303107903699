import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./styles.module.scss";
import { useCallback, useMemo, useState } from "react";
import {
  ButtonWrap,
  IconComponent,
  IconName,
  MobileFloatingSection,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { ReactComponent as HelpIcon } from "../../../assets/client/help-icon.svg";
import clsx from "clsx";

enum CategoryType {
  RedressNumber = "RedressNumber",
  KnownTravelerNumber = "KnownTravelerNumber",
}

interface InputAdornmentLearnMore {
  category: CategoryType;
}

const InputAdornmentLearnMore = ({ category }: InputAdornmentLearnMore) => {
  const { t } = useI18nContext();

  const [openLearnMoreHalfSheet, setOpenLearnMoreHalfSheet] = useState(false);

  const content = useMemo(() => {
    switch (category) {
      case CategoryType.KnownTravelerNumber:
        return {
          title: t("knownTravelerNumber"),
          description: t("knownTravelerNumberLearnMoreDescription"),
        };
      case CategoryType.RedressNumber:
        return {
          title: t("redressNumber"),
          description: t("redressNumberLearnMoreDescription"),
        };
    }
  }, [category, t]);

  const handleOpenHalfSheet = useCallback(() => {
    setOpenLearnMoreHalfSheet(true);
  }, []);

  const handleCloseHalfSheet = useCallback(() => {
    setOpenLearnMoreHalfSheet(false);
  }, []);

  return (
    <>
      {/* Need type="button" to prevent a form submission/refresh */}
      <ButtonWrap
        onClick={handleOpenHalfSheet}
        className={styles.InputAdornmentLearnMoreButton}
        type="button"
      >
        <HelpIcon />
      </ButtonWrap>

      <MobilePopoverCard
        open={openLearnMoreHalfSheet}
        className={clsx(
          "half-sheet-modal",
          styles.InputAdornmentLearnMoreHalfsheet
        )}
        contentClassName="half-sheet-modal-content"
        onClose={handleCloseHalfSheet}
        centered={true}
        topRightButton={
          <ButtonWrap onClick={handleCloseHalfSheet}>
            <IconComponent
              ariaLabel={t("modalClose.ariaLabel")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          </ButtonWrap>
        }
        bottomButton={
          <MobileFloatingSection
            className="half-sheet-continue-button"
            primaryButton={{
              children: t("close.button"),
              onClick: handleCloseHalfSheet,
            }}
          />
        }
      >
        {
          <>
            <h2 className="half-sheet-modal-title">{content.title}</h2>
            <div className="half-sheet-modal-description">
              {content.description}
            </div>
          </>
        }
      </MobilePopoverCard>
    </>
  );
};

export default InputAdornmentLearnMore;
