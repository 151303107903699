import { useCallback, useState } from "react";
import { debounce } from "lodash-es";
import { GenericSlider, GenericDropdown } from "@hopper-b2b/ui";
import { ITimeRange, TripCategory } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import clsx from "clsx";

import "./styles.scss";
import { TIME_RANGE_MAX } from "../../../../../../reducer";
import { DepartureArrivalSelectionProps } from "./container";
import { useSelector } from "react-redux";
import {
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
} from "../../../../../../../shop/reducer";

export const DepartureArrivalSelectionDropdown = (
  props: DepartureArrivalSelectionProps
) => {
  const {
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
  } = props;

  const isInChooseDepartureStep = useSelector(isInChooseDepartureStepSelector);
  const isInChooseReturnStep = useSelector(isInChooseReturnStepSelector);

  const outboundSelection = {
    departureTimeRange: outboundDepartureTimeRange,
    onChangeDepartureTimeRange: (min: number, max: number) =>
      setOutboundDepartureTimeRange({ min, max }),
    arrivalTimeRange: outboundArrivalTimeRange,
    onChangeArrivalTimeRange: (min: number, max: number) =>
      setOutboundArrivalTimeRange({ min, max }),
  };

  const { t } = useI18nContext();

  const returnSelection =
    tripCategory === TripCategory.ROUND_TRIP
      ? {
          departureTimeRange: returnDepartureTimeRange,
          onChangeDepartureTimeRange: (min: number, max: number) =>
            setReturnDepartureTimeRange({ min, max }),
          arrivalTimeRange: returnArrivalTimeRange,
          onChangeArrivalTimeRange: (min: number, max: number) =>
            setReturnArrivalTimeRange({ min, max }),
        }
      : undefined;

  const renderDropdownContent = () => {
    return (
      <>
        {isInChooseDepartureStep && (
          <div className="departure-arrival-selection-root outbound">
            <div className="departure-arrival-selection-container">
              <div className="selection-container">
                <div className="time-window-slider-container departure">
                  <div className="label-container">{t("departure")}</div>
                  <TimeWindowSlider
                    timeRange={outboundSelection.departureTimeRange}
                    onChange={outboundSelection.onChangeDepartureTimeRange}
                  />
                </div>
                <div className="time-window-slider-container arrival">
                  <div className="label-container">
                    {t("searchFilter.arrivalLabel")}
                  </div>
                  <TimeWindowSlider
                    timeRange={outboundSelection.arrivalTimeRange}
                    onChange={outboundSelection.onChangeArrivalTimeRange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {returnSelection && isInChooseReturnStep && (
          <div className="departure-arrival-selection-root arrival">
            <div className="departure-arrival-selection-container">
              <div className="selection-container">
                <div className="time-window-slider-container departure">
                  <div className="label-container">{t("departure")}</div>
                  <TimeWindowSlider
                    timeRange={returnSelection.departureTimeRange}
                    onChange={returnSelection.onChangeDepartureTimeRange}
                  />
                </div>
                <div className="time-window-slider-container arrival">
                  <div className="label-container">
                    {t("searchFilter.arrivalLabel")}
                  </div>
                  <TimeWindowSlider
                    timeRange={returnSelection.arrivalTimeRange}
                    onChange={returnSelection.onChangeArrivalTimeRange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="departure-arrival-dropdown">
      <GenericDropdown
        buttonClassName="departure-arrival-dropdown"
        popoverClassName="departure-arrival-popover b2b"
        ariaLabel={`Time Filter`}
        dropdownLabel={t("searchFilter.time")}
        dropdownContent={renderDropdownContent()}
      />
    </div>
  );
};

interface ITimeWindowSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  timeRange: ITimeRange;
}

const TimeWindowSlider = (props: ITimeWindowSliderProps) => {
  const { className, onChange, timeRange } = props;

  const { brand } = useI18nContext();
  const [value, setValue] = useState(timeRange);

  const debouncedAction = debounce(onChange, 300);
  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (min: number, max: number) => {
    setValue({ min, max });
    stateDebounceDispatchAction(min, max);
  };

  const timeLabelHandler = useCallback(
    (time: number) => {
      const totalTime = dayjs().hour(0).minute(time);
      return totalTime.format(brand.timeFormat || "hh:mm A");
    },
    [brand.timeFormat]
  );

  return (
    <GenericSlider
      className={clsx("time-window-slider-root", className)}
      onChange={handleChange}
      sliderType={"doubleThumb"}
      step={1}
      chosenMin={value.min}
      chosenMax={value.max}
      sliderMin={0}
      sliderMax={TIME_RANGE_MAX}
      getLabel={timeLabelHandler}
      updateLabelsOnChange
    />
  );
};
