export const FETCH_WALLET_OFFERS = "lodgingWallet/FETCH_WALLET_OFFERS";
export type FETCH_WALLET_OFFERS = typeof FETCH_WALLET_OFFERS;

export const SET_WALLET_OFFERS = "lodgingWallet/SET_WALLET_OFFERS";
export type SET_WALLET_OFFERS = typeof SET_WALLET_OFFERS;

export const SET_WALLET_OFFERS_FAILED =
  "lodgingWallet/SET_WALLET_OFFERS_FAILED";
export type SET_WALLET_OFFERS_FAILED = typeof SET_WALLET_OFFERS_FAILED;

export const SET_BEST_WALLET_OFFER = "lodgingWallet/SET_BEST_WALLET_OFFER";
export type SET_BEST_WALLET_OFFER = typeof SET_BEST_WALLET_OFFER;

export const SET_SELECTED_WALLET_OFFER =
  "lodgingWallet/SET_SELECTED_WALLET_OFFER";
export type SET_SELECTED_WALLET_OFFER = typeof SET_SELECTED_WALLET_OFFER;

export const SET_SELECTED_CREDIT_OFFER =
  "lodgingWallet/SET_SELECTED_CREDIT_OFFER";
export type SET_SELECTED_CREDIT_OFFER = typeof SET_SELECTED_CREDIT_OFFER;
