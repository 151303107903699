import { ChildMachineConfig } from "../../../types";
import { Event } from "../../../events";
import {
  ResumeFlightActionTypes,
  ResumeLodgingActions,
  ResumeSharedActionTypes,
} from "./actions";
import { ResumeEvent } from "./events";

export const getFlightResumeMachineState = (config: ChildMachineConfig) => ({
  on: {
    [Event.NEXT]: config.nextState,
    [ResumeEvent.SET_FLIGHT_SEARCH_CONTEXT]: {
      actions: ResumeFlightActionTypes.setFlightSearchContext,
    },
    [ResumeEvent.SET_FLIGHT_SEARCH_ORIGIN]: {
      actions: ResumeFlightActionTypes.setFlightSearchOrigin,
    },
    [ResumeEvent.SET_FLIGHT_SEARCH_DESTINATION]: {
      actions: ResumeFlightActionTypes.setFlightSearchDestination,
    },
    [ResumeEvent.SET_FLIGHT_SHOP_CONTEXT]: {
      actions: ResumeFlightActionTypes.setFlightShopContext,
    },
    [ResumeEvent.SET_FLIGHT_TRIP_DETAILS]: {
      actions: ResumeFlightActionTypes.setFlightShopTripDetails,
    },
    [ResumeEvent.SET_CONTACT_INFORMATION_CONTEXT]: {
      actions: ResumeSharedActionTypes.setContactInformationContext,
    },
    [ResumeEvent.SET_PASSENGER_INFORMATION_CONTEXT]: {
      actions: ResumeSharedActionTypes.setPassengerInformationContext,
    },
    [ResumeEvent.SET_CART_QUOTE_CONTEXT]: {
      actions: ResumeSharedActionTypes.setCartQuoteContext,
    },
    [ResumeEvent.SET_PRICE_DROP_CONTEXT]: {
      actions: ResumeSharedActionTypes.setPriceDropContext,
    },
    [ResumeEvent.SET_PASSENGER_VALIDATION]: {
      actions: ResumeFlightActionTypes.setPassengerValidated,
    },
    [ResumeEvent.SET_TRIP_PRICING]: {
      actions: ResumeFlightActionTypes.setTripPricing,
    },
  },
});

export const getLodgingResumeMachineState = (config: ChildMachineConfig) => ({
  on: {
    [Event.NEXT]: config.nextState,
    [ResumeEvent.SET_LODGING_SHOP_CONTEXT]: {
      actions: ResumeLodgingActions.setLodgingShopContext,
    },
    [ResumeEvent.SET_PASSENGER_INFORMATION_CONTEXT]: {
      actions: ResumeSharedActionTypes.setPassengerInformationContext,
    },
    [ResumeEvent.SET_CART_QUOTE_CONTEXT]: {
      actions: ResumeSharedActionTypes.setCartQuoteContext,
    },
    [ResumeEvent.SET_WALLET_CONTEXT]: {
      actions: ResumeFlightActionTypes.setWalletContext,
    },
  },
});
