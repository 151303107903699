import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FintechMerchandisingTrackingEvents,
  FintechMerchandisingViewedEventProperties,
  FintechMerchandsingContinueEvent,
} from "@hopper-b2b/types";
import { MobileFloatingSection, Slot, Snackbar } from "@hopper-b2b/ui";
import {
  useEnableCfar,
  useEnableCfarHackerFare,
  useEnableChfar,
  useEnableChfarHackerFare,
  useEnableDisruptionHackerFare,
  useEnableMissedConnection,
  useEnableScheduleChange,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { Box, Chip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ClientContext } from "../../../../App";
import { setSelectedCfarOffer } from "../../../cfar/actions/actions";
import { getSelectedCfarOffer, isCfarOffersValid } from "../../../cfar/reducer";
import { setSelectedChfarOffer } from "../../../chfar/actions/actions";
import {
  getSelectedChfarOffer,
  isChfarOffersValid,
} from "../../../chfar/reducer";
import {
  setSelectedMissedConnectionOffer,
  setSelectedScheduleChangeOffer,
} from "../../../disruption/actions/actions";
import {
  getSelectedMissedConnectionOffer,
  getSelectedScheduleChangeOffer,
  isMissedConnectionValid,
  isScheduleChangeValid,
} from "../../../disruption/reducer";
import { setDeclineAllFintechOptions } from "../../actions/actions";
import { useGoToNextStep } from "../../hooks";
import {
  getDeclineAllFintechOptions,
  isReturnFareHackerFareSelector,
  selectedTripSelector,
} from "../../reducer";
import { CfarFintechOptionCard } from "./CfarFintechOptionCard";
import { ChfarFintechOptionCard } from "./ChfarFintechOptionCard";
import { DeclineAllFintechOptions } from "./FintechOption";
import { MissedConnectionFintechOptionCard } from "./MissedConnectionFintechOptionCard";
import { ScheduleChangeFintechOptionCard } from "./ScheduleChangeFintechOptionCard";
import "./styles.scss";

export type FintechSelectionPageProps = {
  onContinue: () => void;
};

export const FintechSelectionPage = ({
  onContinue,
}: FintechSelectionPageProps) => {
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const history = useHistory();

  const dispatch = useDispatch();
  const { setHeader } = useUberBridge();
  const goToNextStep = useGoToNextStep();

  const [error, setError] = useState(false);

  const selectedTrip = useSelector(selectedTripSelector);
  // HackerFare
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);

  // CFAR
  const enableCfar = useEnableCfar();
  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const hasValidCfarOffers = useSelector(isCfarOffersValid(false));
  const enableCfarHackerFare = useEnableCfarHackerFare();
  const isHackerFareEnabledWithCfar = isReturnFareHackerFare
    ? enableCfarHackerFare
    : true; //If HackerFare is disabled/not selected, always return true
  const showCfarOffers =
    enableCfar && hasValidCfarOffers && isHackerFareEnabledWithCfar;

  // CHFAR
  const enableChfar = useEnableChfar();
  const hasValidChfarOffers = useSelector(isChfarOffersValid(false));
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);
  const enableChfarHackerFare = useEnableChfarHackerFare();
  const isHackerFareEnabledWithChfar = isReturnFareHackerFare
    ? enableChfarHackerFare
    : true; //If HackerFare is disabled/not selected, always return true
  const showChfarOffers =
    enableChfar && hasValidCfarOffers && isHackerFareEnabledWithChfar;

  // Disruption HackerFare
  const enableDisruptionHackerFare = useEnableDisruptionHackerFare();
  const isHackerFareEnabledWithDisruption = isReturnFareHackerFare
    ? enableDisruptionHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  // Schedule Change
  const enableDisruptionScheduleChange = useEnableScheduleChange();
  const hasValidScheduleChangeOffers = useSelector(
    isScheduleChangeValid(false)
  );
  const selectedScheduleChangeOffer = useSelector(
    getSelectedScheduleChangeOffer
  );
  const showScheduleChangeOffers =
    enableDisruptionScheduleChange &&
    hasValidScheduleChangeOffers &&
    isHackerFareEnabledWithDisruption;

  // Missed Connection
  const enableDisruptionMissedConnection = useEnableMissedConnection();
  const hasValidMissedConnectionOffers = useSelector(
    isMissedConnectionValid(false)
  );
  const selectedMissedConnectionOffer = useSelector(
    getSelectedMissedConnectionOffer
  );
  const showMissedConnectionOffers =
    enableDisruptionMissedConnection &&
    hasValidMissedConnectionOffers &&
    isHackerFareEnabledWithDisruption;

  const declineAll = useSelector(getDeclineAllFintechOptions);

  const noSelection = useMemo(
    () =>
      !declineAll &&
      selectedCfarOffer === undefined &&
      selectedChfarOffer === undefined &&
      selectedScheduleChangeOffer === undefined &&
      selectedMissedConnectionOffer === undefined,
    [
      selectedCfarOffer,
      selectedChfarOffer,
      selectedMissedConnectionOffer,
      selectedScheduleChangeOffer,
      declineAll,
    ]
  );

  useEffect(() => {
    trackEvent({
      eventName: FintechMerchandisingTrackingEvents.VIEWED,
      properties: {
        hasCfar: hasValidCfarOffers,
        hasChfar: hasValidChfarOffers,
        hasDisruption: hasValidScheduleChangeOffers,
        hasMissedConnection: hasValidMissedConnectionOffers,
      } as FintechMerchandisingViewedEventProperties,
    });
  }, [
    hasValidCfarOffers,
    hasValidChfarOffers,
    hasValidMissedConnectionOffers,
    hasValidScheduleChangeOffers,
  ]);

  useEffect(() => {
    setHeader({
      title: t("fintechSelection.header"),
    });
  }, [setHeader, t]);

  // Check if we have no offers
  useEffect(() => {
    if (
      !showCfarOffers &&
      !showChfarOffers &&
      !showScheduleChangeOffers &&
      !showMissedConnectionOffers
    ) {
      // Go to next step if flights were chosen, or back to flight list if no (i.e. refresh)
      selectedTrip?.tripId ? goToNextStep(true) : history.goBack();
    }
  }, [
    goToNextStep,
    hasValidCfarOffers,
    history,
    selectedTrip,
    showCfarOffers,
    showChfarOffers,
    showMissedConnectionOffers,
    showScheduleChangeOffers,
  ]);

  const clearOffers = useCallback(() => {
    dispatch(setSelectedCfarOffer(undefined));
    dispatch(setSelectedChfarOffer(undefined));
    dispatch(setSelectedScheduleChangeOffer(undefined));
    dispatch(setSelectedMissedConnectionOffer(undefined));
  }, [dispatch]);

  const resetError = useCallback(() => {
    setError(false);
  }, []);

  const handleDeclineAllOffers = useCallback(
    (decline: boolean) => {
      if (decline) {
        resetError();

        trackEvent({
          eventName: FintechMerchandisingTrackingEvents.DECLINE_ALL,
          properties: undefined,
        });
      }

      clearOffers();
      dispatch(setDeclineAllFintechOptions(decline));
    },
    [clearOffers, dispatch, resetError]
  );

  const scrollToError = () => {
    const element = document.getElementById("error-message");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleContinue = useCallback(() => {
    if (noSelection) {
      setError(true);
      scrollToError();
    } else {
      const eventProperties: FintechMerchandsingContinueEvent = {
        eventName: FintechMerchandisingTrackingEvents.CONTINUE_CLICKED,
        properties: {
          added_cfar: !!selectedCfarOffer?.quoteId,
          added_disruption: !!selectedScheduleChangeOffer,
          added_missed_connection: !!selectedMissedConnectionOffer,
          added_chfar: !!selectedChfarOffer,
        },
      };
      trackEvent(eventProperties);
      onContinue();
    }
  }, [
    noSelection,
    onContinue,
    selectedCfarOffer,
    selectedScheduleChangeOffer,
    selectedMissedConnectionOffer,
    selectedChfarOffer,
  ]);

  return (
    <>
      {/* CHFAR Snackbar */}
      <Snackbar open={!!selectedChfarOffer}>
        {t("fintechSelection.fintechAddedNotificationMessage", {
          fintechProduct: t("chOffer.title"),
        })}
      </Snackbar>

      {/* CHFAR Snackbar */}
      <Snackbar open={!!selectedCfarOffer?.quoteId}>
        {t("fintechSelection.fintechAddedNotificationMessage", {
          fintechProduct: t("cfarOffers.title"),
        })}
      </Snackbar>

      {/* Missed Connection Snackbar */}
      <Snackbar open={!!selectedMissedConnectionOffer}>
        {t("fintechSelection.fintechAddedNotificationMessage", {
          fintechProduct: t("fintechSelection.missedConnectionTitle"),
        })}
      </Snackbar>

      {/* Disruption Snackbar */}
      <Snackbar open={!!selectedScheduleChangeOffer}>
        {t("fintechSelection.fintechAddedNotificationMessage", {
          fintechProduct: t("disruptionPurchase.header"),
        })}
      </Snackbar>

      <Box px="1rem" mb="4rem" className="fintech-selection">
        <Typography variant="body1" className="body-text">
          {t("fintechSelection.description")}
        </Typography>

        <Chip
          id="error-message"
          icon={
            <img
              alt="fintech type icon"
              src={
                error
                  ? clientContext?.assets?.warningError
                  : clientContext?.assets?.warning
              }
            />
          }
          label={t("fintechSelection.selectOrDecline")}
          className={clsx("message", { error })}
        />
        <Box>
          <CfarFintechOptionCard disabled={declineAll} onChange={resetError} />
          <ChfarFintechOptionCard disabled={declineAll} onChange={resetError} />
          <ScheduleChangeFintechOptionCard
            disabled={declineAll}
            onChange={resetError}
          />
          <MissedConnectionFintechOptionCard
            disabled={declineAll}
            onChange={resetError}
          />
          <Slot
            id="flight-fintech-selection-page-decline-all"
            title={t?.("fintechSelection.declineAllTitle")}
            description={t?.("fintechSelection.declineAllDescription")}
            checked={declineAll}
            setChecked={handleDeclineAllOffers}
            disabled={false}
            component={
              <DeclineAllFintechOptions
                title={t?.("fintechSelection.declineAllTitle")}
                description={t?.("fintechSelection.declineAllDescription")}
                checked={declineAll}
                setChecked={handleDeclineAllOffers}
                disabled={false}
              />
            }
          />
        </Box>
      </Box>
      <MobileFloatingSection
        className="fintech-purchase-floating-section fintech-floating-section"
        primaryButton={{
          children: t?.("continue"),
          onClick: handleContinue,
          className: "fintech-purchase-button",
          wrapperClassName: "fintech-purchase-button-container",
        }}
      />
    </>
  );
};
