import { useState } from "react";
import {
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";

import { IOption, LabelDropDown, GenericDropdown, Slot } from "@hopper-b2b/ui";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { useApiConfigSelector } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { trackEvent } from "@hopper-b2b/api";

import { IFlightNumberFilter } from "../../../../../../reducer";
import { generateTrackingEvent } from "../../../../../../actions/actions";
import { IFlightNumberFilterSelectionBase } from "./index";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";
import "./styles.scss";

export const FlightNumberFilterSelectionComponent = (
  props: IFlightNumberFilterSelectionBase
) => {
  const {
    allAirlines,
    flightNumbersByAirline,
    setFlightNumberFilter,
    flightNumberFilter = [],
    departureDate,
    showDropdownContentOnly,
  } = props;

  const { t, language: locale } = useI18nContext();

  const departureDateString = departureDate
    ? formatDateTime(
        departureDate,
        locale,
        DateTimeFormatStyle.ShortMonthDayShortWeekday
      )
    : "";

  const [airlineValue, setAirlineValue] = useState("");
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);
  const toOptions = (values: string[], labelPrefix?: string): IOption[] => {
    return values.reduce((result: IOption[], value) => {
      const label = labelPrefix ? `${labelPrefix} ${value}` : value;
      const option: IOption = { value, label };
      result.push(option);
      return result;
    }, []);
  };

  const handleAirlineChange = (airlineValue: string) => {
    setAirlineValue(airlineValue);
    setFlightNumberFilter([]);
  };

  const handleFlightNumberSelection = (newValue: string) => {
    const filterValue: IFlightNumberFilter = {
      airlineCode: airlineValue,
      flightNumber: newValue,
    };

    const shouldRemoveFlight = flightNumberFilter.some(
      (item) =>
        item.airlineCode === filterValue.airlineCode &&
        item.flightNumber === filterValue.flightNumber
    );

    if (shouldRemoveFlight) {
      // Read as: if flight number or airline code is not the same, it is not the same flight and we should not remove.
      const newFilters = flightNumberFilter.filter(
        (item) =>
          item.airlineCode !== filterValue.airlineCode ||
          item.flightNumber !== filterValue.flightNumber
      );
      setFlightNumberFilter(newFilters);
    } else {
      setFlightNumberFilter([...flightNumberFilter, filterValue]);
    }
    trackEvent(generateTrackingEvent("flight number"), apiConfig);
  };

  const isChecked = (option: string): boolean => {
    return flightNumberFilter.some((currentOption: IFlightNumberFilter) => {
      return (
        currentOption.flightNumber === option &&
        airlineValue === currentOption.airlineCode
      );
    });
  };

  const renderFlightNumberOptions = (options: IOption[]) => {
    return options.map((option: IOption) => (
      <FormControlLabel
        className={"flight-number-options"}
        key={option.value}
        control={
          <Checkbox
            checked={isChecked(option.value)}
            onChange={() => handleFlightNumberSelection(option.value)}
            value={option}
          />
        }
        labelPlacement={"start"}
        label={option.label}
      />
    ));
  };

  const renderDropdownContent = () => {
    return (
      <Box className={"flight-number-selection-root"}>
        <Box className="flight-number-selection-container">
          <Box className="header-container">
            <Typography variant="body1" className={"header-text"}>
              {t("searchFilter.flightNumberHeader", { departureDateString })}
            </Typography>
          </Box>
          <Slot
            id="mobile-flight-number-filter-selection"
            options={allAirlines}
            onChange={(newValue) => handleAirlineChange(newValue)}
            value={airlineValue}
            flightNumberOptions={renderFlightNumberOptions(
              toOptions(
                flightNumbersByAirline[airlineValue] ?? [],
                airlineValue
              )
            )}
            component={
              <>
                <Box className="airline-selection">
                  <Box>
                    <LabelDropDown
                      classes={["airline-selection-menu"]}
                      options={allAirlines}
                      label={""}
                      ariaLabel={"Airline Selector"}
                      onChange={(newValue) => handleAirlineChange(newValue)}
                      value={airlineValue}
                    />
                  </Box>
                </Box>
                {airlineValue && (
                  <Box className="flight-number-selection">
                    <FormControl component="fieldset">
                      <FormGroup>
                        {renderFlightNumberOptions(
                          toOptions(
                            flightNumbersByAirline[airlineValue] ?? [],
                            airlineValue
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                )}
              </>
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box className={"flight-number-selection-dropdown"}>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={"b2b-shop-filter"}
          popoverClassName={clsx("flight-number-selection-popover", "b2b")}
          ariaLabel={`Flight Number Filter`}
          dropdownLabel={t("searchFilter.flightNumber")}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </Box>
  );
};
