import { Box, Typography } from "@material-ui/core";

import { useI18nContext } from "@hopper-b2b/i18n";
import { MobileFloatingButton } from "@hopper-b2b/ui";
import "./styles.scss";

export interface IRequestSubmittedStepProps {
  backToTrips: () => void;
}

const defaultProps: Partial<IRequestSubmittedStepProps> = {};

const RequestSubmittedStep = (
  props: IRequestSubmittedStepProps
): JSX.Element => {
  const { backToTrips } = props;
  const { t } = useI18nContext();

  return (
    <Box className="exchange-request-submitted-step">
      <Box className="submitted-copy-container">
        <Typography className="submitted-title" variant="h3">
          {t("exchangeable.submit.success.title")}
        </Typography>
        <Typography className="submitted-body">
          {t("exchangeable.submit.success.body1")}
        </Typography>
        <Typography className="submitted-body">
          {t("exchangeable.submit.success.body2")}
        </Typography>
      </Box>
      <Box className="submitted-step-actions">
        <MobileFloatingButton
          wide
          className="exchange-done-btn"
          onClick={backToTrips}
        >
          {t("done")}
        </MobileFloatingButton>
      </Box>
    </Box>
  );
};

RequestSubmittedStep.defaultProps = defaultProps;

export default RequestSubmittedStep;
