import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { trackEvent } from "@hopper-b2b/api";
import {
  CartSelectors,
  ErrorModal,
  LodgingSelectors,
  ParentState,
  PassengerErrorModalTypes,
  PassengerEventTypes,
  PassengerSelectors,
  SimplePassengerPicker,
  commonSelectors,
  getParentState,
  parseLodgingQueryString,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CHOOSE_TRAVELER,
  IPerson,
  LodgingCheckoutTrackingEvents,
  PersonId,
  ProductType,
} from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useTrackEvents } from "@hopper-b2b/lodging";
import { BackButton } from "@hopper-b2b/ui";

import { Event, TEvent } from "../../events";
import { PATH_HOTELS_SEARCH } from "../../../../utils/urlPaths";
import style from "./styles.module.scss";
import clsx from "clsx";
import { useDarkModePreferred } from "../../../../utils/colors";

export const ConnectedPassengerInformation = (props) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();

  const send = useCheckoutSend<TEvent>();

  const sessionInfo = useSelector(commonSelectors.getSessionInfo);

  const isLoading = useSelector(
    PassengerSelectors.getIsPassengerInformationLoading
  );
  const userPassengers = useSelector(PassengerSelectors.getUserPassengers);

  const allSelectedPassengerIds = useSelector(
    PassengerSelectors.getSelectedPassengerIds
  );
  const currentPassenger = useSelector(
    PassengerSelectors.getCurrentUserPassenger
  );
  const openPassengerFormModal = useSelector(
    PassengerSelectors.getOpenPassengerFormModal
  );
  const openPassengerPicker = useSelector(
    PassengerSelectors.getOpenPassengerPicker
  );

  const stateValue = useSelector(commonSelectors.getStateValue) as string;
  const parentState = getParentState(stateValue);

  const trackLodgingEvent = useTrackEvents();

  const isDarkMode = useDarkModePreferred();

  const titles = useMemo(
    () => ({
      travelerInfoTitle:
        openPassengerFormModal && currentPassenger?.id
          ? t("editTraveler")
          : t("passengerInformation.addPassenger"),
      travelerInfoSubtitle: t("hotelAddTravelerHeader"),
      frequentFlyerTitle: t("passengerInformation.frequentFlyerTitle"),
      additionalInfoTitle: t("passengerInformation.additionalInfo"),
      adultTitle: t("passengerInformation.adult"),
      childTitle: t("passengerInformation.child"),
      infantSeatTitle: t("passengerInformation.infant"),
      infantLapTitle: t("passengerInformation.infant"),
      addTravelers: t("passengerInformation.travelerDetailsTitle"),
      travelerSelectSubtitle: t("lodgingCheckout.passengerSelectSubtitle"),
      noTravelersSelectSubtitle: t("lodgingCheckout.passengerSelectSubtitle"),
    }),
    [openPassengerFormModal, currentPassenger?.id, t]
  );

  const handleCloseForm = () => {
    send({ type: PassengerEventTypes.OPEN_PASSENGER_PICKER });
  };

  // TODO: Remove logic below when lodging re-quote is supported
  const isSelectedPassengerIdsChanged = useMemo(() => {
    const { selectedPassengerIds } = parseLodgingQueryString(history);
    return !selectedPassengerIds.every((id) =>
      allSelectedPassengerIds.includes(id)
    );
  }, [history, allSelectedPassengerIds]);
  const isCartLocked = useSelector(CartSelectors.getCartQuoteIsLocked);
  const availabilityLink = useSelector(LodgingSelectors.getAvailabilityLink);

  const handleContinue = useCallback(() => {
    if (isCartLocked && isSelectedPassengerIdsChanged) {
      history.replace(availabilityLink);
    } else {
      send({ type: Event.NEXT, productType: ProductType.LODGING });
    }
  }, [
    availabilityLink,
    history,
    isCartLocked,
    isSelectedPassengerIdsChanged,
    send,
  ]);

  const handleDeletePassenger = (personId: string) => {
    send({ type: PassengerEventTypes.DELETE_PASSENGER, personId });
  };

  const handleEditClick = (passenger: IPerson) => {
    send({ type: PassengerEventTypes.SET_CURRENT_PASSENGER, passenger });
    send(PassengerEventTypes.OPEN_PASSENGER_FORM);
  };

  const handleGoBack = useCallback(() => {
    if (isCartLocked) {
      send(Event.GO_TO_CONTACT_INFORMATION);
    } else {
      history.goBack();
    }
  }, [history, isCartLocked, send]);

  const handleSelectPassenger = (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => {
    if (!allSelectedPassengerIds.includes(passengerId)) {
      trackLodgingEvent(LodgingCheckoutTrackingEvents.hotel_choose_traveler);
    }
    send({
      type: PassengerEventTypes.SELECT_PASSENGER,
      passengerId,
      singleTravelerWorkflow,
    });
  };

  const handleUpdatePassenger = (
    passenger: IPerson,
    singleTravelerWorkflow?: boolean
  ) => {
    const { passport, ...restOfPassenger } = passenger;
    if (!allSelectedPassengerIds.includes(passenger.id)) {
      trackEvent({
        eventName: CHOOSE_TRAVELER,
        properties: {},
      });
      trackLodgingEvent(LodgingCheckoutTrackingEvents.hotel_choose_traveler);
    }
    send({
      type: PassengerEventTypes.UPDATE_PASSENGER,
      person: passport.number ? passenger : restOfPassenger,
      singleTravelerWorkflow,
    });
  };

  const handleClickAddTraveler = () => {
    send({
      type: PassengerEventTypes.SET_CURRENT_PASSENGER,
      passenger: {} as IPerson,
    });
    send(PassengerEventTypes.OPEN_PASSENGER_FORM);
  };

  const handleOpenPassengerPicker = () => {
    send(PassengerEventTypes.OPEN_PASSENGER_PICKER);
  };

  useEffect(() => {
    if (parentState === ParentState.passengerInformation) {
      trackLodgingEvent(LodgingCheckoutTrackingEvents.hotel_view_travelers);
    }
  }, [parentState, trackLodgingEvent]);

  const headerTitle =
    openPassengerFormModal && currentPassenger?.id
      ? t("editTraveler")
      : openPassengerFormModal
      ? t("passengerInformation.addPassenger")
      : t("passengerInformation.guests");

  return (
    <>
      <div className={style.passengerSelectHeaderContainer}>
        <BackButton className={style.backButton} onClick={handleGoBack} />
        <h1 className={style.passengerSelectHeader}>{headerTitle}</h1>
      </div>
      <SimplePassengerPicker
        {...props}
        isLoading={isLoading}
        userPassengers={userPassengers}
        userInfo={sessionInfo?.userInfo}
        selectedPassengerIds={allSelectedPassengerIds}
        currentPassenger={currentPassenger}
        openPassengerFormModal={openPassengerFormModal}
        openPassengerPicker={openPassengerPicker}
        handleCloseForm={handleCloseForm}
        handleContinue={handleContinue}
        handleDeletePassenger={handleDeletePassenger}
        handleEditClick={handleEditClick}
        handleGoBack={handleGoBack}
        handleSelectPassenger={handleSelectPassenger}
        handleUpdatePassenger={handleUpdatePassenger}
        handleClickAddTraveler={handleClickAddTraveler}
        titles={titles}
        handleOpenPassengerPicker={handleOpenPassengerPicker}
        isMobile={matchesMobile}
        hideAdditionalInfoSection
        hideFrequentFlyerSection
        singleTravelerWorkflow
        modalClassName={clsx(
          style.passengerPickerModal,
          isDarkMode ? "dark-mode" : null
        )}
        productType={ProductType.LODGING}
        className={clsx(
          style.passengerPickerWrapper,
          isDarkMode ? "dark-mode" : null
        )}
        mobilePaperClassName={style.passengerPickerPaper}
      />
    </>
  );
};

export const ConnectedErrorModal = () => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();
  const open = useSelector(PassengerSelectors.getPassengerErrorOpen);
  const passengerError = useSelector(PassengerSelectors.getPassengerError);
  const clearError = () =>
    send(PassengerEventTypes.CLEAR_PASSENGER_INFORMATION_ERROR);
  const searchAgain = () => {
    clearError();
    history.push(PATH_HOTELS_SEARCH);
  };
  const openPassengerPicker = () => {
    send(PassengerEventTypes.OPEN_PASSENGER_PICKER);
    clearError();
  };

  const getErrorModalProps = (type?: PassengerErrorModalTypes) => {
    switch (type) {
      case PassengerErrorModalTypes.UpdatePassenger:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.FetchPassengers:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.ValidatePassengers:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.NoPassengers:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.noPassengersSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.DeletePassenger:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      default:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
    }
  };
  const errorModalProps = getErrorModalProps(passengerError?.type);

  return (
    <ErrorModal
      open={open}
      {...errorModalProps}
      productType={ProductType.LODGING}
    />
  );
};
