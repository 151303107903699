import { useCallback, useState } from "react";
import { ReactComponent as Chevron } from "../../../assets/client/chevron.svg";
import Coin from "../../../assets/client/coin.svg";
import { ButtonWrap, ChoiceButton, Divider } from "@hopper-b2b/ui";
import { FintechHalfSheet } from "../../../components/FintechHalfSheet";
import styles from "./styles.module.scss";
import { WalletOffer } from "@hopper-b2b/types";
import { HotelOfferTermsModal } from "../../../components/TermsAndConditionsWrapper/component";
import { useI18nContext } from "@hopper-b2b/i18n";

interface OfferSelectionCheckbox {
  offer: WalletOffer;
  selected: boolean;
  onCheckClick: () => void;
}

const OfferSelectionCheckbox = ({ offer, selected, onCheckClick }) => {
  const { t } = useI18nContext();
  const [isHalfSheetOpen, setIsHalfSheetOpen] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const openHalfSheetHandler = useCallback(() => {
    setIsHalfSheetOpen(true);
  }, []);

  const closeHalfSheetHandler = useCallback(() => {
    setIsHalfSheetOpen(false);
  }, []);

  return (
    <>
      <div className={styles.OfferSelectionCheckbox}>
        <div className="offer-selection-left">
          <ChoiceButton
            onClick={onCheckClick}
            isChosen={selected}
            disabled={false}
            className={styles.Checkbox}
            message={""}
          />
          <ButtonWrap onClick={openHalfSheetHandler} className="content">
            <p className="title">{offer.offerContent.title}</p>
            <p className="description">{offer.offerContent.restrictions}</p>
          </ButtonWrap>
        </div>
        <ButtonWrap onClick={openHalfSheetHandler}>
          <Chevron className="chevron" />
        </ButtonWrap>
      </div>
      {/* half sheet */}
      <FintechHalfSheet
        isOpen={isHalfSheetOpen}
        onClose={closeHalfSheetHandler}
        icon={Coin}
        title={offer.offerContent.title}
        description={
          <div>
            <p>{offer.offerContent.lineItemDescription}</p>
            <p className={styles.OfferSelectionCheckboxFinePrint}>
              {offer.offerContent.restrictions}
            </p>
            <Divider />
            <ButtonWrap
              className={styles.HotelVoucherTermButton}
              onClick={() => setOpenTermsModal(true)}
            >
              <p className={styles.HotelVoucherTerms}>
                {t("termsAndConditions")}
              </p>
              <Chevron />
            </ButtonWrap>
          </div>
        }
      />
      <HotelOfferTermsModal
        openModal={openTermsModal}
        setOpenModal={setOpenTermsModal}
      />
    </>
  );
};

export default OfferSelectionCheckbox;
