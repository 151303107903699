import { TripCategory } from "@hopper-b2b/types";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  getDepartureDate,
  getOrigin,
  getReturnDate,
} from "../../../search/reducer";
import { FlightShopStep } from "../../../shop/reducer";
import { getItineraryTripType } from "../../selectors";
import DesktopFlightShop from "./DesktopFlightShop";
import { FlightExchangeShop } from "./FlightExchangeShop";

export interface IExchangeShopStepProps {
  backToTrips: () => void;
  bookingId: string;
  onSearch: () => void;
  setExchangeStep: (exchangeStep: FlightShopStep) => void;
}

const defaultProps: Partial<IExchangeShopStepProps> = {};

const ExchangeShopStep = (props: IExchangeShopStepProps): JSX.Element => {
  const { backToTrips, bookingId, onSearch, setExchangeStep } = props;
  const { matchesMobile } = useDeviceTypes();
  const departureDate = useSelector(getDepartureDate);
  const origin = useSelector(getOrigin);
  const returnDate = useSelector(getReturnDate);
  const tripType = useSelector(getItineraryTripType);
  const isRoundTrip = tripType === TripCategory.ROUND_TRIP;

  return (
    <Box className="exchange-shop flight-shop-container">
      {matchesMobile ? (
        <FlightExchangeShop
          bookingId={bookingId}
          departureDate={departureDate}
          isRoundTrip={isRoundTrip}
          onShopDone={() => setExchangeStep(FlightShopStep.ReviewItinerary)}
          origin={origin}
          returnDate={returnDate}
          setOpenCalendarModal={() =>
            setExchangeStep(FlightShopStep.ExchangeDatesPicker)
          }
        />
      ) : (
        <DesktopFlightShop backToTrips={backToTrips} onSearch={onSearch} />
      )}
    </Box>
  );
};

ExchangeShopStep.defaultProps = defaultProps;

export default ExchangeShopStep;
