import { useEffect, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";

interface HorizontalScrollListProps<GenericItemType> {
  columns: Array<GenericItemType>;
  scrollingDivClassName?: string;
  parentDivClassName?: string;
  itemWidth?: number;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  renderColumn: (item: GenericItemType, index: number) => JSX.Element;
  selectedId: string | null;
  getItemId: (item: GenericItemType) => string;
  listItemClassName?: string;
  onItemScroll: (idx: number) => void;
}

export function HorizontalScrollList<GenericItemType>({
  columns,
  scrollingDivClassName,
  parentDivClassName,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  itemWidth,
  renderColumn,
  selectedId,
  getItemId,
  listItemClassName,
  onItemScroll,
}: HorizontalScrollListProps<GenericItemType>) {
  const parentRef = useRef<HTMLDivElement>();

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columns.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemWidth,
    gap: 16,
    paddingStart: 16,
    scrollPaddingStart: 16,
    paddingEnd: 16,
  });

  // Loads next page when end of list is reached
  useEffect(() => {
    const [lastItem] = [...columnVirtualizer.getVirtualItems()].reverse();
    if (!lastItem) {
      return;
    }
    if (
      lastItem.index >= columns.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    columnVirtualizer,
    hasNextPage,
    fetchNextPage,
    columns.length,
    isFetchingNextPage,
  ]);

  const handleScroll = () => {
    const parent = parentRef.current;
    if (parent) {
      // Measure the amount of pixels that have been scrolled to the left
      const scrolledLeft = parent.scrollLeft;
      // Calculate the item index, divide (and round) the amount scrolled by the item plus its piece of the gap
      // this will give an integer that should correspond
      const itemIndex = Math.round(scrolledLeft / (itemWidth + 16));

      columnVirtualizer.scrollToIndex(itemIndex, {
        align: "start",
        behavior: "smooth",
      });
      onItemScroll(itemIndex);
    }
  };

  useEffect(() => {
    if (typeof selectedId === "string") {
      const index = columns.findIndex(
        (c: GenericItemType) => getItemId(c) === selectedId
      );
      columnVirtualizer.scrollToIndex(index, {
        align: "start",
      });
    }
  }, [columnVirtualizer, columns, getItemId, selectedId]);

  return (
    <div
      ref={parentRef}
      className={clsx("List", parentDivClassName)}
      onMouseUp={handleScroll}
      onTouchEnd={handleScroll}
    >
      <div
        className={scrollingDivClassName}
        style={{
          width: `${columnVirtualizer.getTotalSize()}px`,
        }}
      >
        {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
          <div
            key={virtualColumn.index}
            className={listItemClassName}
            style={{
              width: `${itemWidth}px`,
              transform: `translateX(${virtualColumn.start}px)`,
            }}
          >
            {renderColumn(columns[virtualColumn.index], virtualColumn.index)}
          </div>
        ))}
      </div>
    </div>
  );
}
