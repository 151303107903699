import { Box, Collapse, Grid } from "@material-ui/core";
import { useState } from "react";
import clsx from "clsx";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  IPaymentLineItem,
  ISummaryLineItem,
} from "@hopper-b2b/types";
import { ActionLink, FlightPricingLineItem } from "@hopper-b2b/ui";
import { getEnvVariables } from "@hopper-b2b/utilities";

import PrevPaymentSection, {
  IPrevPaymentLineItem,
} from "../PrevPaymentSection/component";
import { TravelerRow } from "../TravelerRow";
import SummaryRow from "./SummaryRow";

import "./styles.scss";

export interface IPricingSummaryProps {
  enablePricingToggle?: boolean;
  passengerItems: FlightPricingLineItem[];
  payments: IPrevPaymentLineItem[];
  summaryItems: ISummaryLineItem[];
  totalPayable: IPaymentLineItem;
}

const defaultProps: Partial<IPricingSummaryProps> = {
  enablePricingToggle: false,
};

const PricingSummary = (props: IPricingSummaryProps): JSX.Element => {
  const {
    enablePricingToggle,
    passengerItems,
    payments,
    summaryItems,
    totalPayable,
  } = props;
  const client = getEnvVariables("clientName") as ClientName;
  const { t } = useI18nContext();
  const [showPricing, setShowPricing] = useState(false);

  return (
    <Box className="exchange-pricing-summary">
      <Box className="travelers-section">
        {passengerItems.map((passengerItem) => (
          <TravelerRow
            expanded={showPricing}
            key={passengerItem.lineTitle}
            pricing={passengerItem}
          />
        ))}
      </Box>
      <Collapse unmountOnExit in={showPricing} timeout="auto">
        {summaryItems.map((summaryItem, idx) => (
          <Box
            className="pricing-summary-section"
            key={`${summaryItem.type}-${idx}`}
          >
            <SummaryRow summary={summaryItem} />
          </Box>
        ))}
        {payments.length ? (
          <Box className="pricing-summary-section deductions">
            <PrevPaymentSection payments={payments} />
          </Box>
        ) : null}
      </Collapse>
      <Grid
        container
        direction={client === ClientName.UBER ? "column-reverse" : "column"}
      >
        <Grid item className="pricing-summary-section total">
          <SummaryRow
            summary={totalPayable}
            titles={{
              rewardsAccountText: (lastFour?: string) =>
                lastFour ? `Ending in ${lastFour}` : `Amount due:`,
              rewardsAppliedText: (accountName: string) =>
                `${accountName} Rewards applied:`,
              totalText: t("exchangeable.priceBreakdown.total"),
            }}
          />
        </Grid>
        {enablePricingToggle && (
          <Grid item>
            <ActionLink
              className="b2b show-pricing-action"
              content={
                showPricing
                  ? t("exchangeable.submit.hidePricing")
                  : t("exchangeable.submit.showPricing")
              }
              onClick={() => setShowPricing(!showPricing)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

PricingSummary.defaultProps = defaultProps;

export default PricingSummary;
