import { RoomProduct } from "@b2bportal/lodging-api";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@hopper-b2b/ui";

export interface RoomDetailsCtaProps {
  onClick: () => void;
  selectedProduct: RoomProduct;
}

export function RoomDetailsCta({
  onClick,
  selectedProduct,
}: RoomDetailsCtaProps) {
  const { t } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();

  return (
    <ActionButton
      onClick={onClick}
      fullWidth
      size="medium"
      message={t("reserveForPricePerNight", {
        price: formatPrice(
          selectedProduct?.nightlyDiscountAware.priceWithUnmanagedDiscounts
        ),
      })}
    />
  );
}
