import "@datadog/browser-logs/bundle/datadog-logs";
import { datadogRum } from "@datadog/browser-rum";
import { Environment } from "@hopper-b2b/utilities";
import { inspect } from "@xstate/inspect";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
// import "./nubank.index.scss";
import * as serviceWorker from "./serviceWorker";
import config from "./utils/config";
import { Root } from "./Root";
import { CLIENT_TOKEN, DATADOG_SITE, initializeRum } from "@hopper-b2b/datadog";
import { ClientName } from "@hopper-b2b/types";

process.env.NODE_ENV !== "production" &&
  inspect({
    iframe: false,
  });

const sessionId = uuidv4();
window.DD_LOGS.init({
  clientToken: CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: ClientName.NUBANK,
  version: config.REACT_APP_VERSION,
  env: config.ENV,
  sampleRate: 100,
  trackInteractions: true,
  beforeSend: (log: any) => {
    log.browser_session_id = sessionId;
    log.path = window.location.pathname;
  },
});

setTimeout(() => {
  initializeRum({
    enableSessionRecording: true,
    service: ClientName.NUBANK,
    env: config.ENV,
    version: config.REACT_APP_VERSION,
    allowedTracingUrls: [
      "http://localhost:3003",
      "https://nubank.eng.app-development.h4r.io/",
      "https://nubank.staging.hopper.com/",
      "https://nubank.hopper.com/",
    ],
  });
}, 1000);

setTimeout(() => {
  window.DD_LOGS.logger.info(
    `DD RUM: ${JSON.stringify(datadogRum.getInternalContext())}`
  );
}, 2000);

// Only use MOCK mode if enabled and not in production
if (config.ENV !== Environment.production && config.MOCK) {
  import("@hopper-b2b/mock").then(({ setupTenantWorker }) => {
    setupTenantWorker(ClientName.NUBANK).start();
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
