import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FareDetails,
  TrackingEventEntryPoint,
  TripCategory,
} from "@hopper-b2b/types";
import { ActionButton, FloatingBox, Slot } from "@hopper-b2b/ui";
import { hasHTSConnectSupport, useDeviceTypes } from "@hopper-b2b/utilities";
import { DisplayedPrice } from "@hopper-b2b/wallet";
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import styles from "./FlightPricingSummarySection.module.scss";

export type FlightPricingSummarySectionProps = {
  fareDetails: FareDetails;
  isOneWay: boolean;
  disabled?: boolean;
  hidePrice?: boolean;
  onClick: () => void;
};

export const FlightPricingSummarySection = ({
  fareDetails,
  isOneWay,
  disabled,
  hidePrice,
  onClick,
}: FlightPricingSummarySectionProps) => {
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();
  const isHTSConnect = hasHTSConnectSupport();

  const pricing = fareDetails?.paxPricings[0].pricing;

  const totalCostFiat: FiatPrice = useMemo(
    () => ({
      value:
        (pricing?.baseAmount?.fiat.value || 0) +
        (pricing?.taxAmount?.fiat.value || 0) +
        (pricing?.additionalMargin ? pricing?.additionalMargin.fiat.value : 0),
      currencyCode: pricing?.baseAmount.fiat.currencyCode,
      currencySymbol: pricing?.baseAmount.fiat.currencySymbol,
    }),
    [
      pricing?.additionalMargin,
      pricing?.baseAmount.fiat.currencyCode,
      pricing?.baseAmount.fiat.currencySymbol,
      pricing?.baseAmount.fiat.value,
      pricing?.taxAmount?.fiat.value,
    ]
  );

  const renderDesktop = () => {
    return (
      <Slot
        id="flight-shop-cta-section"
        totalPrice={totalCostFiat}
        tripCategory={isOneWay ? TripCategory.ONE_WAY : TripCategory.ROUND_TRIP}
        onClick={onClick}
        buttonDisabled={disabled}
        component={
          <Box p={3} className="flight-pricing-container">
            {!hidePrice && (
              <>
                <Box mx={2} mb={1} className="price-container">
                  <Grid
                    container
                    spacing={2}
                    direction={matchesMobile ? "row" : "column-reverse"}
                  >
                    <Grid item>
                      <Typography color="textSecondary">
                        {isOneWay
                          ? t("oneWayPerTraveler")
                          : t("roundTripPerTraveler")}
                      </Typography>
                    </Grid>
                    {matchesMobile && <Grid item xs />}
                    <Grid item>
                      <Typography variant="h4" className="price-text">
                        <DisplayedPrice
                          discountedCostFiat={
                            fareDetails.paxPricings[0].pricing
                              ?.totalWithDiscount?.fiat
                          }
                          totalCostFiat={totalCostFiat}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Slot
                  id="missed-connection-protection-outlined-button"
                  entryPoint={TrackingEventEntryPoint.Details}
                />
              </>
            )}
            <ActionButton
              onClick={onClick}
              disabled={disabled}
              fullWidth
              message={t("flightShopReview.continueToCheckout")}
              className="button-container"
            />
          </Box>
        }
      />
    );
  };

  const renderMobile = () => {
    return (
      <Box
        p={3}
        className={clsx(
          styles.flightPricingContainerMobile,
          "flight-pricing-container-mobile"
        )}
      >
        <Box
          mx={2}
          mb={1}
          className={clsx(styles.container, "price-container")}
        >
          <div>
            <Typography variant="h4" className="price-text">
              <DisplayedPrice
                discountedCostFiat={
                  fareDetails.paxPricings[0].pricing?.totalWithDiscount?.fiat
                }
                totalCostFiat={totalCostFiat}
              />
            </Typography>
            <Typography color="textSecondary">
              {`${
                isOneWay ? t("oneWayPerTraveler") : t("roundTripPerTraveler")
              } - ${t("taxesIncluded")}`}
            </Typography>
          </div>
          <ActionButton
            onClick={onClick}
            disabled={disabled}
            fullWidth
            message={t("continue")}
            className="button-container"
          />
        </Box>
        <div>
          <Divider />
          <Slot
            id="missed-connection-protection-outlined-button"
            entryPoint={TrackingEventEntryPoint.Details}
          />
        </div>
      </Box>
    );
  };

  return (
    <FloatingBox>
      <Paper
        variant="outlined"
        className={clsx({ [styles.mobile]: matchesMobile })}
      >
        {matchesMobile && isHTSConnect ? renderMobile() : renderDesktop()}
      </Paper>
    </FloatingBox>
  );
};
