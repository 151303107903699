import { ChildMachineConfig } from "../../../types/common";
import { ActionTypes } from "./actions";
import { AirPriceDropState } from "./types";

export const getAirPriceDropMachine = (config: ChildMachineConfig) => {
  return {
    initial: AirPriceDropState.addProduct,
    states: {
      // price drop product will added automatically without ui page. Only add product state needed.
      [AirPriceDropState.addProduct]: {
        always: [
          {
            actions: ActionTypes.addPriceDropProduct,
            target: config.nextState,
          },
        ],
      },
    },
  };
};
