import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SlicePickerModalContent } from "@hopper-b2b/ui";
import { useDispatch, useSelector } from "react-redux";

import { setOutboundSelection, setReturnSelection } from "../../reducer/search";
import {
  getOutboundSelection,
  getReturnSelection,
} from "../../reducer/selectors";
import { getAirlines, getAirports, getItinerary } from "../../selectors";

import "./styles.scss";

export interface ISlicePickerStepProps {
  onContinue: () => void;
}

const defaultProps: Partial<ISlicePickerStepProps> = {};

const SlicePickerStep = (props: ISlicePickerStepProps): JSX.Element => {
  const { onContinue } = props;
  const dispatch = useDispatch();
  const { t } = useI18nContext();
  const airlines = useSelector(getAirlines);
  const airports = useSelector(getAirports);
  const flight = useSelector(getItinerary);
  const outboundSelection = useSelector(getOutboundSelection);
  const returnSelection = useSelector(getReturnSelection);

  const modOutbound = outboundSelection === ExchangeActionEnum.Change;
  const modReturn = returnSelection === ExchangeActionEnum.Change;

  const onSliceToggle = (sliceIdx: number) => {
    if (sliceIdx === 0) {
      dispatch(
        setOutboundSelection(
          modOutbound ? ExchangeActionEnum.Keep : ExchangeActionEnum.Change
        )
      );
    } else {
      dispatch(
        setReturnSelection(
          modReturn ? ExchangeActionEnum.Keep : ExchangeActionEnum.Change
        )
      );
    }
  };

  return (
    <SlicePickerModalContent
      airlines={airlines}
      airports={airports}
      checkboxLabel={t("exchangeable.slicePicker.modify")}
      className="flight-exchange-slice-picker"
      flight={flight}
      onContinue={onContinue}
      onSliceToggle={onSliceToggle}
      title={t("exchangeable.slicePicker.title")}
    />
  );
};

SlicePickerStep.defaultProps = defaultProps;

export default SlicePickerStep;
