import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  useEnableCfar,
  useEnableChfar,
  useEnableScheduleChange,
  useEnableMissedConnection,
} from "@hopper-b2b/utilities";
import { fetchCfarOffers } from "../../cfar/actions/actions";
import { fetchChfarOffer } from "../../chfar/actions/actions";
import { fetchDisruptionOffers } from "../../disruption/actions/actions";

export const useFetchFintechProducts = () => {
  const dispatch = useDispatch();

  const enableScheduleChange = useEnableScheduleChange();
  const enableMissedConnection = useEnableMissedConnection();

  const showFlightCFAR = useEnableCfar();
  const showFlightChFar = useEnableChfar();

  return useCallback(
    (tripId: string, fareId: string) => {
      if (enableScheduleChange || enableMissedConnection) {
        dispatch(fetchDisruptionOffers());
      }
      if (showFlightCFAR) {
        tripId && fareId && dispatch(fetchCfarOffers(tripId, fareId));
      }
      if (showFlightChFar) {
        tripId && fareId && dispatch(fetchChfarOffer(tripId, fareId));
      }
    },
    [
      dispatch,
      enableMissedConnection,
      enableScheduleChange,
      showFlightCFAR,
      showFlightChFar,
    ]
  );
};
