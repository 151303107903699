import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ITripTerminus, SelfServeEvents } from "@hopper-b2b/types";
import { CurrencyFormatters, useUberBridge } from "@hopper-b2b/utilities";
import { Box, Collapse, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as searchActions } from "../../../search/actions";
import { LocationSearchWithDropdowns } from "../../../search/components/MobileFlightSearchControl/components/LocationSearchWithDropdowns";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../../search/reducer";
import { getChangeFee, getMarketingAirline } from "../../reducer/selectors";
import { getBaseEventProps, getIsFtc } from "../../selectors";

import "./styles.scss";
import { Slot } from "@hopper-b2b/ui";
import { LocationModal } from "../../../shop";

export interface IMobileTerminiPickerProps {
  onContinue: () => void;
}

const defaultProps: Partial<IMobileTerminiPickerProps> = {};

const MobileTerminiPicker = (props: IMobileTerminiPickerProps): JSX.Element => {
  const { onContinue } = props;
  const dispatch = useDispatch();
  const { t } = useI18nContext();

  const airline = useSelector(getMarketingAirline);
  const baseEventProps = useSelector(getBaseEventProps);
  const changeFee = useSelector(getChangeFee);
  const destination = useSelector(getDestination);
  const isFTC = useSelector(getIsFtc);
  const origin = useSelector(getOrigin);
  const departureDate = useSelector(getDepartureDate);
  const returnDate = useSelector(getReturnDate);

  const [showPolicy, setShowPolicy] = useState(true);
  const [openLocationModal, setOpenLocationModal] = useState(false);

  const { postEvent } = useUberBridge();

  const changeFeeAmt = CurrencyFormatters.get(changeFee?.currency).format(
    changeFee?.amount
  );

  const onDestinationChange = (newDest: ITripTerminus) => {
    dispatch(searchActions.setDestination(newDest));
  };

  const onOriginChange = (newOrigin: ITripTerminus) => {
    dispatch(searchActions.setOrigin(newOrigin));
  };

  useEffect(() => {
    const eventName = isFTC
      ? SelfServeEvents.ViewedFTCExchangeLanding
      : SelfServeEvents.ViewedExchangeLanding;

    trackEvent({
      eventName,
      properties: baseEventProps,
    });
    postEvent(eventName, baseEventProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenLocationModal = useCallback(
    () => setOpenLocationModal(true),
    []
  );

  const handleCloseLocationModal = useCallback(
    () => setOpenLocationModal(false),
    []
  );

  const redirectToCalendar = useCallback(() => {
    if (
      onContinue &&
      origin &&
      destination &&
      origin.id.code.code !== destination.id.code.code
    ) {
      onContinue();
    }
  }, [destination, onContinue, origin]);

  const policyTitle = useMemo(() => {
    return changeFee?.amount
      ? t("exchangeable.landing.title.fee", {
          airline: airline,
          amount: changeFeeAmt,
          interpolation: {
            defaultVariables: {
              airline: t("exchangeable.landing.fallbackAirline"),
            },
          },
        })
      : t("exchangeable.landing.title.noFee", {
          airline: airline,
          interpolation: {
            defaultVariables: {
              airline: t("exchangeable.landing.fallbackAirline"),
            },
          },
        });
  }, []);

  return (
    <>
      <Slot
        id="exchange-landing-screen"
        onContinue={onContinue}
        airline={airline}
        origin={origin}
        destination={destination}
        handleChangeDates={redirectToCalendar}
        handleChangeLocations={handleOpenLocationModal}
        departureDate={departureDate}
        returnDate={returnDate}
        policyTitle={policyTitle}
        component={
          <Box className="airport-picker-container">
            <Collapse
              classes={{
                root: "exchange-policy-info-container",
                wrapper: "policy-info",
              }}
              in={showPolicy}
            >
              <Typography className="policy-title" variant="h3">
                {policyTitle}
              </Typography>
              <Typography className="policy-body" variant="body1">
                {t("exchangeable.landing.body")}
              </Typography>
            </Collapse>
            <Box className="location-search-container">
              <LocationSearchWithDropdowns
                hideCategoryBar
                autoSearch={false}
                destination={destination}
                onComplete={onContinue}
                onBlur={() => setShowPolicy(true)}
                onFocus={() => setShowPolicy(false)}
                origin={origin}
                popperClassName="exchange-search-airport-popper"
                popupIcon={null} // hides autocomplete dropdown icon
                setDestination={onDestinationChange}
                setOrigin={onOriginChange}
                setTripCategory={undefined}
              />
            </Box>
          </Box>
        }
      />
      {openLocationModal && (
        <LocationModal
          open={openLocationModal}
          onClose={handleCloseLocationModal}
          origin={origin}
          destination={destination}
          setOrigin={onOriginChange}
          setDestination={onDestinationChange}
          onSubmit={handleCloseLocationModal}
        />
      )}
    </>
  );
};

MobileTerminiPicker.defaultProps = defaultProps;

export default MobileTerminiPicker;
