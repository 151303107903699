import { useMemo } from "react";
import dayjs from "dayjs";
import { MobileFloatingButton } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ITripTerminus } from "@hopper-b2b/types";
import { ReactComponent as Calendar } from "../../../assets/client/calendar-unfilled.svg";
import { ReactComponent as LocationMarker } from "../../../assets/client/location-marker.svg";
import "./styles.scss";

export interface IExchangeLandingScreenProps {
  onContinue: () => void;
  origin: ITripTerminus;
  destination: ITripTerminus;
  handleChangeDates: () => void;
  handleChangeLocations: () => void;
  returnDate: Date;
  departureDate: Date;
  policyTitle: string | JSX.Element;
}

const ExchangeLandingScreen = ({
  onContinue,
  origin,
  destination,
  handleChangeDates,
  handleChangeLocations,
  returnDate,
  departureDate,
  policyTitle,
}: IExchangeLandingScreenProps): JSX.Element => {
  const { t } = useI18nContext();

  const dateString = useMemo(() => {
    return returnDate
      ? t("departureToReturn", {
          departureDate: dayjs(departureDate).format("ddd, D MMM"),
          returnDate: dayjs(returnDate).format("ddd, D MMM"),
        })
      : dayjs(departureDate).format("ddd, D MMM");
  }, [departureDate, returnDate]);

  return (
    <div className="exchange-landing-screen nubank slot">
      <div className="exchange-landing-screen-header">
        <h3 className="exchange-landing-screen-title">
          {t("exchangeable.landing.body")}
        </h3>
        <p className="exchange-landing-screen-subtitle">{policyTitle}</p>
      </div>
      <div className="exchange-landing-screen-information">
        <h3 className="exchange-landing-screen-information-title">
          {t("importantInfoTitle")}:
        </h3>
        <ol className="exchange-landing-screen-information-content">
          <li className="exchange-landing-screen-information-list-item">
            {t("exchangeable.landing.terms.passengers")}
          </li>
          <li className="exchange-landing-screen-information-list-item">
            {t("exchangeable.landing.terms.paymentMethod")}
          </li>
          <li className="exchange-landing-screen-information-list-item">
            {t("exchangeable.landing.terms.fees")}
          </li>
        </ol>
      </div>
      <div className="exchange-landing-screen-buttons">
        <div className="exchange-landing-screen-button-section">
          <div className="exchange-landing-screen-button-section-content">
            <div className="left">
              <Calendar className="calendar-icon" />
            </div>
            <div className="center">
              <div className="title">{t("dates")}</div>
              <div className="label">{dateString}</div>
            </div>
          </div>
          <div className="exchange-landing-screen-button-section-button">
            <div className="right">
              <button className="change-button" onClick={handleChangeDates}>
                {t("change")}
              </button>
            </div>
          </div>
        </div>
        <div className="exchange-landing-screen-button-section">
          <div className="exchange-landing-screen-button-section-content">
            <div className="left">
              <LocationMarker className="location-icon" />
            </div>
            <div className="center">
              <div className="title">{t("searchControl.airport")}</div>
              <div className="label">
                {t("originToDestination", {
                  origin: origin?.label,
                  destination: destination?.label,
                })}
              </div>
            </div>
          </div>
          <div className="exchange-landing-screen-button-section-button">
            <div className="right">
              <button className="change-button" onClick={handleChangeLocations}>
                {t("change")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="exchange-landing-screen-docked-section">
        <MobileFloatingButton
          className="exchange-landing-screen-docked-button"
          onClick={onContinue}
          wrapperClassName="exchange-landing-screen-docked-button-wrapper"
          wide
        >
          {t("searchButton")}
        </MobileFloatingButton>
      </div>
    </div>
  );
};

export default ExchangeLandingScreen;
