import * as React from "react";
import clsx from "clsx";
import workingBunnies from "../../assets/gifs/hopper/working_bunnies.gif";

export interface IWorkingBunniesProps {
  classes?: string[];
}

const WorkingBunnies = ({
  classes,
}: IWorkingBunniesProps): React.ReactElement => (
  <img
    className={clsx("working-bunnies", classes)}
    src={workingBunnies}
    alt="working bunnies animation"
  />
);

export default WorkingBunnies;
