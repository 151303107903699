import { getEnvVariables } from "./envVariableHelpers";

export const hasHTSConnectSupport = () => {
  const tenant = getEnvVariables("clientName");
  const tenantsWithSupportMap = {
    volaris: true,
    flair: true,
  };

  return Boolean(tenantsWithSupportMap[tenant]);
};
