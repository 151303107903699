export const TERMS_AND_CONDITIONS_DESKTOP = "Hopper Terms & Conditions";
export const TERMS_AND_CONDITIONS_MOBILE = ["Hopper Terms", "& Conditions"];
export const TERMS_OF_USE_DESKTOP = "Site Terms of Use";
export const TERMS_OF_USE_MOBILE = ["Site Terms", "of Use"];
export const PRIVACY = "Privacy Policy";
export const FAQS = "FAQs";

export const LEGAL = `© ${new Date().getFullYear()} Hopper`;
export const POWERED_BY = "Powered by";

export const PRIVACY_LINK = "https://hopper.com/legal/privacy-policy";
export const TERMS_AND_CONDITIONS_LINK =
  "https://hopper.com/legal/terms-and-conditions";
export const FAQS_LINK =
  "https://help.hopper.com/en_us/categories/flights-Hywr1lPz_";
