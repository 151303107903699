import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useEffect } from "react";
import { RouteComponentProps, matchPath, useLocation } from "react-router";

import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CHANGED_TRIP_TYPE,
  ChangeTripTypeScreenEnum,
  ITripTerminus,
  TripCategory,
} from "@hopper-b2b/types";
import {
  ActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  hasHTSConnectSupport,
  tenantFlagsEnabled,
} from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../App";
import { PATH_EXCHANGE } from "../../../../utils/urlPaths";
import { LocationSearchWithDropdowns } from "../../../search/components/MobileFlightSearchControl/components";
import { MobileFlightSearchConnectorProps } from "./container";
import "./styles.scss";

export interface ILocationModalProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {
  hideCategoryBar?: boolean;
  open: boolean;
  onClose: () => void;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  setOrigin: (value: ITripTerminus | null) => void;
  setDestination: (value: ITripTerminus | null) => void;
  tripCategory: TripCategory;
  setTripCategory: (value: TripCategory) => void;
  setReturnDate?: (value: Date | null) => void;
  onSubmit?: () => void;
}

export const LocationModal = ({
  tripCategory,
  fetchDepartureCalendar,
  setCalendar,
  open,
  onClose,
  setTripCategory,
  setOpenLocationModal,
  setOpenCalendarModal,
  renderSearchInput,
  setRenderSearchInput,
  origin,
  destination,
  setOrigin,
  setDestination,
  setReturnDate,
  onSubmit,
}: ILocationModalProps) => {
  const clientContext = useContext(ClientContext);
  const location = useLocation();
  const { t } = useI18nContext();
  const inExchange = matchPath(location.pathname, PATH_EXCHANGE);
  const tenant = getEnvVariables("clientName");
  const isHTSConnect = hasHTSConnectSupport();

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      origin?.id?.code?.code &&
      destination?.id?.code?.code
    ) {
      fetchDepartureCalendar(origin?.id?.code, destination?.id?.code);
    } else {
      setCalendar();
    }
  }, [
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    origin?.id?.code?.code,
    destination?.id?.code?.code,
    origin?.id?.code,
    destination?.id?.code,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = useCallback(() => {
    setRenderSearchInput("");
  }, [setRenderSearchInput]);

  const updateTripCategory = useCallback(
    (value: TripCategory) => {
      trackEvent({
        eventName: CHANGED_TRIP_TYPE,
        properties: { screen: ChangeTripTypeScreenEnum.LOCATION_MODAL },
      });
      setTripCategory(value);
      if (value === TripCategory.ONE_WAY && setReturnDate) {
        setReturnDate(null);
      }
    },
    [setReturnDate, setTripCategory]
  );

  const onComplete = useCallback(() => {
    onSubmit && onSubmit();
    setOpenLocationModal(false);
    setOpenCalendarModal(true);
  }, [setOpenCalendarModal, setOpenLocationModal]);

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      // fullscreen makes position absolute and transition being super weird
      fullScreen={!isHTSConnect}
      className="location-modal-popover"
      contentClassName="location-modal-wrapper"
      paperClassName={`${tenant}-location-modal-calendar-picker`}
      topLeftButton={
        <ActionLink
          className={clsx("location-modal-header-back-button", {
            hidden: !renderSearchInput,
          })}
          onClick={handleGoBack}
          content={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
        />
      }
      topRightButton={
        <ActionLink
          className="location-modal-header-close-button"
          onClick={onClose}
          content={
            clientContext?.assets?.close ? (
              <Slot
                id="dropdown-icon"
                name="dropdown-icon-close"
                className="close-button-icon"
                component={
                  <img
                    src={clientContext.assets.close}
                    className={clsx("close-button-icon")}
                    alt={t("close")}
                  />
                }
              />
            ) : (
              <IconComponent
                ariaLabel={t("close.imageAlt")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            )
          }
          label={t("close.button")}
        />
      }
    >
      <Box className={clsx("location-modal-root")}>
        <HeaderCenterSection />
        <Box className="location-search-container">
          <LocationSearchWithDropdowns
            hideCategoryBar={Boolean(inExchange)}
            onComplete={onComplete}
            setTripCategory={updateTripCategory}
            origin={origin}
            destination={destination}
            setOrigin={setOrigin}
            setDestination={setDestination}
            modal
          />
        </Box>
      </Box>
    </MobilePopoverCard>
  );
};

const HeaderCenterSection = () => {
  const { t } = useI18nContext();
  return (
    <div className="header-center-section">
      <div className="location-modal-header-container">
        <h3 className="location-modal-header">
          {t("flightShopLocationModal")}
        </h3>
      </div>
    </div>
  );
};
