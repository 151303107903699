import { TripCategory } from "@hopper-b2b/types";
import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import { getSelectedAccountReferenceId } from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getReturnDate,
  getTripCategory,
} from "../../../search/reducer";
import { setFlightShopProgress } from "../../actions/actions";
import {
  airportsSelector,
  getPriceDropProperties,
  getPriceFreezeOfferCap,
  getPriceFreezeOfferDuration,
  getPriceFreezeOfferFiat,
  getPriceFreezeOfferRewards,
  getPriceFreezeOfferWithSuggested,
  getViewedPriceFreezeProperties,
  getViewedTripSummaryProperties,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  predictionSelector,
  selectedTripPriceFreezeOfferSelector,
  selectedTripSelector,
  tripDetailsLoadingSelector,
  tripDetailsSelector,
} from "../../reducer/selectors";
import { FlightShopReviewItinerary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const selectedFareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );

  return {
    isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    airports: airportsSelector(state, tripId),
    tripDetails,
    fareDetails,
    tripDetailsLoading: tripDetailsLoadingSelector(state),
    isMultiTicket: isOutgoingMultiTicket(state) || isReturnMultiTicket(state),
    includesMultiTicket:
      isOutgoingMultiTicket(state) || isReturnMultiTicket(state),
    rewardsKey: getSelectedAccountReferenceId(state),
    prediction: predictionSelector(state),
    viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
    priceDropViewedProperties: getPriceDropProperties(state),
    priceFreezeFiat: getPriceFreezeOfferFiat(state),
    priceFreezeRewards: getPriceFreezeOfferRewards(state),
    priceFreezeCap: getPriceFreezeOfferCap(state),
    priceFreezeOffer: getPriceFreezeOfferWithSuggested(state),
    priceFreezeDuration: getPriceFreezeOfferDuration(state),
    viewedPriceFreezeProperties: getViewedPriceFreezeProperties(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    selectedTripPriceFreezeOffer: selectedTripPriceFreezeOfferSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopReviewItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopReviewItinerary = connector(
  FlightShopReviewItinerary
);
