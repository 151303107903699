import { ReactElement, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  B2BSpinner,
  LoadingIndicator,
  PricingBreakdown,
  Slot,
} from "@hopper-b2b/ui";
import {
  Currency,
  FarePricing,
  ILineItemProps,
  ISummaryLineItem,
  TrackingEventEntryPoint,
  TripCategory,
} from "@hopper-b2b/types";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface IPriceBreakdownProps {
  isMobile?: boolean;
  customClassName?: string;
  currency: {
    currencyCode: string | Currency;
    currencySymbol: string | undefined;
  };
  lineItems: ILineItemProps[];
  otherLineItems?: ILineItemProps[];
  summaryLineItems: ISummaryLineItem[];
  isLoading: boolean;
  header: string;
  otherHeader?: string;
  initialPassengerPricing?: FarePricing;
  tripCategory?: TripCategory;
  title?: string;
  currencyString?: string;
  priceItemIcon?: ReactElement;
  perTraveler?: boolean;
  entryPoint?: TrackingEventEntryPoint;
  rewardsBack?: string;
}

function toFiat(
  items: ILineItemProps[],
  currency: {
    currencyCode: string | Currency;
    currencySymbol: string | undefined;
  }
) {
  return items?.map((item) => ({
    ...item,
    baseAmount: {
      value: item.baseAmount,
      ...currency,
    },
    taxesAndFees: item.taxesAndFees
      ? {
          value: item.taxesAndFees,
          ...currency,
        }
      : undefined,
    seats: item.seats
      ? {
          value: item.seats,
          ...currency,
        }
      : undefined,
  }));
}

export const PriceBreakdownContent = ({
  isLoading,
  customClassName,
  currency,
  lineItems,
  otherLineItems,
  summaryLineItems,
  header,
  otherHeader,
  initialPassengerPricing,
  tripCategory,
  title,
  currencyString,
  priceItemIcon,
  perTraveler,
  entryPoint,
  rewardsBack,
}: IPriceBreakdownProps) => {
  const { t } = useI18nContext();
  const fiatLineItems = useMemo(
    () => toFiat(lineItems, currency),
    [lineItems, currency]
  );
  const otherFiatLineItems = useMemo(
    () => toFiat(otherLineItems, currency),
    [otherLineItems, currency]
  );
  const renderPriceBreakdown = () => {
    return (
      <>
        <Box className="pricing-breakdown-header-wrapper">
          <Box className="pricing-breakdown-header-container">
            <Typography className="header" variant="h6">
              {header}
            </Typography>
          </Box>
        </Box>
        <Slot
          id="missed-connection-protection-outlined-button"
          displayCaption
          entryPoint={entryPoint}
        />
        <PricingBreakdown
          perTraveler={perTraveler}
          className="price-breakdown"
          pricingItems={fiatLineItems}
          summaryItems={summaryLineItems}
          currencySymbol={currency?.currencySymbol}
          initialPassengerPricing={initialPassengerPricing}
          tripCategory={tripCategory}
          currencyString={currencyString}
          modalTitle={title}
          priceItemIcon={priceItemIcon}
          rewardsBack={rewardsBack}
        />
        {otherLineItems && (
          <Box mt={2}>
            <Box className="pricing-breakdown-header-wrapper">
              <Box className="pricing-breakdown-header-container">
                <Typography className="other-header" variant="h6">
                  {otherHeader}
                </Typography>
              </Box>
            </Box>
            <PricingBreakdown
              perTraveler={perTraveler}
              className="price-breakdown"
              pricingItems={otherFiatLineItems}
              summaryItems={[]}
              currencySymbol={currency?.currencySymbol}
              initialPassengerPricing={initialPassengerPricing}
              tripCategory={tripCategory}
              currencyString={currencyString}
              modalTitle={title}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <Box className={clsx(customClassName, "price-breakdown")}>
      {isLoading ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={t("fetching-latest-price")}
        />
      ) : (
        renderPriceBreakdown()
      )}
    </Box>
  );
};
