import { ReactElement, useCallback, useEffect, useRef } from "react";
import { useDeviceTypes, useFeatureFlagsContext } from "@hopper-b2b/utilities";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { useLodgingLink } from "../../hooks/useLodgingLink";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom-v5-compat";
import {
  ActionLink,
  Carousel,
  IconComponent,
  IconName,
  Slot,
  StarRating,
} from "@hopper-b2b/ui";
import { Prices } from "@b2bportal/lodging-api";
import { BasicLodgingInfo } from "./index";
import { TripAdvisor } from "../LodgingCard/components/TripAdvisor";

interface IMapMarker extends BasicLodgingInfo {
  lodgingId: string;
  lodgingName: string;
  lat: number;
  lng: number;
  selected: boolean;
  isOveredInLodgingList: boolean;
  price: Prices;
  onClick?: (id: string, lat: number, lng: number) => void;
  onClose?: (e: Event) => void;
  onOpenQuickView?: (offsetY: number) => void;
}

export const Markers = ({
  lodgingId,
  lodgingName,
  selected = false,
  isOveredInLodgingList = false,
  lat,
  lng,
  onClick,
  name,
  media,
  starRating,
  tripAdvisor,
  price,
  totalPrice,
  onClose,
  onOpenQuickView,
}: IMapMarker): ReactElement => {
  const { matchesMobile } = useDeviceTypes();
  const { brand } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();
  const quickViewRef = useRef(null);
  const onSelect = useCallback(() => {
    onClick(lodgingId, lat, lng);
  }, [lat, lng, lodgingId, onClick]);

  const lodgingLink = useLodgingLink(lodgingId, lodgingName);
  // Tenant defined UX -  Hide Lodging card in map when marker is selected when showing a horizontal
  // list of lodgings overlaying map
  const featureFlags = useFeatureFlagsContext();
  const isHorizontalScrollEnabled = Boolean(
    featureFlags.showHorizontalScrollListInMobileMap
  );

  useEffect(() => {
    if (selected && quickViewRef?.current) {
      onOpenQuickView(quickViewRef.current.offsetHeight / 2);
    }
  }, [onOpenQuickView, selected]);

  const formattedPrice = formatPrice(price, {
    maximumFractionDigits: brand?.lodgingCardPriceRounding || 0,
    minimumFractionDigits: brand?.lodgingCardPriceRounding || 0,
    roundingMode: "ceil",
  });

  return (
    <>
      <button
        type="button"
        className={clsx("marker", {
          selected,
          isOveredInLodgingList,
          unavailable: formattedPrice.length === 0,
        })}
        onClick={onSelect}
      >
        <Typography color="inherit" noWrap>
          {formattedPrice}
        </Typography>
      </button>
      {(!isHorizontalScrollEnabled || !matchesMobile) && selected ? (
        <Link
          className="quick-view-container"
          to={lodgingLink}
          target={matchesMobile ? "" : "_blank"}
          ref={quickViewRef}
        >
          <div className="close-container">
            <ActionLink
              content={<IconComponent name={IconName.Close} />}
              onClick={onClose}
            />
          </div>
          <div className="quick-view-carousel">
            <Carousel
              imageUrlsArray={media?.map((asset) => asset.url)}
              onClick={null}
            />
          </div>
          <Typography variant="h2">{name}</Typography>
          <div className="quick-view-ratings">
            <StarRating
              className="quick-view-ratings-starRating"
              starRating={starRating}
            />
            <TripAdvisor
              className="quick-view-ratings-reviews"
              tripAdvisorReviews={tripAdvisor}
            />
          </div>

          <Slot id="lodging-card-policy" lodgingId={lodgingId} />
          <Typography variant="h2">
            <I18nMarkup
              tKey={"pricePerNight"}
              replacements={{
                price: formatPrice(price, {
                  maximumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                  minimumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                  roundingMode: "ceil",
                }),
              }}
            />
          </Typography>
          <Typography variant="body1">{totalPrice}</Typography>
        </Link>
      ) : null}
    </>
  );
};

export const ClusterMarker = ({
  cluster,
  lat,
  lng,
  expansionZoom,
  isOveredInLodgingList,
  onClick,
}) => {
  const { t } = useI18nContext();

  const onClusterClick = useCallback(() => {
    onClick(lat, lng, expansionZoom);
  }, [expansionZoom, lat, lng, onClick]);
  return (
    <button
      type="button"
      className={clsx("cluster-marker", {
        isOveredInLodgingList,
      })}
      onClick={onClusterClick}
    >
      <Typography color="inherit" noWrap>
        {t("stays", { count: cluster.properties.point_count })}
      </Typography>
    </button>
  );
};
