import { Amount, PaymentAmountEnum } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IPaymentLineItem,
  RewardsPrice,
  SelfServeEvents,
} from "@hopper-b2b/types";
import { useUberBridge } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import {
  getExchangeTypeEvent,
  getIsFtc,
  getNewPayments,
  getOriginalExchangeFee,
  getPrevPayments,
  getSummaryItems,
} from "../../selectors";
import { IPrevPaymentLineItem, PricingSummary } from "./components";

import "./styles.scss";

export const ON_LAP_KEY = "INF-OL";

export interface IPaymentInfo {
  cardInfo: string;
  cashPaid?: Amount;
  pfCredit: number;
  prevPayments: IPrevPaymentLineItem[];
  rewardsPaid?: {
    PaymentAmount: PaymentAmountEnum;
    rewardsCashAmt: number;
    rewardsCashCurrency: string;
    rewardsPrice: RewardsPrice;
  };
}

export interface IPriceBreakdownStepProps {
  enablePricingToggle?: boolean;
  isMobile?: boolean;
}

const defaultProps: Partial<IPriceBreakdownStepProps> = {
  enablePricingToggle: false,
  isMobile: false,
};

const PriceBreakdownStep = (props: IPriceBreakdownStepProps): JSX.Element => {
  const { enablePricingToggle, isMobile } = props;
  const { brand, t } = useI18nContext();

  const totalRef = useRef(0);
  const exchangeTypeEvent = useSelector(getExchangeTypeEvent);
  const isFtc = useSelector(getIsFtc);
  const ogChangeFee = useSelector(getOriginalExchangeFee);
  const prevPaymentInfo = useSelector(getPrevPayments);
  const pricingItems = useSelector(getNewPayments);
  const summaryItems = useSelector(getSummaryItems);
  const { postEvent } = useUberBridge();

  const prevPaymentInfoWithCopy = useMemo(() => {
    const { prevPayments } = prevPaymentInfo;

    prevPaymentInfo.prevPayments = prevPayments.map((p) => ({
      ...p,
      label: t(p.label),
    }));

    return prevPaymentInfo;
  }, [prevPaymentInfo, t]);

  const pricingItemsWithCopy = useMemo(() => {
    return pricingItems.map((pi) => ({
      ...pi,
      lineTitle: pi.lineTitle?.endsWith(ON_LAP_KEY)
        ? pi.lineTitle.replace(ON_LAP_KEY, `(${t("infantModal.onLap")})`)
        : pi.lineTitle,
    }));
  }, [pricingItems, t]);

  const summaryItemsWithCopy = useMemo(() => {
    totalRef.current = 0;

    return summaryItems.map((item) => {
      const {
        fiatPrice: { currencyCode, currencySymbol, value },
      } = item;
      totalRef.current += value;

      return {
        ...item,
        fiatPrice: {
          ...item.fiatPrice,
          currencyCode: currencyCode || brand.currency.code,
          currencySymbol: currencySymbol || brand.currency.symbol,
        },
        label: "label" in item ? t(item.label) : undefined,
      };
    });
  }, [brand, summaryItems, t]);

  const totalPaymentLine: IPaymentLineItem = {
    fiatPrice: {
      currencyCode: brand.currency.code,
      currencySymbol: brand.currency.symbol,
      value: totalRef.current,
    },
    // rewardsPrice: newRewardsPrice(total),
    label: t("exchangeable.priceBreakdown.totalCost"),
    lastFour: prevPaymentInfo.cardInfo,
    type: "payment",
  };

  // const newRewardsPrice = (cashAmt: number): RewardsPrice => ({
  //   currency: t("DEFAULT_REWARDS_CURRENCY"),
  //   value: cashAmt * 100,
  // });

  useEffect(() => {
    const eventName = isFtc
      ? SelfServeEvents.ViewedFTCExchangeConfirmPage
      : SelfServeEvents.ViewedExchangeConfirmPage;
    const properties = {
      url: window.location.pathname,
      exchange_type: exchangeTypeEvent,
      exchange_fee: ogChangeFee.amount,
    };

    trackEvent({ eventName, properties });
    postEvent(eventName, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="exchange-price-breakdown-step">
      <Box className={clsx("checkout-breakdown-root", { mobile: isMobile })}>
        <PricingSummary
          enablePricingToggle={enablePricingToggle}
          passengerItems={pricingItemsWithCopy}
          payments={prevPaymentInfoWithCopy.prevPayments}
          summaryItems={summaryItemsWithCopy}
          totalPayable={totalPaymentLine}
        />
      </Box>
    </Box>
  );
};

PriceBreakdownStep.defaultProps = defaultProps;

export default PriceBreakdownStep;
