import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { CancellationPolicyV2Enum, PriceQuoteV2 } from "@b2bportal/lodging-api";
import clsx from "clsx";

import {
  CartSelectors,
  commonSelectors,
  ContactSelectors,
  getParentState,
  LodgingSelectors,
  ParentState,
  PassengerSelectors,
  PriceChangeType,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { BackButton } from "@hopper-b2b/ui";
import {
  getLocalStorageTrackingEvents,
  useSetLocalStorageTrackingEvents,
  useTrackEvents,
} from "@hopper-b2b/lodging";
import {
  LodgingCheckoutTrackingEvents,
  MODAL_ALERT,
  ModalCategoryType,
  ModalScreens,
} from "@hopper-b2b/types";
import {
  formatPhoneNumber,
  roundToTwoDecimals,
  useEnableWallet,
} from "@hopper-b2b/utilities";
import { trackEvent } from "@hopper-b2b/api";

import { ReactComponent as ExchangeRedemption } from "../../../../assets/client/exchange-redemption.svg";
import { ReactComponent as EditIcon } from "../../../../assets/client/pencil.svg";
import {
  HotelCheckoutCtaSection,
  HotelDetailsPolicies,
  PriceBreakdownAdditionalInfo,
} from "../../../../components/Slots";
import { GenericResponseScreen } from "../../../../components/GenericResponseScreen";
import { Event, TEvent } from "../../events";
import { HotelSummary } from "../HotelSummary";
import style from "./styles.module.scss";
import { OfferSelectionCheckbox } from "../../../../checkout/components";

export const ConnectedReviewComponent = () => {
  const { t, formatFiatCurrency } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();

  const isCartLocked = useSelector(CartSelectors.getCartQuoteIsLocked);
  const priceChange = useSelector(CartSelectors.getCartQuotePriceChange);
  const [isPriceChangeOpen, setIsPriceChangeOpen] = useState(false);
  const closePriceChange = useCallback(() => {
    send(Event.ACKNOWLEDGE_PRICE_CHANGE);
    setIsPriceChangeOpen(false);
  }, [send]);
  const contactInformation = useSelector(
    ContactSelectors.getContactInformation
  );
  const allSelectedUserPassengers = useSelector(
    PassengerSelectors.getAllSelectedUserPassengers
  );
  const stateValue = useSelector(commonSelectors.getStateValue) as string;
  const totalNights = useSelector(LodgingSelectors.getTotalNights);
  const lodgingProduct = useSelector(
    LodgingSelectors.getQuotedLodgingProduct
  ) as PriceQuoteV2;
  const shoppedCheckInInstructions = useSelector(
    LodgingSelectors.getCheckInInstructions
  );
  const checkInInstructions = lodgingProduct
    ? lodgingProduct.checkInInstructions
    : shoppedCheckInInstructions;

  const hotelPricing = useSelector(
    LodgingSelectors.getQuotedLodgingProductPricingV1
  );

  const hotelPricingBreakdown = useSelector(
    LodgingSelectors.getQuotedLodgingBreakdown
  );

  // Wallet
  const enableWallet = useEnableWallet();
  const offer = useSelector(WalletSelectors.getWalletOffer);
  const isOfferApplied = useSelector(WalletSelectors.getIsWalletOfferApplied);
  const hasOffersAvailable = useSelector(
    WalletSelectors.getHasWalletOffersAvailable
  );
  const [discountActive, setDiscountActive] = useState(isOfferApplied);
  const walletPaymentTrackingProperties = useSelector(
    WalletSelectors.getCheckoutOfferSelectionTrackingProperties
  );

  const totalBeforeFees = hotelPricing.subTotal.fiat;
  const taxesAndFees = hotelPricing.taxesAndFeesTotal.fiat;

  const { grandTotal, payLater, payNow, discountedAmount } = useMemo(() => {
    return isOfferApplied
      ? {
          // Grand total is missing pay later, need to combine the balance and pay later for the grand total
          // TA is doing something similar with pay now and CFAR
          grandTotal: {
            ...hotelPricingBreakdown.balance.fiat,
            value: roundToTwoDecimals(
              hotelPricingBreakdown.balance.fiat.value +
                (hotelPricing.paymentSchedule.payLaterTotal?.fiat.value ?? 0)
            ),
          },
          // pay later will remain the same
          payLater: hotelPricing.paymentSchedule.payLaterTotal?.fiat,
          // pay now is the remaining balance
          payNow: hotelPricingBreakdown.balance.fiat,
          discountedAmount: hotelPricingBreakdown.paymentTotal.fiat,
        }
      : {
          grandTotal: hotelPricing.tripTotal.fiat,
          payLater: hotelPricing.paymentSchedule.payLaterTotal?.fiat,
          payNow: hotelPricing.paymentSchedule.payNowTotal.fiat,
          discountedAmount: undefined,
        };
  }, [
    hotelPricing.paymentSchedule.payLaterTotal?.fiat,
    hotelPricing.paymentSchedule.payNowTotal.fiat,
    hotelPricing.tripTotal.fiat,
    hotelPricingBreakdown.balance.fiat,
    hotelPricingBreakdown.paymentTotal.fiat,
    isOfferApplied,
  ]);

  const localStorageTrackingEventsKey = useSelector(
    LodgingSelectors.getLocalStorageKey
  );

  const parentState = getParentState(stateValue);

  const trackEvent = useTrackEvents();
  const setLocalStorageEvents = useSetLocalStorageTrackingEvents();
  const goToBooking = useCallback(() => {
    setLocalStorageEvents(localStorageTrackingEventsKey);
    send(Event.NEXT);
  }, [localStorageTrackingEventsKey, send, setLocalStorageEvents]);

  const onEditContactInfo = useCallback(
    () => send(Event.GO_TO_CONTACT_INFORMATION),
    [send]
  );

  const onEditPassenger = useCallback(
    () =>
      send({
        type: Event.GO_TO_PASSENGER_SELECT,
      }),
    [send]
  );

  const handleGoBack = useCallback(() => {
    if (isCartLocked) {
      send(Event.GO_TO_PASSENGER_SELECT);
    } else {
      history.goBack();
    }
  }, [history, isCartLocked, send]);

  const handleDiscountActive = useCallback(() => {
    send({
      type: discountActive
        ? Event.REMOVE_SELECTED_OFFER
        : Event.SET_SELECTED_OFFER,
      offerId: offer.id,
    });
    setDiscountActive(!discountActive);

    // get tracking values for now the reward changing
    trackEvent(
      LodgingCheckoutTrackingEvents.tapped_rewards_redemption_choice,
      walletPaymentTrackingProperties({ offerActive: !discountActive })
    );
  }, [
    discountActive,
    offer?.id,
    send,
    trackEvent,
    walletPaymentTrackingProperties,
  ]);

  const cancellationPolicies = useMemo(() => {
    const cancellationPolicy = lodgingProduct.cancellationDetails;
    const policy = cancellationPolicy.CancellationPolicyV2;
    if (policy === CancellationPolicyV2Enum.NonRefundable) {
      return {
        primaryText: t("cancellation.NonRefundable.primaryText"),
        secondaryText: t("cancellation.NonRefundable.secondaryText"),
      };
    } else if (policy === CancellationPolicyV2Enum.Unknown) {
      return {
        primaryText: cancellationPolicy.primaryText,
      };
    } else {
      return {
        primaryText: cancellationPolicy.primaryText,
        secondaryText: cancellationPolicy.secondaryText,
      };
    }
  }, []);

  useEffect(() => {
    if (parentState === ParentState.review) {
      // Pass in properties if exist
      const properties = getLocalStorageTrackingEvents(
        localStorageTrackingEventsKey
      );
      trackEvent(
        LodgingCheckoutTrackingEvents.hotel_review_details,
        properties?.properties || {}
      );
    }
  }, [localStorageTrackingEventsKey, parentState, trackEvent]);

  useEffect(() => {
    if (priceChange?.hasDifference && priceChange.acknowledged !== true) {
      setIsPriceChangeOpen(true);
    }
  }, [priceChange]);

  return isPriceChangeOpen ? (
    <PriceChangeModal
      {...priceChange}
      open={isPriceChangeOpen}
      onClose={closePriceChange}
      currencyCode={grandTotal.currencyCode}
    />
  ) : (
    <>
      <div className={style.reviewHeaderContainer}>
        <BackButton className={style.backButton} onClick={handleGoBack} />
        <h1 className={style.reviewHeader}>{t("confirmAndBook")}</h1>
      </div>
      <div className="review-part">
        <HotelSummary />
      </div>
      {/* Contact Information section */}
      <div className={style.contactSection}>
        <div className={style.bookingDetails}>
          <p className={style.bookingDetailsHeader}>
            {t("reviewContactInformation")}
          </p>
        </div>
        <div className={style.reviewRow}>
          <h3 className={style.rowHeader}>{t("phoneNumberLabel")}</h3>
          <p className={style.rowSubheader}>
            {formatPhoneNumber(
              contactInformation.phoneNumber,
              contactInformation.countryCode
            )}
          </p>
          <EditIcon className={style.editIcon} onClick={onEditContactInfo} />
        </div>
        <div className={style.reviewRow}>
          <h3 className={style.rowHeader}>{t("email")}</h3>
          <p className={style.rowSubheader}>{contactInformation.email}</p>
          <EditIcon className={style.editIcon} onClick={onEditContactInfo} />
        </div>
      </div>
      {/* Passenger section */}
      <div className="review-part">
        <div className={style.bookingDetails}>
          <p className={style.bookingDetailsHeader}>
            {t("passengerInformation.travelerDetailsTitle")}
          </p>
        </div>
        {allSelectedUserPassengers.map((passenger) => (
          <div className={style.reviewRow}>
            <h3 className={style.rowHeader}>
              {`${passenger.givenName} ${passenger.surname}`}
            </h3>
            <p className={style.rowSubheader}>
              {t("passengerInformation.adult")}
            </p>
            <EditIcon className={style.editIcon} onClick={onEditPassenger} />
          </div>
        ))}
      </div>
      {/* Cancellation Policy */}
      {cancellationPolicies.primaryText ? (
        <div className="review-part">
          <div className={style.bookingDetails}>
            <p className={style.bookingDetailsHeader}>
              {cancellationPolicies.primaryText}
            </p>
          </div>
          {cancellationPolicies?.secondaryText ? (
            <div className={style.cancellationTextSection}>
              <p className={style.rowSubheader}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: cancellationPolicies.secondaryText,
                  }}
                />
              </p>
            </div>
          ) : null}
        </div>
      ) : null}
      {/* Offers */}
      {enableWallet && hasOffersAvailable ? (
        <div>
          <div className={style.bookingDetails}>
            <p className={style.bookingDetailsHeader}>{t("offers")}</p>
          </div>
          <OfferSelectionCheckbox
            onCheckClick={handleDiscountActive}
            selected={discountActive}
            offer={offer}
          />
        </div>
      ) : null}
      {/* Price Breakdown */}
      <div>
        <div className={style.bookingDetails}>
          <p className={style.bookingDetailsHeader}>{t("priceBreakdown")}</p>
        </div>
        <div className={style.priceBreakdownContainer}>
          <PriceBreakdownAdditionalInfo
            rewardsBack={formatFiatCurrency({
              ...payNow,
              value: payNow.value * 0.01,
            })}
          />
          <div className={style.priceBreakdownRow}>
            <p className={style.priceBreakdownLabel}>
              {t("priceLabelForNights", { count: totalNights })}
            </p>
            <p className={style.priceBreakdownValue}>
              {formatFiatCurrency(totalBeforeFees)}
            </p>
          </div>
          <div className={style.priceBreakdownRow}>
            <p className={style.priceBreakdownLabel}>{t("taxesAndFees")}</p>
            <p className={style.priceBreakdownValue}>
              {formatFiatCurrency(taxesAndFees)}
            </p>
          </div>
          {enableWallet && discountActive && isOfferApplied ? (
            <div
              className={clsx(
                style.priceBreakdownRow,
                style.priceBreakdownDiscount
              )}
            >
              <p className={clsx(style.priceBreakdownLabel)}>
                {t("offerAppliedWithAmount", {
                  amount: `${offer.percentage * 100}%`,
                })}
              </p>
              <p className={style.priceBreakdownValue}>
                - {formatFiatCurrency(discountedAmount)}
              </p>
            </div>
          ) : null}

          <div className={style.priceBreakdownRow}>
            <p className={style.priceBreakdownLabel}>
              <strong>{t("total")}</strong>
            </p>
            <p className={style.priceBreakdownValue}>
              <strong>{formatFiatCurrency(grandTotal)}</strong>
            </p>
          </div>

          <div
            className={clsx(
              style.priceBreakdownRow,
              style.lastPriceBreakdownRow
            )}
          >
            <p className={style.priceBreakdownLabel}>
              <strong>{t("dueToday")}</strong>
            </p>
            <p className={style.priceBreakdownValue}>
              <strong>{formatFiatCurrency(payNow)}</strong>
            </p>
          </div>
          {payLater ? (
            <div className={style.priceBreakdownRow}>
              <p className={style.priceBreakdownLabel}>
                {t("hotelDueUponCheckIn")}
              </p>
              <p className={style.priceBreakdownValue}>
                {formatFiatCurrency(payLater)}
              </p>
            </div>
          ) : null}
          {/* Add line underneath fees due at check in */}
        </div>
      </div>
      <div className={style.lastReviewSection}>
        <HotelDetailsPolicies checkInInstructions={checkInInstructions} />
      </div>
      <HotelCheckoutCtaSection
        onClick={goToBooking}
        message={t("goToPayment")}
      />
    </>
  );
};

interface PriceChangeModalProps extends PriceChangeType {
  open: boolean;
  onClose: () => void;
  currencyCode: string;
}

const PriceChangeModal = ({
  open,
  onClose,
  ...props
}: PriceChangeModalProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const history = useHistory();
  const availabilityLink = useSelector(LodgingSelectors.getAvailabilityLink);

  const toHotelSearch = useCallback(() => {
    history.push(availabilityLink);
  }, [availabilityLink, history]);

  const primaryButtonText = t("continue");
  const secondaryButtonText = t("hotelPriceChange.changeHotel");

  useEffect(() => {
    if (open) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          screen: ModalScreens.FLIGHTS_CHECKOUT,
          category: ModalCategoryType.TROUBLE,
          primary_button: primaryButtonText,
          secondary_button: props.isIncrease ?? secondaryButtonText,
        },
      });
    }
  }, [open, primaryButtonText, props.isIncrease, secondaryButtonText]);

  return (
    <GenericResponseScreen
      onBack={onClose}
      {...(props.isIncrease
        ? {
            title: t(`hotelPriceChange.priceIncreaseTitle`, {
              price: formatFiatCurrency({
                value: props.difference,
                currencyCode: props.currencyCode,
              }),
            }),
            subtitle: t(`hotelPriceChange.priceIncreaseSubtitle`),
            primaryOnClick: toHotelSearch,
            primaryButtonText: t("hotelPriceChange.changeHotel"),
            secondaryOnClick: onClose,
            secondaryButtonText: primaryButtonText,
            className: clsx("warning", style.priceChangeIncreaseScreen),
          }
        : {
            title: t(`hotelPriceChange.priceDecreaseTitle`, {
              price: formatFiatCurrency({
                value: props.difference,
                currencyCode: props.currencyCode,
              }),
            }),
            primaryOnClick: onClose,
            primaryButtonText: primaryButtonText,
            subtitle: t(`hotelPriceChange.priceDecreaseSubtitle`),
            className: style.priceChangeDecreaseScreen,
            image: <ExchangeRedemption className={style.priceDecreaseImage} />,
          })}
    />
  );
};
