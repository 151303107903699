import {
  AirDisruptionGuards,
  CartGuards,
  CfarGuards,
  AirChfarGuards,
  ContactInformationGuards,
  FlightPassengerGuardsV2,
  AirPFExerciseGuards,
  ReviewGuards,
  SeatGuards,
  VipSupportGuards,
  AirChfarExerciseDiscountGuards,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";
import { paymentGuards } from "./payment";
import { NubankPaymentGuards } from "../../../checkout/states/payments";

export const Guards = {
  ...AirDisruptionGuards,
  ...CartGuards,
  ...CfarGuards,
  ...AirChfarGuards,
  ...ContactInformationGuards,
  ...FlightPassengerGuardsV2,
  ...paymentGuards,
  ...AirPFExerciseGuards,
  ...ReviewGuards,
  ...SeatGuards,
  ...VipSupportGuards,
  ...AirChfarExerciseDiscountGuards,
  ...NubankPaymentGuards,
};

export const GuardTypes = getObjectKeysAsObject(Guards);
