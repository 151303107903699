import { DoneInvokeEvent, MachineConfig } from "xstate";
import {
  CartQuoteGuardTypes,
  FlightActionTypes,
  FlightPassengerActionTypesV2,
  FlightPassengerEventTypesV2,
  GenericChildState,
  getAirPriceDropMachine,
  getCartFulfillMachine,
  getCartQuoteMachine,
  getCartUpdateMachine,
  getContactInformationMachine,
  getFlightPassengerInformationMachineV2,
  getFlightResumeMachineState,
  getPassportMachineState,
  getReviewMachineState,
  getSeatMachine,
  ParentState,
  PassengerInformationChildState,
  PassengerInformationState,
  SeatActionTypes,
  trackPollFailure,
  trackPollSuccess,
  VipSupportActionTypes,
  VipSupportEventTypes,
} from "@hopper-b2b/checkout";

import { ActionTypes } from "./actions";
import { Event, TEvent } from "./events";
import { FlightMachineContext } from "./types";
import { getInitialFlightMachineContext } from "./initialContext";
import { ServiceTypes } from "./services";
import { getNubankPaymentMachine } from "../../checkout/states";
import { NubankPaymentEvent } from "../../checkout";
import {
  NubankPaymentChildState,
  NubankPaymentState,
} from "../../checkout/states/payments/types";
import { COMPLETE_BUY_AIR } from "@hopper-b2b/types";
import { FulfillSuccess, Product } from "@b2bportal/purchase-api";
import { GuardTypes } from "./guards";

export const flightMachineId = "nubank-flight-checkout";
export const flightStateMachine: MachineConfig<
  FlightMachineContext,
  any,
  TEvent
> = {
  schema: {
    context: getInitialFlightMachineContext(),
    events: {} as TEvent,
  },

  // Machine identifier
  id: flightMachineId,

  // Initial state
  initial: ParentState.resume,

  // State definitions

  states: {
    [ParentState.resume]: getFlightResumeMachineState({
      parentMachineId: flightMachineId,
      nextState: `#${flightMachineId}.${ParentState.loading}`,
    }),
    [ParentState.loading]: {
      entry: ActionTypes.setInitialContactInformation,
      always: [
        {
          target: `#${flightMachineId}.${ParentState.passengerInformation}`,
          cond: GuardTypes.contactContinue,
        },
        {
          target: `#${flightMachineId}.${ParentState.contactInformation}`,
        },
      ],
    },
    [ParentState.contactInformation]: {
      ...getContactInformationMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.passengerInformation}`,
        nextStateFromReview: `#${flightMachineId}.${ParentState.cartQuote}`,
      }),
    },
    [ParentState.passengerInformation]: {
      ...getFlightPassengerInformationMachineV2({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.cartQuote}`,
        nextStateFromReview: `#${flightMachineId}.${ParentState.cartQuote}`,
        previousState: `#${flightMachineId}.${ParentState.contactInformation}`,
      }),
    },
    [ParentState.passport]: {
      ...getPassportMachineState({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.passengerInformation}`,
        previousState: `#${flightMachineId}.${ParentState.passengerInformation}`,
      }),
    },
    [ParentState.cartQuote]: {
      entry: [
        FlightActionTypes.addMultiProviderProduct,
        SeatActionTypes.resetSelectedSeats,
      ],
      ...getCartQuoteMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.priceDrop}`,
      }),
    },
    // Add Products to Cart
    [ParentState.priceDrop]: {
      ...getAirPriceDropMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.cartUpdate}`,
      }),
    },
    // Update Cart
    [ParentState.cartUpdate]: {
      ...getCartUpdateMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.seatSelection}`,
        failedState: `#${flightMachineId}.${ParentState.seatSelection}`,
      }),
      exit: FlightActionTypes.checkForPriceChange,
    },
    [ParentState.seatSelection]: {
      ...getSeatMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.review}`,
        previousState: `#${flightMachineId}.${ParentState.passengerInformation}`,
      }),
    },
    [ParentState.review]: {
      initial: GenericChildState.loading,
      entry: FlightActionTypes.checkForPriceChange,
      states: {
        [GenericChildState.loading]: {
          invoke: {
            src: ServiceTypes.trackReviewDetails,
            onDone: `#${flightMachineId}.${ParentState.review}.${GenericChildState.idle}`,
            onError: `#${flightMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          },
        },
        [GenericChildState.idle]: {},
      },
      ...getReviewMachineState({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        previousState: `#${flightMachineId}.${ParentState.passengerInformation}`,
      }),
    },
    [ParentState.cartUpdateBeforeFulfill]: {
      ...getCartUpdateMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.payment}`,
      }),
    },
    [ParentState.payment]: {
      ...getNubankPaymentMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.cartFulfill}`,
      }),
    },
    [ParentState.cartFulfill]: {
      ...getCartFulfillMachine({
        parentMachineId: flightMachineId,
        nextState: `#${flightMachineId}.${ParentState.bookingConfirmation}`,
        trackPollSuccessAction: trackPollSuccess<FlightMachineContext>({
          eventName: COMPLETE_BUY_AIR,
          getAdditionalProperties: (context, event) => ({
            multi_ticket_type: (
              event as DoneInvokeEvent<FulfillSuccess>
            ).data.products.find((p) => p.type === Product.MultiProviderFlight)
              ? "hacker_fare" //TODO: add "virtual_interline" when it's ready
              : "single",
            provider: (event as DoneInvokeEvent<FulfillSuccess>).data.products
              .filter(
                (val) =>
                  val.type === Product.Flight ||
                  val.type === Product.MultiProviderFlight
              )
              .reduce((acc, val) => {
                const provider =
                  val.value?.travelItinerary?.locators?.agent?.provider;
                return !acc.length || acc === provider
                  ? provider
                  : "multiProvider";
              }, ""),
            recommendation: context.prediction?.recommendation ?? undefined,
          }),
        }),
        trackPollFailureAction: trackPollFailure({
          eventName: COMPLETE_BUY_AIR,
        }),
      }),
    },
    [ParentState.bookingConfirmation]: {},
  },
  on: {
    [FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER]: `#${flightMachineId}.${ParentState.passengerInformation}`,
    [FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM]: `#${flightMachineId}.${ParentState.passport}`,
    [FlightPassengerEventTypesV2.OPEN_FORM_AND_SET_PASSENGER]: {
      actions: FlightPassengerActionTypesV2.setCurrentPassenger,
      target: `#${flightMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
    },

    [VipSupportEventTypes.TOGGLE_SUPPORT_STATUS]: {
      actions: VipSupportActionTypes.toggleVipSupport,
    },
    [NubankPaymentEvent.GO_TO_PAYMENT_ENROLLMENT_SUCCESS]: `#${flightMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.success}`,
    [NubankPaymentEvent.GO_TO_PAYMENT_ENROLLMENT_FAILURE]: `#${flightMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.failure}`,
    [Event.GO_TO_BOOKING_CONFIRMATION]: `#${flightMachineId}.${ParentState.bookingConfirmation}`,
    [Event.GO_TO_SEAT_SELECTION]: [
      {
        target: `#${flightMachineId}.${ParentState.cartQuote}`,
        cond: CartQuoteGuardTypes.isCartLocked,
      },
      {
        target: `#${flightMachineId}.${ParentState.seatSelection}`,
      },
    ],
    [Event.EDIT_SEATS_FROM_REVIEW]: [
      {
        actions: ActionTypes.setEditSeatsFromReview,
        target: `#${flightMachineId}.${ParentState.seatSelection}`,
      },
    ],
    [FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR]: {
      actions: FlightPassengerActionTypesV2.clearPassengerInformationError,
      target: `#${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
    },
    // Events below are used for browser navigation
    [Event.GO_TO_PASSENGER_SELECT]: `#${flightMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.idle}`,
    [Event.GO_TO_CONTACT_INFORMATION]: `#${flightMachineId}.${ParentState.contactInformation}`,
    [Event.GO_TO_CART_QUOTE]: `#${flightMachineId}.${ParentState.cartQuote}`,
    [Event.GO_TO_REVIEW]: `#${flightMachineId}.${ParentState.review}`,
    [Event.GO_TO_PAYMENT]: `#${flightMachineId}.${ParentState.payment}`,
    [Event.GO_TO_PRICE_DROP]: `#${flightMachineId}.${ParentState.priceDrop}`,
    [Event.GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT]: `#${flightMachineId}.${ParentState.payment}.${NubankPaymentState.selectCreditOrDebit}`,
    [Event.GO_TO_PAYMENT_SELECT_CREDIT]: `#${flightMachineId}.${ParentState.payment}.${NubankPaymentState.credit}`,
    [Event.GO_TO_PAYMENT_SELECT_REVIEW]: `#${flightMachineId}.${ParentState.payment}.${NubankPaymentState.review}`,
  },
};
