import { checkoutServices } from "./common";
import {
  CartQuoteServices,
  CartUpdateServices,
  FlightServices,
  getObjectKeysAsObject,
  FlightPassengerServicesV2,
  AirPFExerciseServices,
  SeatServices,
  ContactInformationServices,
  PassportServices,
  CartFulfillServices,
} from "@hopper-b2b/checkout";
import { NubankPaymentServices } from "../../../checkout/states/payments";

export const Services = {
  ...CartFulfillServices,
  ...CartQuoteServices,
  ...CartUpdateServices,
  ...checkoutServices,
  ...FlightServices,
  ...ContactInformationServices,
  ...FlightPassengerServicesV2,
  ...PassportServices,
  ...AirPFExerciseServices,
  ...SeatServices,
  ...NubankPaymentServices,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
