import { CartUpdateActionTypes } from "@checkout/index";
import { BaseAction, BaseActionObject } from "xstate";
import {
  CartFulfillState,
  CartQuoteState,
  ChildMachineConfig,
} from "../../../types";
import { CartQuoteActionTypes, CartQuoteEventType } from "../CartQuote";
import { ActionTypes as QuoteActions } from "../CartQuote/actions";
import { CartState } from "../types";
import { ActionTypes } from "./actions";
import { CartFulfillEventType } from "./events";
import { GuardTypes } from "./guards";
import { ServiceTypes } from "./services";

type GenericActionType<MachineContext> = BaseAction<
  MachineContext,
  any,
  BaseActionObject,
  any
>;

type CartFulfillConfig<MachineContext> = ChildMachineConfig & {
  trackPollSuccessAction?: GenericActionType<MachineContext>;
  trackPollFailureAction?: GenericActionType<MachineContext>;
};

const getPollOnDoneActions = <MachineContext>({
  trackPollSuccessAction,
}: CartFulfillConfig<MachineContext>): Array<
  GenericActionType<MachineContext>
> => {
  const onDoneActions = [
    ActionTypes.setCartFulfillResult,
    ActionTypes.setCartFulfillCallStateSuccess,
    CartQuoteActionTypes.clearCartContext,
    CartQuoteActionTypes.clearCartQuoteProducts,
  ];
  return trackPollSuccessAction
    ? [trackPollSuccessAction, ...onDoneActions]
    : onDoneActions;
};

const getPollOnErrorActions = <MachineContext>({
  trackPollFailureAction,
}: CartFulfillConfig<MachineContext>): Array<
  GenericActionType<MachineContext>
> => {
  const onErrorActions = [
    ActionTypes.setCartFulfillError,
    ActionTypes.setCartFulfillCallStateFailed,
  ];
  return trackPollFailureAction
    ? [...onErrorActions, trackPollFailureAction]
    : onErrorActions;
};

export const getMachineState = <MachineContext>(
  config: CartFulfillConfig<MachineContext>
) => ({
  id: CartState.cartFulfill,
  initial: CartFulfillState.schedule,
  states: {
    [CartFulfillState.schedule]: {
      entry: ActionTypes.setCartFulfillCallStateInProcess,
      invoke: {
        src: ServiceTypes.scheduleFulfillService,
        onDone: {
          actions: [
            ActionTypes.setCartFulfillCipherText,
            // This is to avoid discarding the quote on fulfillment, once scheduled a quote cannot be mutated/discarded
            QuoteActions.clearCartQuoteCipherText,
          ],
          target: CartFulfillState.polling,
        },
        onError: {
          actions: [
            ActionTypes.setCartFulfillError,
            ActionTypes.setCartFulfillCallStateFailed,
            // This is to avoid discarding the quote on fulfillment, once scheduled a quote cannot be mutated/discarded
            QuoteActions.clearCartQuoteCipherText,
          ],
          target: CartFulfillState.error,
        },
      },
    },
    [CartFulfillState.polling]: {
      invoke: {
        src: ServiceTypes.pollFulfillService,
        onDone: {
          actions: getPollOnDoneActions<MachineContext>(config),
          target: CartFulfillState.route,
        },
        onError: {
          actions: getPollOnErrorActions<MachineContext>(config),
          target: CartFulfillState.error,
        },
      },
    },
    [CartFulfillState.route]: {
      always: [
        {
          target: config.nextState,
          cond: GuardTypes.hasNoAction,
        },
        { target: CartFulfillState.challenge },
      ],
    },
    [CartFulfillState.challenge]: {
      on: {
        [CartFulfillEventType.COMPLETE_INTERACTIVE_SUCCESS]: {
          actions: [
            ActionTypes.clearCartFulfillPendingInteractive,
            ActionTypes.setCartFulfillCompleteInteractive,
          ],
          target: CartFulfillState.completeInteractive,
        },
        [CartFulfillEventType.COMPLETE_INTERACTIVE_ERROR]: {
          actions: getPollOnErrorActions<MachineContext>(config),
          target: CartFulfillState.error,
        },
      },
    },
    [CartFulfillState.completeInteractive]: {
      entry: ActionTypes.setCartFulfillCallStateInProcess,
      invoke: {
        src: ServiceTypes.completeInteractiveFulfillService,
        onDone: {
          target: CartFulfillState.polling,
        },
        onError: {
          actions: getPollOnErrorActions<MachineContext>(config),
          target: CartFulfillState.error,
        },
      },
    },
    [CartFulfillState.error]: {
      ...(config.failedState ? { always: { target: config.failedState } } : {}),
    },
    // Only merges first layer object, doesn't do a deep merge
    // See ChildMachineConfig for more details
    ...(config.stateOverride ? config.stateOverride : {}),
  },
  on: {
    [CartFulfillEventType.CLEAR_CART_FULFILL_ERROR]: {
      actions: ActionTypes.clearCartFulfillError,
    },
    [CartQuoteEventType.RETRY_QUOTE]: {
      target: `#${config.parentMachineId}.${CartState.cartQuote}.${CartQuoteState.route}`,
      actions: [
        CartQuoteActionTypes.setQuoteRetries,
        CartQuoteActionTypes.clearCartQuoteError,
        CartUpdateActionTypes.clearCartUpdateError,
      ],
    },
  },
});
