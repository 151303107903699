import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IApiConfig } from "@hopper-b2b/types";
import { apiConfig, useDebounce } from "@hopper-b2b/utilities";
import { SearchParameters } from "@b2bportal/air-shopping-api";
import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Autocomplete as MuiAutocomplete,
} from "@material-ui/lab";
import { useCallback, useEffect, useState } from "react";
import { Loading } from "../Loading";
import styles from "./Autocomplete.module.scss";

export type AutocompleteProps<T> = {
  id: string;
  defaultValue?: T;
  label: string;
  disabled?: boolean;
  fetch: (
    apiConfig: IApiConfig | undefined,
    search: string,
    callback: (newOptions: T[]) => void
  ) => Promise<void>;
  onChange: (value: T) => void;
  sortOptions?: (options?: T[]) => T[];
  groupBy?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  getGroupLabelKey?: (group: string) => string;
  icon?: React.ReactElement;
  endIcon?: string;
  onOpen?: () => void;
  className?: string;
  paperClassName?: string;
  placeholder?: string;
  closeIcon?: React.ReactNode;
  additionalSearchControl?: SearchParameters;
  enableOpenOnFocus?: boolean;
};

export const Autocomplete = <T,>({
  id,
  defaultValue,
  label,
  disabled,
  fetch,
  sortOptions,
  onChange,
  groupBy,
  getOptionLabel,
  getGroupLabelKey,
  icon,
  endIcon,
  onOpen,
  className,
  paperClassName,
  placeholder,
  closeIcon,
  enableOpenOnFocus,
}: AutocompleteProps<T>) => {
  const { t } = useI18nContext();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shrink, setShrink] = useState<boolean>(false);

  const [value, setValue] = useState<T>(defaultValue ?? null);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<T[]>([]);

  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    if (!open) return;
    setLoading(true);
    fetch(apiConfig, debouncedSearch, (newOptions: T[]) => {
      setOptions(sortOptions ? sortOptions(newOptions) : newOptions);
      setLoading(false);
    });
  }, [fetch, open, debouncedSearch, sortOptions]);

  const handleOnInputChange = useCallback((_event, newInputValue) => {
    setSearch(newInputValue);
  }, []);

  const handleOnChangeValue = useCallback(
    (_event, value: T) => {
      setValue(value);
      onChange(value);
      trackEvent({
        eventName: "tapped_search_suggestion",
        ...value?.["trackingPropertiesV2"],
      });
    },
    [onChange]
  );

  const handleOnOpen = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleOnFocus = useCallback(() => {
    if (enableOpenOnFocus) {
      setOpen(true);
    }
    setShrink(true);
  }, [enableOpenOnFocus]);

  const handleOnBlur = useCallback(() => {
    setShrink(false);
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={label}
        aria-label={label}
        aria-describedby="initInstr"
        placeholder={placeholder}
        variant="filled"
        InputLabelProps={{
          shrink: shrink || !!value,
          focused: false,
          className: styles.label,
        }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: (
            <>
              <InputAdornment position="start">{icon}</InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ),
          endAdornment: (
            <>
              <InputAdornment position="end">
                <Loading loading={loading} />
              </InputAdornment>
              {endIcon && (
                <InputAdornment position="end">
                  <img src={endIcon} alt="" className="autocomplete-end-icon" />
                </InputAdornment>
              )}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [label, placeholder, shrink, value, icon, loading, endIcon]
  );

  const renderGroup = useCallback(
    ({ key, group, children }: AutocompleteRenderGroupParams) => (
      <Box key={key} className={styles.group}>
        <Typography variant="caption" color="textSecondary">
          {t?.(getGroupLabelKey ? getGroupLabelKey(group) : group)}
        </Typography>
        {children}
      </Box>
    ),
    [getGroupLabelKey, t]
  );

  return (
    <>
      <MuiAutocomplete
        id={id}
        open={open}
        disabled={disabled}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        value={value}
        inputValue={search}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        loadingText={t?.("loading")}
        noOptionsText={t?.("noOptions")}
        classes={{ paper: paperClassName }}
        onChange={handleOnChangeValue}
        onInputChange={handleOnInputChange}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        disableClearable={loading}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        renderInput={renderInput}
        renderGroup={renderGroup}
        closeIcon={closeIcon}
        className={className}
        // Disable UI filtering
        filterOptions={(options: T[]) => options}
        aria-label={label}
        aria-autocomplete="list"
        aria-owns="options"
      />
      <span id="initInstr" style={{ display: "none" }}>
        {t("autocompleteAriaHelperText")}
      </span>
      <div aria-live="assertive" className="screen-reader-text"></div>
    </>
  );
};
