import { useCallback, useState } from "react";
import { debounce } from "lodash-es";
import dayjs from "dayjs";
import { ClientName, ITimeRange } from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GenericSlider } from "@hopper-b2b/ui";
import { TIME_RANGE_MAX } from "../../../../../../reducer";
import "./styles.scss";

interface ITimeWindowSelectionProps {
  onChange: (min: number, max: number) => void;
  timeRange?: ITimeRange;
  label?: string;
}

export const TimeWindowSelection = ({
  onChange,
  timeRange,
  label,
}: ITimeWindowSelectionProps) => {
  const [value, setValue] = useState(timeRange);
  const { brand } = useI18nContext();

  const isNubank = getEnvVariables("clientName") === ClientName.NUBANK;

  // update the state as it changes, but debounce the onchange itself
  // debounce in our utils is only for state, not for actions itself
  const debouncedAction = debounce(onChange, 300);
  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const sliderChangeHandler = useCallback(
    (min: number, max: number) => {
      setValue({ min: min, max: max });
      stateDebounceDispatchAction(min, max);
    },
    [stateDebounceDispatchAction]
  );

  const timeLabelHandler = useCallback(
    (time: number) => {
      const totalTime = dayjs().hour(0).minute(time);
      return totalTime.format(brand.timeFormat || "hh:mm A");
    },
    [brand.timeFormat]
  );

  return (
    <div className="departure-arrival-dropdown">
      <div className="departure-arrival-selection-root">
        <div className="departure-arrival-selection-container">
          <div className="selection-container">
            <div className="time-window-slider-container">
              <div className="label-container">{label}</div>
              <GenericSlider
                className="time-window-slider-root"
                onChange={sliderChangeHandler}
                sliderType={"doubleThumb"}
                step={1}
                chosenMin={value.min}
                chosenMax={value.max}
                sliderMin={0}
                sliderMax={TIME_RANGE_MAX}
                getLabel={timeLabelHandler}
                updateLabelsOnChange={isNubank}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
