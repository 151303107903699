/* eslint-disable no-case-declarations */
import { delay, putResolve, select } from "redux-saga/effects";
import { pollFinalized } from "../../../../api/v1/book/book-flow/pollFinalized";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyAirPriceFreezeProperties,
  getCompleteBuyAirProperties,
  getFlightBookType,
  getSession,
} from "../../reducer";
import {
  GetPaymentPending,
  GetPaymentFailed,
  GetPaymentResultEnum,
  PaymentFinalizeResult,
  PaymentFinalizeSucceeded,
  PaymentFinalizeResultEnum,
  COMPLETE_BUY_AIR,
  ErrorCode,
  TravelItineraryEnum,
  MultiTravelItinerary,
  SingleTravelItinerary,
  FlightBookType,
  FlightShopType,
} from "@hopper-b2b/types";
import Logger from "../../../../helpers/Logger";
import {
  setPollFinalizedCallStateFailure,
  setPollFinalizedCallStateSuccess,
  setFinalized,
  IPollFinalized,
} from "../../actions/actions";
import { trackEvent } from "@hopper-b2b/api";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { getCompleteBuyAirChfarProperties } from "../../../chfar/reducer";

export function* pollFinalizedSaga(_: IPollFinalized) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const completeBuyAirProperties = getCompleteBuyAirProperties(state);
    const completeBuyAirPriceFreezeProperties =
      getFlightBookType(state) === FlightBookType.PRICE_FREEZE_EXERCISE
        ? getCompleteBuyAirPriceFreezeProperties(state)
        : undefined;

    const chfarId = state.flightShop.chfarId;
    const isChfarFlightShop =
      state.flightShop.flightShopType === FlightShopType.CHFAR_EXERCISE &&
      chfarId;
    const completeBuyAirChfarProperties = isChfarFlightShop
      ? getCompleteBuyAirChfarProperties(state)
      : undefined;

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [
      2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000, 30000,
    ];

    let pollFailed = false;
    let index = 0;
    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const state: IStoreState = yield select();
      const apiConfig = state[flightApiConfigStoreKey];
      const finalizedCheckedResponse: PaymentFinalizeResult =
        yield pollFinalized(sessionToken, apiConfig);

      switch (finalizedCheckedResponse.result) {
        case GetPaymentResultEnum.Failed:
          pollFailed = true;
          const failedResponse = finalizedCheckedResponse as GetPaymentFailed;
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollFinalizedCallStateFailure(failedResponse.errors)
            );
          } else {
            yield putResolve(setPollFinalizedCallStateFailure([]));
            throw new Error(
              "Poll Finalized response returned an error and the given error code is not handleable."
            );
          }
          trackEvent(
            {
              eventName: COMPLETE_BUY_AIR,
              properties: {
                ...completeBuyAirProperties,
                ...completeBuyAirPriceFreezeProperties,
                ...completeBuyAirChfarProperties,
                failure_reason:
                  failedResponse.errors.length > 0
                    ? failedResponse.errors
                        .map((error) =>
                          (error as ErrorCode)?.code
                            ? (error as ErrorCode).code
                            : error.PaymentError
                        )
                        .join(" - ")
                    : "Poll Finalized response returned an error and the given error code is not handleable.",
                success: false,
              },
            },
            apiConfig
          );
          return;
        case GetPaymentResultEnum.Pending:
          const priceQuoteResponsePoll =
            finalizedCheckedResponse as GetPaymentPending;
          switch (priceQuoteResponsePoll.result) {
            case GetPaymentResultEnum.Failed:
              pollFailed = true;
              yield putResolve(setPollFinalizedCallStateFailure([]));
              // TODO: differentiate tracking for PF purchase
              trackEvent(
                {
                  eventName: COMPLETE_BUY_AIR,
                  properties: {
                    ...completeBuyAirProperties,
                    ...completeBuyAirPriceFreezeProperties,
                    success: false,
                    failure_reason:
                      "Poll Finalized pending response returned an error and the given error code is not handleable.",
                  },
                },
                apiConfig
              );
              break;
            default:
              continue;
          }
          break;
        case GetPaymentResultEnum.Succeeded:
          const finalizedResponse =
            finalizedCheckedResponse as PaymentFinalizeSucceeded;
          switch (finalizedResponse.result) {
            case GetPaymentResultEnum.Succeeded:
              yield putResolve(setPollFinalizedCallStateSuccess());

              switch (finalizedResponse.PaymentFinalizeResult) {
                case PaymentFinalizeResultEnum.FlightPaymentFinalizeResult:
                  yield putResolve(setFinalized(finalizedResponse));

                  const trip =
                    finalizedResponse.purchaseResult.itinerary.travelItinerary;
                  let brand = null;

                  let provider = "";
                  if (
                    trip &&
                    trip?.TravelItinerary ===
                      TravelItineraryEnum.SingleTravelItinerary
                  ) {
                    brand = (trip as unknown as SingleTravelItinerary).slices[0]
                      .segments[0].fareBrand;
                  } else if (
                    trip &&
                    trip?.TravelItinerary ===
                      TravelItineraryEnum.MultiTravelItinerary
                  ) {
                    brand = (trip as unknown as MultiTravelItinerary)
                      .travelItineraries[0].slices[0].segments[0].fareBrand;
                  }

                  if (brand) {
                    if (brand.sabreFareBrand) {
                      provider = "Sabre";
                    } else if (brand.travelportFareBrand) {
                      provider = "Travelport";
                    } else if (brand.amadeusFareBrand) {
                      provider = "Amadeus";
                    } else if (brand.gdxFareBrand) {
                      provider = "GDX";
                    }
                  }

                  trackEvent(
                    {
                      eventName: COMPLETE_BUY_AIR,
                      properties: {
                        ...completeBuyAirProperties,
                        ...completeBuyAirPriceFreezeProperties,
                        success: true,
                        provider: provider || "",
                        agent_locator:
                          finalizedResponse.purchaseResult.itinerary
                            .travelItinerary.locators?.agent.unscopedValue,
                      },
                    },
                    apiConfig
                  );
                  break;
                case PaymentFinalizeResultEnum.PriceFreezeFlightPaymentFinalizeResult:
                  yield putResolve(setFinalized(finalizedResponse));
                  // TODO: handle tracking for PF purchase
                  break;
                default:
                  break;
              }

              return;
            default:
              pollFailed = true;
              yield putResolve(setPollFinalizedCallStateFailure([]));
              // TODO: differentiate tracking for PF purchase
              trackEvent(
                {
                  eventName: COMPLETE_BUY_AIR,
                  properties: {
                    ...completeBuyAirProperties,
                    ...completeBuyAirPriceFreezeProperties,
                    success: false,
                    failure_reason:
                      "Poll Finalized success response returned Failed",
                  },
                },
                apiConfig
              );
              throw new Error("Poll Finalized Failed");
          }
      }

      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    Logger.debug(e);
    yield putResolve(setPollFinalizedCallStateFailure([]));
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const completeBuyAirProperties = getCompleteBuyAirProperties(state);
    const completeBuyAirPriceFreezeProperties =
      getCompleteBuyAirPriceFreezeProperties(state);
    // TODO: differentiate tracking for PF purchase
    trackEvent(
      {
        eventName: COMPLETE_BUY_AIR,
        properties: {
          ...completeBuyAirProperties,
          ...completeBuyAirPriceFreezeProperties,
          failure_reason: "Poll Finalized Network Call Failed",
        },
      },
      apiConfig
    );
  }
}
