// Generic Title
export const PORTAL_TITLE = "Travel Portal";
// Flight Titles
export const FLIGHT_SEARCH_TITLE = "Search for a flight";
export const CHOOSE_DEPARTURE_TITLE = "Select a departure flight";
export const CHOOSE_RETURN_TITLE = "Select an return flight";
export const REVIEW_ITINERARY_TITLE = "Review Flight Itinerary";
export const FLIGHT_CHECKOUT_TITLE = "Complete Flight Booking";
export const BOOKING_CONFIRMATION_TITLE = "Booking Confirmation";
// Price Freeze Titles
export const PRICE_FREEZE_CHECKOUT = "Review and freeze price";
export const PRICE_FREEZE_OVERVIEW = "Review active frozen price";
export const PRICE_FREEZE_BOOK = "Book and checkout flight";
export const CHOOSE_SIMILAR_OUTBOUND = "Choose Similar Outbound Flight";
export const CHOOSE_SIMILAR_RETURN = "Choose Similar Return Flight";
export const REVIEW_ACTIVE_FROZEN_PRICE = "Review active frozen price";
