import { useDeviceTypes } from "@hopper-b2b/utilities";
import { IRerunPrediction } from "../../../../../shop/actions/actions";
import {
  DesktopFlightShopSearchFilter,
  MobileFlightShopSearchFilter,
} from "./components";
import { FlightShopSearchFilterConnectorProps } from "./container";

export interface IFlightShopSearchFilterProps
  extends FlightShopSearchFilterConnectorProps {
  hideAirportFilter?: boolean;
  filtersOpen?: boolean;
  isReturn?: boolean;
  setFiltersOpen?: (filtersOpen: boolean) => void;
  setRerunPrediction: () => IRerunPrediction;
}

export const FlightShopSearchFilter = ({
  hideAirportFilter,
  flightShopFilters,
  filtersOpen,
  isReturn,
  setFiltersOpen,
  setRerunPrediction,
}: IFlightShopSearchFilterProps) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <MobileFlightShopSearchFilter
      flightShopFilters={flightShopFilters}
      hideAirportFilter={hideAirportFilter}
      filtersOpen={filtersOpen}
      setFiltersOpen={setFiltersOpen}
      isReturn={isReturn}
      setRerunPrediction={setRerunPrediction}
    />
  ) : (
    <DesktopFlightShopSearchFilter
      flightShopFilters={flightShopFilters}
      hideAirportFilter={hideAirportFilter}
    />
  );
};
