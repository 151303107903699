import { PriceBreakdownContent } from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Currency, ITotalLineItem } from "@hopper-b2b/types";
import { useSelector } from "react-redux";
import {
  getProductLineItems,
  getTotalAmount,
  shopPricingInfoSelector,
} from "../../../reducer";
import { getTripCategory } from "../../../../../../src/modules/search/reducer";
import styles from "./CheckoutBreakdown.module.scss";

export const CheckoutBreakdown = () => {
  const { t } = useI18nContext();
  const shopPricingInfo = useSelector(shopPricingInfoSelector);
  const tripCurrency = {
    currencyCode: shopPricingInfo?.fare?.[0]?.pricing.baseAmount.fiat
      .currencyCode as Currency,
    currencySymbol:
      shopPricingInfo?.fare?.[0]?.pricing.baseAmount.fiat.currencySymbol,
  };
  const isPriceBreakdownLoading = false;
  const tripPriceLineItems = useSelector(getProductLineItems);
  const totalPrice = useSelector(getTotalAmount);
  const tripPricingSummaryItems = totalPrice
    ? ([
        {
          type: "total",
          fiatPrice: {
            currencyCode: tripCurrency?.currencyCode,
            currencySymbol: tripCurrency?.currencySymbol,
            value: totalPrice,
          },
        },
      ] as ITotalLineItem[])
    : [];
  const tripCategory = useSelector(getTripCategory);

  return (
    <PriceBreakdownContent
      isLoading={isPriceBreakdownLoading}
      initialPassengerPricing={shopPricingInfo?.fare?.[0]?.pricing}
      currency={tripCurrency}
      lineItems={tripPriceLineItems}
      summaryLineItems={tripPricingSummaryItems}
      header={t("checkoutBreakdown")}
      tripCategory={tripCategory}
      customClassName={styles.checkoutContent}
      currencyString={null}
    />
  );
};
