import { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { ActionLink, ChoiceButton } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import "./styles.scss";

export interface ITravelerSelectRowProps {
  className?: string;
  value: string;
  selectRowType: string;
  isSelected: boolean;
  onClickEdit: () => void;
  onClickRemove?: () => void;
  onClickSelect?: () => void;
  buttonClassName?: string;
  wrapButton?: boolean;
  disabled?: boolean;
  isMobile?: boolean;
}

export const TravelerSelectRow = ({
  className,
  value,
  selectRowType,
  isSelected,
  onClickEdit,
  onClickRemove,
  onClickSelect,
  buttonClassName,
  wrapButton,
  disabled,
  isMobile,
}: ITravelerSelectRowProps): JSX.Element => {
  const { t, language } = useI18nContext();

  const travelerTypeEdit = useMemo(() => {
    return selectRowType === "phoneNumber"
      ? t("phone")
      : selectRowType === "email"
      ? t("email")
      : selectRowType;
  }, [selectRowType, t]);

  return (
    <div
      className={clsx("traveler-select-row-root", className, {
        disabled,
        mobile: isMobile,
      })}
    >
      <div
        className={clsx("traveler-select-row-container", {
          selected: isSelected,
        })}
      >
        <div className="traveler-select-row-description">
          <Typography variant="h6" className="name" data-dd-privacy="mask">
            {value}
          </Typography>
          <Typography variant="body1" className="type" data-dd-privacy="mask">
            {travelerTypeEdit}
          </Typography>
          {wrapButton && (
            <div className="traveler-select-row-edit-delete-buttons-container">
              <ActionLink
                className="edit-button"
                disabled={disabled}
                onClick={onClickEdit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickEdit();
                  }
                }}
                label={t("editTraveler")}
                content={
                  <Typography className="action-link-text">
                    {t("edit")}
                  </Typography>
                }
              />
              {onClickRemove && (
                <>
                  <Typography
                    className={clsx(
                      "action-link-text",
                      "action-link-separator"
                    )}
                  >
                    |
                  </Typography>
                  <ActionLink
                    className="remove-button"
                    disabled={disabled}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onClickRemove();
                      }
                    }}
                    onClick={onClickRemove}
                    label={t("removeTraveler")}
                    content={
                      <Typography className="action-link-text">
                        {t("delete")}
                      </Typography>
                    }
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div className="traveler-select-row-buttons-section">
          {!wrapButton && !disabled && (
            <>
              <ActionLink
                className="edit-button"
                disabled={disabled}
                onClick={onClickEdit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickEdit();
                  }
                }}
                label={t("editTraveler")}
                content={
                  <Typography className="action-link-text">
                    {t("edit")}
                  </Typography>
                }
              />
              {onClickRemove && (
                <>
                  <Typography
                    className={clsx(
                      "action-link-text",
                      "action-link-separator"
                    )}
                  >
                    |
                  </Typography>
                  <ActionLink
                    className="remove-button"
                    disabled={disabled}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onClickRemove();
                      }
                    }}
                    onClick={onClickRemove}
                    label={t("removeTraveler")}
                    content={
                      <Typography className="action-link-text">
                        {t("delete")}
                      </Typography>
                    }
                  />
                </>
              )}
            </>
          )}
          {onClickSelect ? (
            <ChoiceButton
              onClick={onClickSelect}
              isChosen={isSelected}
              disabled={!!disabled}
              ariaLabel={`${t("choose")} ${value}`}
              message={t("choose")}
              className={`${buttonClassName} ${language}`}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
