import { put, select, putResolve, call } from "redux-saga/effects";

import { actions as searchActions } from "../../search/actions";
import { fetchTripSummaries as fetchSummaries } from "../../../api/v1/shop/fetchTripSummaries";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import {
  getAirEntryProperties,
  getPriceFreezeOfferWithSuggested,
  maxFlightPriceSelector,
} from "../reducer/selectors";
import { IShopParams } from "../actions/actions";
import { setUpFlightShopParams } from "./utils/setUpFlightShopParams";
import {
  AirEntryProperties,
  AIR_ENTRY,
  OfferWithSuggested,
  ShopSummaryResponse,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { trackEvent } from "@hopper-b2b/api";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";

export function* fetchTripSummaries(action: actions.IFetchTripSummaries) {
  try {
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    } = yield call(setUpFlightShopParams, action);
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
    };
    const response: ShopSummaryResponse = yield fetchSummaries(
      requestBody,
      action.isMobile,
      apiConfig
    );

    const properties: AirEntryProperties | null = yield select(
      getAirEntryProperties
    );
    yield trackEvent(
      {
        eventName: AIR_ENTRY,
        properties,
      },
      apiConfig
    );

    // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
    // related selectors prior to that of the prediction
    yield putResolve(actions.setTripSummaries(response.tripSummaries));
    yield putResolve(actions.setPriceFreezeOffer(response.priceFreezeOffer));
    yield putResolve(actions.setPrediction(response.prediction));

    const priceFreeze: OfferWithSuggested | null = yield select(
      getPriceFreezeOfferWithSuggested
    );
    if (priceFreeze) {
      yield put(actions.fetchTripDetails(priceFreeze.cheapestTrip.tripId));
    }
    const maxPriceFlight: number = yield select(maxFlightPriceSelector);
    yield put(searchActions.setMaxPriceFilter(maxPriceFlight));

    yield put(searchActions.setAwaitingRefetch(false));
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}
