import {
  CallState,
  WalletOffer,
  WalletVoucherStatusEnum,
} from "@hopper-b2b/types";
import { actionTypes, actions } from "../actions";
export * from "./selectors";

export interface ILodgingWalletState {
  walletOfferCallState: CallState;
  walletOffers?: WalletOffer[];
  selectedWalletOffers?: WalletOffer;
  bestOffer?: WalletOffer;
  creditOffer?: WalletOffer;
  selectedCreditOffer?: WalletOffer;
  offerStatus?: WalletVoucherStatusEnum;
}

export const initialState: ILodgingWalletState = {
  walletOfferCallState: CallState.NotCalled,
  walletOffers: undefined,
  selectedWalletOffers: undefined,
  bestOffer: undefined,
  creditOffer: undefined,
  selectedCreditOffer: undefined,
  offerStatus: undefined,
};

export function reducer(
  state: ILodgingWalletState = initialState,
  action: actions.LodgingWalletActions
): ILodgingWalletState {
  switch (action.type) {
    case actionTypes.FETCH_WALLET_OFFERS:
      return {
        ...initialState,
        walletOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_WALLET_OFFERS:
      return {
        ...state,
        walletOffers: action.offers,
        walletOfferCallState: CallState.Success,
        selectedWalletOffers: action.bestOffer,
        bestOffer: action.bestOffer,
        creditOffer: action.creditOffer,
        selectedCreditOffer: action.creditOffer,
      };

    case actionTypes.SET_WALLET_OFFERS_FAILED:
      return {
        ...state,
        walletOfferCallState: CallState.Failed,
      };

    case actionTypes.SET_SELECTED_WALLET_OFFER:
      return {
        ...state,
        selectedWalletOffers: action.selectedWalletOffer,
      };

    case actionTypes.SET_SELECTED_CREDIT_OFFER:
      return {
        ...state,
        selectedCreditOffer: action.selectedCreditOffer
          ? state.creditOffer
          : undefined,
      };

    default:
      return state;
  }
}

export * from "./selectors";
