import { trackEvent } from "@hopper-b2b/api";
import { VIEW_PAYMENT } from "@hopper-b2b/types";

export const trackViewPayment = (_context, _event: unknown) =>
  new Promise((resolve, _reject) => {
    const viewPaymentProperties = {}; //TODO

    trackEvent({
      eventName: VIEW_PAYMENT,
      properties: viewPaymentProperties,
    })
      .then(() => resolve(null))
      .catch(() => resolve(null));
  });
