import {
  CartActions,
  ContactInformationActions,
  getObjectKeysAsObject,
  LodgingActions,
  PassengerActions,
  ReviewActions,
  ResumeLodgingActions,
  WalletActions,
} from "@hopper-b2b/checkout";
import { commonActions } from "./common";
import { NubankPaymentActions } from "../../../checkout/states/payments";

export const Actions = {
  ...CartActions,
  ...commonActions,
  ...ContactInformationActions,
  ...LodgingActions,
  ...NubankPaymentActions,
  ...PassengerActions,
  ...ResumeLodgingActions,
  ...WalletActions,
  ...ReviewActions,
};

export const ActionTypes = getObjectKeysAsObject(Actions);
