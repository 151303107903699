import {
  CallState,
  ClientName,
  FlightShopDefaultSort,
  FlightShopType,
} from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { actionTypes, actions } from "../actions";
import { FlightShopStep, IFlightShopState } from "./types";
import { compareDuration, comparePrices, formatTripSummaries } from "./utils";
import dayjs from "dayjs";

const initialSortOption = () => {
  switch (getEnvVariables("clientName")) {
    case "volaris":
    case "flair":
      return "price";
    case "jetblue-site":
      return "stopAndDepartureTime";
    default:
      return "fareScore";
  }
};

export const initialState: IFlightShopState = {
  tripSummariesById: {},
  flightGridFares: null,
  returnFlightsByOutgoingId: {},
  tripDetailsById: {},
  selectedTrip: {
    tripId: null,
    outgoingSliceId: null,
    outgoingFareId: null,
  },
  sortedTripIds: [],
  tripSummariesError: false,
  predictionError: false,
  tripSummariesLoading: true,
  predictionLoading: null,
  tripDetailsLoading: null,
  // TODO: change to FlightShopStep.PricePrediction when adding price prediction
  progress: FlightShopStep.ChooseDeparture,
  sortOption: initialSortOption(),
  prediction: null,
  rerunPrediction: false,
  watches: [],
  createWatchCallState: CallState.NotCalled,
  updateWatchCallState: CallState.NotCalled,
  listWatchCallState: CallState.NotCalled,
  deleteWatchCallState: CallState.NotCalled,
  calendarBuckets: [],
  priceBuckets: [],
  openCalendarModal: false,
  openLocationModal: false,
  priceFreezeOffer: null,
  flights: null,
  flightShopType: FlightShopType.DEFAULT,
  similarFlightsResponse: null,
  fetchSimilarFlightsCallState: CallState.NotCalled,
  transferToSimilarFlightsResponse: null,
  fetchTransferToSimilarFlightsCallState: CallState.NotCalled,
  selectedTripPriceFreezeOffer: null,
  selectedTripPriceFreezeOfferResponse: null,
  fetchSelectedTripPriceFreezeOfferCallState: CallState.NotCalled,
  declineAllFintechOptions: false,
  // TODO(thilina): remove tenant names and use env flags instead
  renderLoadingSteps: [ClientName.UBER, ClientName.NUBANK].includes(
    getEnvVariables("clientName") as ClientName
  ),
  defaultSort: FlightShopDefaultSort.Recommended,
  offers: null,
  // EXPERIMENT TO TAG FLIGHTS IN THE FRONTEND FOR VI DO NOT USE THIS
  topFlightsIds: [],
};

export function reducer(
  state: IFlightShopState = initialState,
  action: actions.FlightShopActions
): IFlightShopState {
  switch (action.type) {
    case actionTypes.FETCH_TRIP_SUMMARIES:
      return {
        ...state,
        rerunPrediction: false,
        tripSummariesLoading: true,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION:
      return {
        ...state,
        predictionLoading: true,
        tripSummariesLoading: true,
        rerunPrediction: false,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_V2:
      return {
        ...state,
        rerunPrediction: false,
        tripSummariesLoading: true,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_V3:
      return {
        ...state,
        rerunPrediction: false,
        tripSummariesLoading: true,
      };

    case actionTypes.FETCH_FLIGHTS_V4:
      return {
        ...state,
        rerunPrediction: false,
        tripSummariesLoading: true,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2:
      return {
        ...state,
        predictionLoading: true,
        tripSummariesLoading: true,
        rerunPrediction: false,
      };

    case actionTypes.SET_TRIP_SUMMARIES_ERROR: {
      return {
        ...state,
        tripSummariesById: {},
        returnFlightsByOutgoingId: {},
        tripSummariesError: true,
        predictionLoading: false,
        tripSummariesLoading: false,
      };
    }

    case actionTypes.RERUN_PREDICTION: {
      return {
        ...state,
        rerunPrediction: true,
      };
    }

    case actionTypes.SET_PREDICTION_ERROR: {
      return {
        ...state,
        prediction: null,
        predictionError: true,
      };
    }

    case actionTypes.SET_PREDICTION: {
      return {
        ...state,
        prediction: action.prediction,
        predictionError: false,
      };
    }

    case actionTypes.SET_OFFERS: {
      return {
        ...state,
        offers: action.offers,
      };
    }

    case actionTypes.SET_PRICE_PRICE_FREEZE_OFFER: {
      return {
        ...state,
        priceFreezeOffer: action.payload,
      };
    }

    case actionTypes.FETCH_TRIP_DETAILS:
      return {
        ...state,
        tripDetailsLoading: true,
      };

    case actionTypes.SET_TRIP_SUMMARIES: {
      const { tripSummaries } = action;
      const { tripSummariesById, returnFlightsByOutgoingId } =
        formatTripSummaries(tripSummaries);

      return {
        ...state,
        tripSummariesById: { ...tripSummariesById },
        returnFlightsByOutgoingId: { ...returnFlightsByOutgoingId },
        tripSummariesLoading: false,
        tripSummariesError: false,
        predictionLoading: false,
      };
    }

    case actionTypes.SET_FLIGHTS: {
      const { flights } = action;
      return {
        ...state,
        flights,
        tripSummariesLoading: false,
        tripSummariesError: false,
        predictionLoading: false,
      };
    }

    case actionTypes.SET_TRIP_DETAILS: {
      const { tripDetails } = action;
      const tripDetailsById = {
        ...state.tripDetailsById,
        [tripDetails.id]: tripDetails,
      };
      return {
        ...state,
        tripDetailsLoading: false,
        tripDetailsById,
      };
    }

    case actionTypes.SET_CHOSEN_OUTGOING_SLICE: {
      const {
        outgoingSliceId,
        outgoingFareRating,
        outgoingFareId,
        tripId,
        resetReturnIds,
      } = action;
      const selectedTrip = {
        ...state.selectedTrip,
        tripId,
        outgoingSliceId,
        outgoingFareId: outgoingFareId || null,
        outgoingFareRating: outgoingFareRating,
        // resetting the return ids are sometimes required depending on the current progress of FlightShop workflow
        ...(resetReturnIds && {
          returnSliceId: null,
          returnFareId: null,
        }),
      };
      return {
        ...state,
        selectedTrip,
      };
    }

    case actionTypes.SET_CHOSEN_RETURN_SLICE: {
      const { returnSliceId, returnFareId, returnFareRating, tripId } = action;
      const selectedTrip = {
        ...state.selectedTrip,
        tripId,
        returnSliceId,
        returnFareRating,
        returnFareId: returnFareId || null,
      };
      return {
        ...state,
        selectedTrip,
      };
    }

    case actionTypes.RESET_SELECTED_TRIP: {
      return {
        ...state,
        selectedTrip: {
          tripId: null,
          outgoingSliceId: null,
          outgoingFareId: null,
        },
      };
    }

    case actionTypes.SET_FLIGHT_SHOP_TYPE: {
      return {
        ...state,
        flightShopType: action.flightShopType,
      };
    }

    case actionTypes.SET_FLIGHT_SHOP_PROGRESS: {
      const { progress } = action;

      return {
        ...state,
        progress,
      };
    }

    case actionTypes.SET_RENDER_LOADING_STEPS: {
      const { renderLoadingSteps } = action;

      return {
        ...state,
        renderLoadingSteps,
      };
    }

    case actionTypes.FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER:
      return {
        ...state,
        selectedTripPriceFreezeOfferResponse: null,
        fetchSelectedTripPriceFreezeOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_SELECTED_TRIP_PRICE_FREEZE_OFFER: {
      const { offer } = action;

      return {
        ...state,
        selectedTripPriceFreezeOffer: offer,
      };
    }

    case actionTypes.FETCH_SIMILAR_FLIGHTS: {
      return {
        ...state,
        similarFlightsResponse: null,
        fetchSimilarFlightsCallState: CallState.InProcess,
      };
    }

    case actionTypes.SET_SIMILAR_FLIGHTS_RESPONSE: {
      return {
        ...state,
        similarFlightsResponse: action.response,
        fetchSimilarFlightsCallState: action.callState,
      };
    }

    case actionTypes.SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED: {
      return {
        ...state,
        fetchSimilarFlightsCallState: CallState.Failed,
      };
    }

    case actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS: {
      return {
        ...state,
        transferToSimilarFlightsResponse: null,
        fetchTransferToSimilarFlightsCallState: CallState.InProcess,
      };
    }

    case actionTypes.SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE: {
      return {
        ...state,
        transferToSimilarFlightsResponse: action.response,
        fetchTransferToSimilarFlightsCallState: action.callState,
      };
    }

    case actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED: {
      return {
        ...state,
        fetchTransferToSimilarFlightsCallState: CallState.Failed,
      };
    }

    case actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED: {
      return {
        ...state,
        fetchTransferToSimilarFlightsCallState: CallState.NotCalled,
      };
    }

    case actionTypes.SET_SORT_OPTION: {
      const { sortOption } = action;

      return {
        ...state,
        sortOption,
      };
    }

    case actionTypes.CREATE_WATCH:
      return {
        ...state,
        createWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_CREATE_WATCH_CALL_STATE:
      return {
        ...state,
        createWatchCallState: action.callState,
      };

    case actionTypes.UPDATE_WATCH:
      return {
        ...state,
        updateWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_UPDATE_WATCH_CALL_STATE:
      return {
        ...state,
        updateWatchCallState: action.callState,
      };

    case actionTypes.DELETE_WATCH:
      return {
        ...state,
        deleteWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_DELETE_WATCH_CALL_STATE:
      return {
        ...state,
        deleteWatchCallState: action.callState,
      };

    case actionTypes.LIST_WATCHES:
      return {
        ...state,
        listWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_LIST_WATCHES_CALL_STATE:
      return {
        ...state,
        listWatchCallState: action.callState,
      };

    case actionTypes.SET_WATCHES:
      return {
        ...state,
        watches: action.watches,
      };

    case actionTypes.SET_CALENDAR_BUCKETS:
      return {
        ...state,
        calendarBuckets: action.months,
        priceBuckets: action.prices,
      };

    case actionTypes.SET_OPEN_CALENDAR_MODAL:
      return {
        ...state,
        openCalendarModal: action.openCalendarModal,
      };

    case actionTypes.SET_OPEN_LOCATION_MODAL:
      return {
        ...state,
        openLocationModal: action.openLocationModal,
      };

    case actionTypes.SET_DECLINE_FINTECH_OPTIONS:
      return {
        ...state,
        declineAllFintechOptions: action.declineAllFintechOptions,
      };

    case actionTypes.SET_CHFAR_ID:
      return {
        ...state,
        chfarId: action.chfarId,
      };

    case actionTypes.SET_FLIGHT_SHOP_DEFAULT_SORT:
      return {
        ...state,
        defaultSort: action.defaultSort,
      };

    case actionTypes.SET_SHOP_TRACKING_PROPERTIES:
      return {
        ...state,
        trackingProperties: action.trackingProperties,
      };

    // all this logic will go away when we delete the experiment
    case actionTypes.SET_TOP_FLIGHTS_IDS: {
      const flightsToRender = action.flightsToRender;
      const flightsSlices = flightsToRender.map((f) => ({
        ...f,
        ...state.flights.slices[f.slice],
      }));

      const fastest = [...flightsSlices].sort((a, b) => {
        const duration = compareDuration(a, b);

        if (duration === 0) {
          // If duration times are the same, compare prices
          return comparePrices(a, b);
        }

        return duration;
      })[0];

      const bestValue = [...flightsSlices].sort((a, b) => {
        const priceComparison = comparePrices(a, b);

        if (priceComparison === 0) {
          // if price is the same compare duration
          return compareDuration(a, b);
        }
        return priceComparison;
      })[0];

      const recommend = [...flightsSlices].sort((a, b) => {
        const aCalc =
          a.totalDurationMinutes * 0.25 +
          Math.min(...a.fares.map((f) => f.amount.fiat.value));
        const bCalc =
          b.totalDurationMinutes * 0.25 +
          Math.min(...b.fares.map((f) => f.amount.fiat.value));
        return aCalc - bCalc;
      })[0];
      return {
        ...state,
        topFlightsIds: [
          { name: "bestValue", id: bestValue?.id },
          { name: "recommend", id: recommend?.id },
          { name: "fastest", id: fastest?.id },
        ],
      };
    }

    default:
      return state;
  }
}

export * from "./selectors";
export * from "./types";
export * from "./utils";
