import { Actions } from "./actions";
import { Guards } from "./guards";
import { Services } from "./services";
import { LodgingCheckoutComponent } from "./components";
import { getInitialLodgingMachineContext } from "./initialContext";
import {
  populateCheckoutQueryParams,
  transitionStateOnPathnameChange,
} from "./utilities";
import { lodgingStateMachine } from "./stateMachine";
import { CheckoutProps } from "@hopper-b2b/types";

const LODGING_CHECKOUT_TITLE = "Complete Hotel Booking";
const PORTAL_TITLE = "Hopper Travel";

const onLodgingCheckoutLoad = () => {
  document.title = LODGING_CHECKOUT_TITLE;
};

const onLodgingCheckoutOnCleanUp = () => {
  document.title = PORTAL_TITLE;
};

export const lodgingCheckoutProps: CheckoutProps = {
  stateMachine: lodgingStateMachine,
  Component: LodgingCheckoutComponent,
  actions: Actions,
  context: getInitialLodgingMachineContext(),
  guards: Guards,
  services: Services,
  onCleanUp: onLodgingCheckoutOnCleanUp,
  onLoad: onLodgingCheckoutLoad,
  getInitialContext: getInitialLodgingMachineContext,
  onStateValueChange: populateCheckoutQueryParams,
  onPathnameChange: transitionStateOnPathnameChange,
  validateContext: () => true,
};
