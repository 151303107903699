import { useCallback, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import { AirlineIcon, Slot } from "@hopper-b2b/ui";
import { getSliceIndex } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AirportMap, FareDetails, TripDetails } from "@hopper-b2b/types";
import { getEmptyRestrictionsText } from "@hopper-b2b/utilities";

import {
  Restriction,
  IRestrictionProps,
} from "../FlightShopReviewDetails/component";
import "./styles.scss";

export interface IRestrictionsSectionProps {
  fareDetails: FareDetails | undefined;
  tripDetails: TripDetails;
  isOneWay: boolean;
  returnDate: Date | null;
  departureDate: Date | null;
  airports: AirportMap;
  departureRestrictions: IRestrictionProps[] | null;
  returnRestrictions: IRestrictionProps[] | null;
  isHackerFare: boolean;
}

export const RestrictionsSection = ({
  fareDetails,
  tripDetails,
  isOneWay,
  returnDate,
  departureDate,
  airports,
  departureRestrictions,
  returnRestrictions,
  isHackerFare,
}: IRestrictionsSectionProps) => {
  const { t } = useI18nContext();
  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);

  const getReviewCardHeaderWithTypeLocation = useMemo(
    () =>
      airports[tripDetails.slices[firstSliceIndex]?.destinationCode]
        ? `${
            airports[tripDetails.slices[firstSliceIndex]?.destinationCode]
              .cityName
          } (${
            airports[tripDetails.slices[firstSliceIndex]?.destinationCode].code
          })`
        : tripDetails.slices[firstSliceIndex]?.destinationName,
    [airports, firstSliceIndex, tripDetails]
  );

  const getRestrictionsList = useCallback(
    (restrictions: IRestrictionProps[] | null, index: number) => {
      if (restrictions && restrictions?.length > 0) {
        return restrictions?.map((restriction) => (
          <Restriction
            key={restriction.name}
            symbol={restriction.symbol}
            name={restriction.name}
            description={
              <Typography className="description">
                {restriction.description}
              </Typography>
            }
          />
        ));
      } else {
        return (
          <div className="empty-restrictions">
            {getEmptyRestrictionsText(
              fareDetails?.slices[index]?.fareShelf?.rating,
              t
            )}
          </div>
        );
      }
    },
    [fareDetails?.slices, t]
  );

  const origin = useMemo(
    () =>
      airports[tripDetails.slices[secondSliceIndex]?.destinationCode]
        ? airports[tripDetails.slices[secondSliceIndex]?.destinationCode]
            .cityName
        : tripDetails.slices[secondSliceIndex]?.destinationName,
    [airports, secondSliceIndex, tripDetails]
  );

  const destination = useMemo(
    () => getReviewCardHeaderWithTypeLocation,
    [getReviewCardHeaderWithTypeLocation]
  );

  if (isOneWay) {
    return (
      <Box className="restrictions-section">
        {fareDetails
          ? getRestrictionsList(departureRestrictions, firstSliceIndex)
          : null}
      </Box>
    );
  } else {
    return (
      <>
        <Box
          className={clsx("restrictions-section outbound-section", {
            "hacker-fare-restrictions-section": isHackerFare,
          })}
        >
          <Typography className="fare-slice-header" variant="body1">
            <Slot
              id="fare-details-slice-header"
              origin={origin}
              destination={destination}
              date={departureDate}
              airline={tripDetails.slices[0].segmentDetails[0].airlineName}
              isHackerFare={isHackerFare}
            />
          </Typography>
          {isHackerFare && (
            <Box className="flight-info-details">
              <Box className="airline-details">
                <AirlineIcon
                  airlineCode={
                    tripDetails.slices[0].segmentDetails[0].airlineCode
                  }
                />
              </Box>
              <Typography variant="body2" className="flight-info">
                {tripDetails.slices[0].segmentDetails[0].airlineName}
              </Typography>
            </Box>
          )}
          {fareDetails
            ? getRestrictionsList(departureRestrictions, firstSliceIndex)
            : null}
        </Box>
        <Box
          className={clsx("restrictions-section", {
            "hacker-fare-restrictions-section": isHackerFare,
          })}
        >
          <Typography className="fare-slice-header" variant="body1">
            <Slot
              id="fare-details-slice-header"
              origin={destination}
              destination={origin}
              date={returnDate}
              airline={tripDetails.slices[1].segmentDetails[0].airlineName}
              isHackerFare={isHackerFare}
            />
          </Typography>
          {isHackerFare && (
            <Box className="flight-info-details">
              <Box className="airline-details">
                <AirlineIcon
                  airlineCode={
                    tripDetails.slices[1].segmentDetails[0].airlineCode
                  }
                />
              </Box>
              <Typography variant="body2" className="flight-info">
                {tripDetails.slices[1].segmentDetails[0].airlineName}
              </Typography>
            </Box>
          )}
          {fareDetails
            ? getRestrictionsList(returnRestrictions, secondSliceIndex)
            : null}
        </Box>
      </>
    );
  }
};
