import { useCallback, useContext, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot } from "@hopper-b2b/ui";
import { Product } from "@b2bportal/purchase-api";
import { DisruptionProductType } from "@hopper-b2b/types";
import clsx from "clsx";

import { setDeclineAllFintechOptions } from "../../../actions/actions";
import { ClientContext } from "../../../../../App";
import "./styles.scss";

export type FintechOptionProps = {
  title: string;
  description: string;
  checked?: boolean;
  disabled: boolean;
  useCheckbox?: boolean;
  onClick?: () => void;
  setChecked?: (checked: boolean) => void;
  iconName?: string;
  pricePerPax?: string;
  openInfoSheet: () => void;
  product?: Product | DisruptionProductType;
};

export const FintechOption = (props: FintechOptionProps) => {
  return (
    <Slot
      id={"flights-fintech-selection-page-option"}
      {...props}
      component={<FintechOptionComponent {...props} />}
    />
  );
};

export const FintechOptionComponent = ({
  title,
  description,
  checked,
  disabled,
  useCheckbox = true,
  onClick,
  setChecked,
  iconName,
  pricePerPax,
  openInfoSheet,
}: FintechOptionProps) => {
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (_event, checked: boolean) => {
      setChecked(checked);
    },
    [setChecked]
  );

  const iconSrc = useMemo(
    () => clientContext?.assets?.[iconName],
    [clientContext?.assets, iconName]
  );

  const handleShowHalfSheet = useCallback(
    (event) => {
      event.preventDefault();
      if (!disabled) {
        openInfoSheet();
      }
    },
    [disabled, openInfoSheet]
  );

  const disabledClickHandler = () => {
    if (disabled) {
      dispatch(setDeclineAllFintechOptions(false));
    }
  };

  return (
    <Box
      my={2}
      pr={4}
      className={clsx("fintech-selection-card", {
        selected: checked,
      })}
    >
      <FormControlLabel
        labelPlacement="start"
        disabled={disabled}
        onClick={disabledClickHandler}
        control={
          useCheckbox ? (
            <Checkbox
              className="checkbox"
              checked={checked}
              onChange={onChange}
              color="default"
            />
          ) : (
            <Button
              disableElevation
              className={clsx("cfar-select-button", {
                disabled: disabled,
              })}
              onClick={onClick}
            >
              {checked
                ? t("fintechSelection.edit")
                : t("fintechSelection.select")}
            </Button>
          )
        }
        label={
          <Box
            mr={2}
            className={clsx("fintech-label", {
              disabled: disabled,
            })}
            onClick={handleShowHalfSheet}
          >
            {iconSrc && (
              <img
                alt="fintech type icon"
                className={clsx("fintech-icon", {
                  "src-svg-disabled": disabled,
                })}
                src={iconSrc}
              />
            )}
            <Box>
              <Typography variant="body1" className="fintech-option-title">
                {title}
              </Typography>
              <Typography variant="caption" className="fintech-description">
                {description}
              </Typography>
              <Box className="passenger-price">
                <Typography variant="caption">{pricePerPax}</Typography>
                <img
                  alt="info icon"
                  src={clientContext?.assets?.info}
                  className={clsx("info-icon", {
                    "src-svg-disabled": disabled,
                  })}
                />
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
