import * as React from "react";
import { Box, Popover, Typography } from "@material-ui/core";
import { IconName, Icon } from "../Icon";
import clsx from "clsx";
import "./styles.scss";

export interface IFlightCombinationBannerProps {
  className?: string;
  description: string;
  toolTipCopy: string;
  onClick?: () => void;
}

export const FlightCombinationBanner = (
  props: IFlightCombinationBannerProps
) => {
  const { className, description, toolTipCopy, onClick } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className={clsx(className, "flight-combination-banner")}
        onClick={(event) => {
          onClick && onClick();
          handlePopoverOpen(event);
        }}
      >
        <Typography className="subtitle" variant="subtitle2">
          {description}
        </Typography>
        <Icon name={IconName.InfoCircle} className="info-icon" ariaLabel="" />
      </Box>
      <Popover
        id="mouse-over-popover"
        className="flight-combination-info-modal"
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <Typography
          className="tooltip-info"
          variant="body2"
          onMouseLeave={handlePopoverClose}
        >
          {toolTipCopy ||
            `Restrictions may differ between outbound and return flights.
          Cancellations or changes to one of your flights will not alter the
          other automatically.`}
        </Typography>
      </Popover>
    </>
  );
};
