import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { fetchFlightsV4, fetchTripSummariesV3 } from "../actions/actions";
import {
  tenantFlagsEnabled,
  useDeviceTypes,
  useEnableAirShopV4,
} from "@hopper-b2b/utilities";

export const useFetchFlights = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { matchesMobile } = useDeviceTypes();

  // useFlightV4 is false before we call experiments, should wait for experiments before we call this
  const useFlightV4 = useEnableAirShopV4();
  // TODO: use experiments after fixing experiments to load first before hydrating UI before
  const isIataLookupEnabled = tenantFlagsEnabled.FlightLocationIataLookup;

  return useCallback(
    (controller?: AbortController) =>
      dispatch(
        useFlightV4
          ? fetchFlightsV4(
              history,
              matchesMobile,
              isIataLookupEnabled,
              controller
            )
          : fetchTripSummariesV3(history, matchesMobile, isIataLookupEnabled)
      ),
    [dispatch, history, isIataLookupEnabled, matchesMobile, useFlightV4]
  );
};
