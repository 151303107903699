import { WalletPaymentUpdate } from "@b2bportal/hopper-wallet-api";
import { Payment, PaymentOpaqueValue } from "@b2bportal/purchase-api";
import { PartialParentContext, ParentState } from "../../../types/common";
import { RequiredField } from "@hopper-b2b/utilities";

export interface WalletOffer extends PaymentOpaqueValue {
  type: typeof Payment.Wallet;
  value: WalletPaymentUpdate;
}

export enum WalletState {
  route = "route",
  addDiscount = "addDiscount",
  error = "error",
  getDiscount = "getDiscount",
  removeDiscount = "removeDiscount",
  idle = "idle",
}

export type ParentContextWithWallet = RequiredField<
  PartialParentContext,
  ParentState.wallet
>;
