import { useI18nContext } from "@hopper-b2b/i18n";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Chip, Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./Tags.module.scss";

interface Props {
  available: boolean;
  isPreferred: boolean;
  className?: string;
}

export function Tags({ available, isPreferred, className }: Props) {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  return (
    <div className={clsx(styles.Tags, className)}>
      {!available ? (
        <Typography className={styles.UnavailableText}>
          {t("soldOut")}
        </Typography>
      ) : isPreferred ? (
        <Chip
          className={clsx(styles.PreferredTag, "preferred-tag")}
          color="secondary"
          size="small"
          icon={<img src={icons.logoWhite} alt="" />}
          label={t("preferredHotel")}
        />
      ) : null}
    </div>
  );
}
