import clsx from "clsx";
import H from "history";
import { ActionLink } from "../../../ActionLink";
import "./styles.scss";

export interface IHeaderMenuLinkBaseProps {
  className?: string;
  selected?: boolean;
  content: JSX.Element;
  onClick: (history?: H.History) => void;
  disabled?: boolean;
}

type IHeaderMenuLinkProps = IHeaderMenuLinkBaseProps;

const HeaderMenuLink = ({
  className,
  selected,
  content,
  onClick,
  disabled,
}: IHeaderMenuLinkProps) => {
  return (
    <ActionLink
      className={clsx("header-menu-link", className, { selected: selected })}
      content={content}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
export default HeaderMenuLink;
