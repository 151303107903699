import { TripCategory } from "@hopper-b2b/types";
import { connect, ConnectedProps } from "react-redux";
import { matchPath } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { PATH_EXCHANGE } from "../../../../utils/urlPaths";
import { getItinerary } from "../../../exchange/selectors";
import {
  getAwaitingRefetch,
  getDestination,
  getOrigin,
  getTripCategory,
} from "../../../search/reducer/selectors";
import { actions } from "../../../shop/actions";
import {
  flightShopProgressSelector,
  selectedTripSelector,
  tripDetailsSelector,
  tripSummariesLoadingSelector,
} from "../../../shop/reducer/selectors";
import { FlightShopHeader } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);
  const prevItinerary = getItinerary(state);
  const tripId = selectedTripSelector(state)?.tripId ?? "";
  // TODO: handle hasChosenDepartureFlight logic properly once selected fare option is stored in redux
  const hasChosenDepartureFlight = inExchange
    ? Boolean(prevItinerary)
    : tripDetailsSelector(state, tripId) !== undefined;

  return {
    currentStep: flightShopProgressSelector(state),
    isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
    destination: getDestination(state),
    origin: getOrigin(state),
    hasChosenDepartureFlight,
    awaitingRefetch: getAwaitingRefetch(state),
    tripSummariesLoading: tripSummariesLoadingSelector(state),
  };
};

const mapDispatchToProps = {
  setSortOption: actions.setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShopHeader = connector(FlightShopHeader);
