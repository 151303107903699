import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, SliceStopCountFilter } from "@hopper-b2b/types";
import { RadioDropdown } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useApiConfigSelector,
  useEnablePricePrediction,
} from "@hopper-b2b/utilities";

import { useCallback } from "react";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";
import { StopsOptionSelectionProps } from "./container";
import "./styles.scss";

interface IStopsOptionSelectionProps extends StopsOptionSelectionProps {
  showDropdownContentOnly?: boolean;
  rerunPricePrediction?: boolean;
  setNonstopFilterSelected?: (nonstopFilterSelected: boolean) => void;
}

interface IStopsOption {
  label: string;
  value: SliceStopCountFilter;
}

export const StopsOptionSelection = (props: IStopsOptionSelectionProps) => {
  const {
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    showDropdownContentOnly,
    setNonstopFilterSelected,
  } = props;

  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);
  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;

  const { t } = useI18nContext();

  const enablePricePrediction = useEnablePricePrediction();

  const handleChange = useCallback(
    (value: string) => {
      const nonStopClicked =
        value === SliceStopCountFilter.NONE ||
        stopsOption === SliceStopCountFilter.NONE;

      if (setNonstopFilterSelected) {
        // Mobile
        nonStopClicked
          ? setNonstopFilterSelected(true)
          : setNonstopFilterSelected(false);
      } else {
        // Desktop
        isHopper &&
          nonStopClicked &&
          enablePricePrediction &&
          setRerunPrediction();
      }
      setStopsOption(value as SliceStopCountFilter, apiConfig);
    },
    [
      apiConfig,
      enablePricePrediction,
      isHopper,
      setNonstopFilterSelected,
      setRerunPrediction,
      setStopsOption,
      stopsOption,
    ]
  );

  const stopsOptions: IStopsOption[] = [
    {
      label: t("searchFilter.anyStop"),
      value: SliceStopCountFilter.ANY_NUMBER,
    },
    {
      label: t("searchFilter.noStops"),
      value: SliceStopCountFilter.NONE,
    },
    {
      label: t("searchFilter.oneOrFewerStops"),
      value: SliceStopCountFilter.ONE_OR_LESS,
    },
    {
      label: t("searchFilter.twoOrFewerStops"),
      value: SliceStopCountFilter.TWO_OR_LESS,
    },
  ];

  return (
    <RadioDropdown
      dropdownLabel={t("searchFilter.stops")}
      options={stopsOptions}
      setOption={handleChange}
      selected={stopsOption}
      showDropdownContentOnly={showDropdownContentOnly}
      buttonClassName="b2b-shop-filter"
      groupAriaLabel={t("searchFilter.filterStops")}
      buttonAriaLabel={t("searchFilter.changeFilterStops")}
      popoverClassName="b2b"
    />
  );
};
