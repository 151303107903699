import { Actions } from "./actions";
import { Guards } from "./guards";
import { Services } from "./services";
import { flightStateMachine } from "./stateMachine";
import { FlightCheckoutComponent } from "./components";
import { getInitialFlightMachineContext } from "./initialContext";
import {
  populateCheckoutQueryParams,
  transitionStateOnPathnameChange,
} from "./utilities";
import { CheckoutProps } from "@hopper-b2b/types";

const FLIGHT_CHECKOUT_TITLE = "Complete Flight Booking";
const PORTAL_TITLE = "Nubank | Hopper";

const onFlightCheckoutLoad = () => {
  document.title = FLIGHT_CHECKOUT_TITLE;
};

const onFlightCheckoutOnCleanUp = () => {
  document.title = PORTAL_TITLE;
};

export const flightCheckoutProps: CheckoutProps = {
  stateMachine: flightStateMachine,
  Component: FlightCheckoutComponent,
  actions: Actions,
  context: getInitialFlightMachineContext(),
  guards: Guards,
  services: Services,
  onCleanUp: onFlightCheckoutOnCleanUp,
  onLoad: onFlightCheckoutLoad,
  getInitialContext: getInitialFlightMachineContext,
  onStateValueChange: populateCheckoutQueryParams,
  onPathnameChange: transitionStateOnPathnameChange,
  validateContext: () => true,
};
