import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ButtonWrap,
  IconComponent,
  MobilePopoverCard,
  ActionLink,
  IconName,
  MobileFloatingSection,
} from "@hopper-b2b/ui";
import "./styles.scss";

export interface SelectWithHalfSheetProps {
  label: string;
  selected: string | null;
  className: string;
  modalContent: JSX.Element;
  chevronSrc?: string;
  ctaLabel?: string | JSX.Element;
  error?: string;
  required?: boolean;
}

const SelectWithHalfSheet = ({
  label,
  selected,
  className,
  modalContent,
  chevronSrc,
  ctaLabel,
  error,
  required,
}: SelectWithHalfSheetProps) => {
  const { t } = useI18nContext();
  const [openHalfSheet, setOpenHalfSheet] = useState(false);

  const handleOpenHalfSheet = useCallback(
    (e) => {
      // Prevent form submit for ButtonWrap component
      e.preventDefault();
      setOpenHalfSheet(true);
    },
    [setOpenHalfSheet]
  );
  const handleCloseHalfSheet = useCallback(
    () => setOpenHalfSheet(false),
    [setOpenHalfSheet]
  );

  const selectedLabel = useMemo(() => {
    if (selected) {
      return selected;
    } else {
      return t("select");
    }
  }, [selected, t]);

  return (
    <div className="select-with-half-sheet-button-container">
      <ButtonWrap
        onClick={handleOpenHalfSheet}
        className={clsx("select-with-half-sheet-button", { error }, className)}
      >
        <p className="select-with-half-sheet-label">
          {label}
          {required ? <span className="asterix">*</span> : null}
        </p>
        <div className="select-with-half-sheet-select-row">
          <p
            className={clsx("select-with-half-sheet-button-value", {
              placeholder: !selected,
            })}
          >
            {selectedLabel}
          </p>
          {chevronSrc ? (
            <img className="down-arrow" alt="" src={chevronSrc} />
          ) : (
            <IconComponent name={IconName.ChevronDown} className="down-arrow" />
          )}
        </div>
      </ButtonWrap>
      <div className="select-with-half-sheet-error">{error}</div>

      <MobilePopoverCard
        open={openHalfSheet}
        className={clsx(
          "select-with-half-sheet-modal",
          `${className}-modal`,
          className
        )}
        contentClassName="select-with-half-sheet-modal-content"
        onClose={handleCloseHalfSheet}
        centered={true}
        topRightButton={
          <ActionLink
            onClick={handleCloseHalfSheet}
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
          />
        }
        bottomButton={
          <MobileFloatingSection
            className="select-with-half-sheet-continue-button"
            primaryButton={{
              children: ctaLabel || t("apply"),
              onClick: handleCloseHalfSheet,
            }}
          />
        }
      >
        {
          <>
            <h2 className="select-with-half-sheet-modal-title">{label}</h2>
            <div className="select-with-half-sheet-modal-description">
              {modalContent}
            </div>
          </>
        }
      </MobilePopoverCard>
    </div>
  );
};

export default SelectWithHalfSheet;
