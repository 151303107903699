import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { applyFilterSaga } from "./applyFilterSaga";
import { fetchDepartureCalendarSaga } from "./fetchDepartureCalendarSaga";
import { fetchDestinationCategories } from "./fetchDestinationCategoriesSaga";
import { fetchOriginCategories } from "./fetchOriginCategoriesSaga";
import { fetchSearchFromUrlSaga } from "./fetchSearchFromUrl";
import { populateSearchUrlParams } from "./populateSearchUrlParams";

export function* watchFetchOriginCategories() {
  yield takeLatest(actionTypes.FETCH_ORIGIN_CATEGORIES, fetchOriginCategories);
}

export function* watchFetchDestinationCategories() {
  yield takeLatest(
    actionTypes.FETCH_DESTINATION_CATEGORIES,
    fetchDestinationCategories
  );
}

export function* watchFetchSearchFromUrl() {
  yield takeLatest(actionTypes.FETCH_SEARCH_FROM_URL, fetchSearchFromUrlSaga);
}

export function* watchPopulateSearchUrlParams() {
  yield takeLatest(
    actionTypes.POPULATE_SEARCH_URL_PARAMS,
    populateSearchUrlParams
  );
}

export function* watchFetchDepartureCalendar() {
  yield takeLatest(
    actionTypes.FETCH_DEPARTURE_CALENDAR,
    fetchDepartureCalendarSaga
  );
}
