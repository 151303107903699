import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import {
  Flights,
  OffersResponse,
  TrackingProperties,
} from "@b2bportal/air-shopping-api";
import { PriceFreezeOfferData } from "@b2bportal/price-freeze-api";
import {
  CallState,
  FlightShopDefaultSort,
  FlightShopType,
  GetSimilarFlightsResponse,
  IFlightGridFares,
  IMonthBucket,
  ISelectedTrip,
  OfferWithSuggested,
  PassengerPricing,
  Prediction,
  TransferResponse,
  TripDetails,
  TripSummary,
} from "@hopper-b2b/types";

export enum FlightShopStep {
  PricePrediction,
  ChooseDeparture,
  ChooseReturn,
  ReviewItinerary,
  FintechSelection,
  ScheduleChange,
  MissedConnection,
  CfarOffers,
  ChfarOffer,
  FareDetails,
  ExchangeLoadingContext,
  ExchangeGatekeeper,
  ExchangeLanding,
  ExchangeLocationsPicker,
  ExchangeDatesPicker,
  ExchangeSlicePicker,
  ExchangeSearching,
  ExchangePriceBreakdown,
  ExchangeConfirmReq,
  ExchangeSubmitting,
  ExchangeSubmitted,
  ExchangeReqFailure,
  ExchangeError,
  WalletOffers,
}

export type SortOption = Partial<
  | "price"
  | "fareScore"
  | "stops"
  | "departureTime"
  | "arrivalTime"
  | "duration"
  | "stopAndDepartureTime"
>;
export interface ITripSummariesById {
  [key: string]: TripSummary;
}

export interface ITripDetailsByTripId {
  [key: string]: TripDetails;
}

export type TripSummaryId = string;

export interface ITripPricing {
  fare?: PassengerPricing[];
}

export interface IFlightGridFareMap {
  [sliceId: string]: IFlightGridByOutgoingRating;
}

export interface IFlightGridByOutgoingRating {
  [outgoingRating: number]: IFlightGridFares;
}

export interface IReturnSlicesByOutgoingId {
  [outgoingSliceId: string]: ITripIdsByReturnSlice;
}

export interface ITripIdsByReturnSlice {
  [returnSliceId: string]: TripSummaryId;
}

export interface IWatchState {
  watches: WatchAlertView[];
  createWatchCallState: CallState;
  updateWatchCallState: CallState;
  listWatchCallState: CallState;
  deleteWatchCallState: CallState;
}

export interface TravelWalletOfferState {
  percentage?: number;
}

export type TopFlightsIds = {
  name: "recommend" | "bestValue" | "fastest";
  id: string;
};

export interface IFlightShopState extends IWatchState {
  tripSummariesById: ITripSummariesById;
  flightGridFares: { [sliceId: string]: IFlightGridFares } | null;
  returnFlightsByOutgoingId: IReturnSlicesByOutgoingId;
  selectedTrip: ISelectedTrip;
  sortedTripIds: [];
  tripSummariesError: boolean;
  tripSummariesLoading: boolean | null;
  predictionError: boolean;
  tripDetailsLoading: boolean | null;
  predictionLoading: boolean | null;
  tripDetailsById: ITripDetailsByTripId;
  progress: FlightShopStep;
  selectedTripPriceFreezeOffer: PriceFreezeOfferData | null;
  selectedTripPriceFreezeOfferResponse: any | null; // Update type
  fetchSelectedTripPriceFreezeOfferCallState: CallState;
  sortOption: SortOption;
  prediction: Prediction | null;
  rerunPrediction: boolean;
  calendarBuckets: IMonthBucket[];
  priceBuckets: string[];
  openCalendarModal: boolean;
  openLocationModal: boolean;
  priceFreezeOffer: OfferWithSuggested | null;
  flights: Flights | null;
  flightShopType: FlightShopType;
  // note: trips (of type TripSummary[]) is stored separately through the SET_TRIP_SUMMARIES event
  similarFlightsResponse: Omit<GetSimilarFlightsResponse, "trips"> | null;
  fetchSimilarFlightsCallState: CallState;
  transferToSimilarFlightsResponse: TransferResponse | null;
  fetchTransferToSimilarFlightsCallState: CallState;
  declineAllFintechOptions: boolean;
  chfarId?: string;
  renderLoadingSteps?: boolean;
  offers: OffersResponse | null;
  defaultSort: FlightShopDefaultSort;
  trackingProperties?: TrackingProperties;
  topFlightsIds: TopFlightsIds[];
}
