import { PropsWithChildren, useEffect } from "react";
import { useHistory } from "react-router";
import { DefaultContext } from "xstate";

import { useCheckoutState } from "@checkout/context";
import { Event } from "@checkout/events";
import {
  ResumeStep,
  parseCheckoutStateQueryString,
  runResumeSteps,
} from "@checkout/states/common/ResumeCheckout";
import { ParentState } from "@checkout/index";

interface ResumeCheckoutProps extends PropsWithChildren {
  steps: ResumeStep[];
  validateContext: (ctx: DefaultContext) => boolean;
}

export const ResumeCheckoutComponent = ({
  steps,
  children,
  validateContext,
}: ResumeCheckoutProps) => {
  const [state, send] = useCheckoutState();
  const history = useHistory();

  useEffect(() => {
    if (!validateContext(state.context)) {
      // Check if the state.value is one which checkout should not be refreshed at (bookingConfirmation and cartFulfill)
      const { checkoutState } = parseCheckoutStateQueryString(history);
      if (
        checkoutState &&
        (checkoutState === ParentState.cartFulfill ||
          checkoutState === ParentState.bookingConfirmation)
      ) {
        history.replace("/");
      } else {
        runResumeSteps({
          steps,
          history,
          context: state.context,
          send,
        });
      }
    } else {
      send(Event.NEXT);
    }
  }, [history]);

  return children;
};
