import { AirRestrictionStatus } from "@hopper-b2b/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Typography } from "@material-ui/core";
import { IconComponent, IconContentView, IconName } from "@hopper-b2b/ui";
import { ReactComponent as DollarSign } from "../../../../assets/client/dollar-sign.svg";
import clsx from "clsx";
import styles from "./styles.module.scss";

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
}

const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className="icon-available"
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <DollarSign
      className="icon-paid"
      name={IconName.MoneyOutlineTransparentIcon}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <IconComponent
      className="icon-unavailable"
      name={IconName.XCircleFilled}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.GENERIC]: (
    <IconComponent
      className="icon-generic"
      name={IconName.Generic}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
};
const Restriction = (props: IRestrictionProps) => {
  const { symbol, description } = props;
  return (
    <IconContentView
      className={clsx("fare-details-restriction", styles.restrictionIcon)}
      icon={restrictionIcon[symbol]}
      content={<Typography variant="body2">{description}</Typography>}
    />
  );
};

export default Restriction;
