import { useState } from "react";
import { useHistory } from "react-router";

import { CallState, CHFAR_ID_QUERY_PARAM } from "@hopper-b2b/types";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BLoadingPopup, B2BSpinner, WorkingBunnies } from "@hopper-b2b/ui";
import {
  AirChfarExerciseDiscountSelectors,
  CartQuoteErrorModal,
  CartQuoteEventType,
  cartQuoteSelectors,
  CartQuoteState,
  DesktopLayout,
  FlightPassengerEventTypesV2,
  getChildState,
  useCheckoutSend,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import {
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { FlightError } from "@b2bportal/air-booking-api";

import { PATH_FLIGHTS_SEARCH } from "../../../../utils/urlPaths";
import { ErrorScreen } from "../../../../components/ErrorScreen";
import { ConnectedPriceBreakdownContent } from "../PriceBreakdownDropdown";
import { LoadingScreen } from "../../../../components/Slots";
import { FlightMachineContext } from "../../types";
import { getClientAssets } from "../../selectors";
import { TEvent } from "../../events";
import "./styles.scss";

export const CartQuoteComponent = (props) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const clientAssets = useSelector(getClientAssets);
  const [state] = useCheckoutState<TEvent, FlightMachineContext>();
  const childState = getChildState(state.value);
  const openCartQuoteModal = useSelector(
    cartQuoteSelectors.getOpenCartQuoteModal
  );

  const [resetLoader, setResetLoader] = useState<boolean>(false);

  const getMessage = (): string => {
    switch (childState) {
      // Schedule happens so fast, the text blinks in and gets replaced by polling
      // case CartQuoteState.schedule:
      //   return t("passengerInformation.validatingPassengers");
      case CartQuoteState.schedule:
      case CartQuoteState.polling:
        return t("savingTraveler");
      default:
        return "";
    }
  };

  const loadingSteps = [
    {
      title: t("pollQuoteLoading.step1Title"),
    },
    {
      title: t("pollQuoteLoading.step2Title"),
    },
    {
      title: t("pollQuoteLoading.step3Title"),
    },
  ];

  const callState = useSelector(cartQuoteSelectors.getCartQuoteCallState);
  const errorState = useSelector(cartQuoteSelectors.getOpenCartQuoteErrorModal);

  if (errorState) {
    return <CartErrorScreen setResetLoader={setResetLoader} />;
  }

  return matchesMobile ? (
    <LoadingScreen
      loading={callState === CallState.InProcess}
      averageLoadingTime={getEnvVariables("pollQuoteLoadingTime") as number}
      className="cart-quote-loading-popup"
      loadingSteps={loadingSteps}
    />
  ) : (
    <>
      <DesktopLayout
        clientLogo={clientAssets?.logo}
        rightContent={<ConnectedPriceBreakdownContent />}
      >
        <B2BSpinner />
      </DesktopLayout>
      <B2BLoadingPopup
        open={openCartQuoteModal}
        message={getMessage()}
        popupSize={"desktop"}
        loadingIcon={<WorkingBunnies />}
        className="schedule-quote-loading"
      />
    </>
  );
};

export const CartErrorScreen = ({ setResetLoader }) => {
  return <MappedErrorScreen setResetLoader={setResetLoader} />;
};

export const MappedErrorScreen = (props) => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();
  const cartError = useSelector(cartQuoteSelectors.getCartQuoteError);
  const allowedQuoteRetry = useSelector(cartQuoteSelectors.getAllowQuoteRetry);
  const chfarId = useSelector(AirChfarExerciseDiscountSelectors.getChfarId);
  const { setResetLoader } = props;

  const clearError = () => send(CartQuoteEventType.CLEAR_CART_QUOTE_ERROR);
  const searchAgain = () => {
    clearError();
    //ChFAR exercise
    if (chfarId)
      history.push(
        `/${PATH_FLIGHTS_SEARCH}?${CHFAR_ID_QUERY_PARAM}=${chfarId}`
      );
    else history.push("/" + PATH_FLIGHTS_SEARCH);
  };
  const updateTravelers = () => {
    send(CartQuoteEventType.OPEN_PASSENGER_PICKER);
    clearError();
  };
  const retryQuote = () => {
    setResetLoader(true);
    send(CartQuoteEventType.RETRY_QUOTE);
  };

  const openPassportForm = () =>
    send(FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM);

  const DEFAULT_ERROR_MODEL_PROPS = {
    title: t("checkoutError.genericErrorTitle"),
    subtitle: t("checkoutError.genericErrorSubtitle"),
    ...(allowedQuoteRetry
      ? {
          primaryButtonText: t("checkoutError.tryAgain"),
          primaryOnClick: retryQuote,
          secondaryButtonText: t("checkoutError.findNewFlight"),
          secondaryOnClick: searchAgain,
        }
      : {
          primaryButtonText: t("checkoutError.findNewFlight"),
          primaryOnClick: searchAgain,
        }),
  };

  const getFlightError = (flightError: FlightError) => {
    switch (flightError) {
      case FlightError.DuplicatedPassport:
        return {
          title: t("checkoutError.duplicatedPassportTitle"),
          subtitle: t("checkoutError.duplicatedPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.DuplicatedNationalId:
        return {
          title: t("checkoutError.duplicatedNationalIdTitle"),
          subtitle: t("checkoutError.duplicatedNationalIdSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      // ! CPF numbers do not expire so the following is commented out
      // case FlightError.ExpiredNationalId:
      //   return {
      //     title: t("checkoutError.expiredNationalIdTitle"),
      //     subtitle: t("checkoutError.expiredNationalIdSubtitle"),
      //     primaryButtonText: t("checkoutError.updateTraveler"),
      //     primaryOnClick: updateTravelers,
      //   };
      // case FlightError.ExpiringNationalId:
      //   return {
      //     title: t("checkoutError.expiringNationalIdTitle"),
      //     subtitle: t("checkoutError.expiringNationalIdSubtitle"),
      //     primaryButtonText: t("checkoutError.updateTraveler"),
      //     primaryOnClick: updateTravelers,
      //   };
      case FlightError.CheckInMinimumAgeNotMet:
        return {
          title: t("checkoutError.checkInMinimumAgeNotMetTitle"),
          subtitle: t("checkoutError.checkInMinimumAgeNotMetSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.ExpiringPassport:
        return {
          title: t("checkoutError.expiringPassportTitle"),
          subtitle: t("checkoutError.expiringPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.ExpiredPassport:
        return {
          title: t("checkoutError.expiredPassportTitle"),
          subtitle: t("checkoutError.expiredPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.IllegalLapInfantKTN:
        return {
          title: t("checkoutError.illegalLapInfantKTNTitle"),
          subtitle: t("checkoutError.illegalLapInfantKTNSubtitle"), // todo: we don't have the pax name
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.IllegalLapInfantRedress:
        return {
          title: t("checkoutError.illegalLapInfantRedressTitle"),
          subtitle: t("checkoutError.illegalLapInfantRedressSubtitle"), // todo: we don't have the pax name
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.InvalidCustomer:
        return {
          title: t("checkoutError.invalidCustomerTitle"),
          subtitle: t("checkoutError.invalidCustomerSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.InvalidPassengerName:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.invalidPassengerName"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.InvalidPassengers:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.invalidPassengerSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.InvalidPassportNumber:
        return {
          title: t("checkoutError.invalidPassportNumberTitle"),
          subtitle: t("checkoutError.invalidPassportNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassportForm,
        };
      case FlightError.LapInfantTooOld:
        return {
          title: t("checkoutError.lapInfantTooOldTitle"),
          subtitle: t("checkoutError.lapInfantTooOldSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.LapInfantsUnsupported:
        return {
          title: t("checkoutError.lapInfantsUnsupportedTitle"),
          subtitle: t("checkoutError.lapInfantsUnsupportedSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.MalformedKnownTravelerNumber:
        return {
          title: t("checkoutError.malformedKnownTravelerNumberTitle"),
          subtitle: t("checkoutError.malformedKnownTravelerNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.MalformedRedressNumber:
        return {
          title: t("checkoutError.malformedRedressNumberTitle"),
          subtitle: t("checkoutError.malformedRedressNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.MissingAirlineLocator:
        return {
          title: t("checkoutError.missingAirlineLocatorTitle"),
          subtitle: t("checkoutError.missingAirlineLocatorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case FlightError.MissingNationality:
        return {
          title: t("checkoutError.missingNationalityTitle"),
          subtitle: t("checkoutError.missingNationalitySubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.MissingPassport:
        return {
          title: t("checkoutError.missingPassportTitle"),
          subtitle: t("checkoutError.missingPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassportForm,
        };
      case FlightError.MissingPassportNumber:
        return {
          title: t("checkoutError.missingPassportNumberTitle"),
          subtitle: t("checkoutError.missingPassportNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassportForm,
        };
      case FlightError.NoAvailability:
        return {
          title: t("checkoutError.noAvailabilityTitle"),
          subtitle: t("checkoutError.noAvailabilitySubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case FlightError.NoAdultPassenger:
        return {
          title: t("checkoutError.noAdultPassengerTitle"),
          subtitle: t("checkoutError.noAdultPassengerSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.NoContactInformation:
        return {
          title: t("checkoutError.noContactInformationTitle"),
          subtitle: t("checkoutError.noContactInformationSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"), // todo: update contact info
          primaryOnClick: updateTravelers,
        };
      case FlightError.NoTicketlessResponse:
        return {
          title: t("checkoutError.noTicketlessResponseTitle"),
          subtitle: t("checkoutError.noTicketlessResponseSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case FlightError.PassengerNameTooShort:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.passengerNameTooShortSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.ProviderError:
        return {
          title: t("checkoutError.providerErrorTitle"),
          subtitle: t("checkoutError.providerErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case FlightError.SeatedInfantsUnsupported:
        return {
          title: t("checkoutError.seatedInfantsUnsupportedTitle"),
          subtitle: t("checkoutError.seatedInfantsUnsupportedSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.TooManyLapInfants:
        return {
          title: t("checkoutError.tooManyLapInfantsTitle"),
          subtitle: t("checkoutError.tooManyLapInfantsSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.TooManyPassengers:
        return {
          title: t("checkoutError.tooManyPassengersTitle"),
          subtitle: t("checkoutError.tooManyPassengersSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.TransientServiceError:
        return {
          title: t("checkoutError.transientServiceErrorTitle"),
          subtitle: t("checkoutError.transientServiceErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case FlightError.UnknownSabreAppError:
        return {
          title: t("checkoutError.unknownSabreAppErrorTitle"),
          subtitle: t("checkoutError.unknownSabreAppErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case FlightError.UnsupportedNameCharacters:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.unsupportedNameCharactersSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
        };
      case FlightError.UpgradeRequired:
        return {
          title: t("checkoutError.upgradeRequiredTitle"),
          subtitle: t("checkoutError.unknownSabreAppErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      default:
        // default cover FlightErrors that should not occur in PriceQuote
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getProductError = (error: ProductError) => {
    switch (error.value.type) {
      case Product.Flight:
      case Product.MultiProviderFlight:
      case Product.Seats: {
        // https://github.com/hopper-org/b2b-interfaces/blob/8316205c08da3b4405647ff5245f3bdc76f0ef73/purchase/src/main/scala/com/hopper/b2b/purchase/Models.scala#L114
        const flightError = error.value.value as FlightError;
        return getFlightError(flightError);
      }
      default:
        // covers:
        // - Product.Ground
        // - Product.Hotel
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getPaymentError = (error: PaymentError) => {
    return DEFAULT_ERROR_MODEL_PROPS;
  };

  const getErrorModalProps = (
    type?: CartQuoteErrorModal,
    data?: PurchaseError[]
  ) => {
    switch (type) {
      case CartQuoteErrorModal.FailedPolling: {
        const head = data?.[0]; // todo: we probably want to have some sort of priority order on errors?
        switch (head?.Error) {
          case PurchaseErrorEnum.NoAvailability:
            // todo: No Availability should only be a product error
            return {
              title: t("checkoutError.noAvailabilityTitle"),
              subtitle: t("checkoutError.noAvailabilitySubtitle"),
              primaryButtonText: t("checkoutError.searchAgain"),
              primaryOnClick: searchAgain,
            };
          case PurchaseErrorEnum.PaymentError:
            return getPaymentError(head as PaymentError);
          case PurchaseErrorEnum.ProductError: // todo. Missing `Error` attribute
            return getProductError(head as ProductError);
          default:
            // default case covers
            // - PurchaseErrorEnum.AuthError
            // - PurchaseErrorEnum.ExpiredFulfillToken
            // - PurchaseErrorEnum.ExpiredQuoteToken
            // - PurchaseErrorEnum.FraudAutoReject
            // - PurchaseErrorEnum.InActivity
            // - PurchaseErrorEnum.InvalidSession
            // - PurchaseErrorEnum.NoPaymentsProvided
            // - PurchaseErrorEnum.NonDistinctPayments
            // - PurchaseErrorEnum.ErrorCode (because this isn't standardized enough yet)
            return DEFAULT_ERROR_MODEL_PROPS;
        }
      }
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };
  const errorModalProps = getErrorModalProps(
    cartError?.type,
    cartError?.data as PurchaseError[]
  );

  return <ErrorScreen {...errorModalProps} onBack={updateTravelers} />;
};
