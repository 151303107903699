import { useEffect } from "react";

export interface ViewportType {
  width: number;
  height: number;
}

const getViewport = () => ({
  width: Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  ),
  height: Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  ),
});

const getVisualViewport = () => {
  // For the legacy browsers that don't support visual viewport
  if (!window.visualViewport) {
    return { width: null, height: null };
  }
  return {
    width: window.visualViewport.width,
    height: window.visualViewport.height,
  };
};

const getViewportDifference = (
  skip?: boolean
): ViewportType | Promise<ViewportType> => {
  const visualViewport = getVisualViewport();
  const viewport = getViewport();
  const difference = {
    width: viewport.width - (visualViewport.width ?? viewport.width),
    height: viewport.height - (visualViewport.height ?? viewport.height),
  };
  if (!skip && difference.height === 0 && difference.width === 0) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(getViewportDifference(true)), 50)
    );
  }
  return difference;
};

const useVisualViewport = (onChange: (difference: ViewportType) => void) => {
  useEffect(() => {
    const handleResize = async () => {
      const difference = await getViewportDifference();
      onChange(difference);
    };

    // Hack to handle scrollbar appearing at the time of page load since scrollbar changes the window size
    // but does not trigger a resize event.
    setTimeout(() => handleResize(), 500);

    // For the legacy browsers that don't support visual viewport
    if (!window.visualViewport) {
      return;
    }

    window.visualViewport.addEventListener("scroll", handleResize);
    window.visualViewport.addEventListener("resize", handleResize);
    return () => {
      if (!window.visualViewport) {
        return;
      }
      window.visualViewport.removeEventListener("scroll", handleResize);
      window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);
};

export { useVisualViewport, getViewportDifference };
