import {
  getDateFormat,
  getPreferMonthDayYearFormat,
  I18nMarkup,
  useI18nContext,
  useI18nCountry,
} from "@hopper-b2b/i18n";
import {
  ClientName,
  INationality,
  LocalDate,
  PersonId,
  RadioOption,
  UserInfo,
} from "@hopper-b2b/types";
import {
  ActionButton,
  B2BNumberTextField,
  B2BTextField,
  MobileFloatingSection,
  Slot,
} from "@hopper-b2b/ui";
import { Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import emojiFlags from "emoji-flags";

import { Assistance, Gender, Person } from "@b2bportal/air-booking-api";
import {
  generateUserId,
  getDateTimeWithFormat,
  getEnvVariables,
  getGender,
  getNationalIdInfo,
  getViewportDifference,
  nameRegex,
  parseDayMonthAndYear,
  parseMonthDayAndYear,
  specialCharacterRegex,
} from "@hopper-b2b/utilities";
import { isEmpty } from "lodash-es";
import {
  AdditionalInformationSection,
  FrequentFlyerSection,
  HotelLoyaltySection,
} from "./components";
import { DriverLicenseSection } from "./components/DriverLicenseSection";
import "./styles.scss";
import {
  genderOptions,
  getFrequentFlyerList,
  getFrequentFlyerMap,
  getHotelLoyaltyList,
  getHotelLoyaltyMap,
  IFrequentFlyerList,
  IHotelLoyaltyList,
  renderMenu,
} from "./utils";

export interface ITravelerInfoFormProps {
  className?: string;
  travelerInfoTitle: string;
  travelerInfoSubtitle: string;
  frequentFlyerTitle: string;
  additionalInfoTitle: string;
  passportTitle?: string;
  passportSubtitle?: string;
  traveler?: Person;
  handleUpdatePassenger: (
    traveler: Person,
    onUpdate?: boolean,
    singleTravelerWorkflow?: boolean
  ) => void;
  singleTravelerWorkflow?: boolean;
  handleRemovePassenger: (travelerId: PersonId) => void;
  isMobile?: boolean;
  closeButton?: JSX.Element;
  prefillPassenger?: UserInfo;
  showPassport?: boolean;
  hideFrequentFlyerSection?: boolean;
  hideHotelLoyaltySection?: boolean;
  hideNationalityField?: boolean;
  hideAdditionalInfoSection?: boolean;
  hideGenderField?: boolean;
  showDriverLicenseSection?: boolean;
  buttonClassName?: string;
  hideHeader?: boolean;
  isJetBlueTenant: boolean;
  minBirthdate?: Date;
  handleClose?: () => void;
  ctaEnabledByDefault?: boolean;
  nationalityRequired?: boolean;
}

export const TravelerInfoForm = ({
  className,
  travelerInfoTitle,
  travelerInfoSubtitle,
  frequentFlyerTitle,
  additionalInfoTitle,
  passportTitle,
  passportSubtitle,
  traveler,
  handleUpdatePassenger,
  singleTravelerWorkflow,
  handleRemovePassenger,
  isMobile,
  closeButton,
  prefillPassenger,
  showPassport = false,
  hideFrequentFlyerSection = false,
  hideHotelLoyaltySection = true,
  hideNationalityField = false,
  hideGenderField = false,
  hideAdditionalInfoSection = false,
  showDriverLicenseSection,
  buttonClassName,
  hideHeader = false,
  isJetBlueTenant,
  handleClose,
  minBirthdate,
  ctaEnabledByDefault = false,
  nationalityRequired = false,
}: ITravelerInfoFormProps) => {
  const { t, brand } = useI18nContext();
  const { getAllCountryNames, getCountryName } = useI18nCountry();

  const preferedDateFormat = getDateFormat();
  const preferMonthDayYearFormat = getPreferMonthDayYearFormat();
  const [saveClicked, setSaveClicked] = useState(false);

  // Traveler Info
  const [dirtyFields, setDirtyFields] = useState<string[]>([]);
  const [firstName, setFirstName] = useState(
    traveler?.givenName || prefillPassenger?.firstName || ""
  );
  const [middleName, setMiddleName] = useState(traveler?.middleName ?? "");
  const [lastName, setLastName] = useState(
    traveler?.surname || prefillPassenger?.lastName || ""
  );
  const [birthdate, setBirthdate] = useState<LocalDate>(
    traveler && traveler?.dateOfBirth
      ? dayjs(traveler.dateOfBirth).format(preferedDateFormat)
      : ""
  );

  const [gender, setGender] = useState<Gender | null>(traveler?.gender ?? null);
  const [nationality, setNationality] = useState<INationality | undefined>(
    traveler?.createdAt
      ? traveler?.nationality
      : { country: brand.preferredCountryCode }
  );

  const nationalIdInfo = useMemo(
    () => getNationalIdInfo(nationality?.country),
    [nationality]
  );

  const [nationalId, setNationalId] = useState(
    traveler?.nationalId
      ? {
          number: traveler?.nationalId?.number
            ? nationalIdInfo
              ? nationalIdInfo?.format(traveler?.nationalId?.number)
              : traveler?.nationalId?.number
            : "",
          ...(traveler?.nationalId?.expiration
            ? { expiration: traveler?.nationalId?.expiration }
            : null),
        }
      : null
  );

  // Frequent Flyer Details
  const [frequentFlyerList, setFrequentFlyerList] =
    useState<IFrequentFlyerList>(
      traveler?.frequentFlyer
        ? getFrequentFlyerList(traveler.frequentFlyer)
        : []
    );

  const [hotelLoyaltyList, setHotelLoyaltyList] = useState<IHotelLoyaltyList>(
    traveler?.hotelLoyalty ? getHotelLoyaltyList(traveler.hotelLoyalty) : []
  );

  // Additional Info
  const [assistance, setAssistance] = useState<Assistance[]>(
    traveler?.assistance ?? []
  );
  const [redressNumber, setRedressNumber] = useState(traveler?.redressNumber);
  const [knownTravelerNumber, setKnownTravelerNumber] = useState(
    traveler?.knownTravelerNumber
  );

  // Drivers licence
  const [driverLicense, setLicense] = useState({
    cityCode: traveler?.driverLicense?.cityCode ?? "",
    street: traveler?.driverLicense?.street ?? "",
    country:
      traveler?.driverLicense?.country ??
      traveler?.nationality?.country ??
      "US",
    postCode: traveler?.driverLicense?.postCode ?? "",
  });

  // Passport
  const [passport, setPassport] = useState({
    countryOfIssue: traveler?.passport?.countryOfIssue || "",
    number: traveler?.passport?.number || "",
    expiration: traveler?.passport?.expiration
      ? dayjs(traveler.passport.expiration).format(preferedDateFormat)
      : "",
  });

  const isMissingDriverLicense =
    isEmpty(driverLicense.cityCode) ||
    isEmpty(driverLicense.postCode) ||
    isEmpty(driverLicense.country) ||
    isEmpty(driverLicense.street);

  const isNameInvalid = (name?: string) => name && !nameRegex.test(name);
  const isNameLongEnough = (name?: string) => name && name.length <= 1;

  const genderError = useMemo(() => {
    if (saveClicked && !gender) {
      return t("genderRequiredError");
    }
  }, [gender, t, saveClicked]);

  const nationalityError = useMemo(() => {
    if (nationalityRequired && saveClicked && !nationality) {
      return t("nationalityRequiredError");
    }
  }, [nationality, nationalityRequired, t, saveClicked]);

  const firstNameError = useMemo(() => {
    if (
      (!firstName || firstName === "") &&
      (saveClicked || dirtyFields.includes("firstName"))
    ) {
      return t("firstNameRequiredError");
    } else if (isNameLongEnough(firstName)) {
      return t("firstNameMinCharacterError");
    } else if (isNameInvalid(firstName)) {
      return t("noSpecialCharactersError");
    }
  }, [firstName, t, dirtyFields, saveClicked]);

  const lastNameError = useMemo(() => {
    if (
      (!lastName || lastName === "") &&
      (saveClicked || dirtyFields.includes("lastName"))
    ) {
      return t("lastNameRequiredError");
    } else if (isNameLongEnough(lastName)) {
      return t("lastNameMinCharacterError");
    } else if (isNameInvalid(lastName)) {
      return t("noSpecialCharactersError");
    }
  }, [lastName, t, dirtyFields, saveClicked]);

  const middleNameError = useMemo(
    () => isNameInvalid(middleName),
    [middleName]
  );

  const birthdateError = useMemo(() => {
    if (birthdate) {
      const birthdateDayjs = getDateTimeWithFormat(
        birthdate,
        preferedDateFormat
      );

      const { month, day } = preferMonthDayYearFormat
        ? parseMonthDayAndYear(birthdate)
        : parseDayMonthAndYear(birthdate);

      if (
        !birthdateDayjs.isValid() ||
        birthdateDayjs.isAfter(dayjs()) ||
        (minBirthdate && birthdateDayjs.isBefore(minBirthdate))
      ) {
        return t("enterAValidBirthDate");
      } else if (
        Number(month) !== birthdateDayjs.month() + 1 ||
        Number(day) !== birthdateDayjs.date()
      ) {
        // This check is necessary because dayjs automatically does modulo logic on input, for example:
        // 13/02/1944 will create a dayjs value of Jan 2nd, 1944
        return t("enterAValidBirthDate");
      }
    } else if (dirtyFields.includes("birthdate") || saveClicked) {
      return t("birthDateRequiredError");
    }
  }, [
    birthdate,
    preferMonthDayYearFormat,
    preferedDateFormat,
    minBirthdate,
    t,
    dirtyFields,
    saveClicked,
  ]);

  const passportExpiryError = useMemo(() => {
    if (passport?.expiration) {
      const expiryDateDayjs = getDateTimeWithFormat(
        passport.expiration,
        preferedDateFormat
      );

      const { month, day } = preferMonthDayYearFormat
        ? parseMonthDayAndYear(passport.expiration)
        : parseDayMonthAndYear(passport.expiration);

      if (!expiryDateDayjs.isValid() || !expiryDateDayjs.isAfter(dayjs())) {
        return t("enterAValidExpiry");
      } else if (
        Number(month) !== expiryDateDayjs.month() + 1 ||
        Number(day) !== expiryDateDayjs.date()
      ) {
        return t("enterAValidExpiry");
      }
    }
  }, [passport, preferMonthDayYearFormat, preferedDateFormat, t]);

  const passportNumberError = useMemo(() => {
    if (passport?.number) {
      if (/\s/.test(passport.number)) {
        return t("noWhitespaceError");
      } else if (!specialCharacterRegex.test(passport.number)) {
        return t("noSpecialCharactersError");
      }
    }
  }, [passport, t]);

  const getNationalIdError = useCallback(
    (nationalIdValue: string) => {
      if (
        nationalIdInfo?.regex &&
        !nationalIdInfo.regex.test(nationalIdValue)
      ) {
        return t("onlyNumbersPermittedError");
      } else if (nationalIdValue.length !== nationalIdInfo?.length?.min) {
        return t("nationalIdLengthError", {
          id: nationalIdInfo?.label,
          length: nationalIdInfo?.length?.formatted,
        });
      } else if (!nationalIdValue) {
        return t("nationalIdRequiredError", { id: nationalIdInfo?.label });
      }
      return "";
    },
    [
      nationalIdInfo?.label,
      nationalIdInfo?.length?.formatted,
      nationalIdInfo?.length?.min,
      nationalIdInfo?.regex,
      t,
    ]
  );

  const nationalIdError = useMemo(() => {
    if (
      !nationalIdInfo ||
      (!nationalId?.number &&
        nationalId?.number !== "" &&
        ((!saveClicked && ctaEnabledByDefault) || !ctaEnabledByDefault))
    )
      return;

    if (nationalId?.number) {
      return getNationalIdError(nationalId?.number);
    } else {
      return t("nationalIdRequiredError", { id: nationalIdInfo?.label });
    }
  }, [
    nationalId,
    t,
    nationalIdInfo,
    getNationalIdError,
    saveClicked,
    ctaEnabledByDefault,
  ]);

  const invalid =
    !(firstName?.length > 1) ||
    !(lastName?.length > 1) ||
    !birthdate ||
    !!birthdateError ||
    !!passportExpiryError ||
    !!passportNumberError ||
    !!firstNameError ||
    !!middleNameError ||
    !!lastNameError ||
    !!nationalIdError ||
    (!gender && !hideGenderField) ||
    showDriverLicenseSection
      ? isMissingDriverLicense
      : false;

  const isSaveDisabled = useMemo(
    () => (ctaEnabledByDefault ? false : invalid),
    [ctaEnabledByDefault, invalid]
  );

  const handleSaveTraveler = useCallback(() => {
    if (!saveClicked) {
      setSaveClicked(true);
    }

    if (isSaveDisabled) {
      return null;
    }

    if (invalid) {
      return null;
    }

    const onUpdate =
      isMobile && getEnvVariables("clientName") === ClientName.UBER;

    passport.expiration = getDateTimeWithFormat(
      passport.expiration,
      preferedDateFormat
    ).format("YYYY-MM-DD");

    const newTraveler: Person = {
      ...traveler,
      givenName: firstName,
      middleName: middleName,
      surname: lastName,
      dateOfBirth: getDateTimeWithFormat(birthdate, preferedDateFormat).format(
        "YYYY-MM-DD"
      ),
      nationality: nationality?.country ? nationality : null,
      passport: passport,
      gender: gender ? gender : Gender.F,
      frequentFlyer: getFrequentFlyerMap(frequentFlyerList),
      hotelLoyalty: getHotelLoyaltyMap(hotelLoyaltyList),
      assistance: assistance,
      id: traveler?.id || generateUserId(),
      createdAt: traveler?.createdAt || dayjs().toISOString(),
      nationalId: !nationalIdInfo
        ? null
        : {
            number: nationalIdInfo?.formatPayload
              ? nationalIdInfo.formatPayload(nationalId?.number)
              : nationalId?.number,
            expiration: nationalId?.expiration,
          },
    };

    if (knownTravelerNumber) {
      newTraveler.knownTravelerNumber = knownTravelerNumber;
    }

    newTraveler.redressNumber =
      redressNumber && redressNumber.length > 0 ? redressNumber : undefined;

    newTraveler.driverLicense = driverLicense.street
      ? driverLicense
      : undefined;

    newTraveler.passport = newTraveler.passport
      ? newTraveler.passport
      : undefined;

    if (onUpdate) {
      handleUpdatePassenger(newTraveler, onUpdate, singleTravelerWorkflow);
    } else {
      handleUpdatePassenger(newTraveler, singleTravelerWorkflow);
    }

    return null;
  }, [
    isSaveDisabled,
    isMobile,
    passport,
    preferedDateFormat,
    traveler,
    firstName,
    middleName,
    lastName,
    birthdate,
    nationality,
    gender,
    frequentFlyerList,
    hotelLoyaltyList,
    assistance,
    nationalIdInfo,
    nationalId?.number,
    nationalId?.expiration,
    knownTravelerNumber,
    redressNumber,
    driverLicense,
    handleUpdatePassenger,
    singleTravelerWorkflow,
  ]);

  const onBirthdateChanged = useCallback(
    (birthdate: string) => {
      const { month, day, year, strLength } = preferMonthDayYearFormat
        ? parseMonthDayAndYear(birthdate)
        : parseDayMonthAndYear(birthdate);

      const formattedBirthdate = preferMonthDayYearFormat
        ? month +
          (strLength > 2 ? "/" : "") +
          day +
          (strLength > 4 ? "/" : "") +
          year
        : day +
          (strLength > 2 ? "/" : "") +
          month +
          (strLength > 4 ? "/" : "") +
          year;

      setBirthdate(formattedBirthdate);
    },
    [preferMonthDayYearFormat]
  );

  const onPassportExpiryChanged = useCallback(
    (passportExpiry: string) => {
      const { month, day, year, strLength } = preferMonthDayYearFormat
        ? parseMonthDayAndYear(passportExpiry)
        : parseDayMonthAndYear(passportExpiry);

      const formattedPassportExpiry = preferMonthDayYearFormat
        ? month +
          (strLength > 2 ? "/" : "") +
          day +
          (strLength > 4 ? "/" : "") +
          year
        : day +
          (strLength > 2 ? "/" : "") +
          month +
          (strLength > 4 ? "/" : "") +
          year;

      setPassport({
        ...passport,
        expiration: formattedPassportExpiry,
      });
    },
    [passport, preferMonthDayYearFormat]
  );

  const genderOptionsWithTranslations = useMemo(
    () =>
      [...genderOptions].map((option) => {
        const gender = getGender(option.value);
        option.label = t("person", { context: gender });
        return option;
      }),
    [t]
  );

  const getGenderTranslations = useCallback(() => {
    return renderMenu(genderOptionsWithTranslations);
  }, [genderOptionsWithTranslations]);

  const onInputFocusHandler = useCallback(
    async (
      id: string,
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      const travelerInfoFormPopupElement = document
        ?.getElementsByClassName("traveler-select-workflow-info-form-popup")[0]
        ?.getElementsByClassName(
          "mobile-popover-card-container"
        )[0].parentElement;
      const inputTop =
        document.getElementById(id)?.getBoundingClientRect().top || 0;
      if (isMobile) {
        if (travelerInfoFormPopupElement) {
          const difference = await getViewportDifference();
          const topWithoutDiff = inputTop - 48;
          const topWithDiff = inputTop - 48 - difference.height;
          // TODO: figure out the values here
          if (difference.height > 0 && inputTop > 100) {
            travelerInfoFormPopupElement.scrollBy({
              top: topWithDiff < 0 ? topWithoutDiff : topWithDiff,
              behavior: "smooth",
            });
          } else {
            event.target.parentElement.parentElement.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }
        } else {
          // The info form is not in a popup, scroll the window element
          window.scrollBy({
            top: inputTop - 48 /* Padding from top of screen (3rem) */,
            behavior: "smooth",
          });
        }
      }
    },
    [isMobile]
  );

  const birthdateLabel = useMemo(
    () => (
      <I18nMarkup
        tKey={"birthDateWithDateFormat"}
        replacements={{
          format: preferedDateFormat.toLowerCase(),
          interpolation: { escapeValue: false },
        }}
      />
    ),
    [preferedDateFormat]
  );

  const passportExpiryLabel = useMemo(
    () =>
      t("expiration", {
        format: preferedDateFormat,
        interpolation: { escapeValue: false },
      }),
    [preferedDateFormat, t]
  );

  const handleNationalIdChange = useCallback(
    (value) => {
      if (!value) {
        getNationalIdError("");
      }
      const formattedValue = nationalIdInfo?.onChange(
        value,
        nationalId?.number?.length - value.length < 0
      );
      setNationalId({
        number: formattedValue,
        expiration: nationalId?.expiration,
      });
    },
    [
      nationalId?.number,
      nationalId?.expiration,
      nationalIdInfo,
      getNationalIdError,
    ]
  );

  const handleFirstNameChange = useCallback(
    (newFirstName: string) => setFirstName(newFirstName),
    [setFirstName]
  );

  const handleMiddleNameChange = useCallback(
    (newMiddleName: string) => setMiddleName(newMiddleName),
    [setMiddleName]
  );

  const handleLastNameChange = useCallback(
    (newLastName: string) => setLastName(newLastName),
    [setLastName]
  );

  const handlePassportChange = useCallback(
    (newPassport: string) => {
      setPassport({
        ...passport,
        number: newPassport,
      });
    },
    [setPassport, passport]
  );

  const handleGenderChange = useCallback(
    (newGender: string) => setGender(newGender as Gender),
    [setGender]
  );

  const handleCountryChange = useCallback(
    (newCountry: string) => {
      setPassport({
        ...passport,
        countryOfIssue: newCountry,
      });
    },
    [setPassport, passport]
  );

  const countryOptions = useMemo(
    () =>
      Array.from(Object.entries(getAllCountryNames())).map(([code, label]) => {
        return {
          label,
          value: code,
          icon: (
            <span className="checkout-country-option-flag-icon">
              {emojiFlags.countryCode(code).emoji}
            </span>
          ),
        };
      }),
    [getAllCountryNames]
  );

  const getNationalityOptions = useCallback(
    (preferredCountry: string) => [
      {
        label: preferredCountry ? getCountryName(preferredCountry) : "",
        value: preferredCountry,
      },
      ...countryOptions.filter((option) => option.value !== preferredCountry),
    ],
    [getCountryName, countryOptions]
  );

  return (
    <div
      className={clsx(
        "traveler-info-form-root",
        { mobile: isMobile },
        className,
        buttonClassName
      )}
    >
      <div
        className={clsx("traveler-info-form-container", {
          edit: traveler?.id,
        })}
      >
        <div
          className={clsx("traveler-info-form-section", "basic-info-section")}
        >
          {!hideHeader && (
            <div className="traveler-info-description">
              <Typography variant="h6">{travelerInfoTitle}</Typography>
              <Typography variant="body2">{travelerInfoSubtitle}</Typography>
            </div>
          )}
          <form className="traveler-info-form">
            <B2BTextField
              aria-label={t("firstName")}
              aria-live="assertive"
              label={t("firstName")}
              className={clsx("traveler-info-field", "first-name", {
                error: firstNameError,
              })}
              required
              value={firstName}
              error={!!firstNameError || (firstName && firstName.length <= 1)}
              helperText={firstNameError}
              inputProps={{ minLength: 2 }}
              onChange={handleFirstNameChange}
              onBlur={() => {
                if (!dirtyFields.includes("firstName")) {
                  setDirtyFields([...dirtyFields, "firstName"]);
                }
              }}
              onFocus={(_value, event) =>
                onInputFocusHandler("first-name-input", event)
              }
              id="first-name-input"
              variant="default"
            />
            <B2BTextField
              aria-label={t("middleNameOptional")}
              aria-live="assertive"
              label={t("middleNameOptional")}
              className={clsx("traveler-info-field", "middle-name", {
                error: middleName && middleNameError,
              })}
              value={middleName}
              error={!!middleName && !!middleNameError}
              helperText={middleNameError && t("noSpecialCharactersError")}
              onChange={handleMiddleNameChange}
              onFocus={(_value, event) =>
                onInputFocusHandler("middle-name-input", event)
              }
              id="middle-name-input"
              variant="default"
            />
            <B2BTextField
              aria-label={t("lastName")}
              aria-live="assertive"
              label={t("lastName")}
              className={clsx("traveler-info-field", "last-name", {
                error: lastNameError,
              })}
              required
              value={lastName}
              error={!!lastNameError || (lastName && lastName.length <= 1)}
              helperText={lastNameError}
              inputProps={{ minLength: 2 }}
              onChange={handleLastNameChange}
              onBlur={() => {
                if (!dirtyFields.includes("lastName")) {
                  setDirtyFields([...dirtyFields, "lastName"]);
                }
              }}
              onFocus={(_value, event) =>
                onInputFocusHandler("last-name-input", event)
              }
              id="last-name-input"
              variant="default"
            />
            <B2BNumberTextField
              aria-live="assertive"
              aria-label={`${t(
                "birthDate"
              )} ${preferedDateFormat.toLowerCase()}`}
              label={birthdateLabel}
              className={clsx("traveler-info-field", "birthdate", {
                error: !!birthdateError,
              })}
              required
              value={birthdate}
              error={!!birthdateError}
              helperText={birthdateError}
              onChange={onBirthdateChanged}
              onBlur={() => {
                if (!dirtyFields.includes("birthdate")) {
                  setDirtyFields([...dirtyFields, "birthdate"]);
                }
              }}
              type="tel"
              inputProps={{
                pattern: "[0-9]*",
              }}
              onFocus={(_value, event) =>
                onInputFocusHandler("birthday-input", event)
              }
              id="birthday-input"
              placeholder={preferedDateFormat}
              variant="default"
            />
            {!hideGenderField && (
              <Slot
                id="add-traveler-form-select"
                options={genderOptionsWithTranslations}
                label={t("gender")}
                placeholder={"Select gender"}
                setOption={(option) => setGender(option as Gender)}
                selected={genderOptionsWithTranslations?.find(
                  (option) => option.value === gender
                )}
                required
                className={clsx("traveler-info-field", "gender")}
                error={genderError}
                helperText={genderError}
                component={
                  <B2BTextField
                    label={t("gender")}
                    aria-label={t("gender")}
                    className={clsx("traveler-info-field", "gender")}
                    required
                    select
                    value={gender}
                    onChange={handleGenderChange}
                    id="gender-input"
                    variant="default"
                  >
                    {getGenderTranslations()}
                  </B2BTextField>
                }
              />
            )}
            {!hideNationalityField && (
              <Slot
                id="add-traveler-form-select"
                options={getNationalityOptions(
                  brand?.preferredCountryCode || "US"
                )}
                label={t("nationality")}
                setOption={(option: RadioOption["value"]) => {
                  setNationality({
                    country: option?.toString() || "",
                  });
                }}
                selected={{
                  value: nationality?.country || "",
                  label: nationality?.country
                    ? getCountryName(nationality?.country)
                    : "",
                }}
                className={clsx("traveler-info-field", "nationality")}
                required={nationalityRequired}
                error={nationalityError}
                component={
                  <Autocomplete
                    fullWidth
                    value={{
                      value: nationality?.country || "",
                      label: nationality?.country
                        ? getCountryName(nationality?.country)
                        : "",
                    }}
                    classes={{ paper: "nationality-autocomplete-results" }}
                    className="nationality-autocomplete"
                    options={getNationalityOptions(
                      brand.preferredCountryCode || "US"
                    )}
                    onChange={(_e, option: any) =>
                      setNationality({
                        country: option?.value.toString() || "",
                      })
                    }
                    getOptionLabel={(option) =>
                      option.value ? getCountryName(option.value) : ""
                    }
                    renderInput={(params) => (
                      <B2BTextField
                        {...params}
                        aria-label={t("nationality")}
                        label={t("nationality")}
                        className={clsx("traveler-info-field", "nationality")}
                        id="nationality-input"
                        variant="default"
                      />
                    )}
                  />
                }
              />
            )}
            {nationalIdInfo ? (
              <B2BTextField
                aria-label={nationalIdInfo.label}
                aria-live="assertive"
                label={nationalIdInfo.label}
                className={clsx("traveler-info-field", "national-id", {
                  error: nationalIdError,
                })}
                required
                value={nationalId?.number}
                error={!!nationalIdError || nationalId?.number?.length <= 1}
                helperText={nationalIdError}
                inputProps={
                  nationalIdInfo.length
                    ? {
                        minLength: nationalIdInfo.length?.min,
                        maxLength: nationalIdInfo.length?.max,
                      }
                    : null
                }
                onChange={handleNationalIdChange}
                onFocus={(_value, event) =>
                  onInputFocusHandler("national-id-input", event)
                }
                id="national-id-input"
                placeholder={t("nationalIdPlaceholder", {
                  id: nationalIdInfo.label,
                })}
                variant="default"
              />
            ) : null}
          </form>
        </div>
        {!hideHotelLoyaltySection && (
          <HotelLoyaltySection
            hotelLoyaltyTitle="Hotel Loyalty"
            hotelLoyaltyList={hotelLoyaltyList}
            setHotelLoyaltyList={setHotelLoyaltyList}
          />
        )}
        {!hideFrequentFlyerSection && (
          <FrequentFlyerSection
            className="traveler-info-form-section"
            frequentFlyerTitle={frequentFlyerTitle}
            frequentFlyerList={frequentFlyerList}
            setFrequentFlyerList={setFrequentFlyerList}
            isMobile={isMobile}
            closeButton={closeButton}
            isJetBlueTenant={isJetBlueTenant}
          />
        )}
        {!hideAdditionalInfoSection && (
          <AdditionalInformationSection
            className="traveler-info-form-section"
            additionalInfoTitle={additionalInfoTitle}
            assistance={assistance}
            setAssistance={setAssistance}
            redressNumber={redressNumber}
            setRedressNumber={setRedressNumber}
            knownTravelerNumber={knownTravelerNumber}
            setKnownTravelerNumber={setKnownTravelerNumber}
          />
        )}
        {showDriverLicenseSection ? (
          <DriverLicenseSection
            driverLicenseSectionTitle="Driver's License Details"
            className="traveler-info-form-section"
            driverLicense={driverLicense}
            isMissingDriverLicense={isMissingDriverLicense}
            onSetLicense={(license) => setLicense(license)}
            isMobile={isMobile}
          />
        ) : null}
        {showPassport || (traveler?.passport && !hideFrequentFlyerSection) ? (
          <div
            className={clsx(
              "passport-info-container",
              "traveler-info-form-section"
            )}
          >
            <div className="traveler-info-description">
              <Typography variant="subtitle1">
                {passportTitle || t("passportInformation")}
              </Typography>
            </div>
            {passportSubtitle && (
              <div className="traveler-info-subtitle">
                <Typography variant="subtitle1">{passportSubtitle}</Typography>
              </div>
            )}
            <form className={clsx("passport-info-form", "traveler-info-form")}>
              <Slot
                id="passenger-passport-country-select"
                options={countryOptions}
                setOption={(value: string) => {
                  setPassport({
                    ...passport,
                    countryOfIssue: value,
                  });
                }}
                selected={passport.countryOfIssue}
                label={t("countryOfIssue")}
                component={
                  <B2BTextField
                    label={t("countryOfIssue")}
                    className={clsx("traveler-info-field", "country-of-issue")}
                    required
                    select
                    value={passport.countryOfIssue}
                    onChange={handleCountryChange}
                    id="passport-country-of-issue-input"
                    InputProps={{ id: "passport-country-of-issue-input" }}
                    variant="default"
                  >
                    {renderMenu(countryOptions)}
                  </B2BTextField>
                }
              />
              <B2BTextField
                label={t("passportNumber")}
                className={clsx("traveler-info-field", "passport-number", {
                  error: !!passportNumberError,
                })}
                value={passport.number}
                onChange={handlePassportChange}
                onFocus={(_value, event) =>
                  onInputFocusHandler("passport-number-input", event)
                }
                id="passport-number-input"
                InputProps={{ id: "passport-number-input" }}
                error={!!passportNumberError}
                helperText={passportNumberError}
                variant="default"
              />
              <B2BTextField
                label={passportExpiryLabel}
                className={clsx("traveler-info-field", "passport-expiration", {
                  error: !!passportExpiryError,
                })}
                value={passport.expiration}
                onChange={onPassportExpiryChanged}
                onFocus={(_value, event) =>
                  onInputFocusHandler("passport-expiration-input", event)
                }
                id="passport-expiration-input"
                InputProps={{ id: "passport-expiration-input" }}
                error={!!passportExpiryError}
                helperText={passportExpiryError}
                variant="default"
              />
            </form>
          </div>
        ) : null}
        {!isMobile ? (
          <Slot
            id="desktop-traveler-edit-form-buttons"
            onClose={handleClose}
            onSave={handleSaveTraveler}
            component={
              <div className="traveler-info-form-button-container">
                <ActionButton
                  className={clsx(
                    "traveler-info-button",
                    "traveler-save",
                    buttonClassName
                  )}
                  message={t("saveTraveler")}
                  disabled={isSaveDisabled}
                  onClick={handleSaveTraveler}
                  defaultStyle="h4r-primary"
                />
                <ActionButton
                  className={clsx("traveler-info-button", "traveler-cancel", {
                    hideButton: !handleClose,
                  })}
                  message={t("cancel")}
                  onClick={handleClose}
                  defaultStyle="h4r-secondary"
                />
              </div>
            }
          />
        ) : (
          <Slot
            id="mobile-traveler-edit-form-buttons"
            travelerId={traveler?.id}
            disabled={isSaveDisabled}
            onSave={handleSaveTraveler}
            onDelete={handleRemovePassenger}
            component={
              <MobileTravelerFormButtons
                buttonClassName={buttonClassName}
                travelerId={traveler?.id}
                disabled={isSaveDisabled}
                onSave={handleSaveTraveler}
                onDelete={handleRemovePassenger}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

type MobileTravelerFormButtonsProps = {
  buttonClassName?: string;
  travelerId?: string;
  isEdit?: boolean;
  disabled: boolean;
  onSave: () => void;
  onDelete: (travelerId: string) => void;
};

const MobileTravelerFormButtons = ({
  buttonClassName,
  travelerId,
  disabled,
  onSave,
  onDelete,
}: MobileTravelerFormButtonsProps) => {
  const { t } = useI18nContext();

  return (
    <div
      className={clsx(
        "floating-box traveler-info-form-docked-buttons-container",
        {
          hidden: disabled,
        }
      )}
    >
      <MobileFloatingSection
        className="traveler-info-form-button-container"
        primaryButton={{
          children: travelerId ? t("saveChanges") : t("savePassenger"),
          onClick: onSave,
          className: "mobile-traveler-info-form-save-button",
          wrapperClassName: clsx(
            "mobile-traveler-info-form-save-button-container",
            buttonClassName
          ),
          disabled,
        }}
        secondaryButton={
          travelerId
            ? {
                children: t("deletePassenger"),
                onClick: () => onDelete(travelerId),
                className: "traveler-info-form-delete-button",
                wrapperClassName: "traveler-info-form-section delete",
              }
            : null
        }
      />
    </div>
  );
};
