import * as React from "react";
import { AlgomerchModal } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { AlgomerchTag } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface IFlightAlgomerchModalProps {
  selectedCategory: AlgomerchTag;
  setSelectedCategory: React.Dispatch<React.SetStateAction<AlgomerchTag>>;
  openModal: boolean;
  onClose: () => void;
}

export const FlightAlgomerchModal = (props: IFlightAlgomerchModalProps) => {
  const { selectedCategory, setSelectedCategory, openModal, onClose } = props;
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  return (
    <AlgomerchModal
      openModal={openModal}
      onClose={onClose}
      algomerchInfoTitle={t("algomerch.title")}
      scoreFactorTitles={{
        airline: t("algomerch.scoreFactor.airline"),
        fareClass: t("algomerch.scoreFactor.fareClass"),
        price: t("algomerch.scoreFactor.price"),
        duration: t("algomerch.scoreFactor.duration"),
        stops: t("algomerch.scoreFactor.stops"),
      }}
      algomerchTitles={{
        [AlgomerchTag.Cheapest]: {
          title: t("algomerch.cheapestTag.title"),
          description: t("algomerch.cheapestTag.description"),
          factors: t("algomerch.cheapestTag.factors"),
        },
        [AlgomerchTag.BestFlight]: {
          title: t("algomerch.bestFlightTag.title"),
          description: t("algomerch.bestFlightTag.description"),
          factors: t("algomerch.bestFlightTag.factors"),
        },
        [AlgomerchTag.Fastest]: {
          title: t("algomerch.fastestTag.title"),
          description: t("algomerch.fastestTag.description"),
          factors: t("algomerch.fastestTag.factors"),
        },
        [AlgomerchTag.BestQuality]: {
          title: t("algomerch.bestQualityTag.title"),
          description: t("algomerch.bestQualityTag.description"),
          factors: t("algomerch.bestQualityTag.factors"),
        },
      }}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      isMobile={matchesMobile}
      className="b2b-algomerch-modal"
    />
  );
};
