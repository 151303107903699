import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Divider } from "@material-ui/core";
import clsx from "clsx";

import {
  Airport,
  FiatPrice,
  MultiTicketType,
  Person,
  SeatMapResponseEnum,
  SeatSlice,
} from "@b2bportal/air-booking-api";
import { TripSlice } from "@b2bportal/air-shopping-api";
import { FailedProduct, Product } from "@b2bportal/purchase-api";

import {
  AirDisruptionSelectors,
  AirPriceDropSelectors,
  cartQuoteSelectors,
  ContactSelectors,
  FlightPassengerEventTypesV2,
  FlightPassengerSelectorsV2,
  FlightSelectors,
  PriceBreakdownContent,
  PriceChangeType,
  SeatSelectors,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  CONTINUE_TO_PAYMENT,
  DisruptionProductType,
  FlightSummaryInfo,
  GordianSeatSegment,
  IPerson,
  MODAL_ALERT,
  ModalCategoryType,
  ModalScreens,
  SelectedSeatsSegment,
  TripCategory,
  TripDetails,
} from "@hopper-b2b/types";
import {
  ActionLink,
  AirlineIcon,
  ButtonWrap,
  ContactSummaryRow,
  ErrorPopup,
  IconName,
  MobileFlightSummaryRowNew,
  MobileFloatingButton,
  MobilePopoverCard,
  PassengerSummaryRow,
  SeatsSummaryRow,
} from "@hopper-b2b/ui";
import {
  getSelectedSeatsSegments,
  getSliceIndex,
  PATH_HOME,
  PATH_FLIGHTS_SEARCH,
  useDeviceTypes,
  useEnablePriceDropProtection,
} from "@hopper-b2b/utilities";
import { trackEvent } from "@hopper-b2b/api";

import Close from "../../../../assets/client/close.svg";
import CloseWhite from "../../../../assets/client/darkMode/close-white.svg";
import MobileInfoPopover from "../../../../components/MobileInfoPopover";
import { useDarkModePreferred } from "../../../../utils/colors";
import { FlightPriceDropProtectionBanner } from "../../../../components/Slots";
import { BestPriceGuaranteeBanner } from "../../../../components/BestPriceGuaranteeBanner";
import { Event, TEvent } from "../../events";
import {
  getFlightTotalInPrices,
  getIsPriceBreakdownLoading,
  getShopPricingInfo,
  getSummaryLineItems,
  getTripPriceLineItems,
} from "../../selectors";
import { ConnectedBreadcrumbs } from "../Breadcrumbs";
import { ConnectedPriceBreakdownContent } from "../PriceBreakdownDropdown";
import { ReviewLayout } from "./components/ReviewLayout";
import { FareDetailsModal } from "./components/FareDetailsModal";
import { ItineraryDetailsModal } from "./components/ItineraryDetailsModal";
import { PriceSummaryRow } from "./components/PriceSummaryRow";
import "./styles.scss";

import { ReactComponent as AirplaneDouble } from "../../../../assets/client/airplane-double.svg";
import { useExperiment } from "@hopper-b2b/experiments";
import { FEATURE_FLAGS } from "../../../../App";

export const ConnectedReviewComponent = () => {
  const [openPriceBreakdownModal, setOpenPriceBreakdownModal] =
    useState<boolean>(false);
  const { t } = useI18nContext();
  const history = useHistory();
  const contactInformation = useSelector(
    ContactSelectors.getContactInformation
  );
  const send = useCheckoutSend<TEvent>();

  const isDarkModeEnabled = useDarkModePreferred();

  const selectedPassengersWithType = useSelector(
    FlightSelectors.mergePassengersAndBreakdownPersons
  );
  const passengers = useSelector(
    FlightPassengerSelectorsV2.getUserPassengersParent
  );

  const seatMapAvailable =
    useSelector(SeatSelectors.getSeatMapAvailability) ===
    SeatMapResponseEnum.SeatMapAvailable;

  const selectedSeatSegments = useSelector(
    SeatSelectors.getSelectedSeatSegments
  );
  const seatSlices = useSelector(SeatSelectors.getSeatSlices);
  const seatedPassengers = useSelector(
    FlightPassengerSelectorsV2.getSeatedPassengersParent
  );
  const isCartLocked = useSelector(cartQuoteSelectors.getCartQuoteIsLocked);
  const priceChange = useSelector(cartQuoteSelectors.getCartQuotePriceChange);
  const unavailableProducts = useSelector(
    cartQuoteSelectors.getUnavailableProducts
  );
  const disruptionOffersProducts = useSelector(
    AirDisruptionSelectors.getDisruptionOfferProductTypes
  );
  const flightTotalInPrices = useSelector(getFlightTotalInPrices);
  const tripPriceLineItems = useSelector(getTripPriceLineItems);
  const tripPricingSummaryItems = useSelector(getSummaryLineItems);
  const clientAssets = useSelector(FlightSelectors.getClientAssets);
  const airports = useSelector(FlightSelectors.getAirportMap);
  // Flight Shop Summary Panel
  const tripDetails = useSelector(FlightSelectors.getSelectedTripDetailsParent);
  const tripCategory = useSelector(FlightSelectors.getTripCategoryParent);
  const isPriceBreakdownLoading = useSelector(getIsPriceBreakdownLoading);
  const shopPricingInfo = useSelector(getShopPricingInfo);
  //
  const initialPassengerPricing = useMemo(
    () => shopPricingInfo?.fare?.[0]?.pricing,
    [shopPricingInfo?.fare]
  );

  const tripCurrency = useMemo(
    () => ({
      currencyCode: flightTotalInPrices?.fiat?.currencyCode,
      currencySymbol: flightTotalInPrices?.fiat?.currencySymbol,
    }),
    [
      flightTotalInPrices?.fiat?.currencyCode,
      flightTotalInPrices?.fiat?.currencySymbol,
    ]
  );

  const onBookFlight = useCallback(() => {
    trackEvent({ eventName: CONTINUE_TO_PAYMENT, properties: undefined });
    send(Event.NEXT);
  }, [send]);

  const addSeats = useCallback(() => send(Event.GO_TO_SEAT_SELECTION), [send]);

  // If Cart is locked, then seats have already been added, have to rerun PQ
  const handleEditSeatSelection = useCallback(() => {
    if (isCartLocked) {
      send(Event.GO_TO_CART_QUOTE);
    } else {
      send(Event.EDIT_SEATS_FROM_REVIEW);
    }
  }, [isCartLocked, send]);

  const tripTotalCurrency = useMemo(() => {
    t("chargedInCurrency", {
      currency: tripCurrency?.currencyCode,
    });
  }, [tripCurrency, t]);

  const { formatFiatCurrency } = useI18nContext();

  // cashback is a standard 1%, always
  const cashBackAmount = formatFiatCurrency(
    {
      currencyCode: "BRL", //fallback to BRL
      ...flightTotalInPrices?.fiat,
      value: flightTotalInPrices?.fiat.value * 0.01,
    },
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }
  );

  return (
    <>
      {priceChange?.hasDifference ? (
        <PriceChangeModal
          {...priceChange}
          currencyCode={tripCurrency.currencyCode}
        />
      ) : null}
      {unavailableProducts.length > 0 ? (
        <UnavailableProductsModal
          unavailableProducts={unavailableProducts}
          disruptionOffersProducts={disruptionOffersProducts}
        />
      ) : null}
      <ReviewLayout
        handleGoBack={() => history.goBack()}
        onBookFlight={onBookFlight}
        topContent={<ConnectedBreadcrumbs />}
        clientAssets={clientAssets}
        header={false}
        headerTitle={t("reviewDetails")}
        ctaLabel={t("continueToPayment")}
        rightContent={
          <>
            <ConnectedPriceBreakdownContent />
            <div className="flight-book-mobile-button-container">
              <MobileFloatingButton
                className="flight-book-mobile-button"
                onClick={onBookFlight}
                wrapperClassName="b2b"
                wide
              >
                {t("continueToPayment")}
              </MobileFloatingButton>
            </div>
          </>
        }
      >
        <FlightShopSummaryPanel tripDetails={tripDetails} airports={airports} />
        <ContactSummaryRow
          contactInfo={contactInformation}
          onEdit={() => send(Event.GO_TO_CONTACT_INFORMATION)}
          hideIcon
        />
        <PassengerSummaryPanel
          passengers={passengers}
          selectedPassengers={selectedPassengersWithType}
        />

        {seatMapAvailable || selectedSeatSegments.length > 0 ? (
          <SeatsSummaryPanel
            selectedSeatSegments={selectedSeatSegments}
            tripDetails={tripDetails}
            seatedPassengers={seatedPassengers}
            onEditSeatSelectionClick={handleEditSeatSelection}
            airports={airports}
            addSeats={addSeats}
            seatSlices={seatSlices}
          />
        ) : undefined}
        <PricingSummaryPanel
          price={flightTotalInPrices?.fiat}
          tripCategory={tripCategory}
          onClick={() => setOpenPriceBreakdownModal(true)}
          cashBackAmount={cashBackAmount}
        />
      </ReviewLayout>
      <MobilePopoverCard
        centered
        fullScreen
        open={openPriceBreakdownModal}
        className={clsx(
          "review-price-breakdown-modal",
          isDarkModeEnabled ? "dark-mode" : null
        )}
        contentClassName="review-price-breakdown-modal-content"
        onClose={() => setOpenPriceBreakdownModal(false)}
        topRightButton={
          <ActionLink
            className="filter-close-button"
            content={
              <img
                className="close-button-icon"
                src={isDarkModeEnabled ? CloseWhite : Close}
                alt=""
              />
            }
            label="Close"
            onClick={() => setOpenPriceBreakdownModal(false)}
          />
        }
      >
        <PriceBreakdownContent
          isLoading={isPriceBreakdownLoading}
          currency={tripCurrency}
          lineItems={tripPriceLineItems}
          summaryLineItems={tripPricingSummaryItems}
          header={t("checkoutBreakdown")}
          initialPassengerPricing={initialPassengerPricing}
          tripCategory={tripCategory}
          title={`${t("priceBreakdown")}`}
          currencyString={tripTotalCurrency}
          rewardsBack={cashBackAmount}
        />
      </MobilePopoverCard>
    </>
  );
};

interface UnavailableProductsModalProps {
  unavailableProducts: Array<FailedProduct>;
  disruptionOffersProducts: Array<DisruptionProductType>;
}

const UnavailableProductsModal = ({
  unavailableProducts,
  disruptionOffersProducts,
}: UnavailableProductsModalProps) => {
  const { t } = useI18nContext();
  const history = useHistory();

  const toFlightSelection = () => {
    history.push(`${PATH_HOME}${PATH_FLIGHTS_SEARCH}`);
  };

  const [isOpen, setIsOpen] = useState(true);

  const getSubtitle = useCallback(() => {
    const mappedProductToI18nKey = {
      // TODO: Seats will not be added until cart can be mutable with seats
      [Product.Seats]: "Seats",
    };
    const products = unavailableProducts.reduce(
      (listOfProducts, product) => [
        ...(product.id.product === Product.AirDisruption
          ? disruptionOffersProducts.map((disruptionProducts) =>
              t(disruptionProducts)
            )
          : [t(mappedProductToI18nKey[product.id.product])]),
        ...listOfProducts,
      ],
      [] as Array<string>
    );

    return products.length > 1
      ? t("unavailableProductsMultiple", { products })
      : t("unavailableProductSingle", { products });
  }, [unavailableProducts, disruptionOffersProducts, t]);

  return (
    <ErrorPopup
      open={isOpen}
      title={t("genericShopErrorTitle")}
      subtitle={getSubtitle()}
      primaryButtonText={t("continue")}
      primaryOnClick={() => {
        setIsOpen(false);
      }}
      secondaryButtonText={t("flightShopReview.changeFlight")}
      secondaryOnClick={toFlightSelection}
    />
  );
};

interface PriceChangeModalProps extends PriceChangeType {
  currencyCode: string;
}

const PriceChangeModal = (props: PriceChangeModalProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toFlightSelection = useCallback(() => {
    history.push(`${PATH_HOME}${PATH_FLIGHTS_SEARCH}`);
  }, [history]);

  const isDarkModeEnabled = useDarkModePreferred();

  const primaryButtonText = t("continue");
  const secondaryButtonText = t("flightShopReview.changeFlight");

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          screen: ModalScreens.FLIGHTS_CHECKOUT,
          category: ModalCategoryType.TROUBLE,
          primary_button: primaryButtonText,
          secondary_button: secondaryButtonText,
        },
      });
    }
  }, [isOpen, primaryButtonText, secondaryButtonText]);

  return (
    <MobileInfoPopover
      isOpen={isOpen}
      onClose={onClose}
      secondaryButtonAction={toFlightSelection}
      secondaryButtonText={secondaryButtonText}
      primaryButtonAction={onClose}
      primaryButtonText={primaryButtonText}
      {...(props.isIncrease
        ? {
            title: t(`priceChange.priceIncreaseTitle`, {
              amount: formatFiatCurrency({
                value: props.priceQuoteTotal,
                currencyCode: props.currencyCode,
              }),
            }),
            subtitle: t(`priceChange.priceIncreaseSubtitle`),
            secondaryButtonAction: toFlightSelection,
            secondaryButtonText: t("flightShopReview.changeFlight"),
          }
        : {
            title: t(`priceChange.priceDecreaseTitle`, {
              amount: formatFiatCurrency({
                value: props.priceQuoteTotal,
                currencyCode: props.currencyCode,
              }),
            }),
            subtitle: t(`priceChange.priceDecreaseSubtitle`),
          })}
      className="price-change-modal"
      closeIcon={
        <img
          alt={t("modalClose.ariaLabel")}
          className={"close-button-icon"}
          src={isDarkModeEnabled ? CloseWhite : Close}
        />
      }
    />
  );
};

interface FlightShopSummaryPanelProps {
  tripDetails: TripDetails;
  airports: { [key: string]: Airport } | undefined;
}

const FlightShopSummaryPanel = (props: FlightShopSummaryPanelProps) => {
  const { t } = useI18nContext();
  const [openFareModal, setOpenFareModal] = useState<boolean>(false);
  const { tripDetails, airports } = props;
  const departureSliceIndex = getSliceIndex(true, tripDetails);
  const departureSlice = tripDetails?.slices[departureSliceIndex];

  const returnSliceIndex = getSliceIndex(false, tripDetails);
  const hasReturnFlight = returnSliceIndex !== -1;

  const origin = useSelector(FlightSelectors.getFlightSearchOrigin);
  const destination = useSelector(FlightSelectors.getFlightSearchDestination);

  const [openItineraryDetails, setOpenItineraryDetails] = useState<
    "departure" | "return" | null
  >(null);

  const ticketType = useSelector(FlightSelectors.getFlightMultiTicketType);

  const hackerFareV2Enabled = useExperiment(FEATURE_FLAGS.HFv2);
  const showHackerFareContent =
    hackerFareV2Enabled && ticketType === MultiTicketType.hackerFare;

  const returnSlice = hasReturnFlight
    ? tripDetails.slices[returnSliceIndex]
    : null;

  const getFlightSummaryInfo = (tripSlice: TripSlice): FlightSummaryInfo => {
    const flightSegment = tripSlice.segmentDetails[0];

    // TODO: fix when BE returns airports in new resume endpoints
    const originLabel =
      airports?.[tripSlice.originCode]?.cityName ||
      origin?.label ||
      tripSlice.originName;
    const destinationLabel =
      airports?.[tripSlice.destinationCode]?.cityName ||
      destination?.label ||
      tripSlice.destinationName;

    return {
      originCity: tripSlice.outgoing ? originLabel : destinationLabel,
      destinationCity: tripSlice.outgoing ? destinationLabel : originLabel,
      departure: tripSlice.departureTime,
      arrival: tripSlice.arrivalTime,
      airlineCode: flightSegment.airlineCode,
      airlineName: flightSegment.airlineName,
      isDeparture: tripSlice.outgoing,
      title: t("originToDestination", {
        origin: originLabel,
        destination: destinationLabel,
      }),
      stops: tripSlice.stops,
    };
  };

  return (
    <>
      <div className="mobile-itinerary-cards-section review-flight-details-section">
        <h3 className="flight-summary-section-header">{t("flights")}</h3>

        <Divider className="mobile-review-flight-book-divider" />
        {/* TODO: Update flight summary row to match Nubank designs */}
        <MobileFlightSummaryRowNew
          flightSummaryInfo={getFlightSummaryInfo(departureSlice)}
          onClick={() => setOpenItineraryDetails("departure")}
          customIcon={
            // TODO: When VI is enabled, add in check to show the double icon
            // <AirplaneDouble className="hackerfare-icon" />
            <AirlineIcon
              airlineCode={departureSlice.segmentDetails[0].airlineCode}
            />
          }
          customExpandIcon={IconName.ChevronDown}
          renderAirlineIcon={false}
          showHackerFareContent={showHackerFareContent}
        />
        {hasReturnFlight && returnSlice ? (
          <MobileFlightSummaryRowNew
            flightSummaryInfo={getFlightSummaryInfo(returnSlice)}
            className="return-flight-details"
            onClick={() => setOpenItineraryDetails("return")}
            customIcon={
              // TODO: When VI is enabled, add in check to show the double icon
              // <AirplaneDouble className="hackerfare-icon" />
              <AirlineIcon
                airlineCode={returnSlice.segmentDetails[0].airlineCode}
              />
            }
            customExpandIcon={IconName.ChevronDown}
            renderAirlineIcon={false}
            showHackerFareContent={showHackerFareContent}
          />
        ) : null}

        <Divider className="mobile-review-flight-book-divider" />
        <div className="review-fare-details-container">
          <p className="fare-details-title">{t("reviewFareDetailsTitle")}</p>
          <ButtonWrap
            className="fare-details-link"
            onClick={() => setOpenFareModal(true)}
          >
            {t("reviewFareDetailsButton")}
          </ButtonWrap>
        </div>
        <Divider className="mobile-review-flight-book-divider" />
      </div>
      <FareDetailsModal
        open={openFareModal}
        onClose={() => setOpenFareModal(false)}
        showHackerFareContent={showHackerFareContent}
      />
      <ItineraryDetailsModal
        openModal={openItineraryDetails}
        onClose={() => setOpenItineraryDetails(null)}
      />
    </>
  );
};

interface PassengerSummaryPanelProps {
  selectedPassengers: Person[];
}

const PassengerSummaryPanel = (props: PassengerSummaryPanelProps) => {
  const { selectedPassengers } = props;
  const send = useCheckoutSend<TEvent>();

  const onEdit = useCallback(
    (passenger: Person) => {
      send({
        type: FlightPassengerEventTypesV2.OPEN_FORM_AND_SET_PASSENGER,
        passenger,
      });
    },
    [send]
  );

  return (
    <div className="mobile-itinerary-cards-section review-passenger-section">
      <div className="mobile-trip-details-passenger-info-container">
        <PassengerSummaryRow
          passengers={selectedPassengers as IPerson[]}
          onEdit={onEdit}
          review
          hideIcon
        />
      </div>

      <Divider className="mobile-review-flight-book-divider" />
    </div>
  );
};

interface PricingSummaryPanelProps {
  price: FiatPrice;
  tripCategory: TripCategory;
  onClick: () => void;
  cashBackAmount: string;
}

const PricingSummaryPanel = (props: PricingSummaryPanelProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const { price, tripCategory, onClick, cashBackAmount } = props;

  const enabledPriceDropProtection = useEnablePriceDropProtection();
  const isPDPEligible = useSelector(
    AirPriceDropSelectors.getPriceDropEligibility
  );

  const description =
    tripCategory === TripCategory.ROUND_TRIP
      ? t("roundTripCategoryTotal")
      : t("oneWayTripCategoryTotal");

  return (
    <div className="mobile-itinerary-cards-section review-price-section">
      <Divider className="mobile-review-flight-book-divider" />
      <p className="mobile-review-price-title">{t("priceDetails")}</p>
      <Divider className="mobile-review-flight-book-divider" />

      <BestPriceGuaranteeBanner product={Product.Flight} />
      {enabledPriceDropProtection ? (
        <FlightPriceDropProtectionBanner
          showMore
          isEligible={isPDPEligible}
          hideBrand
        />
      ) : null}
      {price ? (
        <PriceSummaryRow
          priceString={formatFiatCurrency(price, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          description={description}
          onClick={onClick}
          cashBackAmount={cashBackAmount}
        />
      ) : null}
    </div>
  );
};

interface SeatsSummaryPanelProps {
  selectedSeatSegments: GordianSeatSegment[];
  tripDetails: TripDetails;
  seatedPassengers: Person[];
  onEditSeatSelectionClick: (idx: number) => void;
  addSeats: () => void;
  airports: { [key: string]: Airport };
  seatSlices: Array<SeatSlice>;
}

const SeatsSummaryPanel = (props: SeatsSummaryPanelProps) => {
  const {
    selectedSeatSegments,
    tripDetails,
    seatedPassengers,
    onEditSeatSelectionClick,
    addSeats,
    airports,
    seatSlices,
  } = props;
  const { formatCurrency } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const [outboundSegmentsSeats, setOutboundSegmentsSeats] = useState<
    SelectedSeatsSegment[]
  >([]);
  const [returnSegmentsSeats, setReturnSegmentsSeats] = useState<
    SelectedSeatsSegment[]
  >([]);

  const totalSeatPricing = useSelector(SeatSelectors.getSeatTotalPricing);
  const passengers = useSelector(
    FlightPassengerSelectorsV2.getAllSelectedUserPassengersParent
  );

  useEffect(() => {
    if (selectedSeatSegments.length > 0) {
      const outboundSegmentSelectedSeats = getSelectedSeatsSegments(
        selectedSeatSegments,
        tripDetails.slices[0],
        seatedPassengers,
        tripDetails.slices[0].segmentDetails.length,
        seatSlices[0]
      );
      setOutboundSegmentsSeats(outboundSegmentSelectedSeats);
      if (tripDetails.slices.length > 1) {
        const returnSegmentSelectedSeats = getSelectedSeatsSegments(
          selectedSeatSegments,
          tripDetails.slices[1],
          seatedPassengers,
          tripDetails.slices[0].segmentDetails.length,
          seatSlices[1]
        );
        setReturnSegmentsSeats(returnSegmentSelectedSeats);
      }
    }
  }, [seatSlices, seatedPassengers, selectedSeatSegments, tripDetails.slices]);

  const description = useMemo(() => {
    return (
      <div className="seats-summary-row-description">
        <I18nMarkup
          tKey={"seats.totalSeatPricing"}
          replacements={{
            count: passengers.length,
            price: formatCurrency(totalSeatPricing),
          }}
        />
      </div>
    );
  }, [formatCurrency, passengers.length, totalSeatPricing]);

  return (
    <div
      className={clsx(
        "mobile-itinerary-cards-section",
        "seat-selection-review-container",
        "nubank",
        "review",
        {
          mobile: matchesMobile,
        }
      )}
    >
      <SeatsSummaryRow
        outboundSeatSegments={outboundSegmentsSeats}
        returnSeatSegments={returnSegmentsSeats}
        onEditClick={onEditSeatSelectionClick}
        outboundOriginCode={tripDetails.slices[0].originCode}
        returnOriginCode={
          tripDetails.slices.length > 1
            ? tripDetails.slices[1].originCode
            : undefined
        }
        airports={airports}
        description={description}
        addSeats={addSeats}
      />
      <Divider className="mobile-review-flight-book-divider" />
    </div>
  );
};
