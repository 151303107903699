import clsx from "clsx";
import "./styles.scss";

export interface IShopHeaderTextProps {
  header: string | JSX.Element;
  subheaderText: string | JSX.Element;
  ctaLabel: string;
  className?: string;
  onClick?: () => void;
}

const ShopHeaderText = ({
  header,
  subheaderText,
  ctaLabel,
  className,
  onClick,
}: IShopHeaderTextProps) => {
  return (
    <div className={clsx("shop-header-root", className)}>
      <div className="header-container">
        <div className="header-text-wrapper">{header}</div>
        <div className="subheader-text-wrapper">{subheaderText}</div>
      </div>
      {onClick ? (
        <button className={clsx("shop-header-button")} onClick={onClick}>
          {ctaLabel}
        </button>
      ) : null}
    </div>
  );
};

export default ShopHeaderText;
