import { useDeviceTypes } from "@hopper-b2b/utilities";

import MobileHeader from "./components/MobileHeader";
import DesktopHeader from "./components/DesktopHeader/component";

interface IHeaderProps {
  locationPath?: string;
  openSupportChat?: () => void;
}

const Header = (props: IHeaderProps) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <MobileHeader {...props} />
  ) : (
    <DesktopHeader {...props} />
  );
};

export default Header;
