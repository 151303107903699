import {
  PassengersResponse,
  Person,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";
import { PersonId } from "@hopper-b2b/types";

export enum FlightPassengerEventTypesV2 {
  ADD_PASSENGER = "ADD_PASSENGER",
  CLEAR_CURRENT_INFANT_ID = "CLEAR_CURRENT_INFANT_ID",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",
  COMPLETE = "COMPLETE",
  DELETE_PASSENGER = "DELETE_PASSENGER",
  GO_IDLE = "GO_IDLE",
  NEXT = "NEXT",
  ON_INFANT_MODAL_CONTINUE = "ON_INFANT_MODAL_CONTINUE",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SELECT_PASSENGER = "SELECT_PASSENGER",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  PREVIOUS = "PREVIOUS",
  FETCH_TRIP_PRICING = "FETCH_TRIP_PRICING",
  OPEN_FORM_AND_SET_PASSENGER = "OPEN_FORM_AND_SET_PASSENGER",
}

export type ValidateUserPassengerEventType =
  | FlightPassengerEventTypesV2.ADD_PASSENGER
  | FlightPassengerEventTypesV2.CLEAR_CURRENT_INFANT_ID
  | FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR
  | FlightPassengerEventTypesV2.COMPLETE
  | FlightPassengerEventTypesV2.DELETE_PASSENGER
  | FlightPassengerEventTypesV2.GO_IDLE
  | FlightPassengerEventTypesV2.NEXT
  | FlightPassengerEventTypesV2.ON_INFANT_MODAL_CONTINUE
  | FlightPassengerEventTypesV2.OPEN_PASSENGER_FORM
  | FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER
  | FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM
  | FlightPassengerEventTypesV2.SELECT_PASSENGER
  | FlightPassengerEventTypesV2.SET_CURRENT_PASSENGER
  | FlightPassengerEventTypesV2.UPDATE_PASSENGER
  | FlightPassengerEventTypesV2.PREVIOUS
  | FlightPassengerEventTypesV2.FETCH_TRIP_PRICING
  | FlightPassengerEventTypesV2.OPEN_FORM_AND_SET_PASSENGER;

export interface SelectPassengerEvent {
  type: FlightPassengerEventTypesV2.SELECT_PASSENGER;
  singleTravelerWorkflow?: boolean;
  passengerId: PersonId;
  data?: { id: string; value: Array<Person>; onUpdate?: boolean };
}
export interface AddPassengerEvent {
  type: FlightPassengerEventTypesV2.ADD_PASSENGER;
}
export interface UpdatePassengerEvent {
  type: FlightPassengerEventTypesV2.UPDATE_PASSENGER;
  person: Person;
  onUpdate: boolean;
}
export interface DeletePassengerEvent {
  type: FlightPassengerEventTypesV2.DELETE_PASSENGER;
  personId: PersonId;
}

export interface ValidateUserPassengerEvent {
  type: ValidateUserPassengerEventType;
  passengerId?: string;
  singleTravelerWorkflow?: boolean;
}

export interface SetCurrentPassengerEvent {
  type: FlightPassengerEventTypesV2.SET_CURRENT_PASSENGER;
  passenger: Person;
}

export interface OpenPassengerFormEvent {
  type: FlightPassengerEventTypesV2.OPEN_PASSENGER_FORM;
}

export interface OpenPassengerPickerEvent {
  type: FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER;
}

export interface OpenPassportFormEvent {
  type: FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM;
}

export interface GoIdleEvent {
  type: FlightPassengerEventTypesV2.GO_IDLE;
}

export interface SetCurrentPassengerEvent {
  type: FlightPassengerEventTypesV2.SET_CURRENT_PASSENGER;
  passenger: Person;
}

export interface ClearCurrentInfantId {
  type: FlightPassengerEventTypesV2.CLEAR_CURRENT_INFANT_ID;
}

export interface OnInfantModalContinue {
  type: FlightPassengerEventTypesV2.ON_INFANT_MODAL_CONTINUE;
  seatType: "OwnSeat" | "OnLap";
}

export interface ClearPassengerInformationError {
  type: FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR;
}

export interface NextEventType {
  type: FlightPassengerEventTypesV2.NEXT;
}

export type ValidateAndFetchTripPricingEvent = [
  PassengersResponse,
  TripPricingSummary
];

export interface PreviousEventType {
  type: FlightPassengerEventTypesV2.PREVIOUS;
}

export interface CompleteEventType {
  type: FlightPassengerEventTypesV2.COMPLETE;
}

export interface FetchTripPricing {
  type: FlightPassengerEventTypesV2.FETCH_TRIP_PRICING;
  tripPricing: TripPricingSummary;
}

export interface OpenPassengerFormAndSetCurrentPassenger {
  type: FlightPassengerEventTypesV2.OPEN_FORM_AND_SET_PASSENGER;
  passenger: Person;
}

export type FlightPassengerEventsV2 =
  | AddPassengerEvent
  | ClearCurrentInfantId
  | ClearPassengerInformationError
  | CompleteEventType
  | DeletePassengerEvent
  | GoIdleEvent
  | NextEventType
  | OnInfantModalContinue
  | OpenPassengerFormAndSetCurrentPassenger
  | OpenPassengerFormEvent
  | OpenPassengerPickerEvent
  | OpenPassportFormEvent
  | PreviousEventType
  | SelectPassengerEvent
  | SetCurrentPassengerEvent
  | SetCurrentPassengerEvent
  | UpdatePassengerEvent;
