import { useI18nContext } from "@hopper-b2b/i18n";
import { Language } from "@hopper-b2b/types";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import DropDownIcon from "../../../../assets/icons/common/drop-down-icon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/icons/common/support-icon.svg";
import HeaderMenuDropdown, {
  IHeaderMenuDropdownBaseProps,
} from "../HeaderMenuDropdown/component";

import HeaderMenuLink, {
  IHeaderMenuLinkBaseProps,
} from "../HeaderMenuLink/component";
import { LanguageSelectDropdown } from "../LanguageSelectDropdown";

import "./styles.scss";

export enum HeaderMenuLinkType {
  Trips = "trips",
  Support = "support",
  Menu = "menu",
  Faq = "faq",
}

interface IHeaderHomePageRightSectionLinkProps
  extends IHeaderMenuLinkBaseProps,
    IHeaderMenuDropdownBaseProps {
  type: HeaderMenuLinkType;
}

interface IHeaderHomePageRightSectionProps {
  links: IHeaderHomePageRightSectionLinkProps[];
  className?: string;
  supportedLanguages: Array<Language>;
}

const HeaderHomePageRightSection = ({
  className,
  links,
  supportedLanguages,
}: IHeaderHomePageRightSectionProps) => {
  const { language } = useI18nContext();
  return (
    <Box className={clsx("home-page-menu-links", "right-section", className)}>
      <LanguageSelectDropdown
        defaultLocale={language}
        supportedLanguages={supportedLanguages}
      />
      <HeaderLinks links={links} />
    </Box>
  );
};
export default HeaderHomePageRightSection;

const HeaderLinks = ({
  links,
}: {
  links: IHeaderHomePageRightSectionLinkProps[];
}) => {
  return (
    <>
      {links.map((link, index) => {
        const commonProps = {
          key: index,
          className: clsx(
            "home-page-header-right-section-link",
            link.type,
            link.className
          ),
          selected: link.selected,
          content: (
            <Box
              className="header-link-content-container"
              data-dd-action-name="Main navigation link"
            >
              {link.content}
              {getLinkIcon(link.type)}
            </Box>
          ),
          disabled: link.disabled,
        };
        switch (link.type) {
          case HeaderMenuLinkType.Menu:
            return (
              <HeaderMenuDropdown
                key={"HeaderMenuDropdown-" + index}
                dropdownContent={link.dropdownContent}
                {...commonProps}
              />
            );
          default:
            return (
              <HeaderMenuLink
                key={"HeaderMenuLink-" + index}
                onClick={link.onClick}
                {...commonProps}
              />
            );
        }
      })}
    </>
  );
};

const getLinkIcon = (type: HeaderMenuLinkType) => {
  switch (type) {
    case HeaderMenuLinkType.Support:
      return (
        <SupportIcon className={clsx("header-link-content-icon", "support")} />
      );

    case HeaderMenuLinkType.Faq:
      return (
        <SupportIcon className={clsx("header-link-content-icon", "support")} />
      );

    case HeaderMenuLinkType.Menu:
      return (
        <img
          src={DropDownIcon}
          alt="Drop Down Icon"
          className={clsx("header-link-content-icon", "menu")}
        />
      );

    default:
      return null;
  }
};
