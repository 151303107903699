import { Icon, IconName } from "@hopper-b2b/ui";
import clsx from "clsx";
import "./styles.scss";

const Maintenance = () => {
  return (
    <div className={clsx("cp-maintenance-content")}>
      <Icon className="error-icon" name={IconName.ErrorState} />
      <h2>Service currently unavailable due to scheduled maintenance.</h2>
      <span> Please try again soon</span>
    </div>
  );
};

export default Maintenance;
