import { useContext } from "react";
import {
  fetchLocationAutocomplete,
  fetchTripDetails,
  fetchTripPricing,
  fetchUserPassengers,
  getQuoteBreakdown,
  validateUserPassengers,
} from "@hopper-b2b/api";
import { LocationResponse } from "@b2bportal/air-shopping-api";
import {
  Failure,
  PassengersForFareRequest,
  PassengersResponse,
  PassengersResponseEnum,
  PersonListResponse,
  PersonListSuccess,
  TripPricingSummaryResponse,
  TripPricingSummarySuccess,
} from "@b2bportal/air-booking-api";
import {
  CipherText,
  QuoteBreakdownResponse,
  QuoteState,
} from "@b2bportal/purchase-api";
import queryStringParser from "query-string";
import { History } from "history";

import { ITripTerminus, TripCategory, TripDetails } from "@hopper-b2b/types";
import {
  ResumeStep,
  ResumeCheckoutComponent,
  SetContactInformationContext,
  ResumeEvent,
  parseFlightsQueryString,
  getFlightSelectedLocation,
  SetFlightSearchContext,
  SetFlightShopContext,
  SetPriceDropContext,
} from "@hopper-b2b/checkout";

import { emailRegex } from "@hopper-b2b/utilities";
import { LoadingScreen } from "../../../checkout/components/LoadingScreen";
import dayjs from "dayjs";
import { Event } from "../events";
import { nubankPhoneRegex } from "../../../components/Slots/ContactInfoForm/component";
import { validateContext } from "../utilities";
import { NubankPaymentEvent } from "../../../checkout";
import { UserContext } from "../../../App";
import {
  EnrollmentResponse,
  PAYMENT_ENROLLMENT_STATUS_PARAM,
} from "../../../checkout/states/payments/api/checkAuthStatus";
import {
  PATH_FLIGHTS_SEARCH,
  PATH_FLIGHTS_SHOP,
} from "../../../utils/urlPaths";
import { decodeEmailAndPhone } from "../../../checkout/helpers";

export const RefreshCheckout = () => {
  const { sessionInfo } = useContext(UserContext);

  return sessionInfo?.userInfoResponse?.userId ? (
    <ResumeCheckoutComponent
      steps={getNubankRefreshSteps(sessionInfo.userInfoResponse.userId)}
      validateContext={validateContext}
    >
      <LoadingScreen />
    </ResumeCheckoutComponent>
  ) : null;
};

const contactInfoValidator = (userId: string) => async (history: History) => {
  try {
    const { email, phone } = parseFlightsQueryString(history);
    const { decodedEmail, decodedPhone } = await decodeEmailAndPhone({
      email,
      phone,
      userId,
    });
    if (
      decodedEmail &&
      decodedPhone &&
      emailRegex.test(decodedEmail) &&
      nubankPhoneRegex.test(decodedPhone)
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.log("decoding failed in contactInfoValidator", error);
    return false;
  }
};

const getNubankRefreshSteps = (userId: string): Array<ResumeStep> => [
  // Step 1: check if we have flight search params, if not kick them to PATH_FLIGHTS_SEARCH
  {
    fallback: { url: `/${PATH_FLIGHTS_SEARCH}` },
    validator: (history) => {
      const { tripCategory, origin, destination, departureDate, returnDate } =
        parseFlightsQueryString(history);

      if (
        [TripCategory.ONE_WAY, TripCategory.ROUND_TRIP].includes(tripCategory)
      ) {
        if (IATA_REGEX.test(origin) && IATA_REGEX.test(destination)) {
          if (departureDate && dayjs(departureDate).isValid()) {
            if (tripCategory === TripCategory.ONE_WAY) {
              return true;
            } else if (
              tripCategory === TripCategory.ROUND_TRIP &&
              returnDate &&
              dayjs(returnDate).isValid()
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
    getStepEvent: (history) => {
      const { tripCategory, departureDate, returnDate } =
        parseFlightsQueryString(history);
      return {
        type: ResumeEvent.SET_FLIGHT_SEARCH_CONTEXT,
        tripCategory,
        departureDate,
        returnDate,
      } as SetFlightSearchContext;
    },
    requests: [
      {
        api: fetchLocationAutocomplete,
        requestPayload: (history) => {
          const { origin } = parseFlightsQueryString(history);
          return { LocationQuery: "Label", l: origin };
        },
        responseEvent: (response: LocationResponse, history) => {
          const { origin } = parseFlightsQueryString(history);
          return {
            type: ResumeEvent.SET_FLIGHT_SEARCH_ORIGIN,
            origin: getFlightSelectedLocation(
              response,
              origin
            ) as ITripTerminus,
          };
        },
      },
      {
        api: fetchLocationAutocomplete,
        requestPayload: (history) => {
          const { destination } = parseFlightsQueryString(history);
          return { LocationQuery: "Label", l: destination };
        },
        responseEvent: (response: LocationResponse, history) => {
          const { destination } = parseFlightsQueryString(history);
          return {
            type: ResumeEvent.SET_FLIGHT_SEARCH_DESTINATION,
            destination: getFlightSelectedLocation(
              response,
              destination
            ) as ITripTerminus,
          };
        },
      },
    ],
  },
  // Step 2: check if flight shop params, if not kick them to PATH_FLIGHTS_SHOP
  {
    fallback: { url: `/${PATH_FLIGHTS_SHOP}` },
    validator: (history) => {
      const { tripId } = parseFlightsQueryString(history);
      return !!tripId;
    },
    requests: [
      {
        api: fetchTripDetails,
        requestPayload: (history) => {
          const { tripId } = parseFlightsQueryString(history);
          return tripId;
        },
        responseEvent: (response: TripDetails, history) => {
          const { outgoingFareId, returnFareId } =
            parseFlightsQueryString(history);
          return {
            type: ResumeEvent.SET_FLIGHT_TRIP_DETAILS,
            tripDetails: response,
            selectedFareId: returnFareId || outgoingFareId,
          };
        },
      },
    ],
    getStepEvent: (history) => {
      const { tripId, returnFareId, outgoingFareId } =
        parseFlightsQueryString(history);
      return {
        type: ResumeEvent.SET_FLIGHT_SHOP_CONTEXT,
        tripId,
        returnFareId,
        outgoingFareId,
      } as SetFlightShopContext;
    },
  },
  // Step 2b: check if they have fintech, and if so add it to xstate
  // Currently only checking for price drop protection
  {
    fallback: { url: `/${PATH_FLIGHTS_SHOP}` },
    validator: () => {
      // We want always try adding fintech products, defaulting to true
      return true;
    },

    getStepEvent: (history) => {
      const { priceDropCandidateId } = parseFlightsQueryString(history);
      return {
        type: ResumeEvent.SET_PRICE_DROP_CONTEXT,
        candidateId: priceDropCandidateId,
      } as SetPriceDropContext;
    },
  },
  // Step 3: Contact Information
  {
    fallback: { event: ResumeEvent.GO_TO_CONTACT_INFORMATION },
    validator: contactInfoValidator(userId),
    getStepEvent: async (history) => {
      try {
        const { email, phone } = parseFlightsQueryString(history);
        const { decodedEmail, decodedPhone } = await decodeEmailAndPhone({
          email,
          phone,
          userId,
        });
        return {
          type: ResumeEvent.SET_CONTACT_INFORMATION_CONTEXT,
          email: decodedEmail,
          phone: decodedPhone,
        } as SetContactInformationContext;
      } catch (error) {
        console.log("decoding failed in getStepEvent", error);
        return { type: ResumeEvent.GO_TO_CONTACT_INFORMATION };
      }
    },
  },
  // Step 4: Passenger Information
  {
    fallback: { event: ResumeEvent.GO_TO_PASSENGER_SELECT },
    validator: (history) => {
      const { selectedPassengerIds } = parseFlightsQueryString(history);
      if (selectedPassengerIds?.length && selectedPassengerIds?.length > 0) {
        return true;
      }
      return false;
    },
    requests: [
      {
        api: fetchUserPassengers,
        responseEvent: (response: PersonListResponse, history) => {
          const { selectedLapInfantIds, selectedPassengerIds } =
            parseFlightsQueryString(history);

          if (response.Response === "Success") {
            const passengers = (response as PersonListSuccess).value;
            const passengerIds = passengers.map((p) => p.id);
            const validLapInfants =
              selectedLapInfantIds?.length > 0
                ? selectedLapInfantIds.every((id) => passengerIds.includes(id))
                : true;
            if (
              selectedPassengerIds.every((id) => passengerIds.includes(id)) &&
              validLapInfants
            ) {
              return {
                type: ResumeEvent.SET_PASSENGER_INFORMATION_CONTEXT,
                userPassengers: passengers,
                selectedPassengerIds,
                selectedLapInfantIds,
              };
            }
          }
          return {
            type: Event.GO_TO_PASSENGER_SELECT,
          };
        },
      },
    ],
  },
  // Step 4b: Passenger Validation & Trip Pricing
  {
    fallback: { event: ResumeEvent.GO_TO_PASSENGER_SELECT },
    validator: (history) => {
      const { selectedPassengerIds } = parseFlightsQueryString(history);
      if (selectedPassengerIds?.length && selectedPassengerIds?.length > 0) {
        return true;
      }
      return false;
    },
    requests: [
      {
        api: validateUserPassengers,
        requestPayload: (history) => {
          const {
            tripId,
            outgoingFareId,
            returnFareId,
            selectedPassengerIds,
            selectedLapInfantIds,
          } = parseFlightsQueryString(history);

          const payload: PassengersForFareRequest = {
            seatedPassengers: selectedPassengerIds,
            tripId: tripId,
            fareId: returnFareId ? returnFareId : outgoingFareId,
            lapInfants: selectedLapInfantIds,
            ancillaryIds: [],
          };
          return payload;
        },
        responseEvent: (response: PassengersResponse) => {
          if (
            response.PassengersResponse === PassengersResponseEnum.PassengersOk
          ) {
            return {
              type: ResumeEvent.SET_PASSENGER_VALIDATION,
            };
          } else {
            // Throw an error to run the error flow and run the fallback
            throw Error("invalid passengers");
          }
        },
      },
      {
        api: fetchTripPricing,
        requestPayload: (history) => {
          const {
            tripId,
            outgoingFareId,
            returnFareId,
            selectedPassengerIds,
            selectedLapInfantIds,
          } = parseFlightsQueryString(history);

          const payload: PassengersForFareRequest = {
            seatedPassengers: selectedPassengerIds,
            tripId: tripId,
            fareId: returnFareId ? returnFareId : outgoingFareId,
            lapInfants: selectedLapInfantIds,
            ancillaryIds: [],
          };
          return payload;
        },
        responseEvent: (response: TripPricingSummaryResponse) => {
          if ((response as Failure).errors) {
            // Throw an error to run the error flow and run the fallback
            throw Error((response as Failure).errors.join(". "));
          }

          return {
            type: ResumeEvent.SET_TRIP_PRICING,
            tripSummary: (response as TripPricingSummarySuccess).value,
          };
        },
      },
    ],
  },
  // Step 5: Cart Quote
  {
    fallback: { event: ResumeEvent.GO_TO_PASSENGER_SELECT },
    validator: (history) => {
      const { cartToken } = parseFlightsQueryString(history);
      return !!cartToken;
    },
    requests: [
      {
        api: getQuoteBreakdown,
        requestPayload: (history) => {
          const { cartToken } = parseFlightsQueryString(history);
          return { value: cartToken } as CipherText;
        },
        responseEvent: (response: QuoteBreakdownResponse, history) => {
          const { cartToken } = parseFlightsQueryString(history);

          if (
            response.quoteBreakdown.state === QuoteState.Empty ||
            response.quoteBreakdown.state === QuoteState.Unavailable
          ) {
            throw Error(
              `quoteBreakdown error with quoteState: ${response.quoteBreakdown.state}`
            );
          }

          return {
            type: ResumeEvent.SET_CART_QUOTE_CONTEXT,
            breakdown: response,
            cipherText: { value: cartToken },
          };
        },
        errorEvent: () => ({ type: ResumeEvent.GO_TO_PASSENGER_SELECT }),
      },
    ],
    getStepEvent: () => ({
      type: ResumeEvent.GO_TO_REVIEW,
    }),
  },
];

const IATA_REGEX = /^[a-z]{3}$/i;

export const parsePaymentQueryParams = (history: History) => {
  const queryString = history?.location?.search || "";

  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  return {
    enrollmentResponse: parsedQueryStringPrimitive[
      PAYMENT_ENROLLMENT_STATUS_PARAM
    ] as string,
  };
};
