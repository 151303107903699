import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getTripCategory,
  getOpenDatesModal,
  getCalendarVisited,
  hasSelectedDates,
  viewCalendarEventProperties,
  getDepartureMonths,
} from "../../../../reducer";
import {
  populateSearchUrlParams,
  setCalendarVisited,
  setDepartureDate,
  setOpenDatesModal,
  setReturnDate,
} from "../../../../actions/actions";
import { CalendarPickerButton } from "./component";

export const mapStateToProps = (state: IStoreState) => ({
  tripCategory: getTripCategory(state),
  openDatesModal: getOpenDatesModal(state),
  calendarVisited: getCalendarVisited(state),
  hasSelectedDates: hasSelectedDates(state),
  trackingProperties: viewCalendarEventProperties(state),
  months: getDepartureMonths(state),
});

const mapDispatchToProps = {
  setDepartureDate,
  setReturnDate,
  setOpenDatesModal,
  setCalendarVisited,
  populateSearchUrlParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedCalendarPickerButtonProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCalendarPickerButton = connector(CalendarPickerButton);
