import {
  Box,
  Button,
  Divider,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  REQUESTED_SEATMAP,
  SKIPPED_SEAT_SELECTION,
  SelectedSeatsSegment,
} from "@hopper-b2b/types";
import {
  ActionButton,
  BackButton,
  MobileFloatingButton,
  MobileFloatingSection,
  SelectedSeatsConfirmation,
  Slot,
} from "@hopper-b2b/ui";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";

import { DesktopLayout, MobileLayout } from "../common";
import Dialog from "./Dialog";
import Page from "./Page";
import { CHOOSE_SEAT_FRAME_HASH, getSelectedSeatsSegments } from "./constants";
import "./styles.scss";
import { ISeatSelectionProps } from "./types";

const SeatSelectionContainer: FC<
  PropsWithChildren<{
    className?: string;
    isMobile: boolean;
    headerTitle?: string;
    desktopRightContent?: JSX.Element;
    desktopTopContent?: JSX.Element;
    headerElement?: JSX.Element;
    logo?: string;
    isContinueDisabled: boolean;
    onGoBack: () => void;
    onContinue: () => void;
  }>
> = ({
  headerTitle,
  headerElement,
  logo,
  isMobile,
  className,
  onGoBack,
  onContinue,
  isContinueDisabled,
  desktopRightContent,
  desktopTopContent,
  children,
}) => {
  const { t } = useI18nContext();

  return (
    <div className="seat-selection-workflow-root">
      {isMobile ? (
        <MobileLayout
          className={clsx("seat-checkout-mobile", "mobile", className)}
          topLeftButton={<MobileBackButton onGoBack={onGoBack} />}
          headerElement={headerElement}
          title={headerTitle}
        >
          {children}
        </MobileLayout>
      ) : (
        <DesktopLayout
          clientLogo={logo}
          rightContent={
            <>
              {desktopRightContent}
              <Divider />
              <Box m={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disableElevation
                  onClick={onContinue}
                  disabled={isContinueDisabled}
                >
                  {t?.("continue")}
                </Button>
              </Box>
              <Slot id="non-refundable-banner" applyPositionAbsolute />
            </>
          }
          topContent={desktopTopContent}
        >
          {children}
        </DesktopLayout>
      )}
    </div>
  );
};
export const SeatSelection = ({
  title,
  subtitle,
  className,
  seatsInfo,
  disabled,
  seatMapHtml,
  selectedSeats,
  selectedSeatSegments = [],
  tripDetails,
  passengers,
  setSkipSeatSelection,
  headerElement,
  desktopRightContent,
  clientAssets,
  desktopTopContent,
  onGoBack,
  onGoNext,
  skippedSeatSelection,
  headerTitle,
  setSelectedSeats,
  cheapestSeat,
  totalSeatPricing,
  isPopup = true,
  getSummaryPanel,
  airports,
  styleString,
  editSeatsFromReview = false,
}: ISeatSelectionProps): ReactElement<ISeatSelectionProps> => {
  const { t, formatCurrency, formatFiatCurrency } = useI18nContext();
  const history = useHistory();
  const location = useLocation();
  const { matchesMobile } = useDeviceTypes();

  const isHopper = [ClientName.HOPPER].includes(
    getEnvVariables("clientName") as ClientName
  );

  const [segmentIndex, setSegmentIndex] = useState<number>(0);
  const [onEditMode, setOnEditMode] = useState<boolean>(false);

  const isSeatMapOpen = useMemo(
    () => location.hash === CHOOSE_SEAT_FRAME_HASH,
    [location.hash]
  );

  const setOpenSeatMapModal = useCallback(
    (val: boolean) => {
      if (val) {
        history.push(
          history.location.pathname +
            history.location.search +
            CHOOSE_SEAT_FRAME_HASH
        );
      } else {
        if (isSeatMapOpen) {
          if (!editSeatsFromReview) {
            history.goBack();
          }
        }
      }
    },
    [editSeatsFromReview, history, isSeatMapOpen]
  );

  useEffect(() => {
    if (editSeatsFromReview) {
      setOpenSeatMapModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const outboundSegmentsSeats: SelectedSeatsSegment[] = useMemo(() => {
    if (selectedSeatSegments.length > 0) {
      return getSelectedSeatsSegments(
        selectedSeatSegments,
        tripDetails.slices[0],
        passengers,
        tripDetails.slices[0].segmentDetails.length,
        seatsInfo?.slices[0]
      );
    }
    return [];
  }, [passengers, seatsInfo?.slices, selectedSeatSegments, tripDetails.slices]);

  const returnSegmentsSeats = useMemo(() => {
    if (selectedSeatSegments.length > 0) {
      if (tripDetails.slices.length > 1) {
        return getSelectedSeatsSegments(
          selectedSeatSegments,
          tripDetails.slices[1],
          passengers,
          tripDetails.slices[0].segmentDetails.length,
          seatsInfo?.slices[1]
        );
      }
    }
    return [];
  }, [passengers, seatsInfo?.slices, selectedSeatSegments, tripDetails.slices]);

  const headerText = useMemo(
    () =>
      selectedSeats.length === 0
        ? t("seats.chooseSeats")
        : t("seats.selectedSeats"),
    [selectedSeats, t]
  );

  const onRequestSeatMap = useCallback(() => {
    trackEvent({
      eventName: REQUESTED_SEATMAP,
      properties: {},
    });
    setOpenSeatMapModal(true);
  }, [setOpenSeatMapModal]);

  const onClickMobileCTA = useCallback(() => {
    if (selectedSeats.length > 0) {
      onGoNext();
    } else {
      onRequestSeatMap();
    }
  }, [onGoNext, onRequestSeatMap, selectedSeats.length]);

  const skipSeatSelection = useCallback(() => {
    trackEvent({
      eventName: SKIPPED_SEAT_SELECTION,
      properties: {},
    });
    setSkipSeatSelection();
    onGoNext();
  }, [onGoNext, setSkipSeatSelection]);

  const onEditClick = useCallback(
    (idx: number) => {
      setSegmentIndex(idx);
      setOpenSeatMapModal(true);
      setOnEditMode(true);
    },
    [setOpenSeatMapModal]
  );

  const isUberClient = getEnvVariables("clientName") === ClientName.UBER;

  return (
    <SeatSelectionContainer
      headerTitle={headerTitle}
      isMobile={matchesMobile}
      className={className}
      logo={clientAssets?.logo}
      headerElement={headerElement}
      desktopRightContent={desktopRightContent}
      desktopTopContent={desktopTopContent}
      onGoBack={onGoBack}
      onContinue={onGoNext}
      isPopup={isPopup}
      isContinueDisabled={!(selectedSeats.length > 0) && !skippedSeatSelection}
    >
      <div
        className={clsx("seat-selection-container", className, {
          disabled: disabled,
          mobile: matchesMobile,
        })}
      >
        {!isPopup && isSeatMapOpen ? (
          <Page
            cheapestSeat={cheapestSeat}
            passengers={passengers}
            setSelectedSeats={setSelectedSeats}
            totalSeatPricing={totalSeatPricing}
            tripDetails={tripDetails}
            setOpen={setOpenSeatMapModal}
            outboundSegmentsSeats={outboundSegmentsSeats}
            returnSegmentsSeats={returnSegmentsSeats}
            selectedSeatSegments={selectedSeatSegments}
            seatMapHtml={seatMapHtml}
            segmentIndex={segmentIndex}
            onEditMode={onEditMode}
            styleString={styleString}
          />
        ) : (
          <>
            <Box className="seat-selection-description">
              <h2 className="step-title" style={{ marginTop: 0 }}>
                {title}
              </h2>
              <Typography variant="body2">
                {subtitle ? subtitle : t("seats.subtitle")}
              </Typography>
            </Box>

            {isPopup && isSeatMapOpen ? (
              <Dialog
                cheapestSeat={cheapestSeat}
                passengers={passengers}
                setSelectedSeats={setSelectedSeats}
                totalSeatPricing={totalSeatPricing}
                tripDetails={tripDetails}
                setOpen={setOpenSeatMapModal}
                open={isSeatMapOpen}
                outboundSegmentsSeats={outboundSegmentsSeats}
                returnSegmentsSeats={returnSegmentsSeats}
                selectedSeatSegments={selectedSeatSegments}
                seatMapHtml={seatMapHtml}
                segmentIndex={segmentIndex}
                onEditMode={onEditMode}
              />
            ) : null}
          </>
        )}

        <Paper
          className="seat-selection-wrapper-container"
          variant={isUberClient || isHopper ? "elevation" : "outlined"}
          elevation={0}
        >
          <Box m={matchesMobile ? 0 : 3}>
            {!isPopup && isSeatMapOpen ? null : (
              <div
                className={clsx("seat-selection-wrapper", {
                  "seats-selected": selectedSeatSegments.length > 0,
                  desktop: isHopper,
                })}
              >
                <div
                  className={clsx("seat-selection-title-and-pricing-section", {
                    "full-width": selectedSeatSegments.length > 0 || isHopper,
                  })}
                >
                  {isHopper ? (
                    selectedSeatSegments.length === 0 &&
                    getSummaryPanel?.(undefined)
                  ) : (
                    <>
                      <Typography variant="h6" className="seat-selection-title">
                        {headerText}
                      </Typography>
                      <div className="seat-selection-card">
                        {!disabled &&
                        cheapestSeat &&
                        selectedSeatSegments.length === 0 ? (
                          <Typography
                            className="cheapest-seat-string"
                            variant="body1"
                          >
                            <I18nMarkup
                              tKey={
                                cheapestSeat.fiat.value === 0
                                  ? "seats.freeCheapestString"
                                  : "seats.cheapestSeatPerTraveler"
                              }
                              replacements={{
                                price: formatFiatCurrency(cheapestSeat.fiat),
                              }}
                            />
                          </Typography>
                        ) : null}
                      </div>
                    </>
                  )}

                  {!editSeatsFromReview && selectedSeatSegments.length > 0 ? (
                    getSummaryPanel ? (
                      getSummaryPanel(onEditClick)
                    ) : (
                      <>
                        <hr className="seat-selection-divider" />
                        <Typography
                          className="total-pricing-string"
                          variant="body2"
                        >
                          <I18nMarkup
                            tKey={"seats.totalSeatPricing"}
                            replacements={{
                              count: passengers.length,
                              price: formatCurrency(totalSeatPricing),
                            }}
                          />
                        </Typography>
                        <SelectedSeatsConfirmation
                          outboundSeatSegments={outboundSegmentsSeats}
                          returnSeatSegments={returnSegmentsSeats}
                          onEditClick={onEditClick}
                          outboundOriginCode={tripDetails.slices[0].originCode}
                          returnOriginCode={
                            tripDetails.slices.length > 1
                              ? tripDetails.slices[1].originCode
                              : undefined
                          }
                          airports={airports}
                          isMobile={matchesMobile}
                        />
                      </>
                    )
                  ) : null}
                </div>

                {!isHopper &&
                !matchesMobile &&
                selectedSeatSegments.length === 0 ? (
                  <ActionButton
                    className="seat-selection-select-button"
                    defaultStyle={"h4r-secondary"}
                    onClick={onRequestSeatMap}
                    message={t("seats.buttonText")}
                    disabled={disabled}
                  />
                ) : null}

                {isHopper &&
                !matchesMobile &&
                selectedSeatSegments.length === 0 ? (
                  <Box className="seats-cta-container">
                    <ActionButton
                      className="seat-selection-select-button hopper"
                      defaultStyle={"h4r-secondary"}
                      onClick={onRequestSeatMap}
                      message={t("seats.buttonText")}
                      disabled={disabled}
                    />
                    <ActionButton
                      disableElevation
                      className="mobile-seat-selection-skip-button hopper"
                      onClick={skipSeatSelection}
                      message={t("seats.continueWithoutSeats")}
                    />
                  </Box>
                ) : null}
              </div>
            )}

            {!matchesMobile ? (
              <>
                {!isHopper && (
                  <Box
                    className={clsx("skip-seat-selection-radio-container", {
                      "seats-selected": selectedSeats.length > 0,
                    })}
                    aria-labelledby="skip-seat-selection"
                    onClick={() => {
                      trackEvent({
                        eventName: SKIPPED_SEAT_SELECTION,
                        properties: {},
                      });
                      setSkipSeatSelection();
                    }}
                  >
                    <Radio
                      name="skip-seat-selection"
                      checked={skippedSeatSelection}
                      disabled={disabled}
                    />
                    <Box
                      className="skip-seat-selection-radio-text"
                      id="skip-seat-selection"
                    >
                      <Typography
                        variant="body1"
                        className="skip-seat-selection-title"
                      >
                        {t("seats.skipSeatsSubtitle")}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {!isHopper && (
                  <MobileFloatingButton
                    className="seat-selection-continue-button"
                    wrapperClassName={clsx(
                      "seat-selection-continue-button-container",
                      className
                    )}
                    onClick={onGoNext}
                    disabled={
                      !(selectedSeats.length > 0) && !skippedSeatSelection
                    }
                  >
                    {t("continue")}
                  </MobileFloatingButton>
                )}
              </>
            ) : !isSeatMapOpen ? (
              <div>
                {isHopper ? (
                  <Box className="cta-container">
                    {selectedSeatSegments.length === 0 && (
                      <ActionButton
                        disableElevation
                        className="mobile-seat-selection-skip-button"
                        onClick={skipSeatSelection}
                        message={t("seats.continueWithoutSeats")}
                      />
                    )}

                    <ActionButton
                      disableElevation
                      className="seat-selection-select-button"
                      onClick={onClickMobileCTA}
                      message={
                        selectedSeats.length > 0
                          ? t("continue")
                          : t("seats.selectYourSeats")
                      }
                    />
                  </Box>
                ) : (
                  <>
                    <MobileFloatingButton
                      className={clsx("mobile-seat-selection-continue-button", {
                        docked: selectedSeatSegments.length > 0,
                      })}
                      wrapperClassName={clsx(
                        "mobile-seat-selection-continue-button-container",
                        className
                      )}
                      floatingBoxClassName={
                        selectedSeats.length > 0 ? "selected-seats" : undefined
                      }
                      onClick={onClickMobileCTA}
                      wide
                    >
                      {selectedSeats.length > 0
                        ? t("continue")
                        : t("seats.selectYourSeats")}
                    </MobileFloatingButton>
                    {selectedSeatSegments.length > 0 ? null : (
                      <MobileFloatingButton
                        className="mobile-seat-selection-skip-button"
                        wrapperClassName={clsx(
                          "mobile-seat-selection-skip-button-container",
                          className
                        )}
                        onClick={skipSeatSelection}
                        wide
                      >
                        {t("seats.continueWithoutSeats")}
                      </MobileFloatingButton>
                    )}
                  </>
                )}
              </div>
            ) : null}
          </Box>
        </Paper>
      </div>
    </SeatSelectionContainer>
  );
};

const MobileBackButton = ({ onGoBack }: { onGoBack: () => void }) => (
  <BackButton className="seat-checkout-popup-back-button" onClick={onGoBack} />
);
