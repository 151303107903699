import { EventObject } from "xstate";
import { useCallback, useContext, useEffect } from "react";
import {
  ParentState,
  useCheckoutState,
  ContactSelectors,
  FlightPassengerSelectorsV2,
  getParentState,
  getChildState,
  CartQuoteState,
  CartSelectors,
  parseFlightsQueryString,
  FlightContextV2,
} from "@hopper-b2b/checkout";
import { encryptMessage } from "@hopper-b2b/utilities";
import { useHistory } from "react-router";
import queryStringParser from "query-string";
import { isEqual } from "lodash-es";

import config from "../../utils/config";
import { UserContext } from "../../App";

export const useUpdateCheckoutUrl = () => {
  const [state] = useCheckoutState<EventObject, FlightContextV2>();
  const { sessionInfo } = useContext(UserContext);
  const history = useHistory();

  const updateUrlParams = useCallback(async () => {
    const currentParsedQuery = queryStringParser.parse(history.location.search);
    const parentState = getParentState(state.value);
    const childState = getChildState(state.value);

    switch (parentState) {
      // step after ParentState.contactInformation (add contact info data)
      case ParentState.passengerInformation: {
        const email = ContactSelectors.getEmailAddress({
          context: state.context,
        }) as string;
        const { encodedString: encryptedEmail } = await encryptMessage(
          email,
          sessionInfo?.userInfoResponse?.userId || "",
          config.ENCRYPT_SALT,
          config.ENCRYPT_IV
        );
        const phone = ContactSelectors.getFormattedPhoneNumber({
          context: state.context,
        });
        const { encodedString: encryptedPhone } = await encryptMessage(
          phone,
          sessionInfo?.userInfoResponse?.userId || "",
          config.ENCRYPT_SALT,
          config.ENCRYPT_IV
        );

        const { email: currentEmail, phone: currentPhone } =
          parseFlightsQueryString(history);
        if (
          currentEmail !== encryptedEmail ||
          currentPhone !== encryptedPhone
        ) {
          history.replace({
            search: queryStringParser.stringify({
              ...currentParsedQuery,
              email: encryptedEmail,
              phone: encryptedPhone,
            }),
          });
        }
        break;
      }

      // step after ParentState.passengerInformation (add selected passenger ids)
      case ParentState.cartQuote: {
        switch (childState) {
          case CartQuoteState.schedule: {
            const {
              selectedPassengerIds: currentSelectedPassengerIds,
              selectedLapInfantIds: currentSelectedLapInfantIds,
            } = parseFlightsQueryString(history);

            const selectedPassengerIds =
              FlightPassengerSelectorsV2.getSelectedPassengerIdsParent({
                context: state.context,
              });
            const selectedLapInfantIds =
              FlightPassengerSelectorsV2.getSelectedLapInfantIdsParent({
                context: state.context,
              });

            if (
              !isEqual(
                currentSelectedPassengerIds.sort(),
                selectedPassengerIds.sort()
              ) ||
              !isEqual(
                currentSelectedLapInfantIds.sort(),
                selectedLapInfantIds.sort()
              )
            ) {
              history.replace({
                search: queryStringParser.stringify({
                  ...currentParsedQuery,
                  selectedPassengerIds: selectedPassengerIds.join(","),
                  selectedLapInfantIds: selectedLapInfantIds.join(","),
                }),
              });
            }
            break;
          }

          case CartQuoteState.polling: {
            const { cartToken } = parseFlightsQueryString(history);

            const cipherText = CartSelectors.getPriceQuoteCipherText({
              context: state.context,
            });

            if (cartToken !== cipherText?.value) {
              history.replace({
                search: queryStringParser.stringify({
                  ...currentParsedQuery,
                  cartToken: cipherText?.value,
                }),
              });
            }
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  }, [state, history]);

  useEffect(() => {
    updateUrlParams();
    // Only run when state.value changes
  }, [state.value]);

  return null;
};
