import { PropsWithChildren } from "react";
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogProps,
  DialogContent,
  DialogContentProps,
  DialogActions,
} from "@material-ui/core";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ActionLink, IconComponent, IconName } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./HotelDetailsDialog.module.scss";
import clsx from "clsx";

export function HotelDetailsDialog({
  children,
  fullScreen,
  ...dialogProps
}: DialogProps) {
  const { matchesMobile } = useDeviceTypes();

  return (
    <Dialog
      maxWidth={"sm"}
      scroll="paper"
      fullScreen={fullScreen ?? matchesMobile}
      fullWidth
      {...dialogProps}
    >
      {children}
    </Dialog>
  );
}

export type DialogTitleWithCloseButtonProps = CloseButtonProps & {
  fullScreen?: boolean;
  titleId?: string;
};

function TitleWithCloseButton({
  className,
  children,
  titleId,
  fullScreen,
  onClose,
}: PropsWithChildren<DialogTitleWithCloseButtonProps>) {
  const { matchesMobile } = useDeviceTypes();

  return (
    <DialogTitle
      className={clsx(className, styles.dialogTitle)}
      disableTypography
    >
      <div
        className={clsx(styles.dialogTitleText, {
          fullScreen: fullScreen ?? matchesMobile,
        })}
      >
        <Typography component="h2" variant="h4" id={titleId}>
          {children}
        </Typography>
      </div>
      <CloseButton onClose={onClose} />
    </DialogTitle>
  );
}

function StyledDialogContent({
  children,
  dividers = true,
  ...dialogContentProps
}: DialogContentProps) {
  return (
    <DialogContent
      className={clsx(styles.dialogContent, "DialogContent")}
      dividers={dividers}
      {...dialogContentProps}
    >
      {children}
    </DialogContent>
  );
}

export type CloseButtonProps = {
  onClose: () => void;
  className?: string;
};

function CloseButton({ onClose, className }: CloseButtonProps) {
  const { t } = useI18nContext();

  return (
    <ActionLink
      className={clsx(styles.closeButton, className)}
      content={
        <IconComponent ariaLabel={t("closeModal")} name={IconName.Close} />
      }
      onClick={onClose}
    />
  );
}

HotelDetailsDialog.TitleWithCloseButton = TitleWithCloseButton;
HotelDetailsDialog.CloseButton = CloseButton;
HotelDetailsDialog.DialogContent = StyledDialogContent;
HotelDetailsDialog.DialogActions = DialogActions;
