import { Suspense, useContext, useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core";
import { Navigate, Route, Routes } from "react-router-dom-v5-compat";
import clsx from "clsx";

import { useExperiment, useTrackingProperties } from "@hopper-b2b/experiments";
import { SlotProvider, Slots } from "@hopper-b2b/ui";
import {
  LaunchedApplicationProperties,
  TripsClientAssetProps,
} from "@hopper-b2b/types";
import { logger, trackEvent } from "@hopper-b2b/api";
import TripsApp, { UberTripsList } from "@hopper-b2b/trips";
import {
  FeatureFlagsContextProps,
  getEnvVariables,
  getLandingScreen,
  useLaunchedApplicationEvent,
} from "@hopper-b2b/utilities";
import { ChatPropertiesType } from "@b2bportal/chat-api";

import { EXPERIMENTS, FEATURE_FLAGS, UserContext } from "../../App";
import { colors, useDarkModePreferred } from "../../utils/colors";
import config from "../../utils/config";
import {
  apiConfig,
  PATH_HOME,
  PATH_HOTELS_SEARCH,
  PATH_LANDING,
  PATH_TRIPS,
  ROUTE_FLIGHTS,
  ROUTE_HOTELS,
} from "../../utils/urlPaths";
import Maintenance from "../Maintenance/maintenance";
import {
  AddTravelerFormSelect,
  ContactInfoForm,
  ContactSupportModalContent,
  ExchangeLandingScreen,
  FareDetailsSliceHeader,
  FiltersModal,
  FilterTitle,
  FlightCardExpandedSection,
  FlightCheckoutCtaSection,
  FlightNumberFilterSelection,
  FlightPriceDropProtectionBanner,
  FlightSearchMobileHeaderLeftContent,
  FlightShopLocationDestinationAutocomplete,
  FlightShopLocationOriginAutocomplete,
  FlightShopReviewMultiticketBanner,
  FlightShopSummaryPanel,
  FrequentFlyerDropdown,
  GalleryGrid,
  GalleryHeader,
  HotelCancellationConfirmationScreen,
  HotelCancellationSummaryPanel,
  HotelCheckoutCtaSection,
  HotelDetailsPolicies,
  HotelDetailsReview,
  HotelHeader,
  HotelListMapCard,
  HotelShopMap,
  HotelVoucherOfferBanner,
  InputAdornmentLearnMore,
  Loader,
  LoadingScreen,
  LodgingCardCashBack,
  LodgingSearch,
  LodgingSearchMobileGuestModal,
  MixedCabinHalfsheet,
  MobileActionLinks,
  MobileFlightPricePredictionContent,
  NoResults,
  NubankFlightCard,
  PassportCountrySelect,
  PriceBreakdownAdditionalInfo,
  RequiredAssistanceSelect,
  RoomDetailsCta,
  RoomDetailsHeader,
  RoomDetailsModalHeader,
  RoomDetailsSelect,
  RoomGallery,
  SearchModal,
  SelfServeFlightInfo,
  ShopHeader,
  TripDetailsSummary,
  TripsCard,
  TripsConfirmationCopyAndGoButton,
  TripsErrorModalContent,
} from "../Slots";
import { SharedFlightCtaSection } from "../SharedFlightCtaSection";
import { ConfirmationScreen } from "../ConfirmationScreen";
import { ErrorScreen } from "../ErrorScreen";
import { darkModeIcons, lightModeIcons } from "./sharedIcons";
import FlightsRouteComponent from "./FlightsRouteComponent";
import LodgingRouteComponent from "./LodgingRouteComponent";
import LodgingSearchRoute from "./LodgingSearchRoute";
import HomeRouteComponent from "./HomeRouteComponent";
import useStyles from "./styles";
import "./styles.scss";
import { HotelSearchAutocomplete } from "../Slots/HotelSearchAutocomplete";

const slots: Slots = {
  "flight-shop-loading-screen": LoadingScreen,
  "trips-list-loading-screen": Loader,
  "mobile-flight-price-prediction-content": MobileFlightPricePredictionContent,
  "flight-shop-list-mobile-card": NubankFlightCard,
  "flight-shop-list-expanded-section": FlightCardExpandedSection,
  "fare-details-slice-header": FareDetailsSliceHeader,
  "flight-shop-cta-section": SharedFlightCtaSection,
  "mobile-flight-number-filter-selection": FlightNumberFilterSelection,
  "mobile-flight-filter-title": FilterTitle,
  "add-traveler-form-select": AddTravelerFormSelect,
  "checkout-contact-info-form": ContactInfoForm,
  "passenger-frequent-flyer-select": FrequentFlyerDropdown,
  "passenger-required-assistance-select": RequiredAssistanceSelect,
  "passenger-passport-country-select": PassportCountrySelect,
  "passenger-input-field-learn-more": InputAdornmentLearnMore,
  "flight-review-mixed-cabin-tooltip": MixedCabinHalfsheet,
  "flight-shop-summary-panel": FlightShopSummaryPanel,
  "trip-details-links": MobileActionLinks,
  "trip-details-summary": TripDetailsSummary,
  "empty-trips-list": NoResults,
  "flight-price-drop-protection-banner": FlightPriceDropProtectionBanner,
  "flight-shop-location-search-destination-autocomplete":
    FlightShopLocationDestinationAutocomplete,
  "flight-shop-location-search-origin-autocomplete":
    FlightShopLocationOriginAutocomplete,
  "flight-search-mobile-header-left-content":
    FlightSearchMobileHeaderLeftContent,
  "price-breakdown-additional-info": PriceBreakdownAdditionalInfo,
  "flight-shop-review-multiticket-banner": FlightShopReviewMultiticketBanner,

  // Self-serve
  "self-serve-cancellation-flight-info": SelfServeFlightInfo,
  "self-serve-cancellation-error-screen": ErrorScreen,
  "exchange-loader": Loader,
  "exchange-error-screen": ErrorScreen,
  "exchange-contact-airline-screen": SelfServeFlightInfo,
  "exchange-landing-screen": ExchangeLandingScreen,
  "exchange-request-confirmation-screen": ConfirmationScreen,
  "trips-confirmation-modal-copy-and-go-button":
    TripsConfirmationCopyAndGoButton,
  "trips-error-modal-content": TripsErrorModalContent,
  "hotel-cancellation-confirmation-screen": HotelCancellationConfirmationScreen,

  // Hotels
  "hotel-shop-filters-modal": FiltersModal,
  "lodging-details-screen-header": HotelHeader,
  "hotel-shop-header": ShopHeader,
  "hotel-shop-search-modal": SearchModal,
  "lodging-availability-search": LodgingSearch,
  "hotel-details-gallery-header": GalleryHeader,
  "hotel-details-gallery-grid": GalleryGrid,
  "hotel-details-policies": HotelDetailsPolicies,
  "hotel-details-review": HotelDetailsReview,
  "hotel-shop-map": HotelShopMap,
  "hotel-room-details-header": RoomDetailsHeader,
  "hotel-room-details-select": RoomDetailsSelect,
  "hotel-room-details-modal-header": RoomDetailsModalHeader,
  "hotel-room-details-gallery": RoomGallery,
  "hotel-room-details-cta": RoomDetailsCta,
  "lodging-search-mobile-guest-modal": LodgingSearchMobileGuestModal,
  "lodging-card-cash-back": LodgingCardCashBack,
  "hotel-list-map-card": HotelListMapCard,
  "hotel-voucher-offer-banner": HotelVoucherOfferBanner,
  "hotel-cancellation-summary-panel": HotelCancellationSummaryPanel,
  "hotel-cancellation-loader": LoadingScreen,
  "hotel-search-autocomplete": HotelSearchAutocomplete,

  // Trips
  "mytrips-list": UberTripsList,
  "mytrips-trip-card": TripsCard,
  "mytrips-contact-support-modal-content": ContactSupportModalContent,
};

interface IBodyProps {
  theme: Theme;
  className?: string;
  isFirstSession: boolean;
  launchEventSent: boolean;
  setLaunchEventSent: (val: boolean) => void;
  openSupportChat: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
  onDarkModeChange: (val: boolean) => void;
}

const Body = (props: IBodyProps) => {
  const {
    theme,
    className,
    openSupportChat,
    launchEventSent,
    setLaunchEventSent,
    onDarkModeChange,
  } = props;
  const classes = useStyles();
  const isInMaintenance = useExperiment(FEATURE_FLAGS.MAINTENANCE);

  // Fintech Flags
  const enablePriceWatch = useExperiment(FEATURE_FLAGS.AIR_PRICE_WATCH);
  const enableScheduleChange = useExperiment(FEATURE_FLAGS.AIR_DISRUPTION);
  const enableMissedConnection = useExperiment(
    FEATURE_FLAGS.AIR_MISSED_CONNECTION
  );
  const enableCfar = useExperiment(FEATURE_FLAGS.AIR_CFAR);
  const enableChfar = useExperiment(FEATURE_FLAGS.AIR_CHFAR);
  const enableVipSupport = useExperiment(FEATURE_FLAGS.VIP_SUPPORT);
  const enableDisruptionExerciseRebooking = useExperiment(
    FEATURE_FLAGS.AIR_DISRUPTION_EXERCISE_REBOOKING
  );
  const enablePricePrediction = useExperiment(FEATURE_FLAGS.PRICE_PREDICTION);
  const enablePriceDropProtection = useExperiment(
    FEATURE_FLAGS.PRICE_DROP_PROTECTION
  );

  // Features
  const enableAirExchange = useExperiment(FEATURE_FLAGS.AIR_EXCHANGE);
  const enableDarkMode = useExperiment(FEATURE_FLAGS.APP_DARKMODE);
  const enableAirShopV4 = true; //Default should use v4
  const enableLoadingSteps = useExperiment(FEATURE_FLAGS.LOADING_STEPS);
  const enableLodging = useExperiment(FEATURE_FLAGS.LODGING);
  const enableHFv2 = useExperiment(FEATURE_FLAGS.HFv2);
  const enableWallet = useExperiment(FEATURE_FLAGS.WALLET);
  const enableLodgingMap = useExperiment(FEATURE_FLAGS.SHOW_LODGING_MAP);

  // Experiments
  const hideAirItineraryReview = useExperiment(
    EXPERIMENTS.HIDE_AIR_ITINERARY_REVIEW
  );
  const hideFareDetails = useExperiment(EXPERIMENTS.HIDE_FARE_DETAILS);
  const enableFintechSelection = useExperiment(
    EXPERIMENTS.AIR_FINTECH_SELECTION
  );
  const hideSeats = useExperiment(EXPERIMENTS.HIDE_SEATS);

  const trackingProperties: LaunchedApplicationProperties =
    useTrackingProperties({
      landing_screen: getLandingScreen(),
      url: document.location.pathname,
    });

  const launchSent = useLaunchedApplicationEvent(
    launchEventSent,
    trackingProperties,
    trackEvent
  );

  useEffect(() => {
    if (launchSent) {
      setLaunchEventSent(true);
    }
  }, [launchSent, setLaunchEventSent]);

  const isDarkMode = useDarkModePreferred();

  const featureFlags: FeatureFlagsContextProps = useMemo(
    () => ({
      enableAirExchange,
      enableCfar,
      enableChfar,
      enableDarkMode: enableDarkMode && isDarkMode,
      enableFintechSelection,
      enableMissedConnection,
      enablePriceWatch,
      enableScheduleChange,
      enableVipSupport,
      enableDisruptionExerciseRebooking,
      hideAirItineraryReview,
      hideFareDetails,
      hideSeats,
      enableAirShopV4,
      enableLoadingSteps,
      enablePricePrediction,
      enableLodging,
      enablePriceDropProtection,
      enableHFv2,
      enableWallet,
      hideLodgingMap: enableLodgingMap !== true,
    }),
    [
      enableAirExchange,
      enableCfar,
      enableChfar,
      enableDarkMode,
      isDarkMode,
      enableFintechSelection,
      enableMissedConnection,
      enablePriceWatch,
      enableScheduleChange,
      enableVipSupport,
      enableDisruptionExerciseRebooking,
      hideAirItineraryReview,
      hideFareDetails,
      hideSeats,
      enableAirShopV4,
      enableLoadingSteps,
      enablePricePrediction,
      enableLodging,
      enablePriceDropProtection,
      enableHFv2,
      enableWallet,
      enableLodgingMap,
    ]
  );

  useEffect(() => {
    if (enableDarkMode && isDarkMode) {
      onDarkModeChange(true);
    }
  }, [enableDarkMode, isDarkMode, onDarkModeChange]);

  // Adding DD RUM tracking for non-prod environments ONLY

  useEffect(() => {
    if (config.ENV !== "production") {
      const promise = document?.hasStorageAccess?.();
      promise?.then(
        function (hasAccess) {
          logger().info(`hasStorageAccess - success: ${hasAccess}`);
        },
        function (reason) {
          logger().info(`hasStorageAccess - failed: ${reason}`);
        }
      );
      if (!promise) {
        logger().info("hasStorageAccess does not exist on document");
      }
    }
  }, []);

  return (
    <main
      className={clsx(
        "main-body",
        classes.main,
        className,
        getEnvVariables("clientName")
      )}
    >
      {isInMaintenance ? (
        <Maintenance />
      ) : (
        <Grid className={classes.content}>
          <Routes>
            {/* TODO: Add landing page */}
            <Route
              path={"/*"}
              element={<Navigate to={PATH_LANDING} replace={true} />}
            />
            <Route
              path={PATH_HOME}
              element={<HomeRouteComponent featureFlags={featureFlags} />}
            />
            <Route
              path={ROUTE_FLIGHTS}
              element={
                <Suspense>
                  <FlightsRouteComponent
                    featureFlags={featureFlags}
                    theme={theme}
                    slots={{
                      ...slots,
                      "mobile-checkout-cta-section": FlightCheckoutCtaSection,
                    }}
                  />
                </Suspense>
              }
            />
            <Route
              path={PATH_HOTELS_SEARCH}
              element={
                <Suspense>
                  <LodgingSearchRoute />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_HOTELS}
              element={
                <Suspense>
                  {/* TODO: nubank loading screen */}
                  <LodgingRouteComponent
                    //Do not use client assets, use TenantContext for icons and images
                    featureFlags={{
                      ...featureFlags,
                      showHorizontalScrollListInMobileMap: true,
                    }}
                    slots={{
                      ...slots,
                      "mobile-checkout-cta-section": HotelCheckoutCtaSection,
                    }}
                  />
                </Suspense>
              }
            />

            <Route
              path={PATH_TRIPS}
              element={
                <TripRoute
                  featureFlags={featureFlags}
                  openSupportChat={openSupportChat}
                  theme={theme}
                />
              }
            />
          </Routes>
        </Grid>
      )}
    </main>
  );
};

export default Body;

interface INubankRoute {
  theme: Theme;
  featureFlags: FeatureFlagsContextProps;
}

interface INubankTripsRoute extends INubankRoute {
  openSupportChat: () => void;
}

const TripRoute = ({
  theme,
  featureFlags,
  openSupportChat,
}: INubankTripsRoute) => {
  const { sessionInfo } = useContext(UserContext);
  const isDarkMode = useDarkModePreferred();
  const tripsAppClientAssets: TripsClientAssetProps = useMemo(
    () => ({
      sessionInfo: sessionInfo,
      featureFlag: {},
      assets: isDarkMode ? darkModeIcons : lightModeIcons,
    }),
    [isDarkMode, sessionInfo]
  );

  return (
    <SlotProvider slots={slots}>
      <TripsApp
        apiConfig={apiConfig}
        clientAssets={tripsAppClientAssets}
        featureFlags={featureFlags}
        colors={colors}
        theme={theme}
        onSupportClick={openSupportChat}
      />
    </SlotProvider>
  );
};
