import { useI18nContext } from "@hopper-b2b/i18n";
import { AirportMap, FareDetails, TripDetails } from "@hopper-b2b/types";
import { FlightCombinationBanner, Slot } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { ExpandableFlightCard } from "../../ExpandableFlightCard/ExpandableFlightCard";
import "./DesktopReviewContent.scss";

const DEPARTURE_KEY = "departure";
const RETURN_KEY = "return";

type DesktopReviewContentProps = {
  isMultiTicket: boolean;
  isOneWay: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  fareDetails: FareDetails;
  tripDetails: TripDetails;
  airports: AirportMap;
  isOutgoingMixedClass: boolean;
  isReturnMixedClass: boolean;
  onChangeFlight?: (isOutbound: boolean) => void;
  showChangeFlight: boolean;
  expanded?: boolean;
  hideTripReviewCombinationBanner?: boolean;
};

export const DesktopReviewContent = ({
  isMultiTicket,
  isOneWay,
  departureDate,
  returnDate,
  fareDetails,
  tripDetails,
  airports,
  isOutgoingMixedClass,
  isReturnMixedClass,
  onChangeFlight,
  showChangeFlight,
  expanded = false,
  hideTripReviewCombinationBanner,
}: DesktopReviewContentProps) => {
  const { t } = useI18nContext();

  const showReturnFlightSummary = useMemo(
    () => !isOneWay && returnDate && fareDetails,
    [fareDetails, isOneWay, returnDate]
  );

  const onChangeDepartureFlight = useCallback(() => {
    onChangeFlight?.(true);
  }, [onChangeFlight]);

  const onChangeReturnFlight = useCallback(() => {
    onChangeFlight?.(false);
  }, [onChangeFlight]);

  return (
    <Box className="itinerary-cards-section review-itinerary-cards-section">
      {isMultiTicket && (
        <FlightCombinationBanner
          className={clsx("review-itinerary-flight-combo-banner", "b2b")}
          description={t("combinationFlightWarning")}
          toolTipCopy={t("combinationFlightTooltip")}
        />
      )}
      <Box className="review-itinerary-cards-title-container" my={3}>
        <Typography variant="h4" className="review-itinerary-title">
          {t("reviewItineraryTitle")}
        </Typography>
        {!hideTripReviewCombinationBanner && (
          <Slot
            id="trip-review-combination-banner"
            airports={airports}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
          />
        )}
      </Box>
      {departureDate && fareDetails && (
        <Box className={clsx("flight-shop-review-itinerary-card", "departure")}>
          <ExpandableFlightCard
            cardKey={DEPARTURE_KEY}
            expanded={expanded}
            departure={true}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            airports={airports}
            isHackerFare={isMultiTicket}
            isMixedCabinClass={isOutgoingMixedClass}
            onChangeFlight={onChangeDepartureFlight}
            showChangeFlight={showChangeFlight}
          />
        </Box>
      )}
      {showReturnFlightSummary && (
        <Box className={clsx("flight-shop-review-itinerary-card", "return")}>
          <ExpandableFlightCard
            cardKey={RETURN_KEY}
            expanded={expanded}
            departure={false}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            airports={airports}
            isHackerFare={isMultiTicket}
            isMixedCabinClass={isReturnMixedClass}
            onChangeFlight={onChangeReturnFlight}
            showChangeFlight={showChangeFlight}
          />
        </Box>
      )}
    </Box>
  );
};
