import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { Breadcrumbs, BreadcrumbStep } from "@hopper-b2b/ui";
import {
  useEnableCfar,
  useEnableChfar,
  useEnableMissedConnection,
  useEnableScheduleChange,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getTripCategory } from "../../../search/reducer";
import { useUpdateFlightShopStep } from "../../hooks";
import { flightShopProgressSelector, FlightShopStep } from "../../reducer";

export interface IFlightShopProgressBarProps {
  isReviewItineraryHidden?: boolean;
}

export const FlightShopProgressBar = ({
  isReviewItineraryHidden,
}: IFlightShopProgressBarProps) => {
  const { t } = useI18nContext();

  const tripCategory = useSelector(getTripCategory);

  const isOneWay = tripCategory === TripCategory.ONE_WAY;

  const currentProgress = useSelector(flightShopProgressSelector);
  const updateFlightShopStep = useUpdateFlightShopStep();

  const showFlightCfar = useEnableCfar();
  const showFlightChfar = useEnableChfar();
  const showFlightMissedConnection = useEnableMissedConnection();
  const showFlightScheduleChange = useEnableScheduleChange();

  const hasFintech =
    showFlightCfar ||
    showFlightChfar ||
    showFlightMissedConnection ||
    showFlightScheduleChange;

  const onClickHandler = useCallback(
    (progress: FlightShopStep) => {
      if (progress !== currentProgress) {
        updateFlightShopStep(progress, true);
      }
    },
    [currentProgress, updateFlightShopStep]
  );

  const shopSteps: BreadcrumbStep[] = useMemo(
    () => [
      {
        key: "chooseDeparture",
        label: t("flightShopProgress.departure"),
        onClick: () => onClickHandler(FlightShopStep.ChooseDeparture),
        isSelected: currentProgress === FlightShopStep.ChooseDeparture,
        disabled: false,
        visited: true,
      },
      {
        key: "chooseReturn",
        label: t("flightShopProgress.return"),
        onClick: () => onClickHandler(FlightShopStep.ChooseReturn),
        hide: isOneWay,
        isSelected: currentProgress === FlightShopStep.ChooseReturn,
        disabled: currentProgress < FlightShopStep.ChooseReturn,
        visited: true,
      },
      {
        key: "reviewItinerary",
        label: t("flightShopProgress.reviewItinerary"),
        onClick: () => onClickHandler(FlightShopStep.ReviewItinerary),
        hide: isReviewItineraryHidden,
        isSelected: currentProgress === FlightShopStep.ReviewItinerary,
        disabled: currentProgress < FlightShopStep.ReviewItinerary,
        visited: true,
      },
      {
        key: "fintechSelection",
        label: t("flightShopProgress.customize"),
        onClick: () => onClickHandler(FlightShopStep.FintechSelection),
        hide: !hasFintech,
        isSelected: currentProgress === FlightShopStep.FintechSelection,
        disabled: currentProgress < FlightShopStep.FintechSelection,
        visited: true,
      },
    ],
    [
      t,
      currentProgress,
      isOneWay,
      isReviewItineraryHidden,
      hasFintech,
      onClickHandler,
    ]
  );

  return <Breadcrumbs steps={shopSteps} showBack={true} />;
};
