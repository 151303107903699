import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  ChfarTrackingEvents,
} from "@hopper-b2b/types";
import {
  combineTrackingProperties,
  useEnableChfar,
  useEnableChfarHackerFare,
} from "@hopper-b2b/utilities";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedChfarOffer } from "../../../../chfar/actions/actions";
import {
  getChfarOffers,
  getChfarTrackingProperties,
  getSelectedChfarOffer,
  getSelectedChfarTrackingProperties,
  isChfarOffersValid,
} from "../../../../chfar/reducer";
import { useUpdateQueryParams } from "../../../hooks";
import { SingleOptionFintechCard } from "../SingleOptionFintechCard";
import { FintechOptionCardProps } from "../types";
import { isReturnFareHackerFareSelector } from "../../../reducer";
import { Product } from "@b2bportal/purchase-api";

export type ChfarFintechOptionCard = {
  onChange: () => void;
} & FintechOptionCardProps;

export const ChfarFintechOptionCard = ({ onChange, disabled }) => {
  const { t, formatCurrency } = useI18nContext();

  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  const enableChfar = useEnableChfar();
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const hideFlightChfarHackerFare = useEnableChfarHackerFare();
  const isHackerFareEnabledWithChfar = isReturnFareHackerFare
    ? hideFlightChfarHackerFare
    : true; //If HackerFare is disabled/not selected, always return true
  const hasValidChfarOffers = useSelector(isChfarOffersValid(false));
  const showChfarFintechOptionCard =
    enableChfar && hasValidChfarOffers && isHackerFareEnabledWithChfar;

  const chfarOffer = useSelector(getChfarOffers)?.[0];

  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  const chfarTrackingProperties = useSelector(getChfarTrackingProperties);
  const selectedChfarTrackingProperties = useSelector(
    getSelectedChfarTrackingProperties
  );

  const pricePerPax = useMemo(
    () =>
      formatCurrency(chfarOffer?.premiumAmount?.fiat?.value || 0, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [chfarOffer?.premiumAmount?.fiat?.value, formatCurrency]
  );

  useEffect(() => {
    updateQueryParams(
      {
        [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedChfarOffer?.quoteId,
      },
      true
    );
  }, [selectedChfarOffer?.quoteId, updateQueryParams]);

  const onSelect = useCallback(
    (showChfar: boolean) => {
      if (showChfar) {
        trackEvent({
          eventName: ChfarTrackingEvents.ADDED_CHFAR,
          ...combineTrackingProperties([selectedChfarTrackingProperties]),
        });
      }
      onChange();
      dispatch(setSelectedChfarOffer(showChfar ? chfarOffer : undefined));
    },
    [chfarOffer, dispatch, onChange, selectedChfarTrackingProperties]
  );

  const onShow = useCallback(
    (show: boolean) => {
      if (show) {
        trackEvent({
          eventName: ChfarTrackingEvents.VIEWED_CHFAR,
          ...combineTrackingProperties([chfarTrackingProperties]),
        });
      }
    },
    [chfarTrackingProperties]
  );

  return showChfarFintechOptionCard ? (
    <SingleOptionFintechCard
      product={Product.AirChfar}
      title={t?.("chOffer.title")}
      description={t?.("fintechSelection.chfarDescription")}
      checked={!!selectedChfarOffer}
      setChecked={onSelect}
      iconName="airplaneDepartChfar"
      pricePerPax={pricePerPax}
      icons={["ticket", "arrowFork"]}
      disabled={disabled}
      onShow={onShow}
    />
  ) : null;
};
