import clsx from "clsx";
import "./styles.scss";

export type HotelHeaderProps = {
  children: JSX.Element;
};

export function HotelHeader({ children }: HotelHeaderProps) {
  return (
    <div className={clsx("HotelHeader", "lodging-details-header")}>
      {children}
    </div>
  );
}
