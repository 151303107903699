import { RoomInfo } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { ActionButton, Carousel, Slot } from "@hopper-b2b/ui";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useId } from "react";
import { useSelector } from "react-redux";
import { useTrackEvents } from "../../../../../../tracking";
import { getSelectedRoomId } from "../../../../reducer/selectors";
import { AmenitiesList } from "../../../AmenitiesList";
import { HotelDetailsDialog } from "../../../HotelDetailsDialog";
import styles from "./DetailsAndAmenitiesModal.module.scss";

interface Props extends RoomInfo {
  open: boolean;
  onClose(): void;
  continueRoomBooking?: () => void;
  ctaLabel?: string;
}

export function DetailsAndAmenitiesModal({
  open,
  name,
  description,
  amenities,
  maxAdults,
  beds,
  onClose,
  continueRoomBooking,
  media,
  roomId,
  ctaLabel,
}: Props) {
  const titleId = useId();
  const { t } = useI18nContext();
  const icons = useTenantIcons();
  const trackEvent = useTrackEvents();
  const selectedRoomId = useSelector(getSelectedRoomId);

  useEffect(() => {
    if (open && selectedRoomId === roomId) {
      trackEvent(LodgingShopTrackingEvents.hotel_viewed_bed_types);
    }
  }, [selectedRoomId, roomId, trackEvent, open]);

  const roomImages = media?.map((asset) => asset.url);

  return (
    <HotelDetailsDialog
      aria-labelledby={titleId}
      open={open}
      onClose={onClose}
      classes={{ root: "lodging-details-modal", paper: styles.Dialog }}
    >
      <HotelDetailsDialog.TitleWithCloseButton
        className={clsx(
          styles.LodgingDetailsModalHeader,
          "LodingDetailsModalHeader"
        )}
        onClose={onClose}
      >
        {t("roomDetails")}
      </HotelDetailsDialog.TitleWithCloseButton>
      <HotelDetailsDialog.DialogContent
        className={clsx(styles.DialogContent, "DialogContent")}
      >
        {roomImages ? (
          <div className={clsx(styles.Header, "Header")}>
            <Box
              className={clsx(
                styles.Carousel,
                "lodging-details-modal-carousel-container"
              )}
            >
              <Carousel imageUrlsArray={roomImages} />
            </Box>
            <Slot
              id="hotel-room-details-modal-header"
              name={name}
              maxAdults={maxAdults}
              beds={beds}
            />
          </div>
        ) : null}
        <Box
          className={clsx(styles.Info, "lodging-details-modal-info-container")}
        >
          <Typography className="title" variant="h4" gutterBottom id={titleId}>
            {name}
          </Typography>
          {maxAdults ? (
            <div className={clsx(styles.Icons, "Icons")}>
              <img src={icons.grayGuest} alt="" />
              <Typography>
                {t("roomFitsGuests", {
                  count: maxAdults,
                })}
              </Typography>
            </div>
          ) : null}
          <div className={clsx(styles.Icons, "Icons")}>
            <img src={icons.bed} alt="" />
            <Typography>{beds.description}</Typography>
          </div>

          {amenities.length > 0 ? (
            <div className={clsx(styles.AmenitiesList, "AmenitiesList")}>
              <Typography variant="h5" id={titleId}>
                {t("roomAmenities")}
              </Typography>
              <AmenitiesList amenities={amenities} />
            </div>
          ) : null}
        </Box>
        {description ? (
          <div
            className={clsx(
              styles.RoomDetailsContainer,
              "RoomDetailsContainer"
            )}
          >
            <Typography className="title" variant="h4" id={titleId}>
              {t("roomDescription")}
            </Typography>
            <Typography>
              <span
                className={clsx(styles.RoomDetails, "RoomDetails")}
                dangerouslySetInnerHTML={{ __html: description }}
              ></span>
            </Typography>
          </div>
        ) : amenities.length === 0 ? (
          <Typography>{t("noRoomDetails")}</Typography>
        ) : null}
      </HotelDetailsDialog.DialogContent>
      {continueRoomBooking ? (
        <HotelDetailsDialog.DialogActions
          className={clsx(
            styles.ContinueButtonContainer,
            "ContinueButtonContainer"
          )}
        >
          <ActionButton
            className={clsx(styles.ContinueButton, "ContinueButton")}
            onClick={continueRoomBooking}
            message={ctaLabel ? ctaLabel : t("continueRoomBooking")}
            fullWidth
          />
        </HotelDetailsDialog.DialogActions>
      ) : null}
    </HotelDetailsDialog>
  );
}
