import { ActionButton, ButtonWrap, MobilePopoverCard } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useCheckoutSend } from "@hopper-b2b/checkout";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "../../../../../assets/client/close.svg";
import { PaymentEvent } from "../../../../states/payments";

export const ConfirmPaymentModal = ({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) => {
  const { t } = useI18nContext();
  const send = useCheckoutSend();

  const onClick = () => {
    send(PaymentEvent.NEXT);
  };

  return (
    <MobilePopoverCard
      className={styles.confirmPaymentModal}
      open={open}
      centered={false}
      contentClassName="confirm-payment-modal-content"
    >
      <div className="header">
        <ButtonWrap className="back-button" onClick={closeModal}>
          <CloseIcon className="close-icon" />
        </ButtonWrap>
      </div>
      <div className="confirm-payment-content">
        <h1>{t("nupay.confirmModalHeader")}</h1>
        <p>{t("nupay.confirmModalSubtitle")}</p>

        <ActionButton
          className={"primary-button-wrapper"}
          buttonClassName={"primary-button"}
          message={t("nupay.makePaymentButton")}
          onClick={onClick}
        />
        <ActionButton
          className={"secondary-button-wrapper"}
          buttonClassName={"secondary-button"}
          message={t("cancel")}
          onClick={closeModal}
        />
      </div>
    </MobilePopoverCard>
  );
};
