import { SupportStatus } from "@hopper-b2b/types";

export enum VipSupportEventTypes {
  TOGGLE_SUPPORT_STATUS = "TOGGLE_SUPPORT_STATUS",
}
export interface ToggleSupportStatusEvent {
  type: VipSupportEventTypes.TOGGLE_SUPPORT_STATUS;
  supportStatus: SupportStatus;
}

export type VipSupportEvents = ToggleSupportStatusEvent;
