import clsx from "clsx";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  matchPath,
  Route,
  Routes,
  useLocation,
} from "react-router-dom-v5-compat";

import { ExperimentsProvider } from "@hopper-b2b/experiments";
import I18nProvider, {
  en_defaultTranslations,
  en_nubankTranslations,
  es_defaultTranslations,
  es_nubankTranslations,
  getLang,
  pt_defaultTranslations,
  pt_nubankTranslations,
} from "@hopper-b2b/i18n";
import { installCssConfig } from "@hopper-b2b/themes";
import {
  ISessionContextProps,
  SessionContextProvider,
  SessionStateEnum,
  TenantContextProvider,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";

import "./App.css";
import "./fonts.scss";
import { useWindowSize } from "./hooks/useWindowSize";
import {
  apiConfig,
  HIDDEN_FOOTER_PATHS,
  HIDDEN_HEADER_PATHS,
  HIDDEN_HEADER_PATHS_MOBILE,
  PATH_HOTELS_AVAILABILITY,
  POSITION_FIXED_FOOTER_PATHS,
  ROUTE_AUTH,
} from "./utils/urlPaths";

import { NubankSessionInfo } from "./api/session/startSession";
import { ProtectedRoute } from "./components/Auth";
import { AuthModule } from "./components/AuthModule";
import AxiosInterceptorWrapper from "./components/AxiosInterceptorWrapper";
import Body from "./components/Body";
import { B2BGenericFooter } from "./components/Footer";
import { branding } from "./modules/branding";

import { muiDarkTheme, nubankDarkVariables } from "./modules/darkTheme";
import { muiTheme, nubankVariables } from "./modules/defaultTheme";
import {
  muiUltravioletaTheme,
  nubankUltravioletaVariables,
} from "./modules/ultravioletaDefaultTheme";

import Header from "./components/Header";
import { darkModeIcons, lightModeIcons } from "./components/Body/sharedIcons";
import {
  createGeneralSupportConversation,
  createSupportConversationForProduct,
  SupportContextProvider,
} from "@hopper-b2b/self-serve";
import config from "./utils/config";
import chatProperties from "./api/support/support";
import { ChatPropertiesType } from "@b2bportal/chat-api";
import {
  isUserTypeCookieUltravioleta,
  useDarkModePreferred,
} from "./utils/colors";
import { Translation, TranslationLanguage } from "@hopper-b2b/types";
import {
  muiUltravioletaDarkTheme,
  nubankUltravioletaDarkVariables,
} from "./modules/ultravioletaDarkTheme";
import {
  en_terms,
  pt_terms,
  TermsAndConditionsWrapper,
} from "./components/TermsAndConditionsWrapper";
import { ImpersonationBanner } from "./components/ImpersonationBanner";
import { merge } from "lodash-es";

// Nubank Feature Flags (copied from Uber)
export enum FEATURE_FLAGS {
  MAINTENANCE = "nubank-maintenance",

  // Fintech Flags
  AIR_CFAR = "nubank-cfar",
  AIR_CHFAR = "nubank-chfar",
  AIR_DISRUPTION = "nubank-disruption",
  AIR_DISRUPTION_EXERCISE_REBOOKING = "nubank-air-disruption-exercise-rebooking",
  AIR_MISSED_CONNECTION = "nubank-air-missed-connection",
  AIR_PRICE_FREEZE = "nubank-air-price-freeze",
  AIR_PRICE_WATCH = "nubank-air-price-watch",
  VIP_SUPPORT = "nubank-vip-support",
  PRICE_PREDICTION = "nubank-price-prediction",
  PRICE_DROP_PROTECTION = "nubank-price-drop-protection",

  // Features
  AIR_EXCHANGE = "air-self-serve-exchange",
  APP_DARKMODE = "darkmode",
  AIR_SHOP_V4 = "nubank-air-shop-v4",
  LOADING_STEPS = "nubank-loading-steps",
  LODGING = "lodging",
  HFv2 = "ProvidersExperimentNubankHackerFares",
  WALLET = "nubank-wallet-credits",
  SHOW_LODGING_MAP = "lodging-map",
}

export enum EXPERIMENTS {
  AIR_FINTECH_SELECTION = "nubank-air-fintech-selection",
  HIDE_AIR_ITINERARY_REVIEW = "nubank-air-hide-itinerary-review",
  HIDE_FARE_DETAILS = "nubank-air-hide-fare-details",
  HIDE_SEATS = "nubank-hide-seats",
}

// Uncomment to add partner experiments
// const PARTNER_EXPERIMENTS_QUERY_PARAM = "partner_experiments";
// const DEFAULT_PARTNER_EXPERIMENTS = "air_fintech_control";

// const PARTNER_EXPERIMENTS_SESSION_STORAGE_KEY = "b2bportal-partner-experiments";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

// TODO: Set default locale
const DEFAULT_LOCALE = getLang("pt-BR");

export const StyleContext = createContext({});

type UserInfoContext = {
  sessionInfo: NubankSessionInfo;
  updateSessionInfo: (info: NubankSessionInfo) => void;
};

export const UserContext = createContext<Partial<UserInfoContext>>({});

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

const App = () => {
  const location = useLocation();
  const [theme, setTheme] = useState(muiTheme);

  const darkModePreferred = useDarkModePreferred();
  const [isDarkMode, setIsDarkMode] = useState(darkModePreferred);

  const { matchesMobile } = useDeviceTypes();

  // TODO: update to use Nubank's method of notifying us that the user is UV
  const isUltravioletaUser = isUserTypeCookieUltravioleta();

  useEffect(() => {
    if (isUltravioletaUser) {
      if (isDarkMode) {
        setTheme(muiUltravioletaDarkTheme);
      } else {
        setTheme(muiUltravioletaTheme);
      }
    }
    if (isDarkMode) {
      setTheme(muiDarkTheme);
    }
  }, [isDarkMode, isUltravioletaUser]);

  const changeTheme = useCallback(
    (isDark: boolean) => {
      installCssConfig(
        isUltravioletaUser
          ? isDark
            ? nubankUltravioletaDarkVariables
            : nubankUltravioletaVariables
          : isDark
          ? nubankDarkVariables
          : nubankVariables
      );
    },
    [isUltravioletaUser]
  );
  changeTheme(isDarkMode);

  const handleDarkModeChange = useCallback(
    (val: boolean) => {
      setIsDarkMode(val);
      changeTheme(val);
    },
    [changeTheme, isDarkMode]
  );

  const [sessionInfo, setSessionInfo] = useState<NubankSessionInfo>(null);

  const updateSessionInfo = (sessionInfo) => {
    setSessionInfo(sessionInfo);
  };

  const sessionContext: ISessionContextProps = useMemo(() => {
    const [firstName, lastName] =
      sessionInfo?.userInfoResponse?.name?.split(" ") || "";
    return {
      sessionType: SessionStateEnum.Authenticated,
      isLoggedIn: true,
      email: sessionInfo?.userInfoResponse?.email,
      firstName,
      lastName,
    };
  }, [sessionInfo]);

  const windowSize = useWindowSize();
  // Add a variable for vh to use for specifying full-screen height
  // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  setViewWidthAndHeight(
    `${windowSize.width * 0.01}px`,
    `${windowSize.height * 0.01}px`
  );

  const [launchEventSent, setLaunchEventSent] = useState(false);

  const tenantTranslation: Translation = useMemo(() => {
    return {
      [TranslationLanguage.pt]: merge(
        pt_defaultTranslations,
        pt_nubankTranslations,
        pt_terms
      ),
      [TranslationLanguage.en]: merge(
        en_defaultTranslations,
        en_nubankTranslations,
        en_terms
      ),
      [TranslationLanguage.es]: merge(
        es_defaultTranslations,
        es_nubankTranslations
      ),
    };
  }, []);

  const isWebview = useCallback(() => {
    try {
      return /WebView/.test(navigator.userAgent);
    } catch (e) {
      return false;
    }
  }, []);

  const appClasses = useMemo((): string => {
    const classes = ["App-container"];
    if (isWebview()) {
      classes.push("App-Framed");
    }
    if (isDarkMode) {
      classes.push("dark-mode");
    }
    if (isUltravioletaUser) {
      classes.push("ultravioleta");
    }
    return classes.join(" ");
  }, [isDarkMode, isUltravioletaUser, isWebview]);

  const openSupportChat = (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => {
    if (!productId || !productType || !requestType) {
      console.error("Missing required parameters to open Kustomer chat");
      return;
    }

    createSupportConversationForProduct(
      productId,
      productType,
      requestType,
      chatProperties
    );
  };

  // Uncomment to add partner experiments
  // const partnerExperiments = useMemo(() => {
  //   let partnerExperiments = new URLSearchParams(location.search).get(
  //     PARTNER_EXPERIMENTS_QUERY_PARAM
  //   );

  //   if (partnerExperiments) {
  //     console.debug("Partner supplied experiments", partnerExperiments);
  //     sessionStorage.setItem(
  //       PARTNER_EXPERIMENTS_SESSION_STORAGE_KEY,
  //       partnerExperiments
  //     );
  //   } else {
  //     partnerExperiments = sessionStorage.getItem(
  //       PARTNER_EXPERIMENTS_SESSION_STORAGE_KEY
  //     );
  //     console.debug(
  //       "Retrieving partner experiments from session",
  //       partnerExperiments
  //     );
  //   }

  //   return partnerExperiments || DEFAULT_PARTNER_EXPERIMENTS;
  // }, [location.search]);

  const matchPaths = useCallback(
    (arr: string[]) => arr.some((path) => matchPath(path, location.pathname)),
    [location.pathname]
  );

  const showHeader = useMemo(
    () =>
      !matchPaths(
        matchesMobile ? HIDDEN_HEADER_PATHS_MOBILE : HIDDEN_HEADER_PATHS
      ),
    [matchPaths, matchesMobile]
  );

  const showFooter = useMemo(
    () =>
      !matchPaths(HIDDEN_FOOTER_PATHS) &&
      !location.pathname.includes(PATH_HOTELS_AVAILABILITY),
    [matchPaths, location.pathname]
  );

  return (
    // note: can remove this fallback when we move to the public/env.js strategy
    // <LoadScript googleMapsApiKey={config.googleMapsApiKey || ""}>
    <div>
      <I18nProvider
        defaultLng={DEFAULT_LOCALE}
        branding={branding}
        tenantTranslation={tenantTranslation}
      >
        <StyleContext.Provider value={{ theme }}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <UserContext.Provider value={{ sessionInfo, updateSessionInfo }}>
                <SessionContextProvider sessionContext={sessionContext}>
                  <ExperimentsProvider
                    apiConfig={apiConfig}
                    isLoggedIn={!!sessionInfo?.userInfoResponse?.email}
                    // partnerExperiments={partnerExperiments}
                  >
                    <AxiosInterceptorWrapper
                      hopperSessionToken={sessionInfo?.hopperSessionToken}
                      nubankSessionToken={sessionInfo?.nubankAccessToken}
                    />
                    <TenantContextProviderWithAssets
                      darkModeAllowed={isDarkMode}
                    >
                      <SupportContextProvider
                        kustomerConfig={{
                          apiKey: config.KUSTOMER_API_KEY,
                          brandId: config.KUSTOMER_BRAND_ID,
                          enabled: config.KUSTOMER_CHAT_SUPPORT === "enabled",
                          hopperUserId: sessionInfo?.hopperUserId,
                          kustomerAccessToken: sessionInfo?.kustomerAccessToken,
                          tenant: "nubank",
                          fetchChatProperties: chatProperties,
                        }}
                      >
                        <TermsAndConditionsWrapper>
                          <div className={appClasses}>
                            <Routes>
                              <Route
                                path={ROUTE_AUTH}
                                element={<AuthModule />}
                              />
                              <Route
                                path="/*"
                                element={
                                  <ProtectedRoute>
                                    <>
                                      {sessionInfo?.isImpersonation ? (
                                        <ImpersonationBanner />
                                      ) : null}
                                      <div className={clsx("App-content")}>
                                        {showHeader ? <Header /> : null}
                                        <Body
                                          theme={theme}
                                          // TODO: Don't have this information from startSession/getInfo
                                          isFirstSession={false}
                                          launchEventSent={launchEventSent}
                                          setLaunchEventSent={
                                            setLaunchEventSent
                                          }
                                          openSupportChat={openSupportChat}
                                          onDarkModeChange={
                                            handleDarkModeChange
                                          }
                                        />
                                      </div>
                                      {showFooter ? (
                                        <B2BGenericFooter
                                          className={clsx("generic-footer", {
                                            "position-fixed":
                                              location.pathname &&
                                              POSITION_FIXED_FOOTER_PATHS.includes(
                                                location.pathname
                                              ),
                                          })}
                                          openSupportChat={() =>
                                            createGeneralSupportConversation(
                                              "nubank"
                                            )
                                          }
                                        />
                                      ) : null}
                                    </>
                                  </ProtectedRoute>
                                }
                              />
                            </Routes>
                          </div>
                        </TermsAndConditionsWrapper>
                      </SupportContextProvider>
                    </TenantContextProviderWithAssets>
                  </ExperimentsProvider>
                </SessionContextProvider>
              </UserContext.Provider>
            </ThemeProvider>
          </StylesProvider>
        </StyleContext.Provider>
      </I18nProvider>
    </div>
    // </LoadScript>
  );
};

const TenantContextProviderWithAssets = ({
  darkModeAllowed,
  children,
}: {
  children: ReactNode | undefined;
  darkModeAllowed: boolean;
}) => {
  const isDarkMode = useDarkModePreferred();
  const lightIcons = {
    locationMarker: lightModeIcons.locationMarker,
    guest: lightModeIcons.user,
    multipleGuests: lightModeIcons.twoUsers,
    hotelIcon: lightModeIcons.hotelIcon,
    back: lightModeIcons.back,
    greenShieldCheck: lightModeIcons.greenShieldCheck,
    grayGuest: lightModeIcons.twoUsers,
    bed: lightModeIcons.bed,
    unavailable: lightModeIcons.unavailable,
    airplaneDouble: lightModeIcons.airplaneDouble,
    backWhite: lightModeIcons.backWhite,
    mapIcon: darkModeIcons.locationMarker,
    reload: lightModeIcons.reload,
  };
  const darkIcons = {
    locationMarker: darkModeIcons.locationMarker,
    guest: darkModeIcons.user,
    multipleGuests: darkModeIcons.twoUsers,
    hotelIcon: darkModeIcons.hotelIcon,
    back: darkModeIcons.back,
    greenShieldCheck: darkModeIcons.greenShieldCheck,
    grayGuest: darkModeIcons.twoUsers,
    bed: darkModeIcons.bed,
    unavailable: darkModeIcons.unavailable,
    airplaneDouble: lightModeIcons.airplaneDouble,
    backWhite: lightModeIcons.backWhite,
    mapIcon: darkModeIcons.locationMarker,
    reload: darkModeIcons.reload,
  };

  const tenantContext = {
    icons: isDarkMode ? darkIcons : lightIcons,
  };
  return (
    <TenantContextProvider tenantContext={tenantContext} children={children} />
  );
};

export default App;
