import { trackEvent } from "@hopper-b2b/api";
import { REVIEW_DETAILS } from "@hopper-b2b/types";
import { getChoosePaymentProperties } from "../../selectors";
import { FlightMachineContext } from "../../types";

export const trackReviewDetails = (
  context: FlightMachineContext,
  _event: unknown
) =>
  new Promise((resolve, _reject) => {
    const viewPaymentProperties = getChoosePaymentProperties({ context });
    trackEvent({
      eventName: REVIEW_DETAILS,
      properties: viewPaymentProperties,
    })
      .then(() => resolve(null))
      .catch(() => resolve(null));
  });
