import { useCallback, useContext, useMemo, useState } from "react";
import { matchPath, useHistory } from "react-router";
import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEnvVariables, hasHTSConnectSupport } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "@hopper-b2b/types";
import {
  ActionButton,
  ActionLink,
  FlightCategoryPicker,
  IconComponent,
  IconName,
  MobilePopoverCard,
  PassengerCountPicker,
  Slot,
} from "@hopper-b2b/ui";
import { ClientContext } from "../../../../../../App";
import { PATH_EXCHANGE } from "../../../../../../utils/urlPaths";
import {
  DestinationAutocomplete,
  OriginAutocomplete,
} from "../../../FlightSearchControl/components/TerminusAutocomplete";
import { LocationSearchWithDropdownsConnectorProps } from "./container";
import "./styles.scss";

export interface ILocationSearchWithDropdownsProps
  extends LocationSearchWithDropdownsConnectorProps {
  autoSearch?: boolean;
  hideCategoryBar?: boolean;
  tripCategory: TripCategory;
  setTripCategory: (tripCategory: TripCategory) => void;
  origin: ITripTerminus | null;
  popperClassName?: string;
  destination: ITripTerminus | null;
  onBlur?: () => void;
  onComplete?: () => void;
  onFocus?: () => void;
  setOrigin?: (value: ITripTerminus | null) => void;
  setDestination?: (value: ITripTerminus | null) => void;
  modal?: boolean;
  popupIcon?: JSX.Element;
}

export const LocationSearchWithDropdowns = ({
  autoSearch = true,
  origin,
  destination,
  hideCategoryBar,
  onBlur,
  onComplete,
  onFocus,
  popperClassName,
  readyToContinue,
  populateSearchUrlParams,
  adultsCount,
  childrenCount,
  infantsInSeatCount,
  infantsOnLapCount,
  passengerCount,
  tripCategory,
  setTripCategory,
  setOrigin,
  setDestination,
  modal = false,
  popupIcon,
}: ILocationSearchWithDropdownsProps) => {
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);

  const tenant = getEnvVariables("clientName");
  const isHTSConnect = hasHTSConnectSupport();
  const isHopper = tenant === ClientName.HOPPER;
  const isNubank = tenant === ClientName.NUBANK;

  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();
  const autoText = {
    loadingText: `${t("searching")}...`,
    noOptionsText: t("noOptions"),
    whereFrom: t("searchControl.whereFrom"),
    whereTo: t("searchControl.whereTo"),
  };

  const [openTripCategoryPicker, setOpenTripCategoryPicker] =
    useState<boolean>(false);
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState<boolean>(false);

  const history = useHistory();

  const onSearch = useCallback(() => {
    if (
      onComplete &&
      origin &&
      destination &&
      origin.id.code.code !== destination.id.code.code
    ) {
      onComplete();
    }
  }, [destination, onComplete, origin]);

  const onOriginSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== destination?.id.code.code) {
        if (!autoSearch || modal) {
          setOrigin(value);
        } else {
          populateSearchUrlParams?.({ origin: value }, history);
          onSearch();
        }
      }
    },
    [
      autoSearch,
      destination?.id.code.code,
      history,
      modal,
      onSearch,
      populateSearchUrlParams,
      setOrigin,
    ]
  );

  const onDestinationSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== origin?.id.code.code) {
        if (!autoSearch || modal) {
          setDestination(value);
        } else {
          populateSearchUrlParams?.({ destination: value }, history);
          onSearch();
        }
      }
    },
    [
      autoSearch,
      origin?.id.code.code,
      modal,
      setDestination,
      populateSearchUrlParams,
      history,
      onSearch,
    ]
  );

  const switchLocations = useCallback(() => {
    if (modal) {
      setOrigin(destination);
      setDestination(origin);
    } else {
      populateSearchUrlParams?.(
        { destination: origin, origin: destination },
        history
      );
    }
  }, [
    destination,
    history,
    modal,
    origin,
    populateSearchUrlParams,
    setDestination,
    setOrigin,
  ]);

  const getOptionSelected = useCallback(
    (option: ITripTerminus | null, value: ITripTerminus | null) => {
      if (value && option) {
        /**
         * in exchange, `value` is initialized as the prev itinerary
         * origin/dest which has the same code but different label.
         * Using `isEqual` fixes a bug where the autocomplete
         * stays open an option with same code is selected
         */
        if (inExchange) return isEqual(option, value);

        return (
          value.id.code.code === option.id.code.code &&
          value.id.code.regionType === option.id.code.regionType
        );
      }

      return false;
    },
    [inExchange]
  );

  const handleOpenPassengerModal = useCallback(() => {
    setOpenPassengerCountPicker(!openPassengerCountPicker);
  }, [openPassengerCountPicker]);

  const handleOpenTripCategoryModal = useCallback(() => {
    setOpenTripCategoryPicker(!openTripCategoryPicker);
  }, [openTripCategoryPicker]);

  const onPassengerUpdate = useCallback(
    (passengerCounts: IPassengerCounts) => {
      populateSearchUrlParams?.({ passengers: passengerCounts }, history);
      setOpenPassengerCountPicker(false);
    },
    [history, populateSearchUrlParams]
  );

  const passengerMapToCount = useMemo((): IPassengerCounts => {
    return {
      adultsCount: adultsCount ?? 0,
      childrenCount: childrenCount ?? 0,
      infantsInSeatCount: infantsInSeatCount ?? 0,
      infantsOnLapCount: infantsOnLapCount ?? 0,
    };
  }, [adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount]);

  const tripCategoryIcons = useMemo(() => {
    return {
      round_trip: clientContext?.assets?.roundtrip,
      one_way: clientContext?.assets?.oneway,
    };
  }, [clientContext?.assets?.oneway, clientContext?.assets?.roundtrip]);

  const handleTripCategorySelect = useCallback(
    (value: TripCategory) => {
      setTripCategory(value);
      setOpenTripCategoryPicker(false);
    },
    [setTripCategory]
  );

  const tripCategoryImageSource = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? clientContext?.assets?.oneway
      : clientContext?.assets?.roundtrip;
  }, [
    clientContext?.assets?.oneway,
    clientContext?.assets?.roundtrip,
    tripCategory,
  ]);

  const closePassengerPicker = useCallback(
    () => setOpenPassengerCountPicker(false),
    []
  );

  const closeTripCategoryPicker = useCallback(
    () => setOpenTripCategoryPicker(false),
    []
  );

  const hideIcon = () => {
    if (isNubank) {
      return !isNubank;
    }
    if (isHTSConnect) {
      return !isHTSConnect;
    }
    return true;
  };

  const ctaLabel =
    isNubank || isHTSConnect
      ? modal
        ? t("saveChanges")
        : t("chooseDates")
      : t("continue");

  return (
    <div
      className={clsx("mobile-flight-search-location-with-dropdowns", {
        darkMode: clientContext?.isDarkModePreferred,
      })}
    >
      {!hideCategoryBar && (
        <div className="mobile-flight-search-dropdowns">
          {isHopper ? (
            <Button
              className="mobile-flight-search-button"
              variant="outlined"
              onClick={handleOpenPassengerModal}
            >
              <div className="mobile-flight-search-icon">
                {clientContext?.assets?.passenger ? (
                  <img
                    alt="passenger picker icon"
                    src={clientContext?.assets?.passenger}
                    className="mobile-flight-search-passenger-icon"
                  />
                ) : (
                  <IconComponent
                    className="mobile-flight-search-passenger-icon"
                    name={IconName.Person}
                  />
                )}
              </div>
              <div className="mobile-flight-search-label">
                {t("passengerLabel.count", {
                  count: passengerCount,
                })}
              </div>
              <div className="mobile-flight-search-chevron">
                <FontAwesomeIcon
                  icon={faChevronDown as IconProp}
                  className="chevron-expand-icon"
                />
              </div>
            </Button>
          ) : (
            <button
              className="mobile-flight-search-button"
              onClick={handleOpenPassengerModal}
            >
              <div className="mobile-flight-search-icon">
                {clientContext?.assets?.passenger ? (
                  <img
                    alt="passenger picker icon"
                    src={clientContext?.assets?.passenger}
                    className="mobile-flight-search-passenger-icon"
                  />
                ) : (
                  <IconComponent
                    className="mobile-flight-search-passenger-icon"
                    name={IconName.Person}
                  />
                )}
              </div>
              <div className="mobile-flight-search-label">
                {t("passengerLabel.count", {
                  count: passengerCount,
                })}
              </div>
              <div className="mobile-flight-search-chevron">
                {clientContext?.assets?.dropdown ? (
                  <Slot
                    id="dropdown-icon"
                    name="dropdown-icon-end"
                    className="chevron-expand-icon"
                    component={
                      <img
                        alt="passenger picker icon"
                        src={clientContext?.assets?.dropdown}
                        className="chevron-expand-icon"
                      />
                    }
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronDown as IconProp}
                    className="chevron-expand-icon"
                  />
                )}
              </div>
            </button>
          )}
          {isHopper ? (
            <Button
              className="mobile-flight-search-button"
              variant="outlined"
              onClick={handleOpenTripCategoryModal}
            >
              <div className="mobile-flight-search-icon">
                {tripCategoryImageSource ? (
                  <img
                    className="mobile-flight-search-trip-category-icon"
                    src={
                      tripCategory === TripCategory.ONE_WAY
                        ? clientContext?.assets?.oneway
                        : clientContext?.assets?.roundtrip
                    }
                    alt={
                      tripCategory === TripCategory.ONE_WAY
                        ? t("oneWay")
                        : t("roundTrip")
                    }
                  />
                ) : (
                  <IconComponent
                    className="mobile-flight-search-trip-category-icon"
                    name={
                      tripCategory === TripCategory.ONE_WAY
                        ? IconName.ArrowRight
                        : IconName.Arrows
                    }
                  />
                )}
              </div>
              <div className="mobile-flight-search-label">
                {tripCategory === TripCategory.ONE_WAY
                  ? t("oneWay")
                  : t("roundTrip")}
              </div>
              <div className="mobile-flight-search-chevron">
                <FontAwesomeIcon
                  icon={faChevronDown as IconProp}
                  className="chevron-expand-icon"
                />
              </div>
            </Button>
          ) : (
            <button
              className="mobile-flight-search-button"
              onClick={handleOpenTripCategoryModal}
            >
              <div className="mobile-flight-search-icon">
                {tripCategoryImageSource ? (
                  <img
                    className="mobile-flight-search-trip-category-icon"
                    src={
                      tripCategory === TripCategory.ONE_WAY
                        ? clientContext?.assets?.oneway
                        : clientContext?.assets?.roundtrip
                    }
                    alt={
                      tripCategory === TripCategory.ONE_WAY
                        ? t("oneWay")
                        : t("roundTrip")
                    }
                  />
                ) : (
                  <IconComponent
                    className="mobile-flight-search-trip-category-icon"
                    name={
                      tripCategory === TripCategory.ONE_WAY
                        ? IconName.ArrowRight
                        : IconName.Arrows
                    }
                  />
                )}
              </div>
              <div className="mobile-flight-search-label">
                {tripCategory === TripCategory.ONE_WAY
                  ? t("oneWay")
                  : t("roundTrip")}
              </div>
              <div className="mobile-flight-search-chevron">
                {clientContext?.assets?.dropdown ? (
                  <Slot
                    id="dropdown-icon"
                    name="dropdown-icon-end"
                    className="chevron-expand-icon"
                    component={
                      <img
                        alt="passenger picker icon"
                        src={clientContext?.assets?.dropdown}
                        className="chevron-expand-icon"
                      />
                    }
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronDown as IconProp}
                    className="chevron-expand-icon"
                  />
                )}
              </div>
            </button>
          )}
        </div>
      )}
      <div className="mobile-flight-search-location-inputs">
        <div className="mobile-flight-search-icons">
          <div className="mobile-flight-search-icon-origin"></div>
          <div className="mobile-flight-search-icon-destination"></div>
        </div>
        <div className="mobile-location-pickers">
          {/*
              Props are duplicated here for the slots below instead of
              adding the component as a default in libs/ui to avoid circular dependency
              between ui and flights
          */}
          <Slot
            id="flight-shop-location-search-origin-autocomplete"
            hideIcon={hideIcon()}
            className="origin-auto-complete b2b mobile"
            customClearIcon={
              <IconComponent
                className="clear-search-icon"
                name={IconName.XCircleFilled}
              />
            }
            getOptionSelected={getOptionSelected}
            label={autoText.whereFrom}
            loadingText={autoText.loadingText}
            noOptionsText={<Typography>{autoText.noOptionsText}</Typography>}
            onBlur={onBlur}
            onFocus={onFocus}
            popperClassName={clsx("mobile origin-dropdown", popperClassName, {
              modal,
            })}
            popupIcon={popupIcon}
            setValue={onOriginSelected}
            value={origin}
            component={
              // TODO: Remove tenant specific code here
              <OriginAutocomplete
                hideIcon={hideIcon()}
                className="origin-auto-complete b2b mobile"
                customIcon={
                  isHTSConnect ? (
                    <img
                      src={
                        clientContext?.assets?.airplaneDepart ||
                        clientContext?.assets?.locationMarker
                      }
                      className="icon"
                      alt=""
                    />
                  ) : null
                }
                customClearIcon={
                  isHTSConnect ? (
                    <img
                      src={clientContext?.assets?.close}
                      className="clear-search-icon"
                      alt=""
                    />
                  ) : (
                    <IconComponent
                      className="clear-search-icon"
                      name={IconName.XCircleFilled}
                    />
                  )
                }
                getOptionSelected={getOptionSelected}
                label={autoText.whereFrom}
                loadingText={autoText.loadingText}
                noOptionsText={
                  <Typography>{autoText.noOptionsText}</Typography>
                }
                onBlur={onBlur}
                onFocus={onFocus}
                popperClassName={clsx(
                  "mobile origin-dropdown",
                  popperClassName,
                  {
                    modal,
                  }
                )}
                popupIcon={popupIcon}
                setValue={onOriginSelected}
                value={origin}
              />
            }
          />
          <Slot
            id="flight-shop-location-search-destination-autocomplete"
            hideIcon={hideIcon()}
            className="destination-auto-complete b2b mobile"
            customClearIcon={
              <IconComponent
                className="clear-search-icon"
                name={IconName.XCircleFilled}
              />
            }
            getOptionSelected={getOptionSelected}
            label={autoText.whereTo}
            loadingText={autoText.loadingText}
            noOptionsText={<Typography>{autoText.noOptionsText}</Typography>}
            onBlur={onBlur}
            onFocus={onFocus}
            popperClassName={clsx(
              "mobile",
              "destination-dropdown",
              popperClassName,
              {
                modal,
              }
            )}
            popupIcon={popupIcon}
            setValue={onDestinationSelected}
            value={destination}
            component={
              <DestinationAutocomplete
                hideIcon={hideIcon()}
                className="destination-auto-complete b2b mobile"
                customIcon={
                  isHTSConnect ? (
                    <img
                      src={
                        clientContext?.assets?.airplaneArrive ||
                        clientContext?.assets?.locationMarker
                      }
                      className="icon"
                      alt=""
                    />
                  ) : null
                }
                customClearIcon={
                  isHTSConnect ? (
                    <img
                      src={clientContext?.assets?.close}
                      className="clear-search-icon"
                      alt=""
                    />
                  ) : (
                    <IconComponent
                      className="clear-search-icon"
                      name={IconName.XCircleFilled}
                    />
                  )
                }
                getOptionSelected={getOptionSelected}
                label={autoText.whereTo}
                loadingText={autoText.loadingText}
                noOptionsText={
                  <Typography>{autoText.noOptionsText}</Typography>
                }
                onBlur={onBlur}
                onFocus={onFocus}
                popperClassName={clsx(
                  "mobile",
                  "destination-dropdown",
                  popperClassName,
                  {
                    modal,
                  }
                )}
                popupIcon={popupIcon}
                setValue={onDestinationSelected}
                value={destination}
              />
            }
          />
        </div>
        <div className="mobile-flight-search-switch">
          <button
            className="mobile-flight-search-switch-button"
            onClick={switchLocations}
          >
            <IconComponent
              className="mobile-flight-search-switch-button-icon"
              name={IconName.Arrows}
            />
          </button>
        </div>
      </div>
      {openPassengerCountPicker ? (
        <MobilePopoverCard
          open={openPassengerCountPicker}
          className="mobile-passenger-count-picker-popup"
          contentClassName="mobile-passenger-count-picker-popup-container"
          onClose={closePassengerPicker}
          centered={true}
          topLeftButton={
            isNubank ? (
              <ActionLink
                onClick={closePassengerPicker}
                content={
                  <img
                    src={clientContext?.assets?.close}
                    className={clsx("close-button-icon")}
                    alt={t("modalClose.ariaLabel")}
                  />
                }
              />
            ) : null
          }
        >
          <PassengerCountPicker
            minimumCount={1}
            onClickApply={onPassengerUpdate}
            className="mobile-flight-search-passenger-count-picker"
            counts={passengerMapToCount}
            setPassengerCounts={onPassengerUpdate}
            onClose={closePassengerPicker}
            modalSubtitle={
              isNubank
                ? t("passengerCount.warningMessage", {
                    maximumCount: 6,
                  })
                : null
            }
          />
        </MobilePopoverCard>
      ) : null}
      {openTripCategoryPicker ? (
        <MobilePopoverCard
          open={openTripCategoryPicker}
          className="mobile-passenger-count-picker-popup"
          contentClassName="mobile-passenger-count-picker-popup-container"
          onClose={closeTripCategoryPicker}
          centered={true}
          topRightButton={
            <ActionLink
              onClick={closeTripCategoryPicker}
              content={
                clientContext?.assets?.close ? (
                  <img
                    src={clientContext.assets.close}
                    className={clsx("close-button-icon")}
                    alt={t("modalClose.ariaLabel")}
                  />
                ) : (
                  <IconComponent
                    ariaLabel={t("modalClose.ariaLabel")}
                    className={clsx("close-button-icon")}
                    name={IconName.Close}
                  />
                )
              }
            />
          }
        >
          <FlightCategoryPicker
            className="flight-search-category-toggle"
            setTripCategory={handleTripCategorySelect}
            category={tripCategory}
            icons={tripCategoryIcons}
            onClose={closeTripCategoryPicker}
          />
        </MobilePopoverCard>
      ) : null}
      {readyToContinue ? (
        <ActionButton
          className="mobile-autocomplete-continue-button"
          message={ctaLabel}
          onClick={onSearch}
        />
      ) : null}
    </div>
  );
};
