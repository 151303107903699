import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightAlertKey,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsModalTypes,
  getWatchDetails,
} from "@hopper-b2b/types";
import { ActionLinks } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router";
import { FlightWatchCard } from "./components/FlightWatchCard/component";
import { WatchItineraryModal } from "./components/WatchItineraryModal";
import * as textConstants from "./constants";
import { WatchListConnectorProps } from "./container";
import { DATE_FORMAT } from "../ItineraryList/constants";

import "./styles.scss";
import { flightAlertLinkGenerator } from "./utils/flightAlertLinkGenerator";

export interface IWatchListProps
  extends WatchListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const addWatchType = (watch: WatchAlertView): ItineraryWithType => ({
  ...watch,
  type: ItineraryEnum.Watch,
});

export function WatchList({
  isMobile,
  watches,
  context,
  history,
  setOpenModal,
}: IWatchListProps) {
  const { t } = useI18nContext();

  const redirectToShop = (key: FlightAlertKey) => {
    history.push(flightAlertLinkGenerator(key));
  };

  const onEmailChangeClick = (watch: WatchAlertView) => {
    setOpenModal({
      type: MyTripsModalTypes.ChangeEmail,
      selectedItinerary: addWatchType(watch),
    });
  };

  const onStopWatchingClick = (watch: WatchAlertView) => {
    setOpenModal({
      type: MyTripsModalTypes.StopWatching,
      selectedItinerary: addWatchType(watch),
    });
  };

  const getActionsForWatch = (watch: WatchAlertView) => [
    {
      content: t("watch.flightWatchChangeEmailButtonLabel"),
      onClick: () => onEmailChangeClick(watch),
    },
    {
      content: t("watch.flightWatchStopWatchingButtonLabel"),
      onClick: () => onStopWatchingClick(watch),
    },
    {
      content: t("watch.flightWatchSearchFlightButtonLabel"),
      onClick: () => redirectToShop(watch.key.value),
    },
  ];

  return (
    <>
      <Box className={clsx("watch-list apac", { mobile: isMobile })}>
        {watches.map((watch: WatchAlertView) => {
          const watchDetails = getWatchDetails(
            watch,
            context,
            (date: string) => dayjs(date).format(DATE_FORMAT),
            textConstants.getFilterLabel,
            t("watch.bookNowTagLabel"),
            t("watch.waitTagLabel")
          );

          return (
            <FlightWatchCard
              key={watch.id}
              alertKey={watch.key.value}
              priceDropProtection={watch.priceDropProtection}
              isMobile={isMobile}
              {...watchDetails}
              actions={
                <ActionLinks
                  actions={getActionsForWatch(watch)}
                  className="flight-watch-action-links"
                ></ActionLinks>
              }
            />
          );
        })}
      </Box>
      <WatchItineraryModal isMobile={isMobile} />
    </>
  );
}
