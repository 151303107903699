import {
  airCfarApi,
  AirCfarExerciseQuoteResponse,
  AirCfarExerciseQuoteResponseEnum,
  QuoteSuccess,
  AirCfarExerciseFailureEnum,
} from "@b2bportal/air-cfar-api";
import { trackEvent } from "@hopper-b2b/api";
import {
  CfarExerciseProgress,
  CfarTrackingEvents,
  AirOrchestratorCfarTrackingEvents,
} from "@hopper-b2b/types";
import {
  combineTrackingProperties,
  ITrackingProperties,
  ITrackingPropertiesRequest,
} from "@hopper-b2b/utilities";
import axios, { AxiosResponse } from "axios";
import { put, select } from "redux-saga/effects";
import { actions } from "../../../actions";
import { getCfarExerciseTrackingProperties } from "../../../reducer";

export function* fetchFlightCfarCancellationQuoteV1Saga(
  action: actions.IFetchFlightCfarCancellationQuoteV1
) {
  yield put(actions.setCfarExerciseProgress(CfarExerciseProgress.Loading));
  const requestBody = {
    itineraryId: action.itineraryId,
  };
  try {
    const response: AxiosResponse<AirCfarExerciseQuoteResponse> =
      yield airCfarApi(axios).apiV1CfarAirExerciseQuotePost(requestBody);
    const data = response.data;
    if (
      data?.AirCfarExerciseQuoteResponse ===
      AirCfarExerciseQuoteResponseEnum.QuoteFailure
    ) {
      yield trackEvent({
        eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_QUOTE_FAIL,
        properties: {
          cfar_exercise_fail_reason: data.failure.AirCfarExerciseFailure,
        },
      });
      yield put(
        actions.setFetchFlightCfarCancellationQuoteError(
          data.failure.AirCfarExerciseFailure
        )
      );
      yield put(actions.setFetchFlightCfarCancellationQuoteCallStateFailed());
    } else {
      yield put(
        actions.setFlightCfarCancellationQuoteV1({
          response: response.data as QuoteSuccess,
        })
      );
      yield put(actions.setCfarExerciseProgress(CfarExerciseProgress.Review));
      yield put(actions.setFetchFlightCfarCancellationQuoteError(undefined));

      const trackingProps = yield select(
        getCfarExerciseTrackingProperties
      ) as ITrackingProperties;
      const cfarTrackingProps = combineTrackingProperties([trackingProps]);
      yield trackEvent({
        eventName: CfarTrackingEvents.ENTER_CFAR_EXERCISE_FLOW,
        ...cfarTrackingProps,
      });

      let properties: ITrackingPropertiesRequest = {
        properties: {
          agent_locator:
            action.selectedFlight.bookedItinerary.travelItinerary.locators
              ?.agent.value,
        },
        encryptedProperties: undefined,
      };
      properties = combineTrackingProperties([
        properties,
        (data as any).trackingProperties,
      ]);
      properties = combineTrackingProperties([
        (data as any).quotes[0].trackingProperties,
        properties,
      ]);

      yield trackEvent({
        eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_QUOTE,
        ...properties,
      });
      yield put(actions.setCfarExerciseQuoteTrackingProperties(properties));
    }
  } catch (e) {
    yield put(actions.setFetchFlightCfarCancellationQuoteCallStateFailed());
    yield put(
      actions.setFetchFlightCfarCancellationQuoteError(
        AirCfarExerciseFailureEnum.Error
      )
    );
    yield trackEvent({
      eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_QUOTE_FAIL,
      properties: {
        cfar_exercise_fail_reason: AirCfarExerciseFailureEnum.Error,
      },
    });
  }
}
