import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import {
  initialFilterOptions,
  MobileFlightSearchStep,
} from "../../../../../search/reducer";
import { maxFlightPriceSelector } from "../../../../../shop/reducer/selectors";
import { actions } from "../../../../../search/actions";
import { FlightFindMoreResults } from "./component";
import { setOpenCalendarModal } from "../../../../actions/actions";

interface IStateProps {
  data: { maxFlightPrice: number };
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    data: {
      maxFlightPrice: maxFlightPriceSelector(state),
    },
  };
};

interface IDispatchProps {
  setMobileSearchProgress: (
    step: MobileFlightSearchStep
  ) => actions.ISetMobileSearchProgress;
  setOpenCalendarModal: (val: boolean) => void;
  resetFilters: (maxPrice: number) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setMobileSearchProgress: actions.setMobileSearchProgress,
    setOpenCalendarModal: (val: boolean) => {
      dispatch(setOpenCalendarModal(val));
    },
    resetFilters: (maxPrice: number) => {
      // TODO: add apiConfig here
      dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
      dispatch(actions.setMaxPriceFilter(maxPrice));
      dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
      dispatch(
        actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
      );
      dispatch(
        actions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        actions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnArrivalTimeRange(
          initialFilterOptions.returnArrivalTimeRange
        )
      );
    },
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps) => {
  const maxFlightPrice = stateProps.data.maxFlightPrice;

  return {
    ...stateProps,
    ...dispatchProps,
    resetFilters: () => dispatchProps.resetFilters(maxFlightPrice),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type FlightFindMoreResultsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFindMoreResults = connector(
  withRouter(FlightFindMoreResults)
);
