import { useCallback, useMemo } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

import {
  ActionLink,
  IconContentView,
  IRestrictionProps,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import {
  getEmptyRestrictionsText,
  getRestrictions,
  getSliceIndex,
  OverwriteType,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AirportMap,
  AirRestrictionStatus,
  FareDetails,
  Restriction as RestrictionType,
  TripDetails,
} from "@hopper-b2b/types";
import {
  AirCfarSelectors,
  AirChfarSelectors,
  FlightSelectors,
  useCheckoutStateSelector as useSelector,
  VipSupportSelectors,
} from "@hopper-b2b/checkout";
import { FareDetailsSliceHeader } from "../../../../../../components/Slots";
import CircleCheck from "../../../../../../assets/client/circle-check.svg";
import CircleError from "../../../../../../assets/client/circle-error.svg";
import DollarSign from "../../../../../../assets/client/dollar-sign.svg";

import Close from "../../../../../../assets/client/close.svg";
import CloseWhite from "../../../../../../assets/client/darkMode/close-white.svg";

import "./styles.scss";
import { useDarkModePreferred } from "../../../../../../utils/colors";

export interface FareDetailsModalProps {
  open: boolean;
  onClose: () => void;
  showHackerFareContent: boolean;
}

export const FareDetailsModal = ({
  open,
  onClose,
  showHackerFareContent,
}: FareDetailsModalProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const vipSupportEnabled = useSelector(
    VipSupportSelectors.isVipSupportEnabled
  );
  const cfarQuoteId = useSelector(AirCfarSelectors.getCfarQuoteId);
  const chfarQuoteId = useSelector(AirChfarSelectors.getChfarQuoteId);

  const departureDate = useSelector(
    FlightSelectors.getFlightSearchDepartureDate
  );
  const returnDate = useSelector(FlightSelectors.getFlightSearchReturnDate);
  const airports = useSelector(FlightSelectors.getAirportMap);
  const isOneWay = useSelector(FlightSelectors.getIsSelectedTripOneWay);
  const tripDetails = useSelector(FlightSelectors.getSelectedTripDetailsParent);
  const selectedFareId = useSelector(FlightSelectors.getSelectedFareId);
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );
  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);
  const departureRestrictions = useMemo(() => {
    if (fareDetails) {
      return getRestrictions({
        fareDetails,
        sliceIndex: firstSliceIndex,
        translate: t,
        customerSupport: vipSupportEnabled,
        cfarSelected: !!cfarQuoteId,
        chfarSelected: !!chfarQuoteId,
      });
    }
    return null;
  }, [
    cfarQuoteId,
    chfarQuoteId,
    fareDetails,
    firstSliceIndex,
    t,
    vipSupportEnabled,
  ]);

  const returnRestrictions = useMemo(() => {
    if (fareDetails && !isOneWay) {
      return getRestrictions({
        fareDetails,
        sliceIndex: secondSliceIndex,
        translate: t,
        customerSupport: vipSupportEnabled,
        cfarSelected: !!cfarQuoteId,
        chfarSelected: !!chfarQuoteId,
      });
    }
    return null;
  }, [
    cfarQuoteId,
    chfarQuoteId,
    fareDetails,
    isOneWay,
    secondSliceIndex,
    t,
    vipSupportEnabled,
  ]);

  const isDarkModePreferred = useDarkModePreferred();

  return (
    <MobilePopoverCard
      className={clsx("fare-details-popup", {
        mobile: matchesMobile,
        "dark-mode": isDarkModePreferred,
      })}
      contentClassName="fare-details-content"
      open={open}
      fullScreen={false}
      centered
      onClose={onClose}
      topLeftButton={
        <ActionLink
          className="filter-close-button"
          content={
            <img
              className="close-button-icon"
              src={isDarkModePreferred ? CloseWhite : Close}
              alt=""
            />
          }
          onClick={onClose}
        />
      }
    >
      <h2 className="fare-details-halfsheet-tile">
        {t("flightsPageTitles.fareDetails")}
      </h2>
      <NubankRestrictionsSection
        fareDetails={fareDetails}
        tripDetails={tripDetails}
        isOneWay={isOneWay}
        returnDate={returnDate}
        departureDate={departureDate}
        airports={airports}
        departureRestrictions={departureRestrictions}
        returnRestrictions={returnRestrictions}
        showHackerFareContent={showHackerFareContent}
      />
    </MobilePopoverCard>
  );
};

export interface NubankRestrictionsSectionProps {
  fareDetails: FareDetails | undefined;
  tripDetails: TripDetails;
  isOneWay: boolean;
  returnDate: Date | null;
  departureDate: Date | null;
  airports: AirportMap;
  departureRestrictions: RestrictionType[] | null;
  returnRestrictions: RestrictionType[] | null;
  showHackerFareContent: boolean;
}

export const NubankRestrictionsSection = ({
  fareDetails,
  tripDetails,
  isOneWay,
  returnDate,
  departureDate,
  airports,
  departureRestrictions,
  returnRestrictions,
  showHackerFareContent,
}: NubankRestrictionsSectionProps) => {
  const { t } = useI18nContext();
  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);

  const getReviewCardHeaderWithTypeLocation = useMemo(
    () =>
      airports[tripDetails.slices[firstSliceIndex]?.destinationCode]
        ? airports[tripDetails.slices[firstSliceIndex]?.destinationCode]
            .cityName
        : tripDetails.slices[firstSliceIndex]?.destinationName,
    [airports, firstSliceIndex, tripDetails]
  );

  const getRestrictionsList = useCallback(
    (restrictions: IRestrictionProps[] | null, index: number) => {
      if (restrictions && restrictions?.length > 0) {
        return restrictions?.map((restriction) => (
          <RestrictionItem
            key={restriction.name}
            symbol={restriction.symbol}
            name={restriction.name}
            description={
              <Typography className="description">
                {restriction.description}
              </Typography>
            }
          />
        ));
      } else {
        return (
          <div className="empty-restrictions">
            {getEmptyRestrictionsText(
              fareDetails?.slices[index]?.fareShelf?.rating,
              t
            )}
          </div>
        );
      }
    },
    [fareDetails?.slices, t]
  );

  const origin = useMemo(
    () =>
      airports[tripDetails.slices[secondSliceIndex]?.destinationCode]
        ? airports[tripDetails.slices[secondSliceIndex]?.destinationCode]
            .cityName
        : tripDetails.slices[secondSliceIndex]?.destinationName,
    [airports, secondSliceIndex, tripDetails]
  );

  const destination = useMemo(
    () => getReviewCardHeaderWithTypeLocation,
    [getReviewCardHeaderWithTypeLocation]
  );

  if (isOneWay) {
    return (
      <div className="restrictions-section one-way">
        {fareDetails
          ? getRestrictionsList(departureRestrictions, firstSliceIndex)
          : null}
      </div>
    );
  } else {
    return (
      <>
        <div className="restrictions-section outbound-section">
          <Typography className="fare-slice-header" variant="body1">
            <FareDetailsSliceHeader
              origin={origin}
              destination={destination}
              date={departureDate}
              airline={
                tripDetails.slices[firstSliceIndex].segmentDetails[0]
                  .airlineName
              }
              isHackerFare={showHackerFareContent}
            />
          </Typography>
          {fareDetails
            ? getRestrictionsList(departureRestrictions, firstSliceIndex)
            : null}
        </div>
        <div className="restrictions-section">
          <Typography className="fare-slice-header" variant="body1">
            <FareDetailsSliceHeader
              origin={destination}
              destination={origin}
              date={returnDate}
              airline={
                tripDetails.slices[secondSliceIndex].segmentDetails[0]
                  .airlineName
              }
              isHackerFare={showHackerFareContent}
            />
          </Typography>
          {fareDetails
            ? getRestrictionsList(returnRestrictions, secondSliceIndex)
            : null}
        </div>
      </>
    );
  }
};

const nubankRestrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: <img src={CircleCheck} alt="" />,

  [AirRestrictionStatus.PURCHASABLE]: (
    <img src={DollarSign} className="icon-paid" alt="" />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <img src={CircleError} className="icon-unavailable" alt="" />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
  [AirRestrictionStatus.GENERIC]: null,
};

export const RestrictionItem = ({
  symbol,
  name,
  description,
}: OverwriteType<RestrictionType, { description: JSX.Element }>) => {
  const icon = nubankRestrictionIcon[symbol];

  return (
    <IconContentView
      className="restriction"
      icon={icon}
      content={
        <>
          <Typography className="title">{name}</Typography>
          <Typography variant="caption">{description}</Typography>
        </>
      }
    />
  );
};
