import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Grid, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../../../search/reducer";
import { FlightShopStep, flightShopProgressSelector } from "../../../reducer";
import styles from "./DesktopHeaderText.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { hasHTSConnectSupport, useTenantIcons } from "@hopper-b2b/utilities";

export const DesktopHeaderText = () => {
  const { t } = useI18nContext();
  const tenantIcons = useTenantIcons();

  const currentStep = useSelector(flightShopProgressSelector);
  const origin = useSelector(getOrigin);
  const destination = useSelector(getDestination);
  const departureDate = useSelector(getDepartureDate);
  const returnDate = useSelector(getReturnDate);
  const isHTSConnect = hasHTSConnectSupport();

  const isDeparture = currentStep === FlightShopStep.ChooseDeparture;
  const isReturn = currentStep === FlightShopStep.ChooseReturn;
  const showIsReturnTitle = isReturn && isHTSConnect;
  const getHeaderText = () => {
    return !origin?.label || !destination?.label
      ? ""
      : `${origin?.label} - ${destination?.label}`;
  };
  return (
    <>
      <Typography
        className="desktop-header-flight-shop-title"
        variant="h5"
        gutterBottom
      >
        {showIsReturnTitle ? t("flightShopProgress.return") : getHeaderText()}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {isHTSConnect && tenantIcons.airplaneDepart ? (
            <img
              className={clsx(
                styles.tenantIcon,
                "desktop-header-flight-shop-title-icon-depart"
              )}
              src={tenantIcons.airplaneDepart}
              alt=""
            />
          ) : (
            <IconComponent
              className={clsx(styles.icon, { [styles.return]: !isDeparture })}
              name={IconName.B2BAirplaneIcon}
            />
          )}
        </Grid>
        <Grid item>
          <Typography variant="body1" className="desktop-subtitle-step">
            {isDeparture
              ? t("flightShopProgress.departure")
              : t("flightShopProgress.return")}
          </Typography>
        </Grid>
        <Grid item />
        <Grid item>
          {isHTSConnect && tenantIcons.calendar ? (
            <img
              className={clsx(
                styles.tenantIcon,
                "desktop-header-flight-shop-title-icon-calendar"
              )}
              src={tenantIcons.calendar}
              alt=""
            />
          ) : (
            <IconComponent className={styles.icon} name={IconName.Calendar} />
          )}
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className="desktop-header-flight-shop-calendar-title"
          >
            {dayjs(isDeparture ? departureDate : returnDate).format("LL")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
