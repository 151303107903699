import { useCallback, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import clsx from "clsx";

import { useI18nContext } from "@hopper-b2b/i18n";
import { GuestsSelection, IPassengerCounts } from "@hopper-b2b/types";
import { useEnableDarkMode } from "@hopper-b2b/utilities";
import { getDarkModePreferred } from "@hopper-b2b/themes";
import {
  ActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";

import { ReactComponent as ChevronDown } from "../../../assets/client/chevron-down.svg";
import { ReactComponent as ChevronDownWhite } from "../../../assets/client/darkMode/chevron-down-white.svg";
import styles from "./styles.module.scss";
import { RadioListItem } from "../../RadioListItem";

export const MINIMUM_ADULTS_COUNT = 1;
export const MAXIMUM_COUNT = 6;

const MAX_CHILD_AGE = 17;
const DEFAULT_CHILD_AGE = MAX_CHILD_AGE;

export type PassengerCountPickerType = IPassengerCounts | GuestsSelection;

const getAdultsCount = (counts: PassengerCountPickerType) => {
  // TODO: fix compiler issue where using isPassengerCounts function does not help resolving counts as type of IPassengerCounts
  if ("adultsCount" in counts) {
    return counts.adultsCount;
  } else {
    return counts.adults;
  }
};

const getChildrenCount = (counts: PassengerCountPickerType) => {
  if ("childrenCount" in counts) {
    return counts.childrenCount;
  } else {
    return counts.children.length;
  }
};

const getChildrenAges = (counts: PassengerCountPickerType) => {
  return "children" in counts ? counts.children ?? [] : [];
};

export interface IPassengerCountPickerProps {
  onClickApply?: (passengerCounts: IPassengerCounts) => void;
  onClickApplyGuest?: (passengerCounts: GuestsSelection) => void;
  setPassengerCounts: (passengerCounts: PassengerCountPickerType) => void;
  counts: PassengerCountPickerType;
  minimumCount?: number;
  maximumCount?: number;
  titles?: PassengerCountPickerTitles;
  warningMessage?: string;
  unlimited?: boolean;
  className?: string;
  applyButtonText?: string;
  showRooms?: boolean;
  hideChildrenSubtitle?: boolean;
  onClose?: () => void;
  modalSubtitle?: string;
  captureChildrenAges?: boolean;
}

export type PassengerCountPickerTitles = {
  modalTitle: string;
  modalSubtitle?: string;
  adultTitle: string;
  adultSubtitle: string;
  childrenTitle: string;
  childrenSubtitle: string;
  childrenAgeMessage?: string | null;
  childrenAgePlaceholderKey?: string;
  infantSeatTitle?: string;
  infantSeatSubtitle?: string;
  infantLapTitle?: string;
  infantLapSubtitle?: string;
  roomsTitle?: string;
  roomsSubtitle?: string;
};

export const LodgingSearchMobileGuestModal = (
  props: IPassengerCountPickerProps
) => {
  const { t } = useI18nContext();
  const {
    onClickApply,
    onClickApplyGuest,
    setPassengerCounts,
    counts,
    titles = {
      modalTitle: t("passengerCount.modalTitle"),
      adultTitle: t("passengerCount.adultTitle"),
      adultSubtitle: "",
      childrenTitle: t("passengerCount.childrenTitle"),
      childrenSubtitle: props.hideChildrenSubtitle
        ? ""
        : t("passengerCount.childrenSubtitle"),
      childrenAgeMessage: t("passengerCount.childrenAgeMessage"),
      childrenAgePlaceholderKey: "passengerCount.childrenAgePlaceholder",
      infantLapTitle: t("passengerCount.infantLapTitle"),
      infantLapSubtitle: t("passengerCount.infantLapSubtitle"),
      infantSeatTitle: t("passengerCount.infantSeatTitle"),
      infantSeatSubtitle: t("passengerCount.infantSeatSubtitle"),
      roomsTitle: props.showRooms ? t("passengerCount.roomsTitle") : "",
    },
    minimumCount = MINIMUM_ADULTS_COUNT,
    maximumCount = MAXIMUM_COUNT,
    warningMessage = t("passengerCount.warningMessage", {
      maximumCount: MAXIMUM_COUNT,
    }),
    unlimited,
    applyButtonText = t("apply"),
    onClose,
    captureChildrenAges = false,
  } = props;

  const [adultsCount, setAdultsCount] = useState(getAdultsCount(counts));
  const [childrenCount, setChildrenCount] = useState(getChildrenCount(counts));

  //   const [roomsCount, setRoomsCount] = useState(getRoomsCount(counts));
  const [childrenAges, setChildrenAges] = useState(getChildrenAges(counts));

  const maximumReached =
    !unlimited && adultsCount + childrenCount >= maximumCount;
  const maximumExceeded =
    !unlimited && adultsCount + childrenCount > maximumCount;
  const missingChildrenAges =
    captureChildrenAges &&
    (childrenAges.length < childrenCount ||
      childrenAges.some(
        (age) => !Number.isInteger(age) || age < 0 || age > MAX_CHILD_AGE
      ));

  const applyDisabled = maximumExceeded || missingChildrenAges;

  const handleApplyClick = useCallback(() => {
    if ("adultsCount" in counts) {
      setPassengerCounts &&
        setPassengerCounts({
          adultsCount,
          childrenCount,
          infantsInSeatCount: 0,
          infantsOnLapCount: 0,
        });
    } else {
      setPassengerCounts &&
        setPassengerCounts({
          adults: adultsCount,
          children: captureChildrenAges
            ? childrenAges.slice(0, childrenCount)
            : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
          rooms: 1 /* roomsCount */,
        });
    }

    onClickApply &&
      onClickApply({
        adultsCount,
        childrenCount,
        infantsInSeatCount: 0,
        infantsOnLapCount: 0,
      });

    onClickApplyGuest &&
      onClickApplyGuest({
        adults: adultsCount,
        children: captureChildrenAges
          ? childrenAges.slice(0, childrenCount)
          : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
        rooms: 1 /* roomsCount */,
      });
  }, [
    counts,
    onClickApply,
    adultsCount,
    childrenCount,
    onClickApplyGuest,
    captureChildrenAges,
    childrenAges,
    // roomsCount,
    setPassengerCounts,
  ]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <div
      className={clsx("passenger-count-picker-root", styles.guestCountModal)}
    >
      <ActionLink
        className="filter-close-button"
        content={
          <IconComponent
            ariaLabel={t("modalClose.ariaLabel")}
            className={clsx("close-button-icon")}
            name={IconName.Close}
          />
        }
        label={t("modalClose.label")}
        onClick={handleClose}
      />
      <div id="passenger-count-picker" className="passenger-count-picker-title">
        <h1>{titles.modalTitle}</h1>
        <p className="passenger-count-picker-subtitle">{warningMessage}</p>
      </div>
      <Box mt={3} className="passenger-count-picker-types-section">
        {renderCountPick({
          title: titles.adultTitle,
          subtitle: titles.adultSubtitle,
          className: "adults",
          minimumCount,
          maximumReached,
          count: adultsCount,
          setCount: setAdultsCount,
        })}
        {renderCountPick({
          title: titles.childrenTitle,
          subtitle: titles.childrenSubtitle,
          className: "children",
          maximumReached,
          count: childrenCount,
          setCount: setChildrenCount,
        })}
        {captureChildrenAges ? (
          <ChildrenAgePickers
            childCount={childrenCount}
            message={titles.childrenAgeMessage}
            placeholderKey={titles.childrenAgePlaceholderKey}
            childrenAges={childrenAges}
            onChildAgesChange={setChildrenAges}
            applyButtonText={applyButtonText}
          />
        ) : null}
        {/* {titles.roomsTitle &&
            renderCountPick({
              title: titles.roomsTitle,
              subtitle: titles.roomsSubtitle,
              className: "rooms",
              minimumCount,
              count: roomsCount,
              setCount: setRoomsCount,
            })} */}
      </Box>
      <div className="passenger-count-button-container">
        <button
          className="passenger-count-change-button"
          onClick={handleApplyClick}
          disabled={applyDisabled}
        >
          {applyButtonText}
        </button>
      </div>
    </div>
  );
};

const renderCountPick = (props: {
  title: string;
  subtitle?: string;
  className: string;
  minimumCount?: number;
  maximumReached?: boolean;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {
    title,
    subtitle,
    className,
    minimumCount,
    count,
    setCount,
    maximumReached = false,
  } = props;

  const minusDisabled = count <= (minimumCount ?? 0);

  return (
    <div className={clsx("passenger-count-picker-type", className)}>
      <div className="passenger-type-description-container">
        <div className="passenger-type-description">
          <h1 className="passenger-type-title">{title}</h1>
          {subtitle && <p className="passenger-type-subtitle">{subtitle}</p>}
        </div>
      </div>
      <div className="passenger-count-picker-container">
        <ActionLink
          label={`Decrease ${title} ${subtitle || ""} Traveler`}
          className={clsx("passenger-count-picker-button", "minus")}
          disabled={minusDisabled}
          onClick={() => {
            if (!minusDisabled) {
              setCount((prevCount) => prevCount - 1);
            }
          }}
          content={
            <div
              className={clsx("button-content-wrapper", {
                disabled: minusDisabled,
              })}
            >
              <FontAwesomeIcon
                icon={faMinus as IconProp}
                className={clsx("icon", "minus", minusDisabled && "disabled")}
              />
            </div>
          }
        />
        <div className="passenger-count-number-container">
          <div className="passenger-count-number">
            {count}
            <span
              className="passenger-count-alert"
              aria-live="polite"
              aria-atomic={true}
            >
              {subtitle ? `${count} ${title} ${subtitle}` : `${count} ${title}`}
            </span>
          </div>
        </div>
        <ActionLink
          label={`Increase ${title} ${subtitle || ""} Traveler`}
          className={clsx("passenger-count-picker-button", "plus")}
          onClick={() => setCount((prevCount) => prevCount + 1)}
          disabled={maximumReached}
          content={
            <div
              className={clsx("button-content-wrapper", {
                disabled: maximumReached,
              })}
            >
              {" "}
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                className={clsx("icon", "plus")}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

const ChildrenAgePickers = ({
  message,
  placeholderKey,
  childCount,
  childrenAges,
  onChildAgesChange,
  applyButtonText,
}: {
  message: string;
  placeholderKey?: string;
  childCount: number;
  childrenAges: number[];
  onChildAgesChange: (childrenAges: number[]) => void;
  applyButtonText: string;
}) => {
  const { t } = useI18nContext();
  const darkModeAllowed = useEnableDarkMode();
  const isDarkMode = darkModeAllowed && getDarkModePreferred();

  const [selectedChild, setSelectedChild] = useState<number | null>(null);

  const captureChildAge = useCallback(
    (childIndex: number, age: number) => {
      const newChildrenAges = [...childrenAges];
      newChildrenAges[childIndex] = age;
      onChildAgesChange(newChildrenAges);
    },
    [childrenAges, onChildAgesChange]
  );
  if (childCount <= 0) return null;

  return (
    <div className="passenger-count-picker-child-age-container">
      <div className="passenger-count-picker-child-age-picker-container">
        {Array.from({ length: childCount }).map((_, childIndex) => (
          <div className="child-age-selector-container">
            <p className="child-label">
              {t(placeholderKey, { index: childIndex + 1 })}
            </p>
            <button
              className="child-age-input-button"
              onClick={() => setSelectedChild(childIndex)}
            >
              {typeof childrenAges[childIndex] === "number" ? (
                childrenAges[childIndex] === 0 ? (
                  t("hotelSearchPage.underOneAge")
                ) : (
                  childrenAges[childIndex]
                )
              ) : (
                <span className="placeholder">
                  {t("hotelSearchPage.selectAge")}
                </span>
              )}
              {isDarkMode ? <ChevronDownWhite /> : <ChevronDown />}
            </button>
          </div>
        ))}
        <ChildAgePickerModal
          selectedChildIndex={selectedChild}
          setSelectedChild={setSelectedChild}
          placeholderKey={placeholderKey}
          applyButtonText={applyButtonText}
          captureChildAge={captureChildAge}
          selectedChildAge={childrenAges[selectedChild]}
        />
      </div>
    </div>
  );
};

const ChildAgePickerModal = ({
  selectedChildIndex,
  setSelectedChild,
  placeholderKey,
  applyButtonText,
  captureChildAge,
  selectedChildAge,
}: {
  selectedChildIndex?: number;
  selectedChildAge: number;
  setSelectedChild: React.Dispatch<React.SetStateAction<number | null>>;
  placeholderKey: string;
  applyButtonText: string;
  captureChildAge: (childIndex: number, age: number) => void;
}) => {
  const { t } = useI18nContext();
  const [selectedAge, setSelectedAge] = useState<number>(selectedChildAge || 0);

  return (
    <MobilePopoverCard
      className="lodging-guest-child-age-picker-modal"
      open={selectedChildIndex !== null}
      contentClassName="lodging-guest-child-age-picker-modal-content"
      onClose={() => setSelectedChild(null)}
      invisibleBackdrop={false}
      fullScreen
    >
      <div className={styles.childAgeModalContent}>
        <ActionLink
          className="filter-close-button"
          content={
            <IconComponent
              ariaLabel={t("modalClose.ariaLabel")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label={t("modalClose.label")}
          onClick={() => setSelectedChild(null)}
        />
        <h1 className="child-age-modal-header">
          {t(placeholderKey, { index: selectedChildIndex + 1 })}
        </h1>
        {Array.from({ length: MAX_CHILD_AGE + 1 }).map((_, ageIndex) => (
          <RadioListItem
            isSelected={selectedAge === ageIndex}
            value={{ index: ageIndex }}
            onSelect={({ index }) => setSelectedAge(index)}
            id={`age-index-${ageIndex}`}
            label={ageIndex === 0 ? t("hotelSearchPage.underOneAge") : ageIndex}
          />
        ))}
        <div className="passenger-count-button-container">
          <button
            className="passenger-count-change-button"
            onClick={() => {
              captureChildAge(selectedChildIndex, selectedAge);
              setSelectedChild(null);
            }}
          >
            {applyButtonText}
          </button>
        </div>
      </div>
    </MobilePopoverCard>
  );
};
