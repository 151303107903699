export const apiVersionPrefix = "/api/v0";

export const selfServeFlightPrefix = `${apiVersionPrefix}/selfserve/flight`;
export const fetchExchangePolicyPath = `${selfServeFlightPrefix}/exchangeEligibilityQuote`;
export const fetchFlightByIdPath = `${apiVersionPrefix}/itinerary/flight/single`;
export const fetchExchangeSummariesPath = `${selfServeFlightPrefix}/exchangeShop`;
export const fetchTripDetailsPath = `${apiVersionPrefix}/selfserve/flight/tripDetails`;
export const submitFlightExchangeRequestPath = `${selfServeFlightPrefix}/exchangeAgentFulfill`;

// IVR
const ivrPrefix = `${apiVersionPrefix}/ivr`;
export const bookingSupportIdPath = `${ivrPrefix}/encode`;
