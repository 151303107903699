import { trackEvent } from "@hopper-b2b/api";
import { LodgingCheckoutTrackingEvents } from "@hopper-b2b/types";
import { LodgingMachineContext } from "../../types";

export const trackEditTravelers = async (
  context: LodgingMachineContext,
  _event: unknown
) =>
  trackEvent({
    eventName: LodgingCheckoutTrackingEvents.hotel_edit_guest_information,
    properties: {},
  });
