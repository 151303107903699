import { FC } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { ITripTerminus } from "@hopper-b2b/types";
import { FlightShopStep } from "../../../reducer";
import { FlightShopSummaryPanel } from "../../FlightShopSummaryPanel";
import HeaderText from "./HeaderText";

const MobileFlightShopHeader: FC<{
  origin?: ITripTerminus | null;
  destination?: ITripTerminus | null;
  hasChosenDepartureFlight: boolean;
  awaitingRefetch: boolean;
  currentStep: FlightShopStep;
  isOneWay: boolean;
  onFiltersClick?: () => void;
}> = ({
  origin,
  destination,
  hasChosenDepartureFlight,
  currentStep,
  awaitingRefetch,
  isOneWay,
  onFiltersClick,
}) => {
  return (
    <Box className="mobile-progress-header-and-flight-summary-container">
      {hasChosenDepartureFlight &&
      currentStep === FlightShopStep.ChooseReturn ? (
        <Box className="flight-summary-wrapper">
          <FlightShopSummaryPanel showDepartureReviewOnly={true} />
        </Box>
      ) : null}
      <Box
        className={clsx("header-text-wrapper", {
          "review-step-header": currentStep === FlightShopStep.ReviewItinerary,
        })}
      >
        <HeaderText
          origin={origin}
          destination={destination}
          awaitingRefetch={awaitingRefetch}
          currentStep={currentStep}
          isOneWay={isOneWay}
          onFiltersClick={onFiltersClick}
        />
      </Box>
    </Box>
  );
};

export default MobileFlightShopHeader;
