import { WalletOffer } from "@b2bportal/hopper-wallet-api";
import { CallState, ProductType } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../../../flights/src/reducers/types";
import { shopPricingInfoSelector } from "../../../shop/reducer";

export const getWalletOffers = (
  state: IStoreState
): WalletOffer[] | undefined => {
  return state.flightWallet.walletOffers?.length
    ? state.flightWallet.walletOffers.sort((a, b) => {
        return a.amount.fiat.value - b.amount.fiat.value;
      })
    : undefined;
};

export const getWalletBestOffers = (
  state: IStoreState
): WalletOffer | undefined => state.flightWallet.bestOffer;

export const getWalletCreditOffer = (state: IStoreState): WalletOffer =>
  state.flightWallet.creditOffer;

export const getWalletOffersCallState = (state: IStoreState): CallState =>
  state.flightWallet.walletOfferCallState;

export const getSelectedOffer = (state: IStoreState): WalletOffer | undefined =>
  state.flightWallet.selectedWalletOffers;

export const hasSelectedCreditOffer = (state: IStoreState): boolean =>
  !!state.flightWallet.selectedCreditOffer;

export const getSelectedCreditOffer = (state: IStoreState): WalletOffer =>
  state.flightWallet.selectedCreditOffer;

export const isWalletOffersLoading = createSelector(
  getWalletOffersCallState,
  (walletOfferCallState: CallState) =>
    walletOfferCallState === CallState.InProcess
);

export const getHasWalletOffers = createSelector(
  getWalletOffersCallState,
  getWalletOffers,
  getWalletCreditOffer,
  (callState: CallState, offers?: WalletOffer[], creditOffer?: WalletOffer) =>
    !!offers?.length || !!creditOffer
);

export const getTotalWalletSavings = createSelector(
  getSelectedOffer,
  getSelectedCreditOffer,
  (offer, creditOffer) => {
    return {
      ...offer?.amount.fiat,
      ...creditOffer?.amount.fiat,
      value:
        (offer?.amount.fiat.value || 0) + (creditOffer?.amount.fiat.value || 0),
      currencyCode:
        offer?.amount.fiat.currencyCode ||
        creditOffer?.amount.fiat.currencyCode ||
        "USD",
      currencySymbol:
        offer?.amount.fiat.currencySymbol ||
        creditOffer?.amount.fiat.currencySymbol ||
        "$",
    };
  }
);

const getCreditUsed = createSelector(
  getWalletCreditOffer,
  getSelectedOffer,
  shopPricingInfoSelector,
  (creditOffer, offer, shopPricingInfo) => {
    const calculateCreditUsageValue = () => {
      const flightTotal = shopPricingInfo?.fare?.[0]?.pricing?.total;

      const voucherDiscount = offer?.amount;

      if (flightTotal && creditOffer) {
        const value =
          (flightTotal?.fiat?.value ?? 0) +
          (voucherDiscount?.fiat?.value ?? 0) +
          (creditOffer?.amount?.fiat?.value ?? 0);

        // voucherDiscount && creditOffer are negative amounts while flightTotal is positive
        if (value < 0) {
          return Math.abs((creditOffer.amount?.fiat?.value ?? 0) - value);
        } else {
          return Math.abs(creditOffer.amount?.fiat?.value ?? 0);
        }
      } else {
        return 0;
      }
    };

    const credit = {
      ...creditOffer?.amount,
      fiat: {
        ...creditOffer?.amount.fiat,
        value: calculateCreditUsageValue() || 0,
      },
    };

    return credit?.fiat?.value ?? 0;
  }
);

export const getChooseSavingsProperties = createSelector(
  getWalletCreditOffer,
  getSelectedOffer,
  shopPricingInfoSelector,
  getCreditUsed,
  (creditOffer, offer, shopPricingInfo, creditUsed) => {
    if (creditOffer && !offer) {
      return {
        combined_discount_usd: 0,
        applicable_combined_discount_usd: 0,
        credits_amount_usd: 0,
        applicable_credits_amount_usd: 0,
        product_type: ProductType.FLIGHTS,
      };
    }
    const creditOfferDiscount = Math.abs(creditOffer?.amount?.fiat?.value) ?? 0;
    const voucherDiscount = Math.abs(offer?.amount.fiat.value) ?? 0;

    const totalPrice =
      shopPricingInfo?.fare?.[0]?.pricing?.total?.fiat?.value ?? 0;

    const combinedDiscount = creditOfferDiscount + voucherDiscount;

    return {
      combined_discount_usd: combinedDiscount,
      applicable_combined_discount_usd: Math.min(totalPrice, combinedDiscount),
      credits_amount_usd: creditOfferDiscount,
      applicable_credits_amount_usd: parseFloat(creditUsed.toFixed(2)),
      product_type: ProductType.FLIGHTS,
    };
  }
);
