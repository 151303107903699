import { State } from "xstate";
import { getChildState, getParentState } from "@checkout/helpers";
import {
  ParentState,
  PartialParentContext,
  PassportState,
} from "@checkout/types";

/**
 * Parent machine selectors
 * ------------------------
 */

export const getIsPassportLoading = (state: State<PartialParentContext>) => {
  const parentState = getParentState(state.value);
  const childState = getChildState(state.value);

  return (
    parentState === ParentState.passport && PassportState.idle !== childState
  );
};

export const getPassportError = (state: State<PartialParentContext>) =>
  state.context[ParentState.passport].error;
