import { TripAdvisorBadge } from "@hopper-b2b/ui";
import clsx from "clsx";
import styles from "./styles.module.scss";

export interface HotelDetailsReviewProps {
  title: string;
  text: string;
  created: string;
  score: number;
}

export function HotelDetailsReview({
  title,
  text,
  created,
  score,
}: HotelDetailsReviewProps) {
  return (
    <article className={clsx(styles.HotelDetailsReview, "HotelDetailsReview")}>
      <h3 className="title">“{title}”</h3>
      <TripAdvisorBadge
        translateToTheLeft
        withTripAdvisorIcon={false}
        scoreOutOfFive={score * 5}
      />
      <p className="comment">{text}</p>
      <span className="created">{created}</span>
    </article>
  );
}
