import { FareSliceDetails } from "@hopper-b2b/types";
import { Divider, Grid } from "@material-ui/core";
import { AmenityRow } from "./AmenityRow";

export type AmenityGridProps = {
  selectedFareSlice?: FareSliceDetails;
  targetFareSlice?: FareSliceDetails;
};

export const AmenityGrid = ({
  selectedFareSlice,
  targetFareSlice,
}: AmenityGridProps) =>
  selectedFareSlice?.amenitiesUtas?.utas.utas
    .flatMap((uta) => {
      const targetUta = targetFareSlice?.amenitiesUtas?.utas.utas.find(
        ({ category }) => category === uta.category
      );
      return !targetUta
        ? []
        : [
            <Grid item xs={12} className="upsell-modal-grid-divider">
              <Divider />
            </Grid>,
            <AmenityRow
              key={uta.category}
              currentUta={uta}
              targetUta={targetUta}
            />,
          ];
    })
    .slice(1);
