import {
  CallState,
  DisruptionOffer,
  DisruptionOfferQuote,
  DisruptionProductType,
} from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../../../flights/src/reducers/types";
import { isEmpty } from "lodash-es";

const disruptionProductTypeSelector = (
  offer: DisruptionOffer,
  productType: DisruptionProductType | DisruptionProductType[]
) =>
  offer.quotes.some(
    (q: DisruptionOfferQuote) =>
      q.productType === productType || productType.includes(q.productType)
  );

export const getFetchDisruptionOfferCallState = (state: IStoreState) =>
  state.flightDisruption?.disruptionOffersCallState;

export const getDisruptionOffers = (state: IStoreState) =>
  state.flightDisruption?.disruptionOffers;

export const getScheduleChangeOffer = createSelector(
  getDisruptionOffers,
  (offers?: DisruptionOffer[]): DisruptionOffer | undefined =>
    offers?.find((offer) =>
      disruptionProductTypeSelector(offer, [
        DisruptionProductType.ScheduleChange,
        DisruptionProductType.AirDisruptionProtection,
      ])
    )
);

export const getMissedConnectionOffer = createSelector(
  getDisruptionOffers,
  (offers?: DisruptionOffer[]): DisruptionOffer | undefined =>
    offers?.find((offer) =>
      disruptionProductTypeSelector(
        offer,
        DisruptionProductType.MissedConnection
      )
    )
);

export const getSelectedScheduleChangeOffer = (state: IStoreState) =>
  state.flightDisruption?.selectedScheduleChangeOffer;

export const getSelectedMissedConnectionOffer = (state: IStoreState) =>
  state.flightDisruption?.selectedMissedConnectionOffer;

export const getSelectedDisruptionOffers = createSelector(
  getSelectedScheduleChangeOffer,
  getSelectedMissedConnectionOffer,
  (
    scheduleChangeOffer?: DisruptionOffer,
    missedConnectionOffer?: DisruptionOffer
  ) => [scheduleChangeOffer, missedConnectionOffer].filter(Boolean)
);

export const getDisruptionTotal = (state: IStoreState) => {
  const disruptionOffers = getSelectedDisruptionOffers(state);
  let offerPrice = 0;
  if (disruptionOffers && disruptionOffers.length > 0) {
    disruptionOffers.forEach((offer) => {
      const price = offer.quotes[0].pricePerPax.fiat.value;
      offerPrice += price;
    });
  }

  return offerPrice;
};

export const isDisruptionOffersValid = createSelector(
  getFetchDisruptionOfferCallState,
  getDisruptionOffers,
  (callState: CallState, offers?: DisruptionOffer[]) =>
    callState === CallState.InProcess ||
    (callState === CallState.Success && !isEmpty(offers))
);

export const isScheduleChangeValid = (includeNotCalled: boolean) =>
  createSelector(
    getFetchDisruptionOfferCallState,
    getScheduleChangeOffer,
    (callState: CallState, offer?: DisruptionOffer) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !!offer)
  );

export const isMissedConnectionValid = (includeNotCalled: boolean) =>
  createSelector(
    getFetchDisruptionOfferCallState,
    getMissedConnectionOffer,
    (callState: CallState, offer?: DisruptionOffer) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !!offer)
  );
