import {
  CheckoutProps,
  FlightClientAssetProps,
  FlightModuleProps,
  IApiConfig,
  SessionInfo,
  TenantCallbacks,
} from "@hopper-b2b/types";
import {
  AirFeatureFlagsContextProps,
  FeatureFlagsContext,
  UserSourceProvider,
  updateApiConfigAction,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import { createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "./App.scss";
import { resetState } from "./actions/actions";
import ErrorBoundary from "./components/ErrorBoundary";
import { FlightsReduxProvider } from "./components/FlightsReduxProvider";
import { FlightsCheckout } from "./modules/checkout";
import { FlightExchange } from "./modules/exchange";
import { PriceFreezePurchase } from "./modules/freeze/checkout";
import { PriceFreezeOverviewPage } from "./modules/freeze/overviewPage";
import {
  setAgentEmail,
  setIsFirstLaunch,
} from "./modules/rewards/actions/actions";
import { FlightSearch } from "./modules/search";
import { resetFlightSearch } from "./modules/search/actions/actions";
import { setFlights } from "./modules/shop/actions/actions";
import { FlightShopV3 } from "./modules/shop/v3";
import { flightApiConfigStoreKey } from "./reducers/types";
import { PAWTUCKET_FLIGHTS_MODULE_ID } from "./utils/moduleIds";
import {
  PATH_BOOK,
  PATH_EXCHANGE,
  PATH_HOME,
  PATH_HOME_SEARCH,
  PATH_PRICE_FREEZE_OVERVIEW,
  PATH_PRICE_FREEZE_PURCHASE,
  PATH_SHOP,
} from "./utils/urlPaths";

const generateClassName = createGenerateClassName({
  productionPrefix: PAWTUCKET_FLIGHTS_MODULE_ID,
  seed: PAWTUCKET_FLIGHTS_MODULE_ID,
});

interface FlightsClientContextType extends FlightClientAssetProps {
  isAgentPortal: boolean;
}

export const ClientContext = createContext<Partial<FlightsClientContextType>>(
  {}
);

interface FlightsAppProps extends FlightModuleProps {
  featureFlags?: AirFeatureFlagsContextProps;
}

const FlightsApp = ({
  clientAssets,
  isAgentPortal,
  apiConfig,
  checkoutProps,
  tenantCallbacks,
  priceFreezePurchaseProps,
  featureFlags,
}: FlightsAppProps) => {
  const sessionInfo = clientAssets?.sessionInfo;

  return (
    <FlightsReduxProvider>
      <UserSourceProvider>
        <ClientContext.Provider
          value={{ ...clientAssets, isAgentPortal, sessionInfo }}
        >
          <FeatureFlagsContext.Provider value={featureFlags ?? {}}>
            <div className="App">
              <StylesProvider generateClassName={generateClassName}>
                <Body
                  sessionInfo={sessionInfo}
                  apiConfig={apiConfig}
                  checkoutProps={checkoutProps}
                  tenantCallbacks={tenantCallbacks}
                  priceFreezePurchaseProps={priceFreezePurchaseProps}
                  clientAssets={clientAssets}
                />
              </StylesProvider>
            </div>
          </FeatureFlagsContext.Provider>
        </ClientContext.Provider>
      </UserSourceProvider>
    </FlightsReduxProvider>
  );
};

type BodyProps = {
  sessionInfo?: SessionInfo;
  apiConfig?: IApiConfig;
  checkoutProps?: CheckoutProps;
  tenantCallbacks?: TenantCallbacks;
  clientAssets?: FlightClientAssetProps;
  priceFreezePurchaseProps?: CheckoutProps;
};

export const Body = ({
  sessionInfo,
  apiConfig,
  checkoutProps,
  priceFreezePurchaseProps,
  tenantCallbacks,
  clientAssets,
}: BodyProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Clean up state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetFlightSearch());
      dispatch(setFlights(null));
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (sessionInfo) {
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [sessionInfo, dispatch]);

  useEffect(() => {
    dispatch(updateApiConfigAction(flightApiConfigStoreKey, apiConfig));
  }, [apiConfig, dispatch]);

  return (
    <ErrorBoundary history={history}>
      <Box className="main-section">
        <Switch>
          <Route path={[PATH_HOME, PATH_HOME_SEARCH]} exact>
            <FlightSearch />
          </Route>
          <Route
            path={PATH_SHOP}
            render={(props) => {
              const { history } = props;
              if (history === undefined) {
                return null;
              }
              return <FlightShopV3 tenantCallbacks={tenantCallbacks} />;
            }}
          />
          <Route path={PATH_BOOK}>
            {checkoutProps && (
              <FlightsCheckout clientAssets={clientAssets} {...checkoutProps} />
            )}
          </Route>
          <Route path={PATH_PRICE_FREEZE_PURCHASE}>
            {priceFreezePurchaseProps ? (
              <PriceFreezePurchase
                clientAssets={clientAssets}
                {...priceFreezePurchaseProps}
              />
            ) : null}
          </Route>
          <Route path={PATH_PRICE_FREEZE_OVERVIEW}>
            <PriceFreezeOverviewPage />
          </Route>
          <Route path={PATH_EXCHANGE}>
            <FlightExchange />
          </Route>
          {/* Fallthrough redirect to /flights */}
          <Route
            render={() => (
              <Redirect
                to={{
                  pathname: PATH_HOME,
                }}
              />
            )}
          />
        </Switch>
      </Box>
    </ErrorBoundary>
  );
};

export default FlightsApp;
