import { connect, ConnectedProps } from "react-redux";
import { matchPath, withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { PATH_EXCHANGE } from "../../../../utils/urlPaths";
import { getAirlines, getAirports } from "../../../exchange/selectors";
import { populateFlightShopQueryParams } from "../../actions/actions";
import {
  airlinesSelector,
  airportsSelector,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../reducer/selectors";
import { FlightShopSummaryPanel } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === (selectedTrip.returnFareId ?? selectedTrip.outgoingFareId)
  );
  let airlines = airlinesSelector(state, tripId);
  let airports = airportsSelector(state, tripId);

  if (inExchange) {
    airlines = getAirlines(state);
    airports = getAirports(state);
  }

  return {
    airlines,
    airports,
    tripDetails,
    fareDetails,
    isOutgoingMultiTicket: isOutgoingMultiTicket(state),
    isReturnMultiTicket: isReturnMultiTicket(state),
  };
};

const mapDispatchToProps = {
  populateFlightShopQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopSummaryPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopSummaryPanel = connector(
  FlightShopSummaryPanel
);
