import { AirCfarPriceQuoteData } from "@b2bportal/air-cfar-api";
import {
  AirChfarDiscount,
  AirChfarPriceQuoteData,
} from "@b2bportal/air-chfar-api";
import {
  Payment,
  Product,
  ProductOpaqueValue,
  QuotedProduct,
} from "@b2bportal/purchase-api";
import {
  cartQuoteSelectors,
  FlightPassengerSelectorsV2,
  FlightSelectors,
  SeatSelectors,
  VipSupportSelectors,
} from "@hopper-b2b/checkout";
import {
  ChoosePaymentProperties,
  ChooseTravelerProperties,
  Currency,
  DisruptionOfferQuote,
  FiatPrice,
  ILineItemProps,
  ISummaryLineItem,
  RegionType,
  TripCategory,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { sum } from "lodash-es";
import { State, StateValue } from "xstate";

import { NubankSessionInfo } from "../../../api/session/startSession";
import { FlightMachineContext } from "../types";

type CheckoutState = State<FlightMachineContext>;
type CheckoutStateWithoutValue = Pick<CheckoutState, "context">;
type CheckoutStateWithAndWithoutValue =
  | CheckoutState
  | CheckoutStateWithoutValue;

export const getIsPriceBreakdownLoading = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const tripPricing = state.context.common.tripPricing;
  const quoteBreakdownPricing =
    FlightSelectors.getQuoteBreakdownFlightPricing(state);

  return quoteBreakdownPricing === undefined && tripPricing === undefined;
};

export const getSelectedTrip = (state: CheckoutStateWithAndWithoutValue) =>
  state.context.flightShop.selectedTrip;

export const getPricing = (state: CheckoutStateWithAndWithoutValue) => {
  const tripPricing = state.context.common.tripPricing;
  const quoteTripPricing =
    FlightSelectors.getQuoteBreakdownFlightPricing(state);
  const validPriceQuote =
    (quoteTripPricing?.pricingByPassenger?.length || 0) > 0;
  return validPriceQuote ? quoteTripPricing : tripPricing;
};
export const getShopPricingInfo = (state: CheckoutStateWithAndWithoutValue) =>
  state.context.flightShop.shopPricingInfo;

export const getSelectedTripDetails = (
  state: CheckoutStateWithAndWithoutValue
) => state.context.flightShop.tripDetails;

export const getCurrencyFromPricingInfo = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const shopPricingInfo = getShopPricingInfo(state);
  return {
    currencyCode:
      (shopPricingInfo.fare?.[0]?.pricing.baseAmount.fiat
        .currencyCode as Currency) || "BRL",
    currencySymbol:
      shopPricingInfo.fare?.[0]?.pricing.baseAmount.fiat.currencySymbol,
  };
};

export const getProductTotalPrice = (
  state: CheckoutStateWithAndWithoutValue
) => {
  return state.context.cartQuote.quoteBreakdown?.productTotal;
};

// TODO: fix type below
export const getFlightTotalInPrices = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const selectedSeats = SeatSelectors.getSelectedSeats(state);
  const tripPricing = getPricing(state);
  const currency = getCurrencyFromPricingInfo(state);
  const shopPricingInfo = getShopPricingInfo(state);

  const flightTotal = tripPricing?.totalPricing?.total.fiat.value;
  const farePricing = shopPricingInfo?.fare?.[0]?.pricing;
  const seatTotal = selectedSeats.reduce(
    (sum, seat) => sum + parseFloat(seat.price),
    0
  );

  const productTotalPrice = getFlightBalance(state);
  if (productTotalPrice) {
    //Use checkout v1 breakdown value
    return {
      ...productTotalPrice,
      fiat: {
        ...productTotalPrice.fiat,
        //Seats cannot be removed from quote
        //FE shows a calculated price (total + seat) without adding seats to quote.
        value: productTotalPrice.fiat.value + seatTotal,
        currencyCode: productTotalPrice.fiat.currencyCode ?? "BRL",
      },
    };
  } else if (flightTotal) {
    return {
      fiat: {
        ...currency,
        value: flightTotal + seatTotal,
      },
    };
  } else if (farePricing) {
    const fiatBaseAmount = farePricing.baseAmount.fiat.value || 0;
    const fiatTaxAmount = farePricing.taxAmount.fiat.value || 0;
    const fiatAdditionalMargin = farePricing.additionalMargin
      ? farePricing.additionalMargin.fiat.value
      : 0;

    const fiatTotal = fiatBaseAmount + fiatTaxAmount + fiatAdditionalMargin;
    return {
      fiat: {
        currencyCode: farePricing.baseAmount.fiat.currencyCode,
        currencySymbol: farePricing.baseAmount.fiat.currencySymbol,
        value: fiatTotal + seatTotal,
      },
    };
  } else {
    return null;
  }
};

export const getFlightBalance = (state: CheckoutStateWithAndWithoutValue) => {
  return state.context.cartQuote.quoteBreakdown?.balance;
};

export const getChfarDiscount = (state: CheckoutStateWithAndWithoutValue) => {
  const payment = state.context.cartQuote.quoteBreakdown?.payments.find(
    (p) => p.payment.type === Payment.AirChfarExerciseDiscount
  );

  const chfarDiscount = payment
    ? (payment.payment.value as AirChfarDiscount)
    : undefined;

  const isValueZero = chfarDiscount?.totalDiscountAmount.fiat.value === 0;
  return isValueZero ? undefined : chfarDiscount?.totalDiscountAmount;
};

type ProductLineItemType = { key: string; price: FiatPrice };

const getProductLineItem = (
  product: ProductOpaqueValue
): ProductLineItemType[] => {
  switch (product.type) {
    case Product.AirCfar:
      return [
        {
          key: product.type,
          price: (product.value as AirCfarPriceQuoteData).perPaxPremiumAmount
            .fiat,
        },
      ];
    case Product.AirChfar:
      return [
        {
          key: product.type,
          price: (product.value as AirChfarPriceQuoteData).perPaxPremiumAmount
            .fiat,
        },
      ];
    case Product.AirDisruption: {
      const disruptionProducts = product.value.quotes as DisruptionOfferQuote[];
      return disruptionProducts.map(
        (disruptionProduct: DisruptionOfferQuote) => ({
          key: disruptionProduct.productType,
          price: disruptionProduct.pricePerPax.fiat,
        })
      );
    }
    default:
      return [];
  }
};

export const getProductLineItemsPerPaxPricing = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const productLineItemsPerPax = {};

  cartQuoteSelectors
    .getQuoteBreakdown(state)
    ?.products?.map((quotedProduct: QuotedProduct) =>
      getProductLineItem(quotedProduct.product)
    )
    ?.flat()
    .reduce((acc, productLineItem: ProductLineItemType) => {
      acc[productLineItem.key] = productLineItem.price;
      return acc;
    }, productLineItemsPerPax);

  return productLineItemsPerPax;
};

export const getTripPriceLineItems = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const selectedSeats = SeatSelectors.getSelectedSeats(state);
  const tripPricing = getPricing(state);
  const shopPricingInfo = getShopPricingInfo(state);
  const productLineItemsPerPax = getProductLineItemsPerPaxPricing(state);

  if (tripPricing) {
    return tripPricing.pricingByPassenger.map((pricing) => {
      // Sum pricing for all seats for each passenger
      const seats = selectedSeats.reduce(
        (sum, seat) =>
          seat.person_id === pricing.person.id
            ? sum + parseFloat(seat.price)
            : sum,
        0
      );

      const lineItems: ILineItemProps = {
        lineTitle: `${pricing.person.givenName} ${pricing.person.surname}`,
        baseAmount:
          pricing.baseWithoutMargin.fiat.value +
          (pricing.additionalMargin?.fiat.value ?? 0),
        taxesAndFees: pricing.taxes.fiat.value,
        seats: undefined,
        products: undefined,
      };

      // TODO: update once INF pax are added to the ancillaries
      if (pricing.passengerType !== "INF") {
        lineItems.products = productLineItemsPerPax;
        if (seats) {
          lineItems.seats = seats;
        }
      }

      return lineItems;
    });
  } else if (shopPricingInfo.fare && shopPricingInfo.fare.length > 0) {
    const baseAmount =
      shopPricingInfo.fare[0].pricing.baseAmount.fiat.value || 0;
    const taxesAndFees =
      shopPricingInfo.fare[0].pricing.taxAmount.fiat.value || 0;
    return [
      {
        lineTitle: "",
        baseAmount,
        taxesAndFees,
      },
    ];
  }
  return [];
};

export const getSummaryLineItems = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const flightTotalInPrices = getFlightTotalInPrices(state);
  const vipSupportPricing = VipSupportSelectors.getVipSupportPricing(state);
  const lineItems: ISummaryLineItem[] = [];

  if (vipSupportPricing) {
    lineItems.push({
      type: "product",
      fiatPrice: vipSupportPricing,
      product: Product.VipSupport,
    });
  }
  if (flightTotalInPrices) {
    lineItems.push({
      type: "total",
      fiatPrice: flightTotalInPrices.fiat,
    });
  }
  return lineItems;
};

export const getFlightSearchDepartureLabel = (
  state: CheckoutStateWithAndWithoutValue
) => state.context.flightShop.departureLabel;

export const getFlightSearchReturnLabel = (
  state: CheckoutStateWithAndWithoutValue
) => state.context.flightShop.returnLabel;

export const getTripCategory = (state: CheckoutStateWithAndWithoutValue) =>
  state.context.flightSearch.tripCategory;

export const getSessionInfo = ({ context }: CheckoutStateWithAndWithoutValue) =>
  context.sessionInfo as unknown as NubankSessionInfo;

export const getStateValue = ({ value }: { value: StateValue }) => value;

export const getClientAssets = ({
  context,
}: CheckoutStateWithAndWithoutValue) => context.clientAssets;

export const getFlightSearchDestination = ({
  context,
}: CheckoutStateWithAndWithoutValue) => context.flightSearch.destination;

export const getFlightSearchOrigin = ({
  context,
}: CheckoutStateWithAndWithoutValue) => context.flightSearch.origin;

export const getDestinationCity = ({ context }: CheckoutStateWithoutValue) =>
  context.flightShop.departureLabel;

export const getOriginCity = ({ context }: CheckoutStateWithoutValue) =>
  context.flightShop.returnLabel;

export const getFlightSearchDepartureDate = ({
  context,
}: CheckoutStateWithAndWithoutValue) => context.flightSearch.departureDate;

export const getFlightSearchReturnDate = ({
  context,
}: CheckoutStateWithAndWithoutValue) => context.flightSearch.returnDate;

export const getOriginCountryCode = ({
  context,
}: CheckoutStateWithAndWithoutValue) => context.flightSearch.originCountryCode;

export const getDestinationCountryCode = ({
  context,
}: CheckoutStateWithAndWithoutValue) =>
  context.flightSearch.destinationCountryCode;

export const getTripDetails = ({ context }: CheckoutStateWithAndWithoutValue) =>
  context.flightShop.tripDetails;

export const getChooseTravelerProperties = ({
  context,
}: CheckoutStateWithAndWithoutValue): ChooseTravelerProperties => {
  const selectedTripDetails = getTripDetails({ context });

  const tripCategory = getTripCategory({ context }) as TripCategory;
  const destination = getFlightSearchDestination({ context });
  const origin = getFlightSearchOrigin({ context });
  const originCountryCode = getOriginCountryCode({ context });
  const destinationCountryCode = getDestinationCountryCode({ context });
  const departureDate = getFlightSearchDepartureDate({ context });
  const returnDate = getFlightSearchReturnDate({ context });
  const formattedDepartureDate = dayjs(departureDate).format("YYYY-MM-DD");
  const formattedReturnDate =
    returnDate && dayjs(returnDate).format("YYYY-MM-DD");

  return {
    departure_date: formattedDepartureDate,
    destination_country_code: destinationCountryCode,
    destination: destination
      ? `${destination?.id.code.regionType}/${destination?.id.code.code}`
      : `${RegionType.Airport}/${selectedTripDetails.slices[0].destinationCode}`,
    origin_country_code: originCountryCode,
    origin: origin
      ? `${origin?.id.code.regionType}/${origin?.id.code.code}`
      : `${RegionType.Airport}/${selectedTripDetails.slices[0].originCode}`,
    return_date: formattedReturnDate ? formattedReturnDate : undefined,
    trip_type: tripCategory,
  };
};

export const getChoosePaymentProperties = ({
  context,
}: CheckoutStateWithAndWithoutValue): ChoosePaymentProperties => {
  const chooseTravelerProperties = getChooseTravelerProperties({ context });
  const tripPricing = getPricing({ context });
  const quotedFlightTrackingProperties =
    FlightSelectors.getQuotedFlightTrackingProperties({ context });
  const cartQuoteTrackingProperties =
    cartQuoteSelectors.getCartQuoteTrackingProperties({
      context,
    });
  const totalSelectedPassengers =
    FlightPassengerSelectorsV2.getAllSelectedPassengerIdsParent({
      context,
    }).length;
  const selectedInfants =
    FlightPassengerSelectorsV2.getSelectedLapInfantIdsParent({
      context,
    }).length;
  const userPassengers = FlightPassengerSelectorsV2.getUserPassengersParent({
    context,
  });
  const seatedPassengerIds =
    FlightPassengerSelectorsV2.getSelectedPassengerIdsParent({
      context,
    });
  const selectedChildren = userPassengers.filter((pax) => {
    if (!seatedPassengerIds.includes(pax.id)) return false;
    const age = dayjs().diff(dayjs(pax.dateOfBirth, "YYYY-MM-DD"), "year");
    return age > 1 && age < 2; //TODO: centralize age related logic
  }).length;
  const totalStops = sum(
    getSelectedTripDetails({ context }).slices.map((s) => s.stops)
  );

  return {
    ...chooseTravelerProperties,
    ...quotedFlightTrackingProperties,
    ...cartQuoteTrackingProperties,

    charged_PQ_currency:
      tripPricing?.totalPricing.total.fiat.currencyCode || "BRL",
    total_price: tripPricing?.totalPricing.total.fiat.value || 0,
    pax_total: totalSelectedPassengers,
    selected_pax_children: selectedChildren,
    selected_pax_lap_infants: selectedInfants,
    selected_pax_total: totalSelectedPassengers,
    total_stops: totalStops,
  };
};
