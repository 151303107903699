import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import { paymentMethodPath } from "./paths";
import { Amount, ErrorCode } from "@b2bportal/air-booking-api";
import { CipherText } from "@b2bportal/purchase-api";

export const getPaymentMethods = (
  payload: GetPaymentMethodsRequest,
  apiConfig?: IApiConfig
): Promise<GetPaymentMethodResponse> =>
  new Promise((resolve, reject) => {
    axiosInstance
      .post(paymentMethodPath, payload)
      .then((res) => {
        const responseBody = res.data;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

export interface GetPaymentMethodsRequest {
  amount: Amount;
}

type GetPaymentMethodResponse =
  | PaymentMethodsSuccessResponse
  | PaymentMethodsFailureResponse;

export interface PaymentMethodsSuccessResponse {
  value: Array<PaymentMethodsResponse>;
  Response: "Success";
}

export interface PaymentMethodsFailureResponse {
  Response: "Failure";
  errors: Array<ErrorCode>;
}

export type PaymentMethodsResponse = DebitPaymentMethod | CreditPaymentMethod;

export enum NubankPaymentMethod {
  NuDebit = "NuDebit",
  NuCredit = "NuCredit",
}

export interface DebitPaymentMethod {
  paymentMethodType: NubankPaymentMethod.NuDebit;
  installmentPlans: Array<InstallmentPlan>;
}

export interface CreditPaymentMethod {
  paymentMethodType: NubankPaymentMethod.NuCredit;
  installmentPlans: Array<InstallmentPlan>;
}

// TODO: interest and interest rate is required for designs (defaulted to zero in UI)
export interface InstallmentPlan {
  numberOfInstallments: number;
  amount: Amount;
  fees: Amount;
  /**
   * The installment plan id provided by Nupay.
   */
  id: string;
  /**
   * An opaque identifier that the frontend sends us back so we know which
   * plan the user selected.
   */
  identifier: CipherText;
}
