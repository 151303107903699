import { useMemo } from "react";
import { placeLabelToUrl } from "@hopper-b2b/lodging-utils";
import { useSearchParams } from "react-router-dom-v5-compat";

export function useLodgingLink(id?: string, name?: string) {
  const [queryParams] = useSearchParams();

  return useMemo(() => {
    const urlFriendlyName = placeLabelToUrl(name);

    return `/hotels/shop/${urlFriendlyName}/${id}?${queryParams}`;
  }, [id, name, queryParams]);
}
