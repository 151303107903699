// TODO: relocate client specific language; see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/1959952414/Discussion+on+practical+ways+to+prevent+adding+Cap+One+specific+language+into+Pawtucket
import {
  cardsToShowLastFourOnRewardsBanner,
  cardsToNotShowBannerOn,
} from "@hopper-b2b/types";

export const HOME_PAGE_BANNER_TEXT = ({
  firstName,
  earnRate,
  productId,
  lastFour,
  flightEarnRate,
}: {
  firstName: string;
  earnRate: string | number;
  productId: string;
  lastFour: string;
  flightEarnRate: string | number;
}) => {
  if (cardsToNotShowBannerOn.includes(productId.toLowerCase())) return "";
  if (cardsToShowLastFourOnRewardsBanner.includes(productId.toLowerCase()))
    return flightEarnRate
      ? `Hi ${firstName}, earn ${flightEarnRate}x miles on flights and ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account ending in ${lastFour}.`
      : `Hi ${firstName}, earn ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account ending in ${lastFour}.`;
  return flightEarnRate
    ? `Hi ${firstName}, earn ${flightEarnRate}x miles on flights and ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account ending in ${lastFour}.`
    : `Hi ${firstName}, earn ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account.`;
};

export const FLIGHTS_SHOPPING_FUNNEL_BANNER_TEXT = ({
  firstName,
  earnRate,
  productId,
  lastFour,
  flightEarnRate,
}: {
  firstName: string;
  earnRate: string | number;
  productId: string;
  lastFour: string;
  flightEarnRate: string | number;
}) => {
  if (cardsToNotShowBannerOn.includes(productId.toLowerCase())) return "";
  if (cardsToShowLastFourOnRewardsBanner.includes(productId.toLowerCase()))
    return flightEarnRate
      ? `Hi ${firstName}, earn ${flightEarnRate}x miles on flights and ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account ending in ${lastFour}.`
      : `Hi ${firstName}, earn ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account ending in ${lastFour}.`;
  return flightEarnRate
    ? `Hi ${firstName}, earn ${flightEarnRate}x miles on flights and ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account.`
    : `Hi ${firstName}, earn ${earnRate}x miles on hotels and rental cars when you book with your ${productId} account.`;
};
