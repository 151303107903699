import { State } from "xstate";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithReview } from "./context";

export const getReviewVisited = (
  state: State<PartialParentContextWithReview>
) => state.context[ParentState.review].visited;

export const getEditSeatsFromReview = (
  state: State<PartialParentContextWithReview>
) => state.context[ParentState.review].editSeatsFromReview;
