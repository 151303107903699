export const apiVersionPrefix = "/api/v0";
export const userPrefix = `${apiVersionPrefix}/user`;
export const authPrefix = `${apiVersionPrefix}/auth`;
export const fetchUserInfoPath = `${authPrefix}/getInfo`;
export const startSessionPath = `${authPrefix}/startSession`;

export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;

export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;
export const takeoversApiPrefix = `${apiVersionPrefix}/takeovers`;

export const eventSendPath = `${apiVersionPrefix}/event/send`;
