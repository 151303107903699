import { CheckoutMapComponent, ParentState } from "@hopper-b2b/checkout";

import { BookingConfirmation } from "./BookingConfirmation";
import { CartFulfillComponent } from "./CartFulfillComponent";
import { CartQuoteComponent } from "./CartQuoteComponent";
import { ConnectedContactInformation as ContactInformation } from "./ContactInformation";
import { ConnectedPassengerInformation as PassengerInformation } from "./PassengerInformation";
import { CartUpdateComponent } from "./CartUpdateComponent";
import { ConnectedReviewComponent as ReviewComponent } from "./Review";
import { ConnectedSeatSelection as SeatSelection } from "./SeatSelection";
import { PassportInformation } from "./PassportInformation";
import { NubankPaymentComponent } from "../../../checkout/components/NubankPayment";
import { LoadingScreen } from "../../../checkout/components/LoadingScreen";
import { RefreshCheckout } from "./RestartCheckout";
import { useUpdateCheckoutUrl } from "../useUpdateCheckoutUrl";

export const flightComponentMap = {
  [ParentState.resume]: {
    state: ParentState.resume,
    component: RefreshCheckout,
  },
  [ParentState.loading]: {
    state: ParentState.loading,
    component: LoadingScreen,
  },
  [ParentState.contactInformation]: {
    state: ParentState.contactInformation,
    component: ContactInformation,
  },
  [ParentState.passengerInformation]: {
    state: ParentState.passengerInformation,
    component: PassengerInformation,
  },
  [ParentState.passport]: {
    state: ParentState.passport,
    component: PassportInformation,
  },
  [ParentState.cartQuote]: {
    state: ParentState.cartQuote,
    component: CartQuoteComponent,
  },
  [ParentState.cartUpdate]: {
    state: ParentState.cartUpdate,
    component: CartUpdateComponent,
  },
  [ParentState.seatSelection]: {
    state: ParentState.seatSelection,
    component: SeatSelection,
  },
  [ParentState.review]: {
    state: ParentState.review,
    component: ReviewComponent,
  },
  [ParentState.cartUpdateBeforeFulfill]: {
    state: ParentState.cartUpdateBeforeFulfill,
    component: CartUpdateComponent,
  },
  [ParentState.payment]: {
    state: ParentState.payment,
    component: NubankPaymentComponent,
  },
  [ParentState.cartFulfill]: {
    state: ParentState.cartFulfill,
    component: CartFulfillComponent,
  },
  [ParentState.bookingConfirmation]: {
    state: ParentState.bookingConfirmation,
    component: BookingConfirmation,
  },
};

export const FlightCheckoutComponent = () => {
  useUpdateCheckoutUrl();
  return <CheckoutMapComponent componentMap={flightComponentMap} />;
};
