import { useContext } from "react";
import { GenericBanner } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID } from "@hopper-b2b/types";
import * as constants from "./constants";
import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import { PATH_HOME, PATH_SHOP } from "../../../../utils/urlPaths";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const { history, largestValueAccount: account } = props;

  const { matchesMobile } = useDeviceTypes();

  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  let contentText = "";

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
  } = account || {};
  const {
    hotelsMultiplier: earnRate = "",
    flightsMultiplier: flightEarnRate = "",
  } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if ((account && !account.earn?.hotelsMultiplier) || earnRate <= 0) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
      if (!matchesMobile) {
        contentText = constants.HOME_PAGE_BANNER_TEXT({
          firstName,
          earnRate,
          productId,
          lastFour,
          flightEarnRate,
        });
      }
      // hide the banner on mobile search workflow
      else {
        contentText = "";
      }
      break;
    case PATH_SHOP.slice(0, -1):
    case PATH_SHOP:
      contentText = constants.FLIGHTS_SHOPPING_FUNNEL_BANNER_TEXT({
        firstName,
        earnRate,
        productId,
        lastFour,
        flightEarnRate,
      });
      break;
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
