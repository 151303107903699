import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { LoadingBunny } from "../LoadingBunny";
import "./styles.scss";

export interface ILoadingScreenWithProgressProps {
  className?: string;
  loadingSteps: Array<{ title: string; description?: string; image?: string }>;
  averageLoadingTime?: number;
  loading: boolean;
  showProgressBar?: boolean;
  resetRenderLoadingSteps?: (renderLoadingSteps: boolean) => void;
  resetLoader?: boolean;
  handleResetLoader?: (resetLoader: boolean) => void;
}

const LoadingScreenWithProgress = ({
  className,
  loadingSteps,
  averageLoadingTime = 100,
  loading,
  showProgressBar = true,
  resetRenderLoadingSteps,
  resetLoader,
  handleResetLoader,
}: ILoadingScreenWithProgressProps) => {
  const { matchesMobile } = useDeviceTypes();

  const [step, setStep] = useState<{
    title: string;
    description?: string;
    image?: string;
  }>(loadingSteps[0]);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (resetLoader) {
      setProgress(0);
      setStep(loadingSteps[0]);
      setStepIndex(0);
      handleResetLoader(false);
    }
  }, [loadingSteps, resetLoader, handleResetLoader]);

  useEffect(() => {
    if (loadingSteps.length > stepIndex + 1) {
      if (
        Math.floor(progress) ===
        Math.floor((100 / loadingSteps.length) * (stepIndex + 1))
      ) {
        setStep(loadingSteps[stepIndex + 1]);
        setStepIndex(stepIndex + 1);
      }
    }
  }, [
    averageLoadingTime,
    loading,
    loadingSteps,
    progress,
    setProgress,
    stepIndex,
  ]);

  if (loading && progress < 90) {
    setTimeout(() => {
      setProgress(progress + 0.1);
    }, averageLoadingTime);
  } else if (Math.floor(progress) === 100) {
    if (resetRenderLoadingSteps) {
      resetRenderLoadingSteps(false);
    }
  } else if (!loading) {
    setTimeout(() => {
      setProgress(progress + 0.1);
    }, 1);
  }

  return (
    <div
      className={clsx(
        className,
        loadingSteps?.length > 1 ? "multiple-steps" : "",
        "loading-screen-with-progress",
        { desktop: !matchesMobile, mobile: matchesMobile }
      )}
    >
      <div className={clsx("loading-screen-with-progress-content-wrapper")}>
        <div
          className={clsx("loading-screen-with-progress-content", {
            "with-description": !!step.description,
          })}
        >
          {step.image ? (
            <div className="loading-screen-with-progress-image-container">
              <img
                src={step.image}
                alt={step.description}
                className="loading-screen-with-progress-image"
              />
            </div>
          ) : (
            <LoadingBunny />
          )}
          <h1 className="loading-screen-with-progress-title loading-title font-regular">
            {step.title}
          </h1>
          {step.description ? (
            <div className="loading-screen-with-progress-description">
              <p className="loading-subtitle">{step.description}</p>
            </div>
          ) : null}
        </div>
        {showProgressBar ? (
          <div className="loading-screen-with-progress-bar-container">
            <div
              className="loading-screen-with-progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoadingScreenWithProgress;
