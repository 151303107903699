import { WalletOffers } from "@hopper-b2b/wallet";
import {
  getWalletBestOffers,
  getWalletOffers,
  getHasWalletOffers,
  hasSelectedCreditOffer,
  getTotalWalletSavings,
  getSelectedOffer,
  getWalletCreditOffer,
  getWalletOffersCallState,
  getChooseSavingsProperties,
} from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCreditOffer,
  setSelectedWalletOffer,
} from "../actions/actions";
import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";
import { useFetchWalletOffers } from "../../shop/hooks/fetchWalletOffers";
import { useEffect } from "react";
import { selectedTripSelector } from "../../shop/reducer";
import {
  CONFIRMED_OFFER_REDEMPTION_CHOICE,
  CallState,
  RedemptionStatus,
  TOGGLED_CREDITS_OFF,
  TOGGLED_CREDITS_ON,
  VIEWED_OFFER_REDEMPTION_CHOICE,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import { isEmpty } from "lodash-es";

interface WalletOffersPageProps {
  goToNextStep?: (replace?: boolean) => void;
}

export const WalletOfferPage = ({ goToNextStep }: WalletOffersPageProps) => {
  const { t } = useI18nContext();
  const hasWalletOffers = useSelector(getHasWalletOffers);
  const dispatch = useDispatch();

  const offers = useSelector(getWalletOffers);
  const selectedOffer = useSelector(getSelectedOffer);
  const bestOffer = useSelector(getWalletBestOffers);
  const useCreditOffer = useSelector(hasSelectedCreditOffer);
  const creditOffer = useSelector(getWalletCreditOffer);

  const walletSavings = useSelector(getTotalWalletSavings);

  const fetchWalletOffers = useFetchWalletOffers();
  const selectedTrip = useSelector(selectedTripSelector);

  const callState = useSelector(getWalletOffersCallState);
  const chooseSavingsProperties = useSelector(getChooseSavingsProperties);

  // If wallet offers are not fetched, fetch it.
  useEffect(() => {
    if (callState === CallState.NotCalled) {
      const tripId = selectedTrip.tripId;
      const fareId = selectedTrip.returnFareId
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId;
      fetchWalletOffers(tripId, fareId);
    }
  }, []);

  // If valid offer does not exist go to next step
  useEffect(() => {
    if (
      (!hasWalletOffers && callState === CallState.Success) ||
      callState === CallState.Failed
    ) {
      goToNextStep(true);
    }
  }, [callState, goToNextStep, hasWalletOffers]);

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_OFFER_REDEMPTION_CHOICE,
      properties: {
        credits_amount_usd: chooseSavingsProperties?.credits_amount_usd,
      },
    });
  }, []);

  const handleSelectCreditOffer = (selected: boolean) => {
    dispatch(setSelectedCreditOffer(selected));

    trackEvent({
      eventName: selected ? TOGGLED_CREDITS_ON : TOGGLED_CREDITS_OFF,
      properties: {
        credits_amount_usd: chooseSavingsProperties?.credits_amount_usd,
      },
    });
  };

  const handleContinue = () => {
    goToNextStep();

    const offerStatus = !isEmpty(selectedOffer)
      ? RedemptionStatus.EXPLICITLY_ACCEPTED
      : RedemptionStatus.EXPLICITLY_DECLINED;
    const creditOfferStatus = useCreditOffer
      ? RedemptionStatus.EXPLICITLY_ACCEPTED
      : RedemptionStatus.EXPLICITLY_DECLINED;

    trackEvent({
      eventName: CONFIRMED_OFFER_REDEMPTION_CHOICE,
      properties: {
        ...chooseSavingsProperties,
        offer_status: offerStatus,
        credit_status: creditOfferStatus,
        all_discounts_deferred:
          offerStatus === RedemptionStatus.EXPLICITLY_DECLINED &&
          creditOfferStatus === RedemptionStatus.EXPLICITLY_DECLINED
            ? true
            : false,
      },
    });
  };

  return (
    <div className="wallet-offers-container">
      <WalletOffers
        offers={offers}
        headerSubtitle={""}
        ctaLabel={t("walletOffers.nextStepButton")}
        handleContinue={handleContinue}
        setSelectedWalletOffer={(id) => {
          const offer = id ? offers.find((o) => o.id === id) : undefined;
          dispatch(setSelectedWalletOffer(offer));
        }}
        setSelectedCreditOffer={handleSelectCreditOffer}
        creditOffer={creditOffer}
        bestOffer={bestOffer}
        useCreditOffer={useCreditOffer}
        totalSavings={walletSavings}
        selectedOffer={selectedOffer}
      />
    </div>
  );
};
