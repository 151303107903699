import { IContactInfo } from "@checkout/index";
import { ProductType } from "@hopper-b2b/types";

export enum ContactEventType {
  CHANGE = "CHANGE",
  NEXT = "NEXT",
  CLEAR_CART_QUOTE_AND_EDIT = "CLEAR_CART_QUOTE_AND_EDIT", // Clear the cart quote and reenable the contact info editing.
}

export interface SetContactInfoEvent {
  type: ContactEventType.CHANGE;
  contactInfo: IContactInfo;
}

export interface NextEventType {
  type: ContactEventType.NEXT;
  productType: ProductType;
}

export interface ClearCartQuoteAndEditEvent {
  type: ContactEventType.CLEAR_CART_QUOTE_AND_EDIT;
}

export type ContactEvents =
  | NextEventType
  | SetContactInfoEvent
  | ClearCartQuoteAndEditEvent;
