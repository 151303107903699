import { PropsWithChildren } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { Header, IconComponent, IconName } from "@hopper-b2b/ui";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { ClientName } from "@hopper-b2b/types";

import "./styles.scss";

interface DesktopLayoutProps {
  headerElement?: JSX.Element | string;
  contentClassName?: string;
  className?: string;
  fullScreen?: boolean;
  clientLogo?: string;
  rightContent?: JSX.Element;
  topContent?: JSX.Element;
}

export const DesktopLayout = ({
  headerElement,
  contentClassName,
  className,
  children,
  fullScreen = true,
  clientLogo,
  rightContent,
  topContent,
}: PropsWithChildren<DesktopLayoutProps>) => (
  <Box
    className={clsx(
      "desktop-layout-card-container",
      "checkout",
      fullScreen && "full-screen",
      className
    )}
  >
    {headerElement ? (
      headerElement
    ) : // TODO: Long term have the header injected when single page checkout is ready, for now will just hide on Hopper
    ![
        ClientName.HOPPER,
        ClientName.LLOYDS,
        ClientName.VOLARIS,
        ClientName.FLAIR,
      ].includes(getEnvVariables("clientName") as ClientName) ? (
      <DesktopCheckoutHeader clientLogo={clientLogo} />
    ) : null}
    <Box className={clsx("desktop-layout-content-container", contentClassName)}>
      {topContent && (
        <Box my={3} className="top-content">
          {topContent}
        </Box>
      )}
      <Box className="left-content">{children}</Box>
      {rightContent && (
        <Paper variant="outlined" className="right-content">
          {rightContent}
        </Paper>
      )}
    </Box>
  </Box>
);

export const DesktopCheckoutHeader = ({
  clientLogo,
  title,
  subtitle,
}: {
  clientLogo?: string;
  title?: string;
  subtitle?: string;
}) => {
  const { t } = useI18nContext();
  const history = useHistory();

  return (
    <Header
      className="desktop-flight-checkout-header-container"
      left={
        <Box className={"checkout-left-content"}>
          <Box className={"logo"} onClick={() => history.push("/")}>
            <div className={clsx("header-logo-container")}>
              {clientLogo && (
                <>
                  <img
                    src={clientLogo}
                    className={"mobile img-logo client-logo header-logo-client"}
                    alt={t("b2bLogo")}
                  />
                  <hr className={clsx("header-logo-divider")} />
                </>
              )}
              <IconComponent
                ariaLabel={t("hopperLogo")}
                className={"mobile img-logo header-logo-hopper"}
                name={IconName.HopperLogo}
              />
            </div>
          </Box>
          <Box className={"checkout-left-titles"}>
            <Typography variant={"body1"}>
              {title || t("confirmAndBook")}
            </Typography>
            <Typography variant={"body2"} className={"header-subtitle"}>
              {subtitle || t("enterAllRequiredFields")}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
