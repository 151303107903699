import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { LodgingSearchRoot } from "@hopper-b2b/lodging/src/modules/search/Search";
import { ButtonWrap, SlotProvider } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import { ReactComponent as ArrowBack } from "../../assets/client/arrow-back.svg";
import { LodgingSearchMobileGuestModal } from "../Slots/LodgingSearchMobileGuestModal";
import "./LodgingSearchRoute.scss";
import { PATH_HOME } from "../../utils/urlPaths";
import { useExperiment } from "@hopper-b2b/experiments";
import { ExperimentVariant } from "@hopper-b2b/types";
import { FEATURE_FLAGS } from "../../App";
import { HotelSearchAutocomplete } from "../Slots/HotelSearchAutocomplete";

export const LodgingSearchRoute = () => {
  const navigate = useNavigate();

  const enableLodging: boolean | undefined = useExperiment(
    FEATURE_FLAGS.LODGING,
    ExperimentVariant.Available,
    true
  );

  useEffect(() => {
    if (enableLodging === false) {
      navigate(PATH_HOME, { replace: true });
    }
  }, [enableLodging, navigate]);

  const onSearch = useCallback(
    (path: string) => {
      navigate(`${PATH_HOME}${path}`);
    },
    [navigate]
  );

  return (
    <SlotProvider
      slots={{
        "lodging-search-mobile-guest-modal": LodgingSearchMobileGuestModal,
        "hotel-search-autocomplete": HotelSearchAutocomplete,
      }}
    >
      <div className="nubank lodging-search">
        <LodgingSearchRoot
          onSearch={onSearch}
          CalendarScreenHeader={CalendarScreenHeader}
          LocationAndGuestScreenHeader={LocationAndGuestScreenHeader}
        />
      </div>
    </SlotProvider>
  );
};

const LocationAndGuestScreenHeader = ({ onBack }: { onBack: () => void }) => {
  const { t } = useI18nContext();
  return (
    <div className="lodging-search-header">
      <ButtonWrap className="back-button" onClick={onBack}>
        <ArrowBack className="arrow-back-icon" />
      </ButtonWrap>
      <h1>{t("hotels")}</h1>
    </div>
  );
};

const CalendarScreenHeader = ({ onBack }: { onBack: () => void }) => {
  const { t } = useI18nContext();
  return (
    <div className="lodging-search-header">
      <ButtonWrap className="back-button" onClick={onBack}>
        <ArrowBack className="arrow-back-icon" />
      </ButtonWrap>
      <h1>{t("mobileSearchChooseDate")}</h1>
    </div>
  );
};

export default LodgingSearchRoute;
