import { useI18nContext } from "@hopper-b2b/i18n";
import { GenericHeader } from "../../GenericHeader";

export interface RoomDetailsHeaderProps {
  onBack: () => void;
  title: string;
}

export function RoomDetailsHeader({ onBack, title }: RoomDetailsHeaderProps) {
  const { t } = useI18nContext();

  return <GenericHeader title={title} onBack={onBack} />;
}
