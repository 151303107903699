import { Suggestion } from "@b2bportal/air-shopping-api";
import { FlightSearch, FlightSearchFormProps } from "@hopper-b2b/common-search";
import { ClientName, ITripTerminus, TripCategory } from "@hopper-b2b/types";
import { Slot } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  tenantFlagsEnabled,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { setOpenCalendarModal } from "../../../shop/actions/actions";
import { useUpdateFlightShopStep } from "../../../shop/hooks";
import { useFetchFlights } from "../../../shop/hooks/useFetchFlights";
import {
  FlightShopStep,
  openCalendarModalSelector,
} from "../../../shop/reducer";
import {
  setDepartureDate,
  setDestination,
  setOrigin,
  setPassengerCounts,
  setReturnDate,
  setTripCategory,
} from "../../actions/actions";
import {
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getDestination,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getOrigin,
  getReturnDate,
  getTripCategory,
} from "../../reducer";
import styles from "./FlightShopSearchControl.module.scss";

export type FlightShopSearchControlProps = {
  disabled: boolean;
};

export const FlightShopSearchControl = ({
  disabled,
}: FlightShopSearchControlProps) => {
  const dispatch = useDispatch();
  const tenantIcons = useTenantIcons();
  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;
  const tripCategory = useSelector(getTripCategory);

  const origin = useSelector(getOrigin) as Suggestion;
  const destination = useSelector(getDestination) as Suggestion;

  const departureDate = useSelector(getDepartureDate);
  const returnDate = useSelector(getReturnDate);

  const adultsCount = useSelector(getAdultsCount);
  const childrenCount = useSelector(getChildrenCount);
  const infantsInSeatCount = useSelector(getInfantsInSeatCount);
  const infantsOnLapCount = useSelector(getInfantsOnLapCount);

  // this prop is the right one to open the calendar from outside the button
  const openDatesModal = useSelector(openCalendarModalSelector);
  const onSetOpenCalendarModal = (value: boolean) =>
    dispatch(setOpenCalendarModal(value));
  const fetchFlights = useFetchFlights();

  const updateFlightShopStep = useUpdateFlightShopStep();

  const onSearch = useCallback(
    (_searchUrl: string, searchFormProps: FlightSearchFormProps) => {
      dispatch(setTripCategory(searchFormProps.tripCategory as TripCategory));
      dispatch(setDestination(searchFormProps.destination as ITripTerminus));
      dispatch(setOrigin(searchFormProps.origin as ITripTerminus));
      dispatch(setDepartureDate(new Date(searchFormProps.departureDate)));
      dispatch(
        setReturnDate(
          searchFormProps.returnDate
            ? new Date(searchFormProps.returnDate)
            : null
        )
      );
      dispatch(setPassengerCounts(searchFormProps.paxCount));
      fetchFlights();

      // Go back to Departure step
      updateFlightShopStep(FlightShopStep.ChooseDeparture);
    },
    [dispatch, fetchFlights, updateFlightShopStep]
  );

  const initialValues: FlightSearchFormProps = {
    tripCategory,
    origin,
    destination,
    departureDate: departureDate?.toISOString(),
    returnDate: returnDate ? returnDate?.toISOString() : null,
    paxCount: {
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    },
  };

  return (
    <Box
      className={clsx("flight-shop-search-control-container", styles.container)}
    >
      {initialValues.origin && initialValues.destination && (
        <Slot
          id="flight-search"
          disabled={disabled}
          enableCalendarPrediction={
            tenantFlagsEnabled.FlightSearchCalendarPrediction
          }
          initialValues={initialValues}
          onSearch={onSearch}
          openDatesModal={openDatesModal}
          onSetOpenCalendarModal={onSetOpenCalendarModal}
          component={
            <FlightSearch
              icons={{
                location: isHopper ? null : tenantIcons.locationMarker,
              }}
              disabled={disabled}
              enableCalendarPrediction={
                tenantFlagsEnabled.FlightSearchCalendarPrediction
              }
              initialValues={initialValues}
              onSearch={onSearch}
              openDatesModal={openDatesModal}
              onSetOpenCalendarModal={onSetOpenCalendarModal}
            />
          }
        />
      )}
    </Box>
  );
};
