import { FC, useCallback, useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Button, Chip } from "@material-ui/core";
import Cancel from "../../assets/client/cancel.svg";
import styles from "./styles.module.scss";
import { ILodgingFilterState } from "@hopper-b2b/types";

const AppliedFilterTag: FC<{
  filter: string;
  onDelete: (filter: string) => void;
  deleteIcon?: any;
}> = ({ filter, onDelete, deleteIcon }) => {
  const { t } = useI18nContext();
  const handleDelete = useCallback(() => {
    onDelete(filter);
  }, [filter, onDelete]);

  const label = useMemo(() => {
    return t(`${filter}`);
  }, [filter, t]);

  const onKeyDownHandler = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onDelete(filter);
      }
    },
    [filter, onDelete]
  );

  return (
    <Chip
      className={"applied-filter-chip"}
      label={label}
      aria-label={`Remove ${filter}`}
      onKeyDown={onKeyDownHandler}
      onDelete={handleDelete}
      color="secondary"
      {...(deleteIcon && {
        deleteIcon: <img src={deleteIcon} alt={`Remove ${filter}`} />,
      })}
    />
  );
};

export interface AppliedFilterTagsProps {
  filters: ILodgingFilterState;
  resetFilters: () => { type: string };
  deleteFilter: (key: string) => void;
}

export const AppliedFilterTags = (props: AppliedFilterTagsProps) => {
  const { filters, resetFilters, deleteFilter } = props;

  const appliedFilters = useMemo(() => {
    const appliedFiltersArray = [];
    Object.keys(filters).forEach((key) => {
      if (
        ((key === "starRating" || key === "amenities") &&
          filters[key].length > 0) ||
        (key === "userRating" && filters[key]) ||
        (key === "priceRange" &&
          (filters[key].min !== filters[key].lowest ||
            filters[key].max !== filters[key].highest))
      ) {
        appliedFiltersArray.push(key);
      }
    });
    return appliedFiltersArray;
  }, [filters]);

  const handleDelete = useCallback(
    (key: string) => {
      deleteFilter(key);
    },
    [deleteFilter]
  );

  const onKeyDownHandler = useCallback(
    (e) => {
      if (e.key === "Enter") {
        resetFilters();
      }
    },
    [appliedFilters, resetFilters]
  );

  const { t } = useI18nContext();

  if (appliedFilters?.length <= 0) return null;

  return (
    <Box className={styles.AppliedFilterTags}>
      {appliedFilters.length > 0 && (
        <Button
          className="reset-filters"
          onKeyDown={onKeyDownHandler}
          onClick={resetFilters}
        >
          {t("resetFilters")}
        </Button>
      )}
      {appliedFilters.map((filter) => (
        <AppliedFilterTag
          key={filter}
          filter={filter}
          onDelete={handleDelete}
          deleteIcon={Cancel}
        />
      ))}
    </Box>
  );
};
