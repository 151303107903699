import { B2BLoadingPopup } from "@hopper-b2b/ui";
import "./styles.scss";

export interface ILoaderProps {
  open: boolean;
  message?: string;
  secondaryMessage?: string;
  className?: string;
  onClose?: () => void;
}

const Loader = ({
  open,
  message,
  secondaryMessage,
  className,
  onClose,
}: ILoaderProps) => {
  return (
    <B2BLoadingPopup
      open={open}
      message={message}
      secondaryMessage={secondaryMessage}
      className={className}
      popupSize="mobile"
      onClose={onClose}
      fullScreen
    />
  );
};

export default Loader;
