import { CipherText } from "@b2bportal/purchase-api";
import {
  AirPriceDropContext,
  FlightContextV2,
  ParentState,
} from "@hopper-b2b/checkout";
import { NubankPaymentContext } from "../../checkout/states";

export enum PassportState {
  idle = "idle",
  update = "update",
  updateAndContinue = "updateAndContinue",
  validate = "validate",
}

export interface PaymentStateContext {
  paymentSessionToken?: string;
  paymentQuoteToken?: CipherText;
  error?: PaymentError;
  visited: boolean;
}

export interface FlightMachineContext
  extends Required<
    Omit<
      FlightContextV2,
      | ParentState.cardPayment
      | ParentState.priceFreeze
      | ParentState.wallet
      | ParentState.changeForAnyReason
      | ParentState.changeForAnyReasonDiscount
      | ParentState.cancelForAnyReason
      | ParentState.disruption
    >
  > {
  [ParentState.payment]: NubankPaymentContext;
  [ParentState.bookingConfirmation]: unknown;
  [ParentState.priceDrop]: AirPriceDropContext;
}

export enum PaymentErrorModalTypes {
  ListPaymentMethods = "ListPaymentMethods",
  VerifyPayment = "VerifyPayment",
  ExcessiveUserActivity = "ExcessiveUserActivity",
  GenericError = "GenericError",
  DeletePayment = "DeletePayment",
}

export enum PassportErrorModalTypes {
  TripPricing = "TripPricing",
  UpdatePassenger = "UpdatePassenger",
  ValidatePassengers = "ValidatePassengers",
}

export interface PaymentError {
  type: PaymentErrorModalTypes;
  data: unknown;
}

export interface PassportError {
  type: PassportErrorModalTypes;
  data: unknown;
}

export enum CheckoutQueryParams {
  tripCategory = "tripCategory",
  departureLabel = "departureLabel",
  returnLabel = "returnLabel",
  tripId = "tripId",
  outgoingSliceId = "outgoingSliceId",
  outgoingFareId = "outgoingFareId",
  outgoingFareRating = "outgoingFareRating",
  returnSliceId = "returnSliceId",
  returnFareId = "returnFareId",
  returnFareRating = "returnFareRating",
  contactCountry = "contactCountry",
  contactPhone = "contactPhone",
  contactEmail = "contactEmail",
  selectedLapInfantIds = "selectedLapInfantIds",
  selectedPassengerIds = "selectedPassengerIds",
  selectedPaymentMethodId = "selectedPaymentMethodId",
  checkoutState = "checkoutState",
  checkoutChildState = "checkoutChildState",
}
