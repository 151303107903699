import {
  CartActions,
  LodgingActionTypes,
  getReviewMachineState,
} from "@checkout/states";
import { ChildMachineConfig, GenericChildState } from "@checkout/types";
import { Event } from "../../events";

export const getReviewMachine = (config: ChildMachineConfig) => {
  const defaultReviewMachine = getReviewMachineState(config);
  return {
    initial: GenericChildState.idle,
    entry: LodgingActionTypes.checkForPriceChange,
    states: {
      [GenericChildState.idle]: {},
    },
    always: { ...defaultReviewMachine.always },
    on: {
      [Event.ACKNOWLEDGE_PRICE_CHANGE]: {
        actions: CartActions.acknowledgePriceChange,
      },
      ...defaultReviewMachine.on,
    },
  };
};
