import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import clsx from "clsx";

import {
  cartQuoteSelectors,
  LodgingSelectors,
  useCheckoutStateSelector as useSelector,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  useTrackEvents,
  getLocalStorageTrackingEvents,
  removeLocalStorageTrackingEvents,
} from "@hopper-b2b/lodging";
import { ActionButton, BackButton } from "@hopper-b2b/ui";
import { LodgingCheckoutTrackingEvents } from "@hopper-b2b/types";

import { PATH_LANDING, PATH_TRIPS } from "../../../../utils/urlPaths";
import { ReactComponent as GreenCheckMark } from "../../../../assets/client/green-confirmation-check.svg";
import { ReactComponent as HotelBlackIcon } from "../../../../assets/client/hotel-icon.svg";
import { ReactComponent as HotelWhiteIcon } from "../../../../assets/client/darkMode/hotel-icon-white.svg";
import { ReactComponent as CalendarBlackIcon } from "../../../../assets/client/calendar-unfilled.svg";
import { ReactComponent as CalendarWhiteIcon } from "../../../../assets/client/darkMode/calendar-unfilled-white.svg";
import { useDarkModePreferred } from "../../../../utils/colors";
import style from "./styles.module.scss";
import { useEnableWallet } from "@hopper-b2b/utilities";
const DATE_FORMAT = "dddd, D MMM YYYY";

export const ConnectedBookingConfirmation = () => {
  const { t } = useI18nContext();
  const history = useHistory();
  const isDarkMode = useDarkModePreferred();
  const trackEvent = useTrackEvents();

  const enableWallet = useEnableWallet();
  const offerStatus = useSelector(WalletSelectors.getOfferStatus);

  const localStorageTrackingEventsKey = useSelector(
    LodgingSelectors.getLocalStorageKey
  );
  const email = useSelector(cartQuoteSelectors.getCartQuoteCustomerEmail);
  const fromDate = useSelector(LodgingSelectors.getHotelFromDate);
  const lodging = useSelector(LodgingSelectors.getSelectedLodging);
  const untilDate = useSelector(LodgingSelectors.getHotelUntilDate);
  const confirmationNumber = useSelector(LodgingSelectors.getConfirmationId);

  const redirectToTrips = useCallback(() => {
    history.push(PATH_TRIPS);
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.push(PATH_LANDING);
  }, [history]);

  const HotelIcon = isDarkMode ? HotelWhiteIcon : HotelBlackIcon;
  const CalendarIcon = isDarkMode ? CalendarWhiteIcon : CalendarBlackIcon;

  useEffect(() => {
    const propertiesFromStorage = getLocalStorageTrackingEvents(
      localStorageTrackingEventsKey
    );
    trackEvent(LodgingCheckoutTrackingEvents.hotel_complete_buy, {
      success: true,
      ...(enableWallet ? { offer_status: offerStatus } : {}),
      ...(propertiesFromStorage?.properties || {}),
    });
    removeLocalStorageTrackingEvents(localStorageTrackingEventsKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={style.reviewHeaderContainer}>
        <BackButton className={style.backButton} onClick={handleGoBack} />
        <h1 className={style.reviewHeader}>
          {t("bookingConfirmation.header")}
        </h1>
      </div>
      <div className="content">
        <GreenCheckMark className={style.headerImage} />
      </div>
      <h2 className={style.header}>{t("bookingConfirmation.title")}</h2>
      <h3 className={style.subheader}>
        <I18nMarkup
          tKey={"lodgingConfirmationSubHeader"}
          replacements={{ email }}
        />
      </h3>

      <p className={style.confirmationTag}>
        <I18nMarkup
          tKey={"bookingConfirmation.status"}
          replacements={{ id: confirmationNumber }}
        />
      </p>

      {/* Booking Details */}
      <div className={style.bookingSectionContainer}>
        <div className={style.bookingDetailsContainer}>
          <HotelIcon className={style.gridIcon} />
          <h3 className={clsx(style.bookingDetailsHeader, style.gridHeader)}>
            {lodging?.lodging?.name}
          </h3>
          <p
            className={clsx(style.bookingDetailsSubheader, style.gridSubheader)}
          >
            {lodging?.lodging?.city}, {lodging?.lodging?.country}
          </p>
        </div>
        <div className={style.bookingDetailsContainer}>
          <CalendarIcon className={style.gridIcon} />
          <h3 className={clsx(style.bookingDetailsSubheader, style.gridHeader)}>
            {t("checkIn")}
          </h3>
          <p className={clsx(style.bookingDetailsHeader, style.gridSubheader)}>
            {dayjs(fromDate).format(DATE_FORMAT)}
          </p>
        </div>
        <div className={clsx(style.bookingDetailsContainer, style.lastRow)}>
          <CalendarIcon className={style.gridIcon} />
          <h3 className={clsx(style.bookingDetailsSubheader, style.gridHeader)}>
            {t("checkOut")}
          </h3>
          <p className={clsx(style.bookingDetailsHeader, style.gridSubheader)}>
            {dayjs(untilDate).format(DATE_FORMAT)}
          </p>
        </div>
      </div>

      <div className={style.redirectButtonWrapper}>
        <ActionButton
          buttonClassName={style.redirectButton}
          onClick={redirectToTrips}
          message={t("myTrips")}
        />
      </div>
    </>
  );
};
