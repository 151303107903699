import {
  useCheckoutStateSelector as useSelector,
  PriceBreakdownDropdown,
  PriceBreakdownContent,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import React from "react";

import {
  getFlightTotalInPrices,
  getCurrencyFromPricingInfo,
  getIsPriceBreakdownLoading,
  getTripPriceLineItems,
  getSummaryLineItems,
  getShopPricingInfo,
  getTripCategory,
} from "../selectors";

export const ConnectedPriceBreakdownDropdown = () => {
  const { t } = useI18nContext();

  const flightTotalInPrices = useSelector(getFlightTotalInPrices);
  const tripCurrency = useSelector(getCurrencyFromPricingInfo);
  const isPriceBreakdownLoading = useSelector(getIsPriceBreakdownLoading);
  const tripPriceLineItems = useSelector(getTripPriceLineItems);
  const tripPricingSummaryItems = useSelector(getSummaryLineItems);
  const shopPricingInfo = useSelector(getShopPricingInfo);
  const tripCategory = useSelector(getTripCategory);

  return (
    <PriceBreakdownDropdown
      flightTotal={flightTotalInPrices}
      currency={tripCurrency}
      isLoading={isPriceBreakdownLoading}
      lineItems={tripPriceLineItems}
      summaryLineItems={tripPricingSummaryItems}
      header={t("checkoutBreakdown")}
      // Hardcoded fintech values
      isPriceFreezeExerciseEnabled={false}
      priceDropProtection={null}
      initialPassengerPricing={shopPricingInfo?.fare?.[0]?.pricing}
      tripCategory={tripCategory}
    />
  );
};

export const ConnectedPriceBreakdownContent = () => {
  const { t } = useI18nContext();
  const shopPricingInfo = useSelector(getShopPricingInfo);
  const tripCurrency = useSelector(getCurrencyFromPricingInfo);
  const isPriceBreakdownLoading = useSelector(getIsPriceBreakdownLoading);
  const tripPriceLineItems = useSelector(getTripPriceLineItems);
  const tripPricingSummaryItems = useSelector(getSummaryLineItems);
  const tripCategory = useSelector(getTripCategory);
  return (
    <PriceBreakdownContent
      isLoading={isPriceBreakdownLoading}
      initialPassengerPricing={shopPricingInfo?.fare?.[0]?.pricing}
      currency={tripCurrency}
      lineItems={tripPriceLineItems}
      summaryLineItems={tripPricingSummaryItems}
      header={t("checkoutBreakdown")}
      tripCategory={tripCategory}
      customClassName={"checkout-price-breakdown-content"}
    />
  );
};
