import { B2BSpinner } from "@hopper-b2b/ui";
import { Fragment, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useI18nContext } from "@hopper-b2b/i18n";
import { FilteredFare } from "@hopper-b2b/types";
import {
  useDeviceTypes,
  useEnableWallet,
  useSessionContext,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { SignupBanner } from "@hopper-b2b/wallet";
import { IFlightListData } from "../../../../component";
import { IInfiniteFlightsProps } from "../../index";
import { DesktopInfiniteFlightsGridComponent } from "./DesktopInfiniteFlightsGridComponent";
import { DesktopInfiniteFlightsListComponent } from "./DesktopInfiniteFlightsListComponent";

export interface DesktopInfiniteFlightsProps extends IInfiniteFlightsProps {
  flightsToRender: IFlightListData[];
}

export const DesktopInfiniteFlights = (props: DesktopInfiniteFlightsProps) => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const { isLoggedIn } = useSessionContext();

  const hasVouchersEnabled = useEnableWallet();

  const { faresToShow, flightsToRender, setFetchMoreData, hasMore } = props;

  const { matchesMediumDesktopOnly } = useDeviceTypes();

  const handleNext = useCallback(() => {
    if (setFetchMoreData) {
      setFetchMoreData();
    }
  }, [setFetchMoreData]);

  const rows: FilteredFare[] | IFlightListData[] = matchesMediumDesktopOnly
    ? faresToShow
    : flightsToRender;

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={handleNext}
      hasMore={hasMore}
      loader={
        <div className="loading-flights">
          <B2BSpinner classes={["loading-flights-bunny"]} />
        </div>
      }
    >
      {rows.map((flight, index) => (
        <Fragment key={index}>
          {index === 3 && hasVouchersEnabled && !isLoggedIn && (
            <SignupBanner message={t?.("walletBannerSignIn")} />
          )}
          {matchesMediumDesktopOnly ? (
            <DesktopInfiniteFlightsListComponent
              index={index}
              filteredFare={flight}
              {...props}
            />
          ) : (
            <DesktopInfiniteFlightsGridComponent
              index={index}
              flightData={flight}
              {...props}
            />
          )}
        </Fragment>
      ))}
    </InfiniteScroll>
  );
};
