import {
  CallState,
  DisruptionOffer as DisruptionOfferType,
  DisruptionProductType,
} from "@hopper-b2b/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getFetchDisruptionOfferCallState,
  isDisruptionOffersValid,
} from "../../reducer";
import { DisruptionLoading } from "../DisruptionLoading";
import { MissedConnectionOffer } from "../MissedConnectionOffer";
import { ScheduleChangeOffer } from "../ScheduleChangeOffer";

export type DisruptionOfferProps = {
  type: DisruptionProductType;
  goToNextStep?: (replace?: boolean) => void;
  hideContinueButton?: boolean;
};

export type DisruptionContentProps = Omit<DisruptionOfferProps, "type">;

export const DisruptionOffer = ({
  type,
  goToNextStep,
}: DisruptionOfferProps) => {
  const callState = useSelector(getFetchDisruptionOfferCallState);
  const isOffersValid = useSelector(isDisruptionOffersValid);

  const DisruptionContent = useMemo(
    () =>
      type === DisruptionProductType.ScheduleChange
        ? ScheduleChangeOffer
        : MissedConnectionOffer,
    [type]
  );

  if (!isOffersValid) {
    goToNextStep?.(true);
    return null;
  }

  return callState === CallState.InProcess ? (
    <DisruptionLoading />
  ) : isOffersValid ? (
    <DisruptionContent goToNextStep={goToNextStep} />
  ) : null;
};
