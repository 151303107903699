import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Radio } from "@material-ui/core";
import {
  CancellationPolicyEnum,
  RoomInfoProducts,
  RoomProduct,
} from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import styles from "./styles.module.scss";

export interface RoomDetailsSelectProps {
  room: RoomInfoProducts;
  onChange: (product: RoomProduct) => void;
  nights: number;
  DetailsModal: JSX.Element;
  selectedId: string;
}

const RoomDetailsSelect = ({
  room,
  onChange,
  nights,
  DetailsModal,
  selectedId,
}: RoomDetailsSelectProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();
  const [selectedPricePerNight, setSelectedPricePerNight] = useState(null);
  const [selectedTotalPrice, setSelectedTotalPrice] = useState(null);
  const [surplus, setSurplus] = useState(null);

  useEffect(() => {
    if (room?.products) {
      const product = room.products[0];
      setSelectedPricePerNight(formatPrice(product.perNightTotal));
      setSelectedTotalPrice(formatPrice(product.tripTotal));

      const refundablePricePerNight = room.products.find(
        (p) =>
          p.cancellationPolicy.CancellationPolicy ===
          CancellationPolicyEnum.Refundable
      )?.perNightTotal;
      const nonrefundablePricePerNight = room.products.find(
        (p) =>
          p.cancellationPolicy.CancellationPolicy ===
          CancellationPolicyEnum.NonRefundable
      )?.perNightTotal;

      if (refundablePricePerNight && nonrefundablePricePerNight) {
        setSurplus({
          currencyCode: refundablePricePerNight.fiat?.currencyCode,
          currencySymbol: refundablePricePerNight.fiat?.currencySymbol,
          value: Math.ceil(
            refundablePricePerNight.fiat?.value -
              nonrefundablePricePerNight.fiat?.value
          ),
        });
      }
    }
  }, [room]);

  const handleSelectRoom = useCallback(
    (product: RoomProduct) => {
      setSelectedPricePerNight(formatPrice(product.perNightTotal));
      setSelectedTotalPrice(formatPrice(product.tripTotal));
      onChange(product);
    },
    [onChange, setSelectedPricePerNight, setSelectedTotalPrice]
  );

  const getIsSelected = useCallback(
    (product: RoomProduct) => selectedId === product?.rateId?.value,
    [selectedId]
  );

  return (
    <div
      className={clsx(
        styles.RoomDetailsSelectContainer,
        "RoomDetailsSelectContainer"
      )}
    >
      <fieldset
        className={clsx(styles.RoomDetailsSelect, "RoomDetailsSelect")}
        id={room.roomInfo.roomId}
      >
        <h6 className="title">{t("cancellationPolicyCopy")}</h6>
        {room.products.map((product: RoomProduct) => {
          const refundable =
            product.cancellationPolicy.CancellationPolicy ===
            CancellationPolicyEnum.Refundable;
          const cancellationPolicy = product.cancellationPolicy.primaryText;
          const onlyOption = room.products.length === 1;
          return (
            <div className="option">
              <div className="option-selection">
                <Radio
                  className={clsx({ hidden: onlyOption })}
                  value={product.rateId.value}
                  inputProps={{
                    "aria-selected": getIsSelected(product),
                  }}
                  onClick={() => handleSelectRoom(product)}
                  checked={onlyOption ? true : getIsSelected(product)}
                />
                <div className="input-label-container">
                  <p className="input-label">
                    {refundable ? t("refundable") : t("nonrefundable")}
                  </p>
                  {refundable ? (
                    <div className="cancellation-policy">
                      {cancellationPolicy}
                    </div>
                  ) : null}
                </div>
              </div>
              {refundable && !onlyOption ? (
                <div className="surplus">+{formatFiatCurrency(surplus)}</div>
              ) : null}
            </div>
          );
        })}
      </fieldset>
      <div className={clsx("price-info", styles.RoomDetailsSelectPrice)}>
        <div className="price-per-night price-info-content">
          <p className="price-info-label">{t("pricePerNightLabel")}</p>
          <p className="price-info-value">{selectedPricePerNight}</p>
        </div>
        <div className="total-price price-info-content">
          <p className="price-info-label">
            {t("totalPriceLabel", {
              count: nights,
            })}
          </p>
          <p className="price-info-value">{selectedTotalPrice}</p>
        </div>
        <p className="taxes-and-fees">{t("taxesAndFeesIncluded")}</p>
      </div>
      {DetailsModal}
    </div>
  );
};

export default RoomDetailsSelect;
