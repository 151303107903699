import { CallState, LodgingShopSteps } from "@hopper-b2b/types";

import * as actionTypes from "./constants";
import {
  CheckInInstructions,
  CheckInPolicy,
  Lodging,
  RoomInfoProducts,
  TrackingProperties,
} from "@b2bportal/lodging-api";

export interface ISetSelectedLodging {
  type: actionTypes.SET_SELECTED_LODGING;
  lodging: Lodging;
  offerOpaque?: string;
}

export const setSelectedLodging = (lodging: Lodging, offerOpaque?: string) => ({
  type: actionTypes.SET_SELECTED_LODGING,
  lodging,
  offerOpaque,
});

export interface ICheckInPolicies {
  type: actionTypes.SET_CHECK_IN_POLICIES;
  checkInPolicies: CheckInPolicy[];
}

export const setCheckInPolicies = (checkInPolicies: CheckInPolicy[]) => ({
  type: actionTypes.SET_CHECK_IN_POLICIES,
  checkInPolicies,
});

export interface ICheckInInstructions {
  type: actionTypes.SET_CHECK_IN_INSTRUCTIONS;
  checkInInstructions: CheckInInstructions;
}

export const setCheckInInstructions = (
  checkInInstructions: CheckInInstructions
) => ({
  type: actionTypes.SET_CHECK_IN_INSTRUCTIONS,
  checkInInstructions,
});

export interface ISetRooms {
  type: actionTypes.SET_ROOMS;
  rooms: RoomInfoProducts[];
}

export const setRooms = (rooms: RoomInfoProducts[]) => ({
  type: actionTypes.SET_ROOMS,
  rooms,
});

export interface ISetShopTrackingProperties {
  type: actionTypes.SET_SHOP_TRACKING_PROPERTIES;
  trackingProperties: TrackingProperties;
}

export const setShopTrackingProperties = (
  trackingProperties: TrackingProperties
) => ({
  type: actionTypes.SET_SHOP_TRACKING_PROPERTIES,
  trackingProperties,
});

export interface ISetShopCallState {
  type: actionTypes.SET_SHOP_CALL_STATE;
  callState: CallState;
  roomsOnly?: boolean;
}

export const setShopCallState = (
  callState: CallState,
  roomsOnly?: boolean
) => ({
  type: actionTypes.SET_SHOP_CALL_STATE,
  callState,
  roomsOnly,
});

export interface ISetSelectedRoom {
  type: actionTypes.SET_SELECTED_ROOM;
  room: RoomInfoProducts;
}

export const setSelectedRoom = (room: RoomInfoProducts) => ({
  type: actionTypes.SET_SELECTED_ROOM,
  room,
});

export interface ISetSelectedRate {
  type: actionTypes.SET_SELECTED_RATE;
  rateId: string;
}

export const setSelectedRate = (rateId: string) => ({
  type: actionTypes.SET_SELECTED_RATE,
  rateId,
});

export interface ISetShopStep {
  type: actionTypes.SET_CURRENT_SHOP_STEP;
  step: LodgingShopSteps;
}
export const setCurrentShopStep = (step: LodgingShopSteps) => ({
  type: actionTypes.SET_CURRENT_SHOP_STEP,
  step: step,
});

export interface ISetShopPriceChange {
  type: actionTypes.SET_SHOP_PRICE_CHANGE;
  shopPriceChange: number;
}

export const setShopPriceChange = (shopPriceChange: number) => ({
  type: actionTypes.SET_SHOP_PRICE_CHANGE,
  shopPriceChange,
});

export type LodgingShopActions =
  | ISetSelectedLodging
  | ISetSelectedRoom
  | ISetSelectedRate
  | ISetShopStep
  | ICheckInPolicies
  | ICheckInInstructions
  | ISetRooms
  | ISetShopTrackingProperties
  | ISetShopPriceChange
  | ISetShopCallState;
