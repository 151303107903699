import { FareDetails } from "@hopper-b2b/types";
import { ButtonWrap, IFlightDetailsCardProps } from "@hopper-b2b/ui";
import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import {
  getFilteredFareDetails,
  getSortedFareDetails,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import FareDetailsItem from "./components/FareDetailsItem";
import SelectedFareRestrictions from "./components/SelectedFareRestrictions";
import styles from "./styles.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ReactComponent as UnfoldIcon } from "../../../assets/client/unfold.svg";

export interface IFareNotice {
  id: string;
  message: string;
  tooltipCopy: string;
}

export const FlightCardExpandedSection = (props: IFlightDetailsCardProps) => {
  const {
    className,
    inExchange,
    tripDetails,
    selectedFareId,
    isOutgoing,
    onFareClick,
    onAlgomerchClick,
    isMobile,
    fareNotice,
    rewardsKey,
    getEmptyRestrictionsText = () => "",
    selectedFareClassFilters,
    selectedOutgoingFareSlice,
    getFlightShopFareSlice,
    setMobileSelectedFareId,
    setExpandedFlight,
  } = props;

  const { t } = useI18nContext();

  const fareDetails = tripDetails.fareDetails;

  const fare = fareDetails.find((fare) => fare.id === selectedFareId);
  const selectedFare = (fare ? fare : fareDetails[0]).id;

  const [selectedFareDetails, setSelectedFareDetails] =
    useState<FareDetails | null>(null);
  const [selectedFareIndex, setSelectedFareIndex] = useState<number | null>(
    null
  );

  const [filteredFares, setFilterFares] = useState<FareDetails[]>([]);

  const getSelectedFareId = useCallback(() => {
    const fareExists = filteredFares.some((f) => f.id === selectedFare);
    const firstFareId = filteredFares[0]?.id;
    if (!firstFareId) return undefined;
    if (isOutgoing) {
      return fareExists ? selectedFare : firstFareId;
    } else {
      // check if outgoing is refundable, try to find matching return fare
      if (selectedOutgoingFareSlice?.fareBrandName?.includes("Refundable")) {
        const matchedFare = filteredFares.find(
          (fare) =>
            fare.slices[1]?.fareShelf?.brandName ===
            selectedOutgoingFareSlice?.fareBrandName
        );
        return matchedFare ? matchedFare.id : firstFareId;
      } else {
        return fareExists ? selectedFare : firstFareId;
      }
    }
  }, [
    filteredFares,
    isOutgoing,
    selectedFare,
    selectedOutgoingFareSlice?.fareBrandName,
  ]);

  useEffect(() => {
    if (
      selectedFare &&
      fareDetails &&
      selectedFareDetails === null &&
      selectedFareIndex === null
    ) {
      const onlyFilteredFares = getFilteredFareDetails({
        fareDetails: fareDetails,
        selectedFareClassFilters,
        isOutgoing,
        selectedFareId: getSelectedFareId(),
      });
      const filteredAndSortedFares = getSortedFareDetails(onlyFilteredFares);

      const selected = fareDetails.find(
        (fareDetail) => fareDetail.id === selectedFare
      );
      const selectedIndex = fareDetails.findIndex(
        (fareDetail) => fareDetail.id === selectedFare
      );

      setFilterFares(filteredAndSortedFares);
      setSelectedFareDetails(selected ? selected : fareDetails[0]);
      setSelectedFareIndex(selectedIndex);
    }
  }, [
    fareDetails,
    getSelectedFareId,
    isOutgoing,
    selectedFare,
    selectedFareClassFilters,
    selectedFareDetails,
    selectedFareIndex,
  ]);

  const onFareClicked = useCallback(
    (e: Event | MouseEvent, fare: FareDetails, index: number) => {
      onFareClick && e.stopPropagation();
      setMobileSelectedFareId && setMobileSelectedFareId(fare.id);
      setSelectedFareDetails(fare);
      setSelectedFareIndex(index);
      onFareClick && onFareClick(fare.id);
    },
    [onFareClick, setMobileSelectedFareId]
  );

  return (
    <Box
      className={clsx(
        "flight-card-expanded-section",
        styles.flightCardExpandedSection
      )}
    >
      <div className="fare-container">
        {filteredFares.map((fare, index) => (
          <FareDetailsItem
            key={index + "." + fare.id}
            fare={fare}
            index={index}
            className={className}
            isOutgoing={isOutgoing}
            inExchange={inExchange}
            isMobile={isMobile}
            rewardsKey={rewardsKey}
            selectedFareDetails={selectedFareDetails}
            getFlightShopFareSlice={getFlightShopFareSlice}
            onAlgomerchClick={onAlgomerchClick}
            onFareClicked={onFareClicked}
          />
        ))}
      </div>
      {selectedFareDetails && selectedFareIndex !== null ? (
        <SelectedFareRestrictions
          selectedFare={selectedFareDetails}
          allFareDetails={fareDetails}
          fareNotice={fareNotice}
          index={selectedFareIndex}
          isOutgoing={isOutgoing}
          isMobile={isMobile}
          getEmptyRestrictionsText={getEmptyRestrictionsText}
        />
      ) : null}
      <div className="fold-button-container">
        <ButtonWrap
          className="collapse-flight-card-expanded-section"
          onClick={() => setExpandedFlight("")}
        >
          {t("close.button")}
          <UnfoldIcon />
        </ButtonWrap>
      </div>
    </Box>
  );
};
