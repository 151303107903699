import clsx from "clsx";
import { LodgingPrice } from "@b2bportal/lodging-api";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { useSelector } from "react-redux";
import { getTotalNights } from "../../../../reducer";
import styles from "./Price.module.scss";
import { Typography } from "@material-ui/core";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { Skeleton } from "@material-ui/lab";

interface Props {
  price: LodgingPrice;
  className?: string;
  loading?: boolean;
}

export function Price({ price, className, loading = false }: Props) {
  const { t, brand } = useI18nContext();

  const { formatPrice } = usePriceFormatterContext();
  const nights = useSelector(getTotalNights);

  return price ? (
    <Typography
      variant="h2"
      className={clsx(styles.Price, className)}
      style={{ lineHeight: "100%" }}
    >
      {loading ? (
        <>
          <Skeleton width={116} variant="text" />
          <Skeleton width={116} variant="text" />
        </>
      ) : (
        <>
          <div className="price-per-night">
            <I18nMarkup
              tKey={"pricePerNight"}
              replacements={{
                price: formatPrice(price.nightlyPrice, {
                  maximumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                  minimumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                  roundingMode: "ceil",
                }),
              }}
            />
          </div>
          <Typography variant="body1">
            <span className="total-price">
              <Trans
                i18nKey="priceForNights"
                values={{
                  price: formatPrice(price.totalPrice, {
                    maximumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                    minimumFractionDigits: brand?.lodgingCardPriceRounding || 0,
                    roundingMode: "ceil",
                  }),
                  count: nights,
                }}
                components={[<strong className="value" />]}
              />
            </span>
            <span className="taxes-and-fees">{t("taxesAndFeesIncluded")}</span>
          </Typography>
        </>
      )}
    </Typography>
  ) : null;
}
