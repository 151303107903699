import {
  ITripTerminus,
  Person,
  TripCategory,
  TripDetails,
  WalletOffer,
} from "@hopper-b2b/types";
import { TripPricingSummary } from "@b2bportal/air-booking-api";
import { QuoteBreakdownResponse, CipherText } from "@b2bportal/purchase-api";
import { GuestsSelection } from "@b2bportal/lodging-api";

export enum ResumeEvent {
  // Shared Resume Events
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",
  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_WALLET = "GO_TO_WALLET",
  GO_TO_PRICE_DROP = "GO_TO_PRICE_DROP",
  SET_CONTACT_INFORMATION_CONTEXT = "SET_CONTACT_INFORMATION_CONTEXT",
  SET_PASSENGER_INFORMATION_CONTEXT = "SET_PASSENGER_INFORMATION_CONTEXT",
  SET_CART_QUOTE_CONTEXT = "SET_CART_QUOTE_CONTEXT",
  // Flight Resume Events
  SET_FLIGHT_SEARCH_CONTEXT = "SET_FLIGHT_SEARCH_CONTEXT",
  SET_FLIGHT_SHOP_CONTEXT = "SET_FLIGHT_SHOP_CONTEXT",
  SET_FLIGHT_SEARCH_ORIGIN = "SET_FLIGHT_SEARCH_ORIGIN",
  SET_FLIGHT_SEARCH_DESTINATION = "SET_FLIGHT_SEARCH_DESTINATION",
  SET_FLIGHT_TRIP_DETAILS = "SET_FLIGHT_TRIP_DETAILS",
  SET_PRICE_DROP_CONTEXT = "SET_PRICE_DROP_CONTEXT",
  SET_PASSENGER_VALIDATION = "SET_PASSENGER_VALIDATION",
  SET_TRIP_PRICING = "SET_TRIP_PRICING",
  // Hotel Resume Events
  SET_LODGING_SHOP_CONTEXT = "SET_LODGING_SHOP_CONTEXT",
  // Wallet Resume Events
  SET_WALLET_CONTEXT = "SET_WALLET_CONTEXT",
}

export interface SetFlightSearchContext {
  type: ResumeEvent.SET_FLIGHT_SEARCH_CONTEXT;
  tripCategory: TripCategory;
  departureDate: string;
  returnDate?: string;
}

export interface SetFlightSearchOrigin {
  type: ResumeEvent.SET_FLIGHT_SEARCH_ORIGIN;
  origin: ITripTerminus;
}

export interface SetFlightSearchDestination {
  type: ResumeEvent.SET_FLIGHT_SEARCH_DESTINATION;
  destination: ITripTerminus;
}

export interface SetFlightShopContext {
  type: ResumeEvent.SET_FLIGHT_SHOP_CONTEXT;
  tripId: string;
  outgoingFareId?: string;
  returnFareId?: string;
}

export interface SetFlightShopTripDetails {
  type: ResumeEvent.SET_FLIGHT_TRIP_DETAILS;
  tripDetails: TripDetails;
  selectedFareId: string;
}

export interface SetContactInformationContext {
  type: ResumeEvent.SET_CONTACT_INFORMATION_CONTEXT;
  email: string;
  phone: string;
}

export interface SetPassengerInformationContext {
  type: ResumeEvent.SET_PASSENGER_INFORMATION_CONTEXT;
  userPassengers: Array<Person>;
  selectedPassengerIds: Array<string>;
  selectedLapInfantIds: Array<string>;
}

export interface SetCartQuoteContext {
  type: ResumeEvent.SET_CART_QUOTE_CONTEXT;
  breakdown: QuoteBreakdownResponse;
  cipherText: CipherText;
}

export interface SetPriceDropContext {
  type: ResumeEvent.SET_PRICE_DROP_CONTEXT;
  candidateId: string;
}

export interface SetLodgingShopContext {
  type: ResumeEvent.SET_LODGING_SHOP_CONTEXT;
  fromDate: string;
  untilDate: string;
  availabilityLink: string;
  guests: GuestsSelection;
  email: string;
  phone: string;
  lodgingCity: string;
  roomMediaUrl: string;
  roomMaxAdults: number;
}

export interface SetPassengerValidation {
  type: ResumeEvent.SET_PASSENGER_VALIDATION;
}

export interface SetTripPricing {
  type: ResumeEvent.SET_TRIP_PRICING;
  tripSummary: TripPricingSummary;
}

export interface SetWalletContext {
  type: ResumeEvent.SET_WALLET_CONTEXT;
  selectedOffer?: WalletOffer;
  offers: Array<WalletOffer>;
  offerApplied: boolean;
}

export type ResumeEvents =
  // Shared Events
  | SetContactInformationContext
  | SetPassengerInformationContext
  | SetCartQuoteContext
  // Flight Events
  | SetFlightSearchContext
  | SetFlightSearchOrigin
  | SetFlightSearchDestination
  | SetFlightShopContext
  | SetFlightShopTripDetails
  | SetPassengerValidation
  | SetTripPricing
  // Lodging Events
  | SetLodgingShopContext
  // Wallet Events
  | SetWalletContext;
