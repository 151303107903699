import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  useCheckoutStateSelector as useSelector,
  useCheckoutSend,
} from "@hopper-b2b/checkout";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, MobileFloatingSection } from "@hopper-b2b/ui";

import {
  getCreditInstallments,
  getMaximumCreditInstallment,
  getSelectedCreditInstallment,
} from "../../../../states/payments/selectors";
import { NubankPaymentEvents, PaymentEvent } from "../../../../states";
import { ReactComponent as ArrowBack } from "../../../../../assets/client/arrow-back.svg";
import { ReactComponent as NuPayLogo } from "../../../../../assets/client/nupay-logo.svg";
import { InstallmentPlan } from "../../../../states/payments/api/getPaymentMethods";
import { TermsAndConditionsContext } from "../../../../../components/TermsAndConditionsWrapper";

import { RadioListItem } from "../../../../../components/RadioListItem";
import { ConfirmPaymentModal } from "../ConfirmPaymentModal";
import styles from "./styles.module.scss";
import { trackEvent } from "@hopper-b2b/api";
import {
  INSTALLMENT_SCREEN_SHOWN,
  INSTALLMENT_SCREEN_SUBMIT,
} from "@hopper-b2b/types";

export const CreditInstallmentSelect = () => {
  const { t, formatFiatCurrency } = useI18nContext();
  const { setOpenModal: setTermsModal } = useContext(TermsAndConditionsContext);
  const history = useHistory();

  const send = useCheckoutSend<NubankPaymentEvents>();
  const creditInstallments = useSelector(getCreditInstallments);
  const maxCreditInstallment = useSelector(getMaximumCreditInstallment);
  const selectedCreditInstallment = useSelector(getSelectedCreditInstallment);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [selectedInstallment, setSelectedInstallment] = useState(
    selectedCreditInstallment
  );

  const handleContinue = () => {
    if (selectedInstallment) {
      trackEvent({
        eventName: INSTALLMENT_SCREEN_SUBMIT,
        properties: {
          installment_selected_number: selectedInstallment.numberOfInstallments,
        },
      });
      send({
        type: PaymentEvent.SELECT_INSTALLMENT,
        installmentPlan: selectedInstallment,
      });
      // TODO: figure out if fees = interest
      if (selectedInstallment.fees.amount > 0) {
        send(PaymentEvent.GO_TO_CREDIT_REVIEW);
      } else {
        setOpenModal(true);
      }
    } else {
      console.log("error in handleContinue");
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    trackEvent({ eventName: INSTALLMENT_SCREEN_SHOWN, properties: {} });
  }, []);

  return (
    <>
      <div className={styles.creditInstallmentSelect}>
        <div className="nupay-header">
          <ButtonWrap className="back-button" onClick={handleGoBack}>
            <ArrowBack className="arrow-back-icon" />
          </ButtonWrap>
          <NuPayLogo className="nupay-logo" />
        </div>
        <div className="content">
          <div className="title-container">
            <h1 className="header">{t("nupay.creditInstallmentHeader")}</h1>
            <p>
              {t("nupay.creditInstallmentSubtitle", {
                installments: maxCreditInstallment,
              })}
            </p>
          </div>
          <div className="option-list">
            <form>
              {creditInstallments.map((installment, index) => (
                <div className="credit-installment-item">
                  <RadioListItem
                    isSelected={selectedInstallment?.id === installment.id}
                    value={installment}
                    onSelect={setSelectedInstallment}
                    id={`credit-${index}`}
                    label={
                      <I18nMarkup
                        tKey={"nupay.creditInstallmentRadioItem"}
                        replacements={{
                          installments: installment.numberOfInstallments,
                          price: formatFiatCurrency({
                            value: installment.amount.amount,
                            currencyCode: installment.amount.currency,
                          }),
                        }}
                      />
                    }
                  />
                  <InterestTag installment={installment} />
                </div>
              ))}
            </form>
            <MobileFloatingSection
              primaryButton={{
                children: t("nupay.confirmPaymentButton"),
                onClick: handleContinue,
                disabled: !selectedInstallment,
              }}
              description={
                selectedInstallment ? (
                  <p className="floating-description">
                    <Trans
                      i18nKey="nupay.termsAndConditionsDisclaimer"
                      components={[
                        <ButtonWrap
                          className="payments-terms-link"
                          onClick={() => setTermsModal(true)}
                        />,
                      ]}
                    />
                  </p>
                ) : null
              }
            />
          </div>
        </div>
      </div>
      <ConfirmPaymentModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  );
};

const InterestTag = ({ installment }: { installment: InstallmentPlan }) => {
  const { t, formatFiatCurrency } = useI18nContext();

  // Zero interest
  if (installment.fees.amount === 0) {
    return <p className="interest-tag no-interest">{t("nupay.noInterest")}</p>;
  }
  return (
    <p className="interest-tag interest-price">
      {formatFiatCurrency({
        value: installment.fees.amount,
        currencyCode: installment.fees.currency,
      })}
    </p>
  );
};
