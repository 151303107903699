import { useCallback, useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import {
  customFormatDateTime,
  dateStringToNewDate,
  getEnvVariables,
  hasHTSConnectSupport,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { ClientName, ITripTerminus } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";
import { ActionLink } from "../ActionLink";
import "./styles.scss";

export interface IEditTripButtonsProps {
  assets?: Record<string, any>;
  isOneWay?: boolean;
  origin?: ITripTerminus;
  destination?: ITripTerminus;
  departureDate: Date;
  returnDate?: Date;
  openCalendarModal: boolean;
  openLocationModal: boolean;
  setOpenCalendarModal: (openCalendarModal: boolean) => any;
  setOpenLocationModal: (openLocationModal: boolean) => any;
  setTempDepartureDate?: (value: React.SetStateAction<Date>) => void;
  setTempReturnDate?: (value: React.SetStateAction<Date>) => void;
  setTempOrigin?: (value: React.SetStateAction<ITripTerminus>) => void;
  setTempDestination?: (value: React.SetStateAction<ITripTerminus>) => void;
  onFiltersClick: () => void;
  isInPredictionStep?: boolean;
  filterImg?: string;
  redirectHome?: () => void;
  customLocationSearchButton?: JSX.Element;
  locationLabel?: string;
  customEditLocation?: () => void;
}

const EditTripButtons = ({
  isOneWay = false,
  origin,
  destination,
  departureDate,
  returnDate,
  openCalendarModal,
  setOpenCalendarModal,
  openLocationModal,
  setOpenLocationModal,
  assets,
  setTempReturnDate,
  setTempDepartureDate,
  setTempOrigin,
  setTempDestination,
  onFiltersClick,
  isInPredictionStep,
  filterImg,
  redirectHome,
  customLocationSearchButton,
  locationLabel,
  customEditLocation,
}: IEditTripButtonsProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t, language: locale, brand } = useI18nContext();

  const handleEditCalendar = useCallback(() => {
    if (matchesMobile) {
      setOpenCalendarModal(true);
    } else {
      redirectHome();
    }
  }, [matchesMobile, redirectHome, setOpenCalendarModal]);

  const handleEditLocation = useCallback(() => {
    if (customEditLocation) {
      customEditLocation();
    } else if (matchesMobile) {
      setOpenLocationModal(true);
    } else {
      redirectHome();
    }
  }, [customEditLocation, matchesMobile, redirectHome, setOpenLocationModal]);

  const resetModalUpdates = useCallback(() => {
    if (
      (setTempDepartureDate && setTempReturnDate) ||
      (setTempOrigin && setTempDestination)
    ) {
      if (!openLocationModal) {
        setTempDepartureDate(departureDate);
        setTempReturnDate(returnDate);
      }
      if (!openCalendarModal) {
        setTempOrigin(origin);
        setTempDestination(destination);
      }
    }
  }, [
    departureDate,
    destination,
    openCalendarModal,
    openLocationModal,
    origin,
    returnDate,
    setTempDepartureDate,
    setTempDestination,
    setTempOrigin,
    setTempReturnDate,
  ]);

  useEffect(() => {
    if (!openCalendarModal && !openLocationModal) {
      resetModalUpdates();
    }
  }, [openCalendarModal, openLocationModal, resetModalUpdates]);

  const isHTSConnect = hasHTSConnectSupport();
  const isNubank = getEnvVariables("clientName") === ClientName.NUBANK;
  const showChevron = isNubank;

  const dateFormat = DateTimeFormatStyle.Custom({
    day: "numeric",
    month: "short",
  });

  const formattedDepartureDate = useMemo(() => {
    if (departureDate) {
      let date = departureDate;
      if (typeof departureDate == "string") {
        date = dateStringToNewDate(departureDate);
      }
      return customFormatDateTime(
        date,
        locale,
        dateFormat,
        brand?.customDateTimeFormats?.calendar
      );
    }
    return null;
  }, [
    brand?.customDateTimeFormats?.calendar,
    dateFormat,
    departureDate,
    locale,
  ]);

  const formattedReturnDate = useMemo(() => {
    if (!isOneWay && returnDate) {
      let date = returnDate;
      if (typeof returnDate == "string") {
        date = dateStringToNewDate(returnDate);
      }
      return customFormatDateTime(
        date,
        locale,
        dateFormat,
        brand?.customDateTimeFormats?.calendar
      );
    }
    return null;
  }, [
    brand?.customDateTimeFormats?.calendar,
    dateFormat,
    isOneWay,
    locale,
    returnDate,
  ]);

  const formattedDateString = useMemo(() => {
    if (isOneWay && departureDate) {
      return formattedDepartureDate;
    } else if (!isOneWay && departureDate && returnDate) {
      return `${formattedDepartureDate} - ${formattedReturnDate}`;
    }
    return null;
  }, [
    departureDate,
    formattedDepartureDate,
    formattedReturnDate,
    isOneWay,
    returnDate,
  ]);

  return (
    <Box className={clsx("locations-and-dates-section", locale)}>
      {/* Locations section */}
      {customLocationSearchButton ? (
        customLocationSearchButton
      ) : (
        <button className="locations-section" onClick={handleEditLocation}>
          {assets?.locationMarker ? (
            <img
              alt="Location Icon"
              className="location-section-icon"
              src={assets.locationMarker}
            />
          ) : (
            <IconComponent
              ariaLabel="Location Icon"
              name={IconName.B2BMapPin}
              className="location-section-icon"
            />
          )}
          <span className="locations-section-value">{locationLabel}</span>
          {showChevron ? (
            <IconComponent name={IconName.ChevronDown} className="chevron" />
          ) : null}
        </button>
      )}

      {/* Dates section */}
      <button className="dates-section" onClick={handleEditCalendar}>
        {assets?.calendar ? (
          <img
            alt="Date Icon"
            className="dates-section-icon"
            src={assets.calendar}
          />
        ) : (
          <IconComponent
            ariaLabel="Date Icon"
            className="dates-section-icon"
            name={IconName.Calendar}
          />
        )}
        <span className="dates-section-value">
          {formattedDateString}
          {isInPredictionStep ? (
            <ActionLink
              className="edit-date-button"
              onClick={handleEditCalendar}
              content={<IconComponent name={IconName.B2BEditPencil} />}
            />
          ) : null}
        </span>
        {showChevron ? (
          <IconComponent name={IconName.ChevronDown} className="chevron" />
        ) : null}
      </button>
      {isHTSConnect ? (
        <ActionLink
          className="prediction-filters-button"
          onClick={onFiltersClick}
          content={
            filterImg ? (
              <img src={filterImg} alt={t("filterAndSortMenu")} />
            ) : (
              <IconComponent
                ariaLabel={t("filterAndSortMenu")}
                className={clsx("filter-icon")}
                name={IconName.FilterMenu}
              />
            )
          }
        />
      ) : null}
    </Box>
  );
};

export default EditTripButtons;
