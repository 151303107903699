import { connect, ConnectedProps } from "react-redux";
import { OriginalPriceFreezeInfo } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getFrozenPriceFromPriceFreeze,
  getTripCategoryFromPriceFreeze,
  getFlightSummaryPanelPropsFromPriceFreeze,
} from "../../../freeze/reducer/selectors";
import {
  flightShopProgressSelector,
  fetchSimilarFlightsCallStateSelector,
} from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    frozenPrice: getFrozenPriceFromPriceFreeze(state),
    tripCategory: getTripCategoryFromPriceFreeze(state),
    flightSummary: getFlightSummaryPanelPropsFromPriceFreeze(state),
    fetchSimilarFlightsCallState: fetchSimilarFlightsCallStateSelector(state),
    flightShopStep: flightShopProgressSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OriginalPriceFreezeInfoConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedOriginalPriceFreezeInfo = connector(
  OriginalPriceFreezeInfo
);
