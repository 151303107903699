import {
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  FintechProductOption,
  FintechProductType,
} from "@hopper-b2b/types";
import { FintechPurchaseCard } from "../FintechPurchaseCard";
import { MobileFloatingSection } from "../MobileFloatingSection";
import "./styles.scss";
import clsx from "clsx";
import { LearnMore } from "../LearnMore";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { useCallback } from "react";
import { trackEvent } from "@hopper-b2b/api";

export interface IFintechPurchaseProps {
  options: FintechProductOption[];
  headerTitle?: string;
  headerSubtitle: string | JSX.Element;
  ctaLabel: string;
  handleContinue?: () => void;
  headerImgSrc?: string;
  className?: string;
  clientAssets?: Record<string, any>;
  modal?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isHalfSheet?: boolean;
}
export const FintechPurchase = ({
  options,
  headerTitle,
  headerSubtitle,
  ctaLabel,
  handleContinue,
  headerImgSrc,
  className,
  clientAssets,
  modal = false,
  disabled = false,
  loading = false,
  isHalfSheet = false,
}: IFintechPurchaseProps) => {
  const { t } = useI18nContext();

  // Cfar Learn more within Fintech Selection Page
  const onOpenCfarLearnMore = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.OFFER },
    });
  }, []);

  return (
    <div
      className={clsx("fintech-purchase", className, {
        modal: modal || isHalfSheet,
        "half-sheet": isHalfSheet,
      })}
    >
      <div className="fintech-purchase-content">
        <div className="fintech-purchase-header">
          <div>
            {headerTitle && (
              <h2 className="fintech-purchase-title">{headerTitle}</h2>
            )}
            <div className="fintech-purchase-subtitle">
              <p className="fintech-purchase-subtitle-content">
                {headerSubtitle}
              </p>
              <div className="fintech-purchase-image-container">
                {headerImgSrc ? (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img src={headerImgSrc} className="fintech-purchase-image" />
                ) : null}
              </div>
            </div>
            <div className="fintech-purchase-learn-more">
              {isHalfSheet && (
                <LearnMore
                  label={t("learnMore")}
                  description={
                    <I18nMarkup tKey={"cfarOffers.learnMoreDescription"} />
                  }
                  productType={FintechProductType.CfarPurchase}
                  descriptionAdditional={t("cfarOffers.learnMoreDescription2")}
                  header={t("cfarOffers.learnMoreHeader")}
                  title={t("cfarOffers.learnMoreTitle")}
                  className="cfar-learn-more"
                  onOpen={onOpenCfarLearnMore}
                />
              )}
            </div>
          </div>
        </div>
        <div className="fintech-purchase-card-section">
          <h3 className="fintech-purchase-card-section-title">
            {t("selectCoverageType")}
          </h3>
          {options.map((option: FintechProductOption) => (
            <FintechPurchaseCard
              isHalfSheet={isHalfSheet}
              key={option.value}
              option={option}
              className={className}
              clientAssets={clientAssets}
              loading={loading}
            />
          ))}
        </div>
      </div>

      <div className="fintech-purchase-per-pax-disclaimer">
        {t("perPaxDisclaimerText")}
      </div>
      {handleContinue ? (
        <MobileFloatingSection
          className="fintech-purchase-floating-section fintech-floating-section"
          primaryButton={{
            children: ctaLabel,
            // children: t("declineCta"), // For testing
            onClick: handleContinue,
            className: "fintech-purchase-button accept",
            wrapperClassName: "fintech-purchase-button-container",
            disabled: disabled,
          }}
        />
      ) : null}
    </div>
  );
};
