import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { MenuItem, TextField } from "@material-ui/core";
import { useCallback } from "react";
import { useTrackEvents } from "../../../../tracking";
import styles from "./SortInput.module.scss";
import { SortOption } from "@hopper-b2b/lodging-utils";

interface Props {
  value: SortOption;
  setSort(nextValue: SortOption): void;
}

export function SortInput({ value, setSort }: Props) {
  const { t } = useI18nContext();
  const trackEvent = useTrackEvents();

  const options: { value: SortOption; label: string }[] = [
    {
      value: SortOption.MOST_RECOMMENDED,
      label: t("mostRecommended"),
    },
    {
      value: SortOption.STAR_RATING,
      label: t("starRatingHighestFirst"),
    },
    {
      value: SortOption.USER_RATING,
      label: t("userRatingHighestFirst"),
    },
    {
      value: SortOption.PRICE,
      label: t("priceLowestFirst"),
    },
  ];

  const onChange = useCallback(
    (event) => {
      setSort(event.target.value as SortOption);
      trackEvent(LodgingShopTrackingEvents.hotel_updated_list_page);
    },
    [setSort, trackEvent]
  );

  return (
    <TextField
      className={styles.Select}
      variant="filled"
      label={t("sortBy")}
      value={value}
      onChange={onChange}
      onClick={() => trackEvent(LodgingShopTrackingEvents.hotel_tapped_sort)}
      select
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
