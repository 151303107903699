import { useContext } from "react";
import { fetchUserPassengers, getQuoteBreakdown } from "@hopper-b2b/api";
import {
  CipherText,
  QuoteBreakdownResponse,
  QuoteState,
} from "@b2bportal/purchase-api";
import {
  PersonListResponse,
  PersonListSuccess,
} from "@b2bportal/air-booking-api";
import queryStringParser from "query-string";
import { History } from "history";
import dayjs from "dayjs";

import {
  ResumeStep,
  ResumeCheckoutComponent,
  ResumeEvent,
  parseLodgingQueryString,
  SetLodgingShopContext,
  ParentState,
} from "@hopper-b2b/checkout";
import { WalletVouchersResponse } from "@hopper-b2b/types";

import { emailRegex } from "@hopper-b2b/utilities";
import { LoadingScreen } from "../../../checkout/components/LoadingScreen";
import { Event } from "../events";
import { nubankPhoneRegex } from "../../../components/Slots/ContactInfoForm/component";
import { validateContext } from "../utilities";
import { NubankPaymentEvent } from "../../../checkout";
import { UserContext } from "../../../App";
import {
  EnrollmentResponse,
  PAYMENT_ENROLLMENT_STATUS_PARAM,
} from "../../../checkout/states/payments/api/checkAuthStatus";
import { PATH_HOTELS_SEARCH } from "../../../utils/urlPaths";
import { decodeEmailAndPhone } from "../../../checkout/helpers";
import { getWalletOffers } from "../../../api/wallet/fetchWalletOffers";
import { LodgingMachineContext } from "../types";

export const RefreshCheckout = () => {
  const { sessionInfo } = useContext(UserContext);

  return sessionInfo?.userInfoResponse?.userId ? (
    <ResumeCheckoutComponent
      steps={getLodgingRefreshSteps(sessionInfo.userInfoResponse.userId)}
      validateContext={validateContext}
    >
      <LoadingScreen />
    </ResumeCheckoutComponent>
  ) : null;
};

const contactInfoValidator = async (
  history: History,
  userId: string
): Promise<boolean> => {
  try {
    const { email, phone } = parseLodgingQueryString(history);
    if (!email || !phone) {
      return false;
    }
    const { decodedEmail, decodedPhone } = await decodeEmailAndPhone({
      email,
      phone,
      userId,
    });
    if (
      decodedEmail &&
      decodedPhone &&
      emailRegex.test(decodedEmail) &&
      nubankPhoneRegex.test(decodedPhone)
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.log("decoding failed in contactInfoValidator", error);
    return false;
  }
};

const getLodgingRefreshSteps = (userId: string): Array<ResumeStep> => [
  // Step 1: check if we have lodging shop params, if not kick them to PATH_HOTELS_SEARCH
  {
    fallback: {
      url: (history) => {
        const { availabilityLink } = parseLodgingQueryString(history);
        return availabilityLink || `/${PATH_HOTELS_SEARCH}`;
      },
    },
    validator: (history) => {
      const { fromDate, untilDate, adultsCount, selectedPassengerIds } =
        parseLodgingQueryString(history);
      if (
        fromDate &&
        dayjs(fromDate).isValid() &&
        untilDate &&
        dayjs(untilDate).isValid()
      ) {
        if (parseInt(adultsCount, 10) > 0) {
          if (
            contactInfoValidator(history, userId) &&
            selectedPassengerIds.length > 0
          ) {
            return true;
          }
        }
      }

      return false;
    },
    getStepEvent: async (history) => {
      const {
        fromDate,
        untilDate,
        adultsCount,
        // TODO: Add in when childrenCount param is fixed in lodging shop
        /* childrenCount, */
        availabilityLink,
        email,
        phone,
        lodgingCity,
        roomMediaUrl,
        roomMaxAdults,
      } = parseLodgingQueryString(history);
      const { decodedEmail, decodedPhone } = await decodeEmailAndPhone({
        email,
        phone,
        userId,
      });
      return {
        type: ResumeEvent.SET_LODGING_SHOP_CONTEXT,
        fromDate,
        untilDate,
        availabilityLink: decodeURIComponent(availabilityLink),
        guests: {
          adults: parseInt(adultsCount, 10),
          // TODO: Add in when childrenCount param is fixed in lodging shop
          /* childrenCount: childrenCount ? childrenCount:[] */
        },
        email: decodedEmail,
        phone: decodedPhone,
        lodgingCity: decodeURIComponent(lodgingCity),
        roomMediaUrl: decodeURIComponent(roomMediaUrl),
        roomMaxAdults: parseInt(roomMaxAdults, 10),
      } as SetLodgingShopContext;
    },
    requests: [
      {
        api: fetchUserPassengers,
        responseEvent: (response: PersonListResponse, history) => {
          const { selectedPassengerIds } = parseLodgingQueryString(history);

          if (response.Response === "Success") {
            const passengers = (response as PersonListSuccess).value;
            const passengerIds = passengers.map((p) => p.id);

            if (selectedPassengerIds.every((id) => passengerIds.includes(id))) {
              return {
                type: ResumeEvent.SET_PASSENGER_INFORMATION_CONTEXT,
                userPassengers: passengers,
                selectedPassengerIds,
              };
            } else {
              console.log("invalid passengers during refresh steps");
            }
          } else {
            throw new Error("fetchUserPassengers failure");
          }
        },
      },
    ],
  },
  // Step 2: Check if Cart Quote has run, we cannot resume checkout in lodging until after quote
  // - because we can't fetch the necessary data to hydrate the checkout review screen
  {
    fallback: {
      url: (history) => {
        const { availabilityLink } = parseLodgingQueryString(history);
        return availabilityLink;
      },
    },
    validator: (history) => {
      const { cartToken } = parseLodgingQueryString(history);
      return !!cartToken;
    },
    requests: [
      {
        api: getQuoteBreakdown,
        requestPayload: (history) => {
          const { cartToken } = parseLodgingQueryString(history);
          return { value: cartToken } as CipherText;
        },
        responseEvent: (response: QuoteBreakdownResponse, history) => {
          const { cartToken } = parseLodgingQueryString(history);
          if (
            response.quoteBreakdown.state === QuoteState.Empty ||
            response.quoteBreakdown.state === QuoteState.Unavailable
          ) {
            throw Error(
              `quoteBreakdown error with quoteState: ${response.quoteBreakdown.state}`
            );
          }
          return {
            type: ResumeEvent.SET_CART_QUOTE_CONTEXT,
            breakdown: response,
            cipherText: { value: cartToken },
          };
        },
        errorEvent: () => ({ type: ResumeEvent.GO_TO_PASSENGER_SELECT }),
      },
    ],
  },
  // Step 3: Wallet
  {
    fallback: { event: ResumeEvent.GO_TO_WALLET },
    skipStep: (context: LodgingMachineContext) =>
      !context[ParentState.featureFlags].enableWallet,
    validator: (history) => {
      const { selectedWalletOffer } = parseLodgingQueryString(history);
      return !!selectedWalletOffer;
    },
    requests: [
      {
        api: getWalletOffers,
        responseEvent: (response: WalletVouchersResponse, history) => {
          const { selectedWalletOffer } = parseLodgingQueryString(history);
          const selectedOffer = response.offers.find(
            (offer) => offer.id === selectedWalletOffer
          );

          if (selectedOffer) {
            return {
              type: ResumeEvent.SET_WALLET_CONTEXT,
              selectedOffer: selectedOffer,
              offers: response.offers,
              offerApplied: true,
            };
          } else if (response.offers.length > 0) {
            return {
              type: ResumeEvent.SET_WALLET_CONTEXT,
              offers: response.offers,
              offerApplied: false,
            };
          }
        },
        errorEvent: () => ({ type: ResumeEvent.GO_TO_REVIEW }),
      },
    ],
    getStepEvent: () => ({
      type: ResumeEvent.GO_TO_REVIEW,
    }),
  },
];

export const parsePaymentQueryParams = (history: History) => {
  const queryString = history?.location?.search || "";
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  return {
    enrollmentResponse: parsedQueryStringPrimitive[
      PAYMENT_ENROLLMENT_STATUS_PARAM
    ] as string,
  };
};
