import { AmenityStatus } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Chip, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import {
  mappedOrderedAmenities,
  orderedAmenitiesLength,
} from "../../../../../../util/utils";
import styles from "./Amenities.module.scss";

const CARD_AMENITIES = 3;

interface Props {
  amenities: AmenityStatus[];
  className?: string;
}

export function Amenities({ amenities, className }: Props) {
  const { t } = useI18nContext();

  const memoedAmenities = useMemo(() => {
    const getIndex = (amenity: string) => {
      const i = mappedOrderedAmenities[amenity.toLowerCase()] ?? -1;
      return i >= 0 ? i : orderedAmenitiesLength;
    };

    return [...amenities]
      .sort((a, b) => getIndex(a.amenity) - getIndex(b.amenity))
      .slice(0, CARD_AMENITIES);
  }, [amenities]);

  const moreAmenitiesCount = amenities?.length - memoedAmenities?.length;

  return (
    <div className={className}>
      <Grid container spacing={2} alignItems="center">
        {memoedAmenities.map(({ displayName }) => (
          <Grid key={displayName} item>
            <Chip
              className={clsx(styles.tag, "AmenitiesTag")}
              label={displayName}
              size="small"
              color="default"
            />
          </Grid>
        ))}
        {moreAmenitiesCount > 0 && (
          <Grid item>
            <Typography className="AmenitiesMoreText" variant="caption">
              {t("more", { count: moreAmenitiesCount })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
