import { AirChfarOffer } from "@b2bportal/air-chfar-api";
import { CallState, ChfarOfferProperties } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../../../flights/src/reducers/types";
import { isEmpty } from "lodash-es";

export const getChfarOffers = (
  state: IStoreState
): AirChfarOffer[] | undefined => state.flightChfar.chfarOffers;

export const getChfarOffersCallState = (state: IStoreState): CallState =>
  state.flightChfar.chfarOfferCallState;

export const getSelectedChfarOffer = (
  state: IStoreState
): AirChfarOffer | undefined => state.flightChfar.selectedChfarOffer;

export const getChfarOfferQuoteId = (state: IStoreState) =>
  state.flightChfar.selectedChfarOffer?.quoteId as string;

export const isChfarOffersLoading = createSelector(
  getChfarOffersCallState,
  (chfarOfferCallState: CallState) =>
    chfarOfferCallState === CallState.InProcess
);

export const isChfarOffersValid = (includeNotCalled: boolean) =>
  createSelector(
    getChfarOffersCallState,
    getChfarOffers,
    (callState: CallState, offers?: AirChfarOffer[]) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !isEmpty(offers))
  );

export const getCompleteBuyAirChfarProperties = createSelector(
  getSelectedChfarOffer,
  (selectedChfarOffer): ChfarOfferProperties | undefined => {
    if (!selectedChfarOffer || Object.keys(selectedChfarOffer).length === 0) {
      return;
    }

    return {
      chfar_quote_id: selectedChfarOffer.quoteId,
      chfar_coverage_percentage: selectedChfarOffer.coveragePercentage,
      chfar_coverage_amount: selectedChfarOffer.coverageAmount.fiat.value,
      chfar_premium_amount: selectedChfarOffer.premiumAmount.fiat.value,
      chfar_currency_code: selectedChfarOffer.premiumAmount.fiat.currencyCode,
    };
  }
);

export const getChfarTotal = (state: IStoreState) => {
  const offer = getSelectedChfarOffer(state);
  return offer?.premiumAmount ? offer.premiumAmount.fiat.value : 0;
};

export const getSelectedChfarTrackingProperties = (state: IStoreState) => {
  const offer = getSelectedChfarOffer(state);
  return offer?.trackingProperties;
};

export const getChfarTrackingProperties = (state: IStoreState) => {
  const offer = getChfarOffers(state)?.[0];
  return offer?.trackingProperties;
};
