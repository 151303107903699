import { Typography } from "@material-ui/core";
import { useMemo } from "react";

import Chevron from "../../../../../../assets/client/chevron.svg";
import ChevronWhite from "../../../../../../assets/client/darkMode/chevron-white.svg";
import DollarSign from "../../../../../../assets/client/dollar-sign.svg";
import DollarSignWhite from "../../../../../../assets/client/darkMode/dollar-sign-white.svg";
import CashBackIcon from "../../../../../../assets/client/cash-back-icon.svg";
import CashBackIconLight from "../../../../../../assets/client/darkMode/cash-back-icon-light.svg";
import "./styles.scss";
import { ButtonWrap } from "@hopper-b2b/ui";
import { useDarkModePreferred } from "../../../../../../utils/colors";

export interface PriceSummaryRowProps {
  priceString: string;
  description?: string;
  onClick?: () => void;
  cashBackAmount?: string;
}

export const PriceSummaryRow = ({
  priceString,
  description,
  onClick,
  cashBackAmount,
}: PriceSummaryRowProps) => {
  const isDarkModePreferred = useDarkModePreferred();
  const summaryRow = useMemo(() => {
    return (
      <div className="price-summary-row">
        <div className="price-summary-row-left">
          <div className="price-summary-row-icon">
            <img
              className="mobile-left-icon"
              src={isDarkModePreferred ? DollarSignWhite : DollarSign}
              alt=""
            />
          </div>

          <div className="price-summary-row-value">
            <div className="price-text">
              <Typography className="content" variant="body1">
                {priceString}
              </Typography>
            </div>
            {description ? (
              <div className="description-text">{description}</div>
            ) : null}
          </div>
        </div>
        <div className="price-summary-row-right">
          <div className="flight-card-cash-back">
            <img
              src={isDarkModePreferred ? CashBackIconLight : CashBackIcon}
              alt=""
            />
            <p>{cashBackAmount}</p>
          </div>
          {onClick ? (
            <img
              className="mobile-right-chevron"
              src={isDarkModePreferred ? ChevronWhite : Chevron}
              alt=""
            />
          ) : null}
        </div>
      </div>
    );
  }, [cashBackAmount, description, isDarkModePreferred, onClick, priceString]);

  return onClick ? (
    <ButtonWrap className="price-summary-row-button" onClick={onClick}>
      {summaryRow}
    </ButtonWrap>
  ) : (
    summaryRow
  );
};

export default PriceSummaryRow;
