import { Box } from "@material-ui/core";
import CloseButtonIcon from "../../assets/icons/common/close-button.svg";
import "./styles.scss";
import clsx from "clsx";
import { Icon, IconName } from "../Icon";
import { ActionLink } from "../ActionLink";

export interface IAlertBanner {
  onClick: () => void;
  content: JSX.Element;
  className?: string;
}

export const AlertBanner = (props: IAlertBanner) => {
  const { content, onClick, className } = props;

  return (
    <Box className={clsx("alert-banner-root", className)}>
      <Box className="alert-banner-content">
        <Icon name={IconName.BellWarning} />
        {content}
      </Box>
      <ActionLink
        className="alert-close-button"
        onClick={onClick}
        content={<img src={CloseButtonIcon} alt="close button icon" />}
        label="Close"
      />
    </Box>
  );
};

export default AlertBanner;
