import {
  ContactSelectors,
  PassengerEventTypes,
  PassengerEvents,
  PassengerSelectors,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";
import { LodgingMachineContext } from "../types";
import dayjs from "dayjs";

const validPassenger = (
  context: LodgingMachineContext,
  event: Extract<
    PassengerEvents,
    { type: PassengerEventTypes.SELECT_PASSENGER }
  >
) => {
  const personId = event.passengerId;
  const userPassengers = PassengerSelectors.getUserPassengers({ context });
  const selectedPassenger = userPassengers.find((p) => p.id === personId);
  const age = dayjs().diff(
    dayjs(selectedPassenger.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  return age >= 18;
};

export const commonGuards = {
  validPassenger,
  selectingAndValidPassenger: (
    context: LodgingMachineContext,
    event: Extract<
      PassengerEvents,
      { type: PassengerEventTypes.SELECT_PASSENGER }
    >
  ) => {
    const personId = event.passengerId;
    const selectedPassengerIds = PassengerSelectors.getSelectedPassengerIds({
      context,
    });
    const alreadySelected = selectedPassengerIds.some((id) => id === personId);
    return !alreadySelected && validPassenger(context, event);
  },
  canRunCartQuote: (context: LodgingMachineContext, _event) => {
    const isPassengerStateValid = PassengerSelectors.getPassengerStateValidated(
      { context }
    );
    const isContactStateValid = ContactSelectors.getContactInformationValidated(
      { context }
    );

    return isPassengerStateValid && isContactStateValid;
  },
};

export const CommonGuardTypes = getObjectKeysAsObject(commonGuards);
