import { Person, SeatSlice } from "@b2bportal/air-booking-api";
import { TripSlice } from "@b2bportal/air-shopping-api";
import {
  GordianSeatSegment,
  SeatInfo,
  SelectedSeatsSegment,
  TripSegment,
} from "@hopper-b2b/types";

export const CHOOSE_SEAT_FRAME_HASH = "#CHOOSE_SEAT";

export interface SeatMapOptions {
  modal: boolean;
  fontFamily: string;
  textPrimary?: string;
  textSecondary?: string;
  theme?: {
    textPrimary?: string;
    textSecondary?: string;
    title?: string;
    primary?: string;
    secondary?: string;
    alert?: string;
    buttonBorderRadius?: string;
    seats?: {
      1?: {
        // Standard
        fill?: string;
        stopColor?: string;
        labelColor?: string;
        borderColor?: string;
      };
      2?: {
        // Preferred
        fill?: string;
        stopColor?: string;
        labelColor?: string;
        borderColor?: string;
      };
      3?: {
        // Extra Legroom
        fill?: string;
        stopColor?: string;
        labelColor?: string;
        borderColor?: string;
      };
      4?: {
        // Exit Row
        fill?: string;
        stopColor?: string;
        labelColor?: string;
        borderColor?: string;
      };
      5?: {
        // Front Seat
        fill?: string;
        stopColor?: string;
        labelColor?: string;
        borderColor?: string;
      };
      not_available?: {
        fill?: string;
        stopColor?: string;
        borderColor?: string;
      };
      selected_seat?: {
        fill?: string;
        stopColor?: string;
        labelColor?: string;
      };
    };
    confirmed_seat?: {
      fill?: string;
      color?: string;
      background?: string;
      borderColor?: string;
    };
  };
}

export const SEAT_MAP_OPTIONS: SeatMapOptions = {
  modal: false,
  fontFamily: "Helvetica Neue",
};

export const STYLE_STRING = `@font-face { font-family: "Helvetica Neue"; font-style: normal; font-weight: normal; src: local("Helvetica Neue"); }`;

export const getSeatNotSelected = (passenger: Person) => {
  return {
    carrier: "",
    display_name: "",
    pax: `${passenger.givenName} ${passenger.surname}`,
    pax_id: "",
    person_id: passenger.id,
    seat_id: "",
    total: {
      amount: 0,
      amountUsd: 0,
      currency: "",
      decimalPlaces: 0,
      display: "",

      mantissa: 0,
      mantissaUsd: 0,
    },
  };
};

export const getSeatSelectionNotAvail = (passenger: Person) => {
  return {
    carrier: "",
    display_name: "",
    pax: `${passenger.givenName} ${passenger.surname}`,
    pax_id: "",
    person_id: passenger.id,
    seat_id: "Unavailable",
    total: {
      amount: 0,
      amountUsd: 0,
      currency: "",
      decimalPlaces: 0,
      display: "",

      mantissa: 0,
      mantissaUsd: 0,
    },
  };
};

export const getSelectedSeatsSegments = (
  selectedSeatsInfo: GordianSeatSegment[],
  slice: TripSlice,
  passengers: Person[],
  outgoingSegmentCount: number,
  sliceInfo?: SeatSlice
) => {
  const sliceSelectedSeats: SelectedSeatsSegment[] = [];
  // create seat info map - origin-destination as key -> segment as value
  const seatInfoMap: { [key: string]: GordianSeatSegment } = {};
  // create seat passenger map - origin-destination-passId as key -> seat as value
  const seatPassengerMap: { [key: string]: SeatInfo } = {};
  selectedSeatsInfo.forEach((seat: GordianSeatSegment) => {
    seatInfoMap[`${seat.originCode}-${seat.destinationCode}`] = seat;
    seat.seats.forEach((seatInfo: SeatInfo) => {
      seatPassengerMap[
        `${seat.originCode}-${seat.destinationCode}-${seatInfo.person_id}`
      ] = seatInfo;
    });
  });

  let seats: GordianSeatSegment;
  slice.segmentDetails.forEach((segment: TripSegment, idx: number) => {
    const selectedSeatsSegment =
      seatInfoMap[`${segment.originCode}-${segment.destinationCode}`];
    if (selectedSeatsSegment) {
      if (selectedSeatsSegment.seats.length !== passengers.length) {
        // determine missing passengers - see if the seatPassengerMap has a key that matches org/dest/id
        passengers.forEach((passenger: Person) => {
          const seatInfo: SeatInfo =
            seatPassengerMap[
              `${segment.originCode}-${segment.destinationCode}-${passenger.id}`
            ];
          if (!seatInfo) {
            selectedSeatsSegment.seats.push(getSeatNotSelected(passenger));
          }
        });
      }
      seats = selectedSeatsSegment;
    } else if (
      sliceInfo?.segments.find(
        (seg) =>
          seg.origin === segment.originCode &&
          seg.destination === segment.destinationCode &&
          !seg.seatsAvailable
      )
    ) {
      seats = {
        departureDate: segment.departureTime,
        destinationCode: segment.destinationCode,
        originCode: segment.originCode,
        seats: passengers.map((p: Person) => getSeatSelectionNotAvail(p)),
      };
    } else {
      seats = {
        departureDate: segment.departureTime,
        destinationCode: segment.destinationCode,
        originCode: segment.originCode,
        seats: passengers.map((p: Person) => getSeatNotSelected(p)),
      };
    }
    sliceSelectedSeats.push({
      originCode: segment.originCode,
      flightNumber: segment.flightNumber,
      seats,
      isOutgoing: slice.outgoing,
      idx: slice.outgoing ? idx : outgoingSegmentCount + idx,
    });
  });
  return sliceSelectedSeats;
};
