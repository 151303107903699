import { GenericShopListFooter } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { RouteComponentProps } from "react-router";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import "./styles.scss";
import { FlightFindMoreResultsConnectorProps } from "./container";
import { PATH_HOME, PATH_HOME_SEARCH } from "../../../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../../../../search/reducer";

export interface IFlightFindMoreResultsProps
  extends FlightFindMoreResultsConnectorProps,
    RouteComponentProps {
  className?: string;
}

export const FlightFindMoreResults = (props: IFlightFindMoreResultsProps) => {
  const {
    className,
    resetFilters,
    setMobileSearchProgress,
    history,
    setOpenCalendarModal,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  return (
    <GenericShopListFooter
      className={clsx("find-more-results", className, "b2b")}
      title={t("flightShopFooterTitle")}
      subtitle={t("flightShopFooterSubtitle")}
      buttons={[
        {
          title: t("flightShopAdjustDates"),
          isPrimary: true,
          onClick: () => {
            if (matchesMobile) {
              setMobileSearchProgress(MobileFlightSearchStep.CalendarPicker);
              history.push(matchesMobile ? PATH_HOME_SEARCH : PATH_HOME);
            } else {
              setOpenCalendarModal(true);
            }
          },
          className: "b2b",
        },
        {
          title: t("resetFilters"),
          onClick: () => resetFilters(),
          className: "b2b",
        },
      ]}
      isMobile={matchesMobile}
    />
  );
};
