import { useDeviceTypes } from "@hopper-b2b/utilities";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  FARE_CLASS_DETAILS_I18N_KEY,
  FARE_CLASS_I18N_KEY,
} from "../../../FlightShopHeader/components/FareClassOptionSelection/constants";
import "./styles.scss";
import { MobileFilterFareDetailsConnectorProps } from "./container";
import { useI18nContext } from "@hopper-b2b/i18n";

export const MobileFilterFareDetails = (
  props: MobileFilterFareDetailsConnectorProps
) => {
  const { fareClassFilters } = props;
  const selectedFilter = Object.keys(fareClassFilters).find(
    (key) => fareClassFilters[key]
  );
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  if (!matchesMobile || !selectedFilter) return null;

  return (
    <Box className="fare-class-details-card">
      <Typography className="fare-class-details-title">
        {`${t(FARE_CLASS_I18N_KEY[selectedFilter])} Fares`}
      </Typography>
      <Typography className="fare-class-details-body">
        {t(FARE_CLASS_DETAILS_I18N_KEY[selectedFilter])}
      </Typography>
    </Box>
  );
};
