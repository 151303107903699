import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, ITripTerminus } from "@hopper-b2b/types";
import {
  getEnvVariables,
  useEnableDisplayBasicFareOnly,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { FlightShopStep } from "../../../reducer";

const HeaderText: FC<{
  origin?: ITripTerminus | null;
  destination?: ITripTerminus | null;
  awaitingRefetch: boolean;
  isOneWay: boolean;
  currentStep: FlightShopStep;
  onFiltersClick?: () => void;
}> = ({
  origin,
  destination,
  currentStep,
  awaitingRefetch,
  isOneWay,
  onFiltersClick,
}) => {
  const isNubank = getEnvVariables("clientName") === ClientName.NUBANK;
  const { t } = useI18nContext();

  let headerText = "";
  let subheaderText = "";

  const prevDestination = usePrevious(destination);
  const prevOrigin = usePrevious(origin);
  const isEnableDisplayBasicFareOnly = useEnableDisplayBasicFareOnly();
  const [destinationLabel, setDestinationLabel] = useState("");
  const [originLabel, setOriginLabel] = useState("");

  useEffect(() => {
    setDestinationLabel(
      awaitingRefetch && prevDestination
        ? prevDestination.label
        : destination?.label || ""
    );
    setOriginLabel(
      awaitingRefetch && prevOrigin ? prevOrigin.label : origin?.label || ""
    );
  }, [awaitingRefetch, destination, origin, prevDestination, prevOrigin]);

  switch (currentStep) {
    case FlightShopStep.ChooseDeparture:
      // TODO: Find out if we can get location without state/airport code from BE since we cannot guarantee split method to work 100%
      headerText = t("flightShopHeader.departureTitle", {
        location: destinationLabel.split(",")[0],
      });
      subheaderText = isOneWay
        ? t("flightShopHeader.subtitleSingleTrip")
        : t("flightShopHeader.subtitleRoundTrip");
      break;

    case FlightShopStep.ChooseReturn:
      headerText = t("flightShopHeader.returnTitle", {
        location: originLabel.split(",")[0],
      });
      subheaderText = t("flightShopHeader.subtitleRoundTrip");
      break;

    case FlightShopStep.ReviewItinerary:
      headerText = t("flightShopReview.itineraryHeader");
      subheaderText = t("flightShopReview.itinerarySubheader");
      break;

    default:
      break;
  }

  return (
    <Box className="header-root">
      <Box className="header-container">
        <Box className="header-text-wrapper">
          <Typography
            variant={
              currentStep === FlightShopStep.ChooseDeparture ||
              currentStep === FlightShopStep.ChooseReturn
                ? "h6"
                : "h4"
            }
            dangerouslySetInnerHTML={{ __html: headerText }}
            className="flight-shop-header-title"
          />
        </Box>
        <Box className="subheader-text-wrapper">
          <Typography
            variant="body1"
            className={clsx("flight-shop-header-subtitle", {
              hidden: isEnableDisplayBasicFareOnly,
            })}
          >
            {subheaderText}
          </Typography>
        </Box>
      </Box>
      {onFiltersClick && isNubank ? (
        <button
          className={clsx("flight-shop-header-filters-button")}
          onClick={onFiltersClick}
        >
          {t("filter")}
        </button>
      ) : null}
    </Box>
  );
};

export default HeaderText;
