import dayjs from "dayjs";

export const getRequiredFieldError = (fieldValue: string | null) =>
  !fieldValue || fieldValue.length === 0;

export const ExpiryDateI18nKeys = {
  INVALID: "expiryDateInvalid",
  INVALID_DATE: "expiryDateInvalidDate",
  REQUIRED: "expiryDateRequired",
  IS_BEFORE: "expiryDateIsBefore",
};

export const getExpiryDateError = ({
  expiryDate,
  format = "MM/YYYY",
}: {
  expiryDate: string | null;
  format?: "MM/YYYY" | "MM/YY";
}): string | undefined => {
  if (!expiryDate) {
    return ExpiryDateI18nKeys.REQUIRED;
  }
  const isExpiryDateValid = () => {
    switch (format) {
      case "MM/YYYY":
        return expiryDate.match(/(0[1-9]|10|11|12)\/20[0-9]{2}$/);
      case "MM/YY":
        return expiryDate.match(/(0[1-9]|10|11|12)\/[0-9]{2}$/);
    }
  };
  if (!isExpiryDateValid()) {
    return ExpiryDateI18nKeys.INVALID;
  }
  const expiryDateMoment = dayjs(expiryDate, format, true);

  if (!expiryDateMoment.isValid()) {
    return ExpiryDateI18nKeys.INVALID_DATE;
  }

  if (expiryDateMoment.isBefore(dayjs())) {
    return ExpiryDateI18nKeys.IS_BEFORE;
  }

  return undefined;
};

export const ZipErrorI18nKeys = {
  INVALID: "zipErrorInvalid",
  INVALID_US: "zipErrorInvalidUS",
  INVALID_CA: "zipErrorInvalidCA",
  INVALID_CO: "zipErrorInvalidCO",
  REQUIRED: "zipErrorRequired",
};

export const getZipError = (zip: string, country: string): string | false => {
  const matchUsaPostalCode = zip.match(/^[0-9]{5}$/);
  const matchCanadaPostalCode = zip.match(/^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$/);
  const matchColombiaPostalCode = zip.match(/^[0-9]{6}$/);

  if (!country) {
    return matchUsaPostalCode || matchCanadaPostalCode
      ? false
      : ZipErrorI18nKeys.INVALID;
  }

  if (country === "US") {
    return matchUsaPostalCode ? false : ZipErrorI18nKeys.INVALID_US;
  }

  if (country === "CA") {
    return matchCanadaPostalCode ? false : ZipErrorI18nKeys.INVALID_CA;
  }

  if (country === "CO") {
    return matchColombiaPostalCode ? false : ZipErrorI18nKeys.INVALID_CO;
  }

  return zip ? false : ZipErrorI18nKeys.REQUIRED;
};
