import { ChildMachineConfig, ParentState } from "../../../types/common";
import { ActionTypes } from "./actions";
import { WalletEventTypes } from "./events";
import { GuardTypes } from "./guards";
import { ServiceTypes } from "./services";
import { WalletState } from "./types";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.wallet,
  initial: WalletState.route,
  preserveActionOrder: true,
  states: {
    [WalletState.route]: {
      entry: ActionTypes.setWalletVisited,
      always: [
        {
          target: WalletState.getDiscount,
          cond: GuardTypes.hasWalletEnabled,
        },
        {
          target: config.nextState,
        },
      ],
    },
    [WalletState.getDiscount]: {
      invoke: {
        src: ServiceTypes.getWalletOffers,
        onDone: {
          target: WalletState.addDiscount,
          actions: ActionTypes.handleGetWalletOffers,
        },
        onError: {
          target: `#${config.parentMachineId}.${ParentState.wallet}.${WalletState.error}`,
          actions: ActionTypes.setWalletError,
        },
      },
    },
    [WalletState.addDiscount]: {
      always: [
        {
          actions: ActionTypes.addDiscounts,
          target: config.nextState,
        },
      ],
    },
    [WalletState.error]: {
      always: [
        {
          target: config.failedState,
        },
      ],
    },
    [WalletState.removeDiscount]: {
      always: [
        {
          actions: ActionTypes.removeDiscounts,
          target: config.nextState,
        },
      ],
    },
  },
  on: {
    [WalletEventTypes.NEXT]: {
      target: config.nextState,
    },
  },
});
