import { decryptCipherText } from "@hopper-b2b/utilities";
import config from "../utils/config";

export const decodeEmailAndPhone = async ({
  email,
  phone,
  userId,
}: {
  email: string;
  phone: string;
  userId: string;
}) => {
  const decodedEmail = await decryptCipherText(
    email,
    userId,
    config.ENCRYPT_SALT,
    config.ENCRYPT_IV
  );
  const decodedPhone = await decryptCipherText(
    phone,
    userId,
    config.ENCRYPT_SALT,
    config.ENCRYPT_IV
  );
  return { decodedEmail, decodedPhone };
};
