import { Box } from "@material-ui/core";
import styles from "./FintechProduct.module.scss";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { FintechProductType } from "@hopper-b2b/types";
import { ClientContext } from "../../../../../../../flights/src/App";
import { useContext } from "react";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useSelector } from "react-redux";
import { getSelectedCfarOffer } from "../../../../../../../flights/src/modules/cfar/reducer";

export const FintechBody = ({
  description,
  type,
  openHowItWorks,
  openTerms,
}: {
  type: FintechProductType;
  description: string | string[];
  openHowItWorks: () => void;
  openTerms: () => void;
}) => {
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const percent = selectedCfarOffer?.coveragePercentage === "80" ? "80" : "100";

  return (
    <Box>
      <Box className={styles.descriptionText}>
        {Array.isArray(description)
          ? description.map((textKey, index) => (
              <Box key={index} className={styles.descriptionList}>
                <img src={clientContext?.assets?.check} alt="check-mark" />
                {
                  <I18nMarkup
                    tKey={textKey}
                    className={styles.listItem}
                    replacements={{ percent }}
                  />
                }
              </Box>
            ))
          : description}
      </Box>

      {!matchesMobile && (
        <Box className={styles.moreInfo}>
          <Box onClick={openHowItWorks}>
            <span>{t("howItWorks")}</span>{" "}
          </Box>{" "}
          <span className={styles.divider}>|</span>
          <Box onClick={openTerms}>
            <span>{t("viewTerms")}</span>{" "}
          </Box>
        </Box>
      )}
      {type === FintechProductType.Disruption && (
        <Box className={styles.reviewContainer}>
          <img src={clientContext?.assets?.avatar} alt="avatar" />
          <Box>
            <Box>{t?.("flightDelayGuarantee.customerReview")}</Box>
            <Box className={styles.customerName}>
              - {t?.("flightDelayGuarantee.customerName")}
              {", "}
              <span className={styles.greenText}>
                {t?.("flightDelayGuarantee.customerSavings")}
              </span>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
