import { axiosInstance } from "@hopper-b2b/api";
import { NubankUserInfo } from "./userInfo";

export const apiVersionPrefix = "/api/v0";
export const authPrefix = `${apiVersionPrefix}/auth`;
export const startSessionPath = `${authPrefix}/startSession`;

export interface NubankSessionInfo {
  hopperSessionToken: string;
  hopperUserIdToken: string;
  nubankAccessToken: string;
  hopperUserId: string;
  userInfoResponse: NubankUserInfo;
  kustomerAccessToken?: string;
  isImpersonation: boolean;
}

export interface StartSessionRequest {
  code: string;
  initiator?: string;
}

export const startSession = (code: string): Promise<NubankSessionInfo> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const reqBody = {
      code: code,
    };
    try {
      const res = await axiosInstance.post(startSessionPath, reqBody);
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
export default startSession;
