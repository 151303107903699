import { I18nMarkup } from "@hopper-b2b/i18n";
import { Box, Grid } from "@material-ui/core";
import styles from "./FintechProduct.module.scss";
import { useContext } from "react";
import { ClientContext } from "../../../../../../../flights/src/App";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ProvidedBy } from "@hopper-b2b/wallet";

interface FintechHeaderProps {
  header: string;
  infoTextKey?: string;
  imgSrc: string;
}

export const FintechHeader = ({
  header,
  infoTextKey,
  imgSrc,
}: FintechHeaderProps) => {
  const clientContext = useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <Box className={styles.mobileHeader}>
      <ProvidedBy />
      <img src={imgSrc} alt="header-img" />
    </Box>
  ) : (
    <Grid container alignItems="center">
      <Grid lg={6} xs={12} className={styles.headerTitleContainer}>
        <Box className={styles.headerTitle}>
          <img src={imgSrc} alt="header-img" />
          <Box>
            {header}
            <ProvidedBy />
          </Box>
        </Box>
      </Grid>
      {infoTextKey && (
        <Grid lg={6} xs={12}>
          <Box className={styles.headerInfoText}>
            <img
              src={clientContext?.assets?.infoQuestion}
              alt="info- question"
            />
            <I18nMarkup tKey={infoTextKey} />
          </Box>
          {}
        </Grid>
      )}
    </Grid>
  );
};
