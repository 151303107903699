import {
  AirExchangeShopRequest,
  AirExchangeShopSuccess,
  Airline,
  ExchangeActionEnum,
} from "@b2bportal/air-exchange-api";
import { trackEvent } from "@hopper-b2b/api";
import { PassengerKey, SelfServeEvents, ShopFilter } from "@hopper-b2b/types";
import dayjs from "dayjs";
import { groupBy, mapValues } from "lodash-es";
import { all, put, putResolve, select } from "redux-saga/effects";

import fetchFlightExchangeSummaries from "../../../api/v0/exchange/fetchFlightExchangeSummaries";
import Logger from "../../../helpers/Logger";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { actions as searchActions } from "../../search/actions";
import { resetFareclassOptionFilter } from "../../search/actions/actions";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../search/reducer";
import { actions as shopActions } from "../../shop/actions";
import { setFlightShopProgress } from "../../shop/actions/actions";
import { FlightShopStep } from "../../shop/reducer";
import { actions } from "../actions";
import { addAirlines, addAirports } from "../reducer/policy";
import { getExchangeForType } from "../reducer/selectors";
import { getIsFtc, getNoResultsEventProps, getPassengers } from "../selectors";
import { formats } from "../types";
import { skipShopAction } from "../utils";

export function* fetchFlightSummariesSaga(
  action: actions.IGetFLightSummariesReq
) {
  yield put(setFlightShopProgress(FlightShopStep.ExchangeSearching));

  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  const departureDate = yield select(getDepartureDate);
  const destination = yield select(getDestination);
  const origin = yield select(getOrigin);
  const returnDate = yield select(getReturnDate);
  const { exchangeFor, outboundSelection, returnSelection } = yield select(
    getExchangeForType
  );
  const passengers = yield select(getPassengers);
  const isFtc = yield select(getIsFtc);
  const skipChooseDeparture = skipShopAction(outboundSelection);
  let firstShopStep = FlightShopStep.ChooseDeparture;
  let passengersCount = {};
  let shopRes: AirExchangeShopSuccess;

  if (passengers) {
    const { alone, withLapInfants } = passengers;
    const passengerMap = groupBy(alone, "type");

    passengerMap[PassengerKey.lapInfant] = withLapInfants;

    passengersCount = mapValues(
      passengerMap,
      (passengers) => passengers.length || undefined
    );
  }

  if (
    outboundSelection === ExchangeActionEnum.Keep &&
    returnSelection === ExchangeActionEnum.Change
  ) {
    firstShopStep = FlightShopStep.ChooseReturn;
  }

  const shopReq: AirExchangeShopRequest = {
    passengers: passengersCount,
    originalReservationId: action.bookingId,
    departureDate: dayjs(departureDate).format(formats.REQUEST_DATE),
    tripFilter: ShopFilter.NoFilter,
    platform: action.isMobile ? "Mobile" : "Desktop",
    returnDate: returnDate
      ? dayjs(returnDate).format(formats.REQUEST_DATE)
      : undefined,
    route: {
      origin: origin.id.code,
      destination: destination.id.code,
    },
    exchangeType: {
      AirExchangeForType: exchangeFor,
      outboundSelection: {
        ExchangeAction: outboundSelection,
      },
      returnSelection: returnSelection
        ? {
            ExchangeAction: returnSelection,
          }
        : undefined,
    },
  };

  try {
    shopRes = yield fetchFlightExchangeSummaries(shopReq, apiConfig);

    if (shopRes.flights) {
      const { airlines, airports, fareSlices, outbound, trips } =
        shopRes.flights;
      const numResults = skipChooseDeparture
        ? Object.keys(trips).length
        : outbound.length;

      // when keeping departure, shopped outbound is original flight
      if (skipChooseDeparture && outbound.length === 1) {
        const {
          fares: [{ example, fareSlice }],
          slice,
        } = outbound[0];

        yield put(
          shopActions.setChosenOutgoingSlice({
            outgoingFareId: fareSlice,
            outgoingFareRating: fareSlices[fareSlice]?.fareShelf?.value,
            outgoingSliceId: slice,
            tripId: example.trip,
          })
        );
      }

      if (numResults === 0) {
        const eventName = isFtc
          ? SelfServeEvents.FTCExchangeViewedNoResults
          : SelfServeEvents.ExchangeViewedNoResults;
        const noFlightsEventProps = yield select(getNoResultsEventProps);

        trackEvent({
          eventName,
          properties: noFlightsEventProps,
        });
      }

      yield all([
        put(addAirlines(airlines as unknown as Record<string, Airline>)),
        put(addAirports(airports)),
        put(resetFareclassOptionFilter()),
        putResolve(shopActions.setFlights(shopRes.flights)),
        put(searchActions.setAwaitingRefetch(false)),
      ]);
    }
  } catch (err) {
    Logger.debug(err);

    yield put(shopActions.setTripSummariesError());
  }

  yield put(setFlightShopProgress(firstShopStep));
}
