import { ParentState, CheckoutMapComponent } from "@hopper-b2b/checkout";
import { CheckoutComponentMap } from "@hopper-b2b/types";

import { BookingConfirmation } from "./BookingConfirmation";
import { ContactInformation } from "./ContactInformation";
import { PassengerInformation } from "./PassengerInformation";
import { CartQuoteComponent } from "./CartQuote";
import { CartFulfillComponent } from "./CartFulfill";
import { ReviewComponent } from "./Review";
import { NubankPaymentComponent } from "../../../checkout";
import { LoadingScreen } from "../../../checkout/components/LoadingScreen";
import { useUpdateCheckoutUrl } from "../useUpdateCheckoutUrl";
import { RefreshCheckout } from "./RestartCheckout";

export const lodgingComponentMap: CheckoutComponentMap = {
  [ParentState.resume]: {
    state: ParentState.resume,
    component: RefreshCheckout,
  },
  [ParentState.loading]: {
    state: ParentState.loading,
    component: LoadingScreen,
  },
  [ParentState.contactInformation]: {
    state: ParentState.contactInformation,
    component: ContactInformation,
  },
  [ParentState.passengerInformation]: {
    state: ParentState.passengerInformation,
    component: PassengerInformation,
  },
  [ParentState.cartQuote]: {
    state: ParentState.cartQuote,
    component: CartQuoteComponent,
  },
  [ParentState.review]: {
    state: ParentState.review,
    component: ReviewComponent,
  },
  [ParentState.payment]: {
    state: ParentState.payment,
    component: NubankPaymentComponent,
  },
  [ParentState.cartFulfill]: {
    state: ParentState.cartFulfill,
    component: CartFulfillComponent,
  },
  [ParentState.bookingConfirmation]: {
    state: ParentState.bookingConfirmation,
    component: BookingConfirmation,
  },
};

export const LodgingCheckoutComponent = () => {
  useUpdateCheckoutUrl();
  return <CheckoutMapComponent componentMap={lodgingComponentMap} />;
};
