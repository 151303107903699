import { Box, Divider } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useMemo } from "react";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AirportMap,
  ClientName,
  FareDetails,
  FiatPrice,
  FlightSummaryInfo,
  IPerson,
  TrackingEventEntryPoint,
  TripDetails,
  VIEWED_FLIGHT_DETAILS,
  ViewedFlightDetailsProperties,
} from "@hopper-b2b/types";
import {
  AirlineIcon,
  IconName,
  ImportantInfoList,
  MobileFlightSummaryRowNew,
  PassengerSummaryRow,
  Slot,
  UberPriceSummaryRow as PriceSummaryRow,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getSliceIndex,
  getVirtualInterlineLayovers,
  removeTimezone,
  useEnableHFv2,
  useTenantIcons,
} from "@hopper-b2b/utilities";

import { Person } from "@b2bportal/air-booking-api";
import { MobileFlightSummaryRow } from "../components/MobileFlightSummaryRow";
import { IOpenModal, OpenModalEnum } from "../types/IOpenModal";
import { trackEvent } from "@hopper-b2b/api";
import { MultiTicketType } from "@b2bportal/air-shopping-api";

type MobileReviewContentProps = {
  importantInfo?: string[];
  isOneWay: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  fareDetails: FareDetails;
  tripDetails: TripDetails;
  airports: AirportMap;
  totalCostFiat: FiatPrice;
  isOutgoingMixedClass: boolean;
  isReturnMixedClass: boolean;
  airplaneDepartIcon?: any;
  airplaneArriveIcon?: any;
  cardIcon?: any;
  onDepartureSummaryRowClick: () => void;
  onReturnSummaryRowClick: () => void;
  passengers?: Person[];
  passengersIcon?: any;
  priceSummaryChevronIcon?: ReactNode;
  setOpenModal: (openModal: IOpenModal) => void;
  viewPriceBreakdown?: () => void;
};

export const MobileReviewContent = ({
  importantInfo,
  isOneWay,
  departureDate,
  returnDate,
  fareDetails,
  tripDetails,
  airports,
  totalCostFiat,
  isOutgoingMixedClass,
  isReturnMixedClass,
  airplaneDepartIcon,
  airplaneArriveIcon,
  cardIcon,
  onDepartureSummaryRowClick,
  onReturnSummaryRowClick,
  passengers,
  passengersIcon,
  priceSummaryChevronIcon,
  setOpenModal,
  viewPriceBreakdown,
}: MobileReviewContentProps) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const tenant = getEnvVariables("clientName");
  const isNubank = tenant === ClientName.NUBANK;
  const isUber = tenant === ClientName.UBER;

  const icons = useTenantIcons();

  const hackerFareV2Enabled = useEnableHFv2();
  const showHackerFareContent =
    hackerFareV2Enabled &&
    tripDetails.fareDetails[0].multiTicketType === MultiTicketType.hackerFare;

  const onHandleItineraryClicked = (openModalEnum: OpenModalEnum) => {
    setOpenModal(openModalEnum);
    trackEvent({
      eventName: VIEWED_FLIGHT_DETAILS,
      properties: {
        outbound_vi_layovers: getVirtualInterlineLayovers(fareDetails, true),
        return_vi_layovers: getVirtualInterlineLayovers(fareDetails, false),
        entry_point: TrackingEventEntryPoint.Details,
        slice_direction_viewed:
          openModalEnum === OpenModalEnum.DEPARTURE ? "outbound" : "return",
      } as ViewedFlightDetailsProperties,
    });
  };

  const getFlightSummaryInfo = useCallback(
    (isDeparture: boolean): FlightSummaryInfo => {
      const sliceIndex = getSliceIndex(isDeparture, tripDetails);
      const tripSlice = tripDetails.slices[sliceIndex];
      const flightSegment = tripSlice.segmentDetails[0];
      const destinationCity = airports[tripSlice.destinationCode]
        ? airports[tripSlice.destinationCode].cityName
        : tripSlice.destinationName;
      const originCity = airports[tripSlice.originCode]
        ? airports[tripSlice.originCode].cityName
        : tripSlice.originName;

      return {
        originCity: originCity,
        destinationCity: destinationCity,
        departure: removeTimezone(tripSlice.departureTime),
        arrival: removeTimezone(tripSlice.arrivalTime),
        airlineName: flightSegment.airlineName,
        airlineCode: flightSegment.airlineCode,
        isDeparture: isDeparture,
        title: t("originToDestination", {
          origin: originCity,
          destination: destinationCity,
          interpolation: { escapeValue: false },
        }),
        stops: tripSlice.stops,
      };
    },
    [t, tripDetails, airports]
  );

  const showReturnFlightSummary = useMemo(
    () => !isOneWay && returnDate && fareDetails,
    [fareDetails, isOneWay, returnDate]
  );

  const departureFlightSummaryInfo = useMemo(
    () => getFlightSummaryInfo(true),
    [getFlightSummaryInfo]
  );
  const returnFlightSummaryInfo = useMemo(
    () => showReturnFlightSummary && getFlightSummaryInfo(false),
    [showReturnFlightSummary, getFlightSummaryInfo]
  );

  // Needed to fix https://hopper-jira.atlassian.net/browse/CTG-62
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  // TODO: Rename MobileFlightSummaryRowNew

  return (
    <Box className={clsx("mobile-itinerary-cards-section", tenant)}>
      {departureDate && fareDetails ? (
        <Box className="mobile-trip-card">
          {isUber || isNubank ? (
            <>
              {isNubank ? (
                <>
                  <div className="mobile-trip-card-header">
                    {t("reviewItinerary.outbound")}
                  </div>
                  <Divider className="review-itinerary-divider mobile-trip-card-header-divider" />
                </>
              ) : null}
              <MobileFlightSummaryRowNew
                flightSummaryInfo={departureFlightSummaryInfo}
                isMixedClass={isOutgoingMixedClass}
                iconSrc={airplaneDepartIcon}
                onClick={onDepartureSummaryRowClick}
                customIcon={
                  isNubank ? (
                    // TODO: When VI is enabled, add in check to show the double icon
                    //  <img src={icons.airplaneDouble} alt="" />
                    <AirlineIcon
                      airlineCode={departureFlightSummaryInfo.airlineCode}
                    />
                  ) : undefined
                }
                customExpandIcon={isNubank ? IconName.ChevronDown : undefined}
                renderAirlineIcon={!isNubank}
                showHackerFareContent={showHackerFareContent}
              />
            </>
          ) : (
            <Slot
              id="mobile-flight-summary-row"
              tripSlice={tripDetails.slices[0]}
              airports={airports}
              hasSummaryPanelBorder
              className="mobile-flight-summary-row"
              onClick={() => onHandleItineraryClicked(OpenModalEnum.DEPARTURE)}
              component={
                <MobileFlightSummaryRow
                  isDeparture
                  tripDetails={tripDetails}
                  date={departureDate}
                  setOpen={setOpenModal}
                  airports={airports}
                  isMixedClass={isOutgoingMixedClass}
                />
              }
            />
          )}
        </Box>
      ) : null}
      {showReturnFlightSummary ? (
        <Box className="mobile-trip-card">
          {isUber || isNubank ? (
            <>
              {isNubank ? (
                <>
                  <div className="mobile-trip-card-header">
                    {t("reviewItinerary.return")}
                  </div>
                  <Divider className="review-itinerary-divider mobile-trip-card-header-divider" />
                </>
              ) : null}
              <MobileFlightSummaryRowNew
                flightSummaryInfo={returnFlightSummaryInfo}
                isMixedClass={isReturnMixedClass}
                iconSrc={airplaneArriveIcon}
                onClick={onReturnSummaryRowClick}
                customIcon={
                  isNubank ? (
                    // TODO: When VI is enabled, add in check to show the double icon
                    //  <img src={icons.airplaneDouble} alt="" />
                    <AirlineIcon
                      airlineCode={returnFlightSummaryInfo.airlineCode}
                    />
                  ) : undefined
                }
                customExpandIcon={isNubank ? IconName.ChevronDown : undefined}
                renderAirlineIcon={!isNubank}
                showHackerFareContent={showHackerFareContent}
              />
            </>
          ) : (
            <Slot
              id="mobile-flight-summary-row"
              tripSlice={tripDetails.slices[1]}
              airports={airports}
              className="mobile-flight-summary-row"
              onClick={() => onHandleItineraryClicked(OpenModalEnum.RETURN)}
              isReturn
              hasSummaryPanelBorder
              component={
                <MobileFlightSummaryRow
                  isDeparture={false}
                  tripDetails={tripDetails}
                  date={returnDate}
                  setOpen={setOpenModal}
                  airports={airports}
                  isMixedClass={isReturnMixedClass}
                />
              }
            />
          )}
        </Box>
      ) : null}
      {isUber ? (
        <>
          <Divider className="review-itinerary-divider" />
          <PriceSummaryRow
            chevronIcon={priceSummaryChevronIcon}
            priceString={formatFiatCurrency(totalCostFiat, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            description={
              isOneWay ? t("oneWayPerTraveler") : t("roundTripPerTraveler")
            }
            iconSrc={cardIcon}
            onClick={viewPriceBreakdown}
          />
        </>
      ) : null}
      {passengers?.length ? (
        <>
          <Divider className="review-itinerary-divider" />
          <PassengerSummaryRow
            iconSrc={passengersIcon}
            passengers={passengers as IPerson[]}
          />
        </>
      ) : null}
      {importantInfo?.length ? (
        <>
          <Divider className="review-itinerary-divider" />
          <ImportantInfoList
            ordered
            infoItems={importantInfo}
            title={t("importantInfoTitle")}
            titleProps={{ variant: "body1" }}
          />
        </>
      ) : null}
      <Slot
        id="trip-review-combination-banner"
        airports={airports}
        tripDetails={tripDetails}
        fareDetails={fareDetails}
        isMobile
      />
    </Box>
  );
};
