import { useI18nContext } from "@hopper-b2b/i18n";
import { MyTripsFilter } from "@hopper-b2b/types";
import { ActionButton, NoResults } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { capitalize } from "lodash-es";
import { useNavigate } from "react-router-dom-v5-compat";
import { PATH_FLIGHTS } from "../../../../utils/paths";
import { NoTripResultsConnectorProps } from "./container";
import "./styles.scss";

interface INoTripResultsProps extends NoTripResultsConnectorProps {
  isMobile?: boolean;
}

export const NoTripResults = ({
  hasTripsToDisplay,
  hasError,
  tripsFilter,
  isMobile,
}: INoTripResultsProps) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();

  const getNoResultsTitle = (tripsFilter: MyTripsFilter) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
        return t("noBookings");
      case MyTripsFilter.PAST_TRIPS:
        return t("noPastBookings");
      case MyTripsFilter.FLIGHTS:
        return t("noFlightBookings");
      case MyTripsFilter.HOTELS:
        return t("noHotelBookings");
      case MyTripsFilter.WATCHED_TRIPS:
        return t("noWatches");
    }
    return;
  };

  const getLoadErrorTitle = (tripsFilter: MyTripsFilter) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
        return t("upcomingTripsLoadErrorTitle");
      case MyTripsFilter.PAST_TRIPS:
        return t("pastTripsLoadErrorTitle");
      case MyTripsFilter.FLIGHTS:
        return t("upcomingTripsLoadErrorTitle");
      case MyTripsFilter.HOTELS:
        return t("upcomingTripsLoadErrorTitle");
      case MyTripsFilter.WATCHED_TRIPS:
        return t("watchedTripsLoadErrorTitle");
    }
    return;
  };

  const handleBookAFlightSelect = () => navigate(PATH_FLIGHTS);

  return (
    <Box className={clsx({ mobile: isMobile }, "noTripsResults", "apac")}>
      {hasError && (
        <>
          <NoResults
            className="no-trips-container"
            title={getLoadErrorTitle(tripsFilter)}
            subtitle={t("tripsLoadErrorSubtitle")}
            subtitleVariant="body1"
            hideIcon
          />
          <Box className="no-trips-buttons-container">
            <ActionButton
              className="retry"
              message={capitalize(t("tripsLoadErrorButtonTitle"))}
              defaultStyle="h4r-primary"
              onClick={() => window.location.reload()}
            />
          </Box>
        </>
      )}
      {!hasTripsToDisplay && !hasError && (
        <>
          <NoResults
            className="no-trips-container"
            title={getNoResultsTitle(tripsFilter)}
            subtitle={t("noTripsSubtitle")}
            subtitleVariant="body1"
            hideIcon
          />
          <Box className="no-trips-buttons-container">
            <ActionButton
              className="book-a-trip"
              message={capitalize(t("noTripsButtonTitle"))}
              defaultStyle="h4r-secondary"
              onClick={handleBookAFlightSelect}
              size="medium"
            />
          </Box>
        </>
      )}
    </Box>
  );
};
