import { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import { PriceDropProtectionEnum, VIEWED_FORECAST } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { trackEvent } from "@hopper-b2b/api";
import {
  ActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import {
  useApiConfigSelector,
  useEnablePriceFreeze,
  getEnvVariables,
} from "@hopper-b2b/utilities";

import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { useUpdateFlightShopStep } from "../../hooks/flightShopNavigationHooks";
import { FlightShopStep } from "../../reducer";
import { MobilePricePredictionConnectorProps } from "./container";
import {
  MobilePricePredictionContent,
  MobilePricePredictionShopCard,
} from "./components";
import "./styles.scss";

export const MobilePricePrediction = ({
  prediction,
  refreshPrediction,
  history,
  fetchTripSummaries,
  viewedForecastProperties,
  viewedPriceFreezeProperties,
  isPriceFreezeOfferIncludedInShopSummary,
  isTripDetailsLoading,
}: MobilePricePredictionConnectorProps) => {
  const [hasViewedForecast, setHasViewedForecast] = useState(false);
  const [priceFullPredictionModalOpen, setPriceFullPredictionModalOpen] =
    useState(false);
  const showFlightPriceFreeze = useEnablePriceFreeze();
  const updateFlightShopStep = useUpdateFlightShopStep();

  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  useEffect(() => {
    if (refreshPrediction) {
      fetchTripSummaries(history, true);
    }
  }, []);

  useEffect(() => {
    if (prediction && viewedForecastProperties && !hasViewedForecast) {
      if (isPriceFreezeOfferIncludedInShopSummary) {
        // note: isTripDetailsLoading is of type boolean | null
        if (isTripDetailsLoading === false && viewedPriceFreezeProperties) {
          trackEvent(
            {
              eventName: VIEWED_FORECAST,
              properties: {
                ...viewedForecastProperties,
                ...(showFlightPriceFreeze
                  ? viewedPriceFreezeProperties
                  : undefined),
              },
            },
            apiConfig
          );
          setHasViewedForecast(true);
        }
      } else {
        trackEvent(
          {
            eventName: VIEWED_FORECAST,
            properties: {
              ...viewedForecastProperties,
            },
          },
          apiConfig
        );
        setHasViewedForecast(true);
      }
    }
  }, [
    prediction,
    viewedForecastProperties,
    viewedPriceFreezeProperties,
    hasViewedForecast,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
    showFlightPriceFreeze,
    apiConfig,
  ]);

  const renderCloseModalButton = () => (
    <ActionLink
      className="price-drop-protection-close-button"
      onClick={() => setPriceFullPredictionModalOpen(false)}
      content={
        <IconComponent
          ariaLabel="Close button icon"
          className={clsx("close-button-icon")}
          name={IconName.Close}
        />
      }
      label="Close"
      showTappableArea={true}
    />
  );

  const onOpenPrediction = useCallback(() => {
    if (getEnvVariables("clientName") === "uber") {
      updateFlightShopStep(FlightShopStep.PricePrediction);
    } else {
      setPriceFullPredictionModalOpen(true);
    }
  }, [updateFlightShopStep]);

  return (
    <>
      {prediction && (
        <MobilePopoverCard
          headerElement={<MobilePricePredictionFullTitle />}
          topRightButton={renderCloseModalButton()}
          open={priceFullPredictionModalOpen}
          onClose={() => setPriceFullPredictionModalOpen(false)}
          className="mobile-prediction-popover"
          fullScreen
        >
          <MobilePricePredictionContent
            onClose={() => setPriceFullPredictionModalOpen(false)}
          />
        </MobilePopoverCard>
      )}
      <MobilePricePredictionShopCard
        prediction={prediction}
        tripCategory={prediction}
        openPredictionModal={onOpenPrediction}
      />

      <Slot
        id="flight-price-drop-protection-banner"
        isEligible={
          prediction?.priceDropProtection?.PriceDropProtection ===
          PriceDropProtectionEnum.IsEligible
        }
        showMore
        className="flight-shop-mobile-price-prediction"
      />
    </>
  );
};

const MobilePricePredictionFullTitle = () => {
  const { t } = useI18nContext();
  return (
    <Box className="mobile-price-prediction-full-title">
      <Typography variant="h3">{t("pricePrediction.header")}</Typography>
    </Box>
  );
};
