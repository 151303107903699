import { EventObject } from "xstate";
import { useCallback, useContext, useEffect } from "react";
import {
  CartQuoteState,
  CartSelectors,
  ContactSelectors,
  getChildState,
  getParentState,
  LodgingSelectors,
  ParentState,
  parseLodgingQueryString,
  PassengerSelectors,
  useCheckoutState,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import { encryptMessage } from "@hopper-b2b/utilities";
import { useHistory } from "react-router";
import queryStringParser from "query-string";
import { isEqual } from "lodash-es";

import config from "../../utils/config";
import { UserContext } from "../../App";
import { LodgingMachineContext } from "./types";

export const useUpdateCheckoutUrl = () => {
  const [state] = useCheckoutState<EventObject, LodgingMachineContext>();
  const { sessionInfo } = useContext(UserContext);
  const history = useHistory();

  const updateUrlParams = useCallback(async () => {
    const currentParsedQuery = queryStringParser.parse(history.location.search);
    const parentState = getParentState(state.value);
    const childState = getChildState(state.value);

    switch (parentState) {
      // step after ParentState.contactInformation (add contact info data)
      case ParentState.passengerInformation: {
        const availabilityLink = LodgingSelectors.getAvailabilityLink({
          context: state.context,
        });
        const lodgingCity = LodgingSelectors.getLodgingCityName({
          context: state.context,
        });
        const guestCount = LodgingSelectors.getGuestCount({
          context: state.context,
        });
        const selectedRoom = LodgingSelectors.getSelectedRoom({
          context: state.context,
        });

        const email = ContactSelectors.getEmailAddress({
          context: state.context,
        }) as string;
        const { encodedString: encryptedEmail } = await encryptMessage(
          email,
          sessionInfo?.userInfoResponse?.userId || "",
          config.ENCRYPT_SALT,
          config.ENCRYPT_IV
        );
        const phone = ContactSelectors.getFormattedPhoneNumber({
          context: state.context,
        });
        const { encodedString: encryptedPhone } = await encryptMessage(
          phone,
          sessionInfo?.userInfoResponse?.userId || "",
          config.ENCRYPT_SALT,
          config.ENCRYPT_IV
        );

        const { email: currentEmail, phone: currentPhone } =
          parseLodgingQueryString(history);
        if (
          currentEmail !== encryptedEmail ||
          currentPhone !== encryptedPhone
        ) {
          history.replace({
            search: queryStringParser.stringify({
              ...currentParsedQuery,
              email: encryptedEmail,
              phone: encryptedPhone,
              availabilityLink: encodeURIComponent(availabilityLink),
              lodgingCity: encodeURIComponent(lodgingCity),
              roomMediaUrl: encodeURIComponent(
                selectedRoom?.roomInfo.media[0]?.url
              ),
              roomMaxAdults: selectedRoom?.roomInfo.maxAdults || guestCount,
            }),
          });
        }
        break;
      }

      // step after ParentState.passengerInformation (add selected passenger ids)
      case ParentState.cartQuote: {
        switch (childState) {
          case CartQuoteState.schedule: {
            const { selectedPassengerIds: currentSelectedPassengerIds } =
              parseLodgingQueryString(history);

            const selectedPassengerIds =
              PassengerSelectors.getSelectedPassengerIds({
                context: state.context,
              });

            if (
              !isEqual(
                currentSelectedPassengerIds.sort(),
                selectedPassengerIds.sort()
              )
            ) {
              history.replace({
                search: queryStringParser.stringify({
                  ...currentParsedQuery,
                  selectedPassengerIds: selectedPassengerIds.join(","),
                }),
              });
            }
            break;
          }

          case CartQuoteState.polling: {
            const { cartToken } = parseLodgingQueryString(history);

            const cipherText = CartSelectors.getPriceQuoteCipherText({
              context: state.context,
            });

            if (cartToken !== cipherText?.value) {
              history.replace({
                search: queryStringParser.stringify({
                  ...currentParsedQuery,
                  cartToken: cipherText?.value,
                }),
              });
            }
            break;
          }
          default:
            break;
        }
        break;
      }
      // step after Parent.wallet (add selected offer id)
      case ParentState.cartUpdate: {
        const { selectedWalletOffer: parsedSelectedOfferId } =
          parseLodgingQueryString(history);
        const selectedOfferId = WalletSelectors.getWalletOffer(state);

        if (
          selectedOfferId?.id &&
          parsedSelectedOfferId !== selectedOfferId?.id
        ) {
          history.replace({
            search: queryStringParser.stringify({
              ...currentParsedQuery,
              selectedWalletOffer: selectedOfferId.id,
            }),
          });
        }
        break;
      }
      default:
        break;
    }
  }, [history, state, sessionInfo?.userInfoResponse?.userId]);

  useEffect(() => {
    updateUrlParams();
    // Only run when state.value changes
  }, [state.value]);

  return null;
};
