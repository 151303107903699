import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  TagColors,
  getDepartureSlice,
  getFlightInfoDetails,
  getReturnSlice,
} from "@hopper-b2b/types";
import { Slot, StatusTag } from "@hopper-b2b/ui";
import { removeTimezone } from "@hopper-b2b/utilities";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { ItineraryDate } from "../ItineraryDate/component";
import { scrollTopWithOffset } from "../ItineraryList/component";
import { DATE_FORMAT } from "../ItineraryList/constants";
import { FlightCardConnectorProps } from "./container";
import "./styles.scss";

interface IFlightCardProps
  extends FlightCardConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  flight: BookedFlightItineraryWithDepartureTime;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  isMobile?: boolean;
  isPastTrips: boolean;
}

export const FlightCard = ({
  flight,
  isMobile,
  airportMap,
  airlineMap,
  history,
  expandedCard,
  setSelectedFlight,
  isPastTrips,
  populateTripQueryParams,
}: IFlightCardProps) => {
  const { t } = useI18nContext();
  const flightRef = useRef<HTMLDivElement | null>(null);
  const {
    bookedItinerary: { id: itineraryId, scheduleChange },
    status,
  } = flight;

  const hasMajorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Major;
  const hasMinorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Minor ||
    (status === PortalItineraryStatusEnum.Modified && !scheduleChange);
  const isCancelled = status === PortalItineraryStatusEnum.Canceled;

  useEffect(() => {
    if (!isMobile && flightRef?.current && expandedCard === itineraryId) {
      scrollTopWithOffset(flightRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  const flightInfoDetails = getFlightInfoDetails(
    flight,
    (date: string) => dayjs(date).format(DATE_FORMAT),
    airportMap,
    airlineMap
  );

  const { confirmationCode } = flightInfoDetails;

  const title = t("originToDestination", {
    origin: flightInfoDetails.origin,
    destination: flightInfoDetails.destination,
    interpolation: { escapeValue: false },
  });
  const tripType = flightInfoDetails.roundTrip
    ? t("roundTripFlight")
    : t("oneWayFlight");

  const departureDate = useMemo(() => {
    const departureSlice = getDepartureSlice(flight.bookedItinerary);
    return removeTimezone(departureSlice.segments[0].scheduledDeparture);
  }, [flight]);

  const returnDate = useMemo(() => {
    const returnSlice = getReturnSlice(flight.bookedItinerary);
    if (!returnSlice) {
      return undefined;
    }
    return removeTimezone(returnSlice.segments[0].scheduledDeparture);
  }, [flight]);

  const titleTag = useMemo(() => {
    if (isPastTrips) {
      return {
        label: isCancelled ? t("cancelled") : t("completedItinerary"),
        type: TagColors.GREY,
        iconName: undefined,
        tooltipCopy: undefined,
      };
    }
    switch (flight.status) {
      case PortalItineraryStatusEnum.Pending:
        return {
          label: t("pending"),
          type: TagColors.GREY,
          iconName: undefined,
          tooltipCopy: undefined,
        };
      case PortalItineraryStatusEnum.Modified:
        return {
          label: hasMajorScheduleChange
            ? t("scheduleChange.badge_major")
            : hasMinorScheduleChange
            ? t("scheduleChange.badge_minor")
            : t("itineraryModified"),
          type: hasMajorScheduleChange ? TagColors.RED : TagColors.ORANGE,
          iconName: undefined,
          tooltipCopy: undefined,
        };
      default:
        return undefined;
    }
  }, [
    flight.status,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    isCancelled,
    isPastTrips,
    t,
  ]);

  return (
    <Box className={clsx({ mobile: isMobile }, "flight-list", "apac")}>
      <Box className="my-trips-desktop-card flight-card desktop">
        <Box className="mytrips-flight-icon-container">
          <Slot id="mytrips-flight-icon" />
          {!!titleTag && (
            <StatusTag className="title-status-tag" tagInfo={titleTag} />
          )}
        </Box>
        <Box className="flight-card-icon-title">
          <Typography className="summary-label" variant="h2">
            {title}
          </Typography>
        </Box>
        <Box
          id={flight.bookedItinerary.id}
          key={flight.bookedItinerary.id}
          className={clsx("flight-trip-container", {
            mobile: isMobile,
          })}
        >
          <Box
            className={clsx("flight-trip-details-content", {
              mobile: isMobile,
            })}
          >
            <Box className="flight-trip-dates-details">
              <ItineraryDate label={t("departingLabel")} date={departureDate} />
              {returnDate ? (
                <ItineraryDate label={t("returningLabel")} date={returnDate} />
              ) : null}
            </Box>
            <Box className="flight-trip-metadata-details">
              <Box>
                <Typography className="label">{t("tripTypeLabel")}</Typography>
                <Typography className="value">{tripType}</Typography>
              </Box>
              <Box>
                <Typography className="label">{t("confirmation")}</Typography>
                <Typography className="value">{confirmationCode}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider className="flight-trip-details-divider" />
          <Box className="flight-links-container">
            <Link
              component="button"
              onClick={() => {
                setSelectedFlight(flight);
                populateTripQueryParams(history, {
                  tripId: flight.bookedItinerary.id,
                });
              }}
            >
              <Typography className="flight-view-details-link" variant="body2">
                {t("viewDetails")}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
