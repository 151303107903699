import {
  CartFulfillServices,
  CartQuoteServices,
  CartUpdateServices,
  ContactInformationServices,
  PassengerServices,
  WalletServices,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";
import { commonServices } from "./common";
import { NubankPaymentServices } from "../../../checkout/states/payments";

export const Services = {
  ...CartFulfillServices,
  ...CartQuoteServices,
  ...CartUpdateServices,
  ...ContactInformationServices,
  ...PassengerServices,
  ...commonServices,
  ...WalletServices,
  ...NubankPaymentServices,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
