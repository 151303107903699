import { useHistory } from "react-router";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, MobileFloatingSection } from "@hopper-b2b/ui";
import {
  getNestedChildState,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";

import { ReactComponent as ArrowBack } from "../../../../../assets/client/arrow-back.svg";
import { ReactComponent as MoneyOutIcon } from "../../../../../assets/client/money-out.svg";
import { ReactComponent as ShieldIcon } from "../../../../../assets/client/shield.svg";
import { ReactComponent as RedExclamationIcon } from "../../../../../assets/client/red-error-exclamation.svg";
import { ReactComponent as CardTransferIcon } from "../../../../../assets/client/card-transfer-limits.svg";
import { ReactComponent as PasswordIcon } from "../../../../../assets/client/password.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/client/close.svg";
import { getEnrollmentRedirectUrl } from "../../../../states/payments/selectors";
import { NubankPaymentChildState } from "../../../../states/payments/types";
import { ErrorScreen } from "../../../../../components/ErrorScreen";
import { PaymentEvent } from "../../../../states/payments";
import { LoadingScreen } from "../../../LoadingScreen";
import { GenericResponseScreen } from "../../../../../components/GenericResponseScreen";
import { closeNubankWebview } from "../../../../../utils/nubankHandoff";
import styles from "./styles.module.scss";
import clsx from "clsx";

export const AuthConsentScreen = () => {
  const { t } = useI18nContext();
  const history = useHistory();
  const enrollmentRedirectUrl = useSelector(getEnrollmentRedirectUrl);
  const [state, send] = useCheckoutState();
  const nestedChild = getNestedChildState(state.value);

  const handleGoBack = () => {
    history.goBack();
  };

  if (nestedChild === NubankPaymentChildState.failure) {
    return (
      <GenericResponseScreen
        title={t("nupay.cancelEnrollment.header")}
        subtitle={t("nupay.cancelEnrollment.subheader")}
        primaryButtonText={t("nupay.cancelEnrollment.cancelAndReturnButton")}
        primaryOnClick={closeNubankWebview}
        secondaryButtonText={t("tryAgain")}
        secondaryOnClick={() => send(PaymentEvent.TRY_AGAIN)}
        onBack={closeNubankWebview}
        className={clsx("warn", styles.cancelScreenFloatingSection)}
        image={<RedExclamationIcon className="response-image" />}
        backIcon={<CloseIcon className={styles.cancelScreenCloseIcon} />}
      />
    );
  }

  if (
    [
      // NubankPaymentChildState.failure,
      NubankPaymentChildState.success,
      NubankPaymentChildState.loading,
    ].includes(nestedChild as NubankPaymentChildState)
  ) {
    return <LoadingScreen />;
  }

  if (nestedChild === NubankPaymentChildState.error) {
    return (
      <ErrorScreen
        className="warn"
        title={t("nupay.authorizationErrorTitle")}
        subtitle={t("nupay.authorizationErrorSubheader")}
        primaryButtonText={t("cancel")}
        primaryOnClick={handleGoBack}
        secondaryButtonText={t("tryAgain")}
        secondaryOnClick={() => send(PaymentEvent.TRY_AGAIN)}
        onBack={handleGoBack}
      />
    );
  }

  const onClick = () => {
    // Initiate handoff to nubank
    if (enrollmentRedirectUrl) {
      send(PaymentEvent.GO_TO_LOADING);
      window.location.replace(enrollmentRedirectUrl);
    } else {
      console.log("no redirectUrl defined");
    }
    return null;
  };

  return (
    <div className={styles.authConsentScreen}>
      <div className="nupay-header">
        <ButtonWrap className="back-button" onClick={handleGoBack}>
          <ArrowBack className="arrow-back-icon" />
        </ButtonWrap>
      </div>
      <div className="auth-consent-content">
        <PasswordIcon className="pin-pad-image" />
        <h1>{t("nupay.requestAuthHeader")}</h1>
        <div className="value-props-list">
          <div className="value-props-item">
            <MoneyOutIcon className="value-props-icon" />
            <p>{t("nupay.requestAuthSubtitle1")}</p>
          </div>
          <div className="value-props-item">
            <ShieldIcon className="value-props-icon" />
            <p>{t("nupay.requestAuthSubtitle2")}</p>
          </div>
          <div className="value-props-item">
            <CardTransferIcon className="value-props-icon" />
            <p>{t("nupay.requestAuthSubtitle3")}</p>
          </div>
        </div>
        <MobileFloatingSection
          primaryButton={{
            children: t("nupay.requestAuthButton"),
            onClick: onClick,
          }}
        />
      </div>
    </div>
  );
};
