import {
  CartUpdateState,
  ChildMachineConfig,
  ParentState,
} from "../../../types/common";
import { ServiceTypes } from "./services";
import { ActionTypes } from "./actions";
import { CartQuoteActionTypes } from "../CartQuote";
import { GuardTypes } from "./guards";
import {
  CartQuoteState,
  CartState,
  CartUpdateEventType,
  ReviewGuardTypes,
} from "@checkout/index";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.cartUpdate,
  initial: CartUpdateState.route,
  entry: ActionTypes.setCartUpdateCallStateInProcess,
  states: {
    [CartUpdateState.route]: {
      always: [
        {
          target: config.nextState,
          cond: GuardTypes.isUpdateQuoteRequestEmpty,
        },
        {
          target: CartUpdateState.update,
        },
      ],
    },
    [CartUpdateState.update]: {
      invoke: {
        src: ServiceTypes.updateQuoteService,
        onDone: {
          actions: [CartQuoteActionTypes.lockCart, ActionTypes.clearCartUpdate],
          target: CartUpdateState.getBreakdown,
        },
        onError: {
          actions: [
            ActionTypes.setCartUpdateError,
            ActionTypes.setCartUpdateCallStateFailed,
          ],
          target: CartUpdateState.error,
        },
      },
    },
    [CartUpdateState.getBreakdown]: {
      invoke: {
        src: ServiceTypes.getQuoteBreakdownService,
        onDone: [
          {
            cond: ReviewGuardTypes.visitedReview,
            actions: [
              CartQuoteActionTypes.setCartBreakdown,
              ActionTypes.setCartUpdateCallStateSuccess,
            ],
            target: config.nextStateFromReview || config.nextState,
          },
          {
            actions: [
              CartQuoteActionTypes.setCartBreakdown,
              ActionTypes.setCartUpdateCallStateSuccess,
            ],
            target: config.nextState,
          },
        ],
        onError: {
          actions: [
            ActionTypes.setCartUpdateError,
            ActionTypes.setCartUpdateCallStateFailed,
          ],
          target: CartUpdateState.error,
        },
      },
    },
    [CartUpdateState.error]: {
      ...(config.failedState ? { always: { target: config.failedState } } : {}),
    },
    // Only merges first layer object, doesn't do a deep merge
    // See ChildMachineConfig for more details
    ...(config.stateOverride ? config.stateOverride : {}),
  },
  on: {
    [CartUpdateEventType.CLEAR_CART_UPDATE_ERROR]: {
      actions: ActionTypes.clearCartUpdateError,
    },
    [CartUpdateEventType.RETRY_QUOTE]: {
      // On retry we retry the whole quote
      target: `#${config.parentMachineId}.${CartState.cartQuote}.${CartQuoteState.route}`,
      actions: [ActionTypes.clearCartUpdateError],
    },
  },
});
