import { CartSelectors } from "@hopper-b2b/checkout";
import {
  GetPaymentMethodsRequest,
  getPaymentMethods,
} from "../api/getPaymentMethods";
import { PaymentContext } from "../context";

export const fetchPaymentMethodsService = (
  context: PaymentContext,
  _event: unknown
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const priceBreakdown = CartSelectors.getQuoteBreakdown({ context });

      const paymentTotal = priceBreakdown?.balance.fiat.value;

      if (!paymentTotal) {
        const errorMessage = `quoteBreakdown balance is not a valid number: ${JSON.stringify(
          priceBreakdown
        )}`;
        console.error(errorMessage);
        throw Error(errorMessage);
      }

      const payload: GetPaymentMethodsRequest = {
        amount: {
          amount: priceBreakdown?.balance.fiat.value,
          currency: priceBreakdown?.balance.fiat.currencyCode || "BRL",
        },
      };
      const response = await getPaymentMethods(payload);
      if (response.Response === "Success") {
        resolve(response.value);
      } else {
        reject({ error: response.errors });
      }
    } catch (error) {
      reject({
        error,
      });
    }
  });
