import { HTMLInputTypeAttribute } from "react";
import clsx from "clsx";
import Error from "../../assets/client/error.svg";
import "./styles.scss";

interface IInputTextFieldProps {
  label: string;
  value: string;
  placeholder?: string;
  error: boolean;
  type?: HTMLInputTypeAttribute;
  errorText?: string;
  onChange: (_value: string) => void;
}

const InputTextField = ({
  label,
  value,
  type = "text",
  placeholder,
  error,
  errorText,
  onChange,
}: IInputTextFieldProps) => {
  return (
    <div className="input-field">
      <p className="input-field-subtitle">{label}</p>
      <div className={clsx("input-field-row", { "has-error": error })}>
        <input
          className="input-field-input"
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
        {error && <img className="error-icon" alt="error" src={Error} />}
      </div>
      {error && errorText && <p className="error-text">{errorText}</p>}
    </div>
  );
};

export default InputTextField;
