import { Skeleton } from "@material-ui/lab";
import { Divider } from "@material-ui/core";
import styles from "./LodgingCard.module.scss";
import clsx from "clsx";

export function LodgingCardSkeleton() {
  return (
    <>
      <div className={clsx(styles.LodgingCard, "LodgingCardSkeleton")}>
        <div className={clsx(styles.Carousel, "LodgingCardSkeletonCarousel")}>
          <Skeleton
            variant="rect"
            height="100%"
            className="LodgingCardSkeletonCarousel"
          />
        </div>
        <div>
          <div className={styles.TitleWrapper}>
            <Skeleton variant="text" width="60%" height={24} />
          </div>
          <div className={clsx(styles.GridArea, "LodgingCardSkeletonGridArea")}>
            <div className={styles.Ratings}>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={120} />
            </div>
            <div className={styles.Features} />
            <div className={styles.Amenities}>
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={80} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className={clsx(styles.Price, "LodgingCardSkeletonPrice")}
            >
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={100} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
}
