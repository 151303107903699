import { I18nMarkup } from "@hopper-b2b/i18n";
import {
  AirportMap,
  FareDetails,
  TripDetails,
  VIEWED_FLIGHT_DETAILS,
  ViewedFlightDetailsProperties,
  TrackingEventEntryPoint,
} from "@hopper-b2b/types";
import {
  ExpandableCard,
  IconComponent,
  IconName,
  MixedCabinToolTip,
  Slot,
} from "@hopper-b2b/ui";
import {
  getVirtualInterlineLayovers,
  hasHTSConnectSupport,
  removeTimezone,
} from "@hopper-b2b/utilities";
import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { FlightShopReviewDetails } from "../FlightShopReviewDetails";
import { DesktopFlightSummaryRow } from "../FlightShopReviewItinerary/components/DesktopFlightSummaryRow";
import styles from "./ExpandableFlightCard.module.scss";
import { trackEvent } from "@hopper-b2b/api";

type ExpandableCardContentArgs = {
  cardKey: string;
  expanded?: boolean;
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  airports: AirportMap;
  isHackerFare?: boolean;
  isMixedCabinClass?: boolean;
  stacked?: boolean;
  nonHackerFareTitle?: string;
  onChangeFlight?: () => void;
  showChangeFlight?: boolean;
};

export const ExpandableFlightCard = ({
  cardKey,
  expanded,
  departure,
  tripDetails,
  fareDetails,
  airports,
  isHackerFare,
  isMixedCabinClass,
  stacked,
  nonHackerFareTitle,
  onChangeFlight,
  showChangeFlight,
}: ExpandableCardContentArgs) => {
  const [expandedCardKey, setExpandedCardKey] = useState<string>(
    expanded ? cardKey : ""
  );

  const handleCardKeyChange = useCallback(() => {
    trackEvent({
      eventName: VIEWED_FLIGHT_DETAILS,
      properties: {
        outbound_vi_layovers: getVirtualInterlineLayovers(fareDetails, true),
        return_vi_layovers: getVirtualInterlineLayovers(fareDetails, false),
        entry_point: TrackingEventEntryPoint.Details,
        slice_direction_viewed: departure ? "outbound" : "return",
      } as ViewedFlightDetailsProperties,
    });
    setExpandedCardKey(cardKey === expandedCardKey ? "" : cardKey);
  }, [cardKey, expandedCardKey]);

  const originName = airports[tripDetails.slices[departure ? 0 : 1].originCode]
    ? airports[tripDetails.slices[departure ? 0 : 1].originCode].cityName
    : tripDetails.slices[departure ? 0 : 1].originName;
  const destinationName = airports[
    tripDetails.slices[departure ? 0 : 1].destinationCode
  ]
    ? airports[tripDetails.slices[departure ? 0 : 1].destinationCode].cityName
    : tripDetails.slices[departure ? 0 : 1].destinationName;

  const dateOnly = hasHTSConnectSupport();

  const departureTime = tripDetails.slices[departure ? 0 : 1].departureTime;

  return (
    <Box className={styles.container}>
      <Box mb={2}>
        <Slot
          id="expandable-flight-card-title-container"
          departure={departure}
          originName={originName}
          className="card-header-title"
          destinationName={destinationName}
          date={dayjs(removeTimezone(departureTime)).format(
            dateOnly ? "LL" : "LLL"
          )}
          component={
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <IconComponent
                  className={clsx(styles.titleIcon, {
                    [styles.return]: !departure,
                  })}
                  name={IconName.B2BAirplaneIcon}
                  ariaLabel=""
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h6">
                  <I18nMarkup
                    tKey={"flightShopReview.outboundCardHeaderWithOrigin"}
                    replacements={{
                      origin: originName,
                      destination: destinationName,
                      date: dayjs(removeTimezone(departureTime)).format(
                        dateOnly ? "LL" : "LLL"
                      ),
                    }}
                    className="card-header-title"
                  />
                </Typography>
              </Grid>
              {isMixedCabinClass && <MixedCabinToolTip />}
            </Grid>
          }
        />
      </Box>
      <ExpandableCard
        variant="outlined"
        expandedCardKey={expandedCardKey}
        cardKey={cardKey}
        handleCardKeyChange={handleCardKeyChange}
        content={{
          title: (
            <DesktopFlightSummaryRow
              departure={departure}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              originName={originName}
              destinationName={destinationName}
            />
          ),
          expandedTitle: (
            <DesktopFlightSummaryRow
              departure={departure}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              originName={originName}
              destinationName={destinationName}
            />
          ),
          body: (
            <FlightShopReviewDetails
              departure={departure}
              isMultiTicket={isHackerFare}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              isMixedCabinClass={isMixedCabinClass}
              stacked={stacked}
              nonHackerFareTitle={nonHackerFareTitle}
              onChangeFlight={onChangeFlight}
              origin={originName}
              destination={destinationName}
              showChangeFlight={showChangeFlight}
            />
          ),
        }}
      />
    </Box>
  );
};
