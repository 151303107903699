import { Airport } from "@b2bportal/air-shopping-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  EntryButtonEnum,
  FareDetails,
  FlightShopCardType,
  FreezePriceClickProperties,
  PreviousFlightEnum,
  PriceFreezeEntryEnum,
  PriceFreezeHistoryState,
  PriceFreezeTrackingEvents,
  TripDetails,
  TrackingEventEntryPoint,
} from "@hopper-b2b/types";
import {
  MixedCabinToolTip,
  MobileFlightDetailsModal,
  Slot,
} from "@hopper-b2b/ui";
import {
  getEmptyRestrictionsText,
  getEnvVariables,
  getIsMixedClass,
  getSliceIndex,
  useDeviceTypes,
  tenantFlagsEnabled,
  getPlusDaysOnSliceWithDates,
  getPlusDays,
  getAirlinesCountSegment,
  hasHTSConnectSupport,
} from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { matchPath, useHistory } from "react-router";

import { trackEvent } from "@hopper-b2b/api";
import { ClientContext } from "../../../../../../../App";
import {
  PATH_EXCHANGE,
  PATH_PRICE_FREEZE_PURCHASE,
} from "../../../../../../../utils/urlPaths";
import { FlightDetailsConnectorProps } from "./container";

export interface IFlightDetailsProps extends FlightDetailsConnectorProps {
  isOutgoing: boolean;
  selectedFareId: string;
  onFareSubmit: (sliceId: string, fare?: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
  tripDetails: TripDetails;
  rewardsKey: string | undefined;
  departureDate: Date | undefined | null;
  returnDate: Date | undefined | null;
  airports: { [key: string]: Airport };
  openFlightDetailModal: boolean;
  mobileSelectedFareId: string;
  onMobileSelectedFareChange: (fareId: string) => void;
  setExpandedFlight: (expandedFlight: string) => void;
}

export const FlightDetails = ({
  tripDetails,
  isOutgoing,
  selectedFareId,
  onFareSubmit,
  onAlgomerchClick,
  rewardsKey,
  departureDate,
  returnDate,
  airports,
  selectedFareClassFilters,
  selectedOutgoingFareSlice,
  openFlightDetailModal,
  mobileSelectedFareId,
  onMobileSelectedFareChange,
  adultsCount,
  childrenCount,
  infantsInSeatCount,
  infantsOnLapCount,
  getFareSliceByFareId,
  getFreezeOffer,
  setExpandedFlight,
}: IFlightDetailsProps) => {
  const clientContext = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const { t, brand } = useI18nContext();
  const history = useHistory();
  const tenant = getEnvVariables("clientName") as ClientName;
  const isHTSConnect = hasHTSConnectSupport();
  const isUber = tenant === ClientName.UBER;
  const isHopper = tenant === ClientName.HOPPER;
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);
  const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  const plusDays = useMemo(() => {
    if (slice) {
      return !isHTSConnect
        ? getPlusDays(slice!)
        : getPlusDaysOnSliceWithDates(slice.departureTime, slice.arrivalTime);
    } else {
      return 0;
    }
  }, [slice]);

  useEffect(() => {
    if (selectedFareId || mobileSelectedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find(
          (f) => f.id === selectedFareId || f.id === mobileSelectedFareId
        )
      );
    }
  }, [selectedFareId, mobileSelectedFareId, tripDetails.fareDetails]);

  useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails, isOutgoing]);

  const handleMobileModalContinue = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    onFareSubmit(
      slice.id,
      tripDetails.fareDetails.find((f) => f.id === mobileSelectedFareId)
    );
  }, [onFareSubmit, mobileSelectedFareId, slice.id, tripDetails.fareDetails]);

  const priceFreezePurchaseQueryString = useMemo(() => {
    const urlParams: Record<string, string> = {
      tripId: tripDetails.id,
      fareId: mobileSelectedFareId,
    };

    // Add passenger query params
    if (adultsCount) {
      urlParams.adultsCount = `${adultsCount}`;
    }
    if (childrenCount) {
      urlParams.childrenCount = `${childrenCount}`;
    }
    if (infantsInSeatCount) {
      urlParams.infantsInSeatCount = `${infantsInSeatCount}`;
    }
    if (infantsOnLapCount) {
      urlParams.infantsOnLapCount = `${infantsOnLapCount}`;
    }

    return new URLSearchParams(urlParams).toString();
  }, [
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    mobileSelectedFareId,
    tripDetails.id,
  ]);

  const handleMobileModalPriceFreezeEntry = useCallback(() => {
    trackEvent({
      eventName: PriceFreezeTrackingEvents.FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: PriceFreezeEntryEnum.FLIGHT_LIST,
      } as FreezePriceClickProperties,
    });
    history.push(
      `${PATH_PRICE_FREEZE_PURCHASE}?${priceFreezePurchaseQueryString}`,
      {
        priceFreezeEntry: PriceFreezeEntryEnum.FLIGHT_LIST,
        entryButton: EntryButtonEnum.FREEZE_PRICE,
        previousFlight: PreviousFlightEnum.CHOSEN_FLIGHT,
      } as PriceFreezeHistoryState
    );
  }, [history, priceFreezePurchaseQueryString]);

  const handleMobileFareClick = useCallback(
    (fareId: string) => {
      const tenant = getEnvVariables("clientName");
      switch (tenant) {
        case ClientName.UBER:
        case ClientName.HOPPER:
        case ClientName.NUBANK:
        case ClientName.VOLARIS:
        case ClientName.FLAIR:
          onMobileSelectedFareChange(fareId);
          break;
        default:
          onFareSubmit(
            slice.id,
            tripDetails.fareDetails.find((f) => f.id === fareId)
          );
      }
    },
    [
      onFareSubmit,
      onMobileSelectedFareChange,
      slice.id,
      tripDetails.fareDetails,
    ]
  );

  if (!tripDetails) return null;

  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket) {
      return {
        id: fare.id,
        message: t("combinationFlightWarning"),
        tooltipCopy: t("combinationFlightTooltip"),
      };
    } else {
      return null;
    }
  });

  const renderHeader = (
    isOutgoing: boolean,
    location: string,
    date?: Date | null
  ) => {
    if (!location || !date) return undefined;
    return (
      <Typography variant="h5" className="flight-details-header">
        <I18nMarkup
          tKey={
            isOutgoing
              ? "flightShopReview.outboundCardHeader"
              : "flightShopReview.returnCardHeader"
          }
          replacements={{
            location,
            date: dayjs(date).format("ddd, MMM D"),
          }}
        />
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);
  const airlinesCount = getAirlinesCountSegment(
    tripDetails.slices[isOutgoing ? 0 : 1].segmentDetails
  );

  let primaryButtonText = isUber
    ? t("continueBooking")
    : isHopper
    ? isOutgoing
      ? t("selectThisOutbound")
      : t("selectThisReturn")
    : t("continue");
  let secondaryButtonText =
    isUber && getFreezeOffer !== null
      ? t("priceFreezeEntry.freezePrice")
      : null;

  if (inExchange) {
    primaryButtonText = isOutgoing
      ? t("selectThisOutbound")
      : t("selectThisReturn");
    secondaryButtonText = null;
  }

  const fare = tripDetails.fareDetails.find(
    (fare) => fare.id === selectedFareId
  );
  const selectedFare = fare ? fare : tripDetails.fareDetails[0];

  const selectedFareSlice =
    selectedFare.slices[
      selectedFare.slices.findIndex((slice) =>
        isOutgoing ? slice.outgoing : !slice.outgoing
      )
    ];

  return (
    <>
      <Slot
        id="flight-shop-list-expanded-section"
        className={"b2b"}
        inExchange={Boolean(inExchange)}
        selectedFareId={selectedFareId}
        selectedFareClassFilters={selectedFareClassFilters}
        tripDetails={tripDetails}
        isOutgoing={isOutgoing}
        getEmptyRestrictionsText={getEmptyRestrictionsText}
        onFareClick={(fareId: string) =>
          matchesMobile
            ? handleMobileFareClick(fareId)
            : onFareSubmit(
                slice.id,
                tripDetails.fareDetails.find((f) => f.id === fareId)
              )
        }
        fareNotice={hackerFareNotice}
        onAlgomerchClick={onAlgomerchClick}
        rewardsKey={rewardsKey}
        isMobile={matchesMobile}
        plusDays={plusDays}
        header={
          isOutgoing
            ? renderHeader(
                isOutgoing,
                airports[tripDetails.slices[firstSliceIndex].destinationCode]
                  ? airports[
                      tripDetails.slices[firstSliceIndex].destinationCode
                    ].cityName
                  : tripDetails.slices[firstSliceIndex].destinationName,
                departureDate
              )
            : renderHeader(
                isOutgoing,
                airports[tripDetails.slices[secondSliceIndex].destinationCode]
                  ? airports[
                      tripDetails.slices[secondSliceIndex].destinationCode
                    ].cityName
                  : tripDetails.slices[secondSliceIndex].destinationName,
                returnDate
              )
        }
        isMixedCabinClass={isMixedCabinClass}
        cardType={brand.flightShopCardType || FlightShopCardType.REGULAR}
        selectedOutgoingFareSlice={selectedOutgoingFareSlice}
        setMobileSelectedFareId={onMobileSelectedFareChange}
        setExpandedFlight={setExpandedFlight}
        getFlightShopFareSlice={getFareSliceByFareId}
        renderFlightCombinationBanner={
          tenantFlagsEnabled.TenantSupportsViFlights && airlinesCount > 0
        }
      />
      {matchesMobile ? (
        <Slot
          id="mobile-flight-details-expanded"
          className="mobile-flight-summary"
          entryPoint={
            isOutgoing
              ? TrackingEventEntryPoint.Outbound
              : TrackingEventEntryPoint.Return
          }
          isOutgoing={isOutgoing}
          departureTime={
            isOutgoing
              ? tripDetails.slices[0].departureTime
              : tripDetails.slices[tripDetails.slices.length - 1].departureTime
          }
          segments={
            isOutgoing
              ? tripDetails.slices[0].segmentDetails
              : tripDetails.slices[tripDetails.slices.length - 1].segmentDetails
          }
          plusDays={plusDays}
          fareSlice={selectedFareSlice}
          fareDetails={tripDetails.fareDetails}
          tripDetails={tripDetails}
          onMobileContinue={handleMobileModalContinue}
          setExpandedFlight={setExpandedFlight}
          onFareClick={(fareId: string) =>
            matchesMobile
              ? handleMobileFareClick(fareId)
              : onFareSubmit(
                  slice.id,
                  tripDetails.fareDetails.find((f) => f.id === fareId)
                )
          }
        />
      ) : null}
      {!matchesMobile && !isHTSConnect ? null : (
        <MobileFlightDetailsModal
          openModal={openFlightDetailModal}
          tripDetails={tripDetails}
          title={
            <I18nMarkup
              tKey={
                isOutgoing
                  ? "flightShopReview.outboundCardHeader"
                  : "flightShopReview.returnCardHeader"
              }
              replacements={{
                location: slice?.destinationCode,
                date: isHopper
                  ? dayjs(slice?.departureTime).format("dddd, MMMM D")
                  : dayjs(slice?.departureTime).format("ddd, MMM D"),
              }}
            />
          }
          buttonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          secondaryButtonOnClick={handleMobileModalPriceFreezeEntry}
          fareDetails={tripDetails.fareDetails.find(
            (f) => f.id === mobileSelectedFareId
          )}
          className={clsx("b2b", "b2b-secondary")}
          plusDays={plusDays}
          onClose={() => history.goBack()}
          onClick={handleMobileModalContinue}
          departure={isOutgoing}
          isMixedCabinClass={isMixedCabinClass}
          airports={airports}
          assets={clientContext?.assets}
          fullscreen={isUber || isHopper ? false : true}
        />
      )}
    </>
  );
};
