import {
  BaggageFullThumb,
  BunmojiBulbThumb,
  BunmojiLockThumb,
  BunmojiLuggageThumb,
  FlexThumb,
  FlightsThumb,
  IconName,
  RefundableCircleThumb,
  SeatsFullThumb,
} from "@hopper-b2b/ui";

export const UPSELL_MAP = {
  "Blue Basic": {
    targetBrandName: "Blue",
    headingText: "b6Upsell.title.blueBasic",
  },
  Blue: {
    targetBrandName: "Blue Extra",
    headingText: "b6Upsell.title.blue",
  },
};

export type UtaDetails = {
  Icon: React.ComponentType;
  label: string;
};

export const UTA_MAP = {
  "boarding-priority": {
    Icon: BunmojiBulbThumb,
    label: "b6Upsell.uta.boarding",
  },
  "carry-on-allowance": {
    Icon: BaggageFullThumb,
    label: "b6Upsell.uta.carryOn",
  },
  "checked-bag-allowance": {
    Icon: BunmojiLuggageThumb,
    label: "b6Upsell.uta.checkedBags",
  },
  "check-in-priority": {
    Icon: BunmojiLockThumb,
    label: "b6Upsell.uta.checkInPriority",
  },
  "advance-change": {
    Icon: RefundableCircleThumb,
    label: "b6Upsell.uta.change",
  },
  cancellation: {
    Icon: FlightsThumb,
    label: "b6Upsell.uta.cancellation",
  },
  "same-day-change": {
    Icon: FlexThumb,
    label: "b6Upsell.uta.sameDayChange",
  },
  "seat-selection": {
    Icon: SeatsFullThumb,
    label: "b6Upsell.uta.seatSelection",
  },
};

export const OVERRIDE_MAP = {
  "boarding-priority": {
    benefit: {
      "General boarding": "b6Upsell.overrides.generalBoarding",
      "Boards early": "b6Upsell.overrides.boardsEarly",
    },
    restriction: {
      "Boards last": "b6Upsell.overrides.boardsLast",
    },
  },
  cancellation: {
    benefit: {
      "Cancel for credit": "b6Upsell.overrides.cancelForCredit",
    },
  },
};

export const ASSESSMENT_MAP = {
  restriction: {
    name: IconName.DismissOutline,
    className: "dismiss-icon",
  },
  fee: {
    name: IconName.MoneyOutline,
    className: "money-icon",
  },
  benefit: {
    name: IconName.CheckSolid,
    className: "check-icon",
  },
};
