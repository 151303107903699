import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect } from "react";
import * as React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";

export interface IGenericBannerProps {
  className?: string;
  content: string | React.ReactElement;
  containerId?: string;
  isMobile?: boolean;
  tabIndex?: number;
  parentClassName?: string;
}

export const GenericBanner = ({
  className,
  content,
  containerId,
  isMobile,
  parentClassName,
  tabIndex,
}: IGenericBannerProps) => {
  const parentElement = containerId
    ? document.getElementById(containerId)
    : null;
  const [innerHtmlEmptied, setInnerHtmlEmptied] =
    React.useState<boolean>(false);

  useEffect(() => {
    parentClassName && parentElement?.classList.add(parentClassName);
    return function cleanup() {
      parentClassName && parentElement?.classList.remove(parentClassName);
    };
  }, []);

  const renderBanner = () => (
    <Box
      tabIndex={tabIndex}
      className={clsx("generic-banner-root", { mobile: isMobile }, className)}
    >
      <Typography className="banner-content">{content}</Typography>
    </Box>
  );

  const renderPortal = (container: Element) =>
    ReactDOM.createPortal(renderBanner(), container);

  React.useEffect(() => {
    // remove everything inside of the parent container
    if (parentElement && !innerHtmlEmptied) {
      parentElement.innerHTML = "";
      setInnerHtmlEmptied(true);
    }
  }, [innerHtmlEmptied]);

  if (parentElement) {
    if (!innerHtmlEmptied) return null;

    return renderPortal(parentElement);
  } else {
    return renderBanner();
  }
};
