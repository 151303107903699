import { trackEvent } from "@hopper-b2b/api";
import { LodgingCheckoutTrackingEvents } from "@hopper-b2b/types";
import { LodgingMachineContext } from "../../types";

export const trackNewTraveler = async (
  context: LodgingMachineContext,
  _event: unknown
) =>
  trackEvent({
    eventName: LodgingCheckoutTrackingEvents.hotel_viewed_add_guest,
    properties: {},
  });
