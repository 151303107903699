import { useI18nContext } from "@hopper-b2b/i18n";
import { CFAR_OFFER_QUOTE_ID_QUERY_PARAM } from "@hopper-b2b/types";
import { useEnableCfar, useEnableCfarHackerFare } from "@hopper-b2b/utilities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CfarOffers } from "../../../../cfar/CfarOffers";
import { setSelectedCfarOffer } from "../../../../cfar/actions/actions";
import {
  getCfarOffers,
  getSelectedCfarOffer,
  isCfarOffersValid,
} from "../../../../cfar/reducer";
import { useUpdateQueryParams } from "../../../hooks";
import { FintechOption } from "../FintechOption";
import { FintechOptionCardProps } from "../types";
import { AirCfarOffer } from "@b2bportal/air-cfar-api";
import { isReturnFareHackerFareSelector } from "../../../reducer";
import { Product } from "@b2bportal/purchase-api";

export const CfarFintechOptionCard = ({
  disabled,
  onChange,
}: FintechOptionCardProps) => {
  const { t, formatCurrency } = useI18nContext();

  const dispatch = useDispatch();

  const [showHalfSheet, setShowHalfSheet] = useState(false);
  const [initialSelectedCfarOffer, setInitialSelectedCfarOffer] =
    useState(undefined);

  const updateQueryParams = useUpdateQueryParams();

  const enableCfar = useEnableCfar();
  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const hideFlightCfarHackerFare = useEnableCfarHackerFare();
  const isHackerFareEnabledWithCfar = isReturnFareHackerFare
    ? hideFlightCfarHackerFare
    : true; //If HackerFare is disabled/not selected, always return true
  const hasValidCfarOffers = useSelector(isCfarOffersValid(false));
  const showCfarFintechOptionCard =
    enableCfar && hasValidCfarOffers && isHackerFareEnabledWithCfar;

  const cfarOffers = useSelector(getCfarOffers);

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);

  const cheapestCfarOffer = useMemo(
    () =>
      cfarOffers?.reduce(
        (acc, offer) =>
          offer?.premiumAmount?.fiat?.value &&
          acc?.premiumAmount?.fiat?.value < offer?.premiumAmount?.fiat?.value
            ? acc
            : offer,
        {} as AirCfarOffer
      ),
    [cfarOffers]
  );

  const pricePerPax = useMemo(
    () =>
      formatCurrency(cheapestCfarOffer?.premiumAmount?.fiat?.value || 0, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [formatCurrency, cheapestCfarOffer?.premiumAmount?.fiat?.value]
  );

  useEffect(() => {
    updateQueryParams(
      {
        [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedCfarOffer?.quoteId,
      },
      true
    );
  }, [selectedCfarOffer?.quoteId, updateQueryParams]);

  const toggleShowHalfSheet = useCallback(() => {
    setShowHalfSheet(!showHalfSheet);
  }, [showHalfSheet]);

  const onOpen = useCallback(() => {
    setInitialSelectedCfarOffer(selectedCfarOffer);
    toggleShowHalfSheet();
  }, [selectedCfarOffer, toggleShowHalfSheet]);

  const onNext = useCallback(() => {
    onChange();
    toggleShowHalfSheet();
  }, [onChange, toggleShowHalfSheet]);

  const onClose = useCallback(() => {
    dispatch(setSelectedCfarOffer(initialSelectedCfarOffer));
    onNext();
  }, [dispatch, initialSelectedCfarOffer, onNext]);

  return showCfarFintechOptionCard ? (
    <>
      <FintechOption
        title={t?.("cfarOffers.title")}
        description={t?.("fintechSelection.cfarDescription")}
        useCheckbox={false}
        checked={!!selectedCfarOffer}
        onClick={onOpen}
        disabled={disabled}
        iconName="cancel"
        openInfoSheet={onOpen}
        pricePerPax={`${t("from")} ${pricePerPax}/${t("traveler")}`}
        product={Product.AirCfar}
      />
      {showHalfSheet && (
        <CfarOffers isHalfSheet onClose={onClose} goToNextStep={onNext} />
      )}
    </>
  ) : null;
};
