import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Header, BackButton } from "@hopper-b2b/ui";
import { FlightCheckoutCtaSection } from "../../../../../../components/Slots";

import { DesktopLayout } from "@hopper-b2b/checkout";
import "./styles.scss";

interface ReviewComponentProps extends PropsWithChildren {
  handleGoBack: () => void;
  onBookFlight: () => void;
  priceBreakdowndropdown?: JSX.Element;
  rightContent: JSX.Element;
  topContent: JSX.Element;
  clientAssets?: { logo?: string };
  header?: boolean;
  headerTitle?: string;
  ctaLabel?: string;
}

export const ReviewLayout = (props: ReviewComponentProps) => {
  const {
    handleGoBack,
    onBookFlight,
    priceBreakdowndropdown,
    clientAssets,
    rightContent,
    topContent,
    header = true,
    headerTitle,
    children,
  } = props;

  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const content = (
    <>
      {header ? (
        <div className="title-summary-container">
          <Typography variant="h6" className="title">
            {t("tripReview")}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t("tripReviewSubtitle")}
          </Typography>
        </div>
      ) : null}
      {children}
      {matchesMobile ? (
        <FlightCheckoutCtaSection onClick={onBookFlight} showCombinedTotal />
      ) : null}
    </>
  );

  return matchesMobile ? (
    <div
      className={clsx(
        "mobile-flight-book-workflow-root",
        "mobile-review-flight-book"
      )}
    >
      <Header
        className="mobile-flight-book-workflow-header"
        left={
          <BackButton
            className="mobile-flight-book-header-go-back"
            onClick={handleGoBack}
          />
        }
        right={priceBreakdowndropdown}
        center={<>{headerTitle}</>}
        isMobile
        fullWidth
      />
      {content}
    </div>
  ) : (
    <DesktopLayout
      clientLogo={clientAssets?.logo}
      rightContent={rightContent}
      className={clsx("checkout-review-screen", "desktop")}
      topContent={topContent}
    >
      {content}
    </DesktopLayout>
  );
};
