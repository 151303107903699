import { assign } from "xstate";
import {
  ParentState,
  getObjectKeysAsObject,
  setContextWithKey,
  commonSelectors,
} from "@hopper-b2b/checkout";

import { LodgingMachineContext } from "../types";
import { parsePhoneNumber } from "@hopper-b2b/utilities";
import { NubankSessionInfo } from "../../../api/session/startSession";

const DEFAULT_COUNTRY_CODE = "1";

export const commonActions = {
  setInitialContactInformation: assign(
    (context: LodgingMachineContext, _event) => {
      const sessionUserInfo = (
        commonSelectors.getSessionInfo({
          context,
        }) as unknown as NubankSessionInfo
      )?.userInfoResponse;
      const phoneNumber = sessionUserInfo?.phoneNumber;

      const parsedPhone = parsePhoneNumber(phoneNumber);
      const initialContactInfo = {
        countryCode: `+${parsedPhone.countryCode || DEFAULT_COUNTRY_CODE}`,
        phoneNumber: parsedPhone.nationalNumber,
        email: sessionUserInfo?.email || "",
      };

      return setContextWithKey(
        context,
        `${ParentState.contactInformation}.contactInfo`,
        initialContactInfo
      );
    }
  ),
};

export const CommonActionTypes = getObjectKeysAsObject(commonActions);
