import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { Airport } from "@b2bportal/air-shopping-api";
import * as H from "history";

import {
  Prediction,
  ViewedForecastProperties,
  ViewedPriceFreezeProperties,
  TripDetails,
} from "@hopper-b2b/types";

import {
  getViewedForecastProperties,
  predictionSelector,
  refreshPredictionSelector,
  getPriceFreezeOfferCheapestTripTripId,
  airportsSelector,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { actions as shopActions } from "../../../shop/actions";
import {
  getViewedPriceFreezeProperties,
  tripDetailsSelector,
  isPriceFreezeOfferIncludedInShopSummarySelector,
  tripDetailsLoadingSelector,
} from "../../reducer";
import {} from "../../../rewards/reducer";
import { MobilePricePrediction } from "./component";

const mapStateToProps = (state: IStoreState): IStateProps => {
  const tripDetails = tripDetailsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  const airports = airportsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );
  return {
    prediction: predictionSelector(state),
    refreshPrediction: refreshPredictionSelector(state),
    viewedForecastProperties: getViewedForecastProperties(state),
    viewedPriceFreezeProperties: getViewedPriceFreezeProperties(state),
    tripDetails,
    airports,
    isPriceFreezeOfferIncludedInShopSummary:
      isPriceFreezeOfferIncludedInShopSummarySelector(state),
    isTripDetailsLoading: tripDetailsLoadingSelector(state),
  };
};

interface IStateProps {
  prediction: Prediction | null;
  refreshPrediction: boolean;
  viewedForecastProperties: ViewedForecastProperties | null;
  tripDetails: TripDetails | undefined;
  viewedPriceFreezeProperties: ViewedPriceFreezeProperties | null;
  airports: { [key: string]: Airport };
  isPriceFreezeOfferIncludedInShopSummary: boolean;
  isTripDetailsLoading: boolean | null;
}

interface IDispatchProps {
  fetchTripSummaries: any;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    fetchTripSummaries: (history: any, isMobile?: boolean) =>
      dispatch(shopActions.fetchTripSummaries(history, isMobile)),
  };
};

interface OwnProps {
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
  history: H.History;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: OwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type MobilePricePredictionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePricePrediction = connector(MobilePricePrediction);
