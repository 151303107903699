import { useI18nContext } from "@hopper-b2b/i18n";
import { FareDetails, TripDetails } from "@hopper-b2b/types";
import { AirlineIconSize, Slot } from "@hopper-b2b/ui";
import {
  formatInterval,
  getAirlinesCountSegment,
  getPlusDaysOnSliceWithDates,
  getSliceIndex,
  removeTimezone,
} from "@hopper-b2b/utilities";
import { Grid } from "@material-ui/core";
import dayjs from "dayjs";
import { createStopoverString } from "../../../reducer/utils";
import "./DesktopFlightSummary.scss";

type DesktopFlightSummaryRowProps = {
  airlineIconSize?: AirlineIconSize;
  departure?: boolean;
  destinationName: string;
  fareDetails: FareDetails;
  hidePrice?: boolean;
  hideViewDetails?: boolean;
  originName: string;
  tripDetails: TripDetails;
  truncateTerminals?: boolean;
};

export const DesktopFlightSummaryRow = ({
  airlineIconSize,
  departure = false,
  destinationName,
  fareDetails,
  hidePrice = false,
  hideViewDetails = false,
  originName,
  tripDetails,
  truncateTerminals,
}: DesktopFlightSummaryRowProps) => {
  const { t, brand, formatFiatCurrency } = useI18nContext();
  const sliceIndex = getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];
  const additionalAirlinesCount = getAirlinesCountSegment(
    tripSlice.segmentDetails
  );

  // for VI flights is really important to check plus days, the backend does not return plusDays if the layover happens in between
  // thats why we need to check departure against arrivalTime
  const plusDays = getPlusDaysOnSliceWithDates(
    tripSlice.departureTime,
    tripSlice.arrivalTime
  );

  const fareDetailsIndex = getSliceIndex(departure, fareDetails);
  const fareDetailsSlice = fareDetails.slices[fareDetailsIndex];
  const paxPricing = fareDetailsSlice?.paxPricings
    ? fareDetailsSlice.paxPricings[0]
    : undefined;

  const tagsText = [
    fareDetailsSlice.fareScore?.isBest
      ? t("flightShopReview.bestFlight")
      : null,
    fareDetailsSlice.fareScore?.isCheapest
      ? t("flightShopReview.cheapestFlight")
      : null,
    fareDetailsSlice.fareScore?.isBestQuality
      ? t("flightShopReview.bestQuality")
      : null,
    fareDetailsSlice.fareScore?.isFastest
      ? t("flightShopReview.fastest")
      : null,
  ].reduce<string>((curLabels, curLabel) => {
    if (curLabel)
      curLabels += `${curLabels ? ` ${t("andSeparator")} ` : ""}${curLabel}`;
    return curLabels;
  }, "");

  return (
    <Grid
      container
      alignItems="center"
      className="desktop-flight-summary-row"
      wrap="nowrap"
    >
      <Grid item xs>
        <Slot
          id="flight-summary-row"
          airline={firstTripSegment.airlineName}
          airlineCode={firstTripSegment.airlineCode}
          airlineIconSize={airlineIconSize}
          segments={tripSlice.segmentDetails}
          arrivalCode={tripSlice.destinationCode}
          arrivalTime={dayjs(removeTimezone(tripSlice.arrivalTime)).format(
            brand.timeFormat ?? "h:mm A"
          )}
          departureCode={tripSlice.originCode}
          departureTime={dayjs(removeTimezone(tripSlice.departureTime)).format(
            brand.timeFormat ?? "h:mm A"
          )}
          destinationName={destinationName}
          duration={formatInterval(
            dayjs(tripSlice.arrivalTime).diff(
              dayjs(tripSlice.departureTime),
              "minute",
              true
            )
          )}
          fareClass={fareDetailsSlice.fareShelf?.brandName ?? ""}
          flightNumber={firstTripSegment.flightNumber}
          hidePrice={hidePrice}
          layoverString={createStopoverString(tripSlice) || ""}
          numStops={tripSlice.stops}
          originName={originName}
          price={
            paxPricing
              ? formatFiatCurrency(paxPricing.pricing.baseAmount.fiat)
              : ""
          }
          tagsText={tagsText}
          truncateTerminals={truncateTerminals}
          additionalAirlinesCount={additionalAirlinesCount}
          plusDays={plusDays}
        />
      </Grid>
      {hideViewDetails ? null : (
        <Grid item>
          <span className="view-details">{t("viewDetails")}</span>
        </Grid>
      )}
    </Grid>
  );
};
