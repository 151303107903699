import { Flights, Prediction } from "@b2bportal/air-shopping-api";

import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, ITripTerminus, TripCategory } from "@hopper-b2b/types";
import { Header, IconComponent, IconName, Slot } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useDeviceTypes,
  useEnableCfar,
  useEnableChfar,
  useEnableMissedConnection,
  useEnableScheduleChange,
  useEnableWallet,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ClientContext } from "../../../../App";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { RewardsAccountSelection } from "../../../rewards/components";
import { FlightShopSearchControl } from "../../../search/components/FlightShopSearchControlV3";
import { FlightShopHeader } from "../../components";
import { FintechCustomizePage } from "../../components/FintechCustomizePage";
import { FlightShopReviewItinerary } from "../../components/FlightShopReviewItinerary";
import { useGoToCheckout, useUpdateFlightShopStep } from "../../hooks";

import { WalletOfferPage } from "../../../wallet";
import {
  FlightShopStep,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  isInFintechSelectionStepSelector,
  isInReviewStepSelector,
  isInWalletOfferStepSelector,
} from "../../reducer";
import { FlightList } from "./FlightList";
import { IFlightListProps } from "./FlightList/component";
import { DesktopPricePrediction } from "../../components/DesktopPricePrediction";

type DesktopFlightShopRewardsHeaderProps = {
  isRoundTrip: boolean;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  logoSrc: string;
};

const DesktopFlightShopRewardsHeader = ({
  isRoundTrip,
  origin,
  destination,
  logoSrc,
}: DesktopFlightShopRewardsHeaderProps) => {
  const { t, brand } = useI18nContext();
  const history = useHistory();

  const isInChooseDepartureStep = useSelector(isInChooseDepartureStepSelector);
  const isInReviewStep = useSelector(isInReviewStepSelector);

  return (
    <Header
      className="desktop-flight-shop-rewards-header-container"
      left={
        <Box className={"rewards-account-section-left-content"}>
          <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
            <div className={clsx("header-logo-container")}>
              {logoSrc ? (
                <>
                  <img
                    src={logoSrc}
                    className={`mobile img-logo client-logo header-logo-client ${brand?.clientName?.toLowerCase()}`}
                    alt={t("b2bLogo")}
                  />
                  <hr className={clsx("header-logo-divider")} />
                </>
              ) : null}
              <IconComponent
                ariaLabel={t("hopperLogo")}
                className={"mobile img-logo header-logo-hopper"}
                name={IconName.HopperLogo}
              />
            </div>
          </Box>
          <Box className={"rewards-account-section-travel-details"}>
            <Typography variant={"body1"}>
              {isInReviewStep
                ? t("reviewItineraryHeader")
                : origin && destination
                ? isInChooseDepartureStep
                  ? // TODO: test what the origin and destination label work in different languages
                    t("chooseFlightOutgoing", {
                      destination: destination.label.split(",")[0],
                    })
                  : t("chooseFlightReturn", {
                      origin: origin.label.split(",")[0],
                    })
                : ""}
            </Typography>
            <Typography variant={"body2"} className={"flight-header-subtitle"}>
              {isInReviewStep
                ? t("reviewItinerarySubtitle")
                : isRoundTrip
                ? t("flightPricesRoundTrip")
                : t("flightPricesOneWayTrip")}
            </Typography>
          </Box>
        </Box>
      }
      right={
        <Box className="desktop-flight-shop-rewards-account-contents">
          <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
        </Box>
      }
    />
  );
};

type DesktopFlightShopProps = {
  isPriceFreezeEnabled: boolean;
  tripSummariesLoading: boolean;
  flights: Flights | null;
  prediction: Prediction | null;
} & Omit<DesktopFlightShopRewardsHeaderProps, "logoSrc"> &
  Omit<IFlightListProps, "isInChooseReturnStep">;

export const DesktopFlightShop = ({
  isRoundTrip,
  isPriceFreezeEnabled,
  tripSummariesLoading,
  flights,
  flightsToRender,
  origin,
  destination,
  rewardsKey,
  handleFareSubmit,
  fareClassFilter,
  handleFlightSelect,
  expandedFareDetails,
  departureDate,
  returnDate,
  hasFlightsError,
  selectedTrip,
  maxFlightPrice,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setOpenCalendarModal,
  prediction,
}: DesktopFlightShopProps) => {
  const { matchesMediumDesktopOnly } = useDeviceTypes();

  const clientContext = useContext(ClientContext);

  const isSessionAuthenticated = useIsSessionAuthenticated();

  const showFlightCfar = useEnableCfar();
  const showFlightChfar = useEnableChfar();
  const showFlightMissedConnection = useEnableMissedConnection();
  const showFlightScheduleChange = useEnableScheduleChange();

  const isInChooseDepartureStep = useSelector(isInChooseDepartureStepSelector);
  const isInChooseReturnStep = useSelector(isInChooseReturnStepSelector);
  const isInReviewStep = useSelector(isInReviewStepSelector);
  const isInFintechSelectionStep = useSelector(
    isInFintechSelectionStepSelector
  );
  const isInWalletOfferStep = useSelector(isInWalletOfferStepSelector);

  const showFlightWallet = useEnableWallet();

  const showWalletStep =
    showFlightWallet && isSessionAuthenticated && isInWalletOfferStep;

  const updateFlightStep = useUpdateFlightShopStep();
  const goToCheckout = useGoToCheckout();

  const handleReviewItineraryContinue = useCallback(() => {
    if (
      showFlightCfar ||
      showFlightChfar ||
      showFlightMissedConnection ||
      showFlightScheduleChange
    ) {
      updateFlightStep(FlightShopStep.FintechSelection);
    } else if (isInReviewStep && showFlightWallet && isSessionAuthenticated) {
      updateFlightStep(FlightShopStep.WalletOffers);
    } else {
      goToCheckout();
    }
  }, [
    goToCheckout,
    isInReviewStep,
    isSessionAuthenticated,
    showFlightCfar,
    showFlightChfar,
    showFlightMissedConnection,
    showFlightScheduleChange,
    showFlightWallet,
    updateFlightStep,
  ]);

  const handleChangeFlight = useCallback(
    (departure: boolean) => {
      updateFlightStep(
        departure ? FlightShopStep.ChooseDeparture : FlightShopStep.ChooseReturn
      );
    },
    [updateFlightStep]
  );

  // TODO: fix, add next step controller similar to mobile
  const goToNextStepFromFintech = useCallback(
    (replace?: boolean) => {
      if (showFlightWallet && isSessionAuthenticated) {
        updateFlightStep(FlightShopStep.WalletOffers, replace);
      } else {
        goToCheckout(replace);
      }
    },
    [goToCheckout, isSessionAuthenticated, showFlightWallet, updateFlightStep]
  );

  const showFlightShopSearchControls =
    !isInReviewStep && !isInFintechSelectionStep && !isInWalletOfferStep;

  const showFlightShopPricePrediction = useMemo(() => {
    return (
      !isInChooseReturnStep &&
      !isInReviewStep &&
      !isInFintechSelectionStep &&
      !isInWalletOfferStep &&
      prediction &&
      getEnvVariables("clientName") === ClientName.COMMBANK_AU
    );
  }, [
    prediction,
    isInChooseReturnStep,
    isInReviewStep,
    isInFintechSelectionStep,
    isInWalletOfferStep,
  ]);

  return (
    <>
      <DesktopFlightShopRewardsHeader
        isRoundTrip={isRoundTrip}
        origin={origin}
        destination={destination}
        logoSrc={clientContext?.logo}
      />
      <Box className="flight-shop-result-container">
        {showFlightShopSearchControls ? (
          <>
            <FlightShopSearchControl disabled={isPriceFreezeEnabled} />
            <Divider />
          </>
        ) : null}
        {showFlightShopPricePrediction ? (
          <DesktopPricePrediction
            tripCategory={
              isRoundTrip ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY
            }
            predictionDetails={{
              predictionCopy: prediction.predictionCopy,
              dealness: prediction.dealness,
              lowestPrice: prediction.lowestPrice,
              pricePrediction: prediction.pricePrediction,
            }}
          />
        ) : null}
        <FlightShopHeader
          isMobile={false}
          isMediumDesktop={matchesMediumDesktopOnly}
          isInPriceFreezePurchase={isPriceFreezeEnabled}
          isInSimilarFlights={false}
        />
        {tripSummariesLoading ? <Slot id="flight-search-loader" /> : null}
        {!tripSummariesLoading &&
          (!!isInChooseDepartureStep || !!isInChooseReturnStep) && (
            <FlightList
              flightsToRender={flightsToRender}
              tripSummariesLoading={tripSummariesLoading}
              flights={flights}
              rewardsKey={rewardsKey}
              handleFareSubmit={handleFareSubmit}
              fareClassFilter={fareClassFilter}
              handleFlightSelect={handleFlightSelect}
              expandedFareDetails={expandedFareDetails}
              departureDate={departureDate}
              returnDate={returnDate}
              isInChooseReturnStep={isInChooseReturnStep}
              hasFlightsError={hasFlightsError}
              selectedTrip={selectedTrip}
              maxFlightPrice={maxFlightPrice}
              hasAppliedFareClassFilter={hasAppliedFareClassFilter}
              hasAppliedNonFareclassFilter={hasAppliedNonFareclassFilter}
              setOpenCalendarModal={setOpenCalendarModal}
              isRoundTrip={isRoundTrip}
              disablePriceFreeze={!isPriceFreezeEnabled}
            />
          )}
        {isInReviewStep && (
          <FlightShopReviewItinerary
            isMobile={false}
            onContinue={handleReviewItineraryContinue}
            onChangeFlight={handleChangeFlight}
          />
        )}
        {isInFintechSelectionStep && (
          <FintechCustomizePage goToNextStep={goToNextStepFromFintech} />
        )}
        {showWalletStep ? (
          <WalletOfferPage goToNextStep={goToCheckout} />
        ) : null}
      </Box>
    </>
  );
};
