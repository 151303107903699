import { BookingType } from "@hopper-b2b/types";
import { Box, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import CustomerSupportImage from "./contact_support_image.webp";
import FeedbackImg from "./Feedback.svg";
import "./styles.scss";
import { useI18nContext, Trans } from "@hopper-b2b/i18n";
import { CustomerSupportButtons } from "../../ApacContactSupportButtons/CustomerSupportButtons";

interface SupportModalTextContentProps {
  bookingType: BookingType;
  icon?: ReactNode;
  hasVipSupport: boolean;
  supportId?: string;
  showContact?: boolean;
  showHelpLink?: boolean;
  subtitle?: string;
  title?: string;
  showLoginDisclaimer?: boolean;
  chatSupportAction?: () => void;
}

export const SupportModalTextContent = ({
  chatSupportAction,
  supportId,
  title,
  subtitle,
}: SupportModalTextContentProps) => {
  const { t } = useI18nContext();
  return (
    <div className="support-modal-text-content">
      <Box className="row">
        <Box className="cs-icon">
          <img src={FeedbackImg} alt="Customer Support computer" />
        </Box>

        <Box className="cs-text">
          <Typography variant="h1">
            {title ?? t("customerSupportPage.landingPage.title")}
          </Typography>
          <Typography variant="body1">
            {subtitle ?? t("customerSupportPage.landingPage.descriptionA")}
          </Typography>
          <Typography variant="body1">
            <Trans
              i18nKey="customerSupportPage.landingPage.descriptionB"
              components={[<TelLink />]}
            />
          </Typography>
        </Box>
      </Box>

      <Box className="cs-image">
        <img src={CustomerSupportImage} alt="Customer Support computer" />
      </Box>

      <CustomerSupportButtons
        chatSupportAction={chatSupportAction}
        supportId={supportId}
      />
    </div>
  );
};

const TelLink = ({
  children = [],
  className,
}: {
  children?: React.ReactNode[];
  className?: string;
}) => {
  const tel = (Array.isArray(children) ? children : [])
    .filter((child) => typeof child === "string")
    .join("")
    .replace(/\s/gm, "");
  return (
    <a className={className} href={`tel:${tel}`}>
      {children}
    </a>
  );
};
