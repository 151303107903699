import { I18nMarkup } from "@hopper-b2b/i18n";
import { MobileFlightDetailsModal } from "@hopper-b2b/ui";
import {
  getIsMixedClass,
  getPlusDays,
  getSliceIndex,
} from "@hopper-b2b/utilities";
import {
  FlightSelectors,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import clsx from "clsx";

import LocationMarkerWhite from "../../../../../../assets/client/darkMode/location-marker-white.svg";
import AirplaneOutlinedWhite from "../../../../../../assets/client/darkMode/airplane-outline-white.svg";

interface ItineraryDetailsModal {
  openModal: "departure" | "return" | null;
  onClose: () => void;
}

export const ItineraryDetailsModal = ({
  openModal,
  onClose,
}: ItineraryDetailsModal) => {
  const isDeparture = openModal === "departure";

  const airports = useSelector(FlightSelectors.getAirportMap);
  const tripDetails = useSelector(FlightSelectors.getSelectedTripDetailsParent);
  const selectedFareId = useSelector(FlightSelectors.getSelectedFareId);
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );
  const sliceIndex = tripDetails ? getSliceIndex(isDeparture, tripDetails) : 0;
  const tripSlice = tripDetails?.slices[sliceIndex];
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);

  useEffect(() => {
    if (fareDetails && openModal) {
      isDeparture
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails, openModal, isDeparture]);

  const assets = {
    airplane: AirplaneOutlinedWhite,
    locationMarker: LocationMarkerWhite,
  };

  const subtitle = `${tripSlice?.originCode} - ${tripSlice?.destinationCode}`;

  return (
    <MobileFlightDetailsModal
      departure={isDeparture}
      title={
        <I18nMarkup
          tKey={
            isDeparture
              ? "flightShopReview.outboundCardHeader"
              : "flightShopReview.returnCardHeader"
          }
          replacements={{
            location: tripSlice?.destinationCode,
            date: dayjs(tripSlice?.departureTime).format("ddd, MMM D"),
          }}
        />
      }
      subtitle={subtitle}
      plusDays={getPlusDays(tripSlice)}
      isMixedCabinClass={isMixedCabinClass}
      className={clsx("b2b", "b2b-secondary", "mobile-itinerary-details-modal")}
      airports={airports}
      onClose={onClose}
      tripDetails={tripDetails}
      fareDetails={fareDetails}
      assets={assets}
      openModal={!!openModal}
    />
  );
};
