import {
  ParentState,
  cartQuoteSelectors,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";
import { DoneInvokeEvent, assign } from "xstate";
import {
  GenericPaymentError,
  SelectCreditEvent,
  SelectDebitEvent,
  SetCreditInstallment,
  SetPreAuthCheck,
} from "./events";
import { PaymentState, PaymentContext } from "./context";
import {
  NubankPaymentMethod,
  PaymentMethodsResponse,
} from "./api/getPaymentMethods";
import { Payment } from "@b2bportal/purchase-api";
import {
  getSelectedCreditInstallment,
  getSelectedPaymentMethod,
} from "./selectors";

export const NubankPaymentActions = {
  setPreAuthCheck: assign((ctx: PaymentContext, event: SetPreAuthCheck) => {
    ctx[PaymentState.payment].hasAuth = event.data.hasAuth;
    ctx[PaymentState.payment].redirectUrl = event.data.redirectUrl;
    return ctx;
  }),
  setHasAuth: assign((ctx: PaymentContext) => {
    ctx[PaymentState.payment].hasAuth = true;
    return ctx;
  }),
  setPreAuthError: assign(
    (ctx: PaymentContext, event: DoneInvokeEvent<GenericPaymentError>) => {
      ctx[PaymentState.payment].error = {
        step: "preAuthCheck.fetch",
        details: event.data.error,
      };
      return ctx;
    }
  ),
  setRequestAuthError: assign(
    (ctx: PaymentContext, event: GenericPaymentError) => {
      ctx[PaymentState.payment].error = {
        step: "requestAuth",
        details: event.error,
      };
      return ctx;
    }
  ),
  setPaymentMethods: assign(
    (
      ctx: PaymentContext,
      event: DoneInvokeEvent<Array<PaymentMethodsResponse>>
    ) => {
      ctx[PaymentState.payment].paymentMethods = event.data;
      return ctx;
    }
  ),
  setFetchPaymentMethodsError: assign(
    (ctx: PaymentContext, event: DoneInvokeEvent<{ error?: unknown }>) => {
      ctx[PaymentState.payment].error = {
        step: "selectCreditOrDebit.fetch",
        details: event.data.error,
      };
      return ctx;
    }
  ),
  setCreditInstallment: assign(
    (ctx: PaymentContext, event: SetCreditInstallment) => {
      ctx[PaymentState.payment].selectedCreditInstallment =
        event.installmentPlan;
      return ctx;
    }
  ),
  setDebitPaymentMethod: assign(
    (ctx: PaymentContext, event: SelectDebitEvent) => {
      ctx[PaymentState.payment].selectedPaymentMethod = event.debitPayment;
      return ctx;
    }
  ),
  setCreditPaymentMethod: assign(
    (ctx: PaymentContext, event: SelectCreditEvent) => {
      ctx[PaymentState.payment].selectedPaymentMethod = event.creditPayment;
      return ctx;
    }
  ),
  setFulfillRequestPayments: assign((ctx: PaymentContext, _event) => {
    const quoteCipherToken = cartQuoteSelectors.getPriceQuoteCipherText({
      context: ctx,
    });

    const selectedPaymentMethod = getSelectedPaymentMethod({ context: ctx });

    const getIdentifier = () => {
      if (
        selectedPaymentMethod?.paymentMethodType ===
        NubankPaymentMethod.NuCredit
      ) {
        // Credit payment method
        const selectedCreditInstallment = getSelectedCreditInstallment({
          context: ctx,
        });
        return selectedCreditInstallment?.identifier;
      } else {
        // Debit payment method
        return selectedPaymentMethod?.installmentPlans[0].identifier;
      }
    };

    ctx[ParentState.cartFulfill].fulfillRequestPayments = [
      {
        type: Payment.NupayPayment,
        value: {
          encryptedQuoteToken: quoteCipherToken?.value,
          // The identifier for the selected payment method.
          paymentMethodIdentifier: getIdentifier(),
        },
      },
    ];
    return ctx;
  }),
};

export const NubankPaymentActionTypes =
  getObjectKeysAsObject(NubankPaymentActions);
