import { BrowserRouter } from "react-router-dom";
import { UserSourceProvider } from "@hopper-b2b/utilities";
import { CompatRouter } from "react-router-dom-v5-compat";
import App from "./App";

export const Root = () => {
  return (
    <BrowserRouter>
      <CompatRouter>
        <UserSourceProvider>
          <App />
        </UserSourceProvider>
      </CompatRouter>
    </BrowserRouter>
  );
};
