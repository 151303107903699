import { AirExchangeAgentFulfillRequest } from "@b2bportal/air-exchange-api";
import { trackEvent } from "@hopper-b2b/api";
import { SelfServeEvents } from "@hopper-b2b/types";
import { CurrencyFormatters } from "@hopper-b2b/utilities";
import { put, select } from "redux-saga/effects";

import submitFlightExchangeRequest from "../../../api/v0/exchange/submitFlightExchangeRequest";
import { setFlightShopProgress } from "../../shop/actions/actions";
import { FlightShopStep } from "../../shop/reducer";
import { submitExchangeReq } from "../reducer/shop";
import {
  getBaseEventProps,
  getChangeFee,
  getHasCfar,
  getHasChfar,
  getIsFtc,
  getItineraryPNR,
  getOutboundSlice,
  getReservationId,
  getReturnSlice,
  getShoppedFareDetails,
} from "../selectors";
import { buildPayloadSlice } from "../utils";

function* submitExchangeReqSaga(action: ReturnType<typeof submitExchangeReq>) {
  yield put(setFlightShopProgress(FlightShopStep.ExchangeSubmitting));

  const changeFee = yield select(getChangeFee);
  const fareDetails = yield select(getShoppedFareDetails);
  const hasCfar = yield select(getHasCfar);
  const hasChfar = yield select(getHasChfar);
  const isFTC = yield select(getIsFtc);
  const pnr = yield select(getItineraryPNR);
  const reservationId = yield select(getReservationId);
  const outboundSlice = yield select(getOutboundSlice);
  const returnSlice = yield select(getReturnSlice);
  const baseEventProps = yield select(getBaseEventProps);
  let outboundFareSlice, returnFareSlice;

  if (fareDetails?.slices.length) {
    const { length } = fareDetails.slices;

    outboundFareSlice = fareDetails.slices[0];
    returnFareSlice = fareDetails.slices[length - 1];
  }

  const { amount, currency } = changeFee;
  const reqPayload: AirExchangeAgentFulfillRequest = {
    pnr,
    reservationId,
    itinerary: {
      slices: [
        buildPayloadSlice(outboundSlice, outboundFareSlice),
        buildPayloadSlice(returnSlice, returnFareSlice),
      ],
    },
    totalPrice: action.payload,
  };

  try {
    yield submitFlightExchangeRequest(reqPayload);
    yield put(setFlightShopProgress(FlightShopStep.ExchangeSubmitted));

    trackEvent({
      eventName: isFTC
        ? SelfServeEvents.FTCExchangeSubmitSuccess
        : SelfServeEvents.ExchangeSubmitSuccess,
      properties: baseEventProps,
    });

    if (amount && ((isFTC && hasCfar) || (!isFTC && hasChfar))) {
      trackEvent({
        eventName: SelfServeEvents.RebookingFeeWaived,
        properties: {
          agent_locator: pnr,
          originalChangeFee: CurrencyFormatters.get(currency).format(amount),
        },
      });
    }
  } catch (err) {
    trackEvent({
      eventName: isFTC
        ? SelfServeEvents.FTCExchangeSubmitFailure
        : SelfServeEvents.ExchangeSubmitFailure,
      properties: baseEventProps,
    });

    yield put(setFlightShopProgress(FlightShopStep.ExchangeReqFailure));
  }
}

export default submitExchangeReqSaga;
