import { ReactElement } from "react";
import "./styles.scss";
import clsx from "clsx";
import { Box, Button, Typography } from "@material-ui/core";

/**
 * Flight Summary Row
 * @function GenericShopListFooter
 * @param classes
 *
 */
export interface IGenericShopListFooterProps {
  className?: string;
  title: string;
  subtitle: string;
  buttons: IGenericShopListFooterButton[];
  isMobile?: boolean;
}

export interface IGenericShopListFooterButton {
  title: string;
  className?: string;
  isPrimary?: boolean;
  onClick?: () => void;
}

export const GenericShopListFooter = ({
  className,
  title,
  subtitle,
  buttons,
  isMobile,
}: IGenericShopListFooterProps): ReactElement => {
  return (
    <Box
      className={clsx("generic-shop-list-footer", className, {
        mobile: isMobile,
      })}
    >
      <Typography
        className="generic-shop-list-footer-title"
        variant="subtitle1"
      >
        {title}
      </Typography>
      <Typography
        className="generic-shop-list-footer-subtitle"
        variant="subtitle2"
      >
        {subtitle}
      </Typography>
      <Box className="buttons">
        {buttons.map((b) => (
          <Button
            key={b.title}
            onClick={b.onClick}
            className={clsx("generic-footer-button", b.className, {
              primary: b.isPrimary,
            })}
          >
            {b.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
export default GenericShopListFooter;
