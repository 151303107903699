import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  airportsSelector,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../../reducer/selectors";
import { getSelectedAccountReferenceId } from "../../../../../rewards/reducer";
import { FlightDetails } from "./component";
import { getDepartureDate, getReturnDate } from "../../../../../search/reducer";

export interface IFlightDetailsProps {
  tripId: string;
}

const mapStateToProps = (state: IStoreState, props: IFlightDetailsProps) => ({
  tripDetails: tripDetailsSelector(state, props.tripId),
  outgoingFareRating: selectedTripSelector(state).outgoingFareRating,
  rewardsKey: getSelectedAccountReferenceId(state) ?? undefined,
  airports: airportsSelector(state, props.tripId),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightDetails);
