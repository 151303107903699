import {
  ADDED_WATCH,
  CallState,
  CreateWatchAlertRequest,
  Dealness,
  MessageMethodEnum,
  Prediction,
} from "@hopper-b2b/types";
import { put, select } from "redux-saga/effects";

import { createWatch } from "@hopper-b2b/api";
import Logger from "../../../helpers/Logger";
import { passengerCountSelector } from "../../search/reducer";
import { alertKeySelector, predictionSelector } from "../reducer/selectors";
import { actions } from "../actions";
import { trackEvent } from "@hopper-b2b/api";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";

export function* createWatchSaga({ email }: actions.ICreateWatch) {
  try {
    const request: CreateWatchAlertRequest = {
      key: yield select(alertKeySelector),
      method: {
        email,
        MessageMethod: MessageMethodEnum.Email,
      },
      passengers: yield select(passengerCountSelector),
    };
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    yield createWatch(request, apiConfig);
    yield put(actions.listWatches());
    yield put(actions.setCreateWatchCallState(CallState.Success));
    const prediction: Prediction | null = yield select(predictionSelector);
    trackEvent(
      {
        eventName: ADDED_WATCH,
        properties: {
          recommendation: prediction?.dealness
            ? prediction.dealness === Dealness.Wait
              ? "wait"
              : "buy"
            : null,
        },
      },
      apiConfig
    );
  } catch (e) {
    yield put(actions.setCreateWatchCallState(CallState.Failed));
    Logger.debug(e);
  }
}
