import { useSelector } from "react-redux";

import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom-v5-compat";

import { LodgingShopQueryParams, LodgingShopSteps } from "@hopper-b2b/types";
import {
  useDeviceTypes,
  useEnableWallet,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { CHECKOUT_PATH } from "../../checkout/constants";
import { getCurrentStep } from "../reducer/selectors";

export const useGoToShopLodgingDetailsStep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();

  return useCallback(
    (replace = false) => {
      const updatedStepSearch = new URLSearchParams(search);
      updatedStepSearch.set(
        LodgingShopQueryParams.SHOP_STEP,
        LodgingShopSteps.LODGING_DETAILS
      );

      navigate(
        { pathname: location.pathname, search: updatedStepSearch.toString() },
        { replace }
      );
    },
    [location.pathname, navigate, search]
  );
};

export const useGoToNextStep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();

  const isAuthenticated = useIsSessionAuthenticated();
  const enableWallet = useEnableWallet();

  const currentStep = useSelector(getCurrentStep);

  const { matchesMobile: isMobile } = useDeviceTypes();

  const maybeAddRoomSelectionStep = useMemo(() => {
    return isMobile ? [LodgingShopSteps.ROOM_SELECTION] : [];
  }, [isMobile]);

  const maybeAddCfarStep = useMemo(() => {
    //TODO
    return [];
  }, []);

  const maybeAddFintechSelectionStep = useMemo(() => {
    //TODO
    return [];
  }, []);

  // Need to validate no one is using lodging wallet
  // const maybeAddWalletStep = useMemo(() => {
  //   return enableWallet && isAuthenticated
  //     ? [LodgingShopSteps.WALLET_OFFERS_SELECTION]
  //     : [];
  // }, [enableWallet, isAuthenticated]);

  const steps = useMemo(() => {
    return [
      LodgingShopSteps.LODGING_DETAILS,
      ...maybeAddRoomSelectionStep,
      ...maybeAddFintechSelectionStep,
      ...maybeAddCfarStep,
      // ...maybeAddWalletStep,
    ];
  }, [
    maybeAddCfarStep,
    maybeAddRoomSelectionStep,
    maybeAddFintechSelectionStep,
  ]);

  const currentStepIndex = steps.findIndex((s) => s === currentStep);
  const nextStepIndex = currentStepIndex + 1;

  return useCallback(
    (replace = false) => {
      const updatedStepSearch = new URLSearchParams(search);

      if (nextStepIndex < steps.length) {
        updatedStepSearch.set(
          LodgingShopQueryParams.SHOP_STEP,
          steps[nextStepIndex]
        );
      }

      navigate(
        {
          pathname:
            nextStepIndex < steps.length ? location.pathname : CHECKOUT_PATH,
          search: updatedStepSearch.toString(),
        },
        { replace }
      );
    },
    [nextStepIndex, steps, search, navigate, location.pathname]
  );
};
