import { all } from "redux-saga/effects";
import { sagas as flightBook } from "../modules/book";
import { sagas as flightCfar } from "../modules/cfar";
import { sagas as flightChfar } from "../modules/chfar";
import { sagas as flightDisruption } from "../modules/disruption";
import { sagas as flightExchange } from "../modules/exchange";
import { sagas as flightFreeze } from "../modules/freeze";
import { sagas as flightRewards } from "../modules/rewards";
import { sagas as flightSearch } from "../modules/search";
import { sagas as flightShop } from "../modules/shop";
import { sagas as flightWallet } from "../modules/wallet";

export function* rootSaga() {
  yield all([
    flightSearch.watchFetchOriginCategories(),
    flightSearch.watchFetchDestinationCategories(),
    flightSearch.watchFetchSearchFromUrl(),
    flightSearch.watchPopulateSearchUrlParams(),
    flightSearch.watchFetchDepartureCalendar(),

    flightShop.watchSetUpQueryParams(),
    flightShop.watchFetchTripSummariesSaga(),
    flightShop.watchFetchTripSummariesForPredictionSaga(),
    flightShop.watchFetchSimilarFlightsSaga(),
    flightShop.watchFetchTransferSimilarFlightsSaga(),
    flightShop.watchFetchTripSummariesV3Saga(),
    flightShop.watchFetchFlightsV4Saga(),
    flightShop.watchFetchTripDetailsSaga(),
    flightShop.watchFetchSelectedTripPriceFreezeOfferSaga(),
    flightShop.watchCreateWatch(),
    flightShop.watchListWatches(),
    flightShop.watchUpdateWatch(),
    flightShop.watchDeleteWatch(),

    flightBook.watchSetUpQueryParams(),
    flightBook.watchFetchUserPassengers(),
    flightBook.watchDeletePassengerSaga(),
    flightBook.watchUpdateUserPassenger(),

    flightBook.watchDeletePaymentMethodSaga(),
    flightBook.watchFetchPaymentMethodSaga(),
    flightBook.watchListPaymentMethodsSaga(),
    flightBook.watchVerifyPaymentMethodSaga(),

    flightBook.watchCloseSessionSaga(),
    flightBook.watchSetUpFlightBookParamsSaga(),

    flightBook.watchValidatePassengersSaga(),
    flightBook.watchTripPricingSaga(),
    flightBook.watchSchedulePriceQuoteSaga(),
    flightBook.watchPollPriceQuoteSaga(),
    flightBook.watchSchedulePaymentSaga(),
    flightBook.watchPollFinalizedSaga(),

    flightBook.watchProductToEarnSaga(),

    flightBook.watchChargeAgentBookingFeeSaga(),

    flightFreeze.watchSetUpFlightFreezeParams(),
    flightFreeze.watchGetPriceFreezeOffer(),
    flightFreeze.watchFetchPriceFreeze(),
    flightFreeze.watchRefundPriceFreeze(),

    flightDisruption.watchGetDisruptionOffers(),

    flightCfar.watchGetCfarOffer(),
    flightChfar.watchGetChfarOffer(),
    flightWallet.watchGetWalletOffer(),
    flightRewards.watchFetchRewardsAccounts(),
    flightBook.watchSeatMapSaga(),

    flightBook.watchFetchPriceDropEligibilitySaga(),

    flightExchange.watchFetchExchangeContext(),
    flightExchange.watchFetchFlightSummaries(),
    flightExchange.watchFetchTripDetails(),
    flightExchange.watchSubmitExchangeReq(),
  ]);
}
