import { AirPriceDropQuoteRequest } from "@b2bportal/air-price-drop-api";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { ParentState } from "@checkout/types";
import {
  CartContext,
  FlightContext,
  FlightPassengerInformationContext,
} from "@checkout/index";
import { CommonParentContext } from "@checkout/states/common";
import { AirPriceDropContext } from "./context";

export interface AirPriceDropProduct extends ProductOpaqueValue {
  type: typeof Product.AirPriceDrop;
  value: AirPriceDropQuoteRequest;
}

export enum AirPriceDropState {
  route = "route",
  addProduct = "addProduct",
}

export interface PartialParentContextWithAirPriceDrop
  extends CartContext,
    CommonParentContext,
    FlightContext {
  [ParentState.passengerInformation]: FlightPassengerInformationContext;
  [ParentState.priceDrop]: AirPriceDropContext;
}
