import { Suggestion } from "@b2bportal/air-shopping-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../../AxiosInterceptor";
import { trackEvent } from "../../tracking/trackEvent";

const IATA_LOOKUP_PATH = "/api/v0/iataLookup";

export interface FetchIataCodeLookupRequest {
  iataCode: string;
}

export interface FetchIataCodeLookupResponse {
  airport?: Suggestion;
  city?: Suggestion;
}

export const fetchIataLocation = async (
  request: FetchIataCodeLookupRequest
): Promise<FetchIataCodeLookupResponse> => {
  try {
    const res = await axiosInstance.post(IATA_LOOKUP_PATH, request);
    return res.data as FetchIataCodeLookupResponse;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};
