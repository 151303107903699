import { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import dayjs from "dayjs";
import { AirportRegion } from "@b2bportal/air-shopping-api";

import {
  BackButton,
  IconComponent,
  IconName,
  ActionLink,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { TripCategory } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { tenantFlagsEnabled, useEnableDarkMode } from "@hopper-b2b/utilities";
import { getDarkModePreferred } from "@hopper-b2b/themes";

import { CalendarPicker } from "../../../search/components/CalendarPicker";
import { MobileFlightSearchConnectorProps } from "./container";
import "./styles.scss";
import { ClientContext } from "../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarA11yEnabled } from "../../../search/reducer";
import { setCalendarA11yMode } from "../../../search/actions/actions";

export interface ICalendarModalProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {
  open: boolean;
  onClose: () => void;
  onGoBack: () => void;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  handleSearch: () => void;
  originCode?: AirportRegion;
  destinationCode?: AirportRegion;
}

export const CalendarModal = (props: ICalendarModalProps) => {
  const {
    originCode,
    destinationCode,
    fetchDepartureCalendar,
    setCalendar,
    open,
    onClose,
    onGoBack,
    calendarVisited,
    setCalendarVisited,
    populateSearchUrlParams,
    hasSelectedDates,
    trackingProperties,
    handleSearch,
    tripCategory,
    departureDate,
    returnDate,
    setDepartureDate,
    setReturnDate,
  } = props;

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      originCode?.code &&
      destinationCode?.code
    ) {
      fetchDepartureCalendar(originCode, destinationCode);
    } else {
      setCalendar();
    }
  }, [
    originCode?.code,
    destinationCode?.code,
    fetchDepartureCalendar,
    setCalendar,
    originCode,
    destinationCode,
  ]);

  const darkModeAllowed = useEnableDarkMode();
  const isDarkMode = darkModeAllowed && getDarkModePreferred();

  const { assets } = useContext(ClientContext);
  const { featureFlag } = useContext(ClientContext);
  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();

  const dispatch = useDispatch();

  const calendarA11yModeSelector = useSelector(getCalendarA11yEnabled);

  const calendarA11yHandler = useCallback(() => {
    dispatch(setCalendarA11yMode(!calendarA11yModeSelector));
  }, [calendarA11yModeSelector, dispatch]);

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      fullScreen={true}
      className={clsx("calendar-modal-popover", isDarkMode && "dark-mode")}
      contentClassName="calendar-modal-wrapper"
      topLeftButton={
        <ActionLink
          className="calendar-modal-header-close-button"
          onClick={onClose}
          content={
            clientContext?.assets?.close ? (
              <img
                src={clientContext.assets.close}
                className={clsx("close-button-icon")}
                alt={t("close")}
              />
            ) : (
              <IconComponent
                ariaLabel={t("close.imageAlt")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            )
          }
          label="Close"
        />
      }
      topRightButton={
        featureFlag?.enableA11yMode ? (
          <ActionLink
            className="mobile-flight-search-header-calendar-a11y"
            content={
              <img
                src={
                  calendarA11yModeSelector
                    ? assets?.a11yIconActive
                    : assets?.a11yIcon
                }
                alt=""
              />
            }
            onClick={calendarA11yHandler}
          />
        ) : null
      }
    >
      <div
        className={clsx(
          "calendar-modal-root",
          "date-range-picker",
          calendarA11yModeSelector && "a11y-active"
        )}
      >
        <HeaderCenterSection
          originCode={originCode?.code}
          destinationCode={destinationCode?.code}
          tripCategory={tripCategory}
        />
        <CalendarPicker
          tripCategory={tripCategory}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          focusedMonthIndex={focusedMonthIndex}
          isHistoryPushDisabled={true}
          onComplete={handleSearch}
          isMobile
          modal
          calendarVisited={calendarVisited}
          setCalendarVisited={setCalendarVisited}
          populateSearchUrlParams={populateSearchUrlParams}
          disabled={!hasSelectedDates}
          trackingProperties={trackingProperties}
        />
      </div>
    </MobilePopoverCard>
  );
};

interface IHeaderCenterSectionProps {
  originCode?: string;
  destinationCode?: string;
  tripCategory: TripCategory;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { t } = useI18nContext();
  const { originCode, destinationCode } = props;
  return (
    <div className="header-center-section">
      {originCode && destinationCode && (
        <div className="calendar-modal-header-container">
          <h3>{t("calendarModal.updateDate")}</h3>
        </div>
      )}
      {(!originCode || !destinationCode) && (
        <div className="calendar-modal-header-container">
          <span>{t("flights")}</span>
        </div>
      )}
    </div>
  );
};
