import { GenericResponseScreen } from "../GenericResponseScreen";

interface IConfirmationScreenProps {
  title: string;
  subtitle: string;
  primaryOnClick: () => void;
  primaryButtonText: string | JSX.Element;
  secondaryOnClick?: () => void;
  secondaryButtonText?: string | JSX.Element;
  onBack?: () => void;
  className?: string;
  modal?: boolean;
  header?: string | JSX.Element;
}

const ConfirmationScreen = ({
  title,
  subtitle,
  primaryOnClick,
  primaryButtonText,
  secondaryOnClick,
  secondaryButtonText,
  onBack,
  className,
  modal = false,
  header,
}: IConfirmationScreenProps) => {
  return (
    <GenericResponseScreen
      title={title}
      subtitle={subtitle}
      primaryOnClick={primaryOnClick}
      primaryButtonText={primaryButtonText}
      secondaryOnClick={secondaryOnClick}
      secondaryButtonText={secondaryButtonText}
      onBack={onBack}
      className={className}
      modal={modal}
      header={header}
    />
  );
};

export default ConfirmationScreen;
