import { trackEvent } from "@hopper-b2b/api";
import { VIEW_PAYMENT } from "@hopper-b2b/types";
import { getViewPaymentProperties } from "../../selectors";
import { FlightMachineContext } from "../../types";

export const trackViewPayment = (
  context: FlightMachineContext,
  _event: unknown
) =>
  new Promise((resolve) => {
    const trackingProperties = getViewPaymentProperties({ context });
    trackEvent({
      eventName: VIEW_PAYMENT,
      properties: trackingProperties,
    })
      .then(() => resolve(null))
      .catch((e) => resolve(null));
  });
