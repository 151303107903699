import { useEffect } from "react";
import { GenericInfoPopup, Icon, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { trackEvent } from "@hopper-b2b/api";
import {
  ModalScreens,
  MODAL_ALERT,
  ModalCategoryType,
  ModalAlertProperties,
  ProductType,
} from "@hopper-b2b/types";
import clsx from "clsx";

import "./styles.scss";

type DefaultStyle = "h4r-primary" | "h4r-secondary";

export interface IErrorModal {
  open: boolean;
  iconName?: IconName;
  title: string;
  subtitle: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryOnClick: () => void;
  secondaryOnClick?: () => void;
  trackingEventProperties?: ModalAlertProperties;
  image?: string;
  className?: string;
  onClose?: () => void;
  productType?: ProductType;
  modalType?: string;
}

export const ErrorModal = ({
  primaryButtonText,
  primaryOnClick,
  secondaryButtonText,
  secondaryOnClick,
  title,
  iconName,
  subtitle,
  open,
  trackingEventProperties,
  image,
  className,
  onClose,
  productType,
  modalType,
}: IErrorModal) => {
  const { matchesMobile } = useDeviceTypes();
  const hasSecondaryButton = secondaryButtonText && secondaryOnClick;
  const modalEventProperties = {
    ...trackingEventProperties,
    primary_button: primaryButtonText,
    secondary_button: hasSecondaryButton ? secondaryButtonText : undefined,
    screen: ModalScreens.FLIGHTS_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    product_type: productType,
    modal_type: modalType,
  };

  useEffect(() => {
    if (open)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: modalEventProperties,
      });
  }, [open]);

  const getButtons = () => {
    const buttons = [];

    buttons.push({
      buttonText: primaryButtonText,
      onClick: primaryOnClick,
      defaultStyle: "h4r-primary" as DefaultStyle,
    });

    if (hasSecondaryButton) {
      buttons.push({
        buttonText: secondaryButtonText,
        onClick: secondaryOnClick,
        defaultStyle: "h4r-secondary" as DefaultStyle,
      });
    }

    return buttons;
  };

  const defaultIcon = IconName.ErrorState;

  return (
    <GenericInfoPopup
      buttons={getButtons()}
      className={clsx("flights-booking-error-modal", className, {
        mobile: matchesMobile,
        "with-secondary": hasSecondaryButton,
      })}
      image={
        image ? (
          <img className="error-icon image" src={image} alt="" />
        ) : (
          <Icon className="error-icon" name={iconName || defaultIcon} />
        )
      }
      isMobile={matchesMobile}
      open={open}
      subtitle={subtitle}
      title={title}
      // note: mobile popovers in the checkout workflow have z-index: 1300
      zIndex={matchesMobile ? 1301 : 1300}
      onClose={onClose}
    />
  );
};
