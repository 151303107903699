import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { SeatMapResponseEnum } from "@b2bportal/air-booking-api";
import {
  roundToTwoDecimals,
  getTotalPassengerFromPaxPricing,
  getVirtualInterlineLayovers,
} from "@hopper-b2b/utilities";
import {
  Currency,
  RewardsPrice,
  FiatPrice,
  PaymentAmountEnum,
  PaymentSplitRequest,
  PaymentSplitRequestEnum,
  SchedulePriceQuoteRequest,
  IsEligible,
  ReviewFlightDetailsProperties,
  FareBrand,
  CompleteBuyAirProperties,
  getHopperFareRatingName,
  PostQuoteAncillariesEnum,
  PriceFreezeSchedulePriceQuoteRequest,
  PriceFreezeItineraryEnum,
  FlightBookType,
  PostQuoteAncillaries,
  PaymentType,
  TypeOfPaymentEnum,
  SplitPaymentType,
  UserCardPaymentType,
  PriceDropProtection,
  ProtectionId,
  PassengersForFareRequest,
  CompleteBuyAirPriceFreezeProperties,
  PassengerTypes,
} from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";
import {
  getAgentEmail,
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
} from "../../../rewards/reducer";
import {
  priceFreezeOfferSelector,
  passengerCountSelector,
  priceFreezeOfferTotalSelector,
  priceFreezeOfferCurrencySelector,
  currentPriceFreezeSelector,
  currentPriceFreezePriceDropProtectionSelector,
} from "../../../freeze/reducer";
import {
  getViewedTripSummaryProperties,
  shopPricingInfoSelector,
  selectedTripSelector,
  tripDetailsByIdSelector,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  getFareDetails,
} from "../../../shop/reducer/selectors";

export const getUserPassengers = (state: IStoreState) =>
  state.flightBook.userPassengers;

export const getUserSelectedPassengerIds = (state: IStoreState) =>
  state.flightBook.userSelectedPassengerIds;

export const getUserSelectedLapInfantIds = (state: IStoreState) =>
  state.flightBook.userSelectedLapInfantIds;

export const getSelectedPaymentMethodId = (state: IStoreState) =>
  state.flightBook.selectedPaymentMethodId;

export const getSession = (state: IStoreState) => state.flightBook.session;

export const getPriceQuote = (state: IStoreState) =>
  state.flightBook.priceQuote;

export const getTripPricing = (state: IStoreState) =>
  state.flightBook.tripPricing;

export const getFinalizedItinerary = (state: IStoreState) =>
  state.flightBook.finalizedItinerary;

export const getFlightBookType = (state: IStoreState) =>
  state.flightBook.flightBookType;

export const getFlightBookPriceDropProtection = (state: IStoreState) =>
  state.flightBook.priceDropProtection;

export const getIsSimilarFlightsEnabled = (state: IStoreState) =>
  state.flightBook.isSimilarFlightsEnabled;

export const getSelectedSeats = (state: IStoreState) => {
  return state.flightBook.selectedSeats;
};

export const ancillaryIdsSelector = (state: IStoreState) => {
  const ancillaryIds: ProtectionId[] = [];
  // TODO: add cfar option to the ancillary list once the component is added.

  return ancillaryIds;
};

export const pricingParamsSelector = createSelector(
  selectedTripSelector,

  // xstate
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,

  currentPriceFreezeSelector,
  ancillaryIdsSelector,
  getFlightBookType,
  (
    selectedTrip,
    userPassengers,
    selectedTravelerIds,
    selectedLapInfants,
    priceFreeze,
    ancillaryIds,
    flightBookType
  ): PassengersForFareRequest => {
    const fareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;
    const defaultPricingParams = {
      tripId: selectedTrip.tripId || "",
      seatedPassengers: userPassengers
        .filter((p) => selectedTravelerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: userPassengers
        .filter((p) => selectedLapInfants.includes(p.id))
        .map((u) => u.id),
      fareId: fareId!,
      ancillaryIds,
    };

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        return {
          ...defaultPricingParams,
          priceFreezeId: priceFreeze?.priceFreeze.id,
        };
      case FlightBookType.DEFAULT:
      default:
        return defaultPricingParams;
    }
  }
);

export const getContactEmail = (state: IStoreState) =>
  state.flightBook.confirmationEmailAddress;

export const getContactPhone = (state: IStoreState) =>
  state.flightBook.confirmationPhoneNumber;

export const currentPriceDropProtectionSelector = createSelector(
  getFlightBookPriceDropProtection,
  currentPriceFreezePriceDropProtectionSelector,
  getFlightBookType,
  (
    priceDropProtection,
    priceFreezePriceDropProtection,
    flightBookType
  ): PriceDropProtection | null => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
        return priceFreezePriceDropProtection ?? null;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
      case FlightBookType.DEFAULT:
        return priceDropProtection;
      default:
        return null;
    }
  }
);

export const priceQuoteParamsSelector = createSelector(
  selectedTripSelector,
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  getContactEmail,
  getContactPhone,
  getAgentEmail,
  currentPriceDropProtectionSelector,
  currentPriceFreezeSelector,
  getFlightBookType,
  (
    selectedTrip,
    userPassengers,
    selectedTravelerIds,
    selectedLapInfantIds,
    email,
    phone,
    agentEmail,
    priceDropProtection,
    currentPriceFreeze,
    flightBookType
  ): SchedulePriceQuoteRequest => {
    const getTripId = () => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
          return currentPriceFreeze?.bookingDetails.tripId ?? "";
        case FlightBookType.DEFAULT:
        default:
          return selectedTrip.tripId ?? "";
      }
    };
    const getFareId = () => {
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
          return currentPriceFreeze?.bookingDetails.fareId ?? "";
        case FlightBookType.DEFAULT:
        default:
          return selectedTrip.returnFareId
            ? selectedTrip.returnFareId
            : selectedTrip.outgoingFareId ?? "";
      }
    };

    const candidateId = (priceDropProtection as IsEligible)?.candidateId;
    const params: SchedulePriceQuoteRequest = {
      tripId: getTripId(),
      seatedPassengers: userPassengers
        .filter((p) => selectedTravelerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: userPassengers
        .filter((p) => selectedLapInfantIds.includes(p.id))
        .map((u) => u.id),
      fareId: getFareId(),
      emailAddress: email || "",
      phoneNumber: phone || "",
      ancillaryIds: [],
    };
    if (candidateId) {
      params.priceDropCandidateId = candidateId;
    }
    if (agentEmail) {
      params.delegatedTo = agentEmail;
    }
    return params;
  }
);

// PAYMENT SELECTORS

export const getRewardsPaymentAccountReferenceId = (state: IStoreState) =>
  state.flightBook.rewardsAccountReferenceId;

export const getRewardsPaymentAccount = createSelector(
  getRewardsPaymentAccountReferenceId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getPassengerSeatMap = createSelector(
  getSelectedSeats,
  (seats): Map<string, number> => {
    const map = new Map();
    seats.forEach((seat) => {
      const total = map.get(seat.person_id);
      const seatPrice =
        seat.price_object.usd_total / 10 ** seat.price_object.decimal_places;
      if (total) {
        map.set(seat.person_id, total + seatPrice);
      } else {
        map.set(seat.person_id, seatPrice);
      }
    });
    return map;
  }
);

export const getSeatsTotalPricing = createSelector(
  getPassengerSeatMap,
  (passengerSeatMap) => {
    return Array.from(passengerSeatMap.values()).reduce(
      (currentTotal, seatTotal) => {
        return currentTotal + seatTotal;
      },
      0
    );
  }
);

export const getSeatsRewardsPricing = createSelector(
  getSelectedSeats,
  (seats): Array<{ [key: string]: RewardsPrice }> => {
    return seats.map((s) => s.rewards);
  }
);

export const getFlightTotal = createSelector(
  getTripPricing,
  getPriceQuote,
  getSeatsTotalPricing,
  (tripPricing, priceQuote, seatsTotalPricing) => {
    return priceQuote && priceQuote?.itinerary.sellingPricing.totalPricing.total
      ? priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value +
          seatsTotalPricing
      : tripPricing?.totalPricing?.total.fiat.value;
  }
);

export const getPricing = createSelector(
  getTripPricing,
  getPriceQuote,
  (tripPricing, priceQuote) => {
    return priceQuote?.itinerary.sellingPricing || tripPricing;
  }
);

export const getFlightTotalInRewards = createSelector(
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  getPricing,
  getSeatsRewardsPricing,
  (
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    tripPricing,
    seatsRewardsPricing
  ): RewardsPrice | null => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if (!tripPricing || !activeRewardsAccount) return null;

    const { accountReferenceId } = activeRewardsAccount;
    const tripTotal =
      tripPricing?.totalPricing.total.rewards[accountReferenceId];

    if (seatsRewardsPricing.length > 0) {
      const seatsRewardsTotal = seatsRewardsPricing.reduce(
        (total: number, rewardsMap: { [key: string]: RewardsPrice }) => {
          const rewardsPrice = rewardsMap[accountReferenceId];
          return total + rewardsPrice.value;
        },
        0
      );
      const tripSeatsTotal: RewardsPrice = {
        value: seatsRewardsTotal + tripTotal.value,
        currency: tripTotal.currency,
      };
      return tripSeatsTotal;
    } else {
      return tripTotal;
    }
  }
);

export const getRewardsPaymentInFiatCurrency = (state: IStoreState) =>
  state.flightBook.rewardsPaymentInFiatCurrency;

export const getRewardsPaymentInRewardsCurrency = (state: IStoreState) =>
  state.flightBook.rewardsPaymentTotal;

export const currencySelector = createSelector(
  shopPricingInfoSelector,
  priceFreezeOfferCurrencySelector,
  getFlightBookType,
  (
    pricingInfo,
    priceFreezeCurrency,
    flightBookType
  ): {
    currencyCode: string;
    currencySymbol: string;
  } => {
    // TODO: as Mer has noted, we should remove hard coded currency values
    let currency = {
      currencyCode: Currency.USD,
      currencySymbol: "$",
    };

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        if (pricingInfo.fare && pricingInfo.fare.length > 0) {
          currency = {
            currencyCode: pricingInfo.fare[0].pricing.baseAmount.fiat
              .currencyCode as Currency,
            currencySymbol:
              pricingInfo.fare[0].pricing.baseAmount.fiat.currencySymbol,
          };
        }
        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        if (
          priceFreezeCurrency.currencyCode &&
          priceFreezeCurrency.currencySymbol
        ) {
          currency = {
            currencyCode: priceFreezeCurrency.currencyCode as Currency,
            currencySymbol: priceFreezeCurrency.currencySymbol,
          };
        }
        break;
      default:
        break;
    }

    return currency;
  }
);

export const getTotalCreditCardPaymentRequiredInFiatPrice = createSelector(
  getFlightTotal,
  priceFreezeOfferTotalSelector,
  getRewardsPaymentInFiatCurrency,
  currencySelector,
  getFlightBookType,
  (
    flightTotal,
    offerTotal,
    rewardsPaymentAmount,
    currency,
    flightBookType
  ): FiatPrice => {
    let total: number | undefined;

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
        total = flightTotal;
        break;
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        total = offerTotal;
        break;
      default:
        break;
    }

    if (!total) {
      return {
        ...currency,
        value: 0,
      };
    }

    if (!rewardsPaymentAmount) {
      return {
        ...currency,
        value: total,
      };
    }

    const remainder = total - rewardsPaymentAmount.value;
    const final = remainder <= 0 ? 0 : remainder;
    return {
      ...currency,
      value: final,
    };
  }
);

// TODO: this value should come from the backend
// note: it's needed when no priceQuote has been requested yet
export const getPricingInfoFlightTotalInPrices = createSelector(
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  shopPricingInfoSelector,
  currentPriceFreezeSelector,
  getFlightBookType,
  (
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    pricingInfo,
    priceFreeze,
    flightBookType
  ): { fiat: FiatPrice; rewards: RewardsPrice } | null => {
    const activeRewardsPaymentAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    if (!activeRewardsPaymentAccount) {
      return null;
    }
    const { accountReferenceId } = activeRewardsPaymentAccount;

    if (flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE) {
      if (!priceFreeze) {
        return null;
      }
      // note: on start, when none of passengers is selected, it will display the charge price for 'Total'
      const frozenPricing =
        priceFreeze.frozenFare.paxPricings[0].pricing.chargeAmount;

      return {
        fiat: frozenPricing.fiat,
        // the fallback option should never happen unless accountReferenceId cannot be found
        rewards: frozenPricing.rewards[accountReferenceId] ?? {
          value: 0,
          currency: activeRewardsPaymentAccount.rewardsBalance.currency,
        },
      };
    } else {
      if (!pricingInfo.fare || pricingInfo.fare.length === 0) {
        return null;
      }
      const farePricing = pricingInfo.fare[0].pricing;

      const fiatBaseAmount = farePricing.baseAmount.fiat.value || 0;
      const fiatTaxAmount = farePricing.taxAmount.fiat.value || 0;
      const fiatAdditionalMargin = farePricing.additionalMargin
        ? farePricing.additionalMargin.fiat.value
        : 0;

      const rewardsBaseAmount =
        farePricing.baseAmount.rewards[accountReferenceId]?.value ?? 0;
      const rewardsTaxAmount =
        farePricing.taxAmount.rewards[accountReferenceId]?.value ?? 0;
      const rewardsAdditionalMargin = farePricing.additionalMargin
        ? farePricing.additionalMargin.rewards[accountReferenceId].value
        : 0;

      const fiatTotal = fiatBaseAmount + fiatTaxAmount + fiatAdditionalMargin;
      const rewardsTotal =
        rewardsBaseAmount + rewardsTaxAmount + rewardsAdditionalMargin;

      return {
        fiat: {
          currencyCode: farePricing.baseAmount.fiat.currencyCode,
          currencySymbol: farePricing.baseAmount.fiat.currencySymbol,
          value: fiatTotal,
        },
        rewards: {
          value: rewardsTotal,
          currency: activeRewardsPaymentAccount.rewardsBalance.currency,
        },
      };
    }
  }
);

export const getFlightTotalInPrices = createSelector(
  getFlightTotal,
  currencySelector,
  getFlightTotalInRewards,
  getPricingInfoFlightTotalInPrices,
  (
    flightTotal,
    currency,
    flightTotalInRewards,
    pricingInfoFlightTotalInPrices
  ): { fiat: FiatPrice; rewards: RewardsPrice } | null => {
    if (flightTotal && flightTotalInRewards) {
      return {
        fiat: {
          ...currency,
          value: flightTotal,
        },
        rewards: flightTotalInRewards,
      };
    } else if (pricingInfoFlightTotalInPrices) {
      return pricingInfoFlightTotalInPrices;
    }
    return null;
  }
);

const getPaymentRequestType = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  (
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    selectedPaymentMethodId
  ) => {
    if (selectedPaymentMethodId && !rewardsPayment) {
      return PaymentSplitRequestEnum.PaymentCardRequest;
    } else if (
      rewardsAccountId &&
      rewardsPayment &&
      rewardsFiatPayment &&
      creditCardPayment.value === 0
    ) {
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    } else if (rewardsPayment && rewardsAccountId && rewardsFiatPayment) {
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    } else {
      return null;
    }
  }
);

export const getPaymentMethodRewardsAccountId = (state: IStoreState) =>
  state.flightBook.paymentMethodRewardsAccountId;

export const getPaymentRequest = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  getSelectedSeats,
  getFlightBookType,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session,
    selectedSeats,
    flightBookType
  ): PaymentSplitRequest | null => {
    let amount: PaymentType | null = null;
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        amount = {
          accountReferenceId,
          paymentId: paymentId || "",
          paymentAmount: {
            currency: creditCardPayment.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment.value),
            PaymentAmount: PaymentAmountEnum.FiatAmount,
          },
          Payment: TypeOfPaymentEnum.UserCard,
        };
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        amount = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            fiatAmount: {
              currency: creditCardPayment.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment.value),
              PaymentAmount: PaymentAmountEnum.FiatAmount,
            },
            rewardsAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: rewardsPayment!.value,
                currency: rewardsPayment!.currency,
              },
              PaymentAmount: PaymentAmountEnum.RewardsAmount,
            },
            PaymentAmount: PaymentAmountEnum.SplitAmount,
          },
          Payment: TypeOfPaymentEnum.Split,
        };
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        amount = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
            PaymentAmount: PaymentAmountEnum.RewardsAmount,
          },
          Payment: TypeOfPaymentEnum.Rewards,
        };
        break;
      default:
        return null;
    }

    if (amount && session) {
      const ancillaries: PostQuoteAncillaries[] = [];
      switch (flightBookType) {
        case FlightBookType.PRICE_FREEZE_EXERCISE:
        case FlightBookType.DEFAULT:
          if (selectedSeats.length > 0) {
            ancillaries.push({
              seats: {
                seats: selectedSeats,
              },
              PostQuoteAncillaries: PostQuoteAncillariesEnum.Seats,
            });
          }
          break;
        default:
          break;
      }

      const request = {
        token: session,
        payment: amount,
        ancillaries,
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getCardPaymentAccount = createSelector(
  getRewardsAccounts,
  getPaymentRequest,
  (rewardsAccounts, paymentRequest: any) => {
    switch (paymentRequest?.payment.Payment) {
      case TypeOfPaymentEnum.UserCard:
        paymentRequest = paymentRequest as UserCardPaymentType;
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId === paymentRequest.payment.accountReferenceId
        );
      case TypeOfPaymentEnum.Split:
        paymentRequest = paymentRequest as SplitPaymentType;
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId === paymentRequest.payment.accountReferenceId
        );
      default:
        return null;
    }
  }
);

export const getReviewFlightDetailsProperties = createSelector(
  getViewedTripSummaryProperties,
  selectedTripSelector,
  tripDetailsByIdSelector,
  (
    viewedTripSummaryProperties,
    selectedTrip,
    tripDetailsById
  ): ReviewFlightDetailsProperties => {
    const trip = tripDetailsById[selectedTrip?.tripId];
    if (trip && trip.slices.length > 0) {
      const stops =
        trip.slices.length > 1
          ? trip.slices[0].stops + trip.slices[1].stops
          : trip.slices[0].stops;
      const fare = trip.fareDetails.find(
        (f) =>
          f.id === selectedTrip.outgoingFareId ||
          f.id === selectedTrip.returnFareId
      );
      const brand: FareBrand | undefined =
        fare &&
        fare.slices.length > 0 &&
        fare.slices[0].fareDetails.segments.length > 0
          ? fare?.slices[0].fareDetails.segments[0].brand
          : undefined;
      const carrier: string | undefined =
        fare &&
        fare.slices.length > 0 &&
        fare.slices[0].fareDetails.segments.length > 0
          ? fare?.slices[0].fareDetails.segments[0].validatingCarrierCode
          : undefined;
      let provider = "";
      if (brand) {
        if (brand.sabreFareBrand) {
          provider = "Sabre";
        } else if (brand.travelportFareBrand) {
          provider = "Travelport";
        } else if (brand.amadeusFareBrand) {
          provider = "Amadeus";
        } else if (brand.gdxFareBrand) {
          provider = "GDX";
        }
      }
      return {
        stops,
        provider,
        carrier: carrier || "",
        advance: `${dayjs(trip.slices[0].departureTime).diff(
          dayjs(),
          "day"
        )} days`,
        ...viewedTripSummaryProperties,
        departure_date: dayjs(trip.slices[0].departureTime).format(
          "YYYY-MM-DD"
        ),
        destination: viewedTripSummaryProperties.destination
          ? viewedTripSummaryProperties.destination
          : trip.slices[0].destinationName,
        destination_country_code:
          viewedTripSummaryProperties.destination_country_code
            ? viewedTripSummaryProperties.destination_country_code
            : trip.slices[0].destinationCode,
        fare_class: fare
          ? getHopperFareRatingName(fare?.slices[0].fareShelf?.rating)
          : "",
        origin: viewedTripSummaryProperties.origin
          ? viewedTripSummaryProperties.origin
          : trip.slices[0].originName,
        origin_country_code: viewedTripSummaryProperties.origin_country_code
          ? viewedTripSummaryProperties.origin_country_code
          : trip.slices[0].originCode,
        return_date:
          trip.slices.length > 1
            ? dayjs(trip.slices[1].departureTime).format("YYYY-MM-DD")
            : "",
        trip_type: trip.slices.length > 1 ? "round_trip" : "one_way",
      };
    } else {
      return {
        carrier: "",
        stops: 0,
        provider: "",
        advance: "",
        ...viewedTripSummaryProperties,
      };
    }
  }
);

export const getCompleteBuyAirProperties = createSelector(
  getFareDetails,
  getReviewFlightDetailsProperties,
  getRewardsPaymentInFiatCurrency,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getCardPaymentAccount,
  getRewardsPaymentInRewardsCurrency,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  getRewardsPaymentAccount,
  getSession,
  getPriceQuote,
  getSelectedSeats,
  getSeatsTotalPricing,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  (
    fareDetails,
    reviewFlightDetailsProperties,
    rewardsPaymentFiat,
    creditCardPayment,
    paymentMethodRewardsAccount,
    rewardsPaymentCurrency,
    travelerIds,
    lapInfants,
    rewardsPaymentAccount,
    session,
    priceQuote,
    seats,
    seatsPricing,
    outgoingHackerFare,
    returnHackerFare
  ): CompleteBuyAirProperties | null => {
    if (!reviewFlightDetailsProperties) {
      return null;
    }

    return {
      success: false,
      total_rewards_amount_usd: rewardsPaymentFiat?.value || 0,
      total_card_amount_usd: creditCardPayment.value || 0,
      card_product_used: paymentMethodRewardsAccount?.productDisplayName || "",
      rewards_product_used: rewardsPaymentAccount?.productDisplayName || "",
      rewards_currency: rewardsPaymentCurrency?.currency || "",
      agent_book_fee_amount_usd: 0,
      agent_locator: "", // this is overwritten in the pollFinalizedSaga
      ...reviewFlightDetailsProperties,
      pax_total: travelerIds.length + lapInfants.length,
      booking_session_id: session?.value || "",
      seats_selection_available:
        priceQuote?.seatMap?.SeatMapResponse ===
        SeatMapResponseEnum.SeatMapAvailable,
      seats_selected_seats: seats?.length > 0,
      seats_selected_count: seats?.length,
      seats_selected_paid: seats?.filter((s) => s.price_object.total > 0)
        ?.length,
      seats_selected_free: seats?.filter((s) => s.price_object.total === 0)
        ?.length,
      seats_selected_price_usd: seatsPricing,
      lowest_price_seat: 0, // TODO add when cheapest seat work is in
      multi_ticket_type: fareDetails?.multiTicketType,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
    };
  }
);

export const firstPersonWithFrozenPriceSelector = createSelector(
  getPriceQuote,
  (priceQuote) => priceQuote?.creditSummary?.credits.creditsByPerson[0]
);

export const getCompleteBuyAirPriceFreezeProperties = createSelector(
  currentPriceFreezeSelector,
  getPriceQuote,
  firstPersonWithFrozenPriceSelector,
  (
    priceFreeze,
    priceQuote,
    firstPersonWithFrozenPrice
  ): CompleteBuyAirPriceFreezeProperties => {
    const priceFreezeShownOTMTotalUsd =
      priceFreeze?.frozenFare.totalPricing?.savingsAmount?.fiat?.value || 0;
    const savingsAmountInFiat =
      firstPersonWithFrozenPrice?.priceFreezeExerciseCredit?.creditAmount?.fiat
        ?.value || 0;
    const offerPerPaxCapInFiat =
      priceQuote?.creditSummary?.credits.totalCredits.priceFreezeExerciseCredit
        ?.offerPerPaxCap?.fiat?.value || 0;
    const isFareOverCap = savingsAmountInFiat >= offerPerPaxCapInFiat;
    const paxPricings = priceFreeze?.frozenFare?.paxPricings;
    const totalTravelers = getTotalPassengerFromPaxPricing(paxPricings || []);

    return {
      price_freeze_flow: true,
      price_freeze_id: priceFreeze?.priceFreeze?.id || "",
      price_freeze_shown_OTM_total_usd: priceFreezeShownOTMTotalUsd,
      price_freeze_cap_hit: isFareOverCap,
      price_freeze_duration:
        priceFreeze?.priceFreeze.offer?.timeToLive?.inSeconds || 0,
      price_freeze_pax_total: totalTravelers,
      price_freeze_pax_adults: getTotalPassengerFromPaxPricing(
        paxPricings?.filter((pax) => pax.paxType === PassengerTypes.Adult) || []
      ),
      price_freeze_pax_children: getTotalPassengerFromPaxPricing(
        paxPricings?.filter((pax) => pax.paxType === PassengerTypes.Child) || []
      ),
      price_freeze_pax_lap_infants: getTotalPassengerFromPaxPricing(
        paxPricings?.filter(
          (pax) => pax.paxType === PassengerTypes.InfantInLap
        ) || []
      ),
      price_freeze_pax_seat_infants: getTotalPassengerFromPaxPricing(
        paxPricings?.filter(
          (pax) => pax.paxType === PassengerTypes.InfantInSeat
        ) || []
      ),
      price_freeze_entry: "PF_exercise", // TODO: Add "flight_search" option
      frozen_price_total_usd:
        priceFreeze?.frozenFare.totalPricing?.originalAmount.fiat.value || 0,
      price_freeze_total_cost:
        priceQuote?.itinerary.sellingPricing.totalPricing.total.fiat.value || 0,
      price_freeze_otm_cap_usd:
        priceFreeze?.priceFreeze.offer?.cap.value.amount || 0,
      price_freeze_cost_per_pax:
        priceQuote?.itinerary.sellingPricing.pricingByPassenger.find(
          (pricing) =>
            pricing.person.id === firstPersonWithFrozenPrice?.personId
        )?.total.fiat.value || 0,
    };
  }
);

// note: price freeze only selectors
export const priceFreezePriceQuoteParamsSelector = createSelector(
  selectedTripSelector,
  priceFreezeOfferSelector,
  passengerCountSelector,
  getContactEmail,
  (
    selectedTrip,
    offer,
    counts,
    email
  ): PriceFreezeSchedulePriceQuoteRequest => {
    const fareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    const params: PriceFreezeSchedulePriceQuoteRequest = {
      offerId: offer?.id || "",
      itinerary: {
        tripId: selectedTrip.tripId || "",
        fareId: fareId || "",
        Itinerary: PriceFreezeItineraryEnum.SingleItinerary,
      },
      passengers: counts,
      emailAddress: email || "",
      // TODO: phone number is hard coded in the FE for now; see https://hopchat.slack.com/archives/C02FB9LK0G7/p1633102094087400;
      // remove it once the BE is ready
      phoneNumber: "+14165550134",
    };
    return params;
  }
);
