import { useI18nContext } from "@hopper-b2b/i18n";
import { Tooltip } from "../../Tooltip";

interface IMixedCabinHalfsheetProps {
  showDivider?: boolean;
}

const MixedCabinHalfsheet = ({ showDivider }: IMixedCabinHalfsheetProps) => {
  const { t } = useI18nContext();
  return (
    <Tooltip
      title={t("mixedCabin.icon")}
      description={t("mixedCabin.tooltip")}
    />
  );
};

export default MixedCabinHalfsheet;
