import {
  ProductOpaqueValue,
  PaymentOpaqueValue,
  PaymentId,
  ProductId,
} from "@b2bportal/purchase-api";

export enum CartUpdateEventType {
  ADD_PRODUCTS = "ADD_PRODUCTS",
  REMOVE_PRODUCTS = "REMOVE_PRODUCTS",
  ADD_PAYMENTS = "ADD_PAYMENTS",
  REMOVE_PAYMENTS = "REMOVE_PAYMENTS",
  CLEAR_CART_UPDATE = "CLEAR_CART_UPDATE",
  CLEAR_CART_UPDATE_ERROR = "CLEAR_CART_UPDATE_ERROR",
  RETRY_QUOTE = "RETRY_QUOTE",
  SET_ERROR = "SET_ERROR",
}

export interface AddProductsEvent {
  type: CartUpdateEventType.ADD_PRODUCTS;
  products: Array<ProductOpaqueValue>;
}

export interface RemoveProductsEvent {
  type: CartUpdateEventType.REMOVE_PRODUCTS;
  products: Array<ProductId>;
}

export interface AddPaymentsEvent {
  type: CartUpdateEventType.ADD_PAYMENTS;
  payments: Array<PaymentOpaqueValue>;
}

export interface RemovePaymentsEvent {
  type: CartUpdateEventType.REMOVE_PAYMENTS;
  payments: Array<PaymentId>;
}

export interface ClearCartUpdate {
  type: CartUpdateEventType.CLEAR_CART_UPDATE;
}
export interface ClearCartUpdateError {
  type: CartUpdateEventType.CLEAR_CART_UPDATE;
}

export interface RetryQuote {
  type: CartUpdateEventType.RETRY_QUOTE;
}

export interface SetCartUpdateError {
  type: CartUpdateEventType.SET_ERROR;
  error?: unknown;
}

export type CartUpdateEvents =
  | AddProductsEvent
  | RemoveProductsEvent
  | AddPaymentsEvent
  | RemovePaymentsEvent
  | ClearCartUpdate
  | SetCartUpdateError
  | ClearCartUpdateError
  | RetryQuote;
