import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import {
  getSelectedPaymentMethodId,
  getPaymentMethodRewardsAccountId,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";

import { deletePaymentMethod } from "../../../../api/v1/book/payment-methods/deletePaymentMethod";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* deletePaymentMethodSaga({
  paymentId,
}: actions.IDeletePaymentMethod) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    yield deletePaymentMethod({ paymentId }, apiConfig);
    yield put(actions.deletePaymentMethodCallStateSuccess());

    const selectedPaymentMethodId = getSelectedPaymentMethodId(state);
    const paymentMethodRewardsAccountId =
      getPaymentMethodRewardsAccountId(state);
    if (selectedPaymentMethodId === paymentId) {
      yield put(
        actions.setSelectedPaymentMethodId({
          paymentMethodId: undefined,
          accountId: paymentMethodRewardsAccountId,
        })
      );
    }

    yield put(actions.listPaymentMethods());
  } catch (e) {
    yield put(actions.deletePaymentMethodCallStateFailed());
  }
}
