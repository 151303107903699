// NuCloseButton to use: window.NuCloseButton.postMessage("") (closes webview)
export const closeNubankWebview = () => {
  window?.NuCloseButton?.postMessage?.("");
};

// NuCopyText to use: window.NuCopyText.postMessage("text to be copied")
export const copyTextFromWebview = (text: string) => {
  window?.NuCopyText?.postMessage?.(text);
};

// NuOpenHelp to use: window.NuOpenHelp.postMessage("")  (opens faq on the app side)
export const openNubankHelp = () => {
  window?.NuOpenHelp?.postMessage?.("");
};

// NuOpenTermsAndConditions to use: window.NuOpenTermsAndConditions.postMessage("")
export const openNubankTermsAndConditions = () => {
  window?.NuOpenTermsAndConditions?.postMessage?.("");
};
