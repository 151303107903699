import { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { DateRangePicker } from "@hopper-b2b/ui";
import { ClientContext } from "../../../../App";
import { MonthAndDatePickerConnectorProps } from "./container";
import "./styles.scss";

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}

export interface IMonthAndDatePickerProps
  extends MonthAndDatePickerConnectorProps {
  viewType: MonthAndDatePickerType;
  header?: JSX.Element | string;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
  isMissingDate?: boolean;
}

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const {
    pickerType,
    startDate,
    endDate,
    months,
    priceTags,
    currency,
    setStartDate,
    setEndDate,
    viewType,
    header,
    focusedMonthIndex,
    setFocusedMonthIndex,
    className,
    isMissingDate = false,
  } = props;

  const clientContext = useContext(ClientContext);

  return (
    <>
      {header && (
        <Box className="month-and-date-picker-header-container">
          <Typography variant="body2">{header}</Typography>
        </Box>
      )}
      <DateRangePicker
        className={className}
        minAllowedDate={dayjs().toDate()}
        maxAllowedDate={dayjs().add(1, "year").toDate()}
        months={months}
        priceTags={priceTags}
        currency={currency ?? ""}
        startDate={startDate}
        endDate={endDate}
        assets={clientContext?.assets || undefined}
        isMissingDate={isMissingDate}
        {...(viewType === MonthAndDatePickerType.Horizontal ||
        viewType === MonthAndDatePickerType.Column
          ? { setStartDate, setEndDate, pickerType }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Column
          ? { focusedMonthIndex, columnView: true }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Group
          ? { setFocusedMonthIndex, groupView: true }
          : undefined)}
      />
    </>
  );
};
