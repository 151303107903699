import { IApiConfig } from "@hopper-b2b/types";

export const PATH_HOME = "/";

export const ROUTE_FLIGHTS = "flights/*";
export const PATH_FLIGHTS = "flights/";
export const PATH_FLIGHTS_BOOK = PATH_FLIGHTS + "book/";
export const PATH_FLIGHTS_FREEZE = PATH_FLIGHTS + "freeze/";
export const PATH_FLIGHTS_FREEZE_PURCHASE = PATH_FLIGHTS_FREEZE + "purchase/";
export const PATH_FLIGHTS_FREEZE_OVERVIEW = PATH_FLIGHTS_FREEZE + "overview/";
export const PATH_FLIGHTS_SEARCH = PATH_FLIGHTS + "search/";
export const PATH_FLIGHTS_SHOP = PATH_FLIGHTS + "shop/";
export const PATH_FLIGHTS_BOOK_CONFIRMATION = PATH_FLIGHTS_BOOK + "confirm/";
export const ROUTE_HOTELS = PATH_HOME + "hotels/*";
export const PATH_HOTELS = PATH_HOME + "hotels/";
export const PATH_HOTELS_AVAILABILITY = PATH_HOTELS + "availability/";
export const PATH_HOTELS_SHOP = PATH_HOTELS + "shop/";
export const PATH_HOTELS_BOOK = PATH_HOTELS + "book/";
export const PATH_HOTELS_SEARCH = PATH_HOTELS + "search/";
export const PATH_HOTELS_BOOK_CONFIRMATION = PATH_HOTELS_BOOK + "confirm/";
export const PATH_CARS = PATH_HOME + "cars/";
export const PATH_CARS_SEARCH = PATH_CARS + "search/";
export const PATH_CARS_SHOP = PATH_CARS + "shop/";
export const PATH_CARS_AVAILABILITY = PATH_CARS + "availability/";
export const PATH_CARS_BOOK = `${PATH_CARS}book/`;
export const PATH_CARS_BOOK_CONFIRMATION = PATH_CARS_BOOK + "confirm/";
export const PATH_TRIPS = PATH_HOME + "trips/";
export const PATH_TERMS = PATH_HOME + "terms/";
export const PRICE_DROP_PROTECTION = PATH_TERMS + "price-drop-protection/";
export const BEST_PRICE_GUARANTEE = PATH_TERMS + "best-price-guarantee/";
export const PRICE_FREEZE = PATH_TERMS + "frozen-prices/";
export const PRICE_PREDICTION_PRICE_WATCH =
  PATH_TERMS + "price-prediction-price-watch/";
export const PATH_SITE_TERMS_OF_USE = PATH_HOME + "site-terms-of-use/";
export const ROUTE_AUTH = `auth/*`;
export const PATH_AUTH = `${PATH_HOME}auth/`;
export const PATH_AUTH_FTUX = `${PATH_AUTH}ftux/`;
export const PATH_AUTH_START_SESSION = `startsession/*`;
export const ROUTE_AUTH_START_SESSION = `${PATH_AUTH}startsession/`;
export const ROUTE_AUTH_INVALID_SESSION = `invalidsession`;
export const PATH_AUTH_INVALID_SESSION = `${PATH_AUTH}invalidsession/`;
export const ROUTE_AUTH_LOGOUT = `logout/*`;
export const PATH_AUTH_LOGOUT = `${PATH_AUTH}logout/`;
export const PATH_LUXURY_HOTELS = PATH_HOME + "luxury-hotels/";
export const PATH_LUXURY_HOTELS_SEARCH = PATH_LUXURY_HOTELS + "search/";
export const PATH_EXCHANGE = `${PATH_FLIGHTS}exchange`;
// Landing for bad path
export const PATH_LANDING = PATH_HOME;

export const PATH_GEN_AUTH = `/api/v0/auth/`;
export const PATH_GEN_AUTH_START_SESSION = `${PATH_GEN_AUTH}startSession`;

export const HIDDEN_FOOTER_PATHS = [
  `${PATH_HOTELS}availability/`,
  PATH_FLIGHTS_SEARCH,
  PATH_FLIGHTS_FREEZE_PURCHASE,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_FLIGHTS_SHOP,
  PATH_FLIGHTS_BOOK,
  PATH_TRIPS,
  PATH_EXCHANGE,
  PATH_HOTELS_SEARCH,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_BOOK,
  PATH_HOME,
];

export const HIDDEN_FOOTER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const NO_FOOTER_PADDING_PATHS: string[] = [];

export const NO_FOOTER_PADDING_UNPROTECTED_PATHS: string[] = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const HIDDEN_HEADER_PATHS = [
  PATH_FLIGHTS_SEARCH,
  PATH_FLIGHTS_SHOP,
  PATH_FLIGHTS_BOOK,
  PATH_EXCHANGE,
  PATH_FLIGHTS_FREEZE_PURCHASE,
  PATH_FLIGHTS_FREEZE,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_BOOK,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_BOOK,
  PATH_CARS_SHOP,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_EXCHANGE,
  PATH_TRIPS,
];
export const HIDDEN_HEADER_PATHS_MOBILE = ["*"];
export const DISPLAY_HOMEPAGE_TAKEOVER_PATHS = [
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_CARS,
  PATH_HOME,
];

export const HIDDEN_FOOTER_SEARCH_MOBILE = [
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_CARS_SEARCH,
  PATH_EXCHANGE,
  PATH_LUXURY_HOTELS_SEARCH,
  PATH_TRIPS,
  PATH_FLIGHTS_BOOK,
];

export const UNPROTECTED_PATHS = [
  PATH_AUTH,
  // PATH_TERMS,
  // PATH_TERMS_OF_SERVICE,
  // PATH_SITE_TERMS_OF_USE,
  // PRICE_DROP_PROTECTION,
  // BEST_PRICE_GUARANTEE,
  // PRICE_PREDICTION_PRICE_WATCH,
  // PRICE_FREEZE,
];

export const HIDDEN_HEADER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const HIDDEN_BANNER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
  ...UNPROTECTED_PATHS,
];

export const POSITION_FIXED_FOOTER_PATHS: string[] = [];

export const apiVersionPrefix = "/api/v0";

export const fetchUserInfoPath = `${apiVersionPrefix}/user/getInfo`;
export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;

export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;
export const takeoversApiPrefix = `${apiVersionPrefix}/takeovers`;

export const apiConfig: IApiConfig = {
  experimentsApiPrefix,
  rewardsApiPrefix,
  takeoversApiPrefix,
  analyticsApiPrefix,
  fetchUserInfoPath,
};
