import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItineraryResponse,
} from "@b2bportal/air-booking-api";
import {
  AirCfarExerciseFailureEnum,
  AirCfarExercisePollFulfillResponse,
  AirCfarExerciseQuoteRequest,
  AirCfarExerciseScheduleFulfillResponse,
  CipherText,
  PollFulfillAirCfarExerciseQuoteResponse,
  QuoteSuccess,
  ScheduleAirCfarExerciseQuoteRequest,
  ScheduleAirCfarExerciseQuoteResponseSuccess,
  ScheduleFulfillAirCfarExerciseQuoteRequest,
  ScheduleFulfillAirCfarExerciseQuoteResponse,
  ScheduleFulfillSuccess,
} from "@b2bportal/air-cfar-api";
import {
  FlightKey,
  GetWatchAlertDetailsResponse,
  WatchAlertView,
  WatchAlertViews,
} from "@b2bportal/air-price-watch-api";
import { Slice } from "@b2bportal/air-shopping-api";
import { HotelItinerary, HotelItineraryResponse } from "@b2bportal/lodging-api";
import {
  CallState,
  CarItineraryResponse,
  CarReservation,
  CfarExerciseProgress,
  ChfarExerciseProgress,
  CurrentPriceFreezesEnum,
  DenyScheduleChangeRequest,
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionRebookFailureReason,
  FlightItineraryRequest,
  HotelItineraryRequest,
  IOpenModal,
  ItineraryEnum,
  MyTripsFilter,
  PriceFreezeView,
  TripSummaryContext,
} from "@hopper-b2b/types";
import * as H from "history";
import { IPriceFreezeResponse } from "../sagas/fetchPriceFreezeSaga";
import * as actionTypes from "./constants";
import {
  ITrackingProperties,
  ITrackingPropertiesRequest,
} from "@hopper-b2b/utilities";

export interface ITripQueryParams {
  cfarExerciseProgress?: string;
  chfarExerciseProgress?: string;
  /**
   * Allows deep-linking to a specific trip's "Contact Support" modal.
   * Must be used with the `tripId` query param
   */
  contactSupport?: string;
  tripId?: string;
  tripsFilter?: string;
}

export interface IPopulateTripQueryParams {
  type: actionTypes.POPULATE_TRIP_QUERY_PARAMS;
  history: H.History;
  queryParams?: ITripQueryParams;
}

export const populateTripQueryParams = (
  history: H.History,
  queryParams?: ITripQueryParams
): IPopulateTripQueryParams => ({
  type: actionTypes.POPULATE_TRIP_QUERY_PARAMS,
  history,
  queryParams,
});

export interface IReplaceTripQueryParams {
  type: actionTypes.REPLACE_TRIP_QUERY_PARAMS;
  history: H.History;
  queryParams?: ITripQueryParams;
}

export const replaceTripQueryParams = (
  history: H.History,
  queryParams?: ITripQueryParams
): IReplaceTripQueryParams => ({
  type: actionTypes.REPLACE_TRIP_QUERY_PARAMS,
  history,
  queryParams,
});

export interface ISetTripsFilter {
  type: actionTypes.SET_MY_TRIPS_FILTER;
  tripsFilter: MyTripsFilter;
}

export const setTripsFilter = (
  tripsFilter: MyTripsFilter
): ISetTripsFilter => ({
  type: actionTypes.SET_MY_TRIPS_FILTER,
  tripsFilter,
});

export interface IFetchFlights {
  type: actionTypes.FETCH_FLIGHTS;
  request: FlightItineraryRequest;
  history: H.History;
}

export const fetchFlights = (
  request: FlightItineraryRequest,
  history: H.History
): IFetchFlights => ({
  type: actionTypes.FETCH_FLIGHTS,
  request,
  history,
});

export interface IFetchFlightsFailed {
  type: actionTypes.FETCH_FLIGHTS_FAILED;
}

export const fetchFlightsFailed = (): IFetchFlightsFailed => ({
  type: actionTypes.FETCH_FLIGHTS_FAILED,
});

export interface ISetFlightContext {
  type: actionTypes.SET_FLIGHT_CONTEXT;
  context: {
    airports: { [key: string]: Airport };
    airlines: { [key: string]: Airline };
  };
}

export const setFlightContext = (
  context: ISetFlightContext["context"]
): ISetFlightContext => ({
  type: actionTypes.SET_FLIGHT_CONTEXT,
  context,
});

export interface ISetFlights {
  type: actionTypes.SET_FLIGHTS;
  response: FlightItineraryResponse;
}

export const setFlights = (response: FlightItineraryResponse): ISetFlights => ({
  type: actionTypes.SET_FLIGHTS,
  response,
});

export interface IFetchHotels {
  type: actionTypes.FETCH_HOTELS;
  request: HotelItineraryRequest;
  history: H.History;
}

export const fetchHotels = (
  request: HotelItineraryRequest,
  history: H.History
): IFetchHotels => ({
  type: actionTypes.FETCH_HOTELS,
  request,
  history,
});

export interface IFetchHotelsFailed {
  type: actionTypes.FETCH_HOTELS_FAILED;
}

export const fetchHotelsFailed = (): IFetchHotelsFailed => ({
  type: actionTypes.FETCH_HOTELS_FAILED,
});

export interface ISetHotels {
  type: actionTypes.SET_HOTELS;
  response: HotelItineraryResponse;
}

export const setHotels = (response: HotelItineraryResponse): ISetHotels => ({
  type: actionTypes.SET_HOTELS,
  response,
});

export interface ISetSelectedHotel {
  type: actionTypes.SET_SELECTED_HOTEL;
  selectedHotel: HotelItinerary | null;
}

export const setSelectedHotel = (
  selectedHotel: HotelItinerary | null
): ISetSelectedHotel => ({
  type: actionTypes.SET_SELECTED_HOTEL,
  selectedHotel,
});

export interface IFetchCars {
  type: actionTypes.FETCH_CARS;
  request: unknown;
  history: H.History;
}

export const fetchCars = (
  request: unknown,
  history: H.History
): IFetchCars => ({
  type: actionTypes.FETCH_CARS,
  request,
  history,
});

export interface IFetchCarsFailed {
  type: actionTypes.FETCH_CARS_FAILED;
}

export const fetchCarsFailed = (): IFetchCarsFailed => ({
  type: actionTypes.FETCH_CARS_FAILED,
});

export interface ISetCars {
  type: actionTypes.SET_CARS;
  response: CarItineraryResponse;
}

export const setCars = (response: CarItineraryResponse): ISetCars => ({
  type: actionTypes.SET_CARS,
  response,
});

export interface ISetSelectedCar {
  type: actionTypes.SET_SELECTED_CAR;
  selectedCar: CarReservation | null;
}

export const setSelectedCar = (
  selectedCar: CarReservation | null
): ISetSelectedCar => ({
  type: actionTypes.SET_SELECTED_CAR,
  selectedCar,
});

export interface IUpdateWatch {
  type: actionTypes.UPDATE_WATCH;
  alertKey: FlightKey;
  email: string;
}

export const updateWatch = (
  alertKey: FlightKey,
  email: string
): IUpdateWatch => ({
  type: actionTypes.UPDATE_WATCH,
  alertKey,
  email,
});

export interface ISetUpdateWatchCallState {
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setUpdateWatchCallState = (
  callState: CallState
): ISetUpdateWatchCallState => ({
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE,
  callState,
});

export interface IListWatches {
  type: actionTypes.LIST_WATCHES;
  history: H.History;
}

export const listWatches = (history: H.History): IListWatches => ({
  type: actionTypes.LIST_WATCHES,
  history,
});

export interface IListPriceFreeze {
  type: actionTypes.LIST_PRICE_FREEZE;
  requestType: CurrentPriceFreezesEnum;
}

export const listPriceFreeze = (): IListPriceFreeze => ({
  type: actionTypes.LIST_PRICE_FREEZE,
  requestType: CurrentPriceFreezesEnum.CurrentPriceFreezes,
});

export interface ISetIListPriceFreezeCallState {
  type: actionTypes.SET_LIST_PRICE_FREEZE_CALL_STATE;
  callState: CallState;
}

export const setListPriceFreezeCallState = (
  callState: CallState
): ISetIListPriceFreezeCallState => ({
  type: actionTypes.SET_LIST_PRICE_FREEZE_CALL_STATE,
  callState,
});

export interface ISetPriceFreeze {
  type: actionTypes.SET_PRICE_FREEZE;
  priceFreezes: Array<PriceFreezeView>;
  tripContext: TripSummaryContext;
}

export const setPriceFreeze = ({
  priceFreezes,
  tripContext,
}: IPriceFreezeResponse): ISetPriceFreeze => ({
  type: actionTypes.SET_PRICE_FREEZE,
  priceFreezes,
  tripContext,
});

export interface ISetListWatchesCallState {
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE;
  callState: CallState;
}

export const setListWatchesCallState = (
  callState: CallState
): ISetListWatchesCallState => ({
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE,
  callState,
});

export interface ISetWatches {
  type: actionTypes.SET_WATCHES;
  watches: WatchAlertViews;
}

export const setWatches = (watches: WatchAlertViews): ISetWatches => ({
  type: actionTypes.SET_WATCHES,
  watches,
});

export interface ISetSelectedFlight {
  type: actionTypes.SET_SELECTED_FLIGHT;
  selectedFlight: BookedFlightItineraryWithDepartureTime | null;
}

export const setSelectedFlight = (
  selectedFlight: BookedFlightItineraryWithDepartureTime | null
): ISetSelectedFlight => ({
  type: actionTypes.SET_SELECTED_FLIGHT,
  selectedFlight,
});

export interface ISetOpenModal {
  type: actionTypes.SET_OPEN_MODAL;
  openModal: IOpenModal;
}

export const setOpenModal = (openModal: IOpenModal): ISetOpenModal => ({
  type: actionTypes.SET_OPEN_MODAL,
  openModal,
});

export interface IDeleteWatch {
  type: actionTypes.DELETE_WATCH;
  watch: WatchAlertView;
}

export const deleteWatch = (watch: WatchAlertView): IDeleteWatch => ({
  type: actionTypes.DELETE_WATCH,
  watch,
});

export interface ICreateWatch {
  type: actionTypes.CREATE_WATCH;
  email: string;
  history?: H.History;
}

export const createWatch = (
  email: string,
  history?: H.History
): ICreateWatch => ({
  type: actionTypes.CREATE_WATCH,
  email,
  history,
});

export interface ISetCreateWatchCallState {
  type: actionTypes.SET_CREATE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setCreateWatchCallState = (
  callState: CallState
): ISetCreateWatchCallState => ({
  type: actionTypes.SET_CREATE_WATCH_CALL_STATE,
  callState,
});

export interface ISetDeleteWatchCallState {
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setDeleteWatchCallState = (
  callState: CallState
): ISetDeleteWatchCallState => ({
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE,
  callState,
});

export interface IAcceptScheduleChange {
  type: actionTypes.ACCEPT_SCHEDULE_CHANGE;
  itineraryId: string;
  history: H.History;
}

export const acceptScheduleChange = (
  history: H.History,
  itineraryId: string
): IAcceptScheduleChange => ({
  type: actionTypes.ACCEPT_SCHEDULE_CHANGE,
  itineraryId,
  history,
});

export interface ISetAcceptScheduleChangeCallState {
  type: actionTypes.SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE;
  callState: CallState;
}

export const setAcceptScheduleChangeCallState = (
  callState: CallState
): ISetAcceptScheduleChangeCallState => ({
  type: actionTypes.SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE,
  callState,
});

export interface IDenyScheduleChange {
  type: actionTypes.DENY_SCHEDULE_CHANGE;
  request: DenyScheduleChangeRequest;
  history: H.History;
}

export const denyScheduleChange = (
  req: DenyScheduleChangeRequest,
  history: H.History
): IDenyScheduleChange => ({
  type: actionTypes.DENY_SCHEDULE_CHANGE,
  request: req,
  history,
});

export interface ISetDenyScheduleChangeCallState {
  type: actionTypes.SET_DENY_SCHEDULE_CHANGE_CALL_STATE;
  callState: CallState;
}

export const setDenyScheduleChangeCallState = (
  callState: CallState
): ISetDenyScheduleChangeCallState => ({
  type: actionTypes.SET_DENY_SCHEDULE_CHANGE_CALL_STATE,
  callState,
});

export interface ISetCfarExerciseProgress {
  type: actionTypes.SET_CFAR_EXERCISE_PROGRESS;
  cfarExerciseProgress: CfarExerciseProgress;
}

export const setCfarExerciseProgress = (
  cfarExerciseProgress: CfarExerciseProgress
): ISetCfarExerciseProgress => ({
  type: actionTypes.SET_CFAR_EXERCISE_PROGRESS,
  cfarExerciseProgress,
});

export interface ISetChfarExerciseProgress {
  type: actionTypes.SET_CHFAR_EXERCISE_PROGRESS;
  chfarExerciseProgress: ChfarExerciseProgress;
}

export const setChfarExerciseProgress = (
  chfarExerciseProgress: ChfarExerciseProgress
): ISetChfarExerciseProgress => ({
  type: actionTypes.SET_CHFAR_EXERCISE_PROGRESS,
  chfarExerciseProgress,
});

export interface ISetDisruptionExerciseProgress {
  type: actionTypes.SET_DISRUPTION_EXERCISE_PROGRESS;
  disruptionExerciseProgress: DisruptionExerciseProgress;
}

export const setDisruptionExerciseProgress = (
  disruptionExerciseProgress: DisruptionExerciseProgress
): ISetDisruptionExerciseProgress => ({
  type: actionTypes.SET_DISRUPTION_EXERCISE_PROGRESS,
  disruptionExerciseProgress,
});

export interface ISetDisruptionExerciseProductType {
  type: actionTypes.SET_DISRUPTION_EXERCISE_PRODUCT_TYPE;
  disruptionExerciseProductType: DisruptionExerciseProductType;
}

export const setDisruptionExerciseProductType = (
  disruptionExerciseProductType: DisruptionExerciseProductType
): ISetDisruptionExerciseProductType => ({
  type: actionTypes.SET_DISRUPTION_EXERCISE_PRODUCT_TYPE,
  disruptionExerciseProductType,
});

export interface IFetchFlightCfarCancellationQuote {
  type: actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE;
  itineraryId: string;
  contractId: string;
}

export const fetchFlightCfarCancellationQuote = (
  args: ScheduleAirCfarExerciseQuoteRequest
): IFetchFlightCfarCancellationQuote => ({
  type: actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE,
  ...args,
});

export interface ISetFlightCfarCancellationQuote {
  type: actionTypes.SET_FLIGHT_CFAR_CANCELLATION_QUOTE;
  response: ScheduleAirCfarExerciseQuoteResponseSuccess;
}

export const setFlightCfarCancellationQuote = (args: {
  response: ScheduleAirCfarExerciseQuoteResponseSuccess;
}): ISetFlightCfarCancellationQuote => ({
  type: actionTypes.SET_FLIGHT_CFAR_CANCELLATION_QUOTE,
  ...args,
});

export interface ISetFlightCfarCancellationQuoteV1 {
  type: actionTypes.SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1;
  response: QuoteSuccess;
}

export const setFlightCfarCancellationQuoteV1 = (args: {
  response: QuoteSuccess;
}): ISetFlightCfarCancellationQuoteV1 => ({
  type: actionTypes.SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1,
  ...args,
});

export interface ISetFetchFlightCfarCancellationQuoteCallStateFailed {
  type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED;
}

export const setFetchFlightCfarCancellationQuoteCallStateFailed =
  (): ISetFetchFlightCfarCancellationQuoteCallStateFailed => ({
    type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED,
  });

export interface ISetFetchFlightCfarCancellationQuoteCallStateNotCalled {
  type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_NOT_CALLED;
}

export const setFetchFlightCfarCancellationQuoteCallStateNotCalled =
  (): ISetFetchFlightCfarCancellationQuoteCallStateNotCalled => ({
    type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_NOT_CALLED,
  });

export interface IConfirmFlightCfarCancellation {
  type: actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION;
  quoteToken: CipherText;
}

export const confirmFlightCfarCancellation = (
  args: ScheduleFulfillAirCfarExerciseQuoteRequest
): IConfirmFlightCfarCancellation => ({
  type: actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION,
  ...args,
});

export interface ISetConfirmFlightCfarCancellationResponse {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE;
  response: ScheduleFulfillAirCfarExerciseQuoteResponse;
}

export const setConfirmFlightCfarCancellationResponse = (args: {
  response: ScheduleFulfillAirCfarExerciseQuoteResponse;
}): ISetConfirmFlightCfarCancellationResponse => ({
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE,
  ...args,
});

export interface ISetConfirmFlightCfarCancellationV1Response {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE;
  response: AirCfarExerciseScheduleFulfillResponse;
}

export const setConfirmFlightCfarCancellationV1Response = (args: {
  response: AirCfarExerciseScheduleFulfillResponse;
}): ISetConfirmFlightCfarCancellationV1Response => ({
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE,
  ...args,
});

export interface ISetConfirmFlightCfarCancellationCallStateFailed {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED;
}

export const setConfirmFlightCfarCancellationCallStateFailed =
  (): ISetConfirmFlightCfarCancellationCallStateFailed => ({
    type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED,
  });

export interface ISetConfirmFlightCfarCancellationError {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_ERROR;
  cfarCancellationConfirmError: AirCfarExerciseFailureEnum;
}

export const setConfirmFlightCfarCancellationError =
  (): ISetConfirmFlightCfarCancellationError => ({
    type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_ERROR,
    cfarCancellationConfirmError: AirCfarExerciseFailureEnum.Error,
  });

export interface ISetConfirmFlightCfarCancellationCallStateNotCalled {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED;
}

export const setConfirmFlightCfarCancellationCallStateNotCalled =
  (): ISetConfirmFlightCfarCancellationCallStateNotCalled => ({
    type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED,
  });

export interface ISetPollFulfillFlightCfarCancellationResponse {
  type: actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE;
  response: PollFulfillAirCfarExerciseQuoteResponse;
}

export const setPollFulfillFlightCfarCancellationResponse = (args: {
  response: PollFulfillAirCfarExerciseQuoteResponse;
}): ISetPollFulfillFlightCfarCancellationResponse => ({
  type: actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE,
  ...args,
});

export interface ISetPollFulfillFlightCfarCancellationV1Response {
  type: actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE;
  response: AirCfarExercisePollFulfillResponse;
}

export const setPollFulfillFlightCfarCancellationV1Response = (args: {
  response: AirCfarExercisePollFulfillResponse;
}): ISetPollFulfillFlightCfarCancellationV1Response => ({
  type: actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE,
  ...args,
});

export interface ISetPollFulfillFlightCfarCancellationV1Error {
  type: actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_ERROR;
  cfarPollFulfillError: AirCfarExerciseFailureEnum;
}

export const setPollFulfillFlightCfarCancellationV1Error =
  (): ISetPollFulfillFlightCfarCancellationV1Error => ({
    type: actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_ERROR,
    cfarPollFulfillError: AirCfarExerciseFailureEnum.Error,
  });

export interface IFetchFlightCfarCancellationQuoteV1 {
  type: actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1;
  itineraryId: string;
  selectedFlight: BookedFlightItineraryWithDepartureTime;
}

export const fetchFlightCfarCancellationQuoteV1 = (
  args: AirCfarExerciseQuoteRequest,
  selectedFlight: BookedFlightItineraryWithDepartureTime
): IFetchFlightCfarCancellationQuoteV1 => ({
  type: actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1,
  ...args,
  selectedFlight,
});

export interface IConfirmFlightCfarCancellationV1 {
  type: actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION_V1;
  quoteToken: CipherText;
}

export const confirmFlightCfarCancellationV1 = (
  args: ScheduleFulfillAirCfarExerciseQuoteRequest
): IConfirmFlightCfarCancellationV1 => ({
  type: actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION_V1,
  ...args,
});

export interface IResetCfarExerciseState {
  type: actionTypes.RESET_CFAR_EXERCISE_STATE;
}

export const resetCfarExerciseState = (): IResetCfarExerciseState => ({
  type: actionTypes.RESET_CFAR_EXERCISE_STATE,
});

export interface IResetChfarExerciseState {
  type: actionTypes.RESET_CHFAR_EXERCISE_STATE;
}

export const resetChfarExerciseState = (): IResetChfarExerciseState => ({
  type: actionTypes.RESET_CHFAR_EXERCISE_STATE,
});

export interface IResetDisruptionExerciseState {
  type: actionTypes.RESET_DISRUPTION_EXERCISE_STATE;
}

export const resetDisruptionExerciseState =
  (): IResetDisruptionExerciseState => ({
    type: actionTypes.RESET_DISRUPTION_EXERCISE_STATE,
  });

export interface IDiscardFlightCfarCancellationQuote {
  type: actionTypes.DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE;
}

export const discardFlightCfarCancellationQuote =
  (): IDiscardFlightCfarCancellationQuote => ({
    type: actionTypes.DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE,
  });

export interface IGetWatchDetails {
  type: actionTypes.GET_WATCH_DETAILS;
  watch: WatchAlertView;
  history: H.History;
}

export const getDetailsWatch = (
  watch: WatchAlertView,
  history: H.History
): IGetWatchDetails => ({
  type: actionTypes.GET_WATCH_DETAILS,
  watch,
  history,
});

export interface ISetGetDetailsWatchCallState {
  type: actionTypes.SET_GET_WATCH_DETAILS_CALL_STATE;
  callState: CallState;
}

export const setGetDetailsWatchCallState = (
  callState: CallState
): ISetGetDetailsWatchCallState => ({
  type: actionTypes.SET_GET_WATCH_DETAILS_CALL_STATE,
  callState,
});

export interface ISetSelectedWatch {
  type: actionTypes.SET_SELECTED_WATCH;
  watch: WatchAlertView | null;
}

export const setSelectedWatch = (
  watch: WatchAlertView | null
): ISetSelectedWatch => ({
  type: actionTypes.SET_SELECTED_WATCH,
  watch,
});

export interface ISetCancellationCompleted {
  type: actionTypes.SET_CANCELLATION_COMPLETED;
  cancellationStatus: boolean;
}

export interface ISetSelectedWatchDetails {
  type: actionTypes.SET_SELECTED_WATCH_DETAILS;
  watchDetails: GetWatchAlertDetailsResponse | null;
}

export const setSelectedWatchDetails = (
  watchDetails: GetWatchAlertDetailsResponse | null
): ISetSelectedWatchDetails => ({
  type: actionTypes.SET_SELECTED_WATCH_DETAILS,
  watchDetails,
});

export const setCancellationCompleted = (
  cancellationStatus: boolean
): ISetCancellationCompleted => ({
  type: actionTypes.SET_CANCELLATION_COMPLETED,
  cancellationStatus,
});

export interface ISetDisruptionRebookSelectedFlight {
  type: actionTypes.SET_DISRUPTION_REBOOK_SELECTED_FLIGHT;
  disruptionRebookSelectedFlight: Slice | null;
}

export const setDisruptionRebookSelectedFlight = (
  disruptionRebookSelectedFlight: Slice | null
): ISetDisruptionRebookSelectedFlight => ({
  type: actionTypes.SET_DISRUPTION_REBOOK_SELECTED_FLIGHT,
  disruptionRebookSelectedFlight,
});

export interface ISetDisruptionFulfillError {
  type: actionTypes.SET_DISRUPTION_FULFILL_ERROR;
  disruptionFulfillError: DisruptionRebookFailureReason | null;
}

export const setDisruptionFulfillError = (
  disruptionFulfillError: DisruptionRebookFailureReason | null
): ISetDisruptionFulfillError => ({
  type: actionTypes.SET_DISRUPTION_FULFILL_ERROR,
  disruptionFulfillError,
});

export interface ISetTripSearchResults {
  type: actionTypes.SET_TRIP_SEARCH_RESULTS;
  tripSearchResults:
    | BookedFlightItineraryWithDepartureTime
    | HotelItinerary
    | null;
}

export const setTripSearchResults = (
  tripSearchResults:
    | BookedFlightItineraryWithDepartureTime
    | (HotelItinerary & { type: ItineraryEnum.Hotel })
    | null
): ISetTripSearchResults => ({
  type: actionTypes.SET_TRIP_SEARCH_RESULTS,
  tripSearchResults,
});

export interface ISetFetchFlightCfarCancellationQuoteError {
  type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR;
  cfarCancellationQuoteError: AirCfarExerciseFailureEnum;
}

export const setFetchFlightCfarCancellationQuoteError = (
  cfarCancellationQuoteError: AirCfarExerciseFailureEnum
): ISetFetchFlightCfarCancellationQuoteError => ({
  type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR,
  cfarCancellationQuoteError,
});

export interface ISetCfarExerciseQuoteTrackingProperties {
  type: actionTypes.SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES;
  properties: ITrackingPropertiesRequest;
}

export const setCfarExerciseQuoteTrackingProperties = (
  properties: ITrackingPropertiesRequest
): ISetCfarExerciseQuoteTrackingProperties => ({
  type: actionTypes.SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES,
  properties,
});

export type TripsListActions =
  | ISetTripsFilter
  | IFetchFlights
  | IFetchFlightsFailed
  | ISetFlightContext
  | ISetFlights
  | IUpdateWatch
  | ISetUpdateWatchCallState
  | IListPriceFreeze
  | ISetIListPriceFreezeCallState
  | ISetPriceFreeze
  | IListWatches
  | ISetListWatchesCallState
  | ISetWatches
  | ISetSelectedFlight
  | IFetchHotels
  | IFetchHotelsFailed
  | ISetHotels
  | ISetSelectedHotel
  | IFetchCars
  | IFetchCarsFailed
  | ISetCars
  | ISetSelectedCar
  | IDeleteWatch
  | ISetDeleteWatchCallState
  | ISetOpenModal
  | IAcceptScheduleChange
  | ISetAcceptScheduleChangeCallState
  | IDenyScheduleChange
  | ISetDenyScheduleChangeCallState
  | ISetCfarExerciseProgress
  | ISetChfarExerciseProgress
  | ISetDisruptionExerciseProgress
  | ISetDisruptionExerciseProductType
  | IFetchFlightCfarCancellationQuote
  | ISetFlightCfarCancellationQuote
  | ISetFetchFlightCfarCancellationQuoteCallStateFailed
  | ISetFetchFlightCfarCancellationQuoteCallStateNotCalled
  | IConfirmFlightCfarCancellation
  | ISetConfirmFlightCfarCancellationResponse
  | ISetConfirmFlightCfarCancellationCallStateFailed
  | ISetConfirmFlightCfarCancellationCallStateNotCalled
  | ISetConfirmFlightCfarCancellationError
  | ISetPollFulfillFlightCfarCancellationResponse
  | ISetPollFulfillFlightCfarCancellationV1Response
  | ISetPollFulfillFlightCfarCancellationV1Error
  | IResetCfarExerciseState
  | IResetChfarExerciseState
  | IFetchFlightCfarCancellationQuoteV1
  | IConfirmFlightCfarCancellationV1
  | ISetConfirmFlightCfarCancellationV1Response
  | ISetFlightCfarCancellationQuoteV1
  | IResetDisruptionExerciseState
  | IDiscardFlightCfarCancellationQuote
  | IGetWatchDetails
  | ISetGetDetailsWatchCallState
  | ISetSelectedWatch
  | ICreateWatch
  | ISetCreateWatchCallState
  | ISetCancellationCompleted
  | ISetSelectedWatchDetails
  | ISetDisruptionRebookSelectedFlight
  | ISetDisruptionFulfillError
  | ISetTripSearchResults
  | ISetFetchFlightCfarCancellationQuoteError
  | ISetCfarExerciseQuoteTrackingProperties;
