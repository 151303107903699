import styles from "./styles.module.scss";
interface FlightShopReviewMultiticketBannerProps {
  copy: string;
}

const FlightShopReviewMultiticketBanner = ({
  copy,
}: FlightShopReviewMultiticketBannerProps) => {
  return (
    <div className={styles.FlightShopReviewMultiticketBanner}>
      <p>{copy}</p>
    </div>
  );
};

export default FlightShopReviewMultiticketBanner;
