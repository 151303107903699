import { Product } from "@b2bportal/purchase-api";
import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  ChfarTrackingEvents,
  ChfarTrackingPageSelected,
  DisruptionProductType,
  DisruptionTrackingEvents,
  FintechProductType,
} from "@hopper-b2b/types";
import { LearnMore } from "@hopper-b2b/ui";
import { useCallback } from "react";

export const FintechLearnMore = ({
  product,
}: {
  product: Product | DisruptionProductType;
}) => {
  const { t } = useI18nContext();

  const onOpenCfarLearnMore = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.OFFER },
    });
  }, []);

  const onOpenChfarLearnMore = useCallback(() => {
    trackEvent({
      eventName: ChfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: ChfarTrackingPageSelected.OFFER },
    });
  }, []);

  const onOpenDisruptionLearnMore = useCallback(() => {
    trackEvent({
      eventName: DisruptionTrackingEvents.TAP_DELAYS_PROTECTION_LEARN_MORE,
      properties: undefined,
    });
  }, []);

  switch (product) {
    case Product.AirCfar:
      return (
        <LearnMore
          label={t("learnMore")}
          description={<I18nMarkup tKey={"cfarOffers.learnMoreDescription"} />}
          productType={FintechProductType.CfarPurchase}
          descriptionAdditional={t("cfarOffers.learnMoreDescription2")}
          header={t("cfarOffers.learnMoreHeader")}
          title={t("cfarOffers.learnMoreTitle")}
          className="cfar-learn-more"
          onOpen={onOpenCfarLearnMore}
        />
      );
    case Product.AirChfar:
      return (
        <LearnMore
          label={t("learnMore")}
          description={<I18nMarkup tKey={"chOffer.learnMoreDescription"} />}
          productType={FintechProductType.Chfar}
          descriptionAdditional={t("chOffer.learnMoreDescription2")}
          header={t("chOffer.learnMoreHeader")}
          title={t("chOffer.learnMoreTitle")}
          className="cfar-learn-more"
          onOpen={onOpenChfarLearnMore}
        />
      );
    case DisruptionProductType.ScheduleChange:
      return (
        <LearnMore
          label={t("learnMore")}
          description={<I18nMarkup tKey={"disruptionInfoModal.description"} />}
          productType={FintechProductType.Disruption}
          header={t("disruptionInfoModal.header")}
          title={t("disruptionInfoModal.title")}
          descriptionAdditional={t("disruptionInfoModal.descriptionAdditional")}
          className="disruption-learn-more"
          onOpen={onOpenDisruptionLearnMore}
        />
      );
    case DisruptionProductType.MissedConnection:
      return (
        <LearnMore
          label={t("learnMore")}
          description={
            <I18nMarkup tKey={"missedConnectionInfoPopup.moreInformation"} />
          }
          productType={FintechProductType.MissedConnection}
          header={t("missedConnectionInfoPopup.header")}
          title={t("disruptionInfoModal.title")}
          descriptionAdditional={t("descriptionAdditional")}
          className="disruption-learn-more"
        />
      );
    default:
      return null;
  }
};
