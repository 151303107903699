// Docs: https://developers.google.com/maps/documentation/maps-static/start
const MAP_PREFIX = "https://maps.googleapis.com/maps/api/staticmap";

export const STATIC_MAP_DARK_STYLE =
  "&style=element:geometry|color:0x212121&style=element:labels.icon|visibility:off&style=element:labels.text|color:0xd4d6dd&style=element:labels.text.fill|color:0xd4d6dd&style=feature:administrative|elements:geometry|color:0x757575&style=feature:administrative.country|elements:labels.text.fill|color:0x9e9e9e&style=feature:administrative.land_parcel|visibility:off&style=feature:administrative.land_parcel|elements:geometry.fill|color:0x233131&style=feature:administrative.locality|elements:labels.text.fill|color:0xbdbdbd&style=feature:landscape|elements:geometry.fill|color:0x2c2d2f&style=feature:landscape.natural|elements:geometry.fill|color:0x233132|saturation:-50,&style=feature:landscape.natural.terrain|elements:geometry.fill|color:0x233132&style=feature:poi|elements:labels.text.fill|color:0x757575&style=feature:poi.park|elements:geometry|color:0x181818&style=feature:poi.park|elements:geometry.fill|color:0x223534&style=feature:poi.park|elements:labels.text|color:0x6c9288&style=feature:road|elements:geometry.fill|color:0x2c2c2c&style=feature:road.arterial|elements:geometry|color:0x373737&style=feature:road.arterial|elements:geometry.fill|color:0x525659&style=feature:road.highway|elements:geometry|color:0x3c3c3c&style=feature:road.highway|elements:geometry.fill|color:0x575c62&style=feature:road.highway.controlled_access|elements:geometry|color:0x4e4e4e&style=feature:road.highway.controlled_access|elements:geometry.fill|color:0x6b727d&style=feature:road.local|elements:labels.text.fill|color:0x616161&style=feature:transit|elements:labels.text.fill|color:0x757575&style=feature:transit.station.airport|elements:geometry.fill|color:0x26292b&style=feature:transit.station.airport|elements:geometry.stroke|color:0x525659&style=feature:transit.station.airport|elements:labels.text.fill|color:0xd4d6dd&style=feature:water|elements:geometry|color:0x000000&style=feature:water|elements:geometry.fill|color:0x3c475d&style=element:labels.text.stroke|color:0x212121";

export const getGoogleStaticMapSrc = (
  coordinates: string,
  zoom: string,
  size: string,
  scale = "1",
  showMarker = false,
  markerColor = "red",
  style = ""
) => {
  const marker = showMarker
    ? `&markers=color:${markerColor}|${coordinates}`
    : "";
  return `${MAP_PREFIX}?center=${coordinates}&zoom=${zoom}&size=${size}&scale=${scale}${marker}&maptype=roadmap
  ${style}&key=${import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}`;
};
