const isObject = (obj: unknown) => obj && obj.constructor === Object;

/**
 * Does a deep merge on multiple objects but does not mutate the original like lodash merge
 *
 * @param obj
 * @param sources
 */
export const merge = (
  obj: NonNullable<unknown>,
  ...sources: Array<NonNullable<unknown>>
) => {
  const newObj = structuredClone(obj);
  for (const source of sources) {
    for (const key in source) {
      if (source[key] == null) {
        continue;
      }
      newObj[key] = replace(newObj[key], source[key]);
    }
  }
  return newObj;
};

const replace = (value: unknown, newValue: unknown) => {
  if (value && newValue && isObject(value) && isObject(newValue)) {
    return merge(value, newValue);
  }
  return newValue;
};
