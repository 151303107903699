import {
  AirChfarActions,
  AirChfarExerciseDiscountActions,
  AirDisruptionActions,
  AirPFExerciseActions,
  AirPriceDropActions,
  CartActions,
  CfarActions,
  ContactInformationActions,
  FlightActions,
  FlightPassengerActionsV2,
  PassportActions,
  ResumeFlightActions,
  ReviewActions,
  SeatActions,
  VipSupportActions,
} from "@hopper-b2b/checkout";

import { commonActions } from "./common";
import { NubankPaymentActions } from "../../../checkout/states/payments";

export const Actions = {
  ...AirChfarActions,
  ...AirChfarExerciseDiscountActions,
  ...AirDisruptionActions,
  ...AirPFExerciseActions,
  ...AirPriceDropActions,
  ...CartActions,
  ...CfarActions,
  ...commonActions,
  ...ContactInformationActions,
  ...FlightActions,
  ...FlightPassengerActionsV2,
  ...NubankPaymentActions,
  ...PassportActions,
  ...ResumeFlightActions,
  ...ReviewActions,
  ...SeatActions,
  ...VipSupportActions,
};

type IActionTypes = {
  [K in keyof typeof Actions]: K;
};

export const ActionTypes: IActionTypes = Object.keys(Actions).reduce(
  (prev, cur) => ({ ...prev, [cur]: cur }),
  {} as IActionTypes
);
