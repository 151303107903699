import { Snackbar as MuiSnackbar } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Alert, Color } from "@material-ui/lab";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { ReactComponent as WarningAlertIcon } from "../../assets/icons/common/alert-warning-currentColor.svg";
import { ReactComponent as CheckCircleFilledIcon } from "../../assets/icons/common/check-filled-currentColor.svg";
import { ReactComponent as CircleAlert } from "../../assets/icons/common/circle-alert-currentColor.svg";
import styles from "./Snackbar.module.scss";

export type SnackbarProps = {
  open: boolean;
  type?: string;
  autoHideDuration?: number;
};

export const Snackbar = ({
  open,
  type = "success",
  autoHideDuration = 3000,
  children,
}: PropsWithChildren<SnackbarProps>) => {
  const [show, setShow] = useState<boolean>(open);

  useEffect(() => {
    setShow(open);
  }, [open]);

  useEffect(() => {
    if (!show || !autoHideDuration) return;

    const timer = setTimeout(() => {
      setShow(false);
    }, autoHideDuration);
    return () => clearTimeout(timer);
  }, [autoHideDuration, show]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <MuiSnackbar
      open={open && show}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionComponent={SnackbarTransition}
    >
      <Alert
        onClose={handleClose}
        severity={type as Color}
        classes={{ icon: styles.icon }}
        iconMapping={{
          success: <CheckCircleFilledIcon />,
          warning: <WarningAlertIcon />,
          error: <CircleAlert />,
        }}
      >
        {children}
      </Alert>
    </MuiSnackbar>
  );
};

function SnackbarTransition(props) {
  return <Slide {...props} direction="down" />;
}
