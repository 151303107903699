import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { ButtonWrap } from "@hopper-b2b/ui";
import { DisplayedPrice } from "@hopper-b2b/wallet";
import { Typography } from "@material-ui/core";
import { ReactComponent as ArrowRight } from "../../assets/client/arrow-right.svg";
import "./styles.scss";

interface ISharedFlightCtaSectionProps {
  discountedPrice: FiatPrice;
  totalPrice: FiatPrice;
  tripCategory: TripCategory;
  onClick: () => void;
  buttonDisabled?: boolean;
  showCombinedTotal?: boolean;
}

const SharedFlightCtaSection = ({
  discountedPrice,
  totalPrice,
  tripCategory,
  onClick,
  buttonDisabled = false,
  showCombinedTotal = false,
}: ISharedFlightCtaSectionProps) => {
  const { t } = useI18nContext();
  return (
    <div className="fare-details-bottom-wrapper">
      <div className="fare-details-price-summary-wrapper">
        <div className="fare-details-summary-section">
          <div className="description-text">
            {tripCategory === TripCategory.ONE_WAY
              ? t(
                  showCombinedTotal
                    ? "oneWayTripCategoryTotal"
                    : "oneWayPerTraveler"
                )
              : t(
                  showCombinedTotal
                    ? "roundTripCategoryTotal"
                    : "roundTripPerTraveler"
                )}
          </div>
          <div className="price-text">
            <Typography className="content" variant="body1">
              <DisplayedPrice
                discountedCostFiat={discountedPrice}
                totalCostFiat={totalPrice}
              />
            </Typography>
            <div className="installments-badge">
              <p>{t("upToInterestFreeInstallments", { count: 8 })}</p>
            </div>
          </div>
        </div>
        <ButtonWrap
          className="fare-details-button-section"
          onClick={buttonDisabled ? null : onClick}
        >
          <ArrowRight className={buttonDisabled ? "disabled" : ""} />
        </ButtonWrap>
      </div>
    </div>
  );
};

export default SharedFlightCtaSection;
