import {
  Dispatch,
  FocusEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FrequentFlyerNumber,
  FrequentFlyerProgramCode,
} from "@hopper-b2b/types";
import { Autocomplete } from "@material-ui/lab";
import { Box, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  getViewportDifference,
  specialCharacterRegex,
} from "@hopper-b2b/utilities";
import {
  ActionButton,
  ActionLink,
  B2BTextField,
  BackButton,
  IconComponent,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import {
  formattedFlyerOptions,
  formattedJetBlueFlyerOptions,
  getNextTrackingId,
  IFlyerOption,
  IFrequentFlyer,
  IFrequentFlyerList,
} from "../../utils";
import "./styles.scss";

const MOBILE_HIDE_EDIT_FIELDS_THRESHOLD = 1;

interface IFrequentFlyerSectionProps {
  className?: string;
  frequentFlyerTitle: string;
  frequentFlyerList: IFrequentFlyerList;
  setFrequentFlyerList: Dispatch<SetStateAction<IFrequentFlyerList>>;
  isMobile?: boolean;
  closeButton?: JSX.Element;
  isJetBlueTenant: boolean;
}

export const FrequentFlyerSection = (props: IFrequentFlyerSectionProps) => {
  const {
    className,
    frequentFlyerTitle,
    frequentFlyerList,
    setFrequentFlyerList,
    isMobile,
    closeButton,
    isJetBlueTenant,
  } = props;
  const { t } = useI18nContext();

  const formattedFrequentFlyerOptions = isJetBlueTenant
    ? formattedJetBlueFlyerOptions
    : formattedFlyerOptions;

  const [curFrequentFlyerList, setCurFrequentFlyerList] =
    useState<IFrequentFlyerList>(frequentFlyerList);
  const [frequentFlyerNumber, setFrequentFlyerNumber] =
    useState<FrequentFlyerNumber>("");
  const [frequentFlyerProgram, setFrequentFlyerProgram] =
    useState<IFlyerOption | null>(null);
  const [focusedTrackingId, setFocusedTrackingId] = useState<number | null>(
    null
  );
  const [newTrackingId, setNewTrackingId] = useState<number>(
    getNextTrackingId(frequentFlyerList)
  );
  const [isInputChanged, setIsInputChanged] = useState<boolean>(false);
  const [openEditFieldsModal, setOpenEditFieldsModal] =
    useState<boolean>(false);
  const [frequentFlyerError, setFrequentFlyerError] = useState(false);

  const cleanUpFields = () => {
    // clean fields
    setFrequentFlyerNumber("");
    setFrequentFlyerProgram(null);
  };

  const cleanUp = () => {
    // set focused to null
    setFocusedTrackingId(null);
    cleanUpFields();
  };

  const handleOnEdit = useCallback(
    (frequentFlyer: IFrequentFlyer) => {
      // populate edit fields
      setFrequentFlyerNumber(frequentFlyer.value);
      setFrequentFlyerProgram(
        formattedFrequentFlyerOptions.find(
          (option) => frequentFlyer.key === option.value
        ) || null
      );

      // set focusedTrackingId
      setFocusedTrackingId(frequentFlyer.trackingId);

      // when flyer number & program are populated, and the current newTrackingId matches
      // with the entry in list, the newTrackingId needs to get updated as well
      const newEntry = curFrequentFlyerList.find(
        (frequentFlyer) => frequentFlyer.trackingId === newTrackingId
      );
      if (newEntry) {
        if (
          newEntry.key === frequentFlyerProgram?.value &&
          newEntry.value === frequentFlyerNumber
        ) {
          setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
        }
      }
    },
    [
      curFrequentFlyerList,
      formattedFrequentFlyerOptions,
      frequentFlyerNumber,
      frequentFlyerProgram,
      newTrackingId,
    ]
  );

  const handleOnEditMobile = (frequentFlyer: IFrequentFlyer) => {
    handleOnEdit(frequentFlyer);
    setOpenEditFieldsModal(true);
  };

  const handleOnRemove = (trackingId: number) => {
    setCurFrequentFlyerList((frequentFlyerList) => {
      return frequentFlyerList.filter((frequentFlyer) => {
        return frequentFlyer.trackingId !== trackingId;
      });
    });
  };

  const handleOnRemoveMobile = (trackingId: number) => {
    handleOnRemove(trackingId);
    cleanUpFields();
    setOpenEditFieldsModal(false);
  };

  const renderSavedFrequentFlyers = () => {
    return (
      <Box className="saved-frequent-flyers-section">
        {curFrequentFlyerList
          .filter((frequentFlyer) => {
            return (
              frequentFlyer.trackingId !== newTrackingId &&
              frequentFlyer.trackingId !== focusedTrackingId
            );
          })
          .map((frequentFlyer) => {
            return (
              <FrequentFlyerRow
                key={frequentFlyer.key}
                className="saved-frequent-flyer"
                frequentFlyerNumber={frequentFlyer.value}
                // TODO: handle this logic differently once knowing where to get all airlines
                frequentFlyerProgramName={
                  (formattedFrequentFlyerOptions.find(
                    (option) => option.value === frequentFlyer.key
                  )?.label as JSX.Element) ?? <></>
                }
                onEdit={() =>
                  isMobile
                    ? handleOnEditMobile(frequentFlyer)
                    : handleOnEdit(frequentFlyer)
                }
                onRemove={
                  isMobile
                    ? undefined
                    : () => handleOnRemove(frequentFlyer.trackingId)
                }
                isMobile={isMobile}
              />
            );
          })}
      </Box>
    );
  };

  const filteredFlyerOptions = useMemo(() => {
    const addedFlyerPrograms = new Set();
    curFrequentFlyerList.forEach((frequentFlyer) => {
      if (
        frequentFlyer.trackingId !== focusedTrackingId &&
        frequentFlyer.trackingId !== newTrackingId &&
        !addedFlyerPrograms.has(frequentFlyer.key)
      ) {
        addedFlyerPrograms.add(frequentFlyer.key);
      }
    });

    return formattedFrequentFlyerOptions.filter((option) => {
      return !addedFlyerPrograms.has(option.value);
    });
  }, [
    curFrequentFlyerList,
    focusedTrackingId,
    formattedFrequentFlyerOptions,
    newTrackingId,
  ]);

  const isButtonDisabled = !frequentFlyerNumber || !frequentFlyerProgram;
  const isButtonDisabledAddAnotherMobile =
    (!frequentFlyerNumber || !frequentFlyerProgram) &&
    curFrequentFlyerList.length < MOBILE_HIDE_EDIT_FIELDS_THRESHOLD;

  const updateCurFrequentFlyerList = (
    programNumber: FrequentFlyerNumber,
    programCode: FrequentFlyerProgramCode,
    trackingId: number
  ) => {
    setCurFrequentFlyerList((frequentFlyerList) => {
      const newFrequentFlyerList = [...frequentFlyerList];
      const focusedIndex = newFrequentFlyerList.findIndex(
        (frequentFlyer) => frequentFlyer.trackingId === trackingId
      );

      newFrequentFlyerList[focusedIndex] = {
        ...newFrequentFlyerList[focusedIndex],
        key: programCode,
        value: programNumber,
      };

      return newFrequentFlyerList;
    });
  };

  const addToCurFrequentFlyerList = (
    programNumber: FrequentFlyerNumber,
    airlineCode: FrequentFlyerProgramCode,
    trackingId: number
  ) => {
    setCurFrequentFlyerList((frequentFlyerList) => {
      const newFrequentFlyerList = frequentFlyerList;
      newFrequentFlyerList.push({
        key: airlineCode,
        value: programNumber,
        trackingId: trackingId,
      });

      return newFrequentFlyerList;
    });
  };

  const handleAddAnother = () => {
    if (isButtonDisabled) {
      return null;
    }

    cleanUp();
    setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
    return null;
  };

  const handleAddAnotherMobile = () => {
    if (isButtonDisabledAddAnotherMobile) {
      return null;
    }

    cleanUp();
    setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
    setOpenEditFieldsModal(true);
    return null;
  };

  const handleSaveFlyer = () => {
    // frequentFlyerProgram cannot be resolved as string when isButtonDisabled is used here; it's likely a compiler issue
    if (!frequentFlyerNumber || !frequentFlyerProgram) {
      return null;
    }

    if (focusedTrackingId !== null) {
      // update existing entry
      updateCurFrequentFlyerList(
        frequentFlyerNumber,
        frequentFlyerProgram.value,
        focusedTrackingId
      );
    }

    cleanUp();
    return null;
  };

  const handleSaveFlyerMobile = () => {
    handleSaveFlyer();
    setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
    setOpenEditFieldsModal(false);
  };

  const onFocus = async (event) => {
    const travelerInfoFormPopupElement = document
      ?.getElementsByClassName("traveler-select-workflow-info-form-popup")[0]
      ?.getElementsByClassName(
        "mobile-popover-card-container"
      )[0].parentElement;
    const inputTop =
      document
        .getElementById("frequent-flyer-number-input")
        ?.getBoundingClientRect().top || 0;

    if (travelerInfoFormPopupElement) {
      const difference = await getViewportDifference();
      const topWithoutDiff = inputTop - 48;
      const topWithDiff = inputTop - 48 - difference.height;
      // TODO: figure out the values here
      if (difference.height > 0 && inputTop > 100) {
        travelerInfoFormPopupElement.scrollBy({
          top: topWithDiff < 0 ? topWithoutDiff : topWithDiff,
          behavior: "smooth",
        });
      } else {
        event.target.parentElement.parentElement.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }
  };

  const renderButtons = () => {
    return (
      <Box className="frequent-flyers-buttons-section">
        {focusedTrackingId === null && (
          <Box className={clsx("button-container", "add-another")}>
            <ActionButton
              className={clsx(
                "traveler-info-button",
                "secondary",
                "add-another"
              )}
              message={t("addAnotherProgram")}
              disabled={isButtonDisabled}
              onClick={handleAddAnother}
              defaultStyle="h4r-secondary"
            />
          </Box>
        )}
        {focusedTrackingId !== null && (
          <Box className={clsx("button-container", "save-details")}>
            <ActionButton
              className={clsx("traveler-info-button", "save-details")}
              message={t("saveFlyerDetails")}
              disabled={isButtonDisabled}
              onClick={handleSaveFlyer}
              defaultStyle="h4r-primary"
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderButtonsMobile = () => {
    return (
      <Box
        className={clsx("frequent-flyers-buttons-section", {
          mobile: isMobile,
        })}
      >
        <Box className={clsx("button-container", "add-another")}>
          <Slot
            id="frequent-flyers-add-another-button"
            handleAddAnotherMobile={() => {
              cleanUp();
              setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
              setOpenEditFieldsModal(true);
            }}
            component={
              <ActionButton
                className={clsx(
                  "traveler-info-button",
                  "secondary",
                  "add-another"
                )}
                message={t("addAnotherProgram")}
                disabled={isButtonDisabledAddAnotherMobile}
                onClick={handleAddAnotherMobile}
                defaultStyle="h4r-secondary"
              />
            }
          />
        </Box>
      </Box>
    );
  };

  const renderEditFields = () => {
    return (
      <form className={clsx("traveler-info-form", "frequent-flyer-section")}>
        <Slot
          id="passenger-frequent-flyer-select"
          options={filteredFlyerOptions}
          setOption={(option: IFlyerOption) => setFrequentFlyerProgram(option)}
          selected={frequentFlyerProgram}
          component={
            <Autocomplete
              fullWidth
              value={frequentFlyerProgram}
              defaultValue={null}
              className="frequent-flyer-autocomplete"
              renderOption={({ label }) => label}
              classes={{ paper: "frequent-flyer-autocomplete-results" }}
              options={filteredFlyerOptions}
              onChange={(_e, option: IFlyerOption) =>
                setFrequentFlyerProgram(option)
              }
              getOptionLabel={({ program }) => program}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label={t("frequentFlyerProgram")}
                  className={clsx("traveler-info-field", "flyer-program")}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                    id: "frequent-flyer-program-input",
                  }}
                />
              )}
              id="frequent-flyer-program-input"
            />
          }
        />
        <B2BTextField
          label={t("frequentFlyerNumber")}
          className={clsx("traveler-info-field", "flyer-number", {
            error: !!frequentFlyerNumber && frequentFlyerError,
          })}
          value={frequentFlyerNumber}
          error={!!frequentFlyerNumber && frequentFlyerError}
          helperText={frequentFlyerError && t("noSpecialCharactersError")}
          onFocus={onFocus}
          onChange={(value) => {
            setFrequentFlyerNumber(value);
            if (value && specialCharacterRegex.test(value)) {
              frequentFlyerError && setFrequentFlyerError(false);
            } else if (value && !specialCharacterRegex.test(value)) {
              !frequentFlyerError && setFrequentFlyerError(true);
            }
          }}
          variant="default"
          id="frequent-flyer-number-input"
        />
      </form>
    );
  };

  const renderModalBottomButtons = () => {
    return (
      <>
        {focusedTrackingId !== null && (
          <Box className="frequent-flyer-remove-button-container">
            <ActionButton
              className="frequent-flyer-remove-button"
              disabled={false}
              onClick={() => handleOnRemoveMobile(focusedTrackingId)}
              message={
                <Box className="remove-button-content">
                  <IconComponent
                    ariaLabel="Delete"
                    className={clsx("remove-button-icon")}
                    name={IconName.Delete}
                  />
                  <span>{t("remove")}</span>
                </Box>
              }
              defaultStyle="h4r-secondary"
            />
          </Box>
        )}
        <MobileFloatingButton
          className="mobile-frequent-flyer-save-button"
          wrapperClassName="mobile-frequent-flyer-save-button-container"
          disabled={isButtonDisabled}
          onClick={handleSaveFlyerMobile}
        >
          {t("saveFrequentFlyer")}
        </MobileFloatingButton>
      </>
    );
  };

  const renderEditFieldsModal = () => {
    const handleGoBack = () => {
      // show goBack button when adding new entry; remove added entry when the user clicks on goBack
      if (focusedTrackingId === null) {
        handleOnRemoveMobile(newTrackingId);
      }
    };

    const renderContent = () => {
      return (
        <Box className="frequent-flyer-edit-form-container">
          <Box className="frequent-flyer-description">
            <Typography variant="subtitle1">{frequentFlyerTitle}</Typography>
          </Box>
          {renderEditFields()}
          <Slot
            id="frequent-flyer-form-modal-bottom-buttons"
            focusedTrackingId={focusedTrackingId}
            handleSaveFlyer={handleSaveFlyerMobile}
            handleRemoveFlyer={handleOnRemoveMobile}
            isSaveButtonDisabled={isButtonDisabled}
            component={renderModalBottomButtons()}
          />
        </Box>
      );
    };

    return (
      <Slot
        id="frequent-flyer-form-modal"
        open={openEditFieldsModal}
        children={renderContent()}
        onClose={() => setOpenEditFieldsModal(false)}
        fullScreen
        component={
          <MobilePopoverCard
            open={openEditFieldsModal}
            className="frequent-flyer-edit-fields-popup"
            onClose={() => setOpenEditFieldsModal(false)}
            fullScreen={true}
            topRightButton={closeButton}
            // show goBack button when adding new entry
            topLeftButton={
              focusedTrackingId === null ? (
                <BackButton
                  className="frequent-flyer-popup-go-back-button"
                  onClick={handleGoBack}
                />
              ) : undefined
            }
          >
            {renderContent()}
          </MobilePopoverCard>
        }
      />
    );
  };

  useEffect(() => {
    setIsInputChanged(true);
  }, [frequentFlyerNumber, frequentFlyerProgram]);

  useEffect(() => {
    // on mobile, the focusedTrackingId should be treated as null when editFieldsModal isn't open
    const isFocusedTrackingIdNull =
      focusedTrackingId === null || (isMobile && !openEditFieldsModal);

    // when both flyer number and program fields are populated, and there is no focused trackingId (not editting an existing entry)
    if (
      isInputChanged &&
      frequentFlyerNumber &&
      frequentFlyerProgram &&
      isFocusedTrackingIdNull
    ) {
      const indexWithNewTrackingId = curFrequentFlyerList.findIndex(
        (frequentFlyer) => frequentFlyer.trackingId === newTrackingId
      );

      // when there is an existing entry having newTrackingId
      if (indexWithNewTrackingId >= 0) {
        // update current entry
        updateCurFrequentFlyerList(
          frequentFlyerNumber,
          frequentFlyerProgram.value,
          newTrackingId
        );
      } else {
        // add new entry
        addToCurFrequentFlyerList(
          frequentFlyerNumber,
          frequentFlyerProgram.value,
          newTrackingId
        );
      }

      setIsInputChanged(false);
    }
  }, [
    isInputChanged,
    frequentFlyerNumber,
    frequentFlyerProgram,
    focusedTrackingId,
    curFrequentFlyerList,
    newTrackingId,
    updateCurFrequentFlyerList,
    addToCurFrequentFlyerList,
  ]);

  useEffect(() => {
    setFrequentFlyerList(curFrequentFlyerList);
  }, [curFrequentFlyerList]);

  // on mobile, the edit fields are supposed to be shown only when there are less than 1 existing entries
  const showEditFields =
    !isMobile ||
    curFrequentFlyerList.length < MOBILE_HIDE_EDIT_FIELDS_THRESHOLD ||
    // corner case when the current entry with newTrackingId is being edited
    (curFrequentFlyerList.length === 1 &&
      newTrackingId === curFrequentFlyerList[0].trackingId);

  return (
    <Box
      className={clsx(
        "frequent-flyer-container",
        { mobile: isMobile },
        className
      )}
    >
      <Box className="traveler-info-description">
        <Typography variant="subtitle1">{frequentFlyerTitle}</Typography>
      </Box>
      {renderSavedFrequentFlyers()}
      {showEditFields && renderEditFields()}
      {isMobile ? renderButtonsMobile() : renderButtons()}
      {renderEditFieldsModal()}
    </Box>
  );
};

interface IFrequentFlyerRowProps {
  className?: string;
  frequentFlyerNumber: FrequentFlyerNumber;
  frequentFlyerProgramName: JSX.Element;
  onEdit?: () => void;
  onRemove?: () => void;
  isMobile?: boolean;
}

const FrequentFlyerRow = (props: IFrequentFlyerRowProps) => {
  const {
    className,
    frequentFlyerNumber,
    frequentFlyerProgramName,
    onEdit,
    onRemove,
    isMobile,
  } = props;

  const { t } = useI18nContext();

  return (
    <Box
      className={clsx(
        "frequent-flyer-row-root",
        { mobile: isMobile },
        className
      )}
    >
      <Box className="frequent-flyer-row-container">
        <Box className="frequent-flyer-info-section">
          <Box className="frequent-flyer-info-text">
            {frequentFlyerProgramName}
            {` ****${frequentFlyerNumber.slice(4)}`}
          </Box>
        </Box>
        <Box className="frequent-flyer-buttons-section">
          {onEdit && (
            <ActionLink
              className="edit-button"
              disabled={false}
              onClick={() => onEdit()}
              content={
                <Typography className="action-link-text">
                  {t("edit")}
                </Typography>
              }
            />
          )}
          {onRemove && (
            <>
              <Typography className="action-link-text">|</Typography>
              <ActionLink
                className="remove-button"
                disabled={false}
                onClick={() => onRemove()}
                content={
                  <Typography className="action-link-text">
                    {t("delete")}
                  </Typography>
                }
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
