import { ChildMachineConfig } from "@checkout/types";
import { Event } from "@checkout/events";
import { GuardTypes } from "./guards";
import { ActionTypes } from "./actions";

export const getMachineState = (config: ChildMachineConfig) => ({
  always: {
    actions: ActionTypes.setReviewVisited,
    cond: GuardTypes.notVisitedReview,
  },
  on: {
    [Event.PREVIOUS]: config.previousState,
    [Event.NEXT]: config.nextState,
  },
});
