import { Person } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PersonId, UserInfo, VIEW_TRIP_TRAVELERS } from "@hopper-b2b/types";
import {
  ActionLink,
  DesktopPopupModal,
  IconComponent,
  IconName,
  MobileFloatingSection,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import { getDateTimeWithFormat, useDeviceTypes } from "@hopper-b2b/utilities";
import { Typography, TypographyVariant } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

import { Skeleton } from "@material-ui/lab";
import { TravelerInfoForm } from "../TravelerInfoForm";
import { TravelerSelectRow } from "../TravelerSelectRow";
import {
  TravelerSelectStep,
  TravelerTypeTitles,
} from "../TravelerSelectWorkflow/component";
import "./styles.scss";
import { Product } from "@b2bportal/purchase-api";

export interface SimplePassengerPickerProps {
  className?: string;
  isLoading?: boolean;
  userPassengers: Person[];
  titles: TravelerTypeTitles;
  titleVariant: TypographyVariant;
  selectedPassengerIds: PersonId[];
  handleUpdatePassenger: (
    passenger: Person,
    singleTravelerWorkflow?: boolean
  ) => void;
  handleDeletePassenger: (travelerId: PersonId) => void;
  isMobile?: boolean;
  onProgressChange?: (step: TravelerSelectStep) => void;
  handleContinue?: () => void;
  handleGoBack?: () => void;
  singleTravelerWorkflow?: boolean;
  userInfo?: UserInfo;
  editPassenger?: Person;
  hideFrequentFlyerSection?: boolean;
  hideHotelLoyaltySection?: boolean;
  hideNationalityField?: boolean;
  hideAdditionalInfoSection?: boolean;
  hideGenderField?: boolean;
  showDriverLicenseSection?: boolean;
  buttonMessage?: string;
  buttonClassName?: string;
  fullScreenWithBanner?: boolean;
  wrapButton?: boolean;
  disabled?: boolean;
  displayModalSubtitle?: boolean;
  currentPassenger: Person;
  handleEditClick: (passenger: Person) => void;
  openPassengerFormModal: boolean;
  openPassengerPicker: boolean;
  handleSelectPassenger: (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => void;
  handleCloseForm: () => void;
  handleClickAddTraveler: () => void;
  handleOpenPassengerPicker: () => void;
  isJetBlueTenant?: boolean;
  ErrorBanner?: string | JSX.Element;
  hasError?: boolean;
  modalClassName?: string;
  productType?: Product;
  mobilePaperClassName?: string;
  hideContinue?: boolean;
}

export const SimplePassengerPicker = ({
  className,
  titles,
  titleVariant = "h3",
  isLoading,
  isMobile,
  singleTravelerWorkflow,
  userInfo,
  hideFrequentFlyerSection,
  hideHotelLoyaltySection,
  hideNationalityField,
  hideAdditionalInfoSection,
  hideGenderField,
  showDriverLicenseSection = false, // defaulting to false to not show section
  buttonMessage,
  buttonClassName,
  fullScreenWithBanner,
  wrapButton,
  disabled,
  displayModalSubtitle,

  // Newly added
  currentPassenger,
  handleEditClick,
  openPassengerFormModal,
  handleContinue,
  selectedPassengerIds = [],
  userPassengers = [],
  handleSelectPassenger,
  handleCloseForm,
  handleUpdatePassenger,
  handleDeletePassenger,
  handleClickAddTraveler,
  isJetBlueTenant,
  ErrorBanner,
  hasError = false,
  modalClassName,
  productType,
  mobilePaperClassName,
  hideContinue = false,
}: SimplePassengerPickerProps): JSX.Element => {
  useEffect(() => {
    if (
      productType === Product.Flight ||
      productType === Product.MultiProviderFlight
    ) {
      trackEvent({
        eventName: VIEW_TRIP_TRAVELERS,
        properties: {
          num_travelers: selectedPassengerIds?.length || 0,
          product_type: productType,
        },
      });
    }
  }, []);

  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const [prefillPassenger, setPrefillPassenger] = useState<
    UserInfo | undefined
  >();

  const handleClickEdit = useCallback(
    (passenger: Person) => {
      // Open form and edit passenger
      handleEditClick(passenger);
    },
    [handleEditClick]
  );

  const handleClickRemove = (travelerId: PersonId) => {
    handleDeletePassenger(travelerId);
  };

  const handleClickSelect = useCallback(
    (travelerId: PersonId) => {
      // hacky way to show edit form pop if driver has no license
      if (showDriverLicenseSection) {
        const passenger = userPassengers.find((t) => t.id === travelerId);
        if (!passenger?.driverLicense?.street) {
          handleClickEdit(passenger as Person);
          return null;
        }
      }
      handleSelectPassenger(travelerId, singleTravelerWorkflow);

      return null;
    },
    [
      handleClickEdit,
      handleSelectPassenger,
      showDriverLicenseSection,
      singleTravelerWorkflow,
      userPassengers,
    ]
  );

  const handleAddTraveler = useCallback(() => {
    handleClickAddTraveler();
    if (userPassengers.length === 0) {
      setPrefillPassenger(userInfo);
    } else {
      setPrefillPassenger(undefined);
    }
  }, [handleClickAddTraveler, userInfo, userPassengers.length]);

  const renderTravelerEntriesSection = (props: {
    showRemoveButton?: boolean;
    showSelectButton?: boolean;
    showSelectedEntriesOnly?: boolean;
  }) => {
    const { showRemoveButton, showSelectButton, showSelectedEntriesOnly } =
      props;

    // On mobile, the main page should only show selected passengers

    const filteredTravelers = showSelectedEntriesOnly
      ? userPassengers.filter((passenger) =>
          selectedPassengerIds.includes(passenger.id)
        )
      : userPassengers;

    return (
      <div className={"traveler-select-workflow-entries-section"}>
        {!isLoading ? (
          filteredTravelers.map((passenger: Person, index: number) => {
            return (
              <TravelerSelectRow
                isMobile={isMobile}
                key={index}
                disabled={disabled}
                className="traveler-select-workflow-entry-root"
                value={`${passenger.givenName} ${passenger.surname}`}
                selectRowType={getTravelerType(passenger, titles)}
                isSelected={selectedPassengerIds.includes(passenger.id)}
                onClickEdit={() => handleClickEdit(passenger)}
                onClickRemove={
                  showRemoveButton
                    ? () => handleClickRemove(passenger.id)
                    : undefined
                }
                onClickSelect={
                  showSelectButton
                    ? () => handleClickSelect(passenger.id)
                    : undefined
                }
                buttonClassName={buttonClassName}
                wrapButton={wrapButton}
              />
            );
          })
        ) : (
          <>
            <Skeleton variant="rect" height={75} />
            <Skeleton variant="rect" height={75} />
          </>
        )}
      </div>
    );
  };

  const renderTravelerEntriesSectionBlock = () => {
    const isContinueDisabled =
      hasError || selectedPassengerIds.length === 0 || handleContinue === null;

    return (
      <div className="traveler-entries-select-container">
        <div className="traveler-entries-select-description">
          <Typography
            variant={titleVariant}
            className="traveler-entries-select-description-title"
          >
            {titles.addTravelers}
          </Typography>
          <Typography
            variant="body2"
            className="traveler-entries-select-description-subtitle"
          >
            {userPassengers.length > 0 && titles.travelerSelectSubtitle
              ? titles.travelerSelectSubtitle
              : titles.noTravelersSelectSubtitle
              ? titles.noTravelersSelectSubtitle
              : null}
          </Typography>
          {displayModalSubtitle ? (
            <Typography
              variant="subtitle2"
              className="traveler-entries-select-description-modal-subtitle"
            >
              {titles.travelerInfoSubtitle}
            </Typography>
          ) : null}
        </div>
        {ErrorBanner ? ErrorBanner : null}
        {renderTravelerEntriesSection({
          showRemoveButton: true,
          showSelectButton: true,
        })}
        <div className="traveler-entries-select-buttons-container">
          <ActionLink
            className={clsx("add-traveler")}
            disabled={disabled}
            onClick={handleAddTraveler}
            label={t("addNewTraveler")}
            content={
              <Typography className="action-link-text">
                {buttonMessage ? buttonMessage : t("addNewTraveler")}
              </Typography>
            }
          />
        </div>
        {!hideContinue ? (
          (matchesMobile && !isContinueDisabled) || !matchesMobile ? (
            <Slot
              id="mobile-checkout-cta-section"
              onClick={handleContinue}
              component={
                <MobileFloatingSection
                  className="traveler-entries-continue-button-container"
                  primaryButton={{
                    children: t?.("continue"),
                    onClick: () => {
                      handleContinue && handleContinue();
                    },
                    disabled: isContinueDisabled,
                    className: clsx("traveler-entries-continue-button", {
                      disabled: isContinueDisabled,
                    }),
                  }}
                />
              }
            />
          ) : null
        ) : null}
      </div>
    );
  };

  const renderTravelerInfoFormPopup = () => {
    const renderContent = (closeButton?: JSX.Element) => {
      return (
        <TravelerInfoForm
          className="traveler-select-workflow-info-form"
          travelerInfoTitle={titles.travelerInfoTitle}
          travelerInfoSubtitle={
            titles.travelerInfoFormSubtitle || titles.travelerInfoSubtitle
          }
          hideHeader={!isMobile}
          additionalInfoTitle={titles.additionalInfoTitle}
          hideFrequentFlyerSection={hideFrequentFlyerSection}
          hideHotelLoyaltySection={hideHotelLoyaltySection}
          hideNationalityField={hideNationalityField}
          hideAdditionalInfoSection={hideAdditionalInfoSection}
          hideGenderField={hideGenderField}
          showDriverLicenseSection={showDriverLicenseSection}
          traveler={currentPassenger}
          prefillPassenger={prefillPassenger}
          handleUpdatePassenger={handleUpdatePassenger}
          singleTravelerWorkflow={singleTravelerWorkflow}
          handleRemovePassenger={handleClickRemove}
          isMobile={isMobile}
          closeButton={closeButton}
          buttonClassName={buttonClassName}
          isJetBlueTenant={isJetBlueTenant}
          frequentFlyerTitle={titles.frequentFlyerTitle}
          handleClose={() => handleCloseForm()}
        />
      );
    };

    const renderCloseButton = () => {
      return (
        <ActionLink
          className="traveler-form-popup-close-button"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // on mobile => go to passengerPicker
              // else go to => idle
              handleCloseForm();
            }
          }}
          onClick={() => handleCloseForm()}
          content={
            <IconComponent
              ariaLabel={t("close.imageAlt")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label={t("close.button")}
          showTappableArea={true}
        />
      );
    };

    return (
      <>
        {!isMobile && (
          <DesktopPopupModal
            invisibleBackdrop={false}
            open={openPassengerFormModal}
            className={clsx(
              modalClassName,
              "traveler-select-workflow-info-form-popup"
            )}
            headerElement={
              <div className="traveler-info-description">
                <Typography variant="h6">{titles.travelerInfoTitle}</Typography>
                <Typography variant="body2">
                  {titles.travelerInfoFormSubtitle ||
                    titles.travelerInfoSubtitle}
                </Typography>
              </div>
            }
            onClose={handleCloseForm}
          >
            {renderContent()}
          </DesktopPopupModal>
        )}
        {isMobile && (
          <MobilePopoverCard
            open={openPassengerFormModal}
            className={clsx(
              modalClassName,
              "traveler-select-workflow-info-form-popup",
              "simple-passenger-picker",
              "mobile"
            )}
            onClose={handleCloseForm}
            fullScreen={true}
            topRightButton={renderCloseButton()}
            fullScreenWithBanner={fullScreenWithBanner}
            paperClassName={mobilePaperClassName}
          >
            {renderContent(renderCloseButton())}
          </MobilePopoverCard>
        )}
      </>
    );
  };

  return (
    <div className={className}>
      {renderTravelerInfoFormPopup()}
      {renderTravelerEntriesSectionBlock()}
    </div>
  );
};

export const getTravelerType = (
  passenger: Person,
  titles: {
    adultTitle: string;
    childTitle: string;
    infantSeatTitle: string;
    infantLapTitle: string;
  }
) => {
  const age = dayjs().diff(
    getDateTimeWithFormat(passenger.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  if (age > 11) {
    return titles.adultTitle;
  } else if (age >= 2) {
    return titles.childTitle;
  } else if (age > 1) {
    return titles.infantSeatTitle;
  } else {
    return titles.infantLapTitle;
  }
};
