import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { AlertBanner } from "@hopper-b2b/ui";
import { tenantFlagsEnabled, useDeviceTypes } from "@hopper-b2b/utilities";
import { DISPLAY_COVID_BANNER, FlightSearchEvents } from "@hopper-b2b/types";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";

import { FlightSearchConnectorProps } from "./container";
import { FlightSearchControl } from "./components/FlightSearchControl";
import { MobileFlightSearchControl } from "./components/MobileFlightSearchControl";
import { FLIGHT_SEARCH_TITLE, PORTAL_TITLE } from "../../lang/textConstants";
import "./styles.scss";
import { trackEvent } from "@hopper-b2b/api";

type IFlightSearchProps = FlightSearchConnectorProps;

export const FlightSearch = (props: IFlightSearchProps) => {
  const { resetState, fetchSearchFromUrl } = props;
  const location = useLocation();
  const history = useHistory();

  const { matchesMobile } = useDeviceTypes();
  const [displayBanner, setDisplayBanner] = useState(
    sessionStorage.getItem(DISPLAY_COVID_BANNER) === "true"
  );
  const { t } = useI18nContext();

  // TODO: use experiments after fixing experiments to load first before hydrating UI before
  const isIataLookupEnabled = tenantFlagsEnabled.FlightLocationIataLookup;

  useEffect(() => {
    document.title = FLIGHT_SEARCH_TITLE;
    resetState();
    window.scrollTo(0, 0);
    fetchSearchFromUrl(location?.search, history, isIataLookupEnabled);
    trackEvent({ eventName: FlightSearchEvents.view_search, properties: {} });

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  return matchesMobile ? (
    <Box className="mobile-flight-search-root">
      <Box className="flight-search-container-mobile">
        <MobileFlightSearchControl />
      </Box>
    </Box>
  ) : (
    <Box className="flight-search-root">
      <Box className="flight-search-container-desktop">
        <Typography variant="h1" className="search-title">
          <I18nMarkup tKey={"flightHome.title"} />
        </Typography>
        <Typography variant="h5" className="search-subtitle">
          {t("flightHome.subtitle")}
        </Typography>
        <FlightSearchControl history={history} />
        {displayBanner && (
          <AlertBanner
            className="covid-warning"
            content={
              <Typography className="covid-warning-text">
                <I18nMarkup tKey={"homeCovidWarning"} />
              </Typography>
            }
            onClick={() => {
              setDisplayBanner(false);
              sessionStorage.setItem(DISPLAY_COVID_BANNER, "false");
            }}
          />
        )}
      </Box>
    </Box>
  );
};
