import { FarePricing } from "@hopper-b2b/types";
import { getRewardsString } from "@hopper-b2b/utilities";

export const getRewardsText = ({
  rewardsKey,
  pricing,
}: {
  rewardsKey: string | null;
  pricing: FarePricing;
}) => {
  const baseAmountReward = rewardsKey
    ? pricing.baseAmount.rewards[rewardsKey]
    : undefined;
  const taxAmountReward = rewardsKey
    ? pricing.taxAmount.rewards[rewardsKey]
    : undefined;
  const additionalMarginReward = rewardsKey
    ? pricing.additionalMargin?.rewards[rewardsKey]
    : undefined;
  return baseAmountReward && taxAmountReward
    ? getRewardsString({
        currency: baseAmountReward.currency,
        value:
          baseAmountReward.value +
          taxAmountReward.value +
          (additionalMarginReward ? additionalMarginReward.value : 0),
      })
    : "";
};
