import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import clsx from "clsx";

import { useI18nContext } from "@hopper-b2b/i18n";
import { BackButton, Slot } from "@hopper-b2b/ui";
import {
  emailRegex as defaultEmailRegex,
  phoneRegex as defaultPhoneRegex,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { trackEvent } from "@hopper-b2b/api";
import { VIEWED_CONTACT_INFO } from "@hopper-b2b/types";

import { MobileLayout } from "../common";
import { DesktopLayout } from "../common/DesktopLayout";
import { IContactInfo } from "../../types/common";
import { ContactInformationContent } from "./components/ContactInformationContent";
import "./styles.scss";

export interface IContactInformationProps extends PropsWithChildren {
  className?: string;
  contactInfo: IContactInfo | null;
  onContactInfoChange?: (info: IContactInfo | null) => void;
  onGoBack?: () => void;
  onContinueClick?: () => void;
  headerElement?: JSX.Element;
  desktopRightContent?: JSX.Element;
  desktopTopContent?: JSX.Element;
  clientAssets?: { logo: string };
  headerTitle?: string;
  leftTopContent?: JSX.Element;
  floatingSectionDescription?: JSX.Element;
  showContinueButton?: boolean;
  emailRegex?: RegExp;
  phoneRegex?: RegExp;
  checkPhoneMinLength?: boolean;
  minPhoneLength?: number;
}

export const ContactInformation = ({
  className,
  onGoBack,
  headerElement,
  desktopRightContent,
  clientAssets,
  desktopTopContent,
  headerTitle,
  leftTopContent,
  contactInfo,
  onContactInfoChange,
  onContinueClick,
  showContinueButton = true,
  emailRegex = defaultEmailRegex,
  phoneRegex = defaultPhoneRegex,
  checkPhoneMinLength,
  minPhoneLength,
  ...rest
}: IContactInformationProps) => {
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_CONTACT_INFO,
      properties: undefined,
    });
  }, []);

  const hasContactInfo = useMemo((): boolean => {
    return (
      !!contactInfo?.email &&
      !!contactInfo?.phoneNumber &&
      emailRegex.test(contactInfo?.email) &&
      phoneRegex.test(contactInfo?.phoneNumber.replace(/\D/g, ""))
    );
  }, [emailRegex, contactInfo]);

  const goBack = useCallback(() => {
    // Update xstate FlightState onClick of back button
    onGoBack && onGoBack();
  }, [onGoBack]);

  const handleContactInfoChange = useCallback(() => {
    onContactInfoChange(contactInfo);
    onContinueClick && onContinueClick();
  }, [contactInfo, onContactInfoChange, onContinueClick]);

  return matchesMobile ? (
    <MobileLayout
      className={clsx(
        "contact-info-workflow-info-form-popup",
        "mobile",
        className
      )}
      topLeftButton={
        <BackButton
          className="contact-form-popup-back-button"
          onClick={goBack}
        />
      }
      headerElement={headerElement}
      title={headerTitle}
    >
      <Grid container direction="column" wrap="nowrap">
        {leftTopContent && <Grid item>{leftTopContent}</Grid>}
        <Grid item xs>
          <ContactInformationContent
            showContinueButton={showContinueButton}
            hasContactInfo={hasContactInfo}
            isContinueDisabled={!hasContactInfo || !showContinueButton}
            contactInfo={contactInfo}
            onContactInfoChange={onContactInfoChange}
            onContinue={handleContactInfoChange}
            emailRegex={emailRegex}
            phoneRegex={phoneRegex}
            checkPhoneMinLength={checkPhoneMinLength}
            minPhoneLength={minPhoneLength}
            {...rest}
          />
        </Grid>
      </Grid>
    </MobileLayout>
  ) : (
    <DesktopLayout
      clientLogo={clientAssets?.logo}
      headerElement={headerElement}
      rightContent={
        <>
          {desktopRightContent}
          <Divider />
          <Box m={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disableElevation
              onClick={handleContactInfoChange}
              disabled={!hasContactInfo || !showContinueButton}
            >
              {t?.("continue")}
            </Button>
          </Box>
          <Slot id="non-refundable-banner" applyPositionAbsolute />
        </>
      }
      topContent={desktopTopContent}
    >
      {leftTopContent}
      <ContactInformationContent
        showContinueButton={showContinueButton}
        hasContactInfo={hasContactInfo}
        isContinueDisabled={!hasContactInfo || !showContinueButton}
        contactInfo={contactInfo}
        onContactInfoChange={onContactInfoChange}
        onContinue={handleContactInfoChange}
        {...rest}
      />
    </DesktopLayout>
  );
};
