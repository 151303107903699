import {
  ShopFilter,
  ShopSummaryRequest,
  ShopSummaryResponse,
  SliceStopCountFilter,
  NETWORK_CALL_FAILED,
  VIEWED_FLIGHT_SHOP_LOADING,
  Platform,
  IApiConfig,
} from "@hopper-b2b/types";
import { tripSummariesApiPrefix } from "../paths";
import { IShopParams } from "../../../modules/shop/actions/actions";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import dayjs from "dayjs";

export const fetchTripSummaries = async (
  shopParams: IShopParams,
  isMobile: boolean | undefined,
  apiConfig?: IApiConfig
): Promise<ShopSummaryResponse> => {
  return new Promise(async (resolve, reject) => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter,
    } = shopParams;
    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;
    if (fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    const request: ShopSummaryRequest = {
      passengers: passengerObj,
      departureDate,
      ...(returnDate && { returnDate }),
      route: {
        origin,
        destination,
      },
      tripFilter,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
    };
    try {
      const startTime = dayjs();
      const res = await axiosInstance.post(tripSummariesApiPrefix, request);
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_SHOP_LOADING,
          properties: {
            runtime: dayjs().diff(startTime, "seconds", true),
          },
        },
        apiConfig
      );
      resolve(res.data as ShopSummaryResponse);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
};
