import {
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionTrackingEvents,
  FlightItinerarySegmentStatusEnum,
  MyTripsModalTypes,
  PortalItineraryStatusEnum,
  TagColors,
  VIEWED_TRIP_SUMMARY,
  getDepartureSlice,
  getFlightInfoDetails,
  getFlightSummaryInfo,
  getItinerarySummaryProps,
  getReturnSlice,
  getTripSegmentsFromItinerarySegments,
} from "@hopper-b2b/types";
import {
  ActionLink,
  ActionLinks,
  IActionLink,
  Icon,
  IconName,
  MixedCabinToolTip,
  RemoteAirlineIcon,
  Slot,
  StatusTag,
} from "@hopper-b2b/ui";
import {
  getIsMixedClass,
  removeTimezone,
  useEnableCfar,
  useEnableDisruptionProtection,
  useEnableMissedConnection,
} from "@hopper-b2b/utilities";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, StaticContext } from "react-router";
import {
  fetchFlightCfarCancellationQuoteV1,
  setDisruptionExerciseProductType,
  setDisruptionExerciseProgress,
} from "../../../TripsList/actions/actions";
import { addFlightType } from "../../../TripsList/components/ItineraryList/components/FlightCard/helpers";
import { getConfirmationNumbers } from "../../../TripsList/components/ItineraryList/components/ItinerariesModal/components/ConfirmationModalContent/component";
import { RedeemFTCButton } from "../../../TripsList/components/ItineraryList/components/RedeemFTCButton";
import { ExpandableSection } from "../ExpandableSection/component";
import { FlightCardConnectorProps } from "../FlightCard/container";
import { ItineraryDate } from "../ItineraryDate/component";
import { DATE_FORMAT, TIME_FORMAT } from "../ItineraryList/constants";
import { FlightPaymentSummary } from "./components/FlightPaymentSummary/component";
import { TravelerInformation } from "./components/TravelerInformation/component";
import { FlightAddOns } from "./components/FlightAddOns";
// import { TripFareDetails } from "./components/TripFareDetails/component";
import "./styles.scss";
import { FlightSliceDetails } from "../FlightSliceDetails";

export interface FlightItineraryDetailsProps
  extends FlightCardConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  flight: BookedFlightItineraryWithDepartureTime;
  isMobile?: boolean;
  isPastTrips: boolean;
}

export const FlightItineraryDetails = ({
  flight,
  airportMap,
  airlineMap,
  isMobile,
  setSelectedFlight,
  history,
  setOpenModal,
  isPastTrips,
  populateTripQueryParams,
}: FlightItineraryDetailsProps) => {
  const { t } = useI18nContext();
  const dispatch = useDispatch();

  const openFTCPopover = () => {
    setFTCPopoverOpen(true);
  };

  const onOpenModal = useCallback(
    (type: MyTripsModalTypes) =>
      setOpenModal({
        type,
        selectedItinerary: addFlightType(flight),
      }),
    [flight, setOpenModal]
  );

  const goToExchange = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation();

      onOpenModal(MyTripsModalTypes.ExchangeFlight);
    },
    [onOpenModal]
  );

  const passengerMap = {};
  flight.bookedItinerary.passengers.alone.reduce((map, p) => {
    if (!map[p.person.id]) {
      map[p.person.id] = p.person;
    }
    return passengerMap;
  }, passengerMap);
  flight.bookedItinerary.passengers.withLapInfants.reduce((map, p) => {
    if (!map[p.adult.person.id]) {
      map[p.adult.person.id] = p.adult.person;
    }
    return passengerMap;
  }, passengerMap);

  const openCfarModal = () => {
    const itineraryId = flight.bookedItinerary.id;

    dispatch(fetchFlightCfarCancellationQuoteV1({ itineraryId }, flight));
    dispatch(populateTripQueryParams(history));
    onOpenModal(MyTripsModalTypes.CfarFlight);
  };

  const openMissedConnectionModal = () => {
    onOpenModal(MyTripsModalTypes.MissedConnection);
  };

  const openDelayDisruptionModal = () => {
    dispatch(setSelectedFlight(flight));
    dispatch(
      setDisruptionExerciseProductType(
        DisruptionExerciseProductType.ScheduleChange
      )
    );
    trackEvent({
      eventName: DisruptionTrackingEvents.ENTER_DISRUPTION_EXERCISE_FLOW,
      properties: null,
    });
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
    );
    dispatch(populateTripQueryParams(history));
    onOpenModal(MyTripsModalTypes.DelayDisruption);
  };

  const departureSummary = getFlightSummaryInfo(
    true,
    flight,
    airportMap,
    airlineMap
  );

  const returnSummary = getFlightSummaryInfo(
    false,
    flight,
    airportMap,
    airlineMap
  );

  const flightDetails = useMemo(() => {
    return getFlightInfoDetails(
      flight,
      (date: string) => dayjs(date).format(DATE_FORMAT),
      airportMap,
      airlineMap
    );
  }, [airlineMap, airportMap, flight]);

  const title = t("originToDestination", {
    origin: flightDetails.origin,
    destination: flightDetails.destination,
    interpolation: { escapeValue: false },
  });

  const {
    status,
    travelCredit,
    travelCreditCopy,
    ancillaries: { cfar, delay, missedConnection },
  } = flight;

  const isCancel = status === PortalItineraryStatusEnum.Canceled;

  const ftcPopoverRef = useRef<HTMLButtonElement>(null);
  const [ftcPopoverOpen, setFTCPopoverOpen] = useState(false);
  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);

  const showFlightCFAR = useEnableCfar();
  const showMissedConnection = useEnableMissedConnection();
  const showDisruption = useEnableDisruptionProtection();

  const hasCfar = showFlightCFAR && !!cfar && cfar.isExercisable;
  const hasMissedConnection = showMissedConnection && !!missedConnection;
  const hasDelayDisruption = showDisruption && !!delay;

  const hasFTC = !!travelCredit && !!travelCreditCopy;
  const tripType = flightDetails.roundTrip
    ? t("roundTripFlight")
    : t("oneWayFlight");

  const departureSlice = getDepartureSlice(flight.bookedItinerary);
  const modifiedDepartureSlice =
    flight.bookedItinerary?.scheduleChange?.next?.at(0);

  const returnSlice = getReturnSlice(flight.bookedItinerary);
  const modifiedReturnSlice =
    !!flight.bookedItinerary?.scheduleChange?.next &&
    flight.bookedItinerary?.scheduleChange?.next?.length > 1
      ? flight.bookedItinerary?.scheduleChange?.next?.at(1)
      : null;

  const modifiedStatusCheck = (status: FlightItinerarySegmentStatusEnum) =>
    status === FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange ||
    status === FlightItinerarySegmentStatusEnum.UnMapped ||
    status === FlightItinerarySegmentStatusEnum.Confirmed ||
    status === FlightItinerarySegmentStatusEnum.UnMappedPersisted;
  const outboundChanged = modifiedDepartureSlice?.segments?.find((s) =>
    modifiedStatusCheck(s.status)
  );

  const returnChanged = modifiedReturnSlice?.segments?.find((s) =>
    modifiedStatusCheck(s.status)
  );

  const departureDate = useMemo(() => {
    return removeTimezone(departureSlice.segments[0].scheduledDeparture);
  }, [departureSlice]);

  const returnDate = useMemo(() => {
    if (!returnSlice) {
      return undefined;
    }
    return removeTimezone(returnSlice.segments[0].scheduledDeparture);
  }, [returnSlice]);

  useEffect(() => {
    if (departureSlice) {
      setIsOutgoingMixedClass(getIsMixedClass(departureSlice));
    }
    if (returnSlice) {
      setIsReturnMixedClass(getIsMixedClass(returnSlice));
    }
  }, [flight]);

  useEffect(() => {
    const {
      origin: { locationCode: originLocationCode },
      destination: { locationCode: destinationLocationCode },
    } = departureSlice.segments[departureSlice.segments.length - 1];

    trackEvent({
      eventName: VIEWED_TRIP_SUMMARY,
      properties: {
        origin: flightDetails.origin,
        destination: flightDetails.destination,
        departure_date: flightDetails.startDate,
        return_date: flightDetails.endDate,
        origin_country_code:
          airportMap[originLocationCode].geography.countryCode,
        destination_country_code:
          airportMap[destinationLocationCode].geography.countryCode,
        trip_type: flightDetails.roundTrip ? "round_trip" : "one_way",
      },
    });
  }, [airportMap, departureSlice.segments, flightDetails]);

  const getActions = () => {
    const actions: IActionLink[] = [];

    if (!isCancel && !isPastTrips) {
      actions.push({
        content: t("tripReviewLinks.changeFlight"),
        onClick: goToExchange,
      });
      // note: replace cancel-flight with cancel-for-any-reason when CFAR is included
      if (hasCfar) {
        actions.push({
          linkClassName: "desktop-cfar-link",
          content: (
            <>
              <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
              {t("cancelForAnyReasonEntryPoint")}
            </>
          ),
          onClick: openCfarModal,
        });
      } else {
        actions.push({
          content: t("tripReviewLinks.cancelFlight"),
          onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
        });
      }
    } else if (hasFTC) {
      // flight is cancelled && has travel credit
      actions.push({
        linkClassName: "how-ftc-works-link",
        content: (
          <>
            {t("selfServe.howTcWorks")}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkRef: ftcPopoverRef,
        onClick: openFTCPopover,
      });
    }

    if (hasMissedConnection) {
      actions.push({
        content: t("missedConnection"),
        onClick: openMissedConnectionModal,
      });
    }

    if (hasDelayDisruption) {
      actions.push({
        content: t("delayDisruption"),
        onClick: openDelayDisruptionModal,
      });
    }

    actions.push(
      {
        content: t("tripReviewLinks.resendEmail"),
        onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
      },
      {
        content: t("tripReviewLinks.contactSupport"),
        onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
      }
    );

    return actions;
  };

  const getItineraryDetailsHeader = (
    isOutgoing: boolean,
    airportMap: { [key: string]: Airport | undefined }
  ) => {
    const slice = isOutgoing ? departureSlice : returnSlice;
    if (!slice) {
      return "";
    }
    const lastIndexOfSegments = slice.segments.length - 1;
    return (
      <Box className="itinerary-details-header-content">
        <Typography>
          {t("itineraryDetailsHeader", {
            airport:
              airportMap[
                slice.segments[lastIndexOfSegments].destination.locationCode
              ]?.cityName,
            destination:
              slice.segments[lastIndexOfSegments].destination.locationCode,
            date:
              dayjs(slice.segments[0].scheduledDeparture).format(DATE_FORMAT) +
              " - " +
              dayjs(slice.segments[0].scheduledDeparture).format(TIME_FORMAT),
          })}
        </Typography>
        {titleTag &&
          ((isOutgoing && outboundChanged) ||
            (!isOutgoing && returnChanged)) && (
            <StatusTag
              className="title-status-tag outlined"
              tagInfo={titleTag}
            />
          )}
      </Box>
    );
  };

  const isWithin24Hours = (flight: BookedFlightItineraryWithDepartureTime) => {
    const timeDiff = dayjs(flight.departureTime).diff(dayjs(), "h");
    return timeDiff <= 24;
  };

  const bottomAction = useMemo(() => {
    if (isCancel && hasFTC) {
      return (
        <RedeemFTCButton onClick={goToExchange} travelCredit={travelCredit} />
      );
    } else if (!isPastTrips && !isCancel && isWithin24Hours(flight)) {
      return (
        <ActionLink
          className="watch-opt-in-popup-close-button"
          onClick={() => {
            onOpenModal(MyTripsModalTypes.CheckInFlight);
          }}
          showTappableArea={true}
          content={
            <Typography variant="body2" className="check-in-link">
              {t("tripReviewLinks.checkInForFlight")}
              <FontAwesomeIcon
                className="check-in-icon"
                icon={faEdit as IconProp}
              />
            </Typography>
          }
        />
      );
    }

    return null;
  }, [
    flight,
    goToExchange,
    hasFTC,
    isCancel,
    isPastTrips,
    onOpenModal,
    t,
    travelCredit,
  ]);

  const titleTag = useMemo(() => {
    if (isPastTrips) {
      return undefined;
    }
    switch (flight.status) {
      case PortalItineraryStatusEnum.Modified:
        return {
          label: t("itineraryModified"),
          type: TagColors.ORANGE,
          iconName: undefined,
          tooltipCopy: undefined,
        };
      default:
        return undefined;
    }
  }, [flight.status, isPastTrips, t]);

  return (
    <Box
      className={clsx("flight-itinerary-details-container", "apac", {
        mobile: isMobile,
      })}
    >
      <Link
        className="back-button"
        onClick={() => {
          setSelectedFlight(null);
          populateTripQueryParams(history);
        }}
      >
        <FontAwesomeIcon className="right-chevron" icon={faChevronLeft} />
        <Typography className="back-button-text" variant="body2">
          {"Back to my trips"}
        </Typography>
      </Link>
      <Box className="flight-details-header-content">
        <Slot id="mytrips-flight-icon" />
        {!!titleTag && (
          <StatusTag className="title-status-tag" tagInfo={titleTag} />
        )}
      </Box>
      <Box className={clsx("flight-details-content", { mobile: isMobile })}>
        <Box>
          <Typography variant="h2" className="flight-title">
            {title}
          </Typography>
          <Box className="confirmation-code-container">
            <Typography className="label">{t("confirmation")}</Typography>
            <Typography className="value">
              {flightDetails.confirmationCode}
            </Typography>
            <Link
              className="link"
              onClick={() => {
                onOpenModal(MyTripsModalTypes.ConfirmationModal);
              }}
            >
              <Typography className="link-text">
                {t("viewAllConfirmation", {
                  count: getConfirmationNumbers({ flight, airlineMap })?.length,
                })}
              </Typography>
            </Link>
          </Box>
          <Box className="trip-type-container">
            <Typography className="label">{t("tripTypeLabel")}</Typography>
            <Typography className="value">{tripType}</Typography>
          </Box>
        </Box>
        <Box className="flight-trip-dates-details">
          <Box>
            <Box className="flight-number-details">
              <RemoteAirlineIcon
                className="icon"
                airlineCode={departureSummary.airlineCode}
                altText={departureSummary.airlineName}
                size="small"
              />
              <Box className="flight-number">
                <Typography variant="body1">
                  {`${departureSummary.airlineCode} ${
                    departureSlice.segments[0].marketingAirline.flightNumber.toString() ??
                    ""
                  }`}
                </Typography>
              </Box>
            </Box>
            <ItineraryDate label={t("departingLabel")} date={departureDate} />
          </Box>
          {returnDate ? (
            <Box>
              <Box className="flight-number-details">
                <RemoteAirlineIcon
                  className="icon"
                  airlineCode={returnSummary.airlineCode}
                  altText={returnSummary.airlineName}
                  size="small"
                />
                <Box className="flight-number">
                  <Typography variant="body1">
                    {`${returnSummary.airlineCode} ${
                      returnSlice.segments[0].marketingAirline.flightNumber.toString() ??
                      ""
                    }`}
                  </Typography>
                </Box>
              </Box>
              <ItineraryDate label={t("returningLabel")} date={returnDate} />
            </Box>
          ) : null}
        </Box>
      </Box>
      <ActionLinks className="flight-action-links" actions={getActions()} />
      <Divider />
      <ExpandableSection
        title={getItineraryDetailsHeader(true, airportMap)}
        className="flight-expandable-section outgoing"
      >
        <Box className="details-info-container">
          {modifiedDepartureSlice && (
            <Typography
              className="slice-warning-message"
              dangerouslySetInnerHTML={{
                __html: t("scheduleChange.minorScheduleChangeDescription", {
                  email: flight.emailAddress,
                  origin:
                    airportMap[
                      departureSlice.segments[
                        departureSlice.segments.length - 1
                      ].origin.locationCode
                    ]?.cityName,
                  destination:
                    airportMap[
                      departureSlice.segments[
                        departureSlice.segments.length - 1
                      ].destination.locationCode
                    ]?.cityName,
                }),
              }}
            />
          )}
          <Box className="slice-info-title">
            {isOutgoingMixedClass && <MixedCabinToolTip />}
          </Box>
          <Box className={clsx("slice-info", { mobile: isMobile })}>
            <Box>
              {modifiedDepartureSlice && (
                <>
                  <Typography className="slice-content-title">
                    {t("commBank.trips.updatedItineraryTitle")}
                  </Typography>
                  <FlightSliceDetails
                    className="slice-content updated"
                    showTitle={false}
                    tripSlice={modifiedDepartureSlice}
                    segments={getTripSegmentsFromItinerarySegments(
                      modifiedDepartureSlice.segments,
                      airportMap,
                      airlineMap
                    )}
                    departureTime={
                      modifiedDepartureSlice.segments[0]
                        .zonedUpdatedDeparture ||
                      modifiedDepartureSlice.segments[0]
                        .zonedScheduledDeparture ||
                      modifiedDepartureSlice.segments[0].scheduledDeparture
                    }
                    planeInfo={""}
                    fareClass={
                      modifiedDepartureSlice.fareShelf?.shortBrandName || ""
                    }
                    plusDays={modifiedDepartureSlice.segments
                      .filter((s) => s.plusDays && s.plusDays > 0)
                      .reduce(
                        (total, segment) => total + (segment.plusDays ?? 0),
                        0
                      )}
                    isMixedCabinClass={isOutgoingMixedClass}
                    renderAirlineIconSection={false}
                  />
                  <Typography className="slice-content-title">
                    {t("scheduleChange.originalFlight")}
                  </Typography>
                </>
              )}
              <FlightSliceDetails
                className={clsx("slice-content", {
                  outdated: !!modifiedDepartureSlice,
                })}
                showTitle={false}
                {...getItinerarySummaryProps(
                  flight,
                  true,
                  airportMap,
                  airlineMap
                )}
                isMixedCabinClass={isOutgoingMixedClass}
                renderAirlineIconSection={false}
              />
            </Box>
            {/* TODO: Reenable once UTA issue fixed - APACCLOUD-1401 */}
            {/* <TripFareDetails
              flight={flight}
              bookedItinerary={bookedItinerary}
              outboundSeatSegments={departureSlice.segments}
              dataMap={{ passengerMap, airportMap }}
              originCodes={{
                outboundCode: departureSlice.segments[0].origin.locationCode,
                returnCode: returnSlice?.segments[0].origin.locationCode,
              }}
            /> */}
          </Box>
        </Box>
      </ExpandableSection>
      <Divider />
      {returnSlice && (
        <>
          <ExpandableSection
            title={getItineraryDetailsHeader(false, airportMap)}
            className="flight-expandable-section returning"
          >
            <Box className="details-info-container">
              {modifiedReturnSlice && (
                <Typography
                  className="slice-warning-message"
                  dangerouslySetInnerHTML={{
                    __html: t("scheduleChange.minorScheduleChangeDescription", {
                      email: flight.emailAddress,
                      origin:
                        airportMap[
                          returnSlice.segments[returnSlice.segments.length - 1]
                            .origin.locationCode
                        ]?.cityName,
                      destination:
                        airportMap[
                          returnSlice.segments[returnSlice.segments.length - 1]
                            .destination.locationCode
                        ]?.cityName,
                    }),
                  }}
                />
              )}
              <Box className="slice-info-title">
                {isReturnMixedClass && <MixedCabinToolTip />}
              </Box>
              <Box
                className={clsx("slice-info", "return", { mobile: isMobile })}
              >
                <Box>
                  {modifiedReturnSlice && (
                    <>
                      <Typography className="slice-content-title">
                        {t("scheduleChange.updatedFlight")}
                      </Typography>
                      <FlightSliceDetails
                        className="slice-content updated"
                        showTitle={false}
                        tripSlice={modifiedReturnSlice as any}
                        segments={getTripSegmentsFromItinerarySegments(
                          modifiedReturnSlice.segments,
                          airportMap,
                          airlineMap
                        )}
                        departureTime={
                          modifiedReturnSlice.segments[0]
                            .zonedUpdatedDeparture ||
                          modifiedReturnSlice.segments[0]
                            .zonedScheduledDeparture ||
                          modifiedReturnSlice.segments[0].scheduledDeparture
                        }
                        planeInfo={""}
                        fareClass={
                          modifiedReturnSlice.fareShelf?.shortBrandName || ""
                        }
                        plusDays={modifiedReturnSlice.segments
                          .filter((s) => s.plusDays && s.plusDays > 0)
                          .reduce(
                            (total, segment) => total + (segment.plusDays ?? 0),
                            0
                          )}
                        isMixedCabinClass={isReturnMixedClass}
                        renderAirlineIconSection={false}
                      />
                      <Typography className="slice-content-title">
                        {t("tripsItineraryTitle")}
                      </Typography>
                    </>
                  )}
                  <FlightSliceDetails
                    showTitle={false}
                    className={clsx("slice-content", {
                      outdated: !!modifiedDepartureSlice,
                    })}
                    {...getItinerarySummaryProps(
                      flight,
                      false,
                      airportMap,
                      airlineMap
                    )}
                    isMixedCabinClass={isReturnMixedClass}
                    renderAirlineIconSection={false}
                  />
                </Box>
                {/* TODO: Reenable once UTA issue fixed - APACCLOUD-1401 */}
                {/* <TripFareDetails
                  flight={flight}
                  bookedItinerary={bookedItinerary}
                  outboundSeatSegments={returnSlice.segments}
                  dataMap={{ passengerMap, airportMap }}
                  originCodes={{
                    outboundCode:
                      departureSlice?.segments[0].origin.locationCode,
                    returnCode: returnSlice?.segments[0].origin.locationCode,
                  }}
                /> */}
              </Box>
            </Box>
          </ExpandableSection>
          <Divider />
        </>
      )}
      <ExpandableSection
        className="flight-expandable-section"
        title={"Traveller information"}
      >
        <TravelerInformation
          travelers={flight.bookedItinerary.passengers.withLapInfants
            .map((passenger) => passenger.adult.person)
            .concat(
              flight.bookedItinerary.passengers.alone.map(
                (passenger) => passenger.person
              )
            )}
        />
      </ExpandableSection>
      <Divider />
      <FlightAddOns
        flight={flight}
        addOnFlags={{ hasCfar, hasDelayDisruption, hasMissedConnection }}
        actions={{
          openCfarModal,
          openDelayDisruptionModal,
          openMissedConnectionModal,
        }}
      />
      <ExpandableSection
        className="flight-expandable-section"
        title={"Trip total"}
      >
        <FlightPaymentSummary flight={flight} />
      </ExpandableSection>
      <Box className="bottom-action-container">{bottomAction}</Box>
    </Box>
  );
};
