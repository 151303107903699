import { connect, ConnectedProps } from "react-redux";
import { PickerType } from "@hopper-b2b/ui";
import { TripCategory } from "@hopper-b2b/types";

import {
  getTripCategory,
  getDepartureMonths,
  getPriceRangeLegends,
  getCurrency,
  getIsMissingDate,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { MonthAndDatePicker } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    pickerType:
      getTripCategory(state) === TripCategory.ROUND_TRIP
        ? PickerType.RANGE
        : PickerType.DAY,
    months: getDepartureMonths(state),
    priceTags: getPriceRangeLegends(state),
    currency: getCurrency(state),
    isMissingDate: getIsMissingDate(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MonthAndDatePickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMonthAndDatePicker = connector(MonthAndDatePicker);
