import { ReactElement, useCallback } from "react";
import { Dialog as MUIDialog, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink, CloseButtonIcon } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import { SEAT_MAP_OPTIONS, STYLE_STRING } from "./constants";
import Frame from "./Frame";
import { ISeatSelectionDialogInternalProps } from "./types";

const Dialog = ({
  seatMapHtml,
  setSelectedSeats,
  segmentIndex,
  seatMapOptions = SEAT_MAP_OPTIONS,
  styleString = STYLE_STRING,
  onEditMode,
  setOpen,
  open,
}: ISeatSelectionDialogInternalProps):
  | ReactElement<ISeatSelectionDialogInternalProps>
  | undefined => {
  const { t } = useI18nContext();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!open || !seatMapHtml) {
    return;
  }
  return (
    <MUIDialog
      open={open}
      onClose={closeModal}
      className={clsx("seat-map-wrapper")}
      role="dialog"
      aria-labelledby="choose-seats-modal"
    >
      <div className="header-content">
        <ActionLink
          className="seat-selection-map-close-button"
          content={<img src={CloseButtonIcon} alt={t("close.imageAlt")} />}
          label={t("close.button")}
          onClick={closeModal}
        />

        <Typography
          variant="h6"
          className="header-text"
          id="choose-seats-modal"
          tabIndex={-1}
        >
          {t("seats.chooseSeats")}
        </Typography>
      </div>
      <Frame
        seatMapHtml={seatMapHtml}
        segmentIndex={segmentIndex}
        onEditMode={onEditMode}
        styleString={styleString}
        seatMapOptions={seatMapOptions}
        setOpen={setOpen}
        setSelectedSeats={setSelectedSeats}
      />
    </MUIDialog>
  );
};

export default Dialog;
