import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState, getWatchDetails } from "@hopper-b2b/types";
import { ActionButton, B2BSpinner } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import * as textConstants from "../../../../constants";
import { DATE_FORMAT } from "../../../../../ItineraryList/constants";
import { FlightWatchCard } from "../../../FlightWatchCard/component";
import { StopWatchingModalContentConnectorProps } from "./container";
import "./styles.scss";

interface IStopWatchingModalContentProps
  extends StopWatchingModalContentConnectorProps {
  onClose: () => void;
  isMobile: boolean;
}

export const StopWatchingModalContent = ({
  openModal,
  isMobile,
  context,
  deleteWatch,
  deleteWatchCallState,
  onClose,
}: IStopWatchingModalContentProps) => {
  const { t } = useI18nContext();

  const watch = openModal.selectedItinerary as WatchAlertView;
  const watchDetails = useMemo(() => {
    return getWatchDetails(
      watch,
      context,
      (date: string) => dayjs(date).format(DATE_FORMAT),
      textConstants.getFilterLabel,
      t("watch.bookNowTagLabel"),
      t("watch.waitTagLabel")
    );
  }, [watch, context, t]);

  const stopWatchTitle = useMemo(() => {
    const watchDates = `(${
      watchDetails.endDate
        ? watchDetails.startDate + " - " + watchDetails.endDate
        : watchDetails.startDate
    })`;
    return watchDetails.title + " " + watchDates;
  }, [watchDetails]);

  const getModalContent = () => {
    switch (deleteWatchCallState) {
      case CallState.InProcess:
        return <B2BSpinner />;
      case CallState.Failed:
        return (
          <Fragment>
            <Typography className="stop-watching-title" variant="h2">
              {t("watch.errorModalTitle")}
            </Typography>
            <Box className="stop-watching-buttons">
              <ActionButton
                className={clsx("stop-watching-button", "b2b")}
                onClick={() => {
                  deleteWatch(openModal.selectedItinerary as WatchAlertView);
                }}
                message={t("retry")}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={t("cancel")}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </Fragment>
        );
      case CallState.Success:
        return (
          <Fragment>
            <Typography className="stop-watching-title" variant="h2">
              {stopWatchTitle}
            </Typography>
            <Typography className="stop-watching-subtitle" variant="body1">
              {t("watch.stopWatchSuccessModal")}
            </Typography>
            <Box className="stop-watching-buttons">
              <ActionButton
                className={clsx("cancel-button", "b2b")}
                onClick={() => onClose()}
                message={t("done")}
                defaultStyle="h4r-primary"
              />
            </Box>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Typography className="stop-watching-title" variant="h2">
              {t("watch.stopWatchModalTitle")}
            </Typography>
            <FlightWatchCard
              priceDropProtection={watch.priceDropProtection}
              isMobile={isMobile}
              {...watchDetails}
            />
            <Box
              className={clsx("stop-watching-buttons", { mobile: isMobile })}
            >
              <ActionButton
                className={clsx("stop-watching-button", "b2b")}
                onClick={() => {
                  deleteWatch(openModal.selectedItinerary as WatchAlertView);
                }}
                message={t("watch.stopWatchButtonLabel")}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={t("cancel")}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </Fragment>
        );
    }
  };
  return (
    <Box className="stop-watching-modal-content apac">{getModalContent()}</Box>
  );
};
