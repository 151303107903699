import { useCallback, useContext, useMemo } from "react";
import dayjs from "dayjs";
import clsx from "clsx";

import {
  FareDetails,
  TripDetails,
  TrackingEventEntryPoint,
} from "@hopper-b2b/types";
import {
  MobileFlightDetailsHalfSheet,
  MobileFlightDetailsModal,
  Slot,
} from "@hopper-b2b/ui";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { Airport } from "@b2bportal/air-shopping-api";
import {
  getEnvVariables,
  getPlusDays,
  getPlusDaysOnSliceWithDates,
  getSliceIndex,
} from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../../../App";
import "./styles.scss";
import { useHistory } from "react-router";
import {
  PATH_FLIGHTS_SEARCH,
  PATH_FLIGHTS_SHOP,
  PATH_HOME,
} from "@hopper-b2b/utilities";
import { useUpdateFlightShopStep } from "../../../../hooks";
import { FlightShopStep } from "../../../../reducer";

interface IMobileItineraryModalProps {
  isDeparture: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails | undefined;
  openModal: boolean;
  onClose: () => void;
  buttonText?: string;
  airports: { [key: string]: Airport };
  isMixedCabinClass?: boolean;
  onClick?: () => void;
}

export const MobileItineraryDetailsModal = (
  props: IMobileItineraryModalProps
) => {
  const {
    isDeparture,
    tripDetails,
    openModal,
    isMixedCabinClass,
    airports,
    onClose,
  } = props;
  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();
  const updateFlightStep = useUpdateFlightShopStep();

  const history = useHistory();

  const isHopper = getEnvVariables("clientName") === "hopper";

  const changeFlight = useCallback(() => {
    history.push(PATH_FLIGHTS_SEARCH);
  }, [history]);

  // this function makes you go to right step on mobile
  const handleChangeFlight = useCallback(
    (isOutbound: boolean) => {
      updateFlightStep(
        isOutbound
          ? FlightShopStep.ChooseDeparture
          : FlightShopStep.ChooseReturn,
        false,
        // need to pass flight shop path as new pathname in order to go back to shopping funnel
        PATH_HOME + PATH_FLIGHTS_SHOP
      );
    },
    [updateFlightStep]
  );

  if (!openModal) return null;

  const sliceIndex = tripDetails ? getSliceIndex(isDeparture, tripDetails) : 0;
  const tripSlice = tripDetails?.slices[sliceIndex];

  // TODO: VI team check if the selected fare is pass once we have more fares to choose from
  const selectedFare = tripDetails.fareDetails[0];

  const selectedFareSlice =
    selectedFare.slices[
      selectedFare.slices.findIndex((slice) =>
        isDeparture ? slice.outgoing : !slice.outgoing
      )
    ];

  const subtitle = `${tripSlice?.originCode} - ${tripSlice?.destinationCode}`;

  if (isHopper) {
    return (
      <MobileFlightDetailsHalfSheet
        {...props}
        departure={isDeparture}
        title={
          <I18nMarkup
            tKey={
              isDeparture
                ? "flightShopReview.outboundCardHeader"
                : "flightShopReview.returnCardHeader"
            }
            replacements={{
              location: tripSlice?.destinationCode,
              date: dayjs(tripSlice?.departureTime).format("ddd, MMM D"),
            }}
          />
        }
        plusDays={getPlusDays(tripSlice)}
        isMixedCabinClass={isMixedCabinClass}
        className={clsx(
          "b2b",
          "b2b-secondary",
          "mobile-itinerary-details-modal"
        )}
        airports={airports}
        onClose={onClose}
        assets={clientContext?.assets}
        buttonText={t("flightShopReview.changeFlight")}
        onClick={changeFlight}
      />
    );
  }

  return (
    <Slot
      id="mobile-flight-details-modal"
      isOutgoing={isDeparture}
      entryPoint={TrackingEventEntryPoint.Details}
      departureTime={
        isDeparture
          ? tripDetails.slices[0].departureTime
          : tripDetails.slices[tripDetails.slices.length - 1].departureTime
      }
      segments={
        isDeparture
          ? tripDetails.slices[0].segmentDetails
          : tripDetails.slices[tripDetails.slices.length - 1].segmentDetails
      }
      plusDays={getPlusDaysOnSliceWithDates(
        tripSlice.departureTime,
        tripSlice.arrivalTime
      )}
      fareSlice={selectedFareSlice}
      fareDetails={tripDetails.fareDetails}
      tripDetails={tripDetails}
      locationName={
        airports[tripSlice.destinationCode]
          ? airports[tripSlice.destinationCode].cityName
          : tripSlice.destinationName
      }
      onChangeFlight={handleChangeFlight}
      onClose={onClose}
      component={
        <MobileFlightDetailsModal
          {...props}
          departure={isDeparture}
          title={
            <I18nMarkup
              tKey={
                isDeparture
                  ? "flightShopReview.outboundCardHeader"
                  : "flightShopReview.returnCardHeader"
              }
              replacements={{
                location: tripSlice?.destinationCode,
                date: dayjs(tripSlice?.departureTime).format("ddd, MMM D"),
              }}
            />
          }
          subtitle={subtitle}
          plusDays={getPlusDays(tripSlice)}
          isMixedCabinClass={isMixedCabinClass}
          className={clsx(
            "b2b",
            "b2b-secondary",
            "mobile-itinerary-details-modal"
          )}
          airports={airports}
          onClose={onClose}
          assets={clientContext?.assets}
          buttonText={t("flightDetailsContinue")}
        />
      }
    />
  );
};
