import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import clsx from "clsx";

import { getDateFormat, useI18nContext } from "@hopper-b2b/i18n";
import {
  ErrorModal,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
  MobileLayout,
  DesktopLayout,
  FlightPassengerSelectorsV2,
  PassportSelectors,
  FlightPassengerEventTypesV2,
} from "@hopper-b2b/checkout";
import { Passport, Person } from "@b2bportal/air-booking-api";
import { B2BLoadingPopup, MobileFloatingButton } from "@hopper-b2b/ui";
import { BackButton } from "@hopper-b2b/ui";
import {
  ViewportType,
  getDateTimeWithFormat,
  useDeviceTypes,
  useVisualViewport,
} from "@hopper-b2b/utilities";

import { ReactComponent as Arrow } from "../../../../assets/client/arrow.svg";
import Error from "../../../../assets/client/error.svg";
import { PATH_FLIGHTS_SEARCH } from "../../../../utils/urlPaths";
import { Event, TEvent } from "../../events";
import {
  getClientAssets,
  getFlightSearchDepartureDate,
  getFlightSearchReturnDate,
} from "../../selectors";
import { ConnectedPriceBreakdownContent } from "../PriceBreakdownDropdown";
import { PassportInfoForm } from "../PassportInfoForm";
import "./styles.scss";

export const PassportInformation = () => {
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const send = useCheckoutSend<TEvent>();
  const preferredDateFormat = getDateFormat();

  const selectedPassengers = useSelector(
    FlightPassengerSelectorsV2.getAllSelectedUserPassengersParent
  );
  const isLoading = useSelector(PassportSelectors.getIsPassportLoading);
  const passportError = useSelector(PassportSelectors.getPassportError);
  const clientAssets = useSelector(getClientAssets);
  const departureDate = useSelector(getFlightSearchDepartureDate);
  const returnDate = useSelector(getFlightSearchReturnDate);

  const [userIndex, setUserIndex] = useState(0);
  const [passportInfo, setPassportInfo] = useState<Passport | undefined>(
    selectedPassengers[userIndex]?.passport || undefined
  );
  const [passportExpiryErrorMessage, setPassportExpiryErrorMessage] =
    useState("");
  const [passportNumberErrorMessage, setPassportNumberErrorMessage] =
    useState("");

  const handleGoBack = () => {
    history.goBack();
    send(FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR);
  };

  const updatePassengerWithPassport = (person: Person, onContinue?: boolean) =>
    send({
      type: onContinue
        ? Event.UPDATE_PASSPORT_AND_CONTINUE
        : Event.UPDATE_PASSENGER,
      person,
    });

  const isDisabled = useMemo(
    () =>
      !!passportExpiryErrorMessage ||
      !!passportNumberErrorMessage ||
      !passportInfo ||
      !passportInfo?.countryOfIssue ||
      !passportInfo?.expiration ||
      !passportInfo?.number,
    [passportExpiryErrorMessage, passportNumberErrorMessage, passportInfo]
  );

  const handleAddPassportInfo = useCallback(() => {
    const person = selectedPassengers[userIndex];
    if (isDisabled) {
      return null;
    }
    if (
      passportInfo.expiration &&
      (!person?.passport?.expiration ||
        passportInfo.expiration !== person?.passport?.expiration)
    ) {
      passportInfo.expiration = getDateTimeWithFormat(
        passportInfo.expiration,
        preferredDateFormat
      )
        .format("YYYY-MM-DD")
        .replace(/\//g, "-");
    }
    person.passport = passportInfo;

    if (userIndex < selectedPassengers.length - 1) {
      updatePassengerWithPassport(person);
      setPassportInfo(selectedPassengers[userIndex + 1].passport);
      setUserIndex(userIndex + 1);
    }
    if (userIndex === selectedPassengers.length - 1) {
      updatePassengerWithPassport(person, true);
      setPassportInfo(undefined);
      send(FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR);
    }
  }, [passportInfo, userIndex, selectedPassengers, isDisabled]);

  const clearError = () => send(Event.CLEAR_PASSPORT_ERROR);

  const searchAgain = () => {
    clearError();
    history.push(PATH_FLIGHTS_SEARCH);
  };

  const onVisualViewportChange = (difference: ViewportType) => {
    const elements = document.getElementsByClassName(
      "checkout-passport-information"
    ) as HTMLCollectionOf<HTMLDivElement>;
    const contentElementStyle = elements[0].style;
    contentElementStyle.paddingBottom = `${difference.height}px`;
  };

  useVisualViewport(onVisualViewportChange);

  if (isLoading) {
    return (
      <B2BLoadingPopup
        className="passport-information-loader"
        open={isLoading}
      />
    );
  }

  if (passportError) {
    return (
      <ErrorModal
        open={Boolean(passportError)}
        title={t("checkoutError.genericErrorTitle")}
        subtitle={t("checkoutError.genericErrorSubtitle")}
        primaryButtonText={t("checkoutError.searchAgain")}
        primaryOnClick={searchAgain}
        image={Error}
      />
    );
  }

  const renderBackButton = () => {
    return (
      <BackButton
        className="passport-form-popup-back-button"
        onClick={handleGoBack}
      />
    );
  };

  const content = (
    <>
      <PassportInfoForm
        isMobile={true}
        title={t("passportInfo.title")}
        subtitle={t("passportInfo.subtitle")}
        subtitle2={t("firstAndLastName", {
          index: userIndex + 1,
          totalPassengers: selectedPassengers.length,
          firstName: selectedPassengers[userIndex]?.givenName,
          lastName: selectedPassengers[userIndex]?.surname,
        })}
        passportInfo={passportInfo}
        onPassportInfoChange={(info: Passport) => setPassportInfo(info)}
        passportExpiryError={passportExpiryErrorMessage}
        setPassportExpiryError={setPassportExpiryErrorMessage}
        passportNumberError={passportNumberErrorMessage}
        setPassportNumberError={setPassportNumberErrorMessage}
        departureDate={departureDate}
        returnDate={returnDate}
      />
      <MobileFloatingButton
        className={`save-passport-button ${
          userIndex + 1 < selectedPassengers.length ? "next" : ""
        }`}
        disabled={isDisabled}
        onClick={handleAddPassportInfo}
      >
        {userIndex + 1 < selectedPassengers.length ? <Arrow /> : t("confirm")}
      </MobileFloatingButton>
    </>
  );

  return matchesMobile ? (
    <MobileLayout
      className={clsx("mobile", "checkout-passport-information")}
      title={t("passportInfo.passport")}
      topLeftButton={renderBackButton()}
      headerElement={t("passportInfo.passport")}
    >
      {content}
    </MobileLayout>
  ) : (
    <DesktopLayout
      clientLogo={clientAssets?.logo}
      rightContent={<ConnectedPriceBreakdownContent />}
    >
      {content}
    </DesktopLayout>
  );
};
