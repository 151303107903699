import { toByteArray, fromByteArray } from "base64-js";

const getAlgoEncrypt = (iv: string): AesGcmParams => ({
  name: "AES-GCM",
  iv: toByteArray(iv), // Cannot be reused with the same key, change key every time to avoid clashing iv and keys
  tagLength: 128,
});

const strToArrayBuffer = (str: string) => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

const getKeyMaterial = async (password: string) => {
  return await window.crypto.subtle.importKey(
    "raw", // format
    strToArrayBuffer(password), // keyData
    "PBKDF2", // algorithmIdentifier
    false, // extractable=false
    ["deriveBits", "deriveKey"] //keyUsages
  );
};

const deriveKey = async (
  password: string,
  salt: BufferSource
): Promise<CryptoKey> => {
  const keyMaterial = await getKeyMaterial(password);
  return await window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
};

export const encryptMessage = async (
  message: string,
  password: string,
  rawSalt: string,
  iv: string
) => {
  const key = await deriveKey(password, strToArrayBuffer(rawSalt));
  const encoded = await crypto.subtle.encrypt(
    getAlgoEncrypt(iv),
    key,
    strToArrayBuffer(message)
  );

  const encodedString = fromByteArray(new Uint8Array(encoded));
  return { encoded, encodedString };
};

export const decryptCipherText = async (
  cipherText: string,
  password: string,
  rawSalt: string,
  iv: string
) => {
  const decodedBuffer = toByteArray(cipherText);

  const key = await deriveKey(password, strToArrayBuffer(rawSalt));
  const decrypted = await crypto.subtle.decrypt(
    getAlgoEncrypt(iv),
    key,
    decodedBuffer
  );
  const dec = new TextDecoder("utf-8");
  return dec.decode(decrypted);
};

// Sample use
// ----------------
// const password = "test-password";
// const salt = "test-salt";

// encryptMessage("message", password, salt).then(({ encoded, encodedString }) => {
//   console.log(encodedString) // encodedString = "ER/ofTwub4L+n0Bqh7vdUPjWqwZ0mUU="
//   decryptCipherText(encodedString, password, salt).then((decoded) =>
//     console.log("Decrypted Data:", decoded) // decoded = "message"
//   );
// });
