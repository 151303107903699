import { useMemo } from "react";
import { Suggestion } from "@b2bportal/lodging-api";
import { ILodgingFilterState } from "@hopper-b2b/types";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { IndeterminateLoader, ShopHeaderText } from "@hopper-b2b/ui";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { AppliedFilterTags } from "../../AppliedFilterTags";
import { dateStringToNewDate } from "@hopper-b2b/utilities";
import { ViewOption } from "@hopper-b2b/lodging-utils";

export interface ShopHeaderProps {
  initialDestination: Suggestion;
  initialCheckinDate: string;
  initialCheckoutDate: string;
  openFiltersModal: () => void;
  filters: ILodgingFilterState;
  resetFilters: () => { type: string };
  deleteFilter: (key: string) => void;
  loading?: boolean;
  view?: ViewOption;
}

export const ShopHeader = ({
  initialDestination,
  initialCheckinDate,
  initialCheckoutDate,
  openFiltersModal,
  resetFilters,
  filters,
  deleteFilter,
  loading,
  view,
}: ShopHeaderProps) => {
  const { t, language: locale } = useI18nContext();

  const subheader = useMemo(
    () =>
      `${formatDateTime(
        dateStringToNewDate(initialCheckinDate),
        locale,
        DateTimeFormatStyle.Custom({
          month: "short",
          day: "2-digit",
        })
      )} - ${formatDateTime(
        dateStringToNewDate(initialCheckoutDate),
        locale,
        DateTimeFormatStyle.Custom({
          month: "short",
          day: "2-digit",
        })
      )}`,
    [initialCheckinDate, initialCheckoutDate, locale]
  );

  const headerContent: {
    tKey: string;
    replacements?: { [key: string]: unknown };
  } = useMemo(() => {
    if (initialDestination?.label) {
      return {
        tKey: "hotelsIn",
        replacements: {
          destination: initialDestination?.label,
        },
      };
    }
    if (!loading && (view === ViewOption.MAP || view === ViewOption.LIST)) {
      return {
        tKey: "hotelsOnMap",
      };
    }
    // we're still loading, don't know destination yet
    return {
      tKey: "hotels",
    };
  }, [initialDestination?.label, loading, view]);

  return (
    <>
      <AppliedFilterTags
        filters={filters}
        resetFilters={resetFilters}
        deleteFilter={deleteFilter}
      />
      {loading ? (
        <div className="Availability-loader">
          <IndeterminateLoader />
        </div>
      ) : null}
      <ShopHeaderText
        className="lodging-shop-header"
        header={<I18nMarkup className="shop-header-title" {...headerContent} />}
        subheaderText={subheader}
        ctaLabel={t("filter")}
        onClick={openFiltersModal}
      />
    </>
  );
};
