import { useI18nContext } from "@hopper-b2b/i18n";
import { DullesUta } from "@hopper-b2b/types";
import { IconComponent } from "@hopper-b2b/ui";
import { Grid, Typography } from "@material-ui/core";
import { ASSESSMENT_MAP, UTA_MAP } from "../constants";
import { getUtaOverride } from "../utils";

export type AmenityRowProps = {
  currentUta: DullesUta;
  targetUta: DullesUta;
};

export const AmenityRow = ({ currentUta, targetUta }: AmenityRowProps) => {
  const { t } = useI18nContext();
  const { Icon, label } = UTA_MAP[currentUta.category];
  return (
    <>
      <Grid item xs={6} className="upsell-modal-grid-row-description">
        <Icon />
        <Typography className="upsell-modal-grid-row-text">
          {t(label)}
        </Typography>
      </Grid>
      <Grid item xs={3} className="upsell-modal-grid-row-icon">
        {getUtaOverride(currentUta) ? (
          <Typography className="upsell-modal-grid-row-text">
            {t(getUtaOverride(currentUta))}
          </Typography>
        ) : (
          <IconComponent {...ASSESSMENT_MAP[currentUta.assessment]} />
        )}
      </Grid>
      <Grid item xs={3} className="upsell-modal-grid-row-icon">
        {getUtaOverride(targetUta) ? (
          <Typography className="upsell-modal-grid-row-text">
            {t(getUtaOverride(targetUta))}
          </Typography>
        ) : (
          <IconComponent {...ASSESSMENT_MAP[targetUta.assessment]} />
        )}
      </Grid>
    </>
  );
};
