import {
  Available,
  GetAirCfarOfferResponse,
  GetAirCfarOfferResponseEnum,
} from "@b2bportal/air-cfar-api";
import { fetchCfarOffers } from "@hopper-b2b/api";
import { put, select } from "redux-saga/effects";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { actions } from "../actions";

export function* fetchCfarOffersSaga(action: actions.IFetchCfarOffers) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];

    const response: GetAirCfarOfferResponse = yield fetchCfarOffers(
      {
        fareId: action.fareId,
        tripId: action.tripId,
      },
      apiConfig
    );

    if (
      response.GetAirCfarOfferResponse === GetAirCfarOfferResponseEnum.Available
    ) {
      const { offers, trackingProperties } = response as Available;

      yield put(
        actions.setCfarOffers({
          trackingProperties: trackingProperties,
          offers: offers,
        })
      );
    } else {
      yield put(actions.setCfarOffersFailed());
      console.warn("CFAR offers not available", response);
    }
  } catch (error) {
    yield put(actions.setCfarOffersFailed());
    console.error("Error fetching CFAR offers", error);
  }
}
