import {
  CardPaymentGuards,
  CartGuards,
  ContactInformationGuards,
  ReviewGuards,
  getObjectKeysAsObject,
  PassengerGuards,
  WalletGuards,
} from "@hopper-b2b/checkout";
import { commonGuards } from "./common";
import { NubankPaymentGuards } from "../../../checkout/states/payments";

export const Guards = {
  ...CartGuards,
  ...ContactInformationGuards,
  ...PassengerGuards,
  ...ReviewGuards,
  ...CardPaymentGuards,
  ...commonGuards,
  ...WalletGuards,
  ...NubankPaymentGuards,
};

export const GuardTypes = getObjectKeysAsObject(Guards);
