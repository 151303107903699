import { TripSlice } from "@b2bportal/air-shopping-api";
import { trackEvent } from "@hopper-b2b/api";
import { AirPriceFreezeExerciseTrackingEvents } from "@hopper-b2b/fintech";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightSummaryInfo,
  FrozenPricePaxPricing,
  PRICE_FREEZE_ID_QUERY_PARAM,
} from "@hopper-b2b/types";
import {
  MobileFloatingButton,
  ReviewSummaryRow,
  MobileFlightSummaryRowNew,
} from "@hopper-b2b/ui";
import { copyToClipboard } from "@hopper-b2b/utilities";

import { Divider } from "@material-ui/core";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ClientContext } from "../../../App";
import { PATH_BOOK } from "../../../utils/urlPaths";

import {
  getCurrentPriceFreezeTrackingProperties,
  getHasFetchedPriceFreeze,
} from "../reducer";

import { PriceFreezeOverviewPageConnectorProps } from "./container";
import "./styles.scss";

export type IPriceFreezeOverviewPageProps =
  PriceFreezeOverviewPageConnectorProps;

export const PriceFreezeOverviewPage = ({
  currentPriceFreezeOverview,
  fetchPriceFreeze,
}: IPriceFreezeOverviewPageProps) => {
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const history = useHistory();

  const hasFetchedPriceFreeze = useSelector(getHasFetchedPriceFreeze);
  const priceFreezeTrackingProperties = useSelector(
    getCurrentPriceFreezeTrackingProperties
  );
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const priceFreezeIdFromQuery = urlSearchParams.get(
      PRICE_FREEZE_ID_QUERY_PARAM
    );
    if (priceFreezeIdFromQuery) {
      fetchPriceFreeze(priceFreezeIdFromQuery);
    }
  }, [fetchPriceFreeze, history.location.search]);

  useEffect(() => {
    if (hasFetchedPriceFreeze)
      trackEvent({
        eventName:
          AirPriceFreezeExerciseTrackingEvents.B2B_VIEWED_AIR_PRICE_FREEZE,
        ...priceFreezeTrackingProperties,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedPriceFreeze]);

  const handleNext = useCallback(() => {
    trackEvent({
      eventName:
        AirPriceFreezeExerciseTrackingEvents.B2B_ENTERED_AIR_PRICE_FREEZE_EXERCISE_FLOW,
      ...priceFreezeTrackingProperties,
    });
    history.push(
      `${PATH_BOOK}?${PRICE_FREEZE_ID_QUERY_PARAM}=${currentPriceFreezeOverview.priceFreezeId}`
    );
  }, [
    currentPriceFreezeOverview.priceFreezeId,
    history,
    priceFreezeTrackingProperties,
  ]);

  const getFlightSummaryInfo = useCallback(
    (tripSlice: TripSlice): FlightSummaryInfo => {
      const flightSegment = tripSlice?.segmentDetails[0];
      const destinationCode = tripSlice?.destinationCode;
      const originCode = tripSlice?.originCode;
      const originLabel = currentPriceFreezeOverview?.airports
        ? currentPriceFreezeOverview?.airports[originCode].cityName
        : originCode;
      const destinationLabel = currentPriceFreezeOverview?.airports
        ? currentPriceFreezeOverview?.airports[destinationCode].cityName
        : destinationCode;

      return {
        originCity: originCode,
        destinationCity: destinationCode,
        departure: tripSlice.departureTime,
        arrival: tripSlice.arrivalTime,
        airlineCode: flightSegment.airlineCode,
        airlineName: flightSegment.airlineName,
        isDeparture: tripSlice.outgoing,
        title: t("originToDestination", {
          origin: originLabel,
          destination: destinationLabel,
          interpolation: { escapeValue: false },
        }),
        stops: tripSlice.stops,
      };
    },
    [currentPriceFreezeOverview?.airports, t]
  );

  const departureSlice = useMemo(
    () =>
      currentPriceFreezeOverview?.tripDetails?.slices?.find(
        (slice) => slice.outgoing
      ),
    [currentPriceFreezeOverview?.tripDetails?.slices]
  );

  const returnSlice = useMemo(
    () =>
      currentPriceFreezeOverview?.tripDetails?.slices?.find(
        (slice) => !slice.outgoing
      ),
    [currentPriceFreezeOverview?.tripDetails?.slices]
  );

  const expiryDateString = useMemo(
    () =>
      ` ${t("dateAtTime", {
        date: dayjs(currentPriceFreezeOverview.expiresAt).format("ddd, MMM D"),
        time: dayjs(currentPriceFreezeOverview.expiresAt).format("h:mm A"),
      })}.`,
    [currentPriceFreezeOverview.expiresAt, t]
  );

  const isPFBestPrice = useCallback(() => {
    const paxPricings: FrozenPricePaxPricing =
      currentPriceFreezeOverview?.selectedFare;

    if (paxPricings) {
      const originalAmount = paxPricings?.pricing?.originalAmount.fiat.value;
      const currentAmount = paxPricings?.pricing?.currentAmount.fiat.value;
      return originalAmount <= currentAmount;
    }
    return true;
  }, [currentPriceFreezeOverview?.selectedFare]);

  return (
    <div className="pf-overview-container">
      <div className="pf-overview-header">
        <p className="pf-overview-title">{t("tripPriceFreezeDetails.title")}</p>
        <p className="pf-overview-subtitle">
          {t("tripPriceFreezeDetails.subtitle")}
          <span>{expiryDateString}</span>
        </p>
      </div>

      <div className="pf-overview-price">
        <ReviewSummaryRow
          title={t("price")}
          iconSrc={clientContext.assets?.priceFreeze}
          className="pf-overview-price-container"
        >
          <div className="pf-overview-price-description">
            <p
              className={`pf-overview-price-current ${
                !isPFBestPrice() ? "pf-overview-price-best" : ""
              }`}
            >
              {t("tripPriceFreezeDetails.currentPrice")}:{" "}
              <span>
                {currentPriceFreezeOverview?.currentTripPricePerPax?.fiat}
              </span>
            </p>
            <p
              className={`pf-overview-price-charged ${
                isPFBestPrice() ? "pf-overview-price-best" : ""
              }`}
            >
              {t("tripPriceFreezeDetails.frozenPrice")}:{" "}
              <span>
                {currentPriceFreezeOverview?.frozenTripPricePerPax?.fiat}
              </span>
            </p>
          </div>
        </ReviewSummaryRow>
      </div>
      {currentPriceFreezeOverview.hasReachedFareCap ? (
        <p className="pf-overview-price-fareCap">
          <I18nMarkup
            tKey={"tripPriceFreezeDetails.fareCapReached"}
            replacements={{
              capAmount: currentPriceFreezeOverview.capAmount,
              chargedTripPricePerPax:
                currentPriceFreezeOverview.chargedTripPricePerPax?.fiat,
            }}
          />
        </p>
      ) : null}
      <Divider className="pf-overview-divider" />
      <div className="pf-overview-passengers">
        <ReviewSummaryRow
          title={t("tripPriceFreezeDetails.passengerTitle")}
          iconSrc={clientContext.assets?.passenger}
        >
          {t("passengerLabel.count", {
            count: currentPriceFreezeOverview.totalPassengers,
          })}
        </ReviewSummaryRow>
      </div>
      <Divider className="pf-overview-divider" />
      <div className="pf-overview-flights">
        {departureSlice ? (
          <MobileFlightSummaryRowNew
            flightSummaryInfo={getFlightSummaryInfo(departureSlice)}
            iconSrc={clientContext.assets?.airplaneDepart}
          />
        ) : null}
        {returnSlice ? (
          <MobileFlightSummaryRowNew
            flightSummaryInfo={getFlightSummaryInfo(returnSlice)}
            iconSrc={clientContext.assets?.airplaneArrive}
          />
        ) : null}
      </div>
      <Divider className="pf-overview-divider" />
      <div className="pf-overview-confirmation">
        <ReviewSummaryRow
          title={t("tripPriceFreezeDetails.confirmationTitle")}
          iconSrc={clientContext.assets?.info}
          onClick={() =>
            currentPriceFreezeOverview?.confirmationNumber &&
            copyToClipboard(currentPriceFreezeOverview.confirmationNumber)
          }
          buttonLabel={t("tripPriceFreezeDetails.copy")}
        >
          {currentPriceFreezeOverview.confirmationNumber}
        </ReviewSummaryRow>
      </div>
      <Divider className="pf-overview-divider" />
      <MobileFloatingButton
        className="pf-overview-button"
        wrapperClassName="pf-overview-button-wrapper"
        onClick={handleNext}
        wide
      >
        {t("tripPriceFreezeDetails.continueCta")}
      </MobileFloatingButton>
    </div>
  );
};
