import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useState } from "react";
import { FintechHalfSheet } from "../FintechHalfSheet";
import { FintechHalfSheetProps } from "../FintechHalfSheet/FintechHalfSheet";
import { FintechOption, FintechOptionProps } from "../FintechOption";
import { FintechOptionCardProps } from "../types";
import { Product } from "@b2bportal/purchase-api";
import { DisruptionProductType } from "@hopper-b2b/types";

export type SingleOptionFintechCardProps = {
  pricePerPax: string;
  icons: [string, string];
  onShow: (show: boolean) => void;
} & Pick<
  FintechOptionProps,
  "title" | "description" | "checked" | "setChecked" | "iconName"
> &
  Pick<FintechHalfSheetProps, "product"> &
  Omit<FintechOptionCardProps, "onChange">;

export const SingleOptionFintechCard = ({
  title,
  description,
  checked,
  setChecked,
  iconName,
  pricePerPax,
  product,
  icons,
  disabled,
  onShow,
}: SingleOptionFintechCardProps) => {
  const { t } = useI18nContext();

  const [showHalfSheet, setShowHalfSheet] = useState(false);

  const toggleShowHalfSheet = useCallback(() => {
    onShow(!showHalfSheet);
    setShowHalfSheet(!showHalfSheet);
  }, [onShow, showHalfSheet]);

  const localizeKeyPrefix = mapProductToLocalieKeyPrefix[product];

  return (
    <>
      <FintechOption
        title={title}
        description={description}
        checked={checked}
        setChecked={setChecked}
        disabled={disabled}
        iconName={iconName}
        openInfoSheet={toggleShowHalfSheet}
        pricePerPax={`${pricePerPax}/${t("traveler")}`}
        product={product}
      />
      <FintechHalfSheet
        open={showHalfSheet}
        product={product}
        header={t(`fintechSelection.${localizeKeyPrefix}Title`)}
        onClose={toggleShowHalfSheet}
        onAddProduct={() => setChecked(true)}
        content={{
          description: t(
            `fintechSelection.${localizeKeyPrefix}SheetDescription`
          ),
          reasons: [
            {
              icon: icons[0],
              item: t(`fintechSelection.${localizeKeyPrefix}Reason1`),
            },
            {
              icon: icons[1],
              item: t(`fintechSelection.${localizeKeyPrefix}Reason2`),
            },
          ],
          buttonText: `${t("fintechSelection.addToTrip")}${
            pricePerPax ? ` • ${pricePerPax}` : ""
          }`,
        }}
      />
    </>
  );
};

const mapProductToLocalieKeyPrefix = {
  [Product.AirCfar]: "cfar",
  [Product.AirChfar]: "chfar",
  [DisruptionProductType.MissedConnection]: "missedConnection",
  [DisruptionProductType.ScheduleChange]: "scheduleChange",
};
