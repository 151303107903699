import { useI18nContext } from "@hopper-b2b/i18n";
import { FareDetails, TripSummary } from "@hopper-b2b/types";
import { B2BSpinner, LoadingIndicator } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DESKTOP_OFFSET_SCROLL,
  MOBILE_OFFSET_SCROLL,
  ModalTypes,
} from "../../component";
import { FlightDetails } from "../FlightDetails";
import { FlightListInfo } from "../FlightListInfo";
import { FlightCardType } from "../FlightListInfo/component";

interface FlightListProps {
  expandedFlight: string;
  flightInfoById: (tripId: string) => TripSummary;
  flightsToShow: string[];
  isReturn: boolean;
  onExpandItem: (tripId: string) => void;
  onFareSelectClick: (
    tripId: string,
    sliceId: string,
    fare?: FareDetails
  ) => void;
  setFetchMoreData: () => void;
  sortedFlightIds: string[];
  onFlightRowFareClick: (fareId: string) => void;
  tripDetailsLoading: boolean;
  selectedFareId: string;
  handleClickAlgomerchTag: (tagText: string) => void;
  setOpenModal: (val: ModalTypes) => void;
}
const FlightList = ({
  expandedFlight,
  flightInfoById,
  flightsToShow,
  isReturn,
  onExpandItem,
  onFareSelectClick,
  setFetchMoreData,
  sortedFlightIds,
  onFlightRowFareClick,
  tripDetailsLoading,
  selectedFareId,
  handleClickAlgomerchTag,
  setOpenModal,
}: FlightListProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  return (
    <InfiniteScroll
      dataLength={flightsToShow.length}
      next={setFetchMoreData}
      hasMore={flightsToShow.length < sortedFlightIds.length}
      loader={
        <Box className="loading-flights">
          <B2BSpinner classes={["loading-flights-bunny"]} />
        </Box>
      }
    >
      {flightsToShow.map((tripId) => (
        <Box
          id={tripId}
          className={clsx("flight-list-item", "flight-row", "b2b")}
        >
          <FlightListInfo
            id={tripId}
            key={tripId}
            tripId={tripId}
            onFareClick={(fareId) => {
              onFlightRowFareClick(fareId);
            }}
            type={FlightCardType.content}
            isExpanded={tripId === expandedFlight}
            onClick={() => {
              onExpandItem(tripId);
              setTimeout(() => {
                const OFFSET = matchesMobile
                  ? MOBILE_OFFSET_SCROLL
                  : DESKTOP_OFFSET_SCROLL;
                const cardTop =
                  document?.getElementById(tripId)?.getBoundingClientRect()
                    .top || 0;
                window.scrollBy({
                  top: (cardTop as number) - OFFSET,
                  behavior: matchesMobile ? "auto" : "smooth",
                });
              }, 100);
            }}
          />
          {tripId === expandedFlight && !tripDetailsLoading && (
            <FlightDetails
              tripId={tripId}
              isOutgoing={!isReturn}
              selectedFareId={selectedFareId}
              onFareClick={(sliceId, fare) =>
                onFareSelectClick(tripId, sliceId, fare)
              }
              onAlgomerchClick={(label: string) => {
                handleClickAlgomerchTag(label);
                setOpenModal(ModalTypes.AlgomerchModal);
              }}
            />
          )}
          {tripId === expandedFlight && tripDetailsLoading && (
            <LoadingIndicator
              className="flight-shop-details-loading-indicator"
              indicatorSize={"small"}
              indicator={B2BSpinner}
              message={t("loadingFlightDetails")}
            />
          )}
        </Box>
      ))}
    </InfiniteScroll>
  );
};

export default FlightList;
