import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import {
  IApiConfig,
  LAUNCHED_APPLICATION,
  LaunchedApplicationProperties,
} from "@hopper-b2b/types";
import { useEffect, useState } from "react";

export const useLaunchedApplicationEvent = (
  state: boolean,
  properties: LaunchedApplicationProperties,
  trackEvent: (req: TrackEventRequest, config?: IApiConfig) => Promise<boolean>,
  extraProperties?: { [key: string]: unknown }
): boolean => {
  const [launchEventSent, setLaunchEventSent] = useState(state);

  useEffect(() => {
    if (!launchEventSent) {
      trackEvent({
        eventName: LAUNCHED_APPLICATION,
        properties: {
          ...properties,
          ...extraProperties,
        },
      });
      setLaunchEventSent(true);
    }
  }, [extraProperties, launchEventSent, properties, trackEvent]);

  return launchEventSent;
};
