import { trackEditTravelers } from "./trackEditTravelers";
import { trackNewTraveler } from "./trackNewTraveler";
import { trackReviewDetails } from "./trackReviewDetails";
import { trackViewPayment } from "./trackViewPayment";

export const commonServices = {
  trackReviewDetails,
  trackViewPayment,
  trackEditTravelers,
  trackNewTraveler,
};
