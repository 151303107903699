import { DisruptionOffer } from "@hopper-b2b/types";
import * as actionTypes from "./constants";

export interface ISetDisruptionOffers {
  type: actionTypes.SET_DISRUPTION_OFFERS;
  offers?: DisruptionOffer[];
}

export const setDisruptionOffers = (args: {
  offers?: DisruptionOffer[];
}): ISetDisruptionOffers => ({
  type: actionTypes.SET_DISRUPTION_OFFERS,
  ...args,
});

export interface IFetchDisruptionOffers {
  type: actionTypes.FETCH_DISRUPTION_OFFERS;
}

export const fetchDisruptionOffers = (): IFetchDisruptionOffers => ({
  type: actionTypes.FETCH_DISRUPTION_OFFERS,
});

export interface ISetDisruptionOffersFailed {
  type: actionTypes.SET_DISRUPTION_OFFERS_FAILED;
}

export const setDisruptionOffersFailed = (): ISetDisruptionOffersFailed => ({
  type: actionTypes.SET_DISRUPTION_OFFERS_FAILED,
});

export interface ISetSelectedScheduleChangeOffer {
  type: actionTypes.SET_SELECTED_SCHEDULE_CHANGE_OFFER;
  offer?: DisruptionOffer;
}

export const setSelectedScheduleChangeOffer = (
  offer?: DisruptionOffer
): ISetSelectedScheduleChangeOffer => ({
  type: actionTypes.SET_SELECTED_SCHEDULE_CHANGE_OFFER,
  offer,
});

export interface ISetSelectedMissedConnectionOffer {
  type: actionTypes.SET_SELECTED_MISSED_CONNECTION_OFFER;
  offer?: DisruptionOffer;
}

export const setSelectedMissedConnectionOffer = (
  offer?: DisruptionOffer
): ISetSelectedMissedConnectionOffer => ({
  type: actionTypes.SET_SELECTED_MISSED_CONNECTION_OFFER,
  offer,
});

export type FlightDisruptionActions =
  | ISetDisruptionOffers
  | IFetchDisruptionOffers
  | ISetDisruptionOffersFailed
  | ISetSelectedScheduleChangeOffer
  | ISetSelectedMissedConnectionOffer;
