import { combineServices } from "../../../../../helpers";
import {
  ChildMachineConfig,
  ParentState,
  PassengerInformationChildState,
  PassengerInformationState,
  PassportState,
} from "../../../../../types/common";

import { ActionTypes } from "./actions";
import { FlightPassengerEventTypesV2 } from "./events";
import { FlightServices } from "../../index";
import { GuardTypes } from "./guards";
import { services, ServiceTypes } from "./services";
import { ReviewGuardTypes } from "../../../../common/Review";

export const getMachineStateV2 = (config: ChildMachineConfig) => ({
  id: ParentState.passengerInformation,
  initial: PassengerInformationState.loading,
  states: {
    [PassengerInformationState.loading]: {
      initial: PassengerInformationChildState.fetch,
      states: {
        [PassengerInformationChildState.fetch]: {
          invoke: {
            src: ServiceTypes.fetchUserPassengersService,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.loading}.${PassengerInformationChildState.route}`,
              actions: [
                ActionTypes.setUserPassengers,
                ActionTypes.setSingleDefaultPassenger,
              ],
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.error}`,
              actions: ActionTypes.setPassengersError,
            },
          },
        },
        [PassengerInformationChildState.route]: {
          always: [
            {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
              cond: GuardTypes.haveAnyPassengersLoaded,
            },
            {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
            },
          ],
        },
      },
    },
    [PassengerInformationState.passengerPicker]: {
      initial: PassengerInformationChildState.idle,
      states: {
        [PassengerInformationChildState.idle]: {},
        [PassengerInformationChildState.delete]: {
          invoke: {
            src: ServiceTypes.deleteUserPassengersService,
            onDone: {
              actions: ActionTypes.handleDeletePassenger,
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.idle}`,
            },
            onError: {
              actions: ActionTypes.setPassengersError,
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.error}`,
            },
          },
        },
        [PassengerInformationChildState.validate]: {
          invoke: {
            src: (ctx, e) =>
              combineServices([
                services.validateUserPassengersService(ctx, e),
                FlightServices.fetchTripPricingService(ctx),
              ]),
            onDone: [
              {
                actions: [
                  ActionTypes.onFetchTripPricing,
                  ActionTypes.setValidated,
                ],
                target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.idle}`,
              },
            ],
            onError: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.error}`,
              actions: [
                ActionTypes.dismissNumPassengerAlert,
                ActionTypes.onValidateAndTripPricingError,
              ],
            },
          },
        },
      },
      on: {
        [FlightPassengerEventTypesV2.SELECT_PASSENGER]: [
          {
            actions: [ActionTypes.resetValidated, ActionTypes.selectPassenger],
            target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.validate}`,
            cond: GuardTypes.willHaveSelectedPassengers,
          },
          {
            actions: ActionTypes.selectPassenger,
          },
        ],
        [FlightPassengerEventTypesV2.NEXT]: {
          target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.validate}`,
          cond: GuardTypes.hasAnySelectedPassengers,
        },
      },
    },
    [PassengerInformationState.passengerForm]: {
      initial: PassengerInformationChildState.route,
      states: {
        [PassengerInformationChildState.route]: {
          always: [
            {
              cond: GuardTypes.hasCurrentUserPassenger,
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.edit}`,
            },
            {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.new}`,
            },
          ],
        },
        [PassengerInformationChildState.edit]: {
          invoke: {
            src: ServiceTypes.trackEditTravelers,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.idle}`,
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.idle}`,
            },
          },
        },
        [PassengerInformationChildState.new]: {
          invoke: {
            src: ServiceTypes.trackNewTraveler,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.idle}`,
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.idle}`,
            },
          },
        },
        [PassengerInformationChildState.idle]: {},
        [PassengerInformationChildState.update]: {
          invoke: {
            src: ServiceTypes.updateUserPassengerService,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.validate}`,
              actions: [
                ActionTypes.setUserPassengers,
                ActionTypes.selectPassenger,
              ],
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.error}`,
              actions: ActionTypes.setPassengersError,
            },
          },
        },
      },
      on: {
        [FlightPassengerEventTypesV2.UPDATE_PASSENGER]: {
          target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}.${PassengerInformationChildState.update}`,
        },
      },
    },
    [PassengerInformationState.validate]: {
      invoke: {
        src: (ctx, e) =>
          combineServices([
            services.validateUserPassengersService(ctx, e),
            FlightServices.fetchTripPricingService(ctx),
          ]),
        onDone: [
          {
            cond: ReviewGuardTypes.visitedReview,
            target: config.nextStateFromReview || ParentState.cartQuote,
            actions: [ActionTypes.onFetchTripPricing, ActionTypes.setValidated],
          },
          {
            target: config.nextState || ParentState.passengerInformation,
            actions: [ActionTypes.onFetchTripPricing, ActionTypes.setValidated],
          },
        ],
        onError: {
          target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.error}`,
          actions: [
            ActionTypes.dismissNumPassengerAlert,
            ActionTypes.onValidateAndTripPricingError,
            ActionTypes.resetValidated,
          ],
        },
      },
    },
    [PassengerInformationState.error]: {},
  },
  on: {
    [FlightPassengerEventTypesV2.DELETE_PASSENGER]: {
      target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.delete}`,
    },
    [FlightPassengerEventTypesV2.SET_CURRENT_PASSENGER]: {
      actions: ActionTypes.setCurrentPassenger,
    },
    [FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR]: {
      actions: ActionTypes.clearPassengerInformationError,
      target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
    },
    [FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER]: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,

    [FlightPassengerEventTypesV2.OPEN_PASSENGER_FORM]: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
    [FlightPassengerEventTypesV2.CLEAR_CURRENT_INFANT_ID]: {
      actions: ActionTypes.clearCurrentInfantId,
    },
    [FlightPassengerEventTypesV2.ON_INFANT_MODAL_CONTINUE]: {
      actions: ActionTypes.onInfantModalContinue,
      target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}.${PassengerInformationChildState.validate}`,
    },
    [FlightPassengerEventTypesV2.OPEN_FORM_AND_SET_PASSENGER]: {
      actions: ActionTypes.setCurrentPassenger,
      target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerForm}`,
    },
    [FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM]: `#${config.parentMachineId}.${ParentState.passport}.${PassportState.idle}`,

    [FlightPassengerEventTypesV2.PREVIOUS]: {
      target: config?.previousState || ParentState.contactInformation,
    },
    [FlightPassengerEventTypesV2.COMPLETE]: [
      {
        cond: GuardTypes.notValidated,
        target: `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.validate}`,
      },
      {
        cond: ReviewGuardTypes.visitedReview,
        target: config.nextStateFromReview || ParentState.cartQuote,
      },
      {
        target: config.nextState || ParentState.passengerInformation,
      },
    ],
  },
});
