import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BSpinner, LoadingIndicator } from "@hopper-b2b/ui";

import styles from "./DisruptionLoading.module.scss";

export const DisruptionLoading = () => {
  const { t } = useI18nContext();
  return (
    <LoadingIndicator
      className={styles.loader}
      indicatorSize={"small"}
      indicator={B2BSpinner}
      message={t("loading")}
    />
  );
};
