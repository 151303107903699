import { useState } from "react";
import { ActionButton, ButtonWrap } from "@hopper-b2b/ui";
import styles from "./styles.module.scss";
import { useHistory } from "react-router";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  useCheckoutStateSelector as useSelector,
  useCheckoutSend,
} from "@hopper-b2b/checkout";
import { ReactComponent as ArrowBack } from "../../../../../assets/client/arrow-back.svg";
import { ReactComponent as NuPayLogo } from "../../../../../assets/client/nupay-logo.svg";
import { getSelectedCreditInstallment } from "../../../../states/payments/selectors";
import { wallStreetAmountToFiatPrice } from "../../../../states/payments/helpers";
import { PaymentEvent } from "../../../../states/payments/events";
import { ConfirmPaymentModal } from "../ConfirmPaymentModal";
import clsx from "clsx";

export const CreditReviewScreen = () => {
  const history = useHistory();
  const send = useCheckoutSend();
  const { t, formatFiatCurrency } = useI18nContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const selectedInstallment = useSelector(getSelectedCreditInstallment);

  //TODO(figure out interest rate)
  const interestRate = (0).toFixed(2);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className={styles.creditReviewScreen}>
        <div className="nupay-header">
          <ButtonWrap className="back-button" onClick={handleGoBack}>
            <ArrowBack className="arrow-back-icon" />
          </ButtonWrap>
          <NuPayLogo className="nupay-logo" />
        </div>
        <div className="content">
          <div className="title-container">
            <h1 className="header">{t("nupay.creditReviewTitle")}</h1>
          </div>
          <div className="detail-row">
            <p className="label">{t("nupay.reviewTo")}</p>
            <p className="info">{t("nupay.reviewToName")}</p>
          </div>
          <div className="detail-row">
            <p className="label">{t("nupay.paymentMethodLabel")}</p>
            <p className="info">{t("nupay.paymentMethodCredit")}</p>
            <ActionButton
              className="detail-button"
              message={t("change")}
              onClick={() => send(PaymentEvent.GO_TO_PAYMENT_METHOD)}
            />
          </div>
          <div className="detail-row no-border">
            <p className="label">{t("nupay.numberOfInstallmentsLabel")}</p>
            <p className="info">
              {t("nupay.numberOfInstallments", {
                installment: selectedInstallment?.numberOfInstallments,
                price: formatFiatCurrency(
                  wallStreetAmountToFiatPrice(selectedInstallment?.amount)
                ),
              })}
            </p>
            <ActionButton
              className="detail-button"
              message={t("change")}
              onClick={() => send(PaymentEvent.GO_TO_CREDIT)}
            />
          </div>
          <div className="price-row">
            <p className="label">{t("nupay.purchasePrice")}</p>
            <p className="price">
              {formatFiatCurrency(
                wallStreetAmountToFiatPrice(selectedInstallment?.amount) // TODO: Total base price without fees/taxes/interest
              )}
            </p>
          </div>
          <div className="price-row">
            <p className="label">
              {t("nupay.interestLabel", {
                rate: interestRate,
              })}
            </p>
            <p className="price">
              {/* TODO: INTEREST GOES HERE */}
              {`+ ${formatFiatCurrency({
                value: 0,
                currencyCode: "BRL",
              })}`}{" "}
            </p>
          </div>
          <div className="price-row">
            <p className="label">{t("nupay.taxLabel")}</p>
            <p className="price">
              {/* TODO: TAX GOES HERE */}
              {`+ ${formatFiatCurrency({
                value: 0,
                currencyCode: "BRL",
              })}`}{" "}
            </p>
          </div>
          <div className="price-row">
            <p className="label strong">{t("nupay.totalToPay")}</p>
            <p className="price strong">
              {formatFiatCurrency(
                wallStreetAmountToFiatPrice(selectedInstallment?.amount) //TODO: Total amount to pay. i.e. sum of all installment amounts
              )}
            </p>
          </div>
          <div className="disclaimer-container">
            <p className="disclaimer-text">{t("nupay.reviewDisclaimer")}</p>
          </div>
        </div>
        <div className="bottom-floating-section">
          <div className="price-summary-section">
            <p className="price-text">
              {formatFiatCurrency(
                wallStreetAmountToFiatPrice(selectedInstallment?.amount)
              )}
            </p>

            <p className="installment-text">
              {t("nupay.numberOfInstallments", {
                installment: selectedInstallment?.numberOfInstallments,
                price: formatFiatCurrency(
                  wallStreetAmountToFiatPrice(selectedInstallment?.amount)
                ),
              })}
            </p>
          </div>
          <div className="button-section">
            <ActionButton
              className={clsx("continue-button", "b2b")}
              onClick={handleContinue}
              message={t("nupay.reviewScreenButton")}
            />
          </div>
        </div>
      </div>
      <ConfirmPaymentModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  );
};
