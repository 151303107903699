export enum FintechMerchandisingTrackingEvents {
  VIEWED = "b2b_fintech_merchandising_viewed",
  DECLINE_ALL = "b2b_fintech_merchandising_decline_all",
  CONTINUE_CLICKED = "b2b_fintech_merchandising_continue",
}

export type FintechMerchandisingViewedEventProperties = {
  hasCfar: boolean;
  hasChfar: boolean;
  hasDisruption: boolean;
  hasMissedConnection: boolean;
};

export interface FintechMerchandsingContinueEvent {
  eventName: FintechMerchandisingTrackingEvents.CONTINUE_CLICKED;
  properties: {
    added_cfar: boolean;
    added_disruption: boolean;
    added_missed_connection: boolean;
    added_chfar: boolean;
  };
}
