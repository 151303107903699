import { Airport } from "@b2bportal/air-shopping-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, FareDetails, TripDetails } from "@hopper-b2b/types";
import { ActionLink, AirlineIcon, Slot } from "@hopper-b2b/ui";
import {
  formatInterval,
  getEnvVariables,
  getIsMixedClass,
  getSliceIndex,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FlightShopStep } from "../../reducer";
import { ExpandableFlightCard } from "../ExpandableFlightCard/ExpandableFlightCard";
import { MobileItineraryDetailsModal } from "../FlightShopReviewItinerary/components/MobileItineraryDetailsModal";
import { DesktopFlightReviewDetailsPopover } from "./components";
import { FlightShopSummaryPanelConnectorProps } from "./container";
import "./styles.scss";

const EXPANDABLE_CARD_KEY = "flight-summary-expandable-card";

export interface IFlightShopSummaryPanelProps
  extends FlightShopSummaryPanelConnectorProps {
  // note: when both are set to true, nothing would be shown
  showDepartureReviewOnly?: boolean;
  showReturnReviewOnly?: boolean;
  showViewDetailsSection?: boolean;
  prevPath?: string;
  disableChangeFlight?: boolean;
}

export const FlightShopSummaryPanel = ({
  tripDetails,
  fareDetails,
  showDepartureReviewOnly,
  showReturnReviewOnly,
  showViewDetailsSection,
  airports,
  prevPath,
  populateFlightShopQueryParams,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  disableChangeFlight,
}: IFlightShopSummaryPanelProps) => {
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();

  const handleChangeFlight = useCallback(
    (step: FlightShopStep) => () => {
      populateFlightShopQueryParams({
        history,
        prevPath,
        useHistoryPush: true,
        forceQueryUpdate: true,
        newQueryParams: {
          flightShopProgress: step,
        },
      });
    },
    [history, populateFlightShopQueryParams, prevPath]
  );

  const departureSliceIdx = getSliceIndex(true, tripDetails);
  const hasDepartureFlight = departureSliceIdx !== -1;
  const hasReturnFlight = getSliceIndex(false, tripDetails) !== -1;

  const showDepartureFlight = hasDepartureFlight && !showReturnReviewOnly;
  const showReturnFlight = hasReturnFlight && !showDepartureReviewOnly;
  const clientName = getEnvVariables("clientName");
  const expandableCard =
    clientName === ClientName.HOPPER ||
    clientName === ClientName.VOLARIS ||
    clientName === ClientName.FLAIR ||
    clientName === ClientName.LLOYDS;

  const tripSlice = tripDetails?.slices[departureSliceIdx];

  if (!tripDetails) return null;

  return expandableCard && !matchesMobile ? (
    <ExpandableFlightCard
      cardKey={EXPANDABLE_CARD_KEY}
      departure={true}
      tripDetails={tripDetails}
      fareDetails={fareDetails}
      airports={airports}
      showChangeFlight={true}
    />
  ) : (
    <Box
      className={clsx("flight-shop-summary-panel-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="flight-shop-summary-panel-container">
        <Box
          className={clsx("itinerary-cards-section", {
            "only-display-one":
              (showDepartureFlight && !showReturnFlight) ||
              (!showDepartureFlight && showReturnFlight),
          })}
        >
          {showDepartureFlight ? (
            <Box
              className={clsx("flight-shop-summary-panel-card", "departure", {
                mobile: matchesMobile,
              })}
            >
              <Slot
                id="flight-shop-summary-panel"
                tripSlice={tripSlice}
                airports={airports}
                component={
                  <FlightSummaryPanel
                    departure={true}
                    tripDetails={tripDetails}
                    airports={airports}
                  />
                }
              />
              {showViewDetailsSection && fareDetails && (
                <FlightReviewDetailsPopover
                  departure
                  tripDetails={tripDetails}
                  fareDetails={fareDetails}
                  isMobile={matchesMobile}
                  isMultiTicket={isOutgoingMultiTicket}
                  onClickEdit={
                    !disableChangeFlight
                      ? handleChangeFlight(FlightShopStep.ChooseDeparture)
                      : undefined
                  }
                  airports={airports}
                />
              )}
            </Box>
          ) : null}
          {showReturnFlight ? (
            <Box
              className={clsx("flight-shop-summary-panel-card", "return", {
                mobile: matchesMobile,
              })}
            >
              <Slot
                id="flight-shop-summary-panel"
                tripSlice={tripSlice}
                airports={airports}
                component={
                  <FlightSummaryPanel
                    departure={true}
                    tripDetails={tripDetails}
                    airports={airports}
                  />
                }
              />
              {showViewDetailsSection && fareDetails && (
                <FlightReviewDetailsPopover
                  departure={false}
                  tripDetails={tripDetails}
                  fareDetails={fareDetails}
                  isMobile={matchesMobile}
                  isMultiTicket={isReturnMultiTicket}
                  onClickEdit={
                    !disableChangeFlight
                      ? handleChangeFlight(FlightShopStep.ChooseReturn)
                      : undefined
                  }
                  airports={airports}
                />
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

interface FlightShopPanelProps {
  departure: boolean;
  tripDetails: TripDetails;
  airports: { [key: string]: Airport };
}

const FlightSummaryPanel = ({
  departure,
  tripDetails,
  airports,
}: FlightShopPanelProps) => {
  const { t } = useI18nContext();
  const sliceIndex = getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];

  return (
    <Box className="airline-details-with-title">
      <Typography className="card-header">
        <I18nMarkup
          tKey={
            departure
              ? "flightShopReview.departureCardHeader"
              : "flightShopReview.returnCardHeader"
          }
          replacements={{
            location: airports[tripSlice.destinationCode]
              ? airports[tripSlice.destinationCode].cityName
              : tripSlice.destinationName,
            date: dayjs(removeTimezone(tripSlice.departureTime)).format(
              "ddd, MMM D"
            ),
          }}
        />
      </Typography>
      <Box className="airline-details">
        <Box className="airline-details-left-container">
          <Typography variant="body1" className="flight-timespan">
            {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
              "h:mm A"
            )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
              "h:mm A"
            )}`}
          </Typography>
          <Box className="card-airline-container">
            <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
            <Typography variant="body2">
              {firstTripSegment.airlineName}
            </Typography>
          </Box>
        </Box>
        <Box className="airline-details-right-container">
          <Typography variant="body1" className="flight-timespan">
            {formatInterval(
              dayjs(tripSlice.arrivalTime).diff(
                dayjs(tripSlice.departureTime),
                "minute",
                true
              )
            )}
          </Typography>
          <Typography variant="body2">
            {tripSlice.stops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: tripSlice.stops })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface IFlightReviewDetailsPopoverProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMobile: boolean;
  isMultiTicket: boolean;
  onClickEdit?: () => void;
  airports: { [key: string]: Airport };
}

const FlightReviewDetailsPopover = (
  props: IFlightReviewDetailsPopoverProps
) => {
  const {
    departure,
    tripDetails,
    fareDetails,
    isMobile,
    onClickEdit,
    isMultiTicket,
    airports,
  } = props;

  const { t } = useI18nContext();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);

  useEffect(() => {
    if (fareDetails) {
      departure
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [departure, fareDetails]);

  return (
    <Box className="flight-summary-panel-flight-review-details-root">
      <Box className="flight-summary-panel-flight-review-details-container">
        {isMobile ? (
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => setOpenPopover(true)}
            icon={faChevronRight as IconProp}
          />
        ) : null}
        {!isMobile ? (
          <>
            <ActionLink
              className="open-popover-button"
              onClick={() => setOpenPopover(true)}
              content={t("viewDetails")}
            />
            {onClickEdit ? (
              <>
                <Typography className="button-separator">|</Typography>
                <ActionLink
                  className="edit-flights-button"
                  onClick={onClickEdit}
                  content={t("change")}
                />
              </>
            ) : null}
          </>
        ) : null}
        {isMobile ? (
          <MobileItineraryDetailsModal
            openModal={openPopover}
            isDeparture={departure}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            isMixedCabinClass={isMixedCabinClass}
            airports={airports}
            onClose={() => setOpenPopover(false)}
          />
        ) : null}
        {!isMobile ? (
          <DesktopFlightReviewDetailsPopover
            departure={departure}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            isMultiTicket={isMultiTicket}
            openPopover={openPopover}
            onClose={() => setOpenPopover(false)}
            onClickEdit={onClickEdit}
            airports={airports}
            isMixedCabinClass={isMixedCabinClass}
          />
        ) : null}
      </Box>
    </Box>
  );
};
