import { SessionInfo } from "@b2bportal/auth-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AuthSessionState = {
  authStateToken?: string;
  missingFields?: string[];
  sessionInfo?: SessionInfo;
};

const initialState: AuthSessionState = {
  sessionInfo: undefined,
};

const authSessionSlice = createSlice({
  name: "authSession",
  initialState,
  reducers: {
    removeSession(state) {
      state.sessionInfo = undefined;
    },
    updateSession(state, action: PayloadAction<SessionInfo>) {
      state.sessionInfo = { ...state.sessionInfo, ...action.payload };
    },
  },
});

const {
  actions: { removeSession, updateSession },
  reducer: authReducer,
} = authSessionSlice;

export { authReducer, removeSession, updateSession };
