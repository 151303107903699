import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { verifyPaymentMethod } from "../../../../api/v1/book/payment-methods/verifyPaymentMethod";
import {
  PaymentVerifyResultEnum,
  PaymentVerifyResult,
  Success,
  AddPaymentProperties,
  ADD_PAYMENT,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import { IStoreState } from "../../../../reducers/types";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* verifyPaymentMethodSaga({
  payload,
  accountId,
}: actions.IVerifyPaymentMethod) {
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  try {
    const result: PaymentVerifyResult = yield verifyPaymentMethod(
      payload,
      apiConfig
    );

    switch (result.PaymentVerifyResult) {
      case PaymentVerifyResultEnum.CardDeclined:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.ExcessiveUserActivity:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.GenericError:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.Success: {
        yield put(actions.verifyPaymentMethodCallStateSuccess());
        // Immediately set the selected payment method on addition.
        yield put(
          actions.setSelectedPaymentMethodId({
            paymentMethodId: (result as Success).payment.id,
            accountId,
          })
        );
        const properties: AddPaymentProperties = {
          success: true,
        };
        trackEvent(
          {
            eventName: ADD_PAYMENT,
            properties,
          },
          apiConfig
        );
        yield put(actions.listPaymentMethods());
        return;
      }
      default:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
    }
    const properties: AddPaymentProperties = {
      success: false,
    };
    trackEvent(
      {
        eventName: ADD_PAYMENT,
        properties,
      },
      apiConfig
    );
  } catch (e) {
    yield put(
      actions.verifyPaymentMethodCallStateFailed(
        PaymentVerifyResultEnum.GenericError
      )
    );
    const properties: AddPaymentProperties = {
      success: false,
    };
    trackEvent(
      {
        eventName: ADD_PAYMENT,
        properties,
      },
      apiConfig
    );
  }
}
