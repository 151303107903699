import { TripSlice, TripSummary } from "@b2bportal/air-shopping-api";
import { formatInterval } from "@hopper-b2b/utilities";
import { IReturnSlicesByOutgoingId, ITripSummariesById } from "..";
import dayjs from "dayjs";

export const createStopoverString = (trip: TripSlice) => {
  let layoverString = "";
  const stopoverSegments = trip.segmentDetails.filter(
    (s) => s.stopoverDurationMinutes && s.stopoverDurationMinutes > 0
  );
  stopoverSegments.forEach((segment, index) => {
    layoverString = segment.stopoverDurationMinutes
      ? `${layoverString}
       ${formatInterval(segment.stopoverDurationMinutes)}
      ${segment.destinationCode}`
      : "";

    if (index < stopoverSegments.length - 1) {
      layoverString = layoverString + " / ";
    }
  });
  return layoverString;
};

export const formatTripSummaries = (
  flightInfo: TripSummary[]
): {
  tripSummariesById: ITripSummariesById;
  returnFlightsByOutgoingId: IReturnSlicesByOutgoingId;
} => {
  const flights: ITripSummariesById = {};
  const outgoingReturnFlightMap: IReturnSlicesByOutgoingId = {};

  flightInfo.forEach((flight) => {
    const outgoingSliceId = flight.outgoingSlice.id;
    const returnSliceId = flight.returningSlice?.id;

    if (returnSliceId) {
      if (outgoingReturnFlightMap[outgoingSliceId]) {
        // add valid return flight to map
        outgoingReturnFlightMap[outgoingSliceId][returnSliceId] = flight.tripId;
      } else {
        // create map of valid return slices for a specific outgoing slice
        outgoingReturnFlightMap[outgoingSliceId] = {
          [returnSliceId]: flight.tripId,
        };
      }
    }

    // store all trip summaries
    flights[flight.tripId] = flight;
  });

  return {
    tripSummariesById: flights,
    returnFlightsByOutgoingId: outgoingReturnFlightMap,
  };
};

export const comparePrices = (a: any, b: any) => {
  // we should only compare the first fare
  const priceA = a.fares[0]?.amount?.fiat?.value;
  const priceB = b.fares[0]?.amount?.fiat?.value;
  return priceA - priceB;
};

export const compareDuration = (a: any, b: any) => {
  const aMin = dayjs(a.arrival).diff(dayjs(a.departure), "minute", true);
  const bMin = dayjs(b.arrival).diff(dayjs(b.departure), "minute", true);
  return aMin - bMin;
};
