import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName } from "@hopper-b2b/types";
import { ActionButton, NoResults } from "@hopper-b2b/ui";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { FC, PropsWithChildren, useContext } from "react";
import { useDispatch } from "react-redux";
import { ClientContext } from "../../../../../../../App";
import { setRenderLoadingSteps } from "../../../../../actions/actions";
import { useFetchFlights } from "../../../../../hooks/useFetchFlights";

const NoFlightsMessaging: FC<
  PropsWithChildren<{
    hasAppliedFareClassFilter: boolean;
    hasAppliedNonFareclassFilter: boolean;
    hasFlightsError: boolean;
    onReload?: () => void;
    setOpenCalendarModal: (val: boolean) => void;
  }>
> = ({
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  hasFlightsError,
  onReload,
  setOpenCalendarModal,
}) => {
  const clientContext = useContext(ClientContext);
  const dispatch = useDispatch();
  const { t } = useI18nContext();

  const fetchFlights = useFetchFlights();

  let handleReload = () => {
    dispatch(
      setRenderLoadingSteps(
        [ClientName.UBER, ClientName.NUBANK].includes(
          getEnvVariables("clientName") as ClientName
        )
      )
    );
    fetchFlights();
  };

  if (onReload) {
    handleReload = onReload;
  }

  let noFlightsString = t("noFlightsAvailable");
  const copy = {
    genericShopErrorButton: t("genericShopErrorButton"),
    changeDates: t("changeDates"),
  };

  if (hasAppliedFareClassFilter) {
    noFlightsString = t("fareClassFlightsNotFound");
  } else if (hasAppliedNonFareclassFilter) {
    noFlightsString = t("filteredFlightsNotFound");
  }

  const changeDate = () => setOpenCalendarModal(true);

  return hasFlightsError ? (
    <Box className="no-results-container">
      <NoResults
        className="flight-list-no-results"
        title={t("genericShopErrorTitle")}
        subtitle={t("genericShopErrorSubtitle")}
        iconSrc={
          clientContext?.assets?.error ? clientContext.assets.error : null
        }
      />
      <button onClick={handleReload} className={"reload-button"}>
        <span className="reload-button-content">
          {copy.genericShopErrorButton}
        </span>
      </button>
    </Box>
  ) : (
    <Box className="no-results-container">
      <NoResults
        className="flight-list-no-results"
        title={t("noFlightsFound")}
        subtitle={noFlightsString}
        iconSrc={
          clientContext?.assets?.error ? clientContext.assets.error : null
        }
      />
      <ActionButton
        onClick={changeDate}
        className={clsx("change-dates-button", "b2b")}
        message={copy.changeDates}
      />
    </Box>
  );
};

export default NoFlightsMessaging;
