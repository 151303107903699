import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { HeaderPayload, getEnvVariables } from "@hopper-b2b/utilities";
import { ActionLink, Header } from "@hopper-b2b/ui";
import { ClientName } from "@hopper-b2b/types";

import { setFlightShopProgress } from "../../../shop/actions/actions";
import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import { getReturnSelection } from "../../reducer/selectors";
import ExchangeHeaderCenter from "./HeaderCenter";
import "./styles.scss";

interface IExchangeHeaderProps {
  backToTrips: () => void;
  setHeader: (payload: HeaderPayload) => void;
}

const ExchangeHeader = (props: IExchangeHeaderProps): JSX.Element => {
  const { backToTrips, setHeader } = props;
  const dispatch = useDispatch();
  const exchangeStep = useSelector(flightShopProgressSelector);
  const returnSelection = useSelector(getReturnSelection);

  const handleGoBack = useCallback(() => {
    let nextStep;

    switch (exchangeStep) {
      case FlightShopStep.ExchangeGatekeeper:
      case FlightShopStep.ExchangeLanding:
      case FlightShopStep.ExchangeLocationsPicker:
      case FlightShopStep.ExchangeSubmitted:
        return backToTrips();
      case FlightShopStep.ExchangeDatesPicker:
        nextStep = FlightShopStep.ExchangeLocationsPicker;
        if (getEnvVariables("clientName") === ClientName.NUBANK)
          nextStep = FlightShopStep.ExchangeLanding;
        break;
      case FlightShopStep.ExchangeSlicePicker:
        nextStep = FlightShopStep.ExchangeDatesPicker;
        break;
      case FlightShopStep.ReviewItinerary:
        if (returnSelection === ExchangeActionEnum.Change) {
          nextStep = FlightShopStep.ChooseReturn;
        } else {
          nextStep = FlightShopStep.ChooseDeparture;
        }
        break;
      case FlightShopStep.ExchangePriceBreakdown:
        nextStep = FlightShopStep.ReviewItinerary;
        break;
      case FlightShopStep.ExchangeError:
      default:
        nextStep = FlightShopStep.ExchangeLocationsPicker;
    }

    if (nextStep) {
      dispatch(setFlightShopProgress(nextStep));
    }
  }, [backToTrips, dispatch, exchangeStep, returnSelection]);

  if (
    // shop pages have their own header component
    exchangeStep === FlightShopStep.ChooseDeparture ||
    exchangeStep === FlightShopStep.ChooseReturn ||
    exchangeStep === FlightShopStep.ExchangeSubmitting
  ) {
    return null;
  }

  return (
    <Header
      center={<ExchangeHeaderCenter setHeader={setHeader} />}
      className="flight-exchange-header"
      left={
        <ActionLink
          className="mobile-flight-search-header-go-back"
          onClick={handleGoBack}
          content={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
        />
      }
    />
  );
};

export default ExchangeHeader;
