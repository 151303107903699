import {
  getCartInitialContext,
  IContactInfo,
  ParentState,
} from "@hopper-b2b/checkout";
import { LodgingMachineContext } from "./types";
import {
  IPerson,
  PersonId,
  SessionInfo,
  WalletVoucherStatusEnum,
} from "@hopper-b2b/types";
import { getPaymentInitialContext } from "../../checkout/states";

export const getInitialLodgingMachineContext =
  function (): LodgingMachineContext {
    return {
      ...getCartInitialContext(),
      ...getPaymentInitialContext(),
      auth: {
        guestUser: {
          givenName: undefined,
          surname: undefined,
        },
      },
      lodgingShop: {
        fromDate: undefined,
        untilDate: undefined,
        selectedLodging: undefined,
        selectedRoom: undefined,
        selectedRoomRateId: undefined,
      },
      sessionInfo: {} as SessionInfo,
      featureFlags: {},
      localStorageKey: "nubank-tracking-properties",
      [ParentState.passengerInformation]: {
        selectedPassengerIds: [] as Array<PersonId>,
        userPassengers: [] as Array<IPerson>,
        numPassengerAlertDismissed: false,
        visited: false,
      },
      [ParentState.contactInformation]: {
        contactInfo: {} as IContactInfo,
        visited: false,
      },
      [ParentState.review]: {
        visited: false,
      },
      [ParentState.bookingConfirmation]: {},
      [ParentState.wallet]: {
        selectedOffer: undefined,
        offers: [],
        visited: false,
        error: undefined,
        fetchedOffers: false,
        offerApplied: false,
        offerStatus: WalletVoucherStatusEnum.DEFAULT_OPT_IN,
      },
    };
  };
