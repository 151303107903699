import { useContext } from "react";
import {
  FeatureFlags,
  FeatureFlagsContext,
  defaultContext,
} from "./FeatureFlagsContext";

const useIsFeatureFlagEnabled = (flag: FeatureFlags) => {
  const featureFlagsContext = useContext(FeatureFlagsContext);

  if (!featureFlagsContext) {
    console.error("hook must be within a FeatureFlagsProvider, return default");

    return defaultContext[flag];
  }

  return featureFlagsContext[flag];
};

export const useIsAirEnabled = () => useIsFeatureFlagEnabled(FeatureFlags.AIR);
export const useIsLodgingEnabled = () =>
  useIsFeatureFlagEnabled(FeatureFlags.LODGING);
export const useIsCalendarPredictionEnabled = () =>
  useIsFeatureFlagEnabled(FeatureFlags.CALENDAR_PREDICTION);
export const useIsRoomsEnabled = () =>
  useIsFeatureFlagEnabled(FeatureFlags.ROOMS);
