import { useCallback, useEffect, useMemo, useState } from "react";
import { GordianSeat } from "@b2bportal/air-seats-api";
import clsx from "clsx";

import {
  commonSelectors,
  DesktopLayout,
  ErrorModal,
  FlightPassengerSelectorsV2,
  FlightSelectors,
  getChildState,
  ReviewSelectors,
  SeatEvents,
  SeatEventTypes,
  SeatMapOptions,
  SeatSelection,
  SeatSelectionErrorModal,
  SeatSelectionState,
  SeatSelectors,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import {
  B2BLoadingPopup,
  B2BSpinner,
  SeatsSummaryRow,
  WorkingBunnies,
} from "@hopper-b2b/ui";
import {
  FlightClientAssetProps,
  GordianSeatSegment,
  SelectedSeatsSegment,
} from "@hopper-b2b/types";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  getSelectedSeatsSegments,
  useDeviceTypes,
} from "@hopper-b2b/utilities";

import Error from "../../../../assets/client/error.svg";
import { PATH_FLIGHTS_SEARCH } from "../../../../utils/urlPaths";
import { ConnectedPriceBreakdownContent } from "../PriceBreakdownDropdown";
import { ConnectedBreadcrumbs } from "../Breadcrumbs";
import "./styles.scss";
import { useNavigate } from "react-router-dom-v5-compat";

export const ConnectedSeatSelection = () => {
  const { t, formatFiatCurrency, formatCurrency } = useI18nContext();
  const send = useCheckoutSend<SeatEvents>();
  const navigate = useNavigate();

  const clientAssets = useSelector(FlightSelectors.getClientAssets);
  const tripDetails = useSelector(FlightSelectors.getSelectedTripDetailsParent);
  const airportMap = useSelector(FlightSelectors.getAirportMap);
  const passengers = useSelector(
    FlightPassengerSelectorsV2.getAllSelectedUserPassengersParent
  );

  const isLoading = useSelector(SeatSelectors.getIsSeatSelectionLoading);
  const selectedSeats = useSelector(SeatSelectors.getSelectedSeats);
  const selectedSeatSegments = useSelector(
    SeatSelectors.getSelectedSeatSegments
  );
  const seatSlices = useSelector(SeatSelectors.getSeatSlices);
  const cheapestSeat = useSelector(SeatSelectors.getCheapestSeat);
  const seatMapHtml = useSelector(SeatSelectors.getSeatMapHtml);
  const seatsInfo = useSelector(SeatSelectors.getSeatsInfo);
  const totalSeatPricing = useSelector(SeatSelectors.getSeatTotalPricing);
  const skippedSeatSelection = useSelector(SeatSelectors.getSkipSeatSelection);
  const airports = useSelector(FlightSelectors.getAirportMap);
  const editSeatsFromReview = useSelector(
    ReviewSelectors.getEditSeatsFromReview
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);
  const goNext = useCallback(() => {
    send(SeatEventTypes.NEXT);
  }, [send]);
  const setSelectedSeats = useCallback(
    (seats: GordianSeat[], seatSegments: GordianSeatSegment[]) => {
      send({ type: SeatEventTypes.SELECT_SEATS, seats, seatSegments });
      if (editSeatsFromReview) {
        goNext();
      }
    },
    [editSeatsFromReview, goNext, send]
  );
  const setSkipSeatSelection = useCallback(
    () => send({ type: SeatEventTypes.SKIP_SEAT_SELECTION }),
    [send]
  );

  const styleString = `@font-face { font-family: "Graphik"; font-style: normal; font-weight: normal; src: local("Graphik"); } #gordian-seatmap-dialog {font-family: "Graphik" !important;}`;
  const seatMapOptions: SeatMapOptions = {
    modal: false,
    fontFamily: "Graphik",
    theme: {
      seats: {
        1: {
          // Standard
          stopColor: "#BBBBBB",
          labelColor: "#000000",
        },
        2: {
          // Preferred
          stopColor: "#A9C9FF",
          labelColor: "#000000",
        },
        3: {
          // Extra Legroom
          stopColor: "#7FD99A",
          labelColor: "#000000",
        },
        4: {
          // Exit Row
          stopColor: "#FFB48C",
          labelColor: "#000000",
        },
        5: {
          // Front Seat
          stopColor: "#DDB9FF",
          labelColor: "#000000",
        },
        not_available: {
          fill: "#F3F3F3",
          borderColor: "#A6A6A6",
        },
        selected_seat: {
          stopColor: "#000000",
          labelColor: "#FFFFFF",
        },
      },
    },
  };

  const subtitle =
    selectedSeats.length > 0 ? (
      <I18nMarkup
        tKey={"seats.totalSeatPricing"}
        replacements={{
          count: passengers.length,
          price: formatCurrency(totalSeatPricing),
        }}
      />
    ) : cheapestSeat ? (
      `${t("seats.subtitle", {
        cheapestSeat: formatFiatCurrency(cheapestSeat?.fiat),
      })}`
    ) : undefined;

  const { matchesMobile } = useDeviceTypes();

  const [outboundSegmentsSeats, setOutboundSegmentsSeats] = useState<
    SelectedSeatsSegment[]
  >([]);
  const [returnSegmentsSeats, setReturnSegmentsSeats] = useState<
    SelectedSeatsSegment[]
  >([]);

  useEffect(() => {
    if (selectedSeatSegments.length > 0) {
      const outboundSegmentSelectedSeats = getSelectedSeatsSegments(
        selectedSeatSegments,
        tripDetails.slices[0],
        passengers,
        tripDetails.slices[0].segmentDetails.length,
        seatSlices[0]
      );
      setOutboundSegmentsSeats(outboundSegmentSelectedSeats);
      if (tripDetails.slices.length > 1) {
        const returnSegmentSelectedSeats = getSelectedSeatsSegments(
          selectedSeatSegments,
          tripDetails.slices[1],
          passengers,
          tripDetails.slices[0].segmentDetails.length,
          seatSlices[1]
        );
        setReturnSegmentsSeats(returnSegmentSelectedSeats);
      }
    }
  }, [passengers, seatSlices, selectedSeatSegments, tripDetails.slices]);

  const getSummaryPanel = (onEditClick) => {
    return (
      <div
        className={clsx(
          "seat-selection-review-container",
          "checkout-seats-summary",
          "nubank",
          {
            mobile: matchesMobile,
          }
        )}
      >
        <SeatsSummaryRow
          outboundSeatSegments={outboundSegmentsSeats}
          returnSeatSegments={returnSegmentsSeats}
          onEditClick={onEditClick}
          outboundOriginCode={tripDetails.slices[0].originCode}
          returnOriginCode={
            tripDetails.slices.length > 1
              ? tripDetails.slices[1].originCode
              : undefined
          }
          airports={airports}
          review={false}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingModal clientAssets={clientAssets} />
      ) : (
        <SeatSelection
          title={t("seats.title")}
          subtitle={subtitle}
          seatMapHtml={seatMapHtml}
          skippedSeatSelection={skippedSeatSelection}
          setSelectedSeats={setSelectedSeats}
          selectedSeats={selectedSeats}
          selectedSeatSegments={selectedSeatSegments}
          tripDetails={tripDetails}
          passengers={passengers}
          cheapestSeat={cheapestSeat || null}
          setSkipSeatSelection={setSkipSeatSelection}
          totalSeatPricing={totalSeatPricing}
          airports={airportMap}
          seatsInfo={seatsInfo}
          desktopRightContent={<ConnectedPriceBreakdownContent />}
          desktopTopContent={<ConnectedBreadcrumbs />}
          clientAssets={clientAssets as { logo: string }}
          onGoBack={goBack}
          onGoNext={goNext}
          seatMapOptions={seatMapOptions}
          styleString={styleString}
          headerTitle={t("seatSelection")}
          getSummaryPanel={getSummaryPanel}
          isPopup={!matchesMobile}
          editSeatsFromReview={editSeatsFromReview}
        />
      )}
      <ConnectedErrorModal />
    </>
  );
};

const LoadingModal = ({
  clientAssets,
}: {
  clientAssets: Partial<FlightClientAssetProps> | undefined;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const stateValue = useSelector(commonSelectors.getStateValue);
  const childState = getChildState(stateValue);
  const message = useMemo(() => {
    switch (childState) {
      case SeatSelectionState.validate:
        return t("seats.validateLoadingMessage");
      default:
        return t("seats.loadingMessage");
    }
  }, [childState, t]);

  return matchesMobile ? (
    <B2BLoadingPopup open fullScreen message={message} popupSize={"mobile"} />
  ) : (
    <>
      <DesktopLayout
        clientLogo={clientAssets?.logo}
        rightContent={<ConnectedPriceBreakdownContent />}
      >
        <B2BSpinner />
      </DesktopLayout>
      <B2BLoadingPopup
        open
        message={message}
        popupSize={"desktop"}
        loadingIcon={<WorkingBunnies />}
        className="seat-selection-loading"
      />
    </>
  );
};

export const ConnectedErrorModal = () => {
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const send = useCheckoutSend<SeatEvents>();
  const open = useSelector(SeatSelectors.getSeatSelectionErrorOpen);
  const contactError = useSelector(SeatSelectors.getSeatSelectionError);

  const clearError = () => send(SeatEventTypes.CLEAR_SEAT_ERROR);
  const goNext = () => send(SeatEventTypes.NEXT);

  const continueWithoutSeats = () => {
    clearError();
    goNext();
  };

  const searchAgain = () => {
    clearError();
    navigate(PATH_FLIGHTS_SEARCH);
  };

  const getErrorModalProps = (type?: SeatSelectionErrorModal) => {
    switch (type) {
      case SeatSelectionErrorModal.BrokenCart:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };

      case SeatSelectionErrorModal.FailedAddToCart:
        return {
          title: t("seats.errorFailedAddToCartTitle"),
          subtitle: t("seats.errorFailedAddToCartSubtitle"),
          primaryButtonText: t("seats.continueWithoutSeats"),
          primaryOnClick: continueWithoutSeats,
          secondaryButtonText: t("checkoutError.searchAgain"),
          secondaryOnClick: searchAgain,
        };

      case SeatSelectionErrorModal.GenericError:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      default:
        return {
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
    }
  };
  const errorModalProps = getErrorModalProps(contactError?.type);

  return <ErrorModal open={open} {...errorModalProps} image={Error} />;
};
