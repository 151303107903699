import { IFlightListData } from "@hopper-b2b/types";
import { FlightComponent } from "../FlightComponent";
import { DesktopInfiniteFlightsProps } from "./DesktopInfiniteFlights";

export type DesktopInfiniteFlightsGridComponentProps =
  DesktopInfiniteFlightsProps & {
    flightData: IFlightListData;
    index: number;
  };

export const DesktopInfiniteFlightsGridComponent = (
  props: DesktopInfiniteFlightsGridComponentProps
) => {
  const { flights, flightsToRender, flightData, index } = props;

  const { fares, slice: sliceId } = flightData;
  const flightSlice = flights?.slices[sliceId];

  if (!flightSlice) {
    return null;
  }

  return (
    <FlightComponent
      {...props}
      key={`flight-${sliceId}`}
      slice={flightSlice}
      index={index}
      flight={flightData}
      selectedFare={fares[0]}
      flightsToShow={flightsToRender}
    />
  );
};
