import { useContext } from "react";
import { UserContext } from "../../App";
import { Environment } from "@hopper-b2b/utilities";
import styles from "./styles.module.scss";

const ImpersonationBanner = () => {
  const { sessionInfo } = useContext(UserContext);
  const environment: Environment = import.meta.env.MODE as Environment;
  const dashboardLink =
    environment === Environment.production
      ? "https://b2bdashboard.portal.hopper.com/tenant/nubank/user/"
      : "https://b2bdashboard.portal.staging.hopper.com/tenant/nubank/user/";
  return (
    <div className={styles.ImpersonationBanner}>
      <p>
        Please note you are impersonating{" "}
        <a
          href={dashboardLink + sessionInfo.hopperUserId}
          target="_blank"
          rel="noreferrer"
        >
          {sessionInfo.userInfoResponse.name}
        </a>{" "}
        in {environment}.
      </p>
    </div>
  );
};

export default ImpersonationBanner;
