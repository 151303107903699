import { useState, useEffect } from "react";
import { TripPricingSummary } from "@b2bportal/air-booking-api";
import { Button, Typography, Radio } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLink,
  B2BSpinner,
  DesktopPopupModal,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { useDeviceTypes, useEnableDarkMode } from "@hopper-b2b/utilities";
import { getDarkModePreferred } from "@hopper-b2b/themes";
import { FiatPrice, PersonId } from "@hopper-b2b/types";
import { useI18nCurrency, useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";

export interface IInfantSeatPickerModalProps {
  currentInfantToSelectSeat?: PersonId;
  isLoading: boolean;
  onClose: () => void;
  onContinue: (seatType: SeatType) => void;
  tripPricing: TripPricingSummary | null;
}

type SeatType = "OnLap" | "OwnSeat";

const getTotalPrice = (pricing: FiatPrice[]) =>
  pricing.reduce((sum, cur) => sum + cur.value, 0);

export const InfantSeatPickerModal = ({
  currentInfantToSelectSeat,
  isLoading,
  onClose,
  onContinue,
  tripPricing,
}: IInfantSeatPickerModalProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [infantSeatPricing, setInfantSeatPricing] = useState({
    lapSeatPricing: tripPricing?.pricingByPassenger?.find(
      (passengerPricing) =>
        passengerPricing.person.id === currentInfantToSelectSeat
    ),
    ownSeatPricing: tripPricing?.pricingByPassenger?.find(
      (passengerPricing) =>
        passengerPricing.person.id !== currentInfantToSelectSeat &&
        passengerPricing.baseWithoutMargin.fiat.value +
          passengerPricing.taxes.fiat.value !==
          0
    ),
  });

  useEffect(() => {
    setInfantSeatPricing({
      lapSeatPricing: tripPricing?.pricingByPassenger?.find(
        (passengerPricing) =>
          passengerPricing.person.id === currentInfantToSelectSeat
      ),
      ownSeatPricing: tripPricing?.pricingByPassenger?.find(
        (passengerPricing) =>
          passengerPricing.person.id !== currentInfantToSelectSeat &&
          passengerPricing.baseWithoutMargin.fiat.value +
            passengerPricing.taxes.fiat.value !==
            0
      ),
    });
  }, [tripPricing]);

  const totalPricing = {
    lapSeatTotal: infantSeatPricing?.lapSeatPricing
      ? getTotalPrice([
          infantSeatPricing.lapSeatPricing.baseWithoutMargin.fiat,
          infantSeatPricing.lapSeatPricing.taxes.fiat,
        ])
      : 0,
    ownSeatPricing: infantSeatPricing?.ownSeatPricing
      ? getTotalPrice([
          infantSeatPricing.ownSeatPricing.baseWithoutMargin.fiat,
          infantSeatPricing.ownSeatPricing.taxes.fiat,
        ])
      : 0,
  };

  const currencySymbol =
    tripPricing?.totalPricing?.total.fiat.currencySymbol || "$";

  const InfantSeatPickerModalContent = () => {
    const [seatType, setSeatType] = useState<SeatType>("OnLap");

    const handleOnContinue = () => {
      onContinue(seatType);
    };

    const darkModeAllowed = useEnableDarkMode();
    const isDarkMode = darkModeAllowed && getDarkModePreferred();

    return isLoading ? (
      <div className="loading-container">
        <B2BSpinner />
        <Typography className="subheading">
          {t("infantModal.loading")}
        </Typography>
      </div>
    ) : (
      <div
        className={clsx(
          "lap-infant-seat-picker-container",
          isDarkMode && "dark-mode"
        )}
      >
        <Typography className="heading">{t("infantModal.title")}</Typography>
        <Typography className="subheading">
          {t("infantModal.subtitle")}
        </Typography>
        <SelectRow
          label={t("infantModal.onLap")}
          description={t("infantModal.perInfantDescription")}
          amount={totalPricing.lapSeatTotal}
          selected={seatType === "OnLap"}
          onClick={() => setSeatType("OnLap")}
          currencySymbol={currencySymbol}
        />
        <SelectRow
          label={t("infantModal.ownSeat")}
          description={t("infantModal.perInfantDescription")}
          amount={totalPricing.ownSeatPricing}
          selected={seatType === "OwnSeat"}
          onClick={() => setSeatType("OwnSeat")}
          currencySymbol={currencySymbol}
        />
        <Button
          onClick={handleOnContinue}
          className="continue-button"
          variant="contained"
        >
          {t("infantModal.continue")}
        </Button>
      </div>
    );
  };

  return matchesMobile ? (
    <MobilePopoverCard
      open={Boolean(currentInfantToSelectSeat)}
      onClose={onClose}
      fullScreen
      // centered={true}
      className="infant-seat-picker-modal"
      contentClassName="infant-seat-picker-popover-content"
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={
            <IconComponent
              ariaLabel="Close button icon"
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label="Close"
          onClick={onClose}
        />
      }
    >
      <InfantSeatPickerModalContent />
    </MobilePopoverCard>
  ) : (
    <DesktopPopupModal
      open={Boolean(currentInfantToSelectSeat)}
      onClose={onClose}
      className="lap-infant-seat-select-popup-root"
    >
      <InfantSeatPickerModalContent />
    </DesktopPopupModal>
  );
};

interface ISelectRow {
  selected: boolean;
  label: string;
  amount: number;
  description: string;
  onClick: () => void;
  currencySymbol: string;
}

const SelectRow = ({
  selected,
  label,
  amount,
  description,
  onClick,
}: ISelectRow) => {
  const { t } = useI18nContext();
  const { formatCurrency } = useI18nCurrency();
  const labelSubText =
    amount === 0
      ? t("infantModal.freeDescription")
      : `${formatCurrency(amount)} ${description}`;
  return (
    <div className="select-row">
      <Radio
        className={clsx("radio", { selected })}
        checked={selected}
        onClick={() => onClick()}
      />
      <div
        className={clsx("select-row-text-container", { selected })}
        onClick={onClick}
      >
        <Typography>
          <strong>{label}</strong>
          {` - ${labelSubText}`}
        </Typography>
      </div>
    </div>
  );
};
