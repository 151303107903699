import {
  ISelectedTrip,
  SessionInfo,
  SupportStatus,
  TripDetails,
} from "@hopper-b2b/types";

import {
  IContactInfo,
  ParentState,
  getCartInitialContext,
} from "@hopper-b2b/checkout";
import ClientLogo from "../../assets/client/client-logo.webp";
import { FlightMachineContext } from "./types";
import { getPaymentInitialContext } from "../../checkout/states";

export const getInitialFlightMachineContext =
  function (): FlightMachineContext {
    return {
      ...getCartInitialContext(),
      ...getPaymentInitialContext(),
      sessionInfo: {} as SessionInfo,
      clientAssets: {
        logo: ClientLogo,
      },
      flightSearch: {
        tripCategory: undefined,
        destination: null,
        origin: null,
        departureDate: null,
        returnDate: null,
        originCountryCode: "",
        destinationCountryCode: "",
      },
      flightShop: {
        tripDetails: {} as TripDetails,
        selectedTrip: {} as ISelectedTrip,
        shopPricingInfo: {
          fare: undefined,
        },
        departureLabel: "",
        returnLabel: undefined,
        airports: undefined,
      },
      common: {
        tripPricing: undefined,
      },
      prediction: undefined,
      selectedAccount: undefined,
      featureFlags: {},
      [ParentState.passengerInformation]: {
        addPassport: false,
        currentInfantId: undefined,
        currentUserPassenger: undefined,
        error: undefined,
        infantOnlyError: false,
        isValidated: false,
        numPassengerAlertDismissed: false,
        selectedLapInfantIds: [],
        selectedPassengerIds: [],
        userPassengers: [],
        visited: false,
      },
      [ParentState.contactInformation]: {
        contactInfo: {} as IContactInfo,
        visited: false,
      },
      [ParentState.passport]: {
        error: undefined,
      },
      [ParentState.seatSelection]: {
        selectedSeats: [],
        selectedSeatSegments: [],
        cheapestSeat: undefined,
        seatMapHtml: undefined,
        seatMapRequest: undefined,
        seatsInfo: undefined,
        seatMapAvailable: undefined,
        visited: false,
        skipSeatSelection: false,
      },
      [ParentState.review]: {
        visited: false,
      },
      [ParentState.bookingConfirmation]: {},
      [ParentState.vipSupport]: {
        supportStatus: SupportStatus.vip,
      },
      [ParentState.priceDrop]: { candidateId: undefined },
    };
  };
