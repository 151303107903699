import { AnyAction, combineReducers, createAction } from "@reduxjs/toolkit";

import policyReducer from "./policy";
import searchReducer from "./search";
import shopReducer from "./shop";

export const resetExchange = createAction("exchange/reset");

const exchangeReducer = combineReducers({
  policy: policyReducer,
  search: searchReducer,
  shop: shopReducer,
});

/**
 * If the state given to it is undefined, it must return the initial state for this specific reducer.
 * source: https://redux.js.org/api/combinereducers
 */
export const initialState = exchangeReducer(undefined, { type: null });

const exchangeReducerProxy = (state, action: AnyAction) => {
  if (action.type === resetExchange.toString()) {
    return exchangeReducer(undefined, action);
  }

  return exchangeReducer(state, action);
};

export type IFlightExchangeState = ReturnType<typeof exchangeReducer>;
export default exchangeReducerProxy;
