import {
  ContactSelectors,
  FlightPassengerSelectorsV2,
  getParentState,
  ParentState,
  ReviewSelectors,
  SeatSelectors,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";

import { SeatMapResponseEnum } from "@b2bportal/air-booking-api";
import { Breadcrumbs, BreadcrumbStep } from "@hopper-b2b/ui";
import { Event } from "../events";

export const ConnectedBreadcrumbs = () => {
  const { t } = useI18nContext();
  const [state, send] = useCheckoutState();
  const parentState = getParentState(state.value);

  const contactVisited = useSelector(ContactSelectors.getContactVisited);
  const passengerVisited = useSelector(
    FlightPassengerSelectorsV2.getPassengerVisited
  );
  const reviewVisited = useSelector(ReviewSelectors.getReviewVisited);

  const seatVisited = useSelector(SeatSelectors.getSeatSelectionVisited);
  const seatMapAvailability = useSelector(SeatSelectors.getSeatMapAvailability);
  const seatMapHtml = useSelector(SeatSelectors.getSeatMapHtml);

  const steps: BreadcrumbStep[] = [
    {
      key: ParentState.contactInformation,
      label: t("contactInformation"),
      onClick: () => send(Event.GO_TO_CONTACT_INFORMATION),
      isSelected: parentState === ParentState.contactInformation,
      visited: contactVisited,
    },
    {
      key: ParentState.passengerInformation,
      label: t("travelerInformation"),
      onClick: () => send(Event.GO_TO_PASSENGER_SELECT),
      isSelected: parentState === ParentState.passengerInformation,
      visited: passengerVisited,
    },
    {
      key: ParentState.seatSelection,
      label: t("seats.title"),
      onClick: () => send(Event.GO_TO_SEAT_SELECTION),
      isSelected: parentState === ParentState.seatSelection,
      visited: seatVisited,
      hide:
        seatMapAvailability !== SeatMapResponseEnum.SeatMapAvailable ||
        !seatMapHtml,
    },
    {
      key: ParentState.review,
      label: t("tripReview"),
      onClick: () => send(Event.GO_TO_REVIEW),
      isSelected: parentState === ParentState.review,
      visited: reviewVisited,
    },
  ];

  return <Breadcrumbs steps={steps} />;
};
