import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { TripDetails } from "@hopper-b2b/types";
import { AirlineIcon, MixedCabinToolTip } from "@hopper-b2b/ui";
import {
  formatInterval,
  getSliceIndex,
  removeTimezone,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Airport } from "@b2bportal/air-shopping-api";
import { IOpenModal, OpenModalEnum } from "../types/IOpenModal";

type MobileFlightSummaryRowProps = {
  isDeparture: boolean;
  tripDetails: TripDetails;
  date: Date;
  setOpen: (openModal: IOpenModal) => void;
  airports: { [key: string]: Airport };
  isMixedClass: boolean;
};

export const MobileFlightSummaryRow = ({
  isDeparture,
  tripDetails,
  date,
  setOpen,
  airports,
  isMixedClass,
}: MobileFlightSummaryRowProps) => {
  const { t } = useI18nContext();

  const sliceIndex = getSliceIndex(isDeparture, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];

  return (
    <Box className="airline-details-with-chevron">
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <I18nMarkup
            tKey={
              isDeparture
                ? "flightShopReview.departureCardHeader"
                : "flightShopReview.returnCardHeader"
            }
            replacements={{
              location: airports[tripSlice.destinationCode]
                ? airports[tripSlice.destinationCode].cityName
                : tripSlice.destinationName,
              date: dayjs(date).format("ddd, MMM D"),
            }}
          />
          {((isDeparture && isMixedClass) ||
            (!isDeparture && isMixedClass)) && <MixedCabinToolTip />}
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Typography variant="body1">
              {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
                "h:mm A"
              )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
                "h:mm A"
              )}`}
            </Typography>
            <Box className="card-airline-container">
              <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
              <Typography variant="body2">
                {firstTripSegment.airlineName}
              </Typography>
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {tripSlice.stops === 0
                ? t("stopDetails.nonstop")
                : t("stopDetails.stop", { count: tripSlice.stops })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <FontAwesomeIcon
        className="mobile-right-chevron"
        onClick={() =>
          setOpen(isDeparture ? OpenModalEnum.DEPARTURE : OpenModalEnum.RETURN)
        }
        icon={faChevronRight as IconProp}
      />
    </Box>
  );
};
