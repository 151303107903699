import {
  getPriceText,
  getCurrencySymbol,
  getPriceString,
} from "@hopper-b2b/utilities";
import {
  Dealness,
  PredictionCopy,
  Prices,
  PriceDropProtectionEnum,
  Prediction,
  IsEligible,
  FiatPrice,
  RewardsPrice,
  TimeToLive,
  Cap,
  FareSliceDetails,
  PriceDropProtection,
  WatchState,
} from "@hopper-b2b/types";
import { getPriceFreezeRewardsString } from "./components/FlightShopHeader/components/DesktopPricePrediction/component";

import { useI18nContext } from "@hopper-b2b/i18n";

export const getMonitoringDaysCountForPrediction = (prediction: Prediction) => {
  if (prediction) {
    const days =
      prediction &&
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible
        ? (prediction.priceDropProtection as IsEligible).monitoringDuration
            .inSeconds / 86400
        : 0;
    return days;
  } else {
    return "";
  }
};

export const getMaxRefundForPrediction = (prediction: Prediction) => {
  if (
    prediction.priceDropProtection &&
    prediction.priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible
  ) {
    return prediction.priceDropProtection.maximumRefund.display;
  } else {
    return "";
  }
};

export const getMonitoringDaysCountForProtection = (
  priceDropProtection: PriceDropProtection
) => {
  if (priceDropProtection) {
    const days =
      priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible
        ? (priceDropProtection as IsEligible).monitoringDuration.inSeconds /
          86400
        : 0;
    return days;
  } else {
    return "";
  }
};

export const getMaxRefundForProtection = (
  priceDropProtection: PriceDropProtection
) => {
  if (
    priceDropProtection.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible
  ) {
    return priceDropProtection.maximumRefund.display;
  } else {
    return "";
  }
};

export const useGetPriceDropSubtitle = () => {
  const { t } = useI18nContext();
  return (monitoringDays: number | "", maxRefund: string) => {
    const subtitle = t("priceDropSubtitle", {
      count: monitoringDays || 0,
      days: monitoringDays || 0,
      maxRefund,
    });
    return subtitle;
  };
};

export const getLowestPriceInRewards = (
  lowestPrice: Prices,
  selectedRewardsAccountId: string | null
) => {
  if (
    !selectedRewardsAccountId ||
    !lowestPrice.rewards[selectedRewardsAccountId]
  ) {
    return "";
  }
  return `${lowestPrice.rewards[
    selectedRewardsAccountId || ""
  ]?.value.toLocaleString()} ${
    lowestPrice.rewards[selectedRewardsAccountId || ""]?.currency
  }`;
};

export const getLowestPriceInFiat = (lowestPrice: Prices) => {
  return `${lowestPrice.fiat.currencySymbol}${Math.round(
    lowestPrice.fiat.value
  )}`;
};

export const getPriceRecommendationTitle = (
  watchState: WatchState,
  copy: PredictionCopy
) => {
  switch (watchState) {
    case WatchState.JustWatched:
      return copy?.recommendationTitleJustWatched.join(" ");
    case WatchState.Watched:
      return copy?.recommendationTitleWatching.join(" ");
    default:
      return copy?.recommendationTitle.join(" ");
  }
};

// TODO move logic to determine appropriate copy to the BE

export const getPriceRecommendationSubtitle = (
  watchState: WatchState,
  copy: PredictionCopy,
  dealness: Dealness,
  isMobile: boolean
) => {
  let subtitle = "";
  const showSubtitle = dealness === Dealness.Wait && isMobile;
  switch (watchState) {
    case WatchState.JustWatched:
      if (copy?.recommendationBodyJustWatched.length > 1) {
        subtitle = copy?.recommendationBodyJustWatched[0];
      } else if (showSubtitle) {
        subtitle = copy?.recommendationBodyJustWatched[0];
      }
      break;
    case WatchState.Watched:
      if (copy?.recommendationBodyWatching.length > 1) {
        subtitle = copy?.recommendationBodyWatching[0];
      } else if (showSubtitle) {
        subtitle = copy?.recommendationBodyWatching[0];
      }
      break;
    default:
      if (copy?.recommendationBody.length > 1) {
        subtitle = copy?.recommendationBody[0];
      } else if (showSubtitle) {
        subtitle = copy?.recommendationBody[0];
      }
      break;
  }
  return subtitle;
};

export const useGetPriceWatchCopy = (
  watchState: WatchState,
  hasUnsupportedPredictionFilters: boolean,
  copy?: PredictionCopy
): string => {
  const { t } = useI18nContext();

  if (copy) {
    if (hasUnsupportedPredictionFilters) {
      return t("priceWatch.unsupportedFilterCopy");
    }
    switch (watchState) {
      case WatchState.JustWatched:
        if (copy.recommendationBodyJustWatched.length > 1) {
          return copy.recommendationBodyJustWatched[
            copy.recommendationBodyJustWatched.length - 1
          ];
        } else {
          return copy.recommendationBodyJustWatched[0];
        }
      case WatchState.Watched:
        if (copy?.recommendationBodyWatching.length > 1) {
          return copy.recommendationBodyWatching[
            copy.recommendationBodyWatching.length - 1
          ];
        } else {
          return copy.recommendationBodyWatching[0];
        }
      case WatchState.NotWatching:
        if (copy?.recommendationBody.length > 1) {
          return copy.recommendationBody[copy?.recommendationBody.length - 1];
        } else {
          return copy.recommendationBody[0];
        }
    }
  } else {
    return t("priceWatch.copy");
  }
};

export const useGetPriceWatchTitles = () => {
  const { t } = useI18nContext();
  return (copy?: PredictionCopy) => {
    const priceWatchTitles = {
      header: t("priceWatch.titles.header"),
      title: t("priceWatch.titles.title"),
      subtitle: t("priceWatch.titles.subtitle"),
      watchedTitle: t("priceWatch.titles.watchedTitle"),
      watchedHeader: t("priceWatch.titles.watchedHeader"),
      watchedSubtitle: t("priceWatch.titles.watchedSubtitle"),
      description: t("priceWatch.titles.description"),
      buttonCopy: t("priceWatch.titles.buttonCopy"),
    };
    if (copy) {
      priceWatchTitles.header = t("priceWatch.titles.copyHeader");
      priceWatchTitles.watchedHeader = t("priceWatch.titles.copyWatchedHeader");
    }
    return priceWatchTitles;
  };
};

interface IGetPriceFreezeTitleVars {
  priceFreezeFiat: FiatPrice | undefined;
  priceFreezeRewards: { [key: string]: RewardsPrice } | undefined | null;
  selectedRewardsAccountId: string | null;
  priceFreezeCap: Cap | undefined;
  duration: TimeToLive | undefined;
}

export const getPriceFreezeTitleVars = ({
  priceFreezeFiat,
  priceFreezeRewards,
  selectedRewardsAccountId,
  priceFreezeCap,
  duration,
}: IGetPriceFreezeTitleVars) => {
  const amount = priceFreezeFiat && getPriceText({ price: priceFreezeFiat });
  const rewards = getPriceFreezeRewardsString(
    priceFreezeRewards,
    selectedRewardsAccountId
  );
  const currency = priceFreezeCap?.value.currency;
  const durationTextI18nKey = duration
    ? getDurationTextI18nKey(duration)
    : null;
  const priceString = getPriceString({
    price: priceFreezeCap?.value.amount || 0,
    currencySymbol: currency ? getCurrencySymbol(currency) : undefined,
  });

  return {
    amount,
    rewards,
    durationTextI18nKey,
    priceString,
  };
};

export const getDurationTextI18nKey = (timeToLive: TimeToLive) => {
  const inDays = timeToLive.inDays;
  const inHours = timeToLive.inHours;
  const inSeconds = timeToLive.inSeconds;

  if (inDays) {
    return { i18nKey: "timeCount.day", count: inDays };
  } else if (inHours) {
    return { i18nKey: "timeCount.hour", count: inHours };
  }
  // note: we are currently only offering price freezes as little as 12 hours, so it will never return "X second(s)"
  return { i18nKey: "timeCount.second", count: inSeconds };
};
