import { useState, useEffect } from "react";
import { MobileFlightDetailsModal as Modal } from "@hopper-b2b/ui";
import {
  getIsMixedClass,
  getPlusDays,
  getSliceIndex,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { FareDetails, TripDetails } from "@hopper-b2b/types";
import { I18nMarkup } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import clsx from "clsx";

export interface IMobileFlightDetailsModalProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails | undefined;
  openModal: boolean;
  onClose: () => void;
  onClick: () => void;
  buttonText?: string;
}

export const MobileFlightDetailsModal = (
  props: IMobileFlightDetailsModalProps
) => {
  const { matchesMobile } = useDeviceTypes();
  const { departure, tripDetails, fareDetails } = props;
  const sliceIndex = tripDetails ? getSliceIndex(departure, tripDetails) : 0;
  const tripSlice = tripDetails?.slices[sliceIndex];
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);

  useEffect(() => {
    if (fareDetails) {
      departure
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  return matchesMobile ? (
    <Modal
      {...props}
      title={
        <I18nMarkup
          tKey={
            departure
              ? "flightShopReview.outboundCardHeader"
              : "flightShopReview.returnCardHeader"
          }
          replacements={{
            location: tripSlice?.destinationCode,
            date: dayjs(tripSlice?.departureTime).format("ddd, MMM D"),
          }}
        />
      }
      className={clsx("b2b", "b2b-secondary")}
      plusDays={tripSlice ? getPlusDays(tripSlice) : 0}
      isMixedCabinClass={isMixedCabinClass}
    />
  ) : null;
};
