import { ActionButton } from "@hopper-b2b/ui-core";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import CloseButtonIcon from "../../assets/icons/common/close-button.svg";
import { ActionLink } from "../ActionLink";
import { ExpandableCard } from "../ExpandableCard";
import { Icon, IconName } from "../Icon";
import "./styles.scss";

export interface IPriceFreezeProps {
  className?: string;
  image: string;
  onClose: () => void;
  title: string;
  increaseText?: string;
  decreaseText?: string;
  similarFlightText?: string;
  header: string;
  onClick?: () => void;
  onContinue: () => void;
  ctaText: string;
  isMobile?: boolean;
  mobileTermsConditionCopy?: string;
  icon?: JSX.Element;
}

export const PriceFreeze = (props: IPriceFreezeProps) => {
  const {
    className,
    image,
    onClose,
    onContinue,
    title,
    increaseText,
    decreaseText,
    similarFlightText,
    header,
    ctaText,
    onClick,
    isMobile,
    mobileTermsConditionCopy,
    icon,
  } = props;
  const [expandedKey, setExpandedKey] = useState("");

  const renderMobileTermsAndCondition = () => {
    return (
      <ExpandableCard
        className={clsx("pf-terms-condition", "b2b")}
        expandedCardKey={expandedKey}
        cardKey={"pf-terms-key"}
        handleCardKeyChange={() =>
          setExpandedKey(expandedKey === "pf-terms-key" ? "" : "pf-terms-key")
        }
        scrollExpandedIntoView={true}
        content={{
          title: (
            <Typography className="cta-text" variant="body1">
              {ctaText}
            </Typography>
          ),
          body: (
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: mobileTermsConditionCopy || "",
              }}
            />
          ),
        }}
      />
    );
  };

  const renderDesktopTermsAndCondition = () => {
    return (
      <ActionLink
        className="cta-button"
        onClick={onClick}
        content={
          <Typography className="cta-text" variant="h6">
            <span dangerouslySetInnerHTML={{ __html: ctaText }}></span>
          </Typography>
        }
        showTappableArea={true}
      />
    );
  };

  return (
    <Box className={clsx(className, "price-freeze-container")}>
      <Box
        className="top-content-container"
        style={{ backgroundImage: `url(${image})` }}
      >
        <Box className="img-location-container">
          <Icon className="img-location-icon" name={IconName.B2BMapPin} />
          <Typography className="img-location-text" variant="caption">
            Arctic Ocean, Greenland
          </Typography>
        </Box>
        <ActionLink
          className="price-freeze-close-button"
          onClick={onClose}
          content={
            <img
              src={CloseButtonIcon}
              className="close-button-icon"
              alt="close button icon"
            />
          }
          label="Close"
          showTappableArea={true}
        />
        {icon ? (
          icon
        ) : (
          <Icon
            className="b2b-price-freeze-icon"
            name={IconName.B2bPriceFreeze}
          />
        )}

        <Typography className="card-header" variant="body1">
          <span dangerouslySetInnerHTML={{ __html: header }}></span>
        </Typography>
      </Box>

      <Box className="bottom-content-container">
        <Typography className="title" variant="body1">
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </Typography>

        {increaseText && (
          <Box className={clsx("increase", "copy-container")}>
            <div>
              <Icon className="icon" name={IconName.PriceFreezeArrow} />
            </div>
            <Typography className="text" variant="body1">
              <span dangerouslySetInnerHTML={{ __html: increaseText }}></span>
            </Typography>
          </Box>
        )}

        {decreaseText && (
          <Box className={clsx("decrease", "copy-container")}>
            <div>
              <Icon className="icon" name={IconName.PriceFreezeArrow} />
            </div>
            <Typography className="text" variant="body1">
              <span dangerouslySetInnerHTML={{ __html: decreaseText }}></span>
            </Typography>
          </Box>
        )}

        {similarFlightText && (
          <Box className={clsx("similar-flight", "copy-container")}>
            <div>
              <Icon className="icon" name={IconName.CheckCircleFilledBlue} />
            </div>
            <Typography className="text" variant="body1">
              <span
                dangerouslySetInnerHTML={{ __html: similarFlightText }}
              ></span>
            </Typography>
          </Box>
        )}

        <ActionButton
          className={clsx("price-freeze-continue", {
            isMobile: isMobile,
          })}
          onClick={() => onContinue()}
          defaultStyle="h4r-primary"
          message="Continue"
        />

        {isMobile
          ? renderMobileTermsAndCondition()
          : renderDesktopTermsAndCondition()}
      </Box>
    </Box>
  );
};
