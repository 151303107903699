import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../reducers/types";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getTripCategory,
} from "../search/reducer";
import {
  airlinesSelector,
  airportsSelector,
  flightShopOfferSelector,
  getDestinationCountryCode,
  getOriginCountryCode,
  getTripResultEventProperties,
  selectedTripPriceFreezeOfferSelector,
  selectedTripSelector,
  shopPricingInfoSelector,
  tripDetailsSelector,
} from "../shop/reducer";
import { FlightsCheckout } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const tripId = selectedTripSelector(state).tripId || "";
  return {
    // Flight search
    tripCategory: getTripCategory(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    originCountryCode: getOriginCountryCode(state),
    destinationCountryCode: getDestinationCountryCode(state),

    // Flight shop
    airports: airportsSelector(state, tripId),
    airlines: airlinesSelector(state, tripId),
    selectedTrip: selectedTripSelector(state),
    shopPricingInfo: shopPricingInfoSelector(state),
    tripDetails: tripDetailsSelector(state, tripId),
    tripResultEventProperties: getTripResultEventProperties(state),
    offers: flightShopOfferSelector(state),

    // PriceFreeze
    cheapestPriceFreezeOfferData: selectedTripPriceFreezeOfferSelector(state),
  };
};

const connector = connect(mapStateToProps);

export type FlightsCheckoutConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightsCheckout = connector(FlightsCheckout);
