import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import styles from "./UnavailableRoomsBanner.module.scss";

export const UnavailableRoomsBanner = () => {
  const { t } = useI18nContext();
  return (
    <Box
      className="UnavailableRoomsBanner"
      bgcolor="var(--red-20)"
      borderRadius="var(--border-radius-md)"
      display="flex"
      p={3}
      gridGap="8px"
    >
      <Box className={styles.errorIcon} height={24} width={24} flexShrink={0}>
        <IconComponent
          name={IconName.TripProtectionCrossSolid}
          width="100%"
          height="100%"
        />
      </Box>
      <Typography variant="subtitle2" color="error">
        {t?.("noAvailableRoomsDescription")}
      </Typography>
    </Box>
  );
};
