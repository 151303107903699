import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, SliceStopCountFilter } from "@hopper-b2b/types";
import {
  AccordionCollection,
  ActionButton,
  ActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  hasHTSConnectSupport,
  tenantFlagsEnabled,
  useEnablePricePrediction,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../../../../../App";
import { SortOptionSelection } from "../../../../../../../shop/components/FlightShopHeader/components/SortOptionSelection";
import {
  IShopFilterSelector,
  sortOptionSelector,
} from "../../../../../../../shop/reducer";
import {
  getDestination,
  getOrigin,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getStopsOption,
} from "../../../../../../reducer";
import {
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnArrivalTimeRange,
  setReturnDepartureTimeRange,
} from "../../../../../../actions/actions";
import { setRenderLoadingSteps } from "../../../../../../../shop/actions/actions";
import { useFetchFlights } from "../../../../../../../shop/hooks/useFetchFlights";
import FlightNumberFilterSelection from "../FlightNumberFilterSelection/";
import { TimeWindowSelection } from "../TimeWindowSelection";
import {
  AirlineFilterSelection,
  AirportFilterSelection,
  MaxPriceFilterSelection,
  StopsOptionSelection,
} from "../index";
import "./styles.scss";

export interface IMobileFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
  flightShopFilters: IShopFilterSelector;
  filtersOpen?: boolean;
  isReturn?: boolean;
  setFiltersOpen?: (filtersOpen: boolean) => void;
  setRerunPrediction?: () => void;
}
export const MobileFlightShopSearchFilter = (
  props: IMobileFlightShopSearchFilterProps
) => {
  const { setFiltersOpen, filtersOpen, isReturn, setRerunPrediction } = props;

  const { t } = useI18nContext();
  const tenantIcons = useTenantIcons();

  const [openSearchFilter, setOpenSearchFilter] = useState<boolean>(false);

  const [nonstopFilterSelected, setNonstopFilterSelected] =
    useState<boolean>(false);

  const enablePricePrediction = useEnablePricePrediction();
  const fetchFlights = useFetchFlights();
  const dispatch = useDispatch();

  const handleCloseFilter = useCallback(() => {
    if (nonstopFilterSelected) {
      if (enablePricePrediction && setRerunPrediction) {
        setRerunPrediction();
      }
      dispatch(
        setRenderLoadingSteps(
          [ClientName.UBER, ClientName.NUBANK].includes(
            getEnvVariables("clientName") as ClientName
          )
        )
      );
      fetchFlights();
    }
    if (setFiltersOpen) {
      setFiltersOpen(false);
    } else {
      setOpenSearchFilter(false);
    }
  }, [
    dispatch,
    enablePricePrediction,
    fetchFlights,
    nonstopFilterSelected,
    setFiltersOpen,
    setRerunPrediction,
  ]);

  const tenant = getEnvVariables("clientName");
  const isUberClient = tenant === ClientName.UBER;
  const isHTSConnect = hasHTSConnectSupport();

  const featureFlag = useContext(ClientContext).featureFlag;
  const { hideAirportFilter, flightShopFilters } = props;
  const currSortBy = useSelector(sortOptionSelector);
  const currStops = useSelector(getStopsOption);

  const sortOptionCopy = useMemo(() => {
    return {
      [featureFlag?.enableJetBlueFlightSort
        ? "stopAndDepartureTime"
        : "fareScore"]: t("sortOption.recommended"),
      price: t("sortOption.price"),
      departureTime: t("sortOption.depart"),
      arrivalTime: t("sortOption.arrive"),
      stops: t("sortOption.stops"),
      duration: t("sortOption.duration"),
    };
  }, [featureFlag?.enableJetBlueFlightSort, t]);

  const stopsCopy = useMemo(() => {
    return {
      [SliceStopCountFilter.ANY_NUMBER]: t("searchFilter.anyStop"),
      [SliceStopCountFilter.NONE]: t("searchFilter.noStops"),
      [SliceStopCountFilter.ONE_OR_LESS]: t("searchFilter.oneOrFewerStops"),
      [SliceStopCountFilter.TWO_OR_LESS]: t("searchFilter.twoOrFewerStops"),
    };
  }, [t]);

  const currAppliedFilters = useMemo(() => {
    return tenantFlagsEnabled.FlightShopShowFilterNames
      ? {
          currSortByText: sortOptionCopy[currSortBy],
          currStopsText: stopsCopy[currStops],
        }
      : null;
  }, [currSortBy, currStops, sortOptionCopy, stopsCopy]);

  const origin = useSelector(getOrigin);
  const destination = useSelector(getDestination);

  const originDestinationCopy = useMemo(() => {
    if (origin?.label && destination?.label) {
      const originSplit = origin?.label.split(",");
      const originParenthesisSplit =
        originSplit[originSplit.length - 1].split(" (");
      const destinationSplit = destination?.label.split(",");
      const destinationParenthesisSplit =
        destinationSplit[destinationSplit.length - 1].split(" (");
      return {
        origin: `${originSplit[0]} (${
          originParenthesisSplit[originParenthesisSplit.length - 1]
        }`,
        destination: `${destinationSplit[0]} (${
          destinationParenthesisSplit[destinationParenthesisSplit.length - 1]
        }`,
      };
    }
  }, [destination?.label, origin?.label]);

  const outboundDepartureTimeRange = useSelector(getOutboundDepartureTimeRange);
  const outboundOnChangeDepartureTimeRange = useCallback(
    (min: number, max: number) =>
      dispatch(setOutboundDepartureTimeRange({ min, max })),
    [dispatch]
  );
  const outboundArrivalTimeRange = useSelector(getOutboundArrivalTimeRange);
  const outboundOnChangeArrivalTimeRange = useCallback(
    (min: number, max: number) =>
      dispatch(setOutboundArrivalTimeRange({ min, max })),
    [dispatch]
  );

  const returnDepartureTimeRange = useSelector(getReturnDepartureTimeRange);
  const returnOnChangeDepartureTimeRange = useCallback(
    (min: number, max: number) =>
      dispatch(setReturnDepartureTimeRange({ min, max })),
    [dispatch]
  );
  const returnArrivalTimeRange = useSelector(getReturnArrivalTimeRange);

  const returnOnChangeArrivalTimeRange = useCallback(
    (min: number, max: number) =>
      dispatch(setReturnArrivalTimeRange({ min, max })),
    [dispatch]
  );

  const {
    priceMax,
    priceMin,
    airlineOptions,
    airportOptions,
    flightNumbersByAirline,
  } = flightShopFilters;

  const accordionContents = [
    // Sort by
    {
      title: (
        <div className="header-container">
          {t("searchFilter.sortHeader")}{" "}
          {tenantFlagsEnabled.FlightShopShowFilterNames ? (
            <p className="filter-name">{currAppliedFilters?.currSortByText}</p>
          ) : null}
        </div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <SortOptionSelection showDropdownContentOnly={true} />
        </div>
      ),
    },
    // StopsOption
    {
      title: (
        <div className="header-container">
          {t("searchFilter.stopHeader")}{" "}
          {tenantFlagsEnabled.FlightShopShowFilterNames ? (
            <p className="filter-name">{currAppliedFilters?.currStopsText}</p>
          ) : null}
        </div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <StopsOptionSelection
            showDropdownContentOnly={true}
            setNonstopFilterSelected={setNonstopFilterSelected}
          />
        </div>
      ),
    },
    // MaxPrice
    {
      title: <div className="header-container">{t("searchFilter.price")}</div>,
      body: (
        <div className="mobile-filter-option-selection">
          <MaxPriceFilterSelection
            showDropdownContentOnly={true}
            maximumPrice={priceMax}
            minimumPrice={priceMin}
          />
        </div>
      ),
    },
    // Airline
    {
      title: (
        <div className="header-container">{t("searchFilter.airline")}</div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <AirlineFilterSelection
            showDropdownContentOnly={true}
            allAirlines={airlineOptions}
          />
        </div>
      ),
    },
    // Departure
    ...(!isReturn
      ? [
          {
            title: (
              <div className="header-container">
                {t("searchFilter.departureLabel")}
              </div>
            ),
            body: (
              <TimeWindowSelection
                onChange={outboundOnChangeDepartureTimeRange}
                timeRange={outboundDepartureTimeRange}
                label={t("searchFilter.departureTime", {
                  location: originDestinationCopy?.origin,
                })}
              />
            ),
          },
          {
            title: (
              <div className="header-container">
                {t("searchFilter.arrivalLabel")}
              </div>
            ),
            body: (
              <TimeWindowSelection
                onChange={outboundOnChangeArrivalTimeRange}
                timeRange={outboundArrivalTimeRange}
                label={t("searchFilter.arrivalTime", {
                  location: originDestinationCopy?.destination,
                })}
              />
            ),
          },
        ]
      : []),
    // Arrival
    ...(isReturn
      ? [
          {
            title: <div className="header-container">{t("departure")}</div>,
            body: (
              <div className="mobile-filter-option-selection">
                <TimeWindowSelection
                  onChange={returnOnChangeDepartureTimeRange}
                  timeRange={returnDepartureTimeRange}
                  label={t("searchFilter.departureTime", {
                    location: originDestinationCopy?.destination,
                  })}
                />
              </div>
            ),
          },
          {
            title: (
              <div className="header-container">
                {t("searchFilter.arrivalLabel")}
              </div>
            ),
            body: (
              <div className="mobile-filter-option-selection">
                <TimeWindowSelection
                  onChange={returnOnChangeArrivalTimeRange}
                  timeRange={returnArrivalTimeRange}
                  label={t("searchFilter.arrivalTime", {
                    location: originDestinationCopy?.origin,
                  })}
                />
              </div>
            ),
          },
        ]
      : []),
    // Airport
    ...(hideAirportFilter
      ? []
      : [
          {
            title: (
              <div className="header-container">
                {t("searchFilter.airport")}
              </div>
            ),
            body: (
              <div className="mobile-filter-option-selection">
                <AirportFilterSelection
                  showDropdownContentOnly={true}
                  allAirports={airportOptions}
                />
              </div>
            ),
          },
        ]),
    // Flight Number
    {
      title: (
        <div className="header-container">{t("searchFilter.flightNumber")}</div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <FlightNumberFilterSelection
            showDropdownContentOnly={true}
            allAirlines={airlineOptions}
            flightNumbersByAirline={flightNumbersByAirline}
          />
        </div>
      ),
    },
  ];

  const renderHeader = useMemo(() => {
    return isUberClient
      ? {
          headerElement: (
            <Typography className="custom-mobile-search-header">
              {t("searchFilter.sortAndFilter")}
              <ActionLink
                className="filter-close-button"
                content={
                  <IconComponent
                    ariaLabel="Close button icon"
                    className={clsx("close-button-icon")}
                    name={IconName.Close}
                  />
                }
                label="Close"
                onClick={handleCloseFilter}
              />
            </Typography>
          ),
        }
      : isHTSConnect
      ? {
          headerElement: (
            <Typography
              variant="h4"
              className="hts-custom-mobile-search-header"
            >
              {t("searchFilter.sortAndFilter")}
              <ActionLink
                className="filter-close-button"
                content={
                  <Slot
                    id="dropdown-icon"
                    name="dropdown-icon-close"
                    className="hts-close-modal"
                    component={
                      <img
                        className="hts-close-modal"
                        src={tenantIcons.close}
                        alt=""
                      />
                    }
                  />
                }
                label="Close"
                onClick={handleCloseFilter}
              />
            </Typography>
          ),
        }
      : {
          topRightButton: (
            <ActionLink
              className="filter-close-button"
              content={
                <IconComponent
                  ariaLabel="Close button icon"
                  className={clsx("close-button-icon")}
                  name={IconName.Close}
                />
              }
              label="Close"
              onClick={handleCloseFilter}
            />
          ),
        };
  }, [handleCloseFilter, isUberClient, t, isHTSConnect, tenantIcons.close]);

  return (
    <div className="mobile-flight-shop-search-filter-root">
      <div className="mobile-flight-shop-search-filter-container">
        <MobilePopoverCard
          open={filtersOpen ? filtersOpen : openSearchFilter}
          onClose={handleCloseFilter}
          fullScreen={true}
          className="mobile-flight-shop-search-filter full-screen"
          contentClassName="mobile-flight-shop-search-filter-wrapper"
          bottomButton={
            <ActionButton
              className="mobile-flight-shop-filter-button"
              message={t("searchFilter.apply")}
              onClick={handleCloseFilter}
            />
          }
          {...renderHeader}
        >
          {/* // slot in title and "reset button" */}
          <Slot id="mobile-flight-filter-title" title={t("filters")} />
          <div className="mobile-flight-shop-search-filter-content-container">
            <AccordionCollection
              className="flight-shop-search-filter-accordions-container"
              accordionContents={accordionContents}
              expandIcon={
                <Slot
                  id="dropdown-icon"
                  name="dropdown-icon-end"
                  className="hts-dropdown-icon-end"
                  component={null}
                />
              }
            />
          </div>
        </MobilePopoverCard>
      </div>
    </div>
  );
};
