import { useMemo } from "react";
import clsx from "clsx";
import {
  customFormatDateTime,
  dateStringToNewDate,
} from "@hopper-b2b/utilities";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import {
  HotelItineraryWithType,
  PortalItineraryStatusEnum,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GenericResponseScreen } from "../../GenericResponseScreen";
import { ReactComponent as DuplicateTransaction } from "../../../assets/client/duplicate-transaction.svg";
import { TripsCard } from "../TripsCard";
import styles from "./styles.module.scss";

interface HotelCancellationConfirmationScreenProps {
  hotel: HotelItineraryWithType;
  title: string;
  subtitle: string | string[];
  primaryOnClick: () => void;
  className?: string;
}

const HotelCancellationConfirmationScreen = ({
  className,
  title,
  subtitle,
  primaryOnClick,
  hotel,
}: HotelCancellationConfirmationScreenProps) => {
  const { t, language: locale, brand } = useI18nContext();

  const content = useMemo(
    () => (typeof subtitle == "string" ? subtitle : subtitle.join(" ")),
    [subtitle]
  );

  const formattedDateString = useMemo(
    () =>
      `${customFormatDateTime(
        dateStringToNewDate(hotel.reservation.checkInDate),
        locale,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.tripsList
      )} - ${customFormatDateTime(
        dateStringToNewDate(hotel.reservation.checkOutDate),
        locale,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.tripsList
      )}`,
    [hotel]
  );

  const details = useMemo(
    () =>
      `${
        hotel.reservation.lodgingData?.city
          ? `${hotel.reservation.lodgingData?.city}, `
          : ""
      }${hotel.reservation.lodgingData?.country || ""}`,
    [hotel]
  );

  const hotelName = useMemo(() => hotel.reservation.lodgingData.name, [hotel]);

  return (
    <GenericResponseScreen
      title={title}
      subtitle={content}
      primaryOnClick={primaryOnClick}
      primaryButtonText={t("viewDetails")}
      className={clsx(styles.hotelCancellationConfirmationScreen, className)}
      image={
        <DuplicateTransaction className="hotel-cancellation-confirmation-screen-image" />
      }
      header={t("selfServe.cancelationConfirmation")}
      details={
        <TripsCard
          destination={hotelName}
          dates={formattedDateString}
          details={details}
          status={PortalItineraryStatusEnum.Canceled}
          onClick={primaryOnClick}
        />
      }
    />
  );
};

export default HotelCancellationConfirmationScreen;
