import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { nubankColors as nubankDarkColors } from "./darkTheme";
import { nubankDarkVariables } from "./darkTheme";

export const nubankUltravioletaDarkColors = {
  ...nubankDarkColors,
};
export const nubankUltravioletaDarkVariables = {
  ...nubankDarkVariables,
  // UV
  "UV-Content-Accent-Primary": "var(--violet-70)",
  "UV-Surface-Accent-Primary": "var(--purple-80)",
  "UV-Surface-Accent-Primary-Subtle": "var(--violet-10)",

  primary: "var(--UV-Surface-Accent-Primary)",
  "button-background": "var(--primary)",
};

const nubankUltravioletaDarkTheming = {
  palette: {
    type: "dark",
    primary: {
      light: "#01AAE4",
      main: "#01AAE4",
      contrastText: "#01AAE4",
    },
    secondary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: "#FA6866",
    },
    text: {
      primary: "#bbb",
      secondary: "#878787",
      disabled: "#FFF",
      hint: "#FFF",
    },
  },
  typography: {
    fontFamily: "Graphik, sans-serif",
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.375rem",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.25rem",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    button: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#878787",
      },
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 12,
        fontWeight: 500,
      },
      standardSuccess: {
        backgroundColor: nubankUltravioletaDarkColors.black,
        color: nubankUltravioletaDarkColors.white,
      },
      standardWarning: {
        backgroundColor: nubankUltravioletaDarkColors.black,
        color: nubankUltravioletaDarkColors.white,
      },
      standardError: {
        backgroundColor: nubankUltravioletaDarkColors.black,
        color: nubankUltravioletaDarkColors.white,
      },
      icon: {
        color: `${nubankUltravioletaDarkColors.white} !important`,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor:
          nubankUltravioletaDarkVariables["base-body-background"],
        color: nubankUltravioletaDarkColors["content-default"],
      },
    },
    MuiSlider: {
      root: {
        color: nubankUltravioletaDarkColors.primary,
      },
    },
  },
};

export const muiUltravioletaDarkTheme = asB2BMuiTheme(
  nubankUltravioletaDarkTheming
);
