import { ErrorScreen } from "../../ErrorScreen";
import "./styles.scss";

export interface TripsErrorModalContentProps {
  title: string;
  subtitle: string;
  primaryOnClick: () => void;
  primaryButtonText: string | JSX.Element;
  secondaryOnClick?: () => void;
  secondaryButtonText?: string | JSX.Element;
  onBack?: () => void;
  className?: string;
}

export const TripsErrorModalContent = ({
  title,
  subtitle,
  primaryOnClick,
  primaryButtonText,
  secondaryOnClick,
  secondaryButtonText,
  onBack,
  className,
}) => {
  return (
    <div className="trips-error-modal-content">
      <ErrorScreen
        title={title}
        subtitle={subtitle}
        primaryOnClick={primaryOnClick}
        primaryButtonText={primaryButtonText}
        secondaryOnClick={secondaryOnClick}
        secondaryButtonText={secondaryButtonText}
        onBack={onBack}
        className={className}
      />
    </div>
  );
};
