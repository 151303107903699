// Getters
export const FETCH_TRIP_SUMMARIES = "flightShop/FETCH_TRIP_SUMMARIES";
export type FETCH_TRIP_SUMMARIES = typeof FETCH_TRIP_SUMMARIES;

export const FETCH_TRIP_SUMMARIES_FOR_PREDICTION =
  "flightShop/FETCH_TRIP_SUMMARIES_FOR_PREDICTION";
export type FETCH_TRIP_SUMMARIES_FOR_PREDICTION =
  typeof FETCH_TRIP_SUMMARIES_FOR_PREDICTION;

export const FETCH_TRIP_DETAILS = "flightShop/FETCH_TRIP_DETAILS";
export type FETCH_TRIP_DETAILS = typeof FETCH_TRIP_DETAILS;

export const FETCH_TRIP_SUMMARIES_V2 = "flightShop/FETCH_TRIP_SUMMARIES_V2";
export type FETCH_TRIP_SUMMARIES_V2 = typeof FETCH_TRIP_SUMMARIES_V2;

export const FETCH_TRIP_SUMMARIES_V3 = "flightShop/FETCH_TRIP_SUMMARIES_V3";
export type FETCH_TRIP_SUMMARIES_V3 = typeof FETCH_TRIP_SUMMARIES_V3;

export const FETCH_FLIGHTS_V4 = "flightShop/FETCH_FLIGHTS_V4";
export type FETCH_FLIGHTS_V4 = typeof FETCH_FLIGHTS_V4;

export const FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2 =
  "flightShop/FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2";
export type FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2 =
  typeof FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2;

export const FETCH_SIMILAR_FLIGHTS = "flightShop/FETCH_SIMILAR_FLIGHTS";
export type FETCH_SIMILAR_FLIGHTS = typeof FETCH_SIMILAR_FLIGHTS;

export const FETCH_TRANSFER_TO_SIMILAR_FLIGHTS =
  "flightShop/FETCH_TRANSFER_TO_SIMILAR_FLIGHTS";
export type FETCH_TRANSFER_TO_SIMILAR_FLIGHTS =
  typeof FETCH_TRANSFER_TO_SIMILAR_FLIGHTS;

// Setters
export const SET_TRIP_SUMMARIES = "flightShop/SET_TRIP_SUMMARIES";
export type SET_TRIP_SUMMARIES = typeof SET_TRIP_SUMMARIES;

export const SET_FLIGHTS = "flightShop/SET_FLIGHTS";
export type SET_FLIGHTS = typeof SET_FLIGHTS;

export const SET_TRIP_DETAILS = "flightShop/SET_TRIP_DETAILS";
export type SET_TRIP_DETAILS = typeof SET_TRIP_DETAILS;

export const SET_TRIP_SUMMARIES_ERROR = "flightShop/SET_TRIP_SUMMARIES_ERROR";
export type SET_TRIP_SUMMARIES_ERROR = typeof SET_TRIP_SUMMARIES_ERROR;

export const RERUN_PREDICTION = "flightShop/RERUN_PREDICTION";
export type RERUN_PREDICTION = typeof RERUN_PREDICTION;

export const SET_PREDICTION_ERROR = "flightShop/SET_PREDICTION_ERROR";
export type SET_PREDICTION_ERROR = typeof SET_PREDICTION_ERROR;

export const SET_PREDICTION = "flightShop/SET_PREDICTION";
export type SET_PREDICTION = typeof SET_PREDICTION;

export const SET_OFFERS = "flightShop/SET_OFFERS";
export type SET_OFFERS = typeof SET_OFFERS;

export const SET_PRICE_PRICE_FREEZE_OFFER =
  "flightShop/SET_PRICE_PRICE_FREEZE_OFFER";
export type SET_PRICE_PRICE_FREEZE_OFFER = typeof SET_PRICE_PRICE_FREEZE_OFFER;

export const FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER =
  "flightShop/FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER";
export type FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER =
  typeof FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER;

export const SET_CHOSEN_OUTGOING_SLICE = "flightShop/SET_CHOSEN_OUTGOING_SLICE";
export type SET_CHOSEN_OUTGOING_SLICE = typeof SET_CHOSEN_OUTGOING_SLICE;

export const SET_CHOSEN_RETURN_SLICE = "flightShop/SET_CHOSEN_RETURN_SLICE";
export type SET_CHOSEN_RETURN_SLICE = typeof SET_CHOSEN_RETURN_SLICE;

export const SET_FLIGHT_SHOP_PROGRESS = "flightShop/SET_FLIGHT_SHOP_PROGRESS";
export type SET_FLIGHT_SHOP_PROGRESS = typeof SET_FLIGHT_SHOP_PROGRESS;

export const SET_RENDER_LOADING_STEPS = "flightShop/SET_RENDER_LOADING_STEPS";
export type SET_RENDER_LOADING_STEPS = typeof SET_RENDER_LOADING_STEPS;

export const SET_SELECTED_TRIP_PRICE_FREEZE_OFFER =
  "flightShop/SET_SELECTED_TRIP_PRICE_FREEZE_OFFER";
export type SET_SELECTED_TRIP_PRICE_FREEZE_OFFER =
  typeof SET_SELECTED_TRIP_PRICE_FREEZE_OFFER;

export const SET_SORT_OPTION = "flightShop/SET_SORT_OPTION";
export type SET_SORT_OPTION = typeof SET_SORT_OPTION;

export const POPULATE_FLIGHT_SHOP_QUERY_PARAMS =
  "flightShop/POPULATE_FLIGHT_SHOP_QUERY_PARAMS";
export type POPULATE_FLIGHT_SHOP_QUERY_PARAMS =
  typeof POPULATE_FLIGHT_SHOP_QUERY_PARAMS;

export const CREATE_WATCH = "flightShop/CREATE_WATCH";
export type CREATE_WATCH = typeof CREATE_WATCH;

export const SET_CREATE_WATCH_CALL_STATE =
  "flightShop/SET_CREATE_WATCH_CALL_STATE";
export type SET_CREATE_WATCH_CALL_STATE = typeof SET_CREATE_WATCH_CALL_STATE;

export const UPDATE_WATCH = "flightShop/UPDATE_WATCH";
export type UPDATE_WATCH = typeof UPDATE_WATCH;

export const SET_UPDATE_WATCH_CALL_STATE =
  "flightShop/SET_UPDATE_WATCH_CALL_STATE";
export type SET_UPDATE_WATCH_CALL_STATE = typeof SET_UPDATE_WATCH_CALL_STATE;

export const DELETE_WATCH = "flightShop/DELETE_WATCH";
export type DELETE_WATCH = typeof DELETE_WATCH;

export const SET_DELETE_WATCH_CALL_STATE =
  "flightShop/SET_DELETE_WATCH_CALL_STATE";
export type SET_DELETE_WATCH_CALL_STATE = typeof SET_DELETE_WATCH_CALL_STATE;

export const LIST_WATCHES = "flightShop/LIST_WATCHES";
export type LIST_WATCHES = typeof LIST_WATCHES;

export const SET_LIST_WATCHES_CALL_STATE =
  "flightShop/SET_LIST_WATCHES_CALL_STATE";
export type SET_LIST_WATCHES_CALL_STATE = typeof SET_LIST_WATCHES_CALL_STATE;

export const SET_WATCHES = "flightShop/SET_WATCHES";
export type SET_WATCHES = typeof SET_WATCHES;

export const SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP =
  "flightShop/SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP";
export type SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP =
  typeof SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP;

export const SET_CALENDAR_BUCKETS = "flightShop/SET_CALENDAR_BUCKETS";
export type SET_CALENDAR_BUCKETS = typeof SET_CALENDAR_BUCKETS;

export const SET_OPEN_CALENDAR_MODAL = "flightShop/SET_OPEN_CALENDAR_MODAL";
export type SET_OPEN_CALENDAR_MODAL = typeof SET_OPEN_CALENDAR_MODAL;

export const SET_OPEN_LOCATION_MODAL = "flightShop/SET_OPEN_LOCATION_MODAL";
export type SET_OPEN_LOCATION_MODAL = typeof SET_OPEN_LOCATION_MODAL;

export const SET_FLIGHT_SHOP_TYPE = "flightShop/SET_FLIGHT_SHOP_TYPE";
export type SET_FLIGHT_SHOP_TYPE = typeof SET_FLIGHT_SHOP_TYPE;

export const RESET_SELECTED_TRIP = "flightShop/RESET_SELECTED_TRIP";
export type RESET_SELECTED_TRIP = typeof RESET_SELECTED_TRIP;

export const SET_SIMILAR_FLIGHTS_RESPONSE =
  "flightShop/SET_SIMILAR_FLIGHTS_RESPONSE";
export type SET_SIMILAR_FLIGHTS_RESPONSE = typeof SET_SIMILAR_FLIGHTS_RESPONSE;

export const SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  "flightShop/SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED";
export type SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  typeof SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED;

export const SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE =
  "flightShop/SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE";
export type SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE =
  typeof SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE;

export const SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  "flightShop/SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED";
export type SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  typeof SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED;

export const SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED =
  "flightShop/SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED";
export type SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED =
  typeof SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED;

// Tied to https://app.launchdarkly.com/uber/staging/features/uber-air-fintech-selection/targeting
export const SET_DECLINE_FINTECH_OPTIONS =
  "flightShop/SET_DECLINE_FINTECH_OPTIONS";
export type SET_DECLINE_FINTECH_OPTIONS = typeof SET_DECLINE_FINTECH_OPTIONS;

export const SET_CHFAR_ID = "flightShop/SET_CHFAR_ID";
export type SET_CHFAR_ID = typeof SET_CHFAR_ID;

export const FETCH_CHFAR_FLIGHTS = "flightShop/FETCH_CHFAR_FLIGHTS";
export type FETCH_CHFAR_FLIGHTS = typeof FETCH_CHFAR_FLIGHTS;

export const SET_FLIGHT_SHOP_DEFAULT_SORT =
  "flightShop/SET_FLIGHT_SHOP_DEFAULT_SORT";
export type SET_FLIGHT_SHOP_DEFAULT_SORT = typeof SET_FLIGHT_SHOP_DEFAULT_SORT;

export const SET_SHOP_TRACKING_PROPERTIES =
  "flightShop/SET_SHOP_TRACKING_PROPERTIES";
export type SET_SHOP_TRACKING_PROPERTIES = typeof SET_SHOP_TRACKING_PROPERTIES;

export const SET_TOP_FLIGHTS_IDS = "flightShop/SET_TOP_FLIGHTS_IDS";
export type SET_TOP_FLIGHTS_IDS = typeof SET_TOP_FLIGHTS_IDS;
