import dayjs, { Dayjs } from "dayjs";
import { LodgingData, RoomInfo } from "@b2bportal/lodging-api";

import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingImagePlaceholder } from "@hopper-b2b/ui";

import { ReactComponent as BedIcon } from "../../../../assets/client/bed-single-outline.svg";
import { ReactComponent as BedWhiteIcon } from "../../../../assets/client/darkMode/bed-single-outline-white.svg";
import { ReactComponent as GuestIcon } from "../../../../assets/client/two-users.svg";
import { ReactComponent as GuestWhiteIcon } from "../../../../assets/client/darkMode/two-users-white.svg";
import { useDarkModePreferred } from "../../../../utils/colors";
import styles from "./styles.module.scss";

const DATE_FORMAT = "dddd, D MMM YYYY";

export interface HotelSummaryProps {
  lodging: Partial<LodgingData>;
  room: Partial<RoomInfo>;
  fromDate: Dayjs;
  untilDate: Dayjs;
  guestCount: number;
}

export const HotelSummary = ({
  lodging,
  room,
  fromDate,
  untilDate,
  guestCount,
}: HotelSummaryProps) => {
  const { t } = useI18nContext();
  const isDarkMode = useDarkModePreferred();

  const lodgingImageUrl = lodging.media?.[0]?.url ?? LodgingImagePlaceholder;
  const roomImageUrl = room.media?.[0]?.url ?? lodgingImageUrl;
  const roomDescription = room.name;
  const bedsDescription = room.beds.description;
  const maxAdults = room.maxAdults;

  return (
    <>
      {/* Hotel Summary */}
      <div className={styles.hotelSummaryContainer}>
        {roomImageUrl ? (
          <span
            className={styles.roomImage}
            style={{ backgroundImage: `url(${roomImageUrl})` }}
          />
        ) : null}
        <div className={styles.hotelInfo}>
          <h2 className={styles.hotelInfoText}>{lodging.name}</h2>
          <p className={styles.hotelInfoText}>
            {roomDescription}
            {roomDescription ? `, ${bedsDescription}` : bedsDescription ?? null}
          </p>
          <div className={styles.rowItem}>
            {isDarkMode ? <BedWhiteIcon /> : <BedIcon />}
            <p className={styles.hotelInfoSubtext}>{bedsDescription}</p>
          </div>
          <div className={styles.rowItem}>
            {isDarkMode ? <GuestWhiteIcon /> : <GuestIcon />}
            <p className={styles.hotelInfoSubtext}>
              {t("passengerInformation.guestCount", {
                count: typeof maxAdults === "number" ? maxAdults : guestCount,
              })}
            </p>
          </div>
        </div>
      </div>
      {/* Check in/out details */}
      <div>
        <div className={styles.bookingDetails}>
          <p className={styles.bookingDetailsHeader}>{t("bookingDetails")}</p>
        </div>
        <div className={styles.bookingDetailsContainer}>
          <h3 className={styles.bookingDetailsInfoHeader}>{t("checkIn")}</h3>
          <p className={styles.bookingDetailsInfo}>
            {dayjs(fromDate).format(DATE_FORMAT)}
          </p>
        </div>
        <div className={styles.bookingDetailsContainer}>
          <h3 className={styles.bookingDetailsInfoHeader}>{t("checkOut")}</h3>
          <p className={styles.bookingDetailsInfo}>
            {dayjs(untilDate).format(DATE_FORMAT)}
          </p>
        </div>
      </div>
    </>
  );
};
