import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CallState,
  Dealness,
  FiatPrice,
  Prediction,
  PriceDropProtectionEnum,
  PRICE_DROP_VIEWED,
  RewardsPrice,
  TripCategory,
  VIEWED_FORECAST,
  VIEWED_PRICE_DROP_DETAILS,
  WatchState,
} from "@hopper-b2b/types";
import {
  B2BSpinner,
  B2BWatchImage,
  ButtonWrap,
  Icon,
  IconName,
  IPriceWatchOptInState,
  PredictionForecast,
  PriceDropProtection,
  PriceDropProtectionImage,
  PriceForecast,
  PriceFreeze,
  PriceFreezeImage,
  PricePredictionCard,
  PricePredictionRecommendation,
  PriceWatchCard,
  PriceWatchOptIn,
  Tag,
} from "@hopper-b2b/ui";
import {
  getRewardsString,
  useApiConfigSelector,
  useEnablePriceFreeze,
} from "@hopper-b2b/utilities";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { ClientContext } from "../../../../../../App";
import { flightApiConfigStoreKey } from "../../../../../../reducers/types";
import {
  PATH_PRICE_DROP_PROTECTION,
  PATH_PRICE_FREEZE,
  PATH_PRICE_PREDICTION_PRICE_WATCH,
} from "../../../../../../utils/urlPaths";
import {
  getLowestPriceInFiat,
  getLowestPriceInRewards,
  getMaxRefundForPrediction,
  getMonitoringDaysCountForPrediction,
  getPriceFreezeTitleVars,
  getPriceRecommendationSubtitle,
  getPriceRecommendationTitle,
  useGetPriceDropSubtitle,
  useGetPriceWatchCopy,
  useGetPriceWatchTitles,
} from "../../../../constants";
import { PriceFreezeEntry } from "../../../PriceFreezeEntry";
import { DesktopPricePredictionConnectorProps } from "./container";
import "./styles.scss";

export interface IDesktopPricePredictionProps
  extends DesktopPricePredictionConnectorProps,
    RouteComponentProps {}

export const getPriceFreezeRewardsString = (
  priceFreezeRewards: { [key: string]: RewardsPrice } | undefined | null,
  selectedRewardsAccountId: string | null
) => {
  if (priceFreezeRewards && selectedRewardsAccountId) {
    const rewards = priceFreezeRewards[selectedRewardsAccountId];
    return getRewardsString(rewards);
  }
  return "";
};

export const DesktopPricePrediction = (props: IDesktopPricePredictionProps) => {
  const {
    prediction,
    tripCategory,
    hasUnsupportedPredictionFilters,
    resetFilters,
    history,
    isWatching,
    createWatch,
    listWatchCallState,
    deleteWatch,
    createWatchCallState,
    predictionLoading,
    selectedRewardsAccountId,
    viewedForecastProperties,
    priceDropViewedProperties,
    setOpenCalendarModal,
    setCreateWatchCallState,
    hidden,
    priceFreezeOffer,
    priceFreezeFiat,
    priceFreezeRewards,
    priceFreezeCap,
    tripDetails,
    fareId,
    priceFreezeDuration,
    viewedPriceFreezeProperties,
    airports,
    cheapestFrozenPrice,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
    highlightedPriceFreezeBox,
    highlightedPriceFreezeButton,
    showPriceFreezeIcon,
    highlightedRewards,
  } = props;

  const { t, formatFiatCurrency } = useI18nContext();

  const [watchModalOpen, setWatchModalOpen] = useState(false);
  const [priceFreezeModalOpen, setPriceFreezeModalOpen] = useState(false);
  const [openPriceFreezeDetails, setOpenPriceFreezeDetails] =
    React.useState<boolean>(false);
  const [priceDropPredictionModalOpen, setPriceDropPredictionModalOpen] =
    useState(false);
  const [pricePredictionModalOpen, setPricePredictionModalOpen] =
    useState(false);
  const [showForecast, setShowForecast] = useState(false);
  const [justWatched, setJustWatched] = useState(false);
  const [hasViewedForecast, setHasViewedForecast] = useState(false);
  const { sessionInfo } = useContext(ClientContext);
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);
  const getWatchState = () => {
    if (isWatching) {
      return justWatched ? WatchState.JustWatched : WatchState.Watched;
    } else {
      return WatchState.NotWatching;
    }
  };
  const getPriceDropSubtitle = useGetPriceDropSubtitle();
  const priceWatchTitles = useGetPriceWatchTitles();

  const showFlightPriceFreeze = useEnablePriceFreeze();

  const getForecastedPrice = (prediction: Prediction) => {
    if (prediction) {
      const key =
        prediction.dealness === Dealness.Wait ? "minPrice" : "maxPrice";
      const displayText = prediction.pricePrediction[key]?.display || "";
      if (displayText) {
        return (
          <Typography className="content" variant="body1">
            {displayText}
            {/* TODO: display RewardText once the backend is ready */}
          </Typography>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  const onResetFilters = () => {
    resetFilters();
  };
  const onChangeDates = () => {
    setOpenCalendarModal(true);
  };

  useEffect(() => {
    if (prediction && isPDPEligible) {
      trackEvent(
        {
          eventName: PRICE_DROP_VIEWED,
          properties: {
            ...priceDropViewedProperties,
            page: "flight_list",
          },
        },
        apiConfig
      );
    }
  }, [prediction, isPDPEligible, priceDropViewedProperties, apiConfig]);

  useEffect(() => {
    if (prediction && viewedForecastProperties && !hasViewedForecast) {
      if (isPriceFreezeOfferIncludedInShopSummary) {
        // note: isTripDetailsLoading is of type boolean | null
        if (isTripDetailsLoading === false && viewedPriceFreezeProperties) {
          trackEvent(
            {
              eventName: VIEWED_FORECAST,
              properties: {
                ...viewedForecastProperties,
                ...(showFlightPriceFreeze
                  ? viewedPriceFreezeProperties
                  : undefined),
              },
            },
            apiConfig
          );
          setHasViewedForecast(true);
        }
      } else {
        trackEvent(
          {
            eventName: VIEWED_FORECAST,
            properties: {
              ...viewedForecastProperties,
            },
          },
          apiConfig
        );
        setHasViewedForecast(true);
      }
    }
  }, [
    prediction,
    viewedForecastProperties,
    viewedPriceFreezeProperties,
    hasViewedForecast,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
    showFlightPriceFreeze,
    apiConfig,
  ]);

  const onWatchClick = (email: string) => {
    createWatch(email);
    setJustWatched(true);
  };

  const getOptInState = () => {
    if (
      createWatchCallState === CallState.InProcess ||
      listWatchCallState === CallState.InProcess
    ) {
      return IPriceWatchOptInState.InProcess;
    } else if (isWatching) {
      return IPriceWatchOptInState.Watching;
    } else if (createWatchCallState === CallState.Failed) {
      return IPriceWatchOptInState.Failure;
    } else {
      return IPriceWatchOptInState.NotWatching;
    }
  };

  const userEmail = sessionInfo?.userInfo?.email || "";

  const priceWatchCopy = useGetPriceWatchCopy(
    getWatchState(),
    hasUnsupportedPredictionFilters,
    prediction?.predictionCopy
  );
  const forecastTitles = [
    { title: t("priceForecast.greatTitle"), dealness: Dealness.Great },
    { title: t("priceForecast.goodTitle"), dealness: Dealness.Good },
    { title: t("priceForecast.fairTitle"), dealness: Dealness.Fair },
    { title: t("priceForecast.waitTitle"), dealness: Dealness.Wait },
  ];

  const priceFreezeTitleVars = getPriceFreezeTitleVars({
    priceFreezeFiat,
    priceFreezeRewards,
    selectedRewardsAccountId,
    priceFreezeCap,
    duration: priceFreezeDuration,
  });

  return (
    <Box className={clsx("desktop-price-prediction-root", { hidden: hidden })}>
      {predictionLoading ? (
        <Box className="prediction-loading">
          <B2BSpinner />
        </Box>
      ) : prediction && prediction.predictionCopy ? (
        <>
          <Dialog
            role="tooltip"
            id="desktop-price-prediction-popover"
            open={pricePredictionModalOpen}
            className="price-prediction-popup"
            onClose={() => setPricePredictionModalOpen(false)}
          >
            <Box className="price-prediction-wrapper">
              <PriceDropProtection
                className="price-prediction-card"
                image={PriceDropProtectionImage}
                title={t("pdpTitle")}
                onClick={() =>
                  window.open(`${PATH_PRICE_PREDICTION_PRICE_WATCH} `, "_blank")
                }
                onClose={() => setPricePredictionModalOpen(false)}
                subtitle={t("flightShopPdpSubtitle")}
                header={t("fligthShopPdpHeader")}
                ctaText={t("readTermsAndConditions")}
                icon={
                  <Icon className="icon" name={IconName.PricePredictionIcon} />
                }
              />
            </Box>
          </Dialog>
          {hasUnsupportedPredictionFilters && (
            <Typography
              variant="caption"
              className="unsupported-filters-banner"
            >
              {t("pricePrediction.unsupportedFilters")}
            </Typography>
          )}
          <Box className="prediction-watch-container">
            {!hasUnsupportedPredictionFilters ? (
              <Box className="price-prediction-container">
                <Box className="prediction-info">
                  <PricePredictionRecommendation
                    className="price-recommendation"
                    hideButton={true}
                    title={getPriceRecommendationTitle(
                      getWatchState(),
                      prediction.predictionCopy
                    )}
                    subtitle={getPriceRecommendationSubtitle(
                      getWatchState(),
                      prediction.predictionCopy,
                      prediction.dealness,
                      false
                    )}
                    pdpButtonProps={{
                      displayPDP: isPDPEligible,
                      onPDPModalClick: () => {
                        if (isPDPEligible) {
                          trackEvent(
                            {
                              eventName: VIEWED_PRICE_DROP_DETAILS,
                              properties: {
                                ...priceDropViewedProperties,
                                page: "flight_list",
                              },
                            },
                            apiConfig
                          );
                          setPriceDropPredictionModalOpen(true);
                        }
                      },
                      priceDiff:
                        getMaxRefundForPrediction(prediction) || undefined,
                      pdpButtonAriaLabelledby:
                        "desktop-price-prediction-price-drop-protection-popup",
                      pdpButtonAriaLabel: t(
                        "pricePrediction.priceDropAriaLabel"
                      ),
                    }}
                    onPricePredictionModalClick={() => {
                      trackEvent(
                        {
                          eventName: VIEWED_PRICE_DROP_DETAILS,
                          properties: {
                            ...priceDropViewedProperties,
                            page: "flight_list",
                          },
                        },
                        apiConfig
                      );
                      setPricePredictionModalOpen(true);
                    }}
                    infoIconAriaLabelledby="desktop-price-prediction-popover"
                    infoIconAriaLabel={t("pricePrediction.ariaLabel")}
                  />
                  <Dialog
                    role="tooltip"
                    id="desktop-price-prediction-price-drop-protection-popup"
                    open={priceDropPredictionModalOpen}
                    className="price-drop-protection-popup"
                    onClose={() => setPriceDropPredictionModalOpen(false)}
                  >
                    <Box className="price-drop-protection-wrapper">
                      <PriceDropProtection
                        className="price-drop-protection-card"
                        image={PriceDropProtectionImage}
                        title={t("pdpTitle")}
                        onClick={() =>
                          window.open(
                            `${PATH_PRICE_DROP_PROTECTION} `,
                            "_blank"
                          )
                        }
                        onClose={() => setPriceDropPredictionModalOpen(false)}
                        subtitle={getPriceDropSubtitle(
                          getMonitoringDaysCountForPrediction(prediction),
                          getMaxRefundForPrediction(prediction)
                        )}
                        header={t("flightBookPdpHeader")}
                        ctaText={t("readTermsAndConditions")}
                      />
                    </Box>
                  </Dialog>
                  <PricePredictionCard
                    className="current-price"
                    title={t("pricePrediction.currentPrice")}
                    subtitle={
                      tripCategory === TripCategory.ONE_WAY
                        ? t("oneWayPerTraveler")
                        : t("roundTripPerTraveler")
                    }
                    content={
                      prediction.lowestPrice ? (
                        <Typography className="content">
                          <b>{getLowestPriceInFiat(prediction.lowestPrice)}</b>
                          {" or "}
                          {getLowestPriceInRewards(
                            prediction.lowestPrice,
                            selectedRewardsAccountId
                          )}
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    icon={
                      <Tag
                        className={prediction.dealness}
                        label={
                          prediction.dealness === Dealness.Wait
                            ? t("pricePrediction.waitIcon")
                            : t("pricePrediction.bookNowIcon")
                        }
                      />
                    }
                  />
                </Box>
                <PriceForecast
                  className="price-forecast"
                  items={forecastTitles}
                  dealness={prediction.dealness}
                />
                {!hasUnsupportedPredictionFilters && (
                  <ButtonWrap
                    className="view-price-forecast-button"
                    tabIndex={0}
                    aria-label={t("pricePrediction.ariaLabel")}
                    aria-expanded={showForecast}
                    onClick={() => setShowForecast(!showForecast)}
                  >
                    <Typography
                      className="view-price-forecast-copy"
                      variant="button"
                    >
                      {t("pricePrediction.viewButton")}
                      <Icon
                        name={IconName.Dropdown}
                        className={
                          showForecast
                            ? "transformed-dropdown-icon"
                            : "dropdown-icon"
                        }
                      />
                    </Typography>
                  </ButtonWrap>
                )}
              </Box>
            ) : (
              <Box className="price-prediction-error-container">
                <Typography variant="h6" className="prediction-error-title">
                  {t("pricePrediction.unavailableHeader")}
                  <ButtonWrap
                    className="prediction-modal-icon"
                    aria-labelledby="desktop-price-prediction-popover"
                    onClick={() => setPricePredictionModalOpen(true)}
                  >
                    <Icon
                      name={IconName.InfoCircle}
                      ariaLabel={t("pricePrediction.ariaLabel")}
                    />
                  </ButtonWrap>
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="prediction-error-subtitle"
                >
                  {t("pricePrediction.adjustOrReset")}
                </Typography>
                <Button
                  onClick={onResetFilters}
                  className={"change-dates-button"}
                >
                  {t("resetFilters")}
                </Button>
              </Box>
            )}
            <Box
              className={clsx("price-watch-container", {
                "highlighted-price-freeze-box": highlightedPriceFreezeBox,
              })}
            >
              <Typography variant="h4" className="not-ready-book-header">
                {t("notReadyToBook")}
              </Typography>
              {showFlightPriceFreeze && !!priceFreezeOffer && (
                <PriceFreezeEntry
                  onClickInfoIcon={() => setPriceFreezeModalOpen(true)}
                  airports={airports}
                  priceFreezeModalOpen={priceFreezeModalOpen}
                  isLowestPrice={true}
                  showPriceFreezeDetails={true}
                  openPriceFreezeDetails={openPriceFreezeDetails}
                  setOpenPriceFreezeDetails={setOpenPriceFreezeDetails}
                  history={history}
                  priceFreezeOffer={priceFreezeOffer}
                  tripDetails={tripDetails}
                  fareId={fareId}
                  fiatPrice={formatFiatCurrency(priceFreezeFiat as FiatPrice)}
                  duration={priceFreezeDuration}
                  rewards={getPriceFreezeRewardsString(
                    priceFreezeRewards,
                    selectedRewardsAccountId
                  )}
                  frozenPrice={cheapestFrozenPrice}
                  highlightedButton={highlightedPriceFreezeButton}
                  showFreezeIcon={showPriceFreezeIcon}
                  highlightedRewards={highlightedRewards}
                />
              )}
              <PriceWatchCard
                title={priceWatchCopy}
                buttonCopy={
                  isWatching
                    ? t("priceWatch.watching")
                    : t("priceWatch.watchThisTrip")
                }
                unsupportedFilterCopy={
                  hasUnsupportedPredictionFilters
                    ? t("pricePrediction.unsupportedFiltersCopy")
                    : ""
                }
                onClick={() =>
                  isWatching ? deleteWatch() : setWatchModalOpen(true)
                }
                isWatching={isWatching}
              />
            </Box>
          </Box>
          <Dialog
            open={watchModalOpen}
            className="flight-watch-opt-in-popup"
            onClose={() => setWatchModalOpen(false)}
          >
            <Box className="price-watch-opt-in-wrapper">
              <PriceWatchOptIn
                className="price-watch-card"
                image={B2BWatchImage}
                titles={priceWatchTitles(prediction.predictionCopy)}
                errors={{
                  invalidEmail: t("priceWatchErrors.invalidEmail"),
                  createErrorTitle: t("priceWatchErrors.createErrorTitle"),
                  createErrorSubtitle: t(
                    "priceWatchErrors.createErrorSubtitle"
                  ),
                }}
                optInState={getOptInState()}
                onClick={(email) => onWatchClick(email)}
                onClose={() => {
                  setWatchModalOpen(false);
                  setCreateWatchCallState(CallState.NotCalled);
                }}
                userEmail={userEmail}
                buttonClassName={"b2b"}
                onTermsConditionClick={() =>
                  window.open(`${PATH_PRICE_PREDICTION_PRICE_WATCH} `, "_blank")
                }
                ctaText={t("readTermsAndConditions")}
              />
            </Box>
          </Dialog>
          <Dialog
            open={priceFreezeModalOpen}
            className="flight-price-freeze-popup"
            onClose={() => setPriceFreezeModalOpen(false)}
          >
            <Box className="price-freeze-wrapper">
              <PriceFreeze
                header={t("priceFreezeTitles.header")}
                title={t("priceFreezeTitles.title", {
                  durationText: priceFreezeTitleVars.durationTextI18nKey
                    ? t(priceFreezeTitleVars.durationTextI18nKey.i18nKey, {
                        count: priceFreezeTitleVars.durationTextI18nKey.count,
                      })
                    : "",
                  amount: priceFreezeTitleVars.amount,
                  rewards: priceFreezeTitleVars.rewards,
                })}
                increaseText={t("priceFreezeTitles.increaseText", {
                  priceString: priceFreezeTitleVars.priceString,
                })}
                decreaseText={t("priceFreezeTitles.decreaseText")}
                similarFlightText={t("priceFreezeTitles.similarFlightText")}
                ctaText={t("priceFreezeTitles.ctaText")}
                className="price-freeze-card"
                image={PriceFreezeImage}
                onClose={() => setPriceFreezeModalOpen(false)}
                onContinue={() => {
                  setPriceFreezeModalOpen(false);
                  setOpenPriceFreezeDetails(true);
                }}
                onClick={() => window.open(`${PATH_PRICE_FREEZE}`, "_blank")}
              />
            </Box>
          </Dialog>
          {showForecast && !hasUnsupportedPredictionFilters && (
            <Box className="prediction-forecast-container">
              {!!getForecastedPrice(prediction) && (
                <PricePredictionCard
                  className="forecasted-price"
                  title={
                    prediction.dealness === Dealness.Wait
                      ? t("pricePrediction.forecastedLowest")
                      : t("pricePrediction.forecastedHighest")
                  }
                  icon={
                    <Icon
                      className={
                        prediction.dealness === Dealness.Wait
                          ? "forecasted-lowest-icon"
                          : "forecasted-highest-icon"
                      }
                      name={
                        prediction.dealness === Dealness.Wait
                          ? IconName.DecreaseReviewed
                          : IconName.IncreasedReviewed
                      }
                    />
                  }
                  content={getForecastedPrice(prediction)}
                  subtitle={
                    tripCategory === TripCategory.ONE_WAY
                      ? t("oneWayPerTraveler")
                      : t("roundTripPerTraveler")
                  }
                  isForecastedPrice
                />
              )}
              <PredictionForecast
                intervals={prediction.predictionCopy.intervals}
              ></PredictionForecast>
            </Box>
          )}
        </>
      ) : (
        <Box className="prediction-no-data">
          <Typography variant="h6" className="prediction-no-data-title">
            {t("noDataErrorTitle")}
            <ButtonWrap
              className="prediction-modal-icon"
              aria-labelledby="desktop-price-prediction-popover"
              onClick={() => setPricePredictionModalOpen(true)}
            >
              <Icon
                name={IconName.InfoCircle}
                ariaLabel={t("pricePrediction.ariaLabel")}
              />
            </ButtonWrap>
          </Typography>
          <Typography
            variant="subtitle2"
            className="prediction-no-data-subtitle"
          >
            {t("noDataErrorSubtitle")}
          </Typography>
          <Button onClick={onChangeDates} className={"change-dates-button"}>
            {t("changeDates")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
