import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";

import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { BreadcrumbStep, Breadcrumbs } from "@hopper-b2b/ui";

import { getTripCategory } from "../../../search/reducer";
import { setFlightShopProgress } from "../../../shop/actions/actions";
import {
  FlightShopStep,
  flightShopProgressSelector,
  selectedTripSelector,
} from "../../../shop/reducer";
import { getExchangeType } from "../../reducer/selectors";
// import { getIsFtc } from "../../selectors";
import { skipShopAction } from "../../utils";

import "./styles.scss";

export interface IShopBreadcrumbsProps {
  backToTrips: () => void;
}

const defaultProps: Partial<IShopBreadcrumbsProps> = {};

const ShopBreadcrumbs = (_: IShopBreadcrumbsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useI18nContext();

  const { outboundSelection, returnSelection } = useSelector(getExchangeType);
  // const isFtc = useSelector(getIsFtc);
  const shopStep = useSelector(flightShopProgressSelector);
  const { outgoingSliceId, returnSliceId } = useSelector(selectedTripSelector);
  const tripCategory = useSelector(getTripCategory);

  const isOneWay = tripCategory === TripCategory.ONE_WAY;
  // const returnDisabled = isOneWay || !outgoingSliceId;
  const reviewDisabled = isOneWay
    ? Boolean(!outgoingSliceId)
    : Boolean(!outgoingSliceId && !returnSliceId);
  const skipChooseDeparture = skipShopAction(outboundSelection);
  const skipChooseReturn = skipShopAction(returnSelection);

  const shopSteps = useMemo(() => {
    const steps: BreadcrumbStep[] = [
      {
        disabled: skipChooseDeparture,
        isSelected: shopStep === FlightShopStep.ChooseDeparture,
        key: "chooseDeparture",
        label: t("exchangeable.shop.breadcrumbs.chooseDeparture"),
        onClick: () =>
          dispatch(setFlightShopProgress(FlightShopStep.ChooseDeparture)),
        visited: true,
      },
    ];

    if (!isOneWay) {
      steps.push({
        disabled: skipChooseReturn,
        isSelected: shopStep === FlightShopStep.ChooseReturn,
        key: "chooseReturn",
        label: t("exchangeable.shop.breadcrumbs.chooseReturn"),
        onClick: () =>
          dispatch(setFlightShopProgress(FlightShopStep.ChooseReturn)),
        visited: true,
      });
    }

    steps.push({
      disabled: reviewDisabled,
      isSelected: shopStep === FlightShopStep.ReviewItinerary,
      key: "reviewItinerary",
      label: t("exchangeable.shop.breadcrumbs.reviewItinerary"),
      onClick: () =>
        dispatch(setFlightShopProgress(FlightShopStep.ReviewItinerary)),
      visited: true,
    });

    return steps;
  }, [
    dispatch,
    isOneWay,
    reviewDisabled,
    shopStep,
    skipChooseDeparture,
    skipChooseReturn,
    t,
  ]);

  return (
    <Breadcrumbs
      showBack
      className="exchange-shop-breadcrumbs"
      steps={shopSteps}
    />
  );
};

ShopBreadcrumbs.defaultProps = defaultProps;

export default ShopBreadcrumbs;
