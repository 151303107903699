import { TripPricingSummary } from "@b2bportal/air-booking-api";
import {
  ChooseSavingsProperties,
  IPerson,
  PersonId,
  ProductType,
  UserInfo,
} from "@hopper-b2b/types";
import { B2BSpinner, LoadingIndicator } from "@hopper-b2b/ui";
import { ReactNode } from "react";
import { CheckoutComponentProps } from "../../CheckoutComponent";
import { InfantSeatPickerModal } from "./components/InfantSeatPickerModal";
import { TravelerSelectWorkflow } from "./components/TravelerSelectWorkflow";
import {
  ITravelerSelectWorkflowProps,
  TravelerTypeTitles,
} from "./components/TravelerSelectWorkflow/component";

interface PassengerInformationProps
  extends CheckoutComponentProps,
    ITravelerSelectWorkflowProps {
  isLoading: boolean;
  loadingMessage?: string;
  loadingComponent?: ReactNode;
  userPassengers: IPerson[];
  userInfo?: UserInfo;
  isMobile?: boolean;
  disabled?: boolean;
  travelerWorkflowTitles: TravelerTypeTitles;
  currentInfantId?: PersonId;
  handleInfantPickerModalContinue: (seatType: "OwnSeat" | "OnLap") => void;
  handleInfantPickerModalClose: () => void;
  tripPricing: TripPricingSummary | null;
  isInfantModalLoading: boolean;
  clientAssets?: { logo: string };
  desktopRightContent: JSX.Element;
  desktopTopContent: JSX.Element;
  isJetBlueTenant?: boolean;
  headerTitle?: string;
  ErrorBanner?: string | JSX.Element;
  hasError?: boolean;
  leftTopContent: JSX.Element;
  mobileFloatingDescription?: JSX.Element;
  hideHotelLoyaltySection?: boolean;
  chooseSavingsProperties?: ChooseSavingsProperties;
  headerElement?: JSX.Element;
  travelerFormClassName?: string;
  productType?: ProductType;
  ctaEnabledByDefault?: boolean;
  nationalityRequired?: boolean;
}

export const PassengerInformation = ({
  isLoading,
  key,
  className,
  loadingMessage,
  loadingComponent,
  userPassengers,
  userInfo,
  disabled,
  isMobile,
  travelerWorkflowTitles,
  selectedPassengerIds,
  currentPassenger,
  openPassengerFormModal,
  openPassengerPicker,
  handleCloseForm,
  handleContinue,
  handleDeletePassenger,
  handleEditClick,
  handleGoBack,
  handleSelectPassenger,
  handleUpdatePassenger,
  selectionScreenHeaderElement,
  singleTravelerWorkflow,
  handleClickAddTraveler,
  handleOpenPassengerPicker,
  currentInfantId,
  handleInfantPickerModalContinue,
  handleInfantPickerModalClose,
  tripPricing,
  isInfantModalLoading,
  clientAssets,
  desktopRightContent,
  desktopTopContent,
  isJetBlueTenant,
  headerTitle,
  ErrorBanner,
  hasError,
  leftTopContent,
  mobileFloatingDescription,
  hideHotelLoyaltySection,
  chooseSavingsProperties,
  headerElement,
  travelerFormClassName,
  productType,
  showPassport,
  hideAdditionalInfoSection,
  hideFrequentFlyerSection,
  ctaEnabledByDefault = false,
  nationalityRequired = false,
}: PassengerInformationProps) => {
  return (
    <div key={key} className={className}>
      {isLoading ? (
        loadingComponent ? (
          loadingComponent
        ) : (
          <LoadingIndicator
            className={"flight-book-passenger-selection-loading-indicator"}
            indicatorSize={"small"}
            indicator={B2BSpinner}
            message={loadingMessage}
          />
        )
      ) : (
        <TravelerSelectWorkflow
          buttonClassName="b2b"
          className="b2b"
          currentPassenger={currentPassenger}
          disabled={disabled}
          fullScreenWithBanner={isMobile}
          handleClickAddTraveler={handleClickAddTraveler}
          handleCloseForm={handleCloseForm}
          handleContinue={handleContinue}
          handleDeletePassenger={handleDeletePassenger}
          handleEditClick={handleEditClick}
          handleGoBack={handleGoBack}
          handleOpenPassengerPicker={handleOpenPassengerPicker}
          handleSelectPassenger={handleSelectPassenger}
          handleUpdatePassenger={handleUpdatePassenger}
          isMobile={isMobile}
          openPassengerFormModal={openPassengerFormModal}
          openPassengerPicker={openPassengerPicker}
          selectedPassengerIds={selectedPassengerIds}
          selectionScreenHeaderElement={selectionScreenHeaderElement}
          singleTravelerWorkflow={singleTravelerWorkflow}
          titles={travelerWorkflowTitles}
          userInfo={userInfo}
          userPassengers={userPassengers}
          wrapButton={isMobile}
          clientAssets={clientAssets}
          desktopRightContent={desktopRightContent}
          desktopTopContent={desktopTopContent}
          isJetBlueTenant={Boolean(isJetBlueTenant)}
          buttonMessage={travelerWorkflowTitles.buttonMessage}
          headerTitle={headerTitle}
          headerElement={headerElement}
          ErrorBanner={ErrorBanner}
          hasError={hasError}
          leftTopContent={leftTopContent}
          mobileFloatingDescription={mobileFloatingDescription}
          hideHotelLoyaltySection={hideHotelLoyaltySection}
          showContinue={true}
          chooseSavingsProperties={chooseSavingsProperties}
          travelerFormClassName={travelerFormClassName}
          productType={productType}
          ctaEnabledByDefault={ctaEnabledByDefault}
          nationalityRequired={nationalityRequired}
          showPassport={showPassport}
          hideAdditionalInfoSection={hideAdditionalInfoSection}
          hideFrequentFlyerSection={hideFrequentFlyerSection}
        />
      )}
      <InfantSeatPickerModal
        currentInfantToSelectSeat={currentInfantId}
        isLoading={isInfantModalLoading}
        onClose={handleInfantPickerModalClose}
        onContinue={handleInfantPickerModalContinue}
        tripPricing={tripPricing}
      />
    </div>
  );
};
