import React from "react";
import {
  Uuid,
  CreditCard,
  CardTypeConstants,
  CardType,
} from "@hopper-b2b/types";
import CardAmexIcon from "../../assets/icons/payments/card_amex.svg";
import CardVisaIcon from "../../assets/icons/payments/card_visa.svg";
import CardDinersIcon from "../../assets/icons/payments/card_diners.svg";
import CardDiscoverIcon from "../../assets/icons/payments/card_discover.svg";
import CardMastercardIcon from "../../assets/icons/payments/card_mastercard.svg";
import CardUnknownIcon from "../../assets/icons/payments/card_unknown.svg";
import CardInactiveIcon from "../../assets/icons/payments/card_inactive.svg";
import clsx from "clsx";

export const getPaymentMethod = (payment: CreditCard): Uuid => {
  return payment.id;
};

// CardType-to-image mapping is consistent with Cupertino
// https://github.svc.internal.zone/Hopper/Cupertino/blob/master/Flights/PaymentKit+Hopper/PTKCardType.swift#L51-L66
export const getCardIcon = (cardType: CardType, className?: string) => {
  switch (cardType) {
    case CardTypeConstants.AmericanExpress:
      return (
        <img
          src={CardAmexIcon}
          className={clsx(className)}
          alt="America Express Card"
        />
      );
    case CardTypeConstants.DinersClub:
      return (
        <img
          alt="Diners Club Card Icon"
          src={CardDinersIcon}
          className={clsx(className)}
        />
      );
    case CardTypeConstants.Discover:
      return (
        <img
          alt="Discover Card Icon"
          src={CardDiscoverIcon}
          className={clsx(className)}
        />
      );
    case CardTypeConstants.Visa:
      return (
        <img
          alt="Visa Card Icon"
          src={CardVisaIcon}
          className={clsx(className)}
        />
      );
    case CardTypeConstants.Master:
      return (
        <img
          alt="Master Card Icon"
          src={CardMastercardIcon}
          className={clsx(className)}
        />
      );
    // When the cardType value is not set, show the grey card icon
    case "":
      return (
        <img
          alt="Inactive Card Icon"
          src={CardInactiveIcon}
          className={clsx(className)}
        />
      );
    // JCB, dankort and other unknown cards
    default:
      return (
        <img
          alt="Unknown Card Icon"
          src={CardUnknownIcon}
          className={clsx(className)}
        />
      );
  }
};

// TODO: revisit RewardsAccount data to card icon mapping logic once backend / cap1 is ready
export const getCapOneCardIcon = (
  cardArtUrl: string,
  className?: string
): React.ReactElement => {
  return (
    <img
      src={cardArtUrl}
      className={clsx("capone-card-icon", className)}
      alt=""
    />
  );
};

export const TEST_CARD_LAST_FOURS = ["1111", "1881"];
