import { useNavigate } from "react-router-dom-v5-compat";
import clsx from "clsx";

import {
  ActionLink,
  ButtonWrap,
  Divider,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { FeatureFlagsContextProps } from "@hopper-b2b/utilities";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { trackEvent } from "@hopper-b2b/api";
import { AIR_ENTRY, ClientName } from "@hopper-b2b/types";
import { createGeneralSupportConversation } from "@hopper-b2b/self-serve";

import ArrowBack from "../../assets/client/arrow-back.svg";
import ArrowBackWhite from "../../assets/client/darkMode/arrow-back-white.svg";
import HelpIcon from "../../assets/client/help-icon.svg";
import HelpIconWhite from "../../assets/client/darkMode/help-icon-white.svg";
import Chevron from "../../assets/client/chevron.svg";
import ChevronWhite from "../../assets/client/darkMode/chevron-white.svg";
import AirplaneIconHome from "../../assets/client/airplane-icon-home.svg";
import TripBlackIcon from "../../assets/client/trip-icon.svg";
import TripWhiteIcon from "../../assets/client/darkMode/trip-icon-white.svg";
import HotelIconHome from "../../assets/client/hotel-icon-home.svg";
import CalendarIcon from "../../assets/client/uv/uv-calendar.svg";
import BestPriceGuaranteeIcon from "../../assets/client/uv/uv-dollar.svg";
import WalletIcon from "../../assets/client/uv/uv-wallet.svg";
import { ReactComponent as NubankUltravioletaDarkLogo } from "../../assets/client/uv/ultravioleta-logo.svg";
import { ReactComponent as NubankUltravioletaLightLogo } from "../../assets/client/uv/ultravioleta-logo-white.svg";
import { ReactComponent as Headset } from "../../assets/client/headset.svg";
import {
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_TRIPS,
} from "../../utils/urlPaths";
import { closeNubankWebview, openNubankHelp } from "../../utils/nubankHandoff";
import {
  isUserTypeCookieUltravioleta,
  useDarkModePreferred,
} from "../../utils/colors";
import { ContactSupportModalContent } from "../Slots";
import { TermsAndConditionsContext } from "../TermsAndConditionsWrapper/component";
import style from "./HomeRouteComponent.module.scss";

interface INubankRoute {
  featureFlags: FeatureFlagsContextProps;
}

const HomeRouteComponent = ({ featureFlags }: INubankRoute) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const { setOpenModal } = useContext(TermsAndConditionsContext);
  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);

  const isUltravioleta = isUserTypeCookieUltravioleta();

  const darkModeEnabled = useDarkModePreferred();

  const TripIcon = darkModeEnabled ? TripWhiteIcon : TripBlackIcon;
  const NubankUltravioletaLogo = darkModeEnabled
    ? NubankUltravioletaLightLogo
    : NubankUltravioletaDarkLogo;

  const goBack = useCallback(() => {
    closeNubankWebview();
  }, []);

  const goToFaqs = useCallback(() => {
    openNubankHelp();
  }, []);

  const navigateToLodging = useCallback(() => {
    navigate(PATH_HOTELS_SEARCH);
  }, [navigate]);

  const navigateToFlights = useCallback(() => {
    trackEvent({
      eventName: AIR_ENTRY,
      properties: {},
    });
    navigate(PATH_FLIGHTS_SEARCH);
  }, [navigate]);

  const navigateToTrips = useCallback(() => {
    navigate(PATH_TRIPS);
  }, [navigate]);

  const dealsToShow = useMemo(
    () =>
      [
        {
          title: t("homeBestPriceGuaranteeTitle"),
          subtitle: t("homeBestPriceGuaranteeSubtitle"),
          icon: BestPriceGuaranteeIcon,
        },
        featureFlags.enablePriceDropProtection &&
        featureFlags.enablePricePrediction
          ? {
              title: t("homePriceDropProtectionTitle"),
              subtitle: t("homePriceDropProtectionSubtitle"),
              icon: CalendarIcon,
            }
          : null,
        {
          title: t("homePaymentTitle"),
          subtitle: t("homePaymentSubtitle"),
          icon: WalletIcon,
        },
      ].filter((deal) => !!deal),
    [
      featureFlags.enablePriceDropProtection,
      featureFlags.enablePricePrediction,
      t,
    ]
  );

  const handleOpenContactSupportModal = () => setOpenContactSupportModal(true);

  const handleCloseContactSupportModal = () =>
    setOpenContactSupportModal(false);

  const handleOpenChat = () => {
    handleCloseContactSupportModal();
    createGeneralSupportConversation(ClientName.NUBANK);
  };

  return (
    <div className={style.nubankHome}>
      <div className="home-top">
        <div className="home-nav">
          <ButtonWrap onClick={goBack}>
            <img src={darkModeEnabled ? ArrowBackWhite : ArrowBack} alt="" />
          </ButtonWrap>
          <ButtonWrap onClick={goToFaqs}>
            <img src={darkModeEnabled ? HelpIconWhite : HelpIcon} alt="" />
          </ButtonWrap>
        </div>
        <div className="home-header">
          {isUltravioleta ? (
            <NubankUltravioletaLogo className="uv-logo" />
          ) : null}

          <h2 className="branding">{t("nuTravel")}</h2>
          <h1 className="headline">{t("homeScreenTitle")}</h1>
        </div>
        <div className="home-actions">
          {featureFlags.enableAirShopV4 ? (
            <ButtonWrap className="action-item" onClick={navigateToFlights}>
              <img src={AirplaneIconHome} alt="" />
              <p>{t("flightSearchPrompt")}</p>
            </ButtonWrap>
          ) : null}
          {featureFlags.enableLodging ? (
            <ButtonWrap className="action-item" onClick={navigateToLodging}>
              <img src={HotelIconHome} alt="" />
              <p>{t("searchHotels")}</p>
            </ButtonWrap>
          ) : null}
        </div>
      </div>
      <div className="home-bottom">
        <ButtonWrap onClick={navigateToTrips}>
          <div className="my-trips-container">
            <div className="my-trips-left">
              <img src={TripIcon} alt="" />
              <div className="my-trips-content">
                <h3>{t("myTrips")}</h3>
                <p>{t("homeScreenMyTripsSubtitle")}</p>
              </div>
            </div>
            <img src={darkModeEnabled ? ChevronWhite : Chevron} alt="" />
          </div>
        </ButtonWrap>
        {isUltravioleta ? (
          <>
            <h2 className="find-best-deals">{t("findBestDeals")}</h2>
            {dealsToShow.map((deal, i) => (
              <Fragment key={`deal-to-show-${i}`}>
                <div className="deal-row">
                  <img src={deal?.icon} alt="" className="value-props-icon" />
                  <div>
                    <p className="title">{deal?.title}</p>
                    <p className="subtitle">{deal?.subtitle}</p>
                  </div>
                </div>
                {i < dealsToShow.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </>
        ) : null}
        <Divider />
        <div className="home-contact-support-container">
          <div className="home-tc-link">
            <Trans
              i18nKey={"homeTermsAndConditionsEntry"}
              components={[
                <ButtonWrap
                  className="terms-link"
                  onClick={() => setOpenModal(true)}
                />,
              ]}
            />
          </div>
          <button
            className="home-contact-support"
            onClick={handleOpenContactSupportModal}
          >
            <Headset className="mobile-action-link-icon help" />
            {t("contactSupport")}
          </button>
        </div>
      </div>
      <MobilePopoverCard
        open={openContactSupportModal}
        className="contact-support-modal"
        maxWidth={"sm"}
        scroll="paper"
        fullWidth
        fullScreen
        topRightButton={
          <ActionLink
            className="introduction-modal-close-button"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={handleCloseContactSupportModal}
          />
        }
      >
        <ContactSupportModalContent
          handleCloseContactSupportModal={handleCloseContactSupportModal}
          handleOpenChat={handleOpenChat}
        />
      </MobilePopoverCard>
    </div>
  );
};

export default HomeRouteComponent;
