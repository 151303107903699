import { useSelector } from "react-redux";
import { IStoreState } from "../../../reducers/types";
import { selectedTripSelector, tripDetailsSelector } from "../reducer";
import { getFtcType } from "../utils/getFtcType";

export const useFtcType = () => {
  const selectedTrip = useSelector(selectedTripSelector);
  const fareId: string | null =
    (selectedTrip.returnFareId || selectedTrip.outgoingFareId) ?? null;
  const tripId = selectedTrip.tripId ?? null;
  const tripDetails = useSelector((state: IStoreState) =>
    tripId ? tripDetailsSelector(state, tripId) : null
  );

  const fareDetails = tripDetails?.fareDetails.find((f) => f.id === fareId);
  const outgoingSlice = fareDetails?.slices.find(
    (slice) => slice.outgoing === true
  );

  const outgoingUtaPolicies = outgoingSlice?.amenitiesUtas?.utas;

  const changePolicy =
    outgoingUtaPolicies?.changePolicy?.beforeDeparture ??
    outgoingUtaPolicies?.changePolicy?.anytime;

  return getFtcType(changePolicy);
};
