import { default as dayjs, Dayjs } from "dayjs";

export const URL_DATE_FORMAT = "YYYY-MM-DD";

export const toDate = (dateString?: string | Dayjs | Date): Dayjs =>
  dayjs(dateString);

export function formatDateForUrl(date: ""): undefined;
export function formatDateForUrl(date: string | Dayjs | Date): string;
export function formatDateForUrl(
  date?: string | Dayjs | Date
): string | undefined;
export function formatDateForUrl(
  date?: string | Dayjs | Date
): string | undefined {
  return date != null && date !== ""
    ? toDate(date).format(URL_DATE_FORMAT)
    : undefined;
}
