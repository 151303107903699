import { notifySuccessfulAuth } from "../api/notifySuccessfulAuth";

export const notifySuccessfulEnrollment = (_context, _event) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      await notifySuccessfulAuth();
      resolve(null);
    } catch (error) {
      reject({
        error,
      });
    }
  });
