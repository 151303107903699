import { Person } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ChooseSavingsProperties,
  PersonId,
  ProductType,
  UserInfo,
  VIEW_TRIP_TRAVELERS,
} from "@hopper-b2b/types";
import { BackButton, Slot } from "@hopper-b2b/ui";
import { getDateTimeWithFormat, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Button, Divider } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect } from "react";
import { DesktopLayout } from "../../../common/DesktopLayout/component";
import { MobileLayout } from "../../../common/MobileLayout";
import { TravelerSelectWorkflowContent } from "../TravelerSelectWorkflowContent";
import "./styles.scss";

export enum TravelerSelectStep {
  Main,
  TravelerInfoForm,
  TravelerSelect,
}

export type TravelerTypeTitles = {
  travelerInfoTitle: string;
  travelerInfoSubtitle: string;
  frequentFlyerTitle: string;
  additionalInfoTitle: string;
  addTravelers?: string;
  adultTitle: string;
  childTitle: string;
  infantSeatTitle: string;
  infantLapTitle: string;
  travelerInfoFormSubtitle?: string;
  buttonMessage?: string;
  travelerSelectSubtitle?: string;
  noTravelersSelectSubtitle?: string;
  travelerInfoHeader: string;
  notification?: string;
  passportSubtitle?: string;
};

export interface ITravelerSelectWorkflowProps {
  className?: string;
  userPassengers: Person[];
  titles: TravelerTypeTitles;
  // TODO: figure out why selectedPassengerIds: Set<PersonId> gets converted to be an Object
  selectedPassengerIds: PersonId[];
  handleUpdatePassenger: (passenger: Person) => void;
  handleDeletePassenger: (travelerId: PersonId) => void;
  isMobile?: boolean;
  onProgressChange?: (step: TravelerSelectStep) => void;
  handleContinue?: () => void;
  handleGoBack?: () => void;
  selectionScreenHeaderElement?: JSX.Element;
  singleTravelerWorkflow?: boolean;
  userInfo?: UserInfo;
  editPassenger?: Person;
  hideFrequentFlyerSection?: boolean;
  hideHotelLoyaltySection?: boolean;
  hideNationalityField?: boolean;
  hideAdditionalInfoSection?: boolean;
  hideGenderField?: boolean;
  showDriverLicenseSection?: boolean;
  showPassport?: boolean;
  buttonMessage?: string;
  buttonClassName?: string;
  fullScreenWithBanner?: boolean;
  wrapButton?: boolean;
  disabled?: boolean;
  displayModalSubtitle?: boolean;

  // Newly added
  currentPassenger?: Person;
  handleEditClick: (passenger: Person) => void;
  openPassengerFormModal: boolean;
  openPassengerPicker: boolean;
  handleSelectPassenger?: (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => void;
  handleCloseForm: () => void;
  handleClickAddTraveler: () => void;
  handleOpenPassengerPicker: () => void;
  clientAssets?: { logo: string };
  desktopRightContent?: JSX.Element;
  desktopTopContent?: JSX.Element;
  isJetBlueTenant?: boolean;
  headerTitle?: string;
  ErrorBanner?: string | JSX.Element;
  hasError?: boolean;
  leftTopContent?: JSX.Element;
  mobileFloatingDescription?: JSX.Element;
  showContinue?: boolean;
  chooseSavingsProperties?: ChooseSavingsProperties;
  headerElement?: JSX.Element;
  travelerFormClassName?: string;
  productType?: ProductType;
  ctaEnabledByDefault?: boolean;
  nationalityRequired?: boolean;
}

export const TravelerSelectWorkflow = ({
  className,
  selectionScreenHeaderElement,
  handleGoBack,
  selectedPassengerIds = [],
  clientAssets,
  desktopRightContent,
  desktopTopContent,
  headerTitle,
  leftTopContent,
  hasError,
  handleContinue,
  showContinue,
  chooseSavingsProperties = {} as ChooseSavingsProperties,
  headerElement,
  productType,
  openPassengerFormModal,
  ctaEnabledByDefault,
  nationalityRequired,
  ...rest
}: ITravelerSelectWorkflowProps): JSX.Element => {
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();

  const isContinueDisabled =
    hasError || selectedPassengerIds.length === 0 || openPassengerFormModal;

  useEffect(() => {
    trackEvent({
      eventName: VIEW_TRIP_TRAVELERS,
      properties: {
        ...chooseSavingsProperties,
        num_travelers: selectedPassengerIds?.length || 0,
        product_type: productType,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = () => {
    handleGoBack && handleGoBack();
  };

  return matchesMobile ? (
    <MobileLayout
      className={clsx("traveler-entries-select", "mobile", className)}
      topLeftButton={
        <BackButton
          className="traveler-entries-select-popup-back-button"
          onClick={handleOnClose}
        />
      }
      headerElement={selectionScreenHeaderElement}
      title={headerTitle}
    >
      {leftTopContent}
      <div className="step-content">
        <TravelerSelectWorkflowContent
          isContinueDisabled={isContinueDisabled}
          selectedPassengerIds={selectedPassengerIds}
          handleContinue={handleContinue}
          openPassengerFormModal={openPassengerFormModal}
          ctaEnabledByDefault={ctaEnabledByDefault}
          nationalityRequired={nationalityRequired}
          {...rest}
        />
      </div>
    </MobileLayout>
  ) : (
    <DesktopLayout
      className={clsx("traveler-entries-select", className)}
      clientLogo={clientAssets?.logo}
      headerElement={headerElement}
      rightContent={
        showContinue ? (
          <>
            {desktopRightContent}
            <Divider />
            <Box m={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disableElevation
                disabled={isContinueDisabled}
                onClick={handleContinue}
              >
                {t?.("continue")}
              </Button>
            </Box>
            <Slot id="non-refundable-banner" applyPositionAbsolute />
          </>
        ) : (
          desktopRightContent
        )
      }
      topContent={desktopTopContent}
    >
      {leftTopContent}
      <div className="step-content">
        <TravelerSelectWorkflowContent
          isContinueDisabled={isContinueDisabled}
          selectedPassengerIds={selectedPassengerIds}
          handleContinue={handleContinue}
          openPassengerFormModal={openPassengerFormModal}
          {...rest}
        />
      </div>
    </DesktopLayout>
  );
};

export const getTravelerType = (
  passenger: Person,
  titles: {
    adultTitle: string;
    childTitle: string;
    infantSeatTitle: string;
    infantLapTitle: string;
  }
) => {
  const age = dayjs().diff(
    getDateTimeWithFormat(passenger.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  if (age > 11) {
    return titles.adultTitle;
  } else if (age >= 2) {
    return titles.childTitle;
  } else if (age > 1) {
    return titles.infantSeatTitle;
  } else {
    return titles.infantLapTitle;
  }
};
