import { Box } from "@material-ui/core";
import { Component, ReactNode } from "react";

import { trackEvent } from "@hopper-b2b/api";
import { ActionButton, NoResults } from "@hopper-b2b/ui";
import { ClientName } from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";

import Logger from "../../../../helpers/Logger";

import "./styles.scss";

const EVENT_NAME = "triggered_error_boundary";

export interface IErrorBoundaryProps {
  backToTrips: () => void;
  children: ReactNode;
  errorIcon?: string;
  sendUberEvent: (name: string, props: Record<string, unknown>) => void;
  t: (copyKey: string) => string;
}

export interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  isUber: boolean;

  constructor(props) {
    super(props);

    this.state = { hasError: false };
    this.isUber = getEnvVariables("clientName") === ClientName.UBER;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { sendUberEvent } = this.props;
    const { message, name, stack } = error;
    const { componentStack } = info;
    const { pathname, search } = window.location;
    const eventProps = {
      component_stack: componentStack,
      error_msg: message,
      error_name: name,
      error_stack: stack,
      location: pathname,
      location_search: search,
    };

    if (process.env.NODE_ENV !== "production") {
      Logger.debug(error);
      console.trace(componentStack);
    }

    if (this.isUber) {
      sendUberEvent(EVENT_NAME, eventProps);
    }

    trackEvent({ eventName: EVENT_NAME, properties: eventProps });
  }

  render() {
    const { backToTrips, children, errorIcon, t } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Box className="exchange-error-boundary-fallback">
          <NoResults
            className="fallback-no-results"
            iconSrc={errorIcon ?? null}
            subtitle={t("exchangeable.error.subtitle")}
            title={t("exchangeable.error.title")}
          />
          <ActionButton
            className="fallback-action b2b"
            message={t("exchangeable.backToTrips")}
            onClick={backToTrips}
          />
        </Box>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
