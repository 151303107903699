import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { Prediction } from "@b2bportal/price-freeze-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { PricePredictionCard } from "@hopper-b2b/ui";
import {
  useEnablePriceFreeze,
  useEnablePricePrediction,
  useEnablePriceWatch,
} from "@hopper-b2b/utilities";

import { isPriceFreezeOfferIncludedInShopSummarySelector } from "../../../../reducer";
import { PriceFreezeEntryCard } from "../../../PriceFreezeEntryCard";
import { FlightPriceWatchEntryCard } from "../../../FlightPriceWatchEntryCard";
import { PricePredictionBar } from "../PricePredictionBar";

interface MobilePricePredictionShopCardProps {
  prediction: Prediction | null;
  tripCategory: TripCategory;
  openPredictionModal: () => void;
}

export const MobilePricePredictionShopCard = ({
  prediction,
  tripCategory,
  openPredictionModal,
}: MobilePricePredictionShopCardProps) => {
  const showFlightPriceWatch = useEnablePriceWatch();
  const showFlightPriceFreeze = useEnablePriceFreeze();
  const showPricePrediction = useEnablePricePrediction();
  const hasPriceFreezeOffer = useSelector(
    isPriceFreezeOfferIncludedInShopSummarySelector
  );

  const { t, formatFiatCurrency } = useI18nContext();

  return showPricePrediction && prediction && prediction.predictionCopy ? (
    <Box className="mobile-price-prediction-container">
      <PricePredictionBar
        prediction={prediction}
        onOpenPrediction={openPredictionModal}
      />
      <div className="mobile-price-prediction-items">
        {showFlightPriceFreeze && hasPriceFreezeOffer ? (
          <PriceFreezeEntryCard />
        ) : null}
        {showFlightPriceWatch ? <FlightPriceWatchEntryCard /> : null}
      </div>
      <PricePredictionCard
        className="current-price"
        title=""
        subtitle={
          tripCategory === TripCategory.ONE_WAY
            ? t("pricePrediction.oneWayCurrentPrice")
            : t("pricePrediction.roundTripCurrentPrice")
        }
        content={
          <Typography variant="h5">
            <span className="current-price-lowestprice">
              <b>
                {formatFiatCurrency(prediction?.lowestPrice.fiat, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </b>
            </span>
          </Typography>
        }
      />
    </Box>
  ) : null;
};
