import clsx from "clsx";
import { useTenantIcons } from "@hopper-b2b/utilities";
import {
  LodgingSelectionEnum,
  Lodgings,
  Suggestion,
} from "@b2bportal/lodging-api";
import { Autocomplete, AutocompleteProps } from "../../components/Autocomplete";
import { fetchLocations } from "./fetchLocations";
import { Slot } from "@hopper-b2b/ui";

export interface HotelLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
  > {
  defaultValue?: Suggestion;
  keepLocationLabel?: true;
  animated?: true;
}

export const HotelLocationAutoComplete = ({
  id,
  defaultValue,
  label,
  onChange,
  onOpen,
  className,
  shrinkLabel,
  keepLocationLabel,
  animated,
  renderOption,
}: HotelLocationAutoCompleteProps) => {
  const { locationMarker } = useTenantIcons();

  return (
    <Slot
      id={"hotel-search-autocomplete"}
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
      fetch={fetchLocations}
      groupBy={groupLocations}
      getOptionLabel={getOptionLabel}
      icon={locationMarker}
      onOpen={onOpen}
      className={className}
      shrinkLabel={shrinkLabel}
      paperClassName={clsx("lodging-autocomplete-paper", className)}
      keepLabel={keepLocationLabel}
      animated={animated}
      renderOption={renderOption}
      component={
        <Autocomplete
          id={id}
          defaultValue={defaultValue}
          label={label}
          onChange={onChange}
          fetch={fetchLocations}
          groupBy={groupLocations}
          getOptionLabel={getOptionLabel}
          icon={locationMarker}
          onOpen={onOpen}
          className={className}
          shrinkLabel={shrinkLabel}
          paperClassName={clsx("lodging-autocomplete-paper", className)}
          keepLabel={keepLocationLabel}
          animated={animated}
          renderOption={renderOption}
        />
      }
    />
  );
};

const groupLocations = (value?: Suggestion): LodgingSelectionEnum =>
  (value?.id as Lodgings)?.lodgingSelection?.LodgingSelection;

const getOptionLabel = (value?: Suggestion) => {
  if (
    value?.id.Id === "Lodgings" &&
    value.id.lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
  ) {
    return value.id.lodgingSelection.searchTerm;
  }
  return value?.label;
};
