import { useCallback, useState } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GuestsSelection, LodgingShopTrackingEvents } from "@hopper-b2b/types";
import {
  B2BButton,
  Icon,
  IconName,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import { useTenantIcons } from "@hopper-b2b/utilities";
import styles from "./GuestPicker.module.scss";

export type GuestPickerProps = {
  enableRooms?: boolean;
  initialGuestCount: GuestsSelection;
  updateGuestCount: (count: GuestsSelection) => void;
};

export const GuestPicker = ({
  enableRooms: enableRoomsProps,
  initialGuestCount,
  updateGuestCount,
}: GuestPickerProps) => {
  const { t } = useI18nContext();

  // TODO: figure out if this flag is needed
  // const enableRoomsFlag = useIsRoomsEnabled();
  const enableRooms = enableRoomsProps; /* ?? enableRoomsFlag */

  const [showGuestCountPicker, setShowGuestCountPicker] =
    useState<boolean>(false);
  const [guestCount, setGuestCount] =
    useState<GuestsSelection>(initialGuestCount);

  const handleClosePassengerPicker = useCallback(() => {
    setShowGuestCountPicker(false);
  }, []);

  const handleGuestCountsChange = useCallback(
    (counts: GuestsSelection) => {
      const nextGuestCount = {
        adults: counts.adults,
        children: counts.children,
        rooms: counts.rooms,
      };
      setGuestCount(nextGuestCount);
      updateGuestCount(nextGuestCount);
      handleClosePassengerPicker();
    },
    [handleClosePassengerPicker, updateGuestCount]
  );

  const onClick = useCallback(() => {
    setShowGuestCountPicker(true);
    trackEvent({
      eventName: LodgingShopTrackingEvents.hotel_tapped_guests,
      properties: {
        adults_count: guestCount.adults,
        children_count: guestCount.children.length,
      },
    });
  }, [guestCount]);

  const setPassengerCounts = useCallback((counts: GuestsSelection) => {
    setGuestCount(counts);
    trackEvent({
      eventName: LodgingShopTrackingEvents.hotel_tapped_apply_guests,
      properties: {
        adults_count: counts.adults,
        children_count: counts.children.length,
      },
    });
  }, []);

  const { guest, multipleGuests } = useTenantIcons();
  const multipleGuestsSelected =
    guestCount.adults + (guestCount.children?.length || 0) > 1;
  const guestIcon =
    multipleGuestsSelected && multipleGuests ? multipleGuests : guest || null;

  const guestCountText = `${t("guestCountLabel.adultCount", {
    count: guestCount.adults,
  })}${
    guestCount.children.length > 0
      ? `, ${t("guestCountLabel.childCount", {
          count: guestCount.children.length,
        })}`
      : ""
  }`;
  return (
    <div className={clsx(styles.GuestPicker, "GuestPicker")}>
      <B2BButton variant="traveler-selector" onClick={onClick}>
        <Grid container alignItems="center" spacing={3} wrap="nowrap">
          <Grid item>
            {guestIcon ? (
              <img className={styles.tenantIcon} src={guestIcon} alt="" />
            ) : (
              <Icon aria-hidden={true} name={IconName.B2BUser} ariaLabel="" />
            )}
          </Grid>
          <Grid item>
            <Typography noWrap variant="body2">
              {guestCountText}
            </Typography>
          </Grid>
        </Grid>
      </B2BButton>
      <MobilePopoverCard
        open={showGuestCountPicker}
        onClose={handleClosePassengerPicker}
        invisibleBackdrop={false}
        contentClassName={"lodging-guest-count-modal-content"}
        className={"lodging-guest-count-modal"}
        fullScreen
      >
        <Slot
          id="lodging-search-mobile-guest-modal"
          showRooms={enableRooms}
          minimumCount={1}
          onClickApplyGuest={handleGuestCountsChange}
          setPassengerCounts={setPassengerCounts}
          counts={guestCount}
          onClose={handleClosePassengerPicker}
          titles={{
            modalTitle: t("passengerCount.modalTitle"),
            adultTitle: t("passengerCount.adultTitle"),
            adultSubtitle: t("guestCount.adultSubtitle"),
            childrenTitle: t("passengerCount.childrenTitle"),
            childrenSubtitle: t("guestCount.childrenSubtitle"),
            childrenAgeMessage: t("guestCount.childrenAgeMessage"),
            childrenAgePlaceholderKey: "guestCount.childrenAgePlaceholder",
            roomsTitle: enableRooms
              ? t("passengerCount.roomsTitle") ?? undefined
              : "",
          }}
          captureChildrenAges
        />
      </MobilePopoverCard>
    </div>
  );
};
