import { ParentState, setContextWithKey } from "@hopper-b2b/checkout";
import { assign } from "xstate";

import { FlightMachineContext } from "../types";
import { getSessionInfo } from "../selectors";
import { parsePhoneNumber } from "@hopper-b2b/utilities";

const DEFAULT_COUNTRY_CODE = "55";

export const commonActions = {
  setInitialContactInformation: assign(
    (context: FlightMachineContext, _event) => {
      const sessionUserInfo = getSessionInfo({ context })?.userInfoResponse;
      const phoneNumber = sessionUserInfo?.phoneNumber;

      const parsedPhone = parsePhoneNumber(phoneNumber);
      const initialContactInfo = {
        countryCode: `+${parsedPhone.countryCode || DEFAULT_COUNTRY_CODE}`,
        phoneNumber: parsedPhone.nationalNumber,
        email: sessionUserInfo?.email || "",
      };

      return setContextWithKey(
        context,
        `${ParentState.contactInformation}.contactInfo`,
        initialContactInfo
      );
    }
  ),
};
