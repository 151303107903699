import { getLang } from "./getLang";

export const getDateFormat = (lang?: string, useNavigator = true): string => {
  return getLang(lang, useNavigator).includes("US")
    ? "MM/DD/YYYY"
    : "DD/MM/YYYY";
};

export const getPreferMonthDayYearFormat = (
  lang?: string,
  useNavigator = true
): boolean => getLang(lang, useNavigator).includes("US");
