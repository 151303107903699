import { useHistory } from "react-router";
import { useMemo } from "react";

import {
  getChildState,
  useCheckoutSend,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";

import { LoadingScreen } from "../LoadingScreen";
import { AuthConsentScreen } from "./components/AuthConsent";
import { PaymentMethodSelect } from "./components/PaymentMethodSelect";
import { CreditInstallmentSelect } from "./components/CreditInstallmentSelect";
import { CreditReviewScreen } from "./components/CreditReviewScreen";
import { InvalidPaymentScreen } from "./components/InvalidPaymentScreen";
import { getIsLoading, getPaymentError } from "../../states/payments/selectors";
import { ErrorScreen } from "../../../components/ErrorScreen";
import { PaymentEvent } from "../../states";
import { closeNubankWebview } from "../../../utils/nubankHandoff";

export const NubankPaymentComponent = () => {
  const [state] = useCheckoutState();
  const isLoading = getIsLoading(state.value);
  return isLoading ? <LoadingScreen /> : <MappedComponents />;
};

const MappedComponents = () => {
  const [state] = useCheckoutState();
  const childState = getChildState(state.value);

  switch (childState) {
    case "requestAuth":
      return <AuthConsentScreen />;
    case "selectCreditOrDebit":
      return <PaymentMethodSelect />;
    case "credit":
      return <CreditInstallmentSelect />;
    case "review":
      return <CreditReviewScreen />;
    case "invalidFunds":
      return <InvalidPaymentScreen />;
    case "error":
      return <GenericErrorScreen />;
    default:
      return null;
  }
};

enum GetPaymentMethodErrors {
  NubankGetPaymentMethodsError = "NubankGetPaymentMethodsError",
  NubankNotEnoughBalance = "NubankNotEnoughBalance",
}

const GenericErrorScreen = () => {
  const { t } = useI18nContext();
  const send = useCheckoutSend();
  const history = useHistory();
  const error = useSelector(getPaymentError);

  const errorCode = error?.details?.[0]?.code as GetPaymentMethodErrors;

  const mapErrorCodeToErrorProps = useMemo(() => {
    if (errorCode === GetPaymentMethodErrors.NubankGetPaymentMethodsError) {
      return {
        title: t("nupay.genericErrorSubheader"),
        subtitle: t("nupay.genericErrorSubheader"),
        primaryButtonText: t("tryAgain"),
        primaryOnClick: () => send(PaymentEvent.TRY_AGAIN),
        onBack: () => history.goBack(),
      };
    } else if (errorCode === GetPaymentMethodErrors.NubankNotEnoughBalance) {
      return {
        title: t("nupay.noLimitErrorHeader"),
        subtitle: t("nupay.noLimitErrorSubheader"),
        primaryButtonText: t("nupay.invalidPaymentButton"),
        primaryOnClick: () => closeNubankWebview(),
        onBack: () => closeNubankWebview(),
      };
    } else {
      // Default error copy
      return {
        title: t("nupay.genericErrorSubheader"),
        subtitle: t("nupay.genericErrorSubheader"),
        primaryButtonText: t("tryAgain"),
        primaryOnClick: () => send(PaymentEvent.TRY_AGAIN),
        onBack: () => history.goBack(),
      };
    }
  }, [errorCode, history, send, t]);

  return <ErrorScreen className="warn" {...mapErrorCodeToErrorProps} />;
};
