import { notifyFailedAuth } from "../api/notifyFailedAuth";

export const notifyFailedEnrollment = (_context, _event) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      await notifyFailedAuth();
      resolve(null);
    } catch (error) {
      reject({
        error,
      });
    }
  });
