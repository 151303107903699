import { Flights } from "@b2bportal/air-shopping-api";
import { FareDetails, FilteredFare, TripDetails } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { FC } from "react";

import { FareclassOptionFilter } from "../../../../../../search/reducer";
import { IFlightListData } from "../../component";
import { DesktopInfiniteFlights } from "./components/DesktopInfiniteFlights";
import { MobileInfiniteFlights } from "./components/MobileInfiniteFlights";

export type IInfiniteFlightsProps<P = unknown> = P & {
  className?: string;
  departureDate: Date | null;
  disablePriceFreeze?: boolean;
  expandedFareDetails: TripDetails | null;
  expandedFlight: string;
  setExpandedFlight: (expandedFlight: string) => void;
  fareClassFilter: FareclassOptionFilter;
  flights: Flights | null;
  hasMore: boolean;
  flightsToShow: Array<IFlightListData>;
  onAlgomerchClick: (tagText: string) => void;
  onFareSubmit: (flight: IFlightListData, fareId: string) => void;
  onSliceSelect: (
    fareId: string,
    selectedFareRating: number,
    flight: IFlightListData
  ) => void;
  isInChooseReturnStep: boolean;
  isRoundTrip: boolean;
  maxFlightPrice: number;
  returnDate?: Date | null;
  rewardsKey?: string;
  outgoingFareRating?: number;
  setFetchMoreData?: () => void;
  hasAppliedFareClassFilter: boolean;
  faresToShow: FilteredFare[];
};

export const InfiniteFlights: FC<IInfiniteFlightsProps> = (props) => {
  const {
    flightsToShow,
    expandedFareDetails,
    expandedFlight,
    isInChooseReturnStep,
    faresToShow,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const getFilteredFares = (fareDetails: FareDetails[]) => {
    const selectedFare = fareDetails.find((f) => f.id === expandedFlight);
    if (!selectedFare) return fareDetails;
    const selectedFareRating =
      selectedFare.slices[isInChooseReturnStep ? 1 : 0].fareShelf?.rating;
    if (!selectedFareRating) return fareDetails;
    return fareDetails.filter(
      (fare) =>
        (fare.slices[isInChooseReturnStep ? 1 : 0].fareShelf?.rating || 0) >=
        selectedFareRating
    );
  };

  const filteredExpandedFareDetails =
    expandedFareDetails && expandedFlight
      ? {
          ...expandedFareDetails,
          fareDetails: getFilteredFares(expandedFareDetails.fareDetails),
        }
      : expandedFareDetails;

  return matchesMobile ? (
    <MobileInfiniteFlights
      {...props}
      flightsToRender={flightsToShow}
      faresToShow={faresToShow}
      expandedFareDetails={filteredExpandedFareDetails}
    />
  ) : (
    <DesktopInfiniteFlights
      {...props}
      flightsToRender={flightsToShow}
      faresToShow={faresToShow}
      expandedFareDetails={filteredExpandedFareDetails}
    />
  );
};

export default InfiniteFlights;
