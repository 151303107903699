import { useHistory } from "react-router";

import { FlightError } from "@b2bportal/air-booking-api";
import {
  Payment,
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { CallState, CHFAR_ID_QUERY_PARAM } from "@hopper-b2b/types";
import {
  AirChfarExerciseDiscountSelectors,
  CartFulfillErrorModal,
  CartFulfillEventType,
  cartFulfillSelectors,
  CartFulfillState,
  CartQuoteEventType,
  cartQuoteSelectors,
  getChildState,
  useCheckoutSend,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";

import { PATH_FLIGHTS_SEARCH } from "../../../../utils/urlPaths";
import { Event, TEvent } from "../../events";
import { getClientAssets } from "../../selectors";
import { LoadingScreen } from "../../../../components/Slots";
import { ErrorScreen } from "../../../../components/ErrorScreen";
import { NupayPaymentError } from "../../../../checkout/states/payments/types";
import { FlightMachineContext } from "../../types";
import "./styles.scss";

export const CartFulfillComponent = (props) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [state] = useCheckoutState<TEvent, FlightMachineContext>();
  const childState = getChildState(state.value);
  const clientAssets = useSelector(getClientAssets);
  const isErrorModalOpen = useSelector(
    cartFulfillSelectors.getOpenCartFulfillErrorModal
  );

  const getMessages = () => {
    switch (childState) {
      case CartFulfillState.schedule:
      case CartFulfillState.polling:
        return {
          message: t("bookingFlight"),
          secondaryMessage: <I18nMarkup tKey="bookingFlightSubtitle" />,
        };
      default:
        return { message: "" };
    }
  };

  const loadingSteps = [
    {
      title: t("scheduleFulfillLoading.step1Title"),
    },
    {
      title: t("scheduleFulfillLoading.step2Title"),
    },
    {
      title: t("scheduleFulfillLoading.step3Title"),
    },
  ];

  const callState = useSelector(cartFulfillSelectors.getCartFulfillCallState);

  return isErrorModalOpen ? (
    <MappedErrorModal />
  ) : (
    <LoadingScreen
      loading={callState === CallState.InProcess}
      averageLoadingTime={getEnvVariables("cartFulfillLoadingTime") as number}
      className=".schedule-fulfill-loading"
      loadingSteps={loadingSteps}
    />
  );
};

export const MappedErrorModal = () => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();
  const cartErrorModal = useSelector(cartFulfillSelectors.getCartFulfillError);
  const chfarId = useSelector(AirChfarExerciseDiscountSelectors.getChfarId);

  const clearError = () => send(CartFulfillEventType.CLEAR_CART_FULFILL_ERROR);

  const searchAgain = () => {
    clearError();
    //ChFAR exercise
    if (chfarId)
      history.push(
        `/${PATH_FLIGHTS_SEARCH}?${CHFAR_ID_QUERY_PARAM}=${chfarId}`
      );
    else history.push("/" + PATH_FLIGHTS_SEARCH);
  };
  const updateTravelers = () => {
    clearError();
    send(CartFulfillEventType.OPEN_PASSENGER_PICKER);
  };
  const goToConfirmation = () => {
    clearError();
    send(Event.GO_TO_BOOKING_CONFIRMATION);
  };

  const allowedQuoteRetry = useSelector(cartQuoteSelectors.getAllowQuoteRetry);
  const retryQuote = () => {
    clearError();
    send(CartQuoteEventType.RETRY_QUOTE);
  };
  const retryQuoteOrSearchAgain = {
    ...(allowedQuoteRetry
      ? {
          primaryButtonText: t("checkoutError.tryAgain"),
          primaryOnClick: retryQuote,
          secondaryButtonText: t("checkoutError.findNewFlight"),
          secondaryOnClick: searchAgain,
        }
      : {
          primaryButtonText: t("checkoutError.findNewFlight"),
          primaryOnClick: searchAgain,
        }),
  };
  const DEFAULT_ERROR_MODEL_PROPS = {
    title: t("checkoutError.genericErrorTitle"),
    subtitle: t("checkoutError.genericErrorSubtitle"),
    className: "error",
    ...retryQuoteOrSearchAgain,
  };

  const getFlightError = (flightError: FlightError) => {
    switch (flightError) {
      case FlightError.CheckInMinimumAgeNotMet:
        return {
          title: t("checkoutError.checkInMinimumAgeNotMetTitle"),
          subtitle: t("checkoutError.checkInMinimumAgeNotMetSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.DuplicateBooking:
        return {
          title: t("checkoutError.duplicateBookingTitle"),
          subtitle: t("checkoutError.duplicateBookingSubtitle"),
          primaryButtonText: t("checkoutError.acknowlegement"),
          primaryOnClick: goToConfirmation,
          className: "warn",
        };
      case FlightError.ExpiringPassport:
        return {
          title: t("checkoutError.expiringPassportTitle"),
          subtitle: t("checkoutError.expiringPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.ExpiredPassport:
        return {
          title: t("checkoutError.expiredPassportTitle"),
          subtitle: t("checkoutError.expiredPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.IllegalLapInfantKTN:
        return {
          title: t("checkoutError.illegalLapInfantKTNTitle"),
          subtitle: t("checkoutError.illegalLapInfantKTNSubtitle"), // todo: we don't have the pax name
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.IllegalLapInfantRedress:
        return {
          title: t("checkoutError.illegalLapInfantRedressTitle"),
          subtitle: t("checkoutError.illegalLapInfantRedressSubtitle"), // todo: we don't have the pax name
          primaryButtonText: t("checkoutError.updateTraveler"),
          className: "missing",
          primaryOnClick: updateTravelers,
        };
      case FlightError.Inactivity:
        // inactivity here means that the flight booking session timed out
        return {
          title: t("checkoutError.flightsInActivityTitle"),
          subtitle: t("checkoutError.flightsInActivitySubtitle"),
          primaryButtonText: t("checkoutError.tryAgain"),
          className: "timeout",
          ...retryQuoteOrSearchAgain,
        };
      case FlightError.InvalidCustomer:
        return {
          title: t("checkoutError.invalidCustomerTitle"),
          subtitle: t("checkoutError.invalidCustomerSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.InvalidPassengers:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.invalidPassengerSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          className: "missing",
          primaryOnClick: updateTravelers,
        };
      case FlightError.InvalidPassengerName:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.invalidPassengerName"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.InvalidPassportNumber:
        return {
          title: t("checkoutError.invalidPassportNumberTitle"),
          subtitle: t("checkoutError.invalidPassportNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.LapInfantTooOld:
        return {
          title: t("checkoutError.lapInfantTooOldTitle"),
          subtitle: t("checkoutError.lapInfantTooOldSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.LapInfantsUnsupported:
        return {
          title: t("checkoutError.lapInfantsUnsupportedTitle"),
          subtitle: t("checkoutError.lapInfantsUnsupportedSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.MalformedKnownTravelerNumber:
        return {
          title: t("checkoutError.malformedKnownTravelerNumberTitle"),
          subtitle: t("checkoutError.malformedKnownTravelerNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.MalformedRedressNumber:
        return {
          title: t("checkoutError.malformedRedressNumberTitle"),
          subtitle: t("checkoutError.malformedRedressNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.MissingAirlineLocator:
        return {
          title: t("checkoutError.missingAirlineLocatorTitle"),
          subtitle: t("checkoutError.missingAirlineLocatorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case FlightError.MissingNationality:
        return {
          title: t("checkoutError.missingNationalityTitle"),
          subtitle: t("checkoutError.missingNationalitySubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "missing",
        };
      case FlightError.MissingPassport:
        return {
          title: t("checkoutError.missingPassportTitle"),
          subtitle: t("checkoutError.missingPassportSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.MissingPassportNumber:
        return {
          title: t("checkoutError.missingPassportNumberTitle"),
          subtitle: t("checkoutError.missingPassportNumberSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.NoAdultPassenger:
        return {
          title: t("checkoutError.noAdultPassengerTitle"),
          subtitle: t("checkoutError.noAdultPassengerSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.NoContactInformation:
        return {
          title: t("checkoutError.noContactInformationTitle"),
          subtitle: t("checkoutError.noContactInformationSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"), // todo: update contact info
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.NoTicketlessResponse:
        return {
          title: t("checkoutError.noTicketlessResponseTitle"),
          subtitle: t("checkoutError.noTicketlessResponseSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case FlightError.PassengerNameTooShort:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.passengerNameTooShortSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.ProviderError:
        return {
          title: t("checkoutError.providerErrorTitle"),
          subtitle: t("checkoutError.providerErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case FlightError.SeatedInfantsUnsupported:
        return {
          title: t("checkoutError.seatedInfantsUnsupportedTitle"),
          subtitle: t("checkoutError.seatedInfantsUnsupportedSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "error",
        };
      case FlightError.TicketingNotStarted:
        return {
          title: t("checkoutError.ticketingNotStartedTitle"),
          subtitle: t("checkoutError.ticketingNotStartedSubtitle"),
          primaryButtonText: "", // todo close CTA
          primaryOnClick: goToConfirmation,
          className: "error",
        };
      case FlightError.TicketingUnconfirmed:
        return {
          title: t("checkoutError.ticketingUnconfirmedTitle"),
          subtitle: t("checkoutError.ticketingUnconfirmedSubtitle"),
          primaryButtonText: "", // todo go to confirmation page
          primaryOnClick: goToConfirmation,
        };
      case FlightError.TicketingUnknown:
        return {
          title: t("checkoutError.ticketingUnknownTitle"),
          subtitle: t("checkoutError.ticketingUnknownSubtitle"),
          primaryButtonText: "", // todo go to confirmation page
          primaryOnClick: goToConfirmation,
        };
      case FlightError.TooManyLapInfants:
        return {
          title: t("checkoutError.tooManyLapInfantsTitle"),
          subtitle: t("checkoutError.tooManyLapInfantsSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.TooManyPassengers:
        return {
          title: t("checkoutError.tooManyPassengersTitle"),
          subtitle: t("checkoutError.tooManyPassengersSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.TransientServiceError:
        return {
          title: t("checkoutError.transientServiceErrorTitle"),
          subtitle: t("checkoutError.transientServiceErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case FlightError.UnknownSabreAppError:
        return {
          title: t("checkoutError.unknownSabreAppErrorTitle"),
          subtitle: t("checkoutError.unknownSabreAppErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case FlightError.UnsupportedNameCharacters:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.unsupportedNameCharactersSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case FlightError.UpgradeRequired:
        return {
          title: t("checkoutError.upgradeRequiredTitle"),
          subtitle: t("checkoutError.unknownSabreAppErrorSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      default:
        // FlightError.Unknown
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getProductError = (error: ProductError) => {
    switch (error.value.type) {
      case Product.Flight:
      case Product.MultiProviderFlight:
        return getFlightError(error.value.value as FlightError);
      case Product.Seats:
        // https://github.com/hopper-org/b2b-interfaces/blob/8316205c08da3b4405647ff5245f3bdc76f0ef73/purchase/src/main/scala/com/hopper/b2b/purchase/Models.scala#L114
        return getFlightError(error.value.value as FlightError);
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getNupayPaymentError = (error: NupayPaymentError) => {
    switch (error) {
      case NupayPaymentError.GetUserFailure:
      case NupayPaymentError.OpenSessionFailure:
      case NupayPaymentError.QuoteIdMismatch:
      case NupayPaymentError.UnknownFailure:
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getPaymentError = (error: PaymentError) => {
    switch (error.value.type) {
      case Payment.NupayPayment:
        return getNupayPaymentError(error.value.value as NupayPaymentError);
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getErrorModalProps = (
    type?: CartFulfillErrorModal,
    data?: PurchaseError[]
  ) => {
    switch (type) {
      case CartFulfillErrorModal.FailedPolling: {
        const head = data?.[0]; // todo: we probably want to have some sort of priority order on errors?
        switch (head?.Error) {
          case PurchaseErrorEnum.NoAvailability:
            return {
              title: t("checkoutError.noAvailabilityTitle"),
              subtitle: t("checkoutError.noAvailabilitySubtitle"),
              primaryButtonText: t("checkoutError.searchAgain"),
              primaryOnClick: searchAgain,
              className: "error",
            };
          case PurchaseErrorEnum.PaymentError:
            return getPaymentError(head as PaymentError);
          case PurchaseErrorEnum.ProductError: // todo. Missing `Error` attribute
            return getProductError(head as ProductError);
          case PurchaseErrorEnum.ExpiredFulfillToken:
            return {
              title: t("checkoutError.noAvailabilityTitle"),
              subtitle: t("checkoutError.noAvailabilitySubtitle"),
              primaryButtonText: t("checkoutError.searchAgain"),
              primaryOnClick: searchAgain,
              className: "error",
            };
          default:
            // default case covers
            // - PurchaseErrorEnum.AuthError
            // - PurchaseErrorEnum.ExpiredQuoteToken
            // - PurchaseErrorEnum.FraudAutoReject
            // - PurchaseErrorEnum.InActivity
            // - PurchaseErrorEnum.InvalidSession
            // - PurchaseErrorEnum.NoPaymentsProvided
            // - PurchaseErrorEnum.NonDistinctPayments
            // - PurchaseErrorEnum.ErrorCode (because this isn't standardized enough yet)
            return DEFAULT_ERROR_MODEL_PROPS;
        }
      }
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };
  const errorModalProps = getErrorModalProps(
    cartErrorModal?.type,
    cartErrorModal?.data as PurchaseError[]
  );

  return <ErrorScreen {...errorModalProps} />;
};
