import { FiatPrice } from "@b2bportal/purchase-api";
import { CartContext } from "@hopper-b2b/checkout";
import {
  InstallmentPlan,
  PaymentMethodsResponse,
} from "./api/getPaymentMethods";

export enum PaymentState {
  payment = "payment",
}

export interface NupayCreditInstallment {
  id?: string;
  numOfInstallments: number;
  pricePerInstallment: FiatPrice;
  interest: FiatPrice;
  interestPercent: number;
  total: FiatPrice;
  tax: FiatPrice;
  base: FiatPrice;
}

export interface NubankPaymentContext {
  hasAuth?: boolean;
  redirectUrl?: string;
  error?: { step?: string; details?: unknown };
  paymentMethods: Array<PaymentMethodsResponse>;
  selectedPaymentMethod?: PaymentMethodsResponse;
  selectedCreditInstallment?: InstallmentPlan;
}
export interface PaymentContext extends CartContext {
  [PaymentState.payment]: NubankPaymentContext;
}

export const NubankPaymentInitialContext: NubankPaymentContext = {
  hasAuth: undefined,
  error: undefined,
  paymentMethods: [],
};

export const getPaymentInitialContext = () => ({
  [PaymentState.payment]: { ...NubankPaymentInitialContext },
});
