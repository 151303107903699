import { LodgingAddress } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getGoogleStaticMapSrc,
  getTwoLineAddresses,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import "./styles.scss";
import { ActionButton } from "@hopper-b2b/ui-core";

export interface HotelLocationProps {
  coordinates: string;
  hotelName: string;
  hotelAddress?: LodgingAddress;
  hotelPhone: string;
  isMobile: boolean;
}

const MAP_LINK_PREFIX = `https://www.google.com/maps/search/?api=1`;

export const HotelLocation = ({
  coordinates,
  hotelName,
  hotelAddress,
  hotelPhone,
  isMobile,
}: HotelLocationProps) => {
  const { t } = useI18nContext();

  const address = useMemo(() => {
    const twoLineAddress = getTwoLineAddresses(hotelAddress);
    return twoLineAddress.line1 + " " + twoLineAddress.line2;
  }, [hotelAddress]);

  const onMapClick = (
    coordinateString: string,
    address: string,
    name: string
  ) => {
    const url = `${MAP_LINK_PREFIX}&query=${coordinateString}&query_place_id=${encodeURI(
      address
    )}&query=${encodeURI(name)}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box className="hotel-map-container apac">
      <Box className="hotel-map">
        <ActionButton
          className="view-map-button"
          message={t("hotelDetailsViewMapButtonLabel")}
          onClick={() => onMapClick(coordinates, address, hotelName)}
          defaultStyle="h4r-primary"
        />
        {coordinates ? (
          <img
            className="static-map"
            src={getGoogleStaticMapSrc(
              coordinates,
              "15",
              "600x200",
              "2",
              false
            )}
            alt={t("hotelMapImageAlt", {
              hotelName: hotelName,
            })}
          />
        ) : null}
      </Box>
      <Box className={clsx("address-details", { mobile: isMobile })}>
        <Typography variant="body1">{address}</Typography>

        <Typography variant="body1">
          <a href={`tel:${hotelPhone}`} className="hotel-phone-link">
            {hotelPhone}
          </a>
        </Typography>
      </Box>
    </Box>
  );
};
