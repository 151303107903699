import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { FintechProductType } from "@hopper-b2b/types";
import {
  ActionButton,
  GenericInfoPopup,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../../../App";

import styles from "../FintechProduct.module.scss";
import "./styles.scss";
import { useContext } from "react";
import { Box } from "@material-ui/core";

export const FintechMoreInfo = ({
  type,
  close,
  open,
  openTerms,
}: {
  type: FintechProductType;
  close: () => void;
  open: boolean;
  openTerms: () => void;
}) => {
  const { t } = useI18nContext();

  const clientContext = useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();

  const isCfar = type === FintechProductType.CfarPurchase;
  const termsUrl = isCfar
    ? "https://www.hopper.com/legal/Air-CFAR"
    : "https://www.hopper.com/legal/Flight-Delay-Guarantee";
  const learnMoreTitle = isCfar
    ? t("cfar.learnMoreTitle")
    : t("flightDelayGuarantee.learnMoreTitle");
  const learnMoreContent = isCfar
    ? "cfar.learnMoreContent"
    : "flightDelayGuarantee.learnMoreContent";

  return matchesMobile ? (
    <MobilePopoverCard
      centered
      open={open}
      onClose={close}
      fullScreen={false}
      headerElement={
        <img
          src={clientContext.assets?.bunnyWithSuitcase}
          alt="bunny-with-suticase"
        />
      }
      className="more-info"
      topRightButton={
        <img src={clientContext.assets?.close} alt="close" onClick={close} />
      }
    >
      <Box className="more-info-title">{learnMoreTitle}</Box>
      <Box className="more-info-content">
        <I18nMarkup tKey={learnMoreContent} />
        <a
          href={termsUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#01AAE4",
            cursor: "pointer",
          }}
        >
          <I18nMarkup tKey="flightDelayGuarantee.termsAndConditions" />
        </a>
        <Box className="close-button-mobile-container">
          <ActionButton
            className="close-button-mobile"
            buttonClassName="close-button"
            disabled={false}
            message={t("close.button")}
            onClick={close}
            disableElevation={true}
          />
        </Box>
      </Box>
    </MobilePopoverCard>
  ) : (
    <GenericInfoPopup
      className={styles.fintechPopup}
      onClose={close}
      buttons={[]}
      image={
        <img
          src={clientContext.assets?.bunnyWithSuitcase}
          alt="bunny-with-suitcase"
        />
      }
      closeIcon={clientContext.assets?.close}
      isMobile={matchesMobile}
      open={open}
      title={learnMoreTitle}
      subtitle={
        <>
          <I18nMarkup tKey={learnMoreContent} />
          <a
            href={termsUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              color: "#01AAE4",
              cursor: "pointer",
            }}
          >
            <I18nMarkup tKey="flightDelayGuarantee.termsAndConditions" />
          </a>
        </>
      }
    />
  );
};
