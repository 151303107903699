// eslint-disable-next-line @nx/enforce-module-boundaries
import { searchActions, searchSelectors } from "@hopper-b2b/flights";
import { useDispatch, useSelector } from "react-redux";
import {
  AccessibleAutocomplete,
  IB2BSearchAutocompleteProps,
} from "./AccessibleAutocomplete";

type ConnectedB2BAutoCompleteInputProps<T> = Omit<
  IB2BSearchAutocompleteProps<T>,
  "loading" | "valueCategories" | "fetchValueCategories"
>;

export const OriginAutocomplete = <
  ValueType extends {
    label: string;
  }
>(
  props: ConnectedB2BAutoCompleteInputProps<ValueType>
) => {
  const dispatch = useDispatch();
  const loading = useSelector(searchSelectors.getOriginCategoriesLoading);
  const valueCategories = useSelector(searchSelectors.getOriginCategories);
  const fetchValueCategories = (queryString: string) => {
    dispatch(searchActions.fetchOriginCategories(queryString));
  };
  const setFocusedAutoComplete = () => {
    dispatch(searchActions.setFocusedAutocomplete("origin"));
  };
  const clearFocusedAutocomplete = () => {
    dispatch(searchActions.setFocusedAutocomplete(null));
  };
  const focusedAutocomplete = useSelector(
    searchSelectors.getFocusedAutocomplete
  );
  const isOriginFocused = focusedAutocomplete === "origin";

  return (
    <AccessibleAutocomplete
      loading={loading}
      valueCategories={isOriginFocused ? valueCategories : []}
      fetchValueCategories={fetchValueCategories}
      {...props}
      isFocusedAutocomplete={isOriginFocused}
      setFocusedAutocomplete={setFocusedAutoComplete}
      clearFocusedAutocomplete={clearFocusedAutocomplete}
    />
  );
};

export const DestinationAutocomplete = <
  ValueType extends {
    label: string;
  }
>(
  props: ConnectedB2BAutoCompleteInputProps<ValueType>
) => {
  const dispatch = useDispatch();
  const loading = useSelector(searchSelectors.getDestinationCategoriesLoading);
  const valueCategories = useSelector(searchSelectors.getDestinationCategories);
  const fetchValueCategories = (queryString: string) => {
    dispatch(searchActions.fetchDestinationCategories(queryString));
  };
  const setFocusedAutoComplete = () => {
    dispatch(searchActions.setFocusedAutocomplete("destination"));
  };
  const clearFocusedAutocomplete = () => {
    dispatch(searchActions.setFocusedAutocomplete(null));
  };
  const focusedAutocomplete = useSelector(
    searchSelectors.getFocusedAutocomplete
  );
  const isDestinationFocused = focusedAutocomplete === "destination";

  return (
    <AccessibleAutocomplete
      loading={loading}
      valueCategories={isDestinationFocused ? valueCategories : []}
      fetchValueCategories={fetchValueCategories}
      {...props}
      isFocusedAutocomplete={isDestinationFocused}
      setFocusedAutocomplete={setFocusedAutoComplete}
      clearFocusedAutocomplete={clearFocusedAutocomplete}
    />
  );
};
