import { useMemo } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { Airport } from "@b2bportal/air-booking-api";
import { SelectedSeatsSegment } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot } from "../../Slots";
import { SelectedSeatCard } from "./SelectedSeatCard";
import "./styles.scss";

export interface SeatsSummaryRowProps {
  airports: { [key: string]: Airport };
  outboundSeatSegments: SelectedSeatsSegment[];
  outboundOriginCode: string;
  returnSeatSegments?: SelectedSeatsSegment[];
  returnOriginCode?: string;
  onEditClick?: (idx: number) => void;
  addSeats?: () => void;
  review?: boolean;
  description?: string | JSX.Element | null;
}

export const SeatsSummaryRow = ({
  outboundSeatSegments,
  returnSeatSegments,
  onEditClick,
  addSeats,
  outboundOriginCode,
  returnOriginCode,
  airports,
  review = true,
  description = null,
}: SeatsSummaryRowProps) => {
  const { t } = useI18nContext();

  const isLayover = (flightSegment: SelectedSeatsSegment) => {
    return flightSegment.isOutgoing
      ? flightSegment.originCode !== outboundOriginCode
      : flightSegment.originCode !== returnOriginCode;
  };

  const getLocationString = (location: string) => {
    return airports?.[location]
      ? `${airports[location]?.cityName} (${location})`
      : location;
  };

  const renderEmptySeatsDescription = useMemo(
    () =>
      outboundSeatSegments?.length === 0 &&
      returnSeatSegments?.length === 0 &&
      addSeats,
    [addSeats, outboundSeatSegments?.length, returnSeatSegments?.length]
  );

  return (
    <div className="seats-summary-row">
      <div className="seats-summary-row-left">
        <div className="seats-summary-row-value">
          {review ? (
            <p className="seats-summary-row-title">{t("Seats")}</p>
          ) : null}
          {renderEmptySeatsDescription ? (
            <div className="seats-summary-row-empty">
              <div className="seats-summary-row-description">
                {t("emptySeatsDescription")}
              </div>
              <div className="seats-summary-row-add">
                <button className="add-seats-button" onClick={addSeats}>
                  {t("addSeats")}
                </button>
              </div>
            </div>
          ) : (
            description
          )}
          {outboundSeatSegments?.length > 0 ? (
            <Box className="outbound-selected-seats-container">
              {outboundSeatSegments.map((segment, idx) => {
                return (
                  <Box
                    key={segment.flightNumber}
                    className={clsx("segment-wrapper", {
                      last:
                        returnSeatSegments?.length === 0 &&
                        idx === outboundSeatSegments.length - 1,
                    })}
                  >
                    {isLayover(segment) && (
                      <Box className="flight-change-text-wrapper">
                        <Typography
                          className="flight-change-text"
                          variant="body1"
                        >
                          {t("flightChangeIn", {
                            location: getLocationString(
                              segment.seats.originCode
                            ),
                          })}
                        </Typography>
                      </Box>
                    )}
                    <Slot
                      id="selected-seat-card"
                      selectedSeats={segment.seats}
                      onEditClick={onEditClick}
                      isMobile={true}
                      airports={airports}
                      idx={segment.idx}
                      component={
                        <SelectedSeatCard
                          selectedSeats={segment.seats}
                          onEditClick={onEditClick}
                          isMobile={true}
                          airports={airports}
                          idx={segment.idx}
                        />
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          ) : null}
          {returnSeatSegments?.length > 0 && (
            <>
              <div className="seat-return-section-divider" />
              <Box className="return-selected-seats-container">
                {returnSeatSegments.map((segment, idx) => {
                  return (
                    <Box
                      key={segment.flightNumber}
                      className={clsx("segment-wrapper", {
                        last: idx === returnSeatSegments.length - 1,
                      })}
                    >
                      {isLayover(segment) && (
                        <Box className="flight-change-text-wrapper">
                          <Typography
                            className="flight-change-text"
                            variant="body1"
                          >
                            {t("flightChangeIn", {
                              location: getLocationString(
                                segment.seats.originCode
                              ),
                            })}
                          </Typography>
                        </Box>
                      )}
                      <Slot
                        id="selected-seat-card"
                        selectedSeats={segment.seats}
                        onEditClick={onEditClick}
                        isMobile={true}
                        airports={airports}
                        idx={segment.idx}
                        component={
                          <SelectedSeatCard
                            selectedSeats={segment.seats}
                            onEditClick={onEditClick}
                            isMobile={true}
                            airports={airports}
                            idx={segment.idx}
                          />
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeatsSummaryRow;
