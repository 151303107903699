import { connect, ConnectedProps } from "react-redux";
import {
  getPriceFreezeOfferCheapestFare,
  getPriceFreezeOfferCheapestTripTripId,
  isPriceFreezeOfferIncludedInShopSummarySelector,
} from "../../reducer/selectors";
import { PriceFreezeEntryCard } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    tripId: getPriceFreezeOfferCheapestTripTripId(state),
    fareId: getPriceFreezeOfferCheapestFare(state),
    hasPriceFreezeOffer: isPriceFreezeOfferIncludedInShopSummarySelector(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeEntryCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeEntryCard = connector(PriceFreezeEntryCard);
