import { FC, useCallback, useState } from "react";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { trackEvent } from "@hopper-b2b/api";
import {
  CHOSE_FROM_SEATMAP,
  GordianSeatSegment,
  VIEWED_SEATMAP,
} from "@hopper-b2b/types";
import { GordianSeat } from "@b2bportal/air-seats-api";
import { HopperSeatTotal } from "@checkout/states/Products/Flight/states/SeatSelection/context";
import { SeatMapOptions } from "./constants";
import { HopperSeatMapOptions, HopperSeats } from "./types";

const FRAME_ID = "seat-map";

const Frame: FC<{
  seatMapHtml?: string;
  onEditMode?: boolean;
  segmentIndex?: number;
  styleString: string;
  seatMapOptions?: SeatMapOptions;
  setOpen: (val: boolean) => void;
  setSelectedSeats: (
    selectedSeats: GordianSeat[],
    selectedSeatSegments: GordianSeatSegment[],
    selectedSeatTotal?: HopperSeatTotal
  ) => void;
  onFrameLoaded?: () => void;
  hopperStringStyle?: string;
  hopperSeatMapOptions?: HopperSeatMapOptions;
}> = ({
  seatMapHtml,
  onEditMode,
  segmentIndex,
  styleString,
  seatMapOptions,
  setOpen,
  setSelectedSeats,
  onFrameLoaded,
  hopperStringStyle,
  hopperSeatMapOptions,
}) => {
  const { t } = useI18nContext();
  const [frameLoading, setFrameLoading] = useState(true);

  const handleFrameLoaded = useCallback(() => {
    setFrameLoading(false);
    if (onFrameLoaded != null) onFrameLoaded();
  }, [onFrameLoaded]);

  const onFrameLoad = (_: any) => {
    const iframe = document.getElementById(
      FRAME_ID
    ) as HTMLIFrameElement as any;

    iframe.contentWindow.HopperSeats != null
      ? initHopperSeats(iframe)
      : initGordianSeats(iframe);
  };

  const initGordianSeats = (iframe: any) => {
    // Update styles
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styleString;
    iframe.contentWindow.document.head.appendChild(styleSheet);
    iframe.contentDocument.body.children[0].style.height = "100vh";

    const addPaxIdAndName = iframe.contentWindow.addPaxIdAndName;
    const getAppDisplayInfo = iframe.contentWindow.getAppDisplayInfo;
    const seatsSelected = iframe.contentWindow.hopper_seats.seatsSelected;
    iframe.contentWindow.gordianResponse = (products: any) => {
      const productsWithPaxId = addPaxIdAndName(products);
      const appDisplayInfo = getAppDisplayInfo(products);
      seatsSelected(productsWithPaxId, appDisplayInfo);
      if (productsWithPaxId.length > 0) {
        trackEvent({
          eventName: CHOSE_FROM_SEATMAP,
          properties: {},
        });
      }
      setOpen(false);
      setSelectedSeats(productsWithPaxId, appDisplayInfo.segments);
    };
    const passengers = iframe.contentWindow.PASSENGERS;
    const container = document.getElementById("seatmap");
    iframe.contentWindow.GordianDisplay.showSeatMap({
      ...seatMapOptions,
      passengers,
      container,
      segmentIndex,
      editMode: onEditMode,
    }).then(() => {
      trackEvent({
        eventName: VIEWED_SEATMAP,
        properties: {},
      });

      handleFrameLoaded();
    });
  };

  const initHopperSeats = (iframe: any) => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = hopperStringStyle;
    iframe.contentWindow.document.head.appendChild(styleSheet);
    iframe.contentDocument.body.children[0].style.height = "100vh";

    iframe.contentWindow.hopperResponse = (products: any, appDisplayInfo) => {
      setOpen(false);

      if (products.length > 0) {
        trackEvent({
          eventName: CHOSE_FROM_SEATMAP,
          properties: {},
        });
      }
      setSelectedSeats(products, appDisplayInfo.segments, appDisplayInfo.total);
    };
    (iframe.contentWindow.HopperSeats as HopperSeats)
      .showSeatMap({
        segmentIndex,
        ...hopperSeatMapOptions,
      })
      .then(() => {
        trackEvent({
          eventName: VIEWED_SEATMAP,
          properties: {},
        });

        handleFrameLoaded();
      })
      .catch(() => {
        //Closes seat map
        //TODO: implement actual error screen
        setOpen(false);
      });
  };
  return (
    <div className={clsx("iframe-wrapper", { loading: frameLoading })}>
      <iframe
        id={FRAME_ID}
        marginHeight={0}
        marginWidth={0}
        frameBorder={0}
        title={t("seats.iframeTitle")}
        srcDoc={seatMapHtml}
        onLoad={(obj) => onFrameLoad(obj)}
      ></iframe>
    </div>
  );
};

export default Frame;
