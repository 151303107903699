import { useI18nContext } from "@hopper-b2b/i18n";
import { IMonthBucket, TripCategory } from "@hopper-b2b/types";
import {
  ActionButton,
  DesktopPopupModal,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import styles from "./CalendarPicker.module.scss";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "./MonthAndDatePicker";

export interface CalendarTrackingProperties {
  origin: string;
  destination: string;
  tripCategory: TripCategory;
}

interface ICalendarPickerProps {
  open?: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  closePopup?: () => void;
  disabled: boolean;
  tripCategory: TripCategory;
  focusedMonthIndex?: number;
  onComplete?: () => void;
  isHistoryPushDisabled?: boolean;
  isMobile?: boolean;
  modal?: boolean;
  prices?: string[];
  months?: IMonthBucket[];
  headerTitle: string;
  startDateLabel?: string;
  endDateLabel?: string;
  paperClassName?: string;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
}

export const CalendarPicker = ({
  open,
  closePopup,
  disabled,
  tripCategory,
  departureDate,
  returnDate,
  onComplete,
  isMobile = false,
  modal = false,
  setDepartureDate,
  setReturnDate,
  prices,
  months,
  startDateLabel,
  endDateLabel,
  headerTitle,
  paperClassName,
  minAllowedDate,
  maxAllowedDate,
}: ICalendarPickerProps) => {
  const { t } = useI18nContext();

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const calendarClassName = useMemo(
    () => (tripCategory === TripCategory.ONE_WAY ? "one-way" : "roundtrip"),
    [tripCategory]
  );

  const onDatesSelect = useCallback(() => {
    onComplete ? onComplete() : closePopup?.();
  }, [closePopup, onComplete]);

  const onClose = useCallback(() => {
    closePopup?.();
  }, [closePopup]);

  const readyToSearch = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? !!departureDate
      : tripCategory === TripCategory.ROUND_TRIP
      ? !!returnDate && !!departureDate
      : false;
  }, [tripCategory, departureDate, returnDate]);

  return isMobile ? (
    <MobilePopoverCard
      className={clsx(
        styles["mobile-calendar-picker-root"],
        "mobile-calendar-picker-root",
        { modal }
      )}
      fullScreen
      open={open}
      onClose={onClose}
      paperClassName={paperClassName}
      topRightButton={
        <button className="close-mobile-calendar" onClick={onClose}>
          <IconComponent name={IconName.Close} />
        </button>
      }
      bottomButton={
        <ActionButton
          className="mobile-calendar-picker-search-button"
          onClick={onDatesSelect}
          message={t("searchHotels")}
          disabled={!readyToSearch}
        />
      }
    >
      <MonthAndDatePicker
        tripCategory={tripCategory}
        currency=""
        className={clsx(`b2b ${calendarClassName}`, {
          "search-ready": readyToSearch,
          modal,
        })}
        priceTags={prices}
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        startDate={departureDate}
        endDate={returnDate}
        months={months}
        header={headerTitle}
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
      />
    </MobilePopoverCard>
  ) : (
    <DesktopPopupModal
      open={Boolean(open)}
      onClose={onClose}
      className={clsx(
        styles["desktop-calendar-picker-popup-root"],
        "desktop-flight-calendar-picker-popup-root",
        "flights-module",
        calendarClassName
      )}
      contentClassName="desktop-calendar-picker-wrapper"
      invisibleBackdrop={false}
    >
      <MonthAndDatePicker
        tripCategory={tripCategory}
        currency=""
        className={clsx(`b2b ${calendarClassName}`, {
          "search-ready": readyToSearch,
          modal,
        })}
        priceTags={prices}
        viewType={MonthAndDatePickerType.Horizontal}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        startDate={departureDate}
        endDate={returnDate}
        header={headerTitle}
        months={months}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
      />
      <ActionButton
        onClick={onDatesSelect}
        disabled={disabled}
        className="select-dates-button"
        message={t("searchHotels")}
      />
    </DesktopPopupModal>
  );
};
