import { useCallback } from "react";
import { IconComponent, IconName, RadioDropdown } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

interface Props {
  value: number | number[];
  onChange: (nextValue: number | number[]) => void;
  ariaLabel: string;
  dropdownLabel: string;
}

export function StarRatingFilter({
  value,
  onChange,
  ariaLabel,
  dropdownLabel,
}: Props) {
  const { t } = useI18nContext();

  const options = [
    { value: [], stars: 0, content: t("any") },
    {
      value: [2, 3, 4, 5],
      stars: 2,
      ariaLabel: t("starsAndUp", { stars: 2 }),
    },
    {
      value: [3, 4, 5],
      stars: 3,
      ariaLabel: t("starsAndUp", { stars: 3 }),
    },
    { value: [4, 5], stars: 4, ariaLabel: t("starsAndUp", { stars: 4 }) },
    { value: [5], stars: 5, ariaLabel: t("starsAndUp", { stars: 5 }) },
  ];

  const getLabel = useCallback((starRating: number) => {
    switch (starRating) {
      case 0:
        return t("any");
      default:
        return <Rating value={starRating} />;
    }
  }, []);

  const handleSelectOption = useCallback(
    (option) => onChange(options.find((o) => o.stars == option)?.value),
    [options, onChange]
  );

  return (
    <RadioDropdown
      popoverClassName="star-rating-filter"
      options={options.map((option) => {
        return {
          value: option.stars,
          label: getLabel(option.stars),
        };
      })}
      setOption={handleSelectOption}
      selected={value[0] || 0}
      dropdownLabel={dropdownLabel}
      groupAriaLabel={ariaLabel}
      buttonAriaLabel={ariaLabel}
      showDropdownContentOnly
    />
  );
}

function Rating({ value }: { value: number }) {
  return (
    <span className="star-rating-filter-label">
      <span>{value}</span>
      <IconComponent name={IconName.StarFilled} />
      <span>+</span>
    </span>
  );
}
