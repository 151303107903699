import {
  ContactEvents,
  PassengerEvents,
  CartQuoteEvents,
  CartFulfillEvents,
} from "@hopper-b2b/checkout";
import { ProductType } from "@hopper-b2b/types";
import { NubankPaymentEvents } from "../../checkout/states/payments";

export enum Event {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",

  //  PassengerInformationEvents
  SELECT_PASSENGER = "SELECT_PASSENGER",
  ADD_PASSENGER = "ADD_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  DELETE_PASSENGER = "DELETE_PASSENGER",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  CLEAR_CURRENT_INFANT_ID = "CLEAR_CURRENT_INFANT_ID",
  ON_INFANT_MODAL_CONTINUE = "ON_INFANT_MODAL_CONTINUE",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",

  // ContactInformationEvents
  SET_CONTACT_INFO = "SET_CONTACT_INFO",
  CLEAR_CONTACT_ERROR = "CLEAR_CONTACT_ERROR",

  // ScheduleQuote
  CLEAR_SCHEDULE_QUOTE_ERROR = "CLEAR_SCHEDULE_QUOTE_ERROR",

  // PaymentInformation
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",
  CLEAR_PAYMENT_ERROR = "CLEAR_PAYMENT_ERROR",

  // Review
  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",
  EDIT_SEATS_FROM_REVIEW = "EDIT_SEATS_FROM_REVIEW",
  ACKNOWLEDGE_PRICE_CHANGE = "ACKNOWLEDGE_PRICE_CHANGE",

  // ScheduleFulfill
  CLEAR_SCHEDULE_FULFILL_ERROR = "CLEAR_SCHEDULE_FULFILL_ERROR",

  // Confirmation
  GO_HOME = "GO_HOME",

  // Wallet offers
  REMOVE_SELECTED_OFFER = "REMOVE_SELECTED_OFFER",
  SET_SELECTED_OFFER = "SET_SELECTED_OFFER",

  // Transition to state from query params
  GO_TO_CART_QUOTE = "GO_TO_CART_QUOTE",
  GO_TO_CART_FULFILL = "GO_TO_CART_FULFILL",
  GO_TO_PAYMENT = "GO_TO_PAYMENT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_BOOKING_CONFIRMATION = "GO_TO_BOOKING_CONFIRMATION",
  // Transition to payment state from query params
  GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT = "GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT",
  GO_TO_PAYMENT_SELECT_CREDIT = "GO_TO_PAYMENT_SELECT_CREDIT",
  GO_TO_PAYMENT_SELECT_REVIEW = "GO_TO_PAYMENT_SELECT_REVIEW",
}

export interface GoToPassengerSelect {
  type: Event.GO_TO_PASSENGER_SELECT;
}

export interface GoToPayment {
  type: Event.GO_TO_PAYMENT;
}

export interface GoToContactInformation {
  type: Event.GO_TO_CONTACT_INFORMATION;
  productType: ProductType;
}

export interface GoToReview {
  type: Event.GO_TO_REVIEW;
}
export interface GoToCartQuote {
  type: Event.GO_TO_CART_QUOTE;
}
export interface GoToCartFulfill {
  type: Event.GO_TO_CART_FULFILL;
}
export interface GoToBookingConfirmation {
  type: Event.GO_TO_BOOKING_CONFIRMATION;
}

export interface NextEvent {
  type: Event.NEXT;
  productType: ProductType;
}
export interface PreviousEvent {
  type: Event.PREVIOUS;
}
export interface ClearPaymentError {
  type: Event.CLEAR_PAYMENT_ERROR;
}

export interface GoToPaymentSelectCreditOrDebit {
  type: Event.GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT;
}

export interface GoToPaymentSelectCredit {
  type: Event.GO_TO_PAYMENT_SELECT_CREDIT;
}

export interface GoToPaymentSelectReview {
  type: Event.GO_TO_PAYMENT_SELECT_REVIEW;
}

export interface ClearScheduleFulfillError {
  type: Event.CLEAR_SCHEDULE_FULFILL_ERROR;
}

export interface AcknowledgePriceChange {
  type: Event.ACKNOWLEDGE_PRICE_CHANGE;
}

export interface RemoveSelectedOffer {
  type: Event.REMOVE_SELECTED_OFFER;
  offerId: string;
}

export interface SetSelectedOffer {
  type: Event.SET_SELECTED_OFFER;
  offerId: string;
}

export type TEvent =
  | NextEvent
  | PreviousEvent
  | GoToPassengerSelect
  | GoToContactInformation
  | GoToPayment
  | GoToReview
  | GoToCartFulfill
  | GoToCartQuote
  | GoToBookingConfirmation
  | ClearScheduleFulfillError
  | AcknowledgePriceChange
  // Wallet events
  | RemoveSelectedOffer
  | SetSelectedOffer
  // Contact Information Events
  | ContactEvents
  // Passenger Information Events
  | PassengerEvents
  // Cart Quote Events
  | CartQuoteEvents
  // Cart Fulfill Events
  | CartFulfillEvents
  | NubankPaymentEvents
  | GoToPaymentSelectCreditOrDebit
  | GoToPaymentSelectCredit
  | GoToPaymentSelectReview;
