import { useCallback } from "react";
import { RadioDropdown } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

interface Props {
  value: number | number[];
  onChange: (nextValue: number | number[]) => void;
  ariaLabel: string;
  dropdownLabel: string;
}

export function UserRatingFilter({
  value,
  onChange,
  ariaLabel,
  dropdownLabel,
}: Props) {
  const { t } = useI18nContext();

  const options = [
    {
      value: 0,
      label: t("any"),
    },
    {
      value: 0.7,
      label: t("veryGood"),
    },
    {
      value: 0.9,
      label: t("excellent"),
    },
  ];

  const handleSelectOption = useCallback(
    (value) => {
      onChange(value);
    },
    [options, onChange]
  );

  return (
    <RadioDropdown
      className="user-rating-filter"
      options={options.map((option) => {
        return {
          value: option.value.toString(),
          label: option.label,
        };
      })}
      setOption={handleSelectOption}
      selected={value}
      dropdownLabel={dropdownLabel}
      groupAriaLabel={ariaLabel}
      buttonAriaLabel={ariaLabel}
      showDropdownContentOnly
    />
  );
}
