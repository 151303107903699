import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@hopper-b2b/ui";
import {
  useDeviceTypes,
  useEnableCfar,
  useEnableScheduleChange,
} from "@hopper-b2b/utilities";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CfarOffers } from "../../../cfar/CfarOffers";
import { getSelectedCfarOffer, isCfarOffersValid } from "../../../cfar/reducer";
import { ScheduleChangeOffer } from "../../../disruption/components";
import {
  getScheduleChangeOffer,
  getSelectedScheduleChangeOffer,
} from "../../../disruption/reducer";
import { CheckoutBreakdown } from "./CheckoutBreakdown";
import styles from "./FintechCustomizePage.module.scss";

export interface IFintechCustomizePage {
  goToNextStep?: () => void;
}

export const FintechCustomizePage = ({ goToNextStep }) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const showFlightCfar = useEnableCfar();
  const showFlightScheduleChange = useEnableScheduleChange();

  const isCfarValid = useSelector(isCfarOffersValid(true));
  const scheduleChangeOffer = useSelector(getScheduleChangeOffer);

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const selectedScheduleChangeOffer = useSelector(
    getSelectedScheduleChangeOffer
  );

  const cfarSelectionRequired = !!isCfarValid;

  const disableContinueButton =
    (selectedCfarOffer === undefined && cfarSelectionRequired) ||
    (selectedScheduleChangeOffer === undefined && showFlightScheduleChange);

  const isScheduleChangeVisible =
    showFlightScheduleChange && scheduleChangeOffer;
  const isCfarVisible = showFlightCfar && isCfarValid;

  if (!isScheduleChangeVisible && !isCfarVisible) {
    goToNextStep?.(true);
  }
  return (
    <Box className={styles.fintechCustomizePage}>
      <Box mb={3}>
        <Typography variant="h4">{t("customizeYourTrip")}</Typography>
      </Box>

      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item md={9} sm={9} xs={12}>
          {isScheduleChangeVisible && (
            <Box mb={3}>
              <ScheduleChangeOffer hideContinueButton={!matchesMobile} />
            </Box>
          )}
          {isCfarVisible && (
            <Box>
              <CfarOffers hideContinueButton={!matchesMobile} />
            </Box>
          )}
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <Box className={styles.checkoutContainer}>
            <CheckoutBreakdown />
            <Box mt={4} className={styles.ctaContainer}>
              <ActionButton
                fullWidth
                disabled={disableContinueButton}
                onClick={goToNextStep}
                message={t("continue")}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
