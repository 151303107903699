import { useI18nContext } from "@hopper-b2b/i18n";
import { FlightAlertKey, TagInfo } from "@hopper-b2b/types";
import { Slot, StatusTag } from "@hopper-b2b/ui";
import { PriceDropProtection as IPriceDropProtection } from "@hopper-b2b/types";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { PriceDropProtectionLink } from "../../../../../TripsList/components/WatchList/PriceDropProtectionLink";
import { ItineraryDate } from "../../../ItineraryDate/component";
import "./styles.scss";
import { useNavigate } from "react-router-dom-v5-compat";
import { flightAlertLinkGenerator } from "../../utils/flightAlertLinkGenerator";

export interface FlightWatchCardProps {
  isMobile: boolean;
  className?: string;
  alertKey?: FlightAlertKey;
  confirmationLabel?: string;
  confirmationCode?: string;
  confirmationCodeClassName?: string;
  startLabel?: string;
  startDate: string;
  endDate: string;
  title?: string;
  titleTag?: TagInfo;
  subtitleTag?: TagInfo;
  subtitle?: string;
  banner?: JSX.Element;
  actions?: JSX.Element;
  shouldShowCtaIcon?: boolean;
  origin?: string;
  destination?: string;
  roundTrip?: boolean;
  priceDropProtection: IPriceDropProtection;
}

export const FlightWatchCard = ({
  isMobile,
  className,
  alertKey,
  title,
  titleTag,
  subtitleTag,
  startDate,
  endDate,
  confirmationCode,
  actions,
  roundTrip,
  priceDropProtection,
}: FlightWatchCardProps) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();

  const tripType = roundTrip ? t("roundTripFlight") : t("oneWayFlight");

  const onBookNowStatusClick = () => {
    if (!alertKey) {
      return;
    }
    navigate(flightAlertLinkGenerator(alertKey));
  };

  return (
    <Box
      className={clsx(
        className,
        { mobile: isMobile },
        "flight-watch apac",
        "apac"
      )}
    >
      <Box className="my-trips-desktop-card flight-card desktop">
        <Box className="mytrips-flight-icon-container">
          <Slot id="mytrips-flight-icon" />
          {!!titleTag && (
            <div onClick={onBookNowStatusClick}>
              <StatusTag className="title-status-tag" tagInfo={titleTag} />
            </div>
          )}
        </Box>
        <Box className="flight-card-icon-title">
          <Typography className="summary-label" variant="h2">
            {title}
          </Typography>
        </Box>
        <Box
          id={confirmationCode}
          key={confirmationCode}
          className={clsx("flight-trip-container", {
            mobile: isMobile,
          })}
        >
          <Box
            className={clsx("flight-trip-details-content", {
              mobile: isMobile,
            })}
          >
            <Box className="flight-trip-dates-details">
              <ItineraryDate
                label={t("departingLabel")}
                date={startDate}
                hideTime
              />
              {endDate ? (
                <ItineraryDate
                  label={t("returningLabel")}
                  date={endDate}
                  hideTime
                />
              ) : null}
            </Box>
            <Box className="flight-trip-metadata-details">
              <Box>
                <Typography className="label">{t("tripTypeLabel")}</Typography>
                <Typography className="value">{tripType}</Typography>
              </Box>
              <Box>
                <Typography className="label">
                  {t("watch.flightWatchStopsLabel")}
                </Typography>
                <Typography className="value">{subtitleTag?.label}</Typography>
              </Box>
            </Box>
            <PriceDropProtectionLink
              priceDropProtection={priceDropProtection}
              isMobile={true}
            />
          </Box>
          {actions ? (
            <>
              <Divider className="flight-trip-details-divider" />
              {actions}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
