import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import styles from "./Features.module.scss";

interface Props {
  isFreeCancel: boolean;
  className?: string;
}

export function Features({ isFreeCancel, className }: Props) {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  return (
    <div className={clsx(styles.Feature, className)}>
      {isFreeCancel && (
        <>
          {icons.greenShieldCheck ? (
            <img src={icons.greenShieldCheck} alt="" />
          ) : null}
          <Typography>{t("freeCancellation")}</Typography>
        </>
      )}
    </div>
  );
}
