import { Person } from "@b2bportal/air-booking-api";
import { CipherText } from "@b2bportal/purchase-api";
import { Payment } from "@hopper-b2b/types";
import {
  ContactEvents,
  FlightPassengerEventsV2,
  CartQuoteEvents,
  SeatEvents,
  VipSupportEvents,
  CartFulfillEvents,
} from "@hopper-b2b/checkout";
import { NubankPaymentEvents } from "../../checkout/states/payments";

export enum Event {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",
  RESET_CONTEXT = "RESET_CONTEXT",

  // ContactInformationEvents
  SET_CONTACT_INFO = "SET_CONTACT_INFO",
  CLEAR_CONTACT_ERROR = "CLEAR_CONTACT_ERROR",

  // PassportEvents
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  UPDATE_PASSPORT_AND_CONTINUE = "UPDATE_PASSPORT_AND_CONTINUE",
  VALIDATE_PASSENGERS = "VALIDATE_PASSENGERS",
  CLEAR_PASSPORT_ERROR = "CLEAR_PASSPORT_ERROR",

  // ScheduleQuote
  CLEAR_SCHEDULE_QUOTE_ERROR = "CLEAR_SCHEDULE_QUOTE_ERROR",

  // PaymentInformation
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",

  // UBER Payment
  SET_PAYMENT_DATA_AND_GO_NEXT = "SET_PAYMENT_DATA_AND_GO_NEXT",

  // Review
  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",
  EDIT_SEATS_FROM_REVIEW = "EDIT_SEATS_FROM_REVIEW",

  // ScheduleFulfill
  CLEAR_SCHEDULE_FULFILL_ERROR = "CLEAR_SCHEDULE_FULFILL_ERROR",

  // Confirmation
  GO_HOME = "GO_HOME",

  // Transition to state from query params
  GO_TO_CART_QUOTE = "GO_TO_CART_QUOTE",
  GO_TO_SEAT_SELECTION = "GO_TO_SEAT_SELECTION",
  GO_TO_PAYMENT = "GO_TO_PAYMENT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_BOOKING_CONFIRMATION = "GO_TO_BOOKING_CONFIRMATION",
  GO_TO_PRICE_DROP = "GO_TO_PRICE_DROP",
  // Transition to payment state from query params
  GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT = "GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT",
  GO_TO_PAYMENT_SELECT_CREDIT = "GO_TO_PAYMENT_SELECT_CREDIT",
  GO_TO_PAYMENT_SELECT_REVIEW = "GO_TO_PAYMENT_SELECT_REVIEW",
}

export interface NextEvent {
  type: Event.NEXT;
}
export interface PreviousEvent {
  type: Event.PREVIOUS;
}

export interface GoIdleEvent {
  type: Event.GO_IDLE;
}
export interface GoIdleEvent {
  type: Event.GO_IDLE;
}

export interface OpenPaymentFormEvent {
  type: Event.OPEN_PAYMENT_FORM;
}

export interface ClosePaymentFormEvent {
  type: Event.CLOSE_PAYMENT_FORM;
}

export interface SetAppliedBalanceEvent {
  type: Event.SET_APPLIED_BALANCE;
  appliedBalance: number;
}

export interface VerifyPaymentMethodEvent {
  type: Event.VERIFY_PAYMENT_METHOD;
  token: string;
}

export interface DeletePaymentMethodEvent {
  type: Event.DELETE_PAYMENT_METHOD;
  paymentMethod: {
    paymentId: string;
  };
}

export interface SetPaymentMethodIdEvent {
  type: Event.SET_PAYMENT_METHOD_ID;
  data: {
    selectedPaymentMethodId: number | string;
    paymentMethods: Payment[];
  };
}

export interface SetSelectedPaymentMethodIdEvent {
  type: Event.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethod: {
    paymentId: string | undefined;
  };
}

export interface SetPaymentDataAndGoNext {
  type: Event.SET_PAYMENT_DATA_AND_GO_NEXT;
  paymentQuoteToken: CipherText;
  paymentSessionToken: string;
}

export interface ClearScheduleQuoteError {
  type: Event.CLEAR_SCHEDULE_QUOTE_ERROR;
}

export interface ClearScheduleFulfillError {
  type: Event.CLEAR_SCHEDULE_FULFILL_ERROR;
}

export interface GoToPassengerSelect {
  type: Event.GO_TO_PASSENGER_SELECT;
}

export interface GoToContactInformation {
  type: Event.GO_TO_CONTACT_INFORMATION;
}

export interface GoHomeEvent {
  type: Event.GO_HOME;
}

export interface UpdatePassportAndContinue {
  type: Event.UPDATE_PASSPORT_AND_CONTINUE;
  person: Person;
}

export interface UpdatePassenger {
  type: Event.UPDATE_PASSENGER;
  person: Person;
}

export interface ClearPassportError {
  type: Event.CLEAR_PASSPORT_ERROR;
}

export interface ResetContextEvent {
  type: Event.RESET_CONTEXT;
}

export interface GoToPayment {
  type: Event.GO_TO_PAYMENT;
}

export interface GoToReview {
  type: Event.GO_TO_REVIEW;
}

export interface EditSeatsFromReview {
  type: Event.EDIT_SEATS_FROM_REVIEW;
}

export interface GoToSeatSelection {
  type: Event.GO_TO_SEAT_SELECTION;
}

export interface GoToBookingConfirmation {
  type: Event.GO_TO_BOOKING_CONFIRMATION;
}

export interface GoToCartQuote {
  type: Event.GO_TO_CART_QUOTE;
}

export interface ClearContactError {
  type: Event.CLEAR_CONTACT_ERROR;
}

export interface GoToPriceDrop {
  type: Event.GO_TO_PRICE_DROP;
}

export interface GoToPaymentSelectCreditOrDebit {
  type: Event.GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT;
}

export interface GoToPaymentSelectCredit {
  type: Event.GO_TO_PAYMENT_SELECT_CREDIT;
}

export interface GoToPaymentSelectReview {
  type: Event.GO_TO_PAYMENT_SELECT_REVIEW;
}

export type TEvent =
  | ClearScheduleFulfillError
  | ClearScheduleQuoteError
  | GoHomeEvent
  | GoIdleEvent
  | GoToContactInformation
  | GoToPassengerSelect
  | NextEvent
  | PreviousEvent
  | OpenPaymentFormEvent
  | ClosePaymentFormEvent
  | SetAppliedBalanceEvent
  | VerifyPaymentMethodEvent
  | DeletePaymentMethodEvent
  | SetPaymentMethodIdEvent
  | SetSelectedPaymentMethodIdEvent
  | UpdatePassenger
  | UpdatePassportAndContinue
  | ClearPassportError
  | ResetContextEvent
  | ClearContactError
  | SetPaymentDataAndGoNext
  | GoToPayment
  | GoToReview
  | EditSeatsFromReview
  | GoToSeatSelection
  | GoToBookingConfirmation
  | GoToCartQuote
  | GoToPriceDrop

  // Contact Information Events
  | ContactEvents
  // Passenger Information Events
  | FlightPassengerEventsV2
  // Cart Quote Events
  | CartQuoteEvents
  // Cart Fulfill Events
  | CartFulfillEvents
  // Seat Selection Events
  | SeatEvents
  // Vip Support Events
  | VipSupportEvents
  // Nubank Payment Events
  | NubankPaymentEvents
  | GoToPaymentSelectCreditOrDebit
  | GoToPaymentSelectCredit
  | GoToPaymentSelectReview;
