import { cpfRegex } from "./regex-constants";
import { CountryCode } from "@hopper-b2b/types";

export const getNationalIdInfo = (nationality: CountryCode) => {
  switch (nationality) {
    case "BR":
      return {
        length: { min: 14, max: 14, formatted: 11 },
        // Format: 000.000.000-00
        format: (id: string) =>
          id.slice(0, 3) +
          "." +
          id.slice(3, 6) +
          "." +
          id.slice(6, 9) +
          "-" +
          id.slice(9),
        formatPayload: (id: string) => id.replace(/[.-]/g, ""),
        regex: cpfRegex,
        onChange: (updatedValue, addCharacter) => {
          if (updatedValue.length <= 14) {
            if (addCharacter) {
              // Adding characters
              if (updatedValue.length === 3 || updatedValue.length === 7) {
                updatedValue = updatedValue + ".";
              } else if (updatedValue.length === 11) {
                updatedValue = updatedValue + "-";
              }
            } else {
              // Deleting characters
              if (updatedValue.length === 3 || updatedValue.length === 7) {
                updatedValue = updatedValue.slice(0, -1);
              } else if (updatedValue.length === 11) {
                updatedValue = updatedValue.slice(0, -1);
              }
            }
            return updatedValue;
          }
          return updatedValue.slice(0, -1);
        },
        label: "CPF",
      };
    default:
      return null;
  }
};
