import * as H from "history";
import queryStringParser from "query-string";
import { AnyEventObject, Sender, DefaultContext } from "xstate";
import {
  IdEnum,
  LocationResponse,
  Suggestion,
} from "@b2bportal/air-shopping-api";

import { TripCategory } from "@hopper-b2b/types";
import { FallbackType, ResumeStep, ResumeStepRequest } from "./types";
import { CheckoutQueryParams } from "../../../index";

export const runRequests = async (
  requests: Array<ResumeStepRequest>,
  runFallbackFromStep: () => void,
  history: H.History,
  send: Sender<AnyEventObject>
): Promise<{ status: "complete" | "error"; error?: unknown }> => {
  for (const request of requests) {
    try {
      const response = await request.api(request.requestPayload?.(history));
      if (request.responseEvent) {
        const event = request.responseEvent(response, history);
        send(event);
      }
    } catch (error) {
      if (request.errorEvent) {
        const errorEvent = request.errorEvent(error, history);
        send(errorEvent);
        return { status: "error", error: error }; //break out of for loop and return error
      }
      runFallbackFromStep();
    }
  }
  return { status: "complete" };
};

const getFallbackActionFromStep =
  (fallback: FallbackType, history: H.History, send: Sender<AnyEventObject>) =>
  () => {
    if (typeof fallback.url === "string") {
      history.replace(fallback.url);
    } else if (typeof fallback.url === "function") {
      history.replace(fallback.url(history));
    } else {
      send(fallback.event);
    }
  };

export const runResumeSteps = async ({
  steps,
  history,
  context,
  send,
}: {
  steps: Array<ResumeStep>;
  history: H.History;
  context: DefaultContext;
  send: Sender<AnyEventObject>;
}) => {
  for (const step of steps) {
    const runFallbackFromStep = getFallbackActionFromStep(
      step.fallback,
      history,
      send
    );

    // 1.a. Should we skip the step?
    if (step.skipStep?.(context)) {
      // Skip this step and move on to the next step
      continue;
    }
    // 1.b. Do we have the data needed from the url params
    // - the "Promise.resolve" is used to evaluate a boolean or a Promise<boolean> as both will just be evaluated into a boolean
    const isStepValidated = await Promise.resolve(step.validator(history));
    if (isStepValidated) {
      try {
        // 2. If there are requests for the step, fire them all before continuing
        if (step.requests) {
          const { status, error } = await runRequests(
            step.requests,
            runFallbackFromStep,
            history,
            send
          );
          if (status === "error") {
            throw error;
          }
        }
        // 3. If the previous requests were run, fire the event for this step to update the context with data from the url params
        // - the "Promise.resolve" is used to evaluate a EventObject or a Promise<EventObject> as both will just be evaluated into a EventObject
        if (step.getStepEvent) {
          const stepEvent = await Promise.resolve(step.getStepEvent(history));
          send(stepEvent);
        }
      } catch (error) {
        console.log(error);
        break; // Break out of steps for loop if we throw an error in the requests
      }
    } else {
      // If the url doesn't have the necessary information to continue with the step, fallback url or event
      runFallbackFromStep();
      return;
    }
  }
};

export const getFlightSelectedLocation = (
  { categories }: LocationResponse,
  label: string
): Suggestion | undefined => {
  for (const category of categories) {
    for (const result of category.results) {
      if (
        result.id.Id === IdEnum.Flight &&
        result.id.code.code.toLocaleLowerCase() === label.toLocaleLowerCase()
      ) {
        return result;
      }
    }
  }
};

export const parseLodgingQueryString = (history: H.History) => {
  const queryString = history?.location?.search || "";

  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  const sharedQueryParams = getSharedQueryParams(parsedQueryStringPrimitive);
  return {
    ...sharedQueryParams,
    fromDate: parsedQueryStringPrimitive.fromDate as string,
    untilDate: parsedQueryStringPrimitive.untilDate as string,
    adultsCount: parsedQueryStringPrimitive.adultsCount as string,
    roomsCount: parsedQueryStringPrimitive.roomsCount as string,
    availabilityLink: parsedQueryStringPrimitive.availabilityLink as string,
    lodgingCity: parsedQueryStringPrimitive.lodgingCity as string,
    roomMediaUrl: parsedQueryStringPrimitive.roomMediaUrl as string,
    roomMaxAdults: parsedQueryStringPrimitive.roomMaxAdults as string,
  };
};

export const parseFlightsQueryString = (history: H.History) => {
  const queryString = history?.location?.search || "";

  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  const sharedQueryParams = getSharedQueryParams(parsedQueryStringPrimitive);

  return {
    ...sharedQueryParams,
    tripCategory: parsedQueryStringPrimitive.tripCategory as TripCategory,
    outgoingFareId: parsedQueryStringPrimitive.outgoingFareId as string,
    returnFareId: parsedQueryStringPrimitive.returnFareId as string,
    tripId: parsedQueryStringPrimitive.tripId as string,
    origin: parsedQueryStringPrimitive.origin as string,
    destination: parsedQueryStringPrimitive.destination as string,
    departureDate: parsedQueryStringPrimitive.departureDate as string,
    returnDate: parsedQueryStringPrimitive.returnDate as string,
    selectedLapInfantIds: parseUrlArray(
      parsedQueryStringPrimitive.selectedLapInfantIds as string
    ).filter((e) => !!e),
  };
};

export const getSharedQueryParams = (
  parsedString: queryStringParser.ParsedQuery<string>
) => {
  return {
    email: parsedString.email as string,
    phone: parsedString.phone as string,
    selectedPassengerIds: parseUrlArray(
      parsedString.selectedPassengerIds as string
    ).filter((e) => !!e),
    cartToken: parsedString.cartToken as string,
    priceDropCandidateId: parsedString.priceDropCandidateId as string,
    selectedWalletOffer: parsedString.selectedWalletOffer as string,
  };
};

export const parseUrlArray = (arrayString: string) => {
  return arrayString?.split(",") || [];
};

export const parseCheckoutStateQueryString = (history: H.History) => {
  const queryString = history?.location?.search || "";
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    checkoutState: parsedQueryStringPrimitive[
      CheckoutQueryParams.checkoutState
    ] as string,
  };
};
