import {
  CartContext,
  CommonParentContext,
  ParentState,
  ReviewContext,
  LodgingContext,
  WalletContext,
} from "@hopper-b2b/checkout";
import { NubankPaymentContext } from "../../checkout/states/payments";

export interface LodgingMachineContext
  extends CommonParentContext,
    CartContext,
    LodgingContext {
  [ParentState.payment]: NubankPaymentContext;
  [ParentState.review]: ReviewContext;
  [ParentState.bookingConfirmation]: unknown;
  [ParentState.wallet]: WalletContext;
}

export enum CheckoutQueryParams {
  selectedPaymentMethodId = "selectedPaymentMethodId",
  selectedPassengerIds = "selectedPassengerIds",
  checkoutState = "checkoutState",
  checkoutChildState = "checkoutChildState",
  contactCountry = "contactCountry",
  contactPhone = "contactPhone",
  contactEmail = "contactEmail",
}
