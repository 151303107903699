import { useCallback, useState } from "react";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionLink,
  ButtonWrap,
  IconComponent,
  IconName,
  MobileFloatingSection,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { ReactComponent as Arrow } from "../../assets/client/arrow.svg";
import styles from "./styles.module.scss";

interface ITooltipProps {
  title: string;
  description: string;
}

const Tooltip = ({ title, description }: ITooltipProps) => {
  const { t } = useI18nContext();
  const [openHalfSheet, setOpenHalfSheet] = useState(false);

  const handleOpenHalfSheet = useCallback(
    (e) => {
      // Prevent form submit for ButtonWrap component
      e.preventDefault();
      setOpenHalfSheet(true);
    },
    [setOpenHalfSheet]
  );
  const handleCloseHalfSheet = useCallback(
    () => setOpenHalfSheet(false),
    [setOpenHalfSheet]
  );

  return (
    <>
      <ButtonWrap onClick={handleOpenHalfSheet} className={styles.tooltip}>
        <span className="tooltip-label">{title}</span>
        <Arrow />
      </ButtonWrap>

      <MobilePopoverCard
        open={openHalfSheet}
        className={styles.tooltipHalfsheet}
        contentClassName="tooltip-modal-content"
        onClose={handleCloseHalfSheet}
        centered={true}
        topRightButton={
          <ActionLink
            onClick={handleCloseHalfSheet}
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
          />
        }
        bottomButton={
          <MobileFloatingSection
            className="tooltip-continue-button"
            primaryButton={{
              children: t("close.button"),
              onClick: handleCloseHalfSheet,
            }}
          />
        }
      >
        {
          <>
            <h2 className="tooltip-modal-title">{title}</h2>
            <div className="tooltip-modal-description">{description}</div>
          </>
        }
      </MobilePopoverCard>
    </>
  );
};

export default Tooltip;
