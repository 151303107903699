import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMissedConnectionOffer } from "../../../../disruption/actions/actions";
import {
  getMissedConnectionOffer,
  getSelectedMissedConnectionOffer,
  isMissedConnectionValid,
} from "../../../../disruption/reducer";
import { SingleOptionFintechCard } from "../SingleOptionFintechCard";
import {
  useEnableDisruptionHackerFare,
  useEnableMissedConnection,
} from "@hopper-b2b/utilities";
import { FintechOptionCardProps } from "../types";
import { trackEvent } from "@hopper-b2b/api";
import {
  DisruptionProductType,
  DisruptionTrackingEvents,
} from "@hopper-b2b/types";
import { isReturnFareHackerFareSelector } from "../../../reducer";

export type MissedConnectionFintechOptionCard = {
  onChange: () => void;
} & FintechOptionCardProps;

export const MissedConnectionFintechOptionCard = ({ onChange, disabled }) => {
  const { t, formatCurrency } = useI18nContext();

  const dispatch = useDispatch();

  const enableMissedConnection = useEnableMissedConnection();

  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const hideFlightDisruptionHackerFare = useEnableDisruptionHackerFare();
  const isHackerFareEnabledWithDisruption = isReturnFareHackerFare
    ? hideFlightDisruptionHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  const hasValidMissedConnectionOffers = useSelector(
    isMissedConnectionValid(false)
  );

  const showMissedConnectionFintechOptionCard =
    enableMissedConnection &&
    hasValidMissedConnectionOffers &&
    isHackerFareEnabledWithDisruption;

  const missedConnectionOffer = useSelector(getMissedConnectionOffer);

  const selectedMissedConnectionOffer = useSelector(
    getSelectedMissedConnectionOffer
  );

  const missedConnectionOfferQuote = useMemo(
    () => missedConnectionOffer?.quotes?.[0],
    [missedConnectionOffer?.quotes]
  );

  const pricePerPax = useMemo(
    () =>
      formatCurrency(missedConnectionOfferQuote?.premiumAmount?.fiat?.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [formatCurrency, missedConnectionOfferQuote]
  );

  const onSelect = useCallback(
    (showMissedConnection: boolean) => {
      if (showMissedConnection) {
        trackEvent({
          eventName: DisruptionTrackingEvents.ADDED_MISSED_CONNECTION,
          properties: missedConnectionOfferQuote?.trackingProperties,
        });
      }
      onChange();
      dispatch(
        setSelectedMissedConnectionOffer(
          showMissedConnection ? missedConnectionOffer : undefined
        )
      );
    },
    [missedConnectionOffer, missedConnectionOfferQuote, dispatch, onChange]
  );

  const onShow = useCallback((show: boolean) => {
    if (show) {
      trackEvent({
        eventName: DisruptionTrackingEvents.VIEWED_MISSED_CONNECTION,
        properties: undefined,
      });
    }
  }, []);

  return showMissedConnectionFintechOptionCard ? (
    <SingleOptionFintechCard
      product={DisruptionProductType.MissedConnection}
      title={t?.("fintechSelection.missedConnectionTitle")}
      description={t?.("fintechSelection.missedConnectionDescription")}
      checked={!!selectedMissedConnectionOffer}
      setChecked={onSelect}
      iconName="missedConnectionFintech"
      pricePerPax={pricePerPax}
      icons={["refresh", "ticket"]}
      disabled={disabled}
      onShow={onShow}
    />
  ) : null;
};
