import * as React from "react";
import { FocusEvent } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot, StyledMenuItem } from "@hopper-b2b/ui";
import { Assistance } from "@b2bportal/air-booking-api";
import { getViewportDifference, useDeviceTypes } from "@hopper-b2b/utilities";
import "./styles.scss";

interface IAdditionalInformationSectionProps {
  className?: string;
  additionalInfoTitle: string;
  assistance: Assistance[];
  setAssistance: React.Dispatch<React.SetStateAction<Assistance[]>>;
  redressNumber?: string;
  setRedressNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  knownTravelerNumber?: string;
  setKnownTravelerNumber: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

interface IOption {
  label: string | JSX.Element;
  value: any;
}

export const AdditionalInformationSection = ({
  className,
  additionalInfoTitle,
  assistance,
  setAssistance,
  redressNumber,
  setRedressNumber,
  knownTravelerNumber,
  setKnownTravelerNumber,
}: IAdditionalInformationSectionProps) => {
  const { matchesMobile } = useDeviceTypes();

  const { t } = useI18nContext();

  const assistanceOptions: IOption[] = [
    { label: t("assistanceOptions.blind"), value: Assistance.BLND },
    { label: t("assistanceOptions.deaf"), value: Assistance.DEAF },
    { label: t("assistanceOptions.stretcher"), value: Assistance.STCR },
    {
      label: t("assistanceOptions.wheelchairStairs"),
      value: Assistance.WCHR,
    },
    { label: t("assistanceOptions.wheelchairSeat"), value: Assistance.WCHS },
    { label: t("assistanceOptions.wheelchairCarried"), value: Assistance.WCHC },
    {
      label: t("assistanceOptions.wheelchairDryBattery"),
      value: Assistance.WCBD,
    },
    {
      label: t("assistanceOptions.wheelchairWetBattery"),
      value: Assistance.WCBW,
    },
    {
      label: t("assistanceOptions.wheelchairLithiumBattery"),
      value: Assistance.WCLB,
    },
    { label: t("assistanceOptions.wheelchairManual"), value: Assistance.WCMP },
    { label: t("assistanceOptions.wheelchairOnBoard"), value: Assistance.WCOB },
  ];

  const getAssistanceOptionLabel = (assistance: Assistance) => {
    const option = assistanceOptions.find(
      (option) => option.value === assistance
    );
    return option ? option.label : "Unrecognized assistance";
  };

  const handleSelectCheckbox = React.useCallback(
    (value) => {
      let updatedAssistanceArray = [...assistance];
      if (assistance.find((option) => option === value)) {
        updatedAssistanceArray = updatedAssistanceArray.filter(
          (option) => option !== value
        );
      } else {
        updatedAssistanceArray.push(value);
      }
      setAssistance(updatedAssistanceArray);
    },
    [assistance, setAssistance]
  );

  const onFocus = (id: string) => async (event) => {
    if (matchesMobile) {
      const travelerInfoFormPopupElement = document
        ?.getElementsByClassName("traveler-select-workflow-info-form-popup")[0]
        ?.getElementsByClassName(
          "mobile-popover-card-container"
        )[0].parentElement;
      const inputTop =
        document.getElementById(id)?.getBoundingClientRect().top || 0;

      if (travelerInfoFormPopupElement) {
        const difference = await getViewportDifference();
        const topWithoutDiff = inputTop - 48;
        const topWithDiff = inputTop - 48 - difference.height;
        // TODO: figure out the values here
        if (difference.height > 0 && inputTop > 100) {
          travelerInfoFormPopupElement.scrollBy({
            top: topWithDiff < 0 ? topWithoutDiff : topWithDiff,
            behavior: "smooth",
          });
        } else {
          event.target.parentElement.parentElement.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }
    }
  };

  return (
    <Box className={clsx("additional-info-container", className)}>
      <Box className="traveler-info-description">
        <Typography variant="subtitle1">{additionalInfoTitle}</Typography>
      </Box>
      <form className="traveler-info-form">
        <Slot
          id="passenger-required-assistance-select"
          assistance={assistance}
          assistanceOptions={assistanceOptions}
          setAssistance={(options: Assistance[]) => setAssistance(options)}
          getLabel={getAssistanceOptionLabel}
          optionMenuItems={getAssistanceOptionMenuItems(
            assistanceOptions,
            assistance,
            handleSelectCheckbox
          )}
          selected={assistance
            .map((assistance) => getAssistanceOptionLabel(assistance))
            .join(", ")}
          component={
            <FormControl className={clsx("traveler-info-field", "assistance")}>
              <InputLabel htmlFor="assistance-input">
                {t("requiredAssistance")}
              </InputLabel>
              <Select
                multiple
                value={assistance}
                onChange={(event) =>
                  setAssistance(event.target.value as Assistance[])
                }
                renderValue={(selected) => (
                  <Box className="assistance-text-container">
                    {(selected as Assistance[])
                      .map((assistance) => getAssistanceOptionLabel(assistance))
                      .join(", ")}
                  </Box>
                )}
                inputProps={{
                  id: "assistance-input",
                }}
              >
                {getAssistanceOptionMenuItems(assistanceOptions, assistance)}
              </Select>
            </FormControl>
          }
        />
        <Slot
          id="passenger-required-assistance-redress-number"
          component={
            <TextField
              label={t("redressNumber")}
              className={clsx("traveler-info-field", "redress-number")}
              value={redressNumber}
              onChange={(event) => setRedressNumber(event.target.value)}
              onFocus={onFocus("redress-number-input")}
              id="redress-number-input"
              InputProps={{
                endAdornment: (
                  <Slot
                    id="passenger-input-field-learn-more"
                    category="RedressNumber"
                  />
                ),
              }}
            />
          }
        />
        <Slot
          id="passenger-required-assistance-traveler-number"
          component={
            <TextField
              label={t("knownTravelerNumber")}
              className={clsx("traveler-info-field", "known-traveler-number")}
              value={knownTravelerNumber}
              onChange={(event) => {
                const rgx = new RegExp("[^a-zA-Z0-9]", "g");
                setKnownTravelerNumber(event.target.value.replace(rgx, ""));
              }}
              onFocus={onFocus("known-traveler-number")}
              id="known-traveler-number"
              InputProps={{
                endAdornment: (
                  <Slot
                    id="passenger-input-field-learn-more"
                    category="KnownTravelerNumber"
                  />
                ),
              }}
            />
          }
        />
      </form>
    </Box>
  );
};

const getAssistanceOptionMenuItems = (
  options: IOption[],
  assistanceList: Assistance[],
  onChange?: (e: React.ChangeEvent) => void
) => {
  return options.map(({ label, value }, index) => (
    <StyledMenuItem className="assistance-menu-item" value={value} key={index}>
      <Checkbox
        className={clsx("assistance-checkbox", {
          checked: assistanceList.indexOf(value) !== -1,
        })}
        checked={assistanceList.indexOf(value) !== -1}
        onChange={() => onChange?.(value)}
      />
      <ListItemText>{label}</ListItemText>
    </StyledMenuItem>
  ));
};
