import { useEffect, useState, useCallback, useMemo } from "react";
import { Box } from "@material-ui/core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import {
  InputButton,
  MobilePopoverCard,
  IconName,
  Icon,
  IconComponent,
  ActionButton,
} from "@hopper-b2b/ui";
import { IPassengerCounts, ITripTerminus } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useHistory } from "react-router";

import { PassengerCountPicker } from "../../../FlightSearchControl/components/PassengerCountPicker";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "../../../FlightSearchControl/components/TerminusAutocomplete";
import { LocationSearchConnectorProps } from "./container";
import "./styles.scss";

export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: () => void;
}

export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    origin,
    destination,
    numTravelers,
    onComplete,
    readyToContinue,
    renderSearchInput,
    setRenderSearchInput,
    populateSearchUrlParams,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    tripCategory,
  } = props;

  const { t } = useI18nContext();
  const history = useHistory();

  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState<boolean>(false);

  const numTravelerString = useMemo(
    () => t("locationSearchTraveler", { count: numTravelers }),
    [numTravelers, t]
  );

  const onSearch = useCallback(() => {
    if (
      onComplete &&
      origin &&
      destination &&
      origin.id.code.code !== destination.id.code.code
    ) {
      onComplete();
    }
  }, [destination, onComplete, origin]);

  const onOriginSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== destination?.id.code.code) {
        populateSearchUrlParams({ origin: value }, history);
        setRenderSearchInput("");
        onSearch();
      }
    },
    [
      destination?.id.code.code,
      history,
      onSearch,
      populateSearchUrlParams,
      setRenderSearchInput,
    ]
  );

  const onDestinationSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== origin?.id.code.code) {
        populateSearchUrlParams({ destination: value }, history);
        setRenderSearchInput("");
        onSearch();
      }
    },
    [
      history,
      onSearch,
      origin?.id.code.code,
      populateSearchUrlParams,
      setRenderSearchInput,
    ]
  );

  const onPassengerUpdate = useCallback(
    (passengerCounts: IPassengerCounts) => {
      populateSearchUrlParams({ passengers: passengerCounts }, history);
      setOpenPassengerCountPicker(false);
    },
    [history, populateSearchUrlParams]
  );

  const inputButtonOnClick = useCallback(
    (inputType: string) => {
      setRenderSearchInput(inputType);
    },
    [setRenderSearchInput]
  );

  const returnToInputs = () => setRenderSearchInput("");

  // Redirect to 3-input flight search screen instead of origin input screen when user provides origin or destination in URL params
  useEffect(() => {
    if (
      window.location.search.includes("origin") ||
      window.location.search.includes("destination")
    ) {
      setRenderSearchInput("");
    }
  }, [setRenderSearchInput]);

  // Dont make a callback, should only run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const populateParams = () => {
    if (origin && destination) {
      populateSearchUrlParams(
        {
          origin,
          destination,
          tripCategory,
          passengers: {
            adultsCount,
            childrenCount,
            infantsInSeatCount,
            infantsOnLapCount,
          },
        },
        history
      );
    }
  };

  useEffect(() => {
    // Ensures URL params are set to current state on redirect to landing page
    // - only important for page redirect not first page load, as tracks existing state
    populateParams();
  }, []);

  const handleClosePassengerPicker = useCallback(() => {
    setOpenPassengerCountPicker(false);
  }, []);

  return (
    <Box className="mobile-flight-search-location">
      <Box className="mobile-location-pickers">
        {!renderSearchInput ? (
          <>
            <InputButton
              className={clsx("num-traveler-count", "mobile")}
              prefixString={numTravelerString}
              icon={faUser}
              showCloseIcon={false}
              customEndIcon={
                <Icon
                  className={clsx("edit-icon")}
                  name={IconName.B2BEditPencil}
                />
              }
              onClick={() => setOpenPassengerCountPicker(true)}
              value=""
            />
            <InputButton
              className={clsx("origin-auto-complete", "mobile")}
              prefixString={
                origin ? origin.label : t("searchControl.whereFrom")
              }
              customIcon={
                <IconComponent
                  name={IconName.B2BMapPin}
                  className={clsx("origin-icon")}
                />
              }
              onClick={() => inputButtonOnClick("origin")}
              label={origin ? t("searchControl.whereFrom") : ""}
            />
            <InputButton
              className={clsx("destination-auto-complete", "mobile")}
              prefixString={
                destination ? destination.label : t("searchControl.whereTo")
              }
              customIcon={
                <IconComponent
                  name={IconName.B2BAirplaneIcon}
                  className={clsx("destination-icon")}
                />
              }
              onClick={() => inputButtonOnClick("destination")}
              label={destination ? t("searchControl.whereTo") : ""}
            />
          </>
        ) : renderSearchInput === "origin" ? (
          <OriginAutocomplete
            className="origin-auto-complete b2b mobile"
            customIcon={
              <IconComponent
                name={IconName.B2BMapPin}
                className={clsx("origin-icon")}
              />
            }
            label={t("searchControl.whereFrom")}
            value={origin}
            setValue={onOriginSelected}
            loadingText={`${t("searching")}...`}
            noOptionsText={t("noOptions")}
            getOptionSelected={(
              option: ITripTerminus | null,
              value: ITripTerminus | null
            ) => {
              return (
                !!value &&
                !!option &&
                value.id.code.code === option.id.code.code
              );
            }}
            popperClassName="mobile"
            autoFocus={true}
            onClose={returnToInputs}
          />
        ) : (
          <DestinationAutocomplete
            className="destination-auto-complete b2b mobile"
            customIcon={
              <IconComponent
                name={IconName.B2BAirplaneIcon}
                className={clsx("destination-icon")}
              />
            }
            label={t("searchControl.whereTo")}
            loadingText={`${t("searching")}...`}
            noOptionsText={t("noOptions")}
            value={destination}
            setValue={onDestinationSelected}
            getOptionSelected={(
              option: ITripTerminus | null,
              value: ITripTerminus | null
            ) => {
              return (
                !!value &&
                !!option &&
                value.id.code.code === option.id.code.code
              );
            }}
            popperClassName="mobile"
            autoFocus={true}
            onClose={returnToInputs}
          />
        )}
      </Box>
      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        onClose={handleClosePassengerPicker}
        centered={true}
      >
        <PassengerCountPicker
          minimumCount={1}
          onClickApply={onPassengerUpdate}
          className="b2b"
          onClose={handleClosePassengerPicker}
        />
      </MobilePopoverCard>
      {readyToContinue ? (
        <ActionButton
          className="mobile-autocomplete-continue-button"
          message={t("continue")}
          onClick={onSearch}
        />
      ) : null}
    </Box>
  );
};
