import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  EntryButtonEnum,
  FreezePriceClickProperties,
  PreviousFlightEnum,
  PriceFreezeEntryEnum,
  PriceFreezeHistoryState,
  PriceFreezeTrackingEvents,
} from "@hopper-b2b/fintech";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_PRICE_FREEZE_PURCHASE } from "../../../../utils/urlPaths";
import { ClientContext } from "../../../../App";
import { PriceFreezeEntryCardConnectorProps } from "./container";
import "./styles.scss";
import { IconComponent, IconName } from "@hopper-b2b/ui";

export const PriceFreezeEntryCard = (
  props: PriceFreezeEntryCardConnectorProps
) => {
  const history = useHistory();
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const {
    tripId,
    fareId,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  } = props;

  const priceFreezePurchaseQueryString = useMemo(() => {
    const urlParams: Record<string, string> = {
      tripId,
      fareId,
    };

    // Add passenger query params
    if (adultsCount) {
      urlParams.adultsCount = `${adultsCount}`;
    }
    if (childrenCount) {
      urlParams.childrenCount = `${childrenCount}`;
    }
    if (infantsInSeatCount) {
      urlParams.infantsInSeatCount = `${infantsInSeatCount}`;
    }
    if (infantsOnLapCount) {
      urlParams.infantsOnLapCount = `${infantsOnLapCount}`;
    }

    return new URLSearchParams(urlParams).toString();
  }, [
    tripId,
    fareId,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  ]);

  const handlePriceFreezeButtonClick = useCallback(() => {
    trackEvent({
      eventName: PriceFreezeTrackingEvents.FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: PriceFreezeEntryEnum.FORECAST,
      } as FreezePriceClickProperties,
    });
    history.push(
      `${PATH_PRICE_FREEZE_PURCHASE}?${priceFreezePurchaseQueryString}`,
      {
        priceFreezeEntry: PriceFreezeEntryEnum.FORECAST,
        entryButton: EntryButtonEnum.FREEZE_PRICE,
        previousFlight: PreviousFlightEnum.CLP_FLIGHT,
      } as PriceFreezeHistoryState
    );
  }, [history, priceFreezePurchaseQueryString]);

  return (
    <div className="price-freeze-entry-card">
      <div className="price-freeze-entry-card-icon-container">
        {clientContext?.assets?.blackLock ? (
          <img
            src={clientContext?.assets?.blackLock}
            className="price-freeze-entry-card-icon"
            alt=""
          />
        ) : (
          <IconComponent
            className="price-freeze-entry-card-icon"
            name={IconName.Lock}
          />
        )}
      </div>
      <div className="price-freeze-entry-card-description">
        <h6 className="price-freeze-entry-card-title">
          {`${t("priceFreezeEntry.cardTitle")}`}
        </h6>
        <span className="price-freeze-entry-card-subtitle">
          {`${t("priceFreezeEntry.cardSubtitleAlt")}`}
        </span>
      </div>
      <button
        className="price-freeze-entry-card-cta"
        onClick={handlePriceFreezeButtonClick}
      >
        {`${t("priceFreezeEntry.freezePriceShort")}`}
      </button>
    </div>
  );
};
