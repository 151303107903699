import { combineServices } from "@checkout/helpers";
import {
  ChildMachineConfig,
  ParentState,
  PassengerInformationState,
  PassportState,
} from "@checkout/types";
import { Event } from "@checkout/events";

import { ServiceTypes } from "./services";
import { ActionTypes } from "./actions";

import {
  FlightContext,
  FlightServices,
  FlightPassengerActionTypes,
  FlightPassengerServices,
} from "@checkout/states/Products/Flight";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.passport,
  initial: PassportState.idle,
  states: {
    [PassportState.idle]: {
      on: {
        [Event.UPDATE_PASSENGER]: {
          target: PassportState.update,
        },
        [Event.UPDATE_PASSPORT_AND_CONTINUE]: {
          target: PassportState.updateAndContinue,
        },
      },
    },
    [PassportState.update]: {
      invoke: {
        src: ServiceTypes.updatePassengerWithPassportService,
        onDone: {
          target: PassportState.idle,
        },
        onError: {
          actions: ActionTypes.setPassportError,
        },
      },
    },
    [PassportState.updateAndContinue]: {
      invoke: {
        src: ServiceTypes.updatePassengerWithPassportService,
        onDone: {
          target: PassportState.validate,
        },
        onError: {
          actions: ActionTypes.setPassportError,
        },
      },
    },
    [PassportState.validate]: {
      invoke: {
        src: (ctx: FlightContext) =>
          combineServices([
            FlightPassengerServices.validateUserPassengersParentService(ctx),
            FlightServices.fetchTripPricingService(ctx),
          ]),
        onDone: [
          {
            actions: ActionTypes.setTripPricing,
            target:
              config.nextState ||
              `#${config.parentMachineId}.${ParentState.passengerInformation}`,
          },
        ],
        onError: {
          target: `#${config.parentMachineId}.${ParentState.passengerInformation}`,
          // NOTE: FlightPassengerActionTypes.onValidateAndTripPricingError will likely return
          // no implementation found for onValidateAndTripPricingError as FlightPassengerActionTypes is child action from
          // passengerInformation
          actions: FlightPassengerActionTypes.onValidateAndTripPricingError,
        },
      },
    },
  },
  on: {
    [Event.CLEAR_PASSPORT_ERROR]: {
      actions: ActionTypes.clearPassportError,
    },
    [Event.PREVIOUS]: {
      actions: [
        // NOTE: FlightPassengerActionTypes.onValidateAndTripPricingError will likely return
        // no implementation found for onValidateAndTripPricingError as FlightPassengerActionTypes is child action from
        // passengerInformation
        FlightPassengerActionTypes.clearPassengerInformationError,
        ActionTypes.clearPassportError,
      ],
      target:
        config.previousState ||
        `#${config.parentMachineId}.${ParentState.passengerInformation}.${PassengerInformationState.passengerPicker}`,
    },
  },
});
