import { useMemo } from "react";
import {
  Dealness,
  Prediction,
  PriceDropProtectionEnum,
} from "@b2bportal/air-shopping-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";
import { PredictionForecast } from "@hopper-b2b/ui";

import clsx from "clsx";
import { useDarkModePreferred } from "../../../utils/colors";
import UpArrow from "../../../assets/client/arrow-up.svg";
import UpArrowWhite from "../../../assets/client/darkMode/arrow-up-white.svg";
import DownArrow from "../../../assets/client/arrow-down.svg";
import DownArrowWhite from "../../../assets/client/darkMode/arrow-down-white.svg";
import { useEnablePriceDropProtection } from "@hopper-b2b/utilities";
import { FlightPriceDropProtectionBanner } from "../FlightPriceDropProtectionBanner";

interface MobileFlightPricePredictionContentProps {
  prediction: Prediction;
}

export const MobileFlightPricePredictionContent = ({
  prediction,
}: MobileFlightPricePredictionContentProps) => {
  const { t } = useI18nContext();
  const enabledPriceDropProtection = useEnablePriceDropProtection();
  const isDarkModeEnabled = useDarkModePreferred();

  const pricePredictionCopy = useMemo(
    () => ({
      title:
        prediction.dealness === Dealness.fair
          ? t("pricePredictionCard.fairTitle")
          : prediction?.predictionCopy?.recommendationTitle[0] ?? "",
      detail:
        prediction.dealness === Dealness.fair
          ? t("pricePredictionCard.fairDetail")
          : prediction?.predictionCopy?.recommendationBody[0] ?? "",
    }),
    [
      prediction.dealness,
      prediction?.predictionCopy?.recommendationBody,
      prediction?.predictionCopy?.recommendationTitle,
      t,
    ]
  );

  return (
    <div
      className={clsx(
        "mobile-flight-price-prediction-content",
        isDarkModeEnabled && "dark-mode"
      )}
    >
      {/* TODO: Move .title to a shared header */}
      <div className="title">
        <h1
          dangerouslySetInnerHTML={{
            __html: pricePredictionCopy.title,
          }}
        ></h1>
        <h2
          dangerouslySetInnerHTML={{
            __html: pricePredictionCopy.detail,
          }}
        ></h2>
      </div>

      {enabledPriceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible ? (
        <FlightPriceDropProtectionBanner
          isEligible
          title={null}
          subtitle={
            <I18nMarkup
              className="default-text"
              tKey={"priceDropProtectionDescription"}
            />
          }
          showMore
        />
      ) : null}

      {prediction.pricePrediction.minPrice && (
        <>
          <div className="price-prediction-price-row">
            <img
              className="price-prediction-price-row-icon"
              src={isDarkModeEnabled ? DownArrowWhite : DownArrow}
              alt=""
            />
            <div>
              <h3 className="price-prediction-price-row-title">
                {prediction.pricePrediction.minPrice.display}
              </h3>
              <p className="price-prediction-price-row-subtitle">
                {t("flightSearch.predictedLowestPrice")}
              </p>
            </div>
          </div>
          <hr />
        </>
      )}
      {prediction.pricePrediction.maxPrice && (
        <>
          <div className="price-prediction-price-row">
            <img
              className="price-prediction-price-row-icon"
              src={isDarkModeEnabled ? UpArrowWhite : UpArrow}
              alt=""
            />
            <div>
              <h3 className="price-prediction-price-row-title">
                {prediction.pricePrediction.maxPrice.display}
              </h3>
              <p className="price-prediction-price-row-subtitle">
                {t("flightSearch.predictedHighestPrice")}
              </p>
            </div>
          </div>
          <hr />
        </>
      )}
      {/* price prediction flow */}
      {prediction && prediction.predictionCopy && (
        <PredictionForecast intervals={prediction.predictionCopy.intervals} />
      )}
    </div>
  );
};
