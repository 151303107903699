import { AmenityStatus, Lodging } from "@b2bportal/lodging-api";
import { toDate } from "@hopper-b2b/common-utils";
import { SortOption } from "@hopper-b2b/lodging-utils";
import { toStarRating } from "@hopper-b2b/utilities";
import { createSelector } from "@reduxjs/toolkit";
import { getLodgingAvailabilityTrackingProperties } from "../../../../util/utils";
import {
  ILodgingAvailabilityState,
  ILodgingFilterState,
  LodgingsFilterBoundaries,
} from "../index";

const filterAmenities = (lodgings: Lodging[], filters: ILodgingFilterState) => {
  return lodgings.filter((lodging) => {
    let passes = 0;
    filters.amenities.forEach((am) => {
      if (lodging.lodging.amenities.some((a) => a.amenity === am)) {
        passes++;
      }
    });
    return filters.filterAmenitiesAnd
      ? passes === filters.amenities.length
      : passes > 0;
  });
};

const dedupeLodgings = (lodgings: Lodging[]): Lodging[] => {
  return lodgings.reduce((accumulator, current) => {
    if (
      !accumulator.find(
        (item: Lodging) => item.lodging.id === current.lodging.id
      )
    ) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};

const filterLodgings = (state: ILodgingAvailabilityState): Lodging[] => {
  if (state) {
    const { filters, lodgings } = state;

    const filteredLodgings = lodgings.filter((lodging) => {
      const pass = [null, null, null, null];
      if (filters.freeCancellation) {
        pass[0] = lodging.isFreeCancel;
      }
      if (filters.starRating.length) {
        pass[1] = filters.starRating.includes(
          toStarRating(lodging.lodging.starRating)
        );
      }
      if (filters.priceRange) {
        const price = lodging.price?.nightlyPrice?.fiat?.value;
        pass[2] =
          !price ||
          (price >= filters.priceRange.min && price <= filters.priceRange.max);
      }
      if (filters.userRating > 0) {
        pass[3] = lodging.lodging.score >= filters.userRating;
      }
      return !pass.includes(false);
    });

    if (filters.amenities.length) {
      return filterAmenities(filteredLodgings, filters);
    }

    return filteredLodgings;
  }
  return [];
};

const sortLodgings = (
  lodgings: Lodging[],
  sortOption: SortOption
): Lodging[] => {
  const sortedLodgings = [...lodgings];
  switch (sortOption) {
    case SortOption.STAR_RATING:
      return sortedLodgings.sort(
        (a, b) =>
          toStarRating(b.lodging.starRating) -
          toStarRating(a.lodging.starRating)
      );
    case SortOption.USER_RATING:
      return sortedLodgings.sort((a, b) => b.lodging.score - a.lodging.score);
    case "price":
      return sortedLodgings.sort(
        (a, b) => a.price.totalPrice.fiat.value - b.price.totalPrice.fiat.value
      );
    case SortOption.MOST_RECOMMENDED:
    default:
      return sortedLodgings;
  }
};

export const getLodgingsFilterBoundaries = (
  state: State,
  filtered = true
): LodgingsFilterBoundaries => {
  const amenities: Map<string, { amenity: AmenityStatus; count: number }> =
    new Map();
  if (state.lodgingAvailability.lodgings) {
    const lodgings = filtered
      ? filterLodgings(state.lodgingAvailability)
      : state.lodgingAvailability.lodgings;
    lodgings.forEach((lodge) => {
      // Amenities
      lodge.lodging.amenities.forEach((am) =>
        amenities.set(am.amenity, {
          amenity: am,
          count: amenities.get(am.amenity)?.count + 1 || 1,
        })
      );
    });
  }
  return {
    amenities,
  };
};

type State = { lodgingAvailability: ILodgingAvailabilityState };

export const getUntilDate = ({ lodgingAvailability }: State) =>
  lodgingAvailability.untilDate;
export const getFromDate = ({ lodgingAvailability }: State) =>
  lodgingAvailability.fromDate;
export const getNumberOfAdults = ({ lodgingAvailability }: State) =>
  lodgingAvailability.guests?.adults;
export const getNumberOfChildren = ({ lodgingAvailability }: State) =>
  lodgingAvailability.guests?.children?.length ?? 0;
export const getPlace = ({ lodgingAvailability }: State) =>
  lodgingAvailability.place;

export const getIsFirstRender = ({ lodgingAvailability }: State) =>
  lodgingAvailability.isFirstRender;

export const getLodgings = createSelector(
  [(state: State) => state.lodgingAvailability],
  (availability) => {
    return dedupeLodgings(
      sortLodgings(filterLodgings(availability), availability.sort)
    );
  }
);

export const getTotalLodgingCount = ({ lodgingAvailability }: State) =>
  lodgingAvailability.lodgings?.length;

export const getIsOverFiltered = (
  lodgings: Lodging[],
  filteredLodgings: Lodging[]
) => filteredLodgings?.length === 0 && lodgings?.length > 0;

export const getGuests = ({ lodgingAvailability }: State) =>
  lodgingAvailability.guests;
export const getRooms = ({ lodgingAvailability }: State) =>
  lodgingAvailability.rooms;
export const getFilters = ({ lodgingAvailability }: State) =>
  lodgingAvailability?.filters;
export const getLoading = ({ lodgingAvailability }: State) =>
  lodgingAvailability.loading;
export const getSort = ({ lodgingAvailability }: State) =>
  lodgingAvailability.sort;
export const getView = ({ lodgingAvailability }: State) =>
  lodgingAvailability.view;
export const getCentroid = ({ lodgingAvailability }: State) =>
  lodgingAvailability.centroid;

export const getTotalNights = ({ lodgingAvailability }: State) => {
  if (lodgingAvailability.untilDate && lodgingAvailability.fromDate) {
    const untilDate = toDate(lodgingAvailability.untilDate);
    const fromDate = toDate(lodgingAvailability.fromDate);
    return untilDate.diff(fromDate, "days");
  }
};

export const getOffers = ({ lodgingAvailability }: State) =>
  lodgingAvailability.offers;

export const getHotelAvailabilityTrackingProperties = createSelector(
  getFromDate,
  getUntilDate,
  getSort,
  getFilters,
  getLodgings,
  getNumberOfAdults,
  getNumberOfChildren,
  getLodgingAvailabilityTrackingProperties
);
