import { GenericShopListFooter } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import "./styles.scss";
import { PriceFreezeRefundConnectorProps } from "./container";

export type IPriceFreezeRefundProps = PriceFreezeRefundConnectorProps;

export const PriceFreezeRefund = (props: IPriceFreezeRefundProps) => {
  const { setDisplayPriceFreezeRefundModal } = props;
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  return (
    <>
      <GenericShopListFooter
        className={clsx("flight-shop-price-freeze-refund-footer", "b2b")}
        title={t("priceFreezeRefund.title")}
        subtitle={t("priceFreezeRefund.subtitle")}
        buttons={[
          {
            title: t("priceFreezeRefund.button"),
            isPrimary: false,
            onClick: () => {
              setDisplayPriceFreezeRefundModal(true);
            },
            className: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      />
      {/*<PriceFreezeRefundModal />*/}
    </>
  );
};
