import { Person } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, PersonId, UserInfo } from "@hopper-b2b/types";
import {
  ActionButton,
  ActionLink,
  BackButton,
  IconComponent,
  IconName,
  MobileFloatingSection,
  MobilePopoverCard,
  Slot,
} from "@hopper-b2b/ui";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";
import { Typography, TypographyVariant } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { TravelerInfoForm } from "../TravelerInfoForm";
import { TravelerSelectRow } from "../TravelerSelectRow";
import {
  TravelerSelectStep,
  TravelerTypeTitles,
  getTravelerType,
} from "../TravelerSelectWorkflow/component";

export interface ITravelerSelectWorkflowContentProps {
  userPassengers: Person[];
  titles: TravelerTypeTitles;
  titleVariant?: TypographyVariant;
  subtitleVariant?: TypographyVariant;
  // TODO: figure out why selectedPassengerIds: Set<PersonId> gets converted to be an Object
  selectedPassengerIds: PersonId[];
  handleUpdatePassenger: (passenger: Person) => void;
  handleDeletePassenger: (travelerId: PersonId) => void;
  isMobile?: boolean;
  onProgressChange?: (step: TravelerSelectStep) => void;
  handleContinue?: () => void;
  handleGoBack?: () => void;
  singleTravelerWorkflow?: boolean;
  userInfo?: UserInfo;
  editPassenger?: Person;
  hideFrequentFlyerSection?: boolean;
  hideNationalityField?: boolean;
  hideAdditionalInfoSection?: boolean;
  hideGenderField?: boolean;
  hideButtons?: boolean;
  showDriverLicenseSection?: boolean;
  showPassport?: boolean;
  buttonMessage?: string;
  buttonClassName?: string;
  fullScreenWithBanner?: boolean;
  wrapButton?: boolean;
  disabled?: boolean;
  displayModalSubtitle?: boolean;

  showRemoveButton?: boolean;
  showAddButton?: boolean;

  // Newly added
  currentPassenger?: Person;
  handleEditClick: (passenger: Person) => void;
  openPassengerFormModal: boolean;
  openPassengerPicker: boolean;
  handleSelectPassenger?: (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => void;
  handleCloseForm: () => void;
  handleClickAddTraveler: () => void;
  handleOpenPassengerPicker: () => void;
  isJetBlueTenant?: boolean;
  ErrorBanner?: string | JSX.Element;
  isContinueDisabled?: boolean;
  mobileFloatingDescription?: JSX.Element;
  travelerFormClassName?: string;
  ctaEnabledByDefault?: boolean;
  nationalityRequired?: boolean;
  prefilledPassenger?: UserInfo | undefined;
  modalClassName?: string;
}

export const TravelerSelectWorkflowContent = ({
  titles,
  titleVariant = "h2",
  subtitleVariant = "subtitle2",
  isMobile,
  singleTravelerWorkflow,
  userInfo,
  hideFrequentFlyerSection,
  hideNationalityField,
  hideAdditionalInfoSection,
  hideGenderField,
  showDriverLicenseSection = false, // defaulting to false to not show section
  showPassport,
  buttonMessage,
  buttonClassName,
  fullScreenWithBanner,
  wrapButton,
  disabled,
  displayModalSubtitle,

  showRemoveButton: showRemoveButtonProp = true,
  showAddButton = true,

  // Newly added
  currentPassenger,
  handleEditClick,
  openPassengerFormModal,
  handleContinue,
  handleGoBack,
  selectedPassengerIds = [],
  userPassengers = [],
  handleSelectPassenger,
  handleCloseForm,
  handleUpdatePassenger,
  handleDeletePassenger,
  handleClickAddTraveler,
  isJetBlueTenant,
  ErrorBanner,
  isContinueDisabled,
  mobileFloatingDescription,
  travelerFormClassName,
  ctaEnabledByDefault,
  nationalityRequired,
  prefilledPassenger,
  modalClassName,
}: ITravelerSelectWorkflowContentProps): JSX.Element => {
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const [prefillPassenger, setPrefillPassenger] = useState<
    UserInfo | undefined
  >(prefilledPassenger);

  const isLloydsClient = getEnvVariables("clientName") === ClientName.LLOYDS;

  const handleClickEdit = useCallback(
    (passenger: Person) => {
      // Open form and edit passenger
      handleEditClick(passenger);
    },
    [handleEditClick]
  );

  const handleClickRemove = (travelerId: PersonId) => {
    handleDeletePassenger(travelerId);
  };

  const handleClickSelect = useCallback(
    (travelerId: PersonId) => {
      // hacky way to show edit form pop if driver has no license
      if (showDriverLicenseSection) {
        const passenger = userPassengers.find((t) => t.id === travelerId);
        if (!passenger?.driverLicense?.street) {
          handleClickEdit(passenger as Person);
          return null;
        }
      }
      handleSelectPassenger(travelerId, singleTravelerWorkflow);

      return null;
    },
    [
      handleClickEdit,
      handleSelectPassenger,
      showDriverLicenseSection,
      singleTravelerWorkflow,
      userPassengers,
    ]
  );

  const handlePrefillPassenger = useCallback(() => {
    if (userPassengers.length === 0) setPrefillPassenger(userInfo);
    else setPrefillPassenger(undefined);
  }, [userInfo, userPassengers.length]);

  useEffect(() => {
    !currentPassenger?.id && handlePrefillPassenger();
  }, [currentPassenger?.id, handlePrefillPassenger]);

  const handleAddTraveler = useCallback(() => {
    handleClickAddTraveler();
    handlePrefillPassenger();
  }, [handleClickAddTraveler, handlePrefillPassenger]);

  const renderTravelerEntriesSection = ({
    showRemoveButton,
    showSelectButton,
    showSelectedEntriesOnly,
  }: {
    showRemoveButton?: boolean;
    showSelectButton?: boolean;
    showSelectedEntriesOnly?: boolean;
  }) => {
    // On mobile, the main page should only show selected passengers

    const filteredTravelers = showSelectedEntriesOnly
      ? userPassengers.filter((passenger) =>
          selectedPassengerIds.includes(passenger.id)
        )
      : userPassengers;

    return (
      <div className="traveler-select-workflow-entries-section">
        {filteredTravelers.map((passenger: Person, index: number) => {
          if (currentPassenger?.id === passenger.id && openPassengerFormModal) {
            // Edit traveller info form
            return renderTravelerInfoFormPopup();
          } else {
            return (
              <Slot
                key={passenger.id}
                id="traveler-select-row"
                isMobile={isMobile}
                disabled={disabled}
                className="traveler-select-workflow-entry-root"
                value={`${passenger.givenName} ${passenger.surname}`}
                selectRowType={getTravelerType(passenger, titles)}
                isSelected={selectedPassengerIds.includes(passenger.id)}
                onClickEdit={() => handleClickEdit(passenger)}
                onClickRemove={
                  showRemoveButton && showRemoveButtonProp
                    ? () => handleClickRemove(passenger.id)
                    : undefined
                }
                onClickSelect={
                  showSelectButton
                    ? () => handleClickSelect(passenger.id)
                    : undefined
                }
                buttonClassName={buttonClassName}
                component={
                  <TravelerSelectRow
                    isMobile={isMobile}
                    key={passenger.id}
                    disabled={disabled}
                    className="traveler-select-workflow-entry-root"
                    value={`${passenger.givenName} ${passenger.surname}`}
                    selectRowType={getTravelerType(passenger, titles)}
                    isSelected={selectedPassengerIds.includes(passenger.id)}
                    onClickEdit={() => handleClickEdit(passenger)}
                    onClickRemove={
                      showRemoveButton && showRemoveButtonProp
                        ? () => handleClickRemove(passenger.id)
                        : undefined
                    }
                    onClickSelect={
                      showSelectButton
                        ? () => handleClickSelect(passenger.id)
                        : undefined
                    }
                    buttonClassName={buttonClassName}
                    wrapButton={wrapButton}
                  />
                }
              />
            );
          }
        })}
        {filteredTravelers.length === 0 && (
          <Slot id="traveler-select-row-empty" />
        )}
      </div>
    );
  };

  const handleOnClose = () => {
    handleGoBack && handleGoBack();
  };

  const renderTravelerInfoFormPopup = () => {
    const isUberClient = getEnvVariables("clientName") === ClientName.UBER;
    const isNubankClient = getEnvVariables("clientName") === ClientName.NUBANK;
    const isLloydsClient = getEnvVariables("clientName") === ClientName.LLOYDS;

    const renderContent = (closeButton?: JSX.Element) => {
      return (
        <TravelerInfoForm
          className={clsx(
            "traveler-select-workflow-info-form",
            travelerFormClassName
          )}
          travelerInfoTitle={
            isUberClient || isLloydsClient ? null : titles.travelerInfoTitle
          }
          travelerInfoSubtitle={
            titles.travelerInfoFormSubtitle || titles.travelerInfoSubtitle
          }
          hideHeader={!isMobile || isLloydsClient}
          frequentFlyerTitle={titles.frequentFlyerTitle}
          additionalInfoTitle={titles.additionalInfoTitle}
          hideFrequentFlyerSection={hideFrequentFlyerSection}
          hideNationalityField={hideNationalityField}
          hideAdditionalInfoSection={hideAdditionalInfoSection}
          hideGenderField={hideGenderField}
          showDriverLicenseSection={showDriverLicenseSection}
          traveler={currentPassenger}
          prefillPassenger={prefillPassenger}
          handleUpdatePassenger={handleUpdatePassenger}
          handleRemovePassenger={handleClickRemove}
          isMobile={isMobile}
          closeButton={closeButton}
          buttonClassName={buttonClassName}
          isJetBlueTenant={isJetBlueTenant}
          handleClose={handleCloseForm}
          ctaEnabledByDefault={ctaEnabledByDefault}
          nationalityRequired={nationalityRequired}
          showPassport={showPassport}
          passportSubtitle={titles.passportSubtitle}
        />
      );
    };

    const renderCloseButton = () => {
      return (
        <ActionLink
          className="traveler-form-popup-close-button"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // on mobile => go to passengerPicker
              // else go to => idle
              handleCloseForm();
            }
          }}
          onClick={() => handleCloseForm()}
          content={
            <IconComponent
              ariaLabel={t("close.imageAlt")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label={t("close.button")}
          showTappableArea={true}
        />
      );
    };

    const title = currentPassenger?.id
      ? `${currentPassenger.givenName} ${currentPassenger.surname}`
      : t("newTravelerTitle");

    return (
      <>
        {!isMobile && openPassengerFormModal && !isLloydsClient ? (
          <div className="traveler-select-workflow-info-form-container">
            <div className="traveler-select-workflow-info-form-title">
              {title}
            </div>
            <div>{renderContent()}</div>
          </div>
        ) : null}
        {(isMobile || isLloydsClient) && (
          <Slot
            id="traveller-form-modal"
            open={openPassengerFormModal}
            title={titles.travelerInfoTitle}
            subtitle={titles.travelerInfoSubtitle}
            children={renderContent()}
            onClose={handleCloseForm}
            fullScreen
            className={modalClassName}
            component={
              <MobilePopoverCard
                open={openPassengerFormModal}
                className={clsx(
                  "traveler-select-workflow-info-form-popup",
                  "mobile"
                )}
                onClose={handleOnClose}
                fullScreen={true}
                topLeftButton={
                  isNubankClient ? (
                    <BackButton
                      className="traveler-entries-select-popup-back-button"
                      onClick={handleCloseForm}
                    />
                  ) : null
                }
                topRightButton={
                  isUberClient || isNubankClient ? null : renderCloseButton()
                }
                fullScreenWithBanner={fullScreenWithBanner}
                headerElement={
                  isUberClient || isNubankClient ? (
                    <h4 className="traveler-select-workflow-info-form-header">
                      {titles.travelerInfoHeader}
                      {renderCloseButton()}
                    </h4>
                  ) : null
                }
              >
                {renderContent()}
              </MobilePopoverCard>
            }
          />
        )}
      </>
    );
  };

  return (
    <div className="traveler-entries-select-container">
      <div className="traveler-entries-select-description">
        <Typography
          variant={titleVariant}
          className="traveler-entries-select-description-title"
        >
          {titles.addTravelers}
        </Typography>
        <Typography
          variant={subtitleVariant}
          className="traveler-entries-select-description-subtitle"
        >
          {userPassengers.length > 0 && titles.travelerSelectSubtitle
            ? titles.travelerSelectSubtitle
            : titles.noTravelersSelectSubtitle
            ? titles.noTravelersSelectSubtitle
            : null}
        </Typography>

        <Slot id="traveler-id-match-banner" />
        {displayModalSubtitle ? (
          <Typography
            variant="subtitle2"
            className="traveler-entries-select-description-modal-subtitle"
          >
            {titles.travelerInfoSubtitle}
          </Typography>
        ) : null}
        {titles.notification ? (
          <div className="traveler-entries-select-description-notification">
            {titles.notification}
          </div>
        ) : null}
      </div>
      {ErrorBanner ? ErrorBanner : null}
      {renderTravelerEntriesSection({
        showRemoveButton: true,
        showSelectButton: handleSelectPassenger ? true : false,
      })}

      {openPassengerFormModal && !currentPassenger?.id ? (
        // New traveller info form
        renderTravelerInfoFormPopup()
      ) : !openPassengerFormModal ? (
        <>
          <div className="traveler-entries-select-buttons-container">
            {showAddButton && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "add-traveler"
                )}
                message={buttonMessage ? buttonMessage : t("addNewTraveler")}
                disabled={disabled}
                onClick={handleAddTraveler}
                fullWidth={matchesMobile && !isLloydsClient}
                defaultStyle="h4r-secondary"
                color={isLloydsClient ? "primary" : "default"}
                size={isLloydsClient ? "medium" : undefined}
              />
            )}
          </div>
          {handleContinue && matchesMobile && !isContinueDisabled && (
            <Slot
              id="mobile-checkout-cta-section"
              onClick={handleContinue}
              component={
                <MobileFloatingSection
                  className="traveler-entries-continue-button-container"
                  primaryButton={{
                    children: t("continue"),
                    onClick: () => {
                      handleContinue && handleContinue();
                    },
                    disabled: isContinueDisabled,
                    className: clsx("traveler-entries-continue-button", {
                      disabled: isContinueDisabled,
                    }),
                  }}
                  description={mobileFloatingDescription}
                />
              }
            />
          )}
        </>
      ) : null}
    </div>
  );
};
