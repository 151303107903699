import { PreAuthorizeCode, checkAuthStatus } from "../api/checkAuthStatus";

export const checkAuthStatusService = (_context, _event: unknown) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const response = await checkAuthStatus();
      resolve({
        hasAuth: response.value.code === PreAuthorizeCode.ALREADY_APPROVED,
        redirectUrl: response.value.redirectUrl,
      });
    } catch (error) {
      reject({
        error,
      });
    }
  });
