export const SET_LOADING = "lodgingAvailability/SET_LOADING";
export type SET_LOADING = typeof SET_LOADING;

export const FETCH_PLACE = "lodgingAvailability/FETCH_PLACE";
export type FETCH_PLACE = typeof FETCH_PLACE;

export const SET_AVAILABILITY_LODGINGS =
  "lodgingAvailability/SET_AVAILABILITY_LODGINGS";
export type SET_AVAILABILITY_LODGINGS = typeof SET_AVAILABILITY_LODGINGS;

export const SET_STAY_VALUES = "lodgingAvailability/SET_STAY_VALUES";
export type SET_STAY_VALUES = typeof SET_STAY_VALUES;

export const ADD_AVAILABILITY_LODGINGS =
  "lodgingAvailability/ADD_AVAILABILITY_LODGINGS";
export type ADD_AVAILABILITY_LODGINGS = typeof ADD_AVAILABILITY_LODGINGS;

export const SET_FILTER_FREE_CANCELLATION =
  "lodgingAvailability/SET_FILTER_FREE_CANCELLATION";
export type SET_FILTER_FREE_CANCELLATION = typeof SET_FILTER_FREE_CANCELLATION;

export const SET_FILTER_STAR_RATING =
  "lodgingAvailability/SET_FILTER_STAR_RATING";
export type SET_FILTER_STAR_RATING = typeof SET_FILTER_STAR_RATING;
export const SET_FILTER_USER_RATING =
  "lodgingAvailability/SET_FILTER_USER_RATING";
export type SET_FILTER_USER_RATING = typeof SET_FILTER_USER_RATING;

export const SET_FILTER_AMENITIES = "lodgingAvailability/SET_FILTER_AMENITIES";
export type SET_FILTER_AMENITIES = typeof SET_FILTER_AMENITIES;

export const SET_FILTER_AMENITIES_TYPE =
  "lodgingAvailability/SET_FILTER_AMENITIES_TYPE";
export type SET_FILTER_AMENITIES_TYPE = typeof SET_FILTER_AMENITIES_TYPE;

export const SET_FILTER_PRICE = "lodgingAvailability/SET_FILTER_PRICE";
export type SET_FILTER_PRICE = typeof SET_FILTER_PRICE;

export const RESET_FILTERS = "lodgingAvailability/RESET_FILTERS";
export type RESET_FILTERS = typeof RESET_FILTERS;

export const SET_SORT = "lodgingAvailability/SET_SORT";
export type SET_SORT = typeof SET_SORT;

export const SET_VIEW = "lodgingAvailability/SET_VIEW";
export type SET_VIEW = typeof SET_VIEW;

export const SET_PLACE = "lodgingAvailability/SET_PLACE";
export type SET_PLACE = typeof SET_PLACE;

export const SET_GUESTS = "lodgingAvailability/SET_GUESTS";
export type SET_GUESTS = typeof SET_GUESTS;

export const SET_ROOMS = "lodgingAvailability/SET_ROOMS";
export type SET_ROOMS = typeof SET_ROOMS;

export const SET_CENTROID = "lodgingAvailability/SET_CENTROID";
export type SET_CENTROID = typeof SET_CENTROID;

export const SET_MAP_INFO = "lodgingAvailability/SET_MAP_INFO";
export type SET_MAP_INFO = typeof SET_MAP_INFO;

export const SET_IS_FIRST_RENDER = "lodgingAvailability/SET_IS_FIRST_RENDER";
export type SET_IS_FIRST_RENDER = typeof SET_IS_FIRST_RENDER;
