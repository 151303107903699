import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import { checkAuthStatusPath } from "./paths";

export const PAYMENT_ENROLLMENT_STATUS_PARAM = "enrollment";

export enum EnrollmentResponse {
  success = "success",
  failure = "failure",
}

export const checkAuthStatus = (
  apiConfig?: IApiConfig
): Promise<GetPreAuthorizeStatusResponse> =>
  new Promise((resolve, reject) => {
    const locationUrl = new URL(window.location.toString());
    const searchParams = new URLSearchParams(locationUrl.search);
    searchParams.delete(PAYMENT_ENROLLMENT_STATUS_PARAM);
    const filteredLocationString = `${locationUrl.origin}${
      locationUrl.pathname
    }?${searchParams.toString()}`;

    const successRedirectUrl = `${filteredLocationString}&${PAYMENT_ENROLLMENT_STATUS_PARAM}=${EnrollmentResponse.success}`;
    const failureRedirectUrl = `${filteredLocationString}&${PAYMENT_ENROLLMENT_STATUS_PARAM}=${EnrollmentResponse.failure}`;

    const payload: GetPreAuthorizeStatusRequest = {
      successRedirectUrl,
      failureRedirectUrl,
    };
    axiosInstance
      .post(checkAuthStatusPath, payload)
      .then((res) => {
        const responseBody = res.data;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

interface GetPreAuthorizeStatusRequest {
  successRedirectUrl: string; // url to redirect back to on successful enrollment
  failureRedirectUrl: string; // url to redirect back to on failed enrollment
}

export enum PreAuthorizeCode {
  APPROVAL_NEEDED = "APPROVAL_NEEDED",
  ALREADY_APPROVED = "ALREADY_APPROVED",
}

interface GetPreAuthorizeStatusResponse {
  Response: "Success" | "Failure";
  value: {
    /**
     * "APPROVAL_NEEDED" or "ALREADY_APPROVED".
     */
    code: PreAuthorizeCode;
    /**
     * A URL to redirect the user to in order to get their
     * pre-authorization.
     */
    redirectUrl?: string;
  };
}
