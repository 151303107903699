import { DatePickerButton } from "@hopper-b2b/ui";
import { ClientName, TripCategory } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import { ConnectedCalendarPickerButtonProps } from "./container";
import { CalendarPicker } from "../../../CalendarPicker";
import "./styles.scss";
import { CalendarPicker as CommonCalendarPicker } from "@hopper-b2b/common-search";
import { getEnvVariables } from "@hopper-b2b/utilities";

export interface ICalendarPickerButtonProps
  extends ConnectedCalendarPickerButtonProps {
  retDate: Date | null;
  depDate: Date | null;
  classes?: string[];
  onClick?: () => void;
  disabled?: boolean;
}

export const CalendarPickerButton = ({
  classes = [],
  retDate,
  depDate,
  tripCategory,
  openDatesModal,
  setOpenDatesModal,
  disabled,
  setDepartureDate,
  setReturnDate,
  calendarVisited,
  setCalendarVisited,
  populateSearchUrlParams,
  hasSelectedDates,
  trackingProperties,
}: ICalendarPickerButtonProps) => {
  const { t } = useI18nContext();
  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;

  const onClick = () => {
    if (!disabled) {
      setOpenDatesModal(true);
    }
  };

  return (
    <DatePickerButton
      startDate={depDate}
      startLabel={t("departure")}
      endDate={retDate}
      endLabel={t("return")}
      classes={classes}
      renderCalendarPopup={(props) => {
        return isHopper ? (
          <CommonCalendarPicker
            {...props}
            tripCategory={tripCategory}
            departureDate={depDate}
            returnDate={retDate}
            setDepartureDate={setDepartureDate}
            setReturnDate={setReturnDate}
            open={openDatesModal && props?.open}
            closePopup={() => {
              setOpenDatesModal(false);
              props?.closePopup?.();
            }}
            calendarVisited={calendarVisited}
            setCalendarVisited={setCalendarVisited}
            populateSearchUrlParams={populateSearchUrlParams}
            disabled={!hasSelectedDates}
            trackingProperties={trackingProperties}
          />
        ) : (
          <CalendarPicker
            {...props}
            tripCategory={tripCategory}
            departureDate={depDate}
            returnDate={retDate}
            setDepartureDate={setDepartureDate}
            setReturnDate={setReturnDate}
            open={openDatesModal && props?.open}
            closePopup={() => {
              setOpenDatesModal(false);
              props?.closePopup?.();
            }}
            calendarVisited={calendarVisited}
            setCalendarVisited={setCalendarVisited}
            populateSearchUrlParams={populateSearchUrlParams}
            disabled={!hasSelectedDates}
            trackingProperties={trackingProperties}
          />
        );
      }}
      onClick={onClick}
      dateFormat={"ddd MMM D"}
      hideEndDate={tripCategory === TripCategory.ONE_WAY}
    />
  );
};
