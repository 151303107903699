import { getObjectKeysAsObject } from "@hopper-b2b/checkout";
import { PaymentContext, PaymentState } from "./context";

export const NubankPaymentGuards = {
  hasUserGivenPermission: (ctx: PaymentContext) =>
    ctx[PaymentState.payment].hasAuth === true,
};

export const NubankPaymentGuardTypes =
  getObjectKeysAsObject(NubankPaymentGuards);
