import { Box, Divider, Grid } from "@material-ui/core";
import { FC } from "react";
import { FlightShopSearchFilter } from "../../../../search/components/FlightShopSearchControlV3/components";
import { AppliedFilterTags } from "../../../../search/components/FlightShopSearchControlV3/components/AppliedFilterTags";
import { FlightShopStep } from "../../../reducer";
import { FlightShopProgressBar } from "../../FlightShopProgressBar";
import { FlightShopSummaryPanel } from "../../FlightShopSummaryPanel";
import { OriginalPriceFreezeInfo } from "../../OriginalPriceFreezeInfo";
import { DesktopHeaderText } from "./DesktopHeaderText";
import { FareClassOptionSelection } from "./FareClassOptionSelection";
import { SortOptionSelection } from "./SortOptionSelection";

const DesktopFlightShopHeader: FC<{
  isInPriceFreezePurchase?: boolean;
  isInSimilarFlights?: boolean;
  hasChosenDepartureFlight: boolean;
  currentStep: FlightShopStep;
}> = ({
  isInPriceFreezePurchase,
  isInSimilarFlights,
  hasChosenDepartureFlight,
  currentStep,
}) => {
  const showFareClassSelect =
    currentStep !== FlightShopStep.ReviewItinerary &&
    currentStep !== FlightShopStep.CfarOffers &&
    currentStep !== FlightShopStep.FintechSelection &&
    currentStep !== FlightShopStep.WalletOffers;

  const showMiniSummary =
    hasChosenDepartureFlight && currentStep === FlightShopStep.ChooseReturn;

  return (
    <>
      <Box className="progress-bar-container" my={3}>
        <FlightShopProgressBar
          isReviewItineraryHidden={
            isInPriceFreezePurchase || isInSimilarFlights
          }
        />
      </Box>
      {isInSimilarFlights ? (
        <Box className="original-price-freeze-info-wrapper">
          <OriginalPriceFreezeInfo />
        </Box>
      ) : showMiniSummary ? (
        <Box mb={4} className="header-text-and-flight-summary-container">
          <Box className="left-section">
            <Box className="flight-summary-wrapper">
              <FlightShopSummaryPanel showDepartureReviewOnly={true} />
            </Box>
          </Box>
        </Box>
      ) : null}

      {showFareClassSelect && (
        <Box className="filter-container" mb={3}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item>
              <DesktopHeaderText />
            </Grid>
            <Grid item xs />
            <Grid item>
              <FlightShopSearchFilter hideAirportFilter={false} />
              <AppliedFilterTags />
            </Grid>
          </Grid>
        </Box>
      )}

      {showFareClassSelect ? (
        <>
          <Divider className="filters-divider" />
          <Box className="filters">
            <SortOptionSelection />
            <FareClassOptionSelection />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default DesktopFlightShopHeader;
