import {
  ErrorCode,
  FulfillSuccess,
  Product,
  PurchaseError,
  TrackingProperties,
} from "@b2bportal/purchase-api";
import { trackEvent } from "@hopper-b2b/api";
import { ChooseSavingsProperties, ProductType } from "@hopper-b2b/types";
import { combineTrackingProperties } from "@hopper-b2b/utilities";
import { DoneInvokeEvent, State, assign } from "xstate";
import {
  AirCfarSelectors,
  AirChfarSelectors,
  AirDisruptionSelectors,
  AirDisruptionSelectorsV1,
  AirPFExerciseSelectors,
  FlightSelectors,
  LodgingSelectors,
} from "./states";
import { getCartBreakdownProductTypes } from "./states/Cart/CartQuote/selectors";
import {
  CartFulfillErrorModal,
  ParentState,
  PartialParentContext,
} from "./types";

type ErrorEvent = {
  data: Array<PurchaseError>;
  type: CartFulfillErrorModal;
};

interface TrackFulfillEventProps<Context> {
  eventName: string;
  additionalProperties?: Record<string, string | number | boolean>;
  getAdditionalProperties?: (
    context: Context,
    event: DoneInvokeEvent<FulfillSuccess | ErrorEvent>
  ) => { [key: string]: string | number | boolean };
  getAdditionalEncryptedProperties?: (
    context: Context,
    event: DoneInvokeEvent<FulfillSuccess | ErrorEvent>
  ) => string[];
}

function trackPollSuccess<Context extends PartialParentContext>({
  eventName,
  additionalProperties,
  getAdditionalProperties,
  getAdditionalEncryptedProperties,
}: TrackFulfillEventProps<Context>) {
  return assign((context: Context, event: DoneInvokeEvent<FulfillSuccess>) => {
    const products = getCartBreakdownProductTypes({ context });

    const genericFulfillTrackingProperties = event.data.trackingProperties;
    const fulfilledProducts = event.data.products;
    // Xstate product tracking properties from previous context values
    const arrayOfTrackingProperties = getProductTrackingProperties<Context>(
      products,
      context
    );
    // Fulfilled products' tracking properties
    const fulfilledProductTrackingProperties = fulfilledProducts?.map(
      (product) => {
        return product.value.trackingProperties;
      }
    );
    const chooseSavingsProperties = [getChooseSavingsProperties({ context })];

    // Combine injected properties
    const customProperties = {
      ...(additionalProperties || {}),
      ...(getAdditionalProperties?.(context, event) || {}),
    };
    const customEncryptedProperties =
      getAdditionalEncryptedProperties?.(context, event) || [];

    // Combine all tracking properties
    const { properties, encryptedProperties } = combineTrackingProperties(
      [
        ...arrayOfTrackingProperties,
        fulfilledProductTrackingProperties || [],
        genericFulfillTrackingProperties || [],
        ...chooseSavingsProperties,
      ].flat(),
      customProperties,
      customEncryptedProperties
    );

    trackEvent({
      eventName,
      properties: { success: true, ...properties },
      encryptedProperties,
    });
    return context;
  });
}

function trackPollFailure<Context extends PartialParentContext>({
  eventName,
  additionalProperties,
  getAdditionalProperties,
  getAdditionalEncryptedProperties,
}: TrackFulfillEventProps<Context>) {
  return assign(
    (
      context: Context,
      event: DoneInvokeEvent<{
        data: Array<PurchaseError>;
        type: CartFulfillErrorModal;
      }>
    ) => {
      const products = getCartBreakdownProductTypes({ context });

      const responseErrors = event.data.data;
      const stringifiedErrors =
        responseErrors.length > 0
          ? responseErrors
              .map((error) =>
                (error as ErrorCode)?.code
                  ? (error as ErrorCode).code
                  : (error as any).Error || "ProductError"
              )
              .join(" - ")
          : "Poll Finalized response returned an error and the given error code is not handled.";
      // Xstate product tracking properties from previous context values
      const arrayOfTrackingProperties = getProductTrackingProperties<Context>(
        products,
        context
      );

      // Combine injected properties
      const customProperties = {
        ...(additionalProperties || {}),
        ...(getAdditionalProperties?.(context, event) || {}),
      };

      const customEncryptedProperties =
        getAdditionalEncryptedProperties?.(context, event) || [];

      const { properties, encryptedProperties } = combineTrackingProperties(
        arrayOfTrackingProperties,
        customProperties,
        customEncryptedProperties
      );
      trackEvent({
        eventName,
        properties: {
          success: false,
          failure_reason: stringifiedErrors,
          ...properties,
        },
        encryptedProperties,
      });
      return context;
    }
  );
}

function getProductTrackingProperties<Context>(
  products: Array<Product>,
  context: Context
): Array<TrackingProperties> {
  return (
    products?.map((product) => {
      const productSelector = mapOfProductsToTrackingPropertySelectors[product];
      return productSelector
        ? productSelector({ context })
        : { properties: {} };
    }) || []
  );
}

// Map of xstate selectors to products' fulfill tracking properties
const mapOfProductsToTrackingPropertySelectors: Record<
  Product,
  (state: Pick<State<any>, "context">) => TrackingProperties
> = {
  [Product.AirPriceFreeze]:
    AirPFExerciseSelectors.getCompleteBuyAirPriceFreezeProperties,
  [Product.AirCfar]: AirCfarSelectors.getCompleteBuyAirCfarProperties,
  [Product.AirChfar]: AirChfarSelectors.getCompleteBuyAirChfarProperties,
  [Product.AirDisruption]:
    AirDisruptionSelectorsV1.getCompleteBuyAirDisruptionTrackingProperties,
  [Product.AirPriceDrop]: () => null,
  [Product.Flight]: FlightSelectors.getFlightFulfillTrackingProperties,
  [Product.MultiProviderFlight]:
    FlightSelectors.getFlightFulfillTrackingProperties,
  [Product.Seats]: () => ({
    properties: {},
  }), // TODO: Define tracking properties for seats
  AgentFee: () => null, // NOT IMPLEMENTED
  Ground: () => null, // NOT IMPLEMENTED
  [Product.Homes]: () => null,
  [Product.Hotel]: LodgingSelectors.getLodgingFulfillTrackingProperties,
  LodgingCfar: () => null, // NOT IMPLEMENTED
  LodgingPriceFreeze: () => null, // NOT IMPLEMENTED
  VipSupport: () => null, // NOT IMPLEMENTED
  [Product.Incentives]: () => null,
  AirInsurance: () => null, // NOT IMPLEMENTED,
  Package: () => null, // NOT IMPLEMENTED
};

export const getCreditUsed = (context): number => {
  if (!context[ParentState.wallet]) {
    return undefined;
  }

  const { creditOffer, offer } = context[ParentState.wallet];

  const calculateCreditUsageValue = () => {
    const flightTotal =
      context.flightShop.shopPricingInfo?.fare?.[0]?.pricing?.total;

    const voucherDiscount = offer?.amount;

    if (flightTotal && creditOffer) {
      const value =
        (flightTotal?.fiat?.value ?? 0) +
        (voucherDiscount?.fiat?.value ?? 0) +
        (creditOffer?.amount?.fiat?.value ?? 0);

      // voucherDiscount && creditOffer are negative amounts while flightTotal is positive
      if (value < 0) {
        return Math.abs((creditOffer.amount?.fiat?.value ?? 0) - value);
      } else {
        return Math.abs(creditOffer.amount?.fiat?.value ?? 0);
      }
    } else {
      return 0;
    }
  };

  const credit = {
    ...creditOffer?.amount,
    fiat: {
      ...creditOffer?.amount.fiat,
      value: calculateCreditUsageValue() || 0,
    },
  };

  return credit?.fiat?.value ?? 0;
};

const getChooseSavingsProperties = ({ context }): ChooseSavingsProperties => {
  if (
    !context[ParentState.wallet].creditOffer &&
    !context[ParentState.wallet].offer
  ) {
    return {
      combined_discount_usd: 0,
      applicable_combined_discount_usd: 0,
      credits_amount_usd: 0,
      applicable_credits_amount_usd: 0,
      product_type: ProductType.FLIGHTS,
    };
  }

  const creditOfferDiscount =
    Math.abs(context[ParentState.wallet].creditOffer?.amount?.fiat?.value) ?? 0;
  const voucherDiscount =
    Math.abs(context[ParentState.wallet].offer?.amount.fiat.value) ?? 0;

  const creditUsed = getCreditUsed(context);

  const totalPrice =
    context.flightShop.shopPricingInfo?.fare?.[0]?.pricing?.total?.fiat
      ?.value ?? 0;

  const combinedDiscount = creditOfferDiscount + voucherDiscount;

  return {
    combined_discount_usd: combinedDiscount,
    applicable_combined_discount_usd: Math.min(totalPrice, combinedDiscount),
    credits_amount_usd: creditOfferDiscount,
    applicable_credits_amount_usd: parseFloat(creditUsed.toFixed(2)),
    product_type: ProductType.FLIGHTS,
  };
};

export { getChooseSavingsProperties, trackPollFailure, trackPollSuccess };
