import { putResolve, select } from "redux-saga/effects";
import { ITripTerminus, TripCategory } from "@hopper-b2b/types";
import { History } from "history";

import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import {
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getDestination,
  getDestinationType,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getOrigin,
  getOriginType,
  getReturnDate,
  getTripCategory,
} from "../reducer";
import dayjs from "dayjs";

const PARAM_KEY = {
  origin: "origin",
  destination: "destination",
  originType: "originType",
  destinationType: "destinationType",
  departureDate: "departureDate",
  returnDate: "returnDate",
  tripCategory: "tripCategory",
  adultsCount: "adultsCount",
  childrenCount: "childrenCount",
  infantsInSeatCount: "infantsInSeatCount",
  infantsOnLapCount: "infantsOnLapCount",
};

export function* populateSearchUrlParams(
  action: actions.IPopulateSearchUrlParams
) {
  // Runs on update of flight search values --> update URL params and then state accordingly
  const { params, history } = action;

  const replaceParamWithHistory = replaceSearchParam(history);
  const deleteParamWithHistory = deleteSearchParam(history);

  const state: IStoreState = yield select();

  const {
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  } = getExistingStateVariables(state);

  if (PARAM_KEY.origin in params) {
    if (params.origin) {
      replaceParamWithHistory(
        PARAM_KEY.origin,
        params.origin?.id?.code?.code || ""
      );
      replaceParamWithHistory(
        PARAM_KEY.originType,
        params.origin?.id?.code?.regionType || ""
      );
    } else {
      deleteParamWithHistory(PARAM_KEY.origin);
      deleteParamWithHistory(PARAM_KEY.originType);
    }
    if (params.origin !== origin) {
      yield putResolve(actions.setOrigin(params.origin as ITripTerminus));
      yield putResolve(actions.setOriginType(params.originType));
    }
  }

  if (PARAM_KEY.destination in params) {
    if (params.destination) {
      replaceParamWithHistory(
        PARAM_KEY.destination,
        params.destination?.id?.code?.code || ""
      );
      replaceParamWithHistory(
        PARAM_KEY.destinationType,
        params.destination?.id?.code?.regionType || ""
      );
    } else {
      deleteParamWithHistory(PARAM_KEY.destination);
      deleteParamWithHistory(PARAM_KEY.destinationType);
    }
    if (params.destination !== destination) {
      yield putResolve(
        actions.setDestination(params.destination as ITripTerminus)
      );
      yield putResolve(actions.setDestinationType(params.destinationType));
    }
  }

  if (PARAM_KEY.departureDate in params) {
    if (params.departureDate && dayjs(params.departureDate)) {
      replaceParamWithHistory(
        PARAM_KEY.departureDate,
        dayjs(params.departureDate).format("YYYY-MM-DD") || ""
      );
    } else {
      deleteParamWithHistory(PARAM_KEY.departureDate);
    }
    if (params.departureDate !== departureDate) {
      yield putResolve(
        actions.setDepartureDate(
          params.departureDate ? dayjs(params.departureDate).toDate() : null
        )
      );
    }
  }

  if (PARAM_KEY.returnDate in params) {
    if (params.returnDate && dayjs(params.returnDate)) {
      replaceParamWithHistory(
        PARAM_KEY.returnDate,
        dayjs(params.returnDate).format("YYYY-MM-DD") || ""
      );
    } else {
      deleteParamWithHistory(PARAM_KEY.returnDate);
    }
    if (params.returnDate !== returnDate) {
      yield putResolve(
        actions.setReturnDate(
          params.returnDate ? dayjs(params.returnDate).toDate() : null
        )
      );
    }
  }

  if (params.tripCategory) {
    replaceParamWithHistory(
      PARAM_KEY.tripCategory,
      params.tripCategory as string
    );
    if (params.tripCategory !== tripCategory) {
      yield putResolve(
        actions.setTripCategory(params.tripCategory as TripCategory)
      );
    }
  }

  if (params.passengers) {
    if (params.passengers?.adultsCount) {
      replaceParamWithHistory(
        PARAM_KEY.adultsCount,
        params.passengers?.adultsCount.toString()
      );
    }
    params.passengers?.childrenCount
      ? replaceParamWithHistory(
          PARAM_KEY.childrenCount,
          params.passengers?.childrenCount.toString()
        )
      : deleteParamWithHistory(PARAM_KEY.childrenCount);
    params.passengers?.infantsInSeatCount
      ? replaceParamWithHistory(
          PARAM_KEY.infantsInSeatCount,
          params.passengers?.infantsInSeatCount.toString()
        )
      : deleteParamWithHistory(PARAM_KEY.infantsInSeatCount);
    params.passengers?.infantsOnLapCount
      ? replaceParamWithHistory(
          PARAM_KEY.infantsOnLapCount,
          params.passengers?.infantsOnLapCount.toString()
        )
      : deleteParamWithHistory(PARAM_KEY.infantsOnLapCount);

    if (
      params.passengers?.adultsCount !== adultsCount ||
      params.passengers?.childrenCount !== childrenCount ||
      params.passengers?.infantsInSeatCount !== infantsInSeatCount ||
      params.passengers?.infantsInSeatCount !== infantsOnLapCount
    ) {
      yield putResolve(
        actions.setPassengerCounts({
          adultsCount: params.passengers?.adultsCount,
          childrenCount: params.passengers?.childrenCount,
          infantsInSeatCount: params.passengers?.infantsInSeatCount,
          infantsOnLapCount: params.passengers?.infantsOnLapCount,
        })
      );
    }
  }
}

export function getExistingStateVariables(state: IStoreState) {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    originType: getOriginType(state),
    destinationType: getDestinationType(state),
    tripCategory: getTripCategory(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
  };
}

const deleteSearchParam = (history: History) => (key: string) => {
  const {
    location: { pathname, search, state },
  } = history;
  const updatedParams = new URLSearchParams(search);
  updatedParams.delete(key);
  history.replace({ pathname, search: `?${updatedParams}`, state });
};

const replaceSearchParam =
  (history: History) => (key: string, value: string) => {
    const {
      location: { pathname, search, state },
    } = history;
    const updatedParams = new URLSearchParams(search);
    updatedParams.set(key, value);
    history.replace({ pathname, search: `?${updatedParams}`, state });
  };
