import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { ButtonWrap, MobileFloatingSection } from "@hopper-b2b/ui";
import { ReactComponent as Chevron } from "../../assets/client/arrow-back.svg";
import { ReactComponent as Timeout } from "../../assets/client/timeout.svg";
import { ReactComponent as NotFound } from "../../assets/client/not-found.svg";
import { ReactComponent as Pending } from "../../assets/client/pending.svg";
import { ReactComponent as RedErrorIcon } from "../../assets/client/red-error-exclamation.svg";
import { ReactComponent as MissingInfo } from "../../assets/client/missing-information.svg";
import "./styles.scss";

interface IGenericResponseScreenProps {
  title: string;
  subtitle: string;
  primaryOnClick: () => void;
  primaryButtonText: string | JSX.Element;
  secondaryOnClick?: () => void;
  secondaryButtonText?: string | JSX.Element;
  onBack?: () => void;
  className?: string;
  modal?: boolean;
  header?: string | JSX.Element;
  image?: JSX.Element;
  backIcon?: JSX.Element;
  details?: JSX.Element;
}

const GenericResponseScreen = ({
  title,
  subtitle,
  primaryOnClick,
  primaryButtonText,
  secondaryOnClick,
  secondaryButtonText,
  onBack,
  className,
  modal = false,
  header,
  image,
  backIcon,
  details,
}: IGenericResponseScreenProps) => {
  const svgImage = useMemo(() => {
    if (typeof className === "string") {
      if (className.includes("pending")) {
        return <Pending className="response-image" />;
      } else if (className.includes("warn")) {
        return <RedErrorIcon className="response-image" />;
      } else if (className.includes("error")) {
        return <NotFound className="response-image" />;
      } else if (className.includes("timeout")) {
        return <Timeout className="response-image" />;
      } else if (className.includes("missing")) {
        return <MissingInfo className="response-image" />;
      }
    }
    return null;
  }, [className]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={clsx("nubank-generic-response-screen", className, { modal })}
    >
      <div className="header">
        {onBack ? (
          <ButtonWrap className="back-button" onClick={onBack}>
            {backIcon ?? <Chevron className="back-icon" />}
          </ButtonWrap>
        ) : null}
        {header ? <div className="header-title">{header}</div> : null}
      </div>
      <div
        className={clsx("content", { paddingTop: Boolean(image || svgImage) })}
      >
        {image ?? svgImage}
        <h1 className="generic-response-screen-title">{title}</h1>
        <h4 className="generic-response-screen-subtitle">{subtitle}</h4>
        {details ? (
          <div className="generic-response-screen-details">{details}</div>
        ) : null}
      </div>
      <MobileFloatingSection
        primaryButton={{
          children: primaryButtonText,
          onClick: primaryOnClick,
          className: "generic-response-screen-primary-button",
        }}
        secondaryButton={
          secondaryOnClick
            ? {
                children: secondaryButtonText,
                onClick: secondaryOnClick,
                className: "generic-response-screen-secondary-button",
              }
            : null
        }
      />
    </div>
  );
};

export default GenericResponseScreen;
