import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { trackEvent } from "@hopper-b2b/api";
import { FlightSearch } from "@hopper-b2b/common-search";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ITripTerminus, SelfServeEvents } from "@hopper-b2b/types";
import {
  Breadcrumbs,
  DesktopPopupModal,
  GenericModalContent,
} from "@hopper-b2b/ui";
import { CurrencyFormatters, tenantFlagsEnabled } from "@hopper-b2b/utilities";

import { SlicePickerStep } from ".";
import {
  setDepartureDate,
  setDestination,
  setOrigin,
  setReturnDate,
} from "../../../search/actions/actions";
import { getInitialSearchValues } from "../../../search/reducer";
import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import {
  getChangeFee,
  getIsLoadingContext,
  getMarketingAirline,
} from "../../reducer/selectors";
import { getBaseEventProps, getIsFtc } from "../../selectors";

// import "./styles.scss";

export interface IDesktopLandingPageProps {
  checkForSlicePicker: () => void;
  onSearch: () => void;
}

const defaultProps: Partial<IDesktopLandingPageProps> = {};

const DesktopLandingPage = (props: IDesktopLandingPageProps): JSX.Element => {
  const { checkForSlicePicker, onSearch } = props;
  const dispatch = useDispatch();
  const { t } = useI18nContext();

  const airline = useSelector(getMarketingAirline);
  const baseEventProps = useSelector(getBaseEventProps);
  const changeFee = useSelector(getChangeFee);
  const flightShopStep = useSelector(flightShopProgressSelector);
  const initialValues = useSelector(getInitialSearchValues);
  const isFTC = useSelector(getIsFtc);
  const isLoadingContext = useSelector(getIsLoadingContext);
  const shopStep = useSelector(flightShopProgressSelector);

  const [modalOpen, setModalOpen] = useState(true);

  const basePolicyTitleVars = {
    airline,
    amount: "",
    interpolation: {
      defaultVariables: {
        airline: t("exchangeable.landing.fallbackAirline"),
      },
    },
  };

  useEffect(() => {
    setModalOpen(
      shopStep === FlightShopStep.ExchangeLoadingContext ||
        shopStep === FlightShopStep.ExchangeSlicePicker
    );
  }, [shopStep]);

  useEffect(() => {
    if (isFTC != null && baseEventProps != null) {
      const eventName = isFTC
        ? SelfServeEvents.ViewedFTCExchangeLanding
        : SelfServeEvents.ViewedExchangeLanding;

      trackEvent({
        eventName,
        properties: baseEventProps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="desktop-landing-page-container">
      <Box className="go-back-container">
        <Breadcrumbs
          showBack
          steps={[
            {
              isSelected: true,
              key: "exchangePolicy",
              label: "Flight Exchange",
            },
          ]}
        />
      </Box>
      <Box className="exchange-policy-container">
        {flightShopStep === FlightShopStep.ExchangeLoadingContext ? (
          <Grid
            container
            className="policy-skeleton-container"
            direction="column"
            spacing={1}
          >
            <Grid item xs>
              <Skeleton
                className="policy-skeleton title-skeleton"
                height={32}
              />
            </Grid>
            <Grid item xs={9}>
              <Skeleton className="subtitle-skeleton" height={24} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography className="policy-title" variant="h3">
              {changeFee?.amount
                ? t("exchangeable.landing.title.fee", {
                    ...basePolicyTitleVars,
                    amount: CurrencyFormatters.get(changeFee.currency).format(
                      changeFee.amount
                    ),
                  })
                : t("exchangeable.landing.title.noFee", basePolicyTitleVars)}
            </Typography>
            <Typography className="policy-body" variant="body1">
              {t("exchangeable.landing.body")}
            </Typography>
          </>
        )}
      </Box>
      <Box className="flight-search-form-container">
        <DesktopPopupModal
          hideXButton={shopStep === FlightShopStep.ExchangeLoadingContext}
          className="exchange-flight-desktop-modal"
          invisibleBackdrop={false}
          onClose={() => setModalOpen(false)}
          open={modalOpen}
        >
          {shopStep === FlightShopStep.ExchangeLoadingContext && (
            <GenericModalContent
              image={<CircularProgress />}
              subtitle={t("exchangeable.loading.context")}
            />
          )}
          {shopStep === FlightShopStep.ExchangeSlicePicker && (
            <SlicePickerStep onContinue={onSearch} />
          )}
        </DesktopPopupModal>
        {isLoadingContext ? (
          <Grid
            container
            className="search-control-skeleton-container"
            spacing={2}
          >
            <Grid item xs={6}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
            <Grid item xs={9}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
          </Grid>
        ) : (
          <FlightSearch
            hidePassengerPicker
            hideTripCategoryToggle
            enableCalendarPrediction={
              tenantFlagsEnabled.FlightSearchCalendarPrediction
            }
            changeHandlers={{
              onDepDateChange(date) {
                dispatch(setDepartureDate(date));
              },
              onDestChange(dest) {
                dispatch(setDestination(dest as ITripTerminus));
              },
              onOriginChange(origin) {
                dispatch(setOrigin(origin as ITripTerminus));
              },
              onRetDateChange(date) {
                dispatch(setReturnDate(date));
              },
            }}
            initialValues={initialValues}
            lgDirection="column"
            onSearch={checkForSlicePicker}
          />
        )}
      </Box>
    </Box>
  );
};

DesktopLandingPage.defaultProps = defaultProps;

export default DesktopLandingPage;
