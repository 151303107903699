import { useHistory } from "react-router";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";

import { trackEvent } from "@hopper-b2b/api";
import {
  AirDisruptionSelectors,
  cartFulfillSelectors,
  ContactSelectors,
  DesktopCheckoutHeader,
  FlightSelectors,
  SeatSelectors,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  BOOKING_CONFIRMATION,
  FlightSummaryInfo,
  TripCategory,
} from "@hopper-b2b/types";
import {
  ActionLink,
  FlightConfirmation,
  IconName,
  MobileFloatingSection,
} from "@hopper-b2b/ui";
import { removeTimezone, useDeviceTypes } from "@hopper-b2b/utilities";

import Close from "../../../../assets/client/close.svg";
import GreenCheck from "../../../../assets/client/green-confirmation-check.svg";
import { PATH_TRIPS } from "../../../../utils/urlPaths";
import {
  getClientAssets,
  getFlightSearchReturnLabel,
  getSelectedTripDetails,
  getTripCategory,
} from "../../selectors";
import { ItineraryDetailsModal } from "../Review/components/ItineraryDetailsModal";
import "./styles.scss";

export const BookingConfirmationWrapper: FC<
  PropsWithChildren<{ matchesMobile: boolean }>
> = ({ matchesMobile, children }) => {
  const clientAssets = useSelector(getClientAssets);
  const { t } = useI18nContext();

  return (
    <Box className="booking-confirmation-container nubank">
      {matchesMobile ? (
        children
      ) : (
        <Box className="booking-confirmation-desktop-container">
          <DesktopCheckoutHeader
            clientLogo={clientAssets?.logo}
            title={t("bookingSuccess.title")}
            subtitle={t("bookingSuccess.subtitle")}
          />
          <Box className="booking-confirmation-content-container">
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const BookingConfirmation = () => {
  const history = useHistory();
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const contactInformation = useSelector(
    ContactSelectors.getContactInformation
  );
  const itinerary = useSelector(FlightSelectors.getFinalizedItinerary);
  const seats = useSelector(SeatSelectors.getFulfilledSeats);
  const isRoundTrip = useSelector(getTripCategory) === TripCategory.ROUND_TRIP;
  const returnLabel = useSelector(getFlightSearchReturnLabel);
  const tripDetails = useSelector(getSelectedTripDetails);
  const hasPurchasedAirCfar = useSelector(
    cartFulfillSelectors.hasFulfilledAirCfarProduct
  );
  const hasPurchasedDisruption = useSelector(
    cartFulfillSelectors.hasFulfilledDisruptionProduct
  );
  const hasPurchasedAirChfar = useSelector(
    cartFulfillSelectors.hasFulfilledAirChfarProduct
  );
  const purchasedDisruptionProducts = useSelector(
    AirDisruptionSelectors.getPurchasedDisruptionProducts
  );
  const airports = useSelector(FlightSelectors.getAirportMap);

  const [openItineraryDetails, setOpenItineraryDetails] = useState<
    "departure" | "return" | null
  >(null);

  const getFlightSummaryInfo = useCallback(
    (isOutgoing: boolean): FlightSummaryInfo | null => {
      const slice = tripDetails.slices.find((s) => s.outgoing === isOutgoing);
      const originLabel = airports
        ? airports[slice.originCode]?.cityName
        : slice.originName;
      const destinationLabel = airports
        ? airports[slice.destinationCode]?.cityName
        : slice.destinationName;

      if (slice) {
        return {
          originCity: originLabel,
          destinationCity: destinationLabel,
          arrival: slice.arrivalTime,
          departure: slice.departureTime,
          airlineName: slice.segmentDetails[0].airlineName,
          airlineCode: slice.segmentDetails[0].airlineCode,
          isDeparture: slice.outgoing,
          title: t("originToDestination", {
            origin: originLabel,
            destination: destinationLabel,
          }),
          stops: slice.stops,
        };
      }
      return null;
    },
    [t, tripDetails.slices]
  );

  useEffect(() => {
    document.title = t("bookingConfirmation.documentTitle");
    return () => {
      document.title = t("documentTitle");
    };
  }, [t]);

  const bookingStatus = useMemo(
    () =>
      t("bookingConfirmation.status", {
        id: itinerary?.locators?.agent.unscopedValue || "",
      }),
    [itinerary, t]
  );

  useEffect(() => {
    trackEvent({ eventName: BOOKING_CONFIRMATION, properties: undefined });
  }, []);

  const handleClose = () => history.push(PATH_TRIPS);

  const openFlightDetailsModal = (type: "departure" | "return" | null) =>
    setOpenItineraryDetails(type);

  const redirectToTrips = useCallback(() => {
    history.push(PATH_TRIPS);
  }, [history]);

  const getCustomDate = useCallback((date: string) => {
    return (
      <>
        {dayjs(removeTimezone(date)).format("dddd")},{" "}
        <span className="uppercase">
          {dayjs(removeTimezone(date)).format("DD MMM YY")}
        </span>
      </>
    );
  }, []);

  const getCustomTime = useCallback((departure: string, arrival: string) => {
    return `${dayjs(removeTimezone(departure)).format("HH")}h${dayjs(
      removeTimezone(departure)
    ).format("m")} - ${dayjs(removeTimezone(arrival)).format("HH")}h${dayjs(
      removeTimezone(arrival)
    ).format("m")}`;
  }, []);

  return (
    <BookingConfirmationWrapper matchesMobile={matchesMobile}>
      <Box
        className={clsx("nubank-flight-booking-success-container", {
          mobile: matchesMobile,
          oneWay: !isRoundTrip,
        })}
      >
        <div className="flight-booking-success-header">
          <ActionLink
            onClick={handleClose}
            content={
              <img
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                src={Close}
              />
            }
          />
          <div className="flight-booking-success-header-title">
            {t("bookingConfirmation.documentTitle")}
          </div>
        </div>
        <FlightConfirmation
          statusLabel={bookingStatus}
          title={t("bookingConfirmation.title")}
          subtitle={
            <I18nMarkup
              tKey={"bookingConfirmation.subtitle"}
              replacements={{
                email: contactInformation.email,
              }}
            />
          }
          nextHeader={t("bookingConfirmation.whatsNext")}
          outgoingFlightSummaryInfo={getFlightSummaryInfo(true)}
          returningFlightSummaryInfo={
            isRoundTrip && returnLabel ? getFlightSummaryInfo(false) : undefined
          }
          isMobile={matchesMobile}
          seats={seats?.seats}
          hasPurchasedAirCfar={hasPurchasedAirCfar}
          hasPurchasedDisruption={hasPurchasedDisruption}
          hasPurchasedAirChfar={hasPurchasedAirChfar}
          purchasedDisruptionProducts={purchasedDisruptionProducts}
          headerImage={GreenCheck}
          expandIcon={IconName.ChevronDown}
          openFlightDetailsModal={openFlightDetailsModal}
          getCustomDate={getCustomDate}
          getCustomTime={getCustomTime}
        />
        <MobileFloatingSection
          className="return-home-button"
          primaryButton={{
            children: t("myTrips"),
            onClick: redirectToTrips,
          }}
        />
      </Box>
      <ItineraryDetailsModal
        openModal={openItineraryDetails}
        onClose={() => setOpenItineraryDetails(null)}
      />
    </BookingConfirmationWrapper>
  );
};
