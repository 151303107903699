import { LodgingAddress } from "@b2bportal/lodging-api";
import { getAddressText } from "@hopper-b2b/lodging";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getGoogleStaticMapSrc,
  STATIC_MAP_DARK_STYLE,
} from "@hopper-b2b/utilities";
import { useDarkModePreferred } from "../../../utils/colors";
import LocationPin from "../../../assets/client/location-pin.svg";
import LocationPinWhite from "../../../assets/client/darkMode/location-pin-white.svg";
import styles from "./styles.module.scss";

export type HotelShopMapProps = {
  lodgingCoordinates: string;
  lodgingAddress: LodgingAddress;
  lodgingName: string;
};

export function HotelShopMap({
  lodgingCoordinates,
  lodgingAddress,
  lodgingName,
}: HotelShopMapProps) {
  const { t } = useI18nContext();
  const isDarkMode = useDarkModePreferred();
  //Google static maps requires hex colors to start with 0x
  const markerColor = "0x2A1050";
  return (
    <div className={styles.HotelShopMap}>
      <img
        className="hotel-static-map"
        src={getGoogleStaticMapSrc(
          lodgingCoordinates,
          "16",
          "600x300",
          "2",
          true,
          markerColor,
          isDarkMode ? STATIC_MAP_DARK_STYLE : ""
        )}
        alt={t("hotelMapImageAlt", {
          hotelName: lodgingName,
        })}
      />
      <div className="hotel-map-label">
        <img
          className="availability-map-pin"
          src={isDarkMode ? LocationPinWhite : LocationPin}
          alt=""
        />
        <div>
          <p className="availability-name">{lodgingName}</p>
          <p className="availability-address">
            {getAddressText(lodgingAddress)}
          </p>
        </div>
      </div>
    </div>
  );
}
