import { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export const ShimmerComponent = ({ children }: PropsWithChildren) => {
  return <div className={styles.shimmerComponent}>{children}</div>;
};

export const LoadingScreen = () => {
  return (
    <div className={styles.loadingScreen}>
      <ShimmerComponent>
        <div className="title-container" />
      </ShimmerComponent>
      <ShimmerComponent>
        <div className="content-row-1" />
      </ShimmerComponent>
      <ShimmerComponent>
        <div className="content-row-2" />
      </ShimmerComponent>
    </div>
  );
};
