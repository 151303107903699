import queryStringParser from "query-string";

import { DefaultContext, StateValue, send as xstateSend } from "xstate";
import { History } from "history";
import { CheckoutQueryParams, FlightMachineContext } from "./types";
import { Event } from "./events";
import {
  ParentState,
  getChildState,
  getParentState,
} from "@hopper-b2b/checkout";
import { NubankPaymentState } from "../../checkout/states/payments/types";
import { PATH_HOME } from "../../utils/urlPaths";

// List of states that should not be tracked in the query params
const untrackedStates = [
  ParentState.resume,
  ParentState.route,
  ParentState.loading,
  ParentState.passport,
  ParentState.cartQuote,
  ParentState.cartUpdate,
  ParentState.cartUpdateBeforeFulfill,
  ParentState.cartFulfill,
];

const untrackedPaymentStates = [
  NubankPaymentState.track,
  NubankPaymentState.requestAuth,
  NubankPaymentState.preAuthCheck,
];

// Map of states that should not transition when query params change
// Map: { state that should not transition => previous tracked state }
// Eg: if user is in "cartQuote" and hits back on their browser, the user should not be allowed to go back 1 step and
// instead stay in the current step till it resolves
const preventTransitionParentStates = {
  [ParentState.cartQuote]: ParentState.passengerInformation,
  [ParentState.cartUpdate]: ParentState.passengerInformation,
  [ParentState.cartUpdateBeforeFulfill]: ParentState.review,
  [ParentState.cartFulfill]: ParentState.review,
};

export const populateCheckoutQueryParams = (
  state: StateValue,
  history: History
) => {
  const queryString = history?.location?.search || "";
  const parsedQuery = queryStringParser.parse(queryString);
  const queryParentState = parsedQuery?.[CheckoutQueryParams.checkoutState];
  const queryChildState = parsedQuery?.[CheckoutQueryParams.checkoutChildState];

  const newParentState = getParentState(state);
  const newChildState = getChildState(state);
  const shouldUpdateQuery = !untrackedStates.includes(
    newParentState as ParentState
  );
  const shouldUpdatePaymentQuery = !untrackedPaymentStates.includes(
    newChildState as NubankPaymentState
  );

  const searchParams = {
    ...parsedQuery,
    [CheckoutQueryParams.checkoutState]: newParentState,
  };

  // Handle updating the urls only when we're in the payment state
  if (newParentState === ParentState.payment) {
    searchParams[CheckoutQueryParams.checkoutChildState] = newChildState;
  } else {
    if (parsedQuery?.[CheckoutQueryParams.checkoutChildState]) {
      searchParams[CheckoutQueryParams.checkoutChildState] = undefined;
    }
  }
  const historyData = {
    pathname: history.location.pathname,
    search: queryStringParser.stringify(searchParams),
    state: history.location.state,
  };

  //  If xstate state value is not in query params or if it conflicts with what the
  //  current query params contains, then we push new query params to the URL
  if (!queryParentState && shouldUpdateQuery) {
    history.replace(historyData);
  } else if (queryParentState && shouldUpdateQuery) {
    if (newParentState !== queryParentState) {
      // If the parent states don't match
      if (
        newParentState !== ParentState.payment ||
        (newParentState === ParentState.payment &&
          shouldUpdatePaymentQuery &&
          newChildState !== queryChildState)
      ) {
        history.push(historyData);
      }
    } else if (
      newParentState === ParentState.payment &&
      shouldUpdatePaymentQuery &&
      newChildState !== queryChildState
    ) {
      // If the parent states match but if they're payment, only update if the child states don't match
      history.push(historyData);
    }
  }
};

export const mapFlightStateToParentTransitionEvent = {
  [ParentState.contactInformation]: Event.GO_TO_CONTACT_INFORMATION,
  [ParentState.passengerInformation]: Event.GO_TO_PASSENGER_SELECT,
  [ParentState.review]: Event.GO_TO_REVIEW,
  [ParentState.bookingConfirmation]: Event.GO_TO_REVIEW,
  [ParentState.seatSelection]: Event.GO_TO_SEAT_SELECTION,
};

export const mapPaymentStateToTransitionEvent = {
  [NubankPaymentState.selectCreditOrDebit]:
    Event.GO_TO_PAYMENT_SELECT_CREDIT_OR_DEBIT,
  [NubankPaymentState.credit]: Event.GO_TO_PAYMENT_SELECT_CREDIT,
  [NubankPaymentState.review]: Event.GO_TO_PAYMENT_SELECT_REVIEW,
};

export const transitionStateOnPathnameChange = (
  state: StateValue,
  history: History,
  send: typeof xstateSend
) => {
  const queryString = history.location.search;
  const parsedQuery = queryStringParser.parse(queryString);
  const queryParentState = parsedQuery?.[CheckoutQueryParams.checkoutState];
  const queryChildState = parsedQuery?.[CheckoutQueryParams.checkoutChildState];

  const currentParentState = getParentState(state);
  const currentChildState = getChildState(state);

  const preventParentStateTransition =
    !!preventTransitionParentStates[currentParentState];

  const allowParentStateTransition =
    !preventParentStateTransition &&
    queryParentState &&
    !untrackedStates.includes(queryParentState as ParentState);

  const hasSucceededFulfillment =
    currentParentState === ParentState.bookingConfirmation;

  // If the query and checkout state are in payment, check child states
  if (
    queryParentState === ParentState.payment &&
    currentParentState === ParentState.payment
  ) {
    if (
      queryChildState &&
      queryChildState !== currentChildState &&
      !untrackedPaymentStates.includes(queryChildState as NubankPaymentState)
    ) {
      send({
        type: mapPaymentStateToTransitionEvent[
          queryChildState as NubankPaymentState
        ],
      });
    }
  } else if (
    queryParentState &&
    currentParentState !== queryParentState &&
    allowParentStateTransition
  ) {
    if (hasSucceededFulfillment) {
      handleFulfillSuccess(history);
    } else {
      send({
        type: mapFlightStateToParentTransitionEvent[
          queryParentState as ParentState
        ],
      });
    }
  } else if (
    preventParentStateTransition &&
    preventTransitionParentStates[currentParentState] !== queryParentState
  ) {
    const historyData = {
      pathname: history.location.pathname,
      search: queryStringParser.stringify({
        ...parsedQuery,
        [CheckoutQueryParams.checkoutState]:
          preventTransitionParentStates[currentParentState],
      }),
      state: history.location.state,
    };
    // Pushing the same stack that was removed when the user goes 1 step back in their browser
    history.push(historyData);
  }
};

export const handleFulfillSuccess = (history: History) => {
  // Send user to the home page
  history.replace(PATH_HOME);
};

export const validateContext = (ctx: FlightMachineContext | DefaultContext) =>
  (ctx.flightShop.shopPricingInfo.fare?.length || -1) > 0 &&
  ctx.flightSearch.departureDate &&
  ctx.flightSearch.destination &&
  ctx.flightSearch.origin &&
  ctx.flightShop.selectedTrip.outgoingFareId &&
  ctx.flightShop.selectedTrip.outgoingSliceId &&
  ctx.flightShop.selectedTrip.tripId &&
  Object.keys(ctx.flightShop.airports || {}).length > 0;

export const getCheckoutStepOnQueryParam = () => {
  const queryString = window.location.search;
  const parsedQuery = new URLSearchParams(queryString);
  return parsedQuery.get(CheckoutQueryParams.checkoutState);
};
