import { lazy, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useExperiment } from "@hopper-b2b/experiments";
import { ExperimentVariant } from "@hopper-b2b/types";
import { FeatureFlagsContextProps } from "@hopper-b2b/utilities";
import { SlotProvider, Slots } from "@hopper-b2b/ui";
import { lodgingCheckoutProps } from "../../modules/lodging-checkout";
import { FEATURE_FLAGS, UserContext } from "../../App";
import "../../nubank.hotels.scss";
import { PATH_HOME } from "../../utils/urlPaths";
import styles from "../../nubank.hotels.module.scss";

//nx-ignore-next-line
const LodgingApp = lazy(() => import("@hopper-b2b/lodging"));

export type LodgingRouteComponentProps = {
  featureFlags: FeatureFlagsContextProps;
  slots: Slots;
};

const LodgingRouteComponent = ({
  featureFlags,
  slots,
}: LodgingRouteComponentProps) => {
  const history = useHistory();

  const { sessionInfo } = useContext(UserContext);

  const enableLodging: boolean | undefined = useExperiment(
    FEATURE_FLAGS.LODGING,
    ExperimentVariant.Available,
    true
  );

  useEffect(() => {
    if (enableLodging === false) {
      history.replace(PATH_HOME);
    }
  }, [enableLodging, history]);

  return (
    <SlotProvider slots={slots}>
      <LodgingApp
        className={styles.LodgingRoot}
        checkoutProps={{
          ...lodgingCheckoutProps,
          sessionInfo: sessionInfo,
        }}
        featureFlags={featureFlags}
      />
    </SlotProvider>
  );
};

export default LodgingRouteComponent;
