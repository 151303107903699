import { ExpandableCard } from "@hopper-b2b/ui";
import clsx from "clsx";
import { FlightCardType, FlightListInfo } from "./FlightListInfo/component";

interface SkeletonFlightProps {
  expandedFlight: string;
  onExpandItem: (tripId: string) => void;
}
const SkeletonFlight = ({
  expandedFlight,
  onExpandItem,
}: SkeletonFlightProps) => (
  <ExpandableCard
    key={""}
    className={clsx("flight-list-item", "flight-row", "b2b")}
    expandedCardKey={expandedFlight}
    cardKey={""}
    handleCardKeyChange={() => onExpandItem("")}
    scrollExpandedIntoView={true}
    content={{
      title: (
        <FlightListInfo
          tripId={""}
          onFareClick={() => null}
          type={FlightCardType.skeleton}
        />
      ),
      body: <div />,
    }}
  />
);

export default SkeletonFlight;
