import { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { CheckInInstructions, CheckInTimeEnum } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionLink,
  ButtonWrap,
  IconComponent,
  IconName,
} from "@hopper-b2b/ui";
import { ReactComponent as Chevron } from "../../../assets/client/chevron-down.svg";
import styles from "./styles.module.scss";
import { CheckInPolicy } from "@hopper-b2b/types";

export type HotelDetailsPoliciesProps = {
  checkInInstructions: CheckInInstructions;
};

const HotelPoliciesModalContent = ({
  policies,
}: {
  policies: CheckInPolicy[];
}) => (
  <ul
    className={clsx(
      styles.HotelPoliciesModalContent,
      "HotelPoliciesModalContent"
    )}
  >
    {policies.map((policy, i) => {
      const cleanedStrings = policy.text.split("\n").map((str) => str.trim());
      return (
        <li className="list-item" key={`policies-list-item-${i}`}>
          <p className="list-item-title">{policy.title}</p>
          <ul className="list-item-content">
            {cleanedStrings.map((str, j) => (
              <li
                className="list-item-text"
                key={`policies-list-item-${i}-${j}`}
              >
                {str}
              </li>
            ))}
          </ul>
        </li>
      );
    })}
  </ul>
);
const CheckInModalContent = ({
  checkInInstructions,
}: {
  checkInInstructions: CheckInInstructions;
}) => {
  const { t } = useI18nContext();
  return (
    <div className={clsx(styles.CheckInModalContent, "CheckInModalContent")}>
      <div className="section check-in">
        <h6 className="section-title">{t("checkin")}</h6>
        {!checkInInstructions.checkInTime ? null : (
          <p className="section-content">
            {t("checkinTime", {
              time:
                checkInInstructions.checkInTime?.CheckInTime ===
                CheckInTimeEnum.Anytime
                  ? t("anytime")
                  : checkInInstructions.checkInTime?.checkInTimeFrom,
            })}
          </p>
        )}
      </div>
      <div className="section check-out">
        <h6 className="section-title">{t("checkout")}</h6>
        <p className="section-content">
          {t("checkoutTime", { time: checkInInstructions.checkOutTime })}
        </p>
      </div>
    </div>
  );
};

export function HotelDetailsPolicies({
  checkInInstructions,
}: HotelDetailsPoliciesProps) {
  const { t } = useI18nContext();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const handleOpenModal = useCallback((title: string) => {
    setOpen(true);
    setTitle(title);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
    setTitle("");
  }, [setOpen, setTitle]);

  if (!checkInInstructions) {
    return null;
  }
  const { policies } = checkInInstructions;

  if (!policies || policies.length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles.HotelDetailsPolicies, "HotelDetailsPolicies")}>
      <div
        className={clsx(
          styles.HotelDetailsPoliciesButtons,
          "HotelDetailsPoliciesButtons"
        )}
      >
        <ButtonWrap
          className="check-in-info"
          onClick={() => handleOpenModal(t("checkInInformation"))}
        >
          {t("checkInInformation")}
          <Chevron />
        </ButtonWrap>
        <ButtonWrap
          className="hotel-policies"
          onClick={() => handleOpenModal(t("hotelPolicies"))}
        >
          {t("hotelPolicies")}
          <Chevron />
        </ButtonWrap>
      </div>
      <Dialog
        className={clsx(
          styles.HotelDetailsPoliciesModal,
          "HotelDetailsPoliciesModal"
        )}
        open={open}
        maxWidth={"sm"}
        scroll="paper"
        fullScreen
        fullWidth
      >
        <DialogTitle className={clsx(styles.dialogTitle)} disableTypography>
          <div className={clsx(styles.dialogTitleText, "fullScreen")}>
            <Typography component="h2" variant="h4">
              {title}
            </Typography>
          </div>
          <ActionLink
            className={clsx(styles.closeButton)}
            content={
              <IconComponent
                ariaLabel={t("closeModal")}
                name={IconName.Close}
              />
            }
            onClick={handleCloseModal}
          />
        </DialogTitle>
        <DialogContent className={clsx(styles.dialogContent, "DialogContent")}>
          {title === "" ? null : title === t("checkInInformation") ? (
            <CheckInModalContent checkInInstructions={checkInInstructions} />
          ) : (
            <HotelPoliciesModalContent policies={policies} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
