import { useI18nContext } from "@hopper-b2b/i18n";
import Chevron from "../../../assets/client/chevron.svg";
import { SelectWithHalfSheet } from "../../SelectWithHalfSheet";
import "./styles.scss";

export interface RequiredAssistanceSelectProps {
  assistance: string[];
  assistanceOptions: {
    label: string | JSX.Element;
    value: string;
  }[];
  selected: string;
  optionMenuItems: JSX.Element;
  setAssistance: (assistanceOptions: string[]) => void;
  getLabel: (value: string) => string;
}

const RequiredAssistanceSelect = ({
  selected,
  optionMenuItems,
}: RequiredAssistanceSelectProps) => {
  const { t } = useI18nContext();

  return (
    <SelectWithHalfSheet
      label={t("requiredAssistance")}
      selected={selected}
      className="required-assistance-select traveler-info-field"
      chevronSrc={Chevron}
      modalContent={optionMenuItems}
    />
  );
};

export default RequiredAssistanceSelect;
