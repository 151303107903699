import { getEnvVariables, useUberBridge } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { PropsWithChildren, useEffect } from "react";
import "./styles.scss";

interface MobileLayoutProps {
  headerElement?: JSX.Element | string;
  topLeftButton?: JSX.Element;
  topRightButton?: JSX.Element;
  bottomButton?: JSX.Element;
  contentClassName?: string;
  className?: string;
  fullScreen?: boolean;
  title?: string;
}

export const MobileLayout = (props: PropsWithChildren<MobileLayoutProps>) => {
  const { setHeader } = useUberBridge();
  const {
    headerElement,
    topLeftButton,
    topRightButton,
    bottomButton,
    contentClassName,
    className,
    children,
    fullScreen = true,
    title,
  } = props;

  useEffect(() => {
    if (title && getEnvVariables("clientName") === "uber") {
      setHeader({
        title,
      });
    }
  }, [setHeader, title]);

  return (
    <div
      className={clsx(
        "mobile-layout-card-container",
        fullScreen && "full-screen",
        className
      )}
    >
      {headerElement && (
        <div className="mobile-layout-card-header">{headerElement}</div>
      )}
      {topLeftButton && (
        <div className={clsx("mobile-layout-card-top-button", "left")}>
          {topLeftButton}
        </div>
      )}
      {topRightButton && (
        <div className={clsx("mobile-layout-card-top-button", "right")}>
          {topRightButton}
        </div>
      )}
      <div
        className={clsx(
          "mobile-layout-card-content-container",
          contentClassName
        )}
      >
        {children}
      </div>
      {bottomButton && (
        <div className="mobile-layout-card-bottom-button">{bottomButton}</div>
      )}
    </div>
  );
};
