import { trackEvent } from "@hopper-b2b/api";
import {
  EntryButtonEnum,
  FreezePriceClickProperties,
  PreviousFlightEnum,
  PriceFreezeEntryEnum,
  PriceFreezeHistoryState,
  PriceFreezeTrackingEvents,
} from "@hopper-b2b/fintech";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  MobileFloatingButton,
  UberMobilePricePrediction,
} from "@hopper-b2b/ui";
import {
  useEnablePriceFreeze,
  useEnablePriceWatch,
} from "@hopper-b2b/utilities";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PATH_PRICE_FREEZE_PURCHASE } from "../../../../utils/urlPaths";
import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../../search/reducer/selectors";

import {
  getPriceFreezeOfferCheapestFare,
  getPriceFreezeOfferCheapestTripTripId,
  predictionSelector,
} from "../../reducer/selectors";
import { FlightPriceWatchEntryCard } from "../FlightPriceWatchEntryCard";
import React from "react";

interface UberFlightMobilePricePredictionProps {
  onClose: () => void;
}

export const UberFlightMobilePricePrediction = ({
  onClose,
}: UberFlightMobilePricePredictionProps) => {
  const history = useHistory();
  const enablePriceWatch = useEnablePriceWatch();
  const enablePriceFreeze = useEnablePriceFreeze();
  const prediction = useSelector(predictionSelector);
  const tripId = useSelector(getPriceFreezeOfferCheapestTripTripId);
  const fareId = useSelector(getPriceFreezeOfferCheapestFare);
  const adultsCount = useSelector(getAdultsCount);
  const childrenCount = useSelector(getChildrenCount);
  const infantsInSeatCount = useSelector(getInfantsInSeatCount);
  const infantsOnLapCount = useSelector(getInfantsOnLapCount);
  const { t } = useI18nContext();

  // Price Freeze Entry
  const priceFreezePurchaseQueryString = useMemo(() => {
    const urlParams: Record<string, string> = {
      tripId,
      fareId,
    };

    // Add passenger query params
    if (adultsCount) {
      urlParams.adultsCount = `${adultsCount}`;
    }
    if (childrenCount) {
      urlParams.childrenCount = `${childrenCount}`;
    }
    if (infantsInSeatCount) {
      urlParams.infantsInSeatCount = `${infantsInSeatCount}`;
    }
    if (infantsOnLapCount) {
      urlParams.infantsOnLapCount = `${infantsOnLapCount}`;
    }

    return new URLSearchParams(urlParams).toString();
  }, [
    tripId,
    fareId,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  ]);

  const handlePriceFreezeButtonClick = () => {
    trackEvent({
      eventName: PriceFreezeTrackingEvents.FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: PriceFreezeEntryEnum.PRICE_PREDICTION,
      } as FreezePriceClickProperties,
    });
    history.push(
      `${PATH_PRICE_FREEZE_PURCHASE}?${priceFreezePurchaseQueryString}`,
      {
        priceFreezeEntry: PriceFreezeEntryEnum.PRICE_PREDICTION,
        entryButton: EntryButtonEnum.FREEZE_PRICE,
        previousFlight: PreviousFlightEnum.CLP_FLIGHT,
      } as PriceFreezeHistoryState
    );
  };
  // End of Price Freeze Entry

  return (
    <UberMobilePricePrediction
      onClose={onClose}
      predictionDetails={{
        predictionCopy: prediction.predictionCopy,
        dealness: prediction.dealness,
        lowestPrice: prediction.lowestPrice,
        pricePrediction: prediction.pricePrediction,
        priceDropProtection: prediction.priceDropProtection.PriceDropProtection,
      }}
    >
      {enablePriceWatch ? <FlightPriceWatchEntryCard /> : null}
      {enablePriceFreeze ? (
        <MobileFloatingButton
          wrapperClassName="price-freeze-action-button"
          onClick={handlePriceFreezeButtonClick}
          wide
        >
          {t("priceFreezeEntry.freezePrice")}
        </MobileFloatingButton>
      ) : null}
    </UberMobilePricePrediction>
  );
};
