import { Grid } from "@material-ui/core";
import { IShopFilterSelector } from "../../../../../../../shop/reducer";
import FlightNumberFilterSelection from "../FlightNumberFilterSelection/";
import {
  AirlineFilterSelection,
  AirportFilterSelection,
  DepartureArrivalSelectionDropdown,
  MaxPriceFilterSelection,
  StopsOptionSelection,
} from "../index";
import { useEnableMergeSortingDrodpdownWithFilters } from "@hopper-b2b/utilities";
import { SortOptionSelection } from "../../../../../../../shop/components/FlightShopHeader/components/SortOptionSelection";

export interface IDesktopFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
  flightShopFilters: IShopFilterSelector;
}

export const DesktopFlightShopSearchFilter = ({
  hideAirportFilter,
  flightShopFilters,
}: IDesktopFlightShopSearchFilterProps) => {
  const {
    priceMax,
    priceMin,
    airlineOptions,
    airportOptions,
    flightNumbersByAirline,
  } = flightShopFilters;
  const showSortingDropdownWithFilters =
    useEnableMergeSortingDrodpdownWithFilters();
  return (
    <Grid
      className="desktop-flight-shop-search-filter-container"
      container
      spacing={2}
    >
      <Grid item>
        <StopsOptionSelection />
      </Grid>
      <Grid item>
        <MaxPriceFilterSelection
          maximumPrice={priceMax}
          minimumPrice={priceMin}
        />
      </Grid>
      <Grid item>
        <AirlineFilterSelection allAirlines={airlineOptions} />
      </Grid>
      <Grid item>
        <DepartureArrivalSelectionDropdown />
      </Grid>
      {!hideAirportFilter && (
        <Grid item>
          <AirportFilterSelection allAirports={airportOptions} />
        </Grid>
      )}
      <Grid item>
        <FlightNumberFilterSelection
          allAirlines={airlineOptions}
          flightNumbersByAirline={flightNumbersByAirline}
        />
      </Grid>
      {showSortingDropdownWithFilters && (
        <Grid item>
          <SortOptionSelection />
        </Grid>
      )}
    </Grid>
  );
};
