import { Box, Button } from "@material-ui/core";
import { Airport } from "@b2bportal/air-booking-api";
import clsx from "clsx";

import { GordianSeatSegment, SeatInfo, TagColors } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import { IconName } from "../../../Icon";
import { StatusTag } from "../../../StatusTag";
import "./styles.scss";

export interface ISelectedSeatCardProps {
  selectedSeats: GordianSeatSegment;
  airports: { [key: string]: Airport | undefined };
  idx: number;
  onEditClick?: (idx: number) => void;
  isMobile?: boolean;
}

export const SelectedSeatCard = (props: ISelectedSeatCardProps) => {
  const { t } = useI18nContext();
  const { selectedSeats, onEditClick, isMobile, airports, idx } = props;

  const getLocationString = (location: string) => {
    return airports?.[location] ? airports[location]?.cityName : location;
  };

  const UNAVAILABLE = "Unavailable";

  const locationString = `${getLocationString(
    selectedSeats.originCode
  )} - ${getLocationString(selectedSeats.destinationCode)}`;

  return (
    <Box
      className={clsx("selected-seat-wrapper", "uber", { mobile: isMobile })}
    >
      <div className="selected-seat-info">
        <div className="selected-seat-header">
          <div className="selected-seat-locations">{locationString}</div>
          <div className="selected-seat-edit">
            {onEditClick &&
            selectedSeats.seats.every(
              (seat) => seat.seat_id !== UNAVAILABLE
            ) ? (
              <Button
                className="edit-seats-button"
                onClick={() => onEditClick(idx)}
              >
                {isMobile
                  ? t("edit")
                  : t("editSeat", { count: selectedSeats.seats.length })}
              </Button>
            ) : null}
          </div>
        </div>
        {selectedSeats.seats.map((seatInfo: SeatInfo) => (
          <div className="selected-seat-passenger" key={seatInfo.seat_id}>
            <p className="selected-seat-passenger-name">{seatInfo.pax}</p>
            {seatInfo.seat_id ? (
              seatInfo.seat_id !== UNAVAILABLE ? (
                <p className="selected-seat-number">{seatInfo.seat_id}</p>
              ) : (
                <StatusTag
                  className="seat-not-avail-tag"
                  tagInfo={{
                    label: isMobile
                      ? t("seatSelectionUnavailableMobile")
                      : t("seatSelectionUnavailable"),
                    type: TagColors.GREY,
                    tooltipCopy: t("seatUnavailableDetail"),
                    iconName: IconName.InfoCircle,
                  }}
                />
              )
            ) : (
              <StatusTag
                className="status-tag seat-not-selected-tag"
                tagInfo={{
                  label: isMobile
                    ? t("seatNotSelectedMobile")
                    : t("seatNotSelected"),
                  type: TagColors.YELLOW,
                  tooltipCopy: t("seatNotSelectedDetail"),
                  iconName: IconName.InfoCircle,
                }}
              />
            )}
          </div>
        ))}
      </div>
    </Box>
  );
};
