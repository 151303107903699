import { fetchUserInfoPath } from "../paths";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { apiConfig } from "../../utils/urlPaths";

export type FetchUserInfoRequest = unknown;

export interface NubankUserInfo {
  name: string;
  email: string;
  userId: string;
  preferredName?: string;
  taxId: string;
  phoneNumber?: string;
}

export const fetchUserInfo = (
  userInfoRequest: FetchUserInfoRequest
): Promise<NubankUserInfo> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(
        fetchUserInfoPath,
        userInfoRequest
      );
      resolve(response.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
export default fetchUserInfo;
