import { isEmpty } from "lodash-es";
import { State } from "xstate";
import {
  AirCfarPriceQuoteData,
  AirCfarSchedulePriceQuoteRequest,
  TrackingProperties,
} from "@b2bportal/air-cfar-api";
import { Product, QuotedProduct } from "@b2bportal/purchase-api";

import { getQuoteBreakdown } from "@checkout/states/Cart/CartQuote/selectors";
import { ParentState } from "@checkout/types";
import { CompleteBuyAirCfarChoices } from "@hopper-b2b/types";
import { FlightSelectors } from "../Flight";
import { PartialParentContextWithAirCfarV1 } from "./types";

type CfarUpdateStateType = State<PartialParentContextWithAirCfarV1>;
type CfarUpdateStateWithoutValue = Pick<CfarUpdateStateType, "context">;

export const getCfarCartUpdateRequest = (
  state: CfarUpdateStateWithoutValue
) => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";
  const cfarQuoteId = getCfarQuoteId(state) ?? "";
  const seatedPaxCount =
    state.context.passengerInformation.selectedPassengerIds.length;
  const params: AirCfarSchedulePriceQuoteRequest = {
    tripId,
    cfarQuoteId,
    seatedPaxCount,
  };

  return params;
};

export const getCfarQuoteId = (
  state: CfarUpdateStateType | CfarUpdateStateWithoutValue
) => state.context[ParentState.cancelForAnyReasonV1].cfarQuoteId;

export const getCfarOffers = (
  state: CfarUpdateStateType | CfarUpdateStateWithoutValue
) => state.context[ParentState.cancelForAnyReasonV1].cfarOffers;

export const getCompleteBuyAirCfarChoice = (
  state: CfarUpdateStateType | CfarUpdateStateWithoutValue
): CompleteBuyAirCfarChoices => {
  const cfarOffers = getCfarOffers(state);
  const cfarQuoteId = getCfarQuoteId(state);

  if (isEmpty(cfarOffers)) {
    return CompleteBuyAirCfarChoices.NOT_OFFERED;
  }

  return cfarQuoteId
    ? CompleteBuyAirCfarChoices.ATTACHED
    : CompleteBuyAirCfarChoices.DECLINED;
};

export const getIsCfarAttached = (
  state: CfarUpdateStateType | CfarUpdateStateWithoutValue
): boolean | undefined => {
  const choice = getCompleteBuyAirCfarChoice(state);
  return choice === CompleteBuyAirCfarChoices.NOT_OFFERED
    ? undefined
    : choice === CompleteBuyAirCfarChoices.ATTACHED;
};

export const getCompleteBuyAirCfarProperties = (
  state: CfarUpdateStateWithoutValue
): TrackingProperties | undefined => {
  const cartQuoteBreakdown = getQuoteBreakdown(state);

  const cfarProduct: AirCfarPriceQuoteData = cartQuoteBreakdown?.products?.find(
    (quotedProduct: QuotedProduct) =>
      quotedProduct.product.type === Product.AirCfar
  )?.product?.value;

  return cfarProduct?.trackingProperties;
};

export const getSelectedCfarOffer = (state: CfarUpdateStateWithoutValue) => {
  return state.context[ParentState.cancelForAnyReasonV1].selectedOffer;
};
