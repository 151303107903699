import { CipherText } from "@b2bportal/air-cfar-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AirOrchestratorCfarTrackingEvents,
  CfarExerciseProgress,
  ClientName,
} from "@hopper-b2b/types";
import { ActionButton, MobileFloatingButton } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getHopperLocatorFromBookedFlightItinerary,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  confirmFlightCfarCancellation,
  confirmFlightCfarCancellationV1,
  replaceTripQueryParams,
  resetCfarExerciseState,
  setCfarExerciseProgress,
  setSelectedFlight,
} from "../../../../../pages/TripsList/actions/actions";
import {
  getSelectedFlight,
  getCfarExerciseQuoteTrackingProperties,
} from "../../../../../pages/TripsList/reducer/selectors";
import clsx from "clsx";
import { trackEvent } from "@hopper-b2b/api";

interface CfarProgressCtaProps {
  cfarExerciseProgress: CfarExerciseProgress;
  quoteId: CipherText | null;
  useCfarV1: boolean;
  disableFloatingButton?: boolean;
  done?: () => void;
  secondaryCta?: () => void;
}
export const CfarProgressCta = ({
  cfarExerciseProgress,
  quoteId,
  useCfarV1,
  disableFloatingButton = false,
  done,
  secondaryCta,
}: CfarProgressCtaProps) => {
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const flight = useSelector(getSelectedFlight);
  const { matchesMobile } = useDeviceTypes();

  const buttonText = useMemo(() => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Review:
        return t("cfarExercise.cta.confirmCancellation");
      case CfarExerciseProgress.Completed:
        return t("cfarExercise.cta.completedCancellation");
      default:
        return t("cfarExercise.cta.cancelFlight");
    }
  }, [cfarExerciseProgress, t]);

  const handleClick = () => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Review:
        confirmCfarExercise();
        return;
      case CfarExerciseProgress.Completed:
        concludeCfarExercise();
        done?.();
        return;
      default:
        done?.();
        return;
    }
  };

  const cfarExerciseQuoteTrackingProperties = useSelector(
    getCfarExerciseQuoteTrackingProperties
  );

  const confirmCfarExercise = () => {
    const client = getEnvVariables("clientName") as ClientName;
    if (useCfarV1) {
      dispatch(setCfarExerciseProgress(CfarExerciseProgress.Pending));
      dispatch(confirmFlightCfarCancellationV1({ quoteToken: quoteId }));
      if (client !== ClientName.LLOYDS) {
        // for Lloyds, stay on the trip details screen
        dispatch(replaceTripQueryParams(history));
      }
    } else {
      if (quoteId) {
        dispatch(setCfarExerciseProgress(CfarExerciseProgress.Pending));
        dispatch(confirmFlightCfarCancellation({ quoteToken: quoteId }));
        dispatch(replaceTripQueryParams(history));
      }
    }
  };

  const concludeCfarExercise = () => {
    dispatch(resetCfarExerciseState());
    dispatch(setSelectedFlight(null));
    const client = getEnvVariables("clientName") as ClientName;
    if (client !== ClientName.LLOYDS) {
      // for Lloyds, stay on the trip details screen
      dispatch(replaceTripQueryParams(history));
    }
    if (client === ClientName.UBER) {
      const agentLocator = getHopperLocatorFromBookedFlightItinerary(flight);
      window.parent.postMessage(
        {
          type: "BOOKING_CANCELED",
          payload: { agentLocator },
        },
        import.meta.env["VITE_UBER_URL"]
      );
    }
  };

  return disableFloatingButton ? (
    <Box pb={2} style={{ display: "flex", justifyContent: "flex-start" }}>
      <ActionButton
        className={clsx("cfar-cancel-flight-cta", {
          "full-width": matchesMobile,
        })}
        message={buttonText}
        disabled={!quoteId}
        onClick={handleClick}
      />
      {secondaryCta && (
        <ActionButton
          className={clsx("cfar-cancel-flight-cta", "secondary")}
          message={"Cancel"}
          onClick={secondaryCta}
        />
      )}
    </Box>
  ) : (
    <MobileFloatingButton
      className="cfar-cancel-flight-cta"
      onClick={handleClick}
      wide
      disabled={!quoteId}
    >
      {buttonText}
    </MobileFloatingButton>
  );
};
