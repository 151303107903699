import { Flights } from "@b2bportal/air-shopping-api";
import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import { IFlightListData, TripDetails } from "@hopper-b2b/types";
import {
  ActionButton,
  ActionLink,
  DesktopPopupModal,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { useDeviceTypes, useEnableB6Upsell } from "@hopper-b2b/utilities";
import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AmenityGrid } from "./components/AmenityGrid";
import { UPSELL_MAP } from "./constants";
import "./styles.scss";

export type UpsellModalProps = {
  flights: Flights | null;
  handleFareSubmit: (flight: IFlightListData, fareId: string) => void;
  isOutgoing: boolean;
  selectedFareId: string;
  setSelectedFareId: (fareId: string) => void;
  tripDetails?: TripDetails | null;
};

export const UpsellModal = ({
  flights,
  handleFareSubmit,
  isOutgoing,
  selectedFareId,
  setSelectedFareId,
  tripDetails,
}: UpsellModalProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const { formatFiatCurrency } = useI18nCurrency();
  const showB6Upsell = useEnableB6Upsell();

  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    setSelectedFareId("");
  }, [setSelectedFareId]);

  const selectedFare = useMemo(
    () => tripDetails?.fareDetails.find(({ id }) => id === selectedFareId),
    [selectedFareId, tripDetails]
  );

  const selectedFareSlice = useMemo(
    () => selectedFare?.slices.find(({ outgoing }) => outgoing === isOutgoing),
    [isOutgoing, selectedFare]
  );

  const selectedFareBrandName = useMemo(
    () => selectedFareSlice?.fareShelf?.shortBrandName || "",
    [selectedFareSlice]
  );

  const targetFareBrandName = useMemo(
    () => UPSELL_MAP[selectedFareBrandName]?.targetBrandName,
    [selectedFareBrandName]
  );

  const targetFare = useMemo(
    () =>
      tripDetails?.fareDetails.find((fare) =>
        fare.slices.some(
          ({ outgoing, fareShelf }) =>
            outgoing === isOutgoing &&
            fareShelf?.shortBrandName === targetFareBrandName
        )
      ),
    [isOutgoing, targetFareBrandName, tripDetails]
  );

  const targetFareSlice = useMemo(
    () =>
      targetFare?.slices.find(
        ({ outgoing, fareShelf }) =>
          outgoing === isOutgoing &&
          fareShelf?.shortBrandName === targetFareBrandName
      ),
    [isOutgoing, targetFareBrandName, targetFare]
  );

  const priceDifference = useMemo(
    () =>
      selectedFare &&
      targetFare &&
      formatFiatCurrency(
        {
          currencyCode:
            selectedFare.paxPricings[0].pricing.baseAmount.fiat.currencyCode,
          value:
            (targetFare.paxPricings[0].pricing.total?.fiat.value || 0) -
            (selectedFare.paxPricings[0].pricing.total?.fiat.value || 0),
        },
        { maximumFractionDigits: 0, minimumFractionDigits: 0 }
      ),
    [formatFiatCurrency, selectedFare, targetFare]
  );

  const onClick = useCallback(
    (fareId) => () => {
      const selectedFare = flights?.fares[fareId];

      onClose();

      if (selectedFare) {
        handleFareSubmit(
          {
            slice: flights.fareSlices[selectedFare.outbound].slice,
            fares: flights.trips[selectedFare.tripId].fares.map(
              (f) => flights.fares[f]
            ),
          },
          fareId
        );
      }
    },
    [flights, handleFareSubmit, onClose]
  );

  useEffect(() => {
    if (
      showB6Upsell &&
      tripDetails?.validatingCarrierCode === "B6" &&
      targetFare
    ) {
      setOpen(true);
    } else if (selectedFareId) {
      onClick(selectedFareId)();
    }
  }, [selectedFareId]);

  const content = useMemo(
    () => (
      <Box className={clsx("upsell-modal-root")}>
        <Typography variant="h2" className="upsell-modal-heading">
          {t(UPSELL_MAP[selectedFareBrandName]?.headingText)}
        </Typography>
        <Grid container className="upsell-modal-grid-container">
          <Grid item xs={6} />
          <Grid item xs={3} className="upsell-modal-grid-row-icon">
            <Typography className="upsell-modal-grid-heading">
              {selectedFareBrandName}
            </Typography>
          </Grid>
          <Grid item xs={3} className="upsell-modal-grid-row-icon">
            <Typography className="upsell-modal-grid-heading">
              {targetFareBrandName}
            </Typography>
          </Grid>
          <AmenityGrid
            selectedFareSlice={selectedFareSlice}
            targetFareSlice={targetFareSlice}
          />
        </Grid>
        <Box className="upsell-modal-button-container">
          <ActionButton
            onClick={onClick(targetFare?.id)}
            variant="contained"
            message={t("b6Upsell.button.primary", {
              name: targetFareBrandName,
              price: priceDifference,
            })}
          />
          <ActionButton
            onClick={onClick(selectedFareId)}
            defaultStyle="h4r-secondary"
            variant="outlined"
            message={t("b6Upsell.button.secondary", {
              name: selectedFareBrandName,
            })}
          />
        </Box>
      </Box>
    ),
    [
      onClick,
      priceDifference,
      selectedFareBrandName,
      selectedFareId,
      selectedFareSlice,
      t,
      targetFare,
      targetFareBrandName,
      targetFareSlice,
    ]
  );

  return matchesMobile ? (
    <MobilePopoverCard
      className="mobile-upsell-modal"
      open={open}
      onClose={onClose}
      fullScreen
      topRightButton={
        <ActionLink
          onClick={onClose}
          content={
            <IconComponent
              ariaLabel={t("modalClose.ariaLabel")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
        />
      }
    >
      {content}
    </MobilePopoverCard>
  ) : (
    <DesktopPopupModal
      className="desktop-upsell-modal"
      open={open}
      onClose={onClose}
      invisibleBackdrop={false}
    >
      {content}
    </DesktopPopupModal>
  );
};
