import { IContactInfo } from "@checkout/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { MobileFloatingSection, Slot } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { PropsWithChildren, useCallback } from "react";
import { ContactInfoForm } from "../ContactInfoForm";

export interface IContactInformationContentProps extends PropsWithChildren {
  className?: string;
  disabled?: boolean;
  contactInfo: IContactInfo | null;
  floatingSectionDescription?: JSX.Element;
  onContactInfoChange?: (info: IContactInfo | null) => void;
  onContinue?: () => void;
  showContinueButton?: boolean;
  hasContactInfo?: boolean;
  isContinueDisabled?: boolean;
  emailRegex?: RegExp;
  phoneRegex?: RegExp;
  checkPhoneMinLength?: boolean;
  minPhoneLength?: number;
}

export const ContactInformationContent = ({
  className,
  disabled,
  contactInfo,
  floatingSectionDescription,
  onContactInfoChange,
  onContinue,
  showContinueButton = true,
  hasContactInfo = false,
  isContinueDisabled = true,
  children,
  emailRegex,
  phoneRegex,
  checkPhoneMinLength,
  minPhoneLength,
}: IContactInformationContentProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const updateContactInfo = useCallback(
    (changeInfo: IContactInfo) => {
      const updatedContactInfo = {
        email: changeInfo.email,
        phoneNumber: changeInfo.phoneNumber,
        countryCode: changeInfo.countryCode,
      };
      onContactInfoChange(updatedContactInfo);
    },
    [onContactInfoChange]
  );

  return (
    <>
      <div className="contact-info-container">
        <ContactInfoForm
          isMobile={matchesMobile}
          disabled={disabled}
          title={t("contactInformation")}
          subtitle={t("contactInfoSubtitle")}
          contactInfo={contactInfo}
          onContactInfoChange={updateContactInfo}
          emailRegex={emailRegex}
          phoneRegex={phoneRegex}
          checkPhoneMinLength={checkPhoneMinLength}
          minPhoneLength={minPhoneLength}
        />
        {children}
      </div>
      {matchesMobile && (
        <Slot
          id="mobile-checkout-cta-section"
          onClick={onContinue}
          buttonDisabled={isContinueDisabled}
          component={
            showContinueButton &&
            hasContactInfo && (
              <MobileFloatingSection
                className="mobile-contact-info-form-save-section"
                description={floatingSectionDescription}
                primaryButton={{
                  children: t?.("continue"),
                  onClick: onContinue,
                  disabled: isContinueDisabled,
                  className: "mobile-contact-info-form-save-button",
                  wrapperClassName: clsx(
                    "mobile-contact-info-form-save-button-container",
                    className
                  ),
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
