export enum WalletEventTypes {
  SET_SELECTED_OFFER = "SET_SELECTED_OFFER",
  REMOVE_SELECTED_OFFER = "REMOVE_SELECTED_OFFER",
  CLEAR_WALLET_ERROR = "CLEAR_WALLET_ERROR",
  VERIFY_SELECTED_OFFER = "VERIFY_SELECTED_OFFER",
  PREVIOUS = "PREVIOUS",
  NEXT = "NEXT",
}

export interface SetSelectedOfferEvent {
  type: WalletEventTypes.SET_SELECTED_OFFER;
  offerId: string;
}

export interface RemoveSelectedOfferEvent {
  type: WalletEventTypes.REMOVE_SELECTED_OFFER;
  offerId: string;
}

export interface ClearWalletErrorEvent {
  type: WalletEventTypes.CLEAR_WALLET_ERROR;
}

export interface VerifySelectedOfferEvent {
  type: WalletEventTypes.VERIFY_SELECTED_OFFER;
}

export interface NextWalletEvent {
  type: WalletEventTypes.NEXT;
}

export interface PreviousWalletEvent {
  type: WalletEventTypes.PREVIOUS;
}

export type WalletEvents =
  | SetSelectedOfferEvent
  | RemoveSelectedOfferEvent
  | ClearWalletErrorEvent
  | VerifySelectedOfferEvent
  | NextWalletEvent
  | PreviousWalletEvent;
