import {
  TripFilter,
  IMonthBucket,
  IDateBucket,
  IBucketedDate,
  IDepartureCalendarReport,
} from "@hopper-b2b/types";
import { select, put } from "redux-saga/effects";
import { getEnvVariables } from "@hopper-b2b/utilities";
import dayjs from "dayjs";

import { actions } from "../actions";
import Logger from "../../../helpers/Logger";
import { IStoreState } from "../../../reducers/types";
import { getDestination, getOrigin, getTripCategory } from "../reducer";
import { fetchCalendar } from "@hopper-b2b/api";
import { CalendarBuckets } from "@b2bportal/air-shopping-api";

export function* fetchDepartureCalendarSaga({
  originCode: tempOriginCode,
  departureCode: tempDepartureCode,
}: actions.IFetchDepartureCalendar) {
  try {
    const state: IStoreState = yield select();
    const destination = getDestination(state);
    const origin = getOrigin(state);
    const tripType = getTripCategory(state);

    if (origin && destination) {
      const {
        id: { code: destinationCode },
      } = destination;
      const {
        id: { code: originCode },
      } = origin;

      const requestBody: CalendarBuckets = {
        route: {
          origin: tempOriginCode || originCode,
          destination: tempDepartureCode || destinationCode,
        },
        tripType,
        filter: { TripFilter: TripFilter.NO_FILTER },
      };

      const calendarReport: IDepartureCalendarReport = yield fetchCalendar(
        requestBody
      );

      const departureMonths: IMonthBucket[] = transformDateBuckets(
        calendarReport.departureDateBuckets
      );

      yield put(actions.setCalendar({ ...calendarReport, departureMonths }));
    }
    fireUberStartSession();
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setCalendar());
    fireUberStartSession();
  }
}

const fireUberStartSession = () => {
  // Following event is fired to indicate that the portal is loaded when coming from uber
  // Fired after:
  // - hydrated redux store from query params
  // - rendered calendar UI
  // - fetched calendar prediction data
  if (getEnvVariables("clientName") === "uber") {
    window.parent.postMessage(
      {
        type: "START_SESSION",
        payload: null,
      },
      import.meta.env["VITE_UBER_URL"]
    );
  }
};

export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};
