import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FintechProductOption,
  FintechProductType,
  TripCategory,
} from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ClientContext } from "../../../../../../../flights/src/App";
import { getTripCategory } from "../../../../../../../flights/src/modules/search/reducer";
import { IStoreState } from "../../../../../../../flights/src/reducers/types";
import { selectedTripSelector, tripDetailsSelector } from "../../../reducer";
import { FlightPricingSummarySection } from "../../FlightPricingSummarySection";
import { FintechBody } from "./FintechBody";
import { FintechHeader } from "./FintechHeader";
import { FintechMoreInfo } from "./FintechMoreInfo";
import styles from "./FintechProduct.module.scss";
import { FintechSelection } from "./FintechSelection";

const CFAR_TERMS_URL = "https://www.hopper.com/legal/Air-CFAR";
const FLIGHT_DELAY_TERMS_URL =
  "https://www.hopper.com/legal/Flight-Delay-Guarantee";

const CFAR_HOW_IT_WORKS_URL = "https://hopper.com/legal/cfar-learn-more";

interface FintechProductProps {
  header: string;
  infoTextKey?: string;
  options: FintechProductOption[];
  bodyText?: string | string[];
  type: FintechProductType;
  disableContinueButton?: boolean;
  hideContinueButton?: boolean;
  goToNextStep?: () => void;
}

export const FintechProduct = ({
  header,
  infoTextKey,
  options,
  bodyText,
  type,
  disableContinueButton,
  hideContinueButton,
  goToNextStep,
}: FintechProductProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const clientContext = useContext(ClientContext);

  const [currentModal, setCurrentModal] = useState<string>("");

  const isCfar = type === FintechProductType.CfarPurchase;
  const termsUrl = isCfar ? CFAR_TERMS_URL : FLIGHT_DELAY_TERMS_URL;
  const howItWorksUrl = isCfar ? CFAR_HOW_IT_WORKS_URL : "";

  const openHowItWorks = () => {
    window.open(howItWorksUrl, "_blank").focus();
  };

  const openTerms = () => {
    window.open(termsUrl, "_blank").focus();
  };

  const closeModal = () => {
    setCurrentModal("");
  };

  const selectedTrip = useSelector(selectedTripSelector);
  const tripId = selectedTrip.tripId || "";
  const selectedFareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;
  const tripDetails = useSelector((state: IStoreState) =>
    tripDetailsSelector(state, tripId)
  );
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );
  const isOneWay = useSelector(getTripCategory) === TripCategory.ONE_WAY;

  const headerImageSrc = isCfar
    ? clientContext?.assets?.cfar
    : clientContext?.assets?.flightDelay;
  return (
    <Box
      className={clsx(styles.fintechProduct, {
        [styles.mobile]: matchesMobile,
      })}
    >
      <Box
        className={clsx(styles.header, {
          [styles.otherBackground]: isCfar,
          [styles.mobile]: matchesMobile,
        })}
      >
        <FintechHeader
          imgSrc={headerImageSrc}
          header={header}
          infoTextKey={infoTextKey}
        />
      </Box>
      {matchesMobile && <Box className={styles.mobileTitle}>{header}</Box>}
      <Box className={styles.body}>
        <FintechBody
          type={type}
          description={bodyText}
          openHowItWorks={openHowItWorks}
          openTerms={openTerms}
        />
        <FintechSelection options={options} />
        {matchesMobile && (
          <Box className={styles.moreInfo}>
            <Box onClick={openHowItWorks}>
              <span>{t("howItWorks")}</span>{" "}
            </Box>{" "}
            <span className={styles.divider}>|</span>
            <Box onClick={openTerms}>
              <span>{t("viewTerms")}</span>{" "}
            </Box>
          </Box>
        )}
      </Box>
      {!hideContinueButton && (
        <FlightPricingSummarySection
          hidePrice
          fareDetails={fareDetails}
          isOneWay={isOneWay}
          onClick={goToNextStep}
          disabled={disableContinueButton}
        />
      )}
      <FintechMoreInfo
        type={type}
        open={!!currentModal}
        close={closeModal}
        openTerms={openTerms}
      />
    </Box>
  );
};
