import { useI18nContext } from "@hopper-b2b/i18n";
import { type SortOption } from "@hopper-b2b/lodging-utils";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import {
  ActionButton,
  ActionLink,
  AmenitiesFilter,
  IconComponent,
  IconName,
  PriceRange,
} from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useId } from "react";
import { sortAmenities } from "../../../../../src/util/utils";
import { useTrackEvents } from "../../../../tracking";
import {
  type ILodgingFilterState,
  type LodgingsFilterBoundaries,
  type PriceRange as PriceRangeType,
} from "../../reducer";
import { FreeCancellation } from "../FreeCancellation";
import { SortInput } from "../SortInput";
import { StarRating } from "../StarRating";
import { UserRating } from "../UserRating";
import styles from "./FiltersModal.module.scss";

export interface FiltersModalProps {
  open: boolean;
  filters: ILodgingFilterState;
  filterBoundaries: LodgingsFilterBoundaries;
  closeFiltersModal: () => void;
  onFilterPriceRangeChange(
    nextValue: Pick<PriceRangeType, "min" | "max">
  ): void;
  onStarRatingChange(nextValue: number[]): void;
  onUserRatingChange(nextValue: number): void;
  onAmenitiesChange: (value: string[]) => void;
  onAmenitiesTypeChange: (value: boolean) => void;
  onFreeCancellationChange: (value: boolean) => void;
  resetFilters: () => void;
  sort?: SortOption;
  setSort?: (value: string) => void;
}

export function FiltersModal({
  open,
  filters: {
    priceRange,
    starRating,
    userRating,
    freeCancellation,
    amenities,
    filterAmenitiesAnd,
  },
  filterBoundaries,
  closeFiltersModal,
  onFilterPriceRangeChange,
  onStarRatingChange,
  onUserRatingChange,
  onFreeCancellationChange,
  onAmenitiesChange,
  onAmenitiesTypeChange,
  resetFilters,
  sort,
  setSort,
}: FiltersModalProps) {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const titleId = useId();
  const trackEvent = useTrackEvents();

  const handleAmenitiesFiltersChange = useCallback(
    (amenities: string[]) => {
      onAmenitiesChange(amenities);
    },
    [onAmenitiesChange]
  );

  const handleAmenitiesTypeChange = useCallback(
    (andFilter: boolean) => {
      onAmenitiesTypeChange(andFilter);
    },
    [onAmenitiesTypeChange]
  );

  useEffect(() => {
    if (open) {
      trackEvent(LodgingShopTrackingEvents.hotel_viewed_filters);
    }
  }, [open, trackEvent]);

  return (
    <Dialog
      maxWidth={"sm"}
      scroll="paper"
      aria-labelledby={titleId}
      fullScreen={matchesMobile}
      open={open}
      onClose={closeFiltersModal}
      fullWidth
      hideBackdrop={false}
      BackdropProps={{
        invisible: false,
      }}
      TransitionComponent={Slide}
      TransitionProps={{
        // See https://mui.com/material-ui/transitions/#performance-amp-seo
        unmountOnExit: true,
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of TransitionProps to include direction.
        direction: "up",
        /* eslint-enable */
      }}
      className={clsx("mobile-popover-card-root", "lodging-filters-modal", {
        "full-screen": matchesMobile,
      })}
      paperfullscreen={matchesMobile ? "true" : "false"}
    >
      <DialogTitle disableTypography>
        <div className={styles.dialogTitle}>
          <Typography variant="h2" id={titleId}>
            {t("filters")}
          </Typography>
          <ActionLink
            content={
              <IconComponent
                ariaLabel={t("closeFiltersModal")}
                name={IconName.Close}
              />
            }
            onClick={closeFiltersModal}
          />
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className={styles.dialogContent}>
          {matchesMobile && sort && setSort && (
            <>
              <SortInput className="sort" value={sort} setSort={setSort} />
              <Divider />
            </>
          )}
          <Typography className={styles.priceRange} variant="h3">
            {t("priceRange")}
          </Typography>
          <PriceRange
            min={priceRange.min}
            max={priceRange.max}
            lowest={priceRange.lowest}
            highest={priceRange.highest}
            onFilterPriceRangeChange={onFilterPriceRangeChange}
          />

          <Divider />

          <Typography variant="h3">{t("starRating")}</Typography>
          <StarRating value={starRating} onChange={onStarRatingChange} />

          <Divider />

          <Typography variant="h3">{t("userRating")}</Typography>
          <UserRating value={userRating} onChange={onUserRatingChange} />

          <Divider />

          <Typography variant="h3">{t("freeCancellation")}</Typography>
          <FreeCancellation
            freeCancellation={freeCancellation}
            onFreeCancellationChange={onFreeCancellationChange}
          />

          <Divider />

          <Typography variant="h3">{t("amenities")}</Typography>
          <AmenitiesFilter
            amenities={filterBoundaries.amenities}
            andFilter={filterAmenitiesAnd}
            appliedFilters={amenities}
            onChange={handleAmenitiesFiltersChange}
            onTypeChange={handleAmenitiesTypeChange}
            sortAmenities={sortAmenities}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <ActionButton
          onClick={resetFilters}
          size="medium"
          defaultStyle="h4r-secondary"
          message={t("clearAllFilters")}
          fullWidth={matchesMobile}
        />
        <ActionButton
          onClick={closeFiltersModal}
          size="medium"
          defaultStyle="h4r-primary"
          message={t("applyAllFilters")}
          fullWidth={matchesMobile}
        />
      </DialogActions>
    </Dialog>
  );
}
