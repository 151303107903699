import { putResolve, select } from "redux-saga/effects";
import queryStringParser from "query-string";
import dayjs from "dayjs";
import {
  ITripTerminus,
  TripCategory,
  IIdFlight,
  CHFAR_ID_QUERY_PARAM,
  FlightShopType,
} from "@hopper-b2b/types";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import { actions as shopActions } from "../../shop/actions";

import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
} from "../reducer";
import {
  FetchIataCodeLookupResponse,
  fetchIataLocation,
  fetchLocationAutocomplete,
} from "@hopper-b2b/api";
import {
  LocationQuery,
  LocationQueryEnum,
  LocationResponse,
  RegionType,
} from "@b2bportal/air-shopping-api";

const flightSearchParams = [
  "origin",
  "destination",
  "originType",
  "destinationType",
  "tripCategory",
  "adultsCount",
  "childrenCount",
  "infantsInSeatCount",
  "infantsOnLapCount",
  "departureDate",
  "returnDate",
  CHFAR_ID_QUERY_PARAM,
];

export interface IFlightSearchParsedQuery {
  origin: string | null;
  destination: string | null;
  originType: string | null;
  destinationType: string | null;
  departureDate: string | null;
  returnDate: string | null;
  tripCategory: string | null;
  adultsCount: string | null;
  childrenCount: string | null;
  infantsInSeatCount: string | null;
  infantsOnLapCount: string | null;
  [CHFAR_ID_QUERY_PARAM]?: string;
}

export function* fetchSearchFromUrlSaga(action: actions.IFetchSearchFromUrl) {
  // Runs on mount of the landing/search screen and sets state based on URL params
  const state: IStoreState = yield select();
  const { queryString, history } = action;
  const modifiedParams = new URLSearchParams(queryString);
  const {
    origin: queryParamsOrigin,
    destination: queryParamsDestination,
    originType: queryParamsOriginType,
    destinationType: queryParamsDestinationType,
    departureDate: queryParamsDepartureDate,
    returnDate: queryParamsReturnDate,
    tripCategory: queryParamsTripCategory,
    adultsCount: queryParamsAdultsCount,
    childrenCount: queryParamsChildrenCount,
    infantsInSeatCount: queryParamsInfantsInSeatCount,
    infantsOnLapCount: queryParamsInfantsOnLapCount,
  }: IFlightSearchParsedQuery = parseQueryString(queryString);

  if (queryParamsOriginType) {
    if (validateParam(queryParamsOriginType, "originType")) {
      yield putResolve(
        actions.setOriginType(queryParamsOriginType as RegionType)
      );
    } else {
      modifiedParams.delete("originType");
    }
  }
  if (queryParamsDestinationType) {
    if (validateParam(queryParamsDestinationType, "destinationType")) {
      yield putResolve(
        actions.setDestinationType(queryParamsDestinationType as RegionType)
      );
    } else {
      modifiedParams.delete("destinationType");
    }
  }
  if (queryParamsOrigin) {
    if (validateParam(queryParamsOrigin, "origin")) {
      const originLocation = yield fetchLocation(
        queryParamsOrigin,
        action.isIataEnabled,
        queryParamsOriginType &&
          validateParam(queryParamsOriginType, "originType")
          ? (queryParamsOriginType as RegionType)
          : null
      );
      yield putResolve(actions.setOrigin(originLocation as ITripTerminus));
    } else {
      modifiedParams.delete("origin");
    }
  }
  if (queryParamsDestination) {
    if (validateParam(queryParamsDestination, "destination")) {
      const destinationLocation = yield fetchLocation(
        queryParamsDestination,
        action.isIataEnabled,
        queryParamsDestinationType &&
          validateParam(queryParamsDestinationType, "destinationType")
          ? (queryParamsDestinationType as RegionType)
          : null
      );
      yield putResolve(
        actions.setDestination(destinationLocation as ITripTerminus)
      );
    } else {
      modifiedParams.delete("destination");
    }
  }
  if (queryParamsTripCategory) {
    if (validateParam(queryParamsTripCategory, "tripCategory")) {
      yield putResolve(
        actions.setTripCategory(queryParamsTripCategory as TripCategory)
      );
    } else {
      modifiedParams.delete("tripCategory");
    }
  }
  if (queryParamsDepartureDate) {
    if (validateParam(queryParamsDepartureDate, "departureDate")) {
      yield putResolve(
        actions.setDepartureDate(dayjs(queryParamsDepartureDate).toDate())
      );
    } else {
      modifiedParams.delete("departureDate");
    }
  }
  if (queryParamsReturnDate) {
    if (validateParam(queryParamsReturnDate, "returnDate")) {
      yield putResolve(
        actions.setReturnDate(dayjs(queryParamsReturnDate).toDate())
      );
    } else {
      modifiedParams.delete("returnDate");
    }
  }
  const validPassengerParams = {
    adultsCount:
      queryParamsAdultsCount &&
      validateParam(queryParamsAdultsCount, "adultsCount"),
    childrenCount:
      queryParamsChildrenCount &&
      validateParam(queryParamsChildrenCount, "childrenCount", state),
    infantsInSeatCount:
      queryParamsInfantsInSeatCount &&
      validateParam(queryParamsInfantsInSeatCount, "infantsInSeatCount", state),
    infantsOnLapCount:
      queryParamsInfantsOnLapCount &&
      validateParam(queryParamsInfantsOnLapCount, "infantsOnLapCount", state),
  };

  const updatePassengerState =
    validPassengerParams.adultsCount ||
    validPassengerParams.childrenCount ||
    validPassengerParams.infantsInSeatCount ||
    validPassengerParams.infantsOnLapCount;

  if (updatePassengerState) {
    yield putResolve(
      actions.setPassengerCounts({
        adultsCount: validPassengerParams.adultsCount
          ? parseInt(queryParamsAdultsCount || "0", 10)
          : 1,
        childrenCount: validPassengerParams.childrenCount
          ? parseInt(queryParamsChildrenCount || "0", 10)
          : 0,
        infantsInSeatCount: validPassengerParams.infantsInSeatCount
          ? parseInt(queryParamsInfantsInSeatCount || "0", 10)
          : 0,
        infantsOnLapCount: validPassengerParams.infantsOnLapCount
          ? parseInt(queryParamsInfantsOnLapCount || "0", 10)
          : 0,
      })
    );
  }

  if (
    queryParamsAdultsCount &&
    !validateParam(queryParamsAdultsCount, "adultsCount")
  ) {
    modifiedParams.delete("adultsCount");
    modifiedParams.delete("childrenCount");
    modifiedParams.delete("infantsInSeatCount");
    modifiedParams.delete("infantsOnLapCount");
  }
  if (
    queryParamsChildrenCount &&
    !validateParam(queryParamsChildrenCount, "childrenCount")
  ) {
    modifiedParams.delete("childrenCount");
  }
  if (
    queryParamsInfantsInSeatCount &&
    !validateParam(queryParamsInfantsInSeatCount, "infantsInSeatCount")
  ) {
    modifiedParams.delete("infantsInSeatCount");
  }
  if (
    queryParamsInfantsOnLapCount &&
    !validateParam(queryParamsInfantsOnLapCount, "infantsOnLapCount")
  ) {
    modifiedParams.delete("infantsOnLapCount");
  }

  if (modifiedParams.get(CHFAR_ID_QUERY_PARAM)) {
    yield putResolve(
      shopActions.setChfarId(modifiedParams.get(CHFAR_ID_QUERY_PARAM))
    );
    yield putResolve(
      shopActions.setFlightShopType(FlightShopType.CHFAR_EXERCISE)
    );
  }

  // Remove invalid parameter names
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  Object.keys(parsedQueryStringPrimitive).forEach((key) => {
    if (!flightSearchParams.includes(key)) {
      modifiedParams.delete(key);
    }
  });

  history.replace({ search: modifiedParams.toString() });

  return {
    origin: queryParamsOrigin,
    destination: queryParamsDestination,
    originType: queryParamsOriginType,
    destinationType: queryParamsDestinationType,
    tripCategory: queryParamsTripCategory,
    adultsCount: queryParamsAdultsCount,
    childrenCount: queryParamsChildrenCount,
    infantsInSeatCount: queryParamsInfantsInSeatCount,
    infantsOnLapCount: queryParamsInfantsOnLapCount,
  };
}

export const parseQueryString = (
  queryString: string
): IFlightSearchParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    origin: parsedQueryStringPrimitive.origin as string | null,
    destination: parsedQueryStringPrimitive.destination as string | null,
    originType: parsedQueryStringPrimitive.originType as string | null,
    destinationType: parsedQueryStringPrimitive.destinationType as
      | string
      | null,
    departureDate: parsedQueryStringPrimitive.departureDate as string | null,
    returnDate: parsedQueryStringPrimitive.returnDate as string | null,
    tripCategory: parsedQueryStringPrimitive.tripCategory as string | null,
    adultsCount: parsedQueryStringPrimitive.adultsCount as string | null,
    childrenCount: parsedQueryStringPrimitive.childrenCount as string | null,
    infantsInSeatCount: parsedQueryStringPrimitive.infantsInSeatCount as
      | string
      | null,
    infantsOnLapCount: parsedQueryStringPrimitive.infantsOnLapCount as
      | string
      | null,
  };
};

export const validateParam = (
  paramValue: string,
  paramName: keyof IFlightSearchParsedQuery,
  state?: IStoreState
): boolean => {
  if (paramName === "origin" || paramName === "destination") {
    return typeof paramValue === "string" && paramValue.length === 3;
  }
  if (paramName === "originType" || paramName === "destinationType") {
    return paramValue === RegionType.airport || paramValue === RegionType.city;
  }
  if (paramName === "departureDate" || paramName === "returnDate") {
    return typeof paramValue === "string" && dayjs(paramValue).isValid();
  }
  if (paramName === "tripCategory") {
    return (
      paramValue === TripCategory.ROUND_TRIP ||
      paramValue === TripCategory.ONE_WAY
    );
  }
  if (paramName === "adultsCount") {
    return parseInt(paramValue, 10) <= 6;
  }

  if (
    paramName === "childrenCount" ||
    paramName === "infantsInSeatCount" ||
    paramName === "infantsOnLapCount"
  ) {
    const adultsCount = state ? getAdultsCount(state) : 1;
    if (paramName === "childrenCount") {
      return adultsCount > 0 && adultsCount + parseInt(paramValue, 10) <= 6;
    } else {
      const childrenCount = state ? getChildrenCount(state) : 0;
      if (paramName === "infantsInSeatCount") {
        return (
          adultsCount > 0 &&
          adultsCount + childrenCount + parseInt(paramValue, 10) <= 6
        );
      } else if (paramName === "infantsOnLapCount") {
        const infantsInSeatCount = state ? getInfantsInSeatCount(state) : 0;
        return (
          adultsCount > 0 &&
          adultsCount +
            childrenCount +
            infantsInSeatCount +
            parseInt(paramValue, 10) <=
            6
        );
      }
    }
  }
  return false;
};

function* fetchLocation(
  locationQuery: string,
  isIataCodeLookup: boolean,
  regionType?: RegionType
) {
  if (isIataCodeLookup) {
    const locationResult: FetchIataCodeLookupResponse = yield fetchIataLocation(
      {
        iataCode: locationQuery,
      }
    );
    const originLookupResult = regionType
      ? locationResult[regionType]
      : locationResult.airport || locationResult.city;
    return originLookupResult;
  } else {
    const requestBody: LocationQuery = {
      l: locationQuery,
      LocationQuery: LocationQueryEnum.Label,
    };
    const originMatches: LocationResponse = yield fetchLocationAutocomplete(
      requestBody
    );
    const originMatch =
      originMatches.categories[0].results.find(
        (o) => (o.id as IIdFlight).code.code === locationQuery
      ) ||
      originMatches.categories[1].results.find(
        (o) => (o.id as IIdFlight).code.code === locationQuery
      );
    return originMatch;
  }
}
