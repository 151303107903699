import { Checkout, ParentState } from "@hopper-b2b/checkout";
import {
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CheckoutProps,
  CHFAR_ID_QUERY_PARAM,
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  PRICE_FREEZE_ID_QUERY_PARAM,
} from "@hopper-b2b/types";
import {
  getSliceIndex,
  useFeatureFlagsContext,
  useSessionContext,
} from "@hopper-b2b/utilities";
import { useMemo } from "react";

import { useLocation } from "react-router";
import { FlightsCheckoutConnectorProps } from "./container";
import { PATH_PRICE_FREEZE_PURCHASE } from "../../utils/urlPaths";
import { useSelector } from "react-redux";
import { getCfarOffers, getSelectedCfarOffer } from "../cfar/reducer";
import { getChfarOffers, getSelectedChfarOffer } from "../chfar/reducer";
import {
  getDisruptionOffers,
  getSelectedDisruptionOffers,
} from "../disruption/reducer";
import { getSelectedCreditOffer, getSelectedOffer } from "../wallet/reducer";
import { priceDropProtectionCandidateIdSelector } from "../shop/reducer";

interface FlightsCheckoutProps
  extends CheckoutProps,
    FlightsCheckoutConnectorProps {}

export const FlightsCheckout = ({
  actions,
  airports,
  airlines,
  clientAssets,
  Component,
  context,
  departureDate,
  destination,
  destinationCountryCode,
  getInitialContext,
  guards,
  onCleanUp,
  onLoad,
  onPathnameChange,
  onStateValueChange,
  origin,
  originCountryCode,
  returnDate,
  selectedTrip,
  services,
  sessionInfo,
  shopPricingInfo,
  stateMachine,
  tripCategory,
  tripDetails,
  tripResultEventProperties,
  validateContext,
  cheapestPriceFreezeOfferData,
  offers,
}: FlightsCheckoutProps) => {
  const featureFlags = useFeatureFlagsContext();

  const queryString = useLocation().search;
  const queryParams = useMemo(
    () => new URLSearchParams(queryString),
    [queryString]
  );
  const priceFreezeId = useMemo(
    () => queryParams.get(PRICE_FREEZE_ID_QUERY_PARAM),
    [queryParams]
  );
  const cfarQuoteId = useMemo(
    () => queryParams.get(CFAR_OFFER_QUOTE_ID_QUERY_PARAM),
    [queryParams]
  );
  const chfarQuoteId = useMemo(
    () => queryParams.get(CHFAR_OFFER_QUOTE_ID_QUERY_PARAM),
    [queryParams]
  );

  const chfarId = useMemo(
    () => queryParams.get(CHFAR_ID_QUERY_PARAM),
    [queryParams]
  );

  const departureSliceIndex = getSliceIndex(true, tripDetails);
  const departureSlice = tripDetails?.slices[departureSliceIndex];

  const returnSliceIndex = getSliceIndex(false, tripDetails);
  const hasReturnFlight = returnSliceIndex !== -1;

  const returnSlice = hasReturnFlight
    ? tripDetails?.slices[returnSliceIndex]
    : null;

  const departureLabel = useMemo(
    () =>
      airports[departureSlice?.destinationCode]
        ? airports[departureSlice?.destinationCode].cityName
        : departureSlice?.destinationName,
    [airports, departureSlice?.destinationCode, departureSlice?.destinationName]
  );

  const returnLabel = useMemo(
    () =>
      airports[returnSlice?.destinationCode || ""]
        ? airports[returnSlice?.destinationCode || ""]?.cityName
        : returnSlice?.destinationName,
    [airports, returnSlice?.destinationCode, returnSlice?.destinationName]
  );

  const cfarOffers = useSelector(getCfarOffers);
  const chfarOffers = useSelector(getChfarOffers);

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  const disruptionOffers = useSelector(getDisruptionOffers);
  const selectedDisruptionOffers = useSelector(getSelectedDisruptionOffers);

  const offer = useSelector(getSelectedOffer);
  const creditOffer = useSelector(getSelectedCreditOffer);

  const { countryCode, phoneNumber, email } = useSessionContext();

  const priceDropProtectionCandidateId = useSelector(
    priceDropProtectionCandidateIdSelector
  );

  const contextToInitialize = useMemo(() => {
    return {
      ...context,
      sessionInfo: {
        ...(context?.sessionInfo || {}),
        ...sessionInfo,
      },
      [ParentState.contactInformation]: {
        contactInfo: {
          phoneNumber: phoneNumber,
          email: email,
          countryCode: countryCode,
        },
      },
      flightShop: {
        ...(context?.flightShop || {}),
        tripDetails,
        selectedTrip,
        shopPricingInfo,
        departureLabel,
        airports,
        airlines,
        returnLabel,
        tripResultEventProperties,
        offers,
      },
      flightSearch: {
        ...(context?.flightSearch || {}),
        tripCategory,
        origin,
        departureDate,
        destination,
        returnDate,
        originCountryCode,
        destinationCountryCode,
      },
      featureFlags,
      [ParentState.priceFreeze]: {
        priceFreezeId: priceFreezeId,
        priceFreezePurchaseParams: {
          urlPrefix: PATH_PRICE_FREEZE_PURCHASE,
        },
        cheapestPriceFreezeOfferData,
      },
      [ParentState.cancelForAnyReason]: {
        cfarQuoteId,
        cfarOffers,
        selectedOffer: selectedCfarOffer,
      },
      [ParentState.changeForAnyReason]: {
        chfarQuoteId,
        chfarOffers,
        selectedOffer: selectedChfarOffer,
      },
      [ParentState.changeForAnyReasonDiscount]: {
        chfarId: chfarId,
      },
      [ParentState.disruption]: {
        selectedOffers: selectedDisruptionOffers,
        offersResponse: disruptionOffers,
      },
      //TODO: populate from state or queryParams, when Wallet is added to shop
      [ParentState.wallet]: {
        offer: offer,
        creditOffer: creditOffer,
      },
      [ParentState.priceDrop]: {
        candidateId: priceDropProtectionCandidateId,
      },
    };
  }, [
    context,
    sessionInfo,
    phoneNumber,
    email,
    countryCode,
    tripDetails,
    selectedTrip,
    shopPricingInfo,
    departureLabel,
    airports,
    airlines,
    returnLabel,
    tripResultEventProperties,
    offers,
    tripCategory,
    origin,
    departureDate,
    destination,
    returnDate,
    originCountryCode,
    destinationCountryCode,
    featureFlags,
    priceFreezeId,
    cheapestPriceFreezeOfferData,
    cfarQuoteId,
    cfarOffers,
    selectedCfarOffer,
    chfarQuoteId,
    chfarOffers,
    selectedChfarOffer,
    chfarId,
    selectedDisruptionOffers,
    disruptionOffers,
    offer,
    creditOffer,
    priceDropProtectionCandidateId,
  ]);

  return (
    <Checkout
      actions={actions}
      Component={Component}
      context={contextToInitialize}
      guards={guards}
      onCleanUp={onCleanUp}
      onLoad={onLoad}
      services={services}
      stateMachine={stateMachine}
      getInitialContext={getInitialContext}
      clientAssets={clientAssets}
      onStateValueChange={onStateValueChange}
      onPathnameChange={onPathnameChange}
      validateContext={validateContext}
    />
  );
};
