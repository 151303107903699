// TODO: BASE_PATH_HOME might be better removed in future; it probably belongs to FlightModuleProps in Redmond
export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/flights/";
export const PATH_HOME_SEARCH = PATH_HOME + "search/";
export const PATH_SHOP = PATH_HOME + "shop/";
export const PATH_BOOK = PATH_HOME + "book/";
export const PATH_EXCHANGE = PATH_HOME + "exchange/";
export const PATH_FREEZE = PATH_HOME + "freeze/";
export const PATH_BOOK_CONFIRMATION = PATH_BOOK + "confirm/";
export const PATH_FREEZE_CONFIRMATION = PATH_FREEZE + "confirm/";
export const PATH_PRICE_FREEZE_PURCHASE = PATH_FREEZE + "purchase/";
export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_PRICE_DROP_PROTECTION = `${PATH_TERMS}price-drop-protection/`;
export const PATH_PRICE_PREDICTION_PRICE_WATCH = `${PATH_TERMS}price-prediction-price-watch/`;
export const PATH_BEST_PRICE_GUARANTEE = `${PATH_TERMS}best-price-guarantee/`;
export const PATH_PRICE_FREEZE = `${PATH_TERMS}frozen-prices/`;
export const PATH_PRICE_FREEZE_OVERVIEW = `${PATH_FREEZE}overview/`;
export const PATH_TRIPS = `${BASE_PATH_HOME}trips/`;
