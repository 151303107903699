import { useI18nContext } from "@hopper-b2b/i18n";
import { Button } from "@material-ui/core";
import Freeze from "./freeze.svg";
import "./styles.scss";

export interface IPriceFreezeReviewEntry {
  tripId: string;
  fareId: string;
  onPriceFreezeClick: (tripId: string, fareId: string) => void;
}

export const PriceFreezeReviewEntry = (props: IPriceFreezeReviewEntry) => {
  const { t } = useI18nContext();

  const { tripId, fareId, onPriceFreezeClick } = props;

  return (
    <div className="price-freeze-trip-entry-container">
      <div className="price-freeze-trip-entry-info">
        <div className="price-freeze-trip-entry-info-icon">
          <img src={Freeze} alt="" />
        </div>
        <div>
          <div className="price-freeze-trip-entry-title">
            <strong>{t("priceFreezeEntry.notReadyToBook")}</strong>
          </div>
          <div className="price-freeze-trip-entry-subtitle">
            {t("priceFreezeEntry.cardSubtitle")}
          </div>
        </div>
      </div>
      <Button
        className="price-freeze-trip-entry-cta"
        onClick={() => onPriceFreezeClick(tripId, fareId)}
      >
        {`${t("priceFreezeEntry.freezePrice")}`}
      </Button>
    </div>
  );
};
