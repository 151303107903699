import { Divider } from "@material-ui/core";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ContactInformation } from "@hopper-b2b/types";
import { ReviewSummaryRow } from "../ReviewSummaryRow";
import { Slot } from "../Slots";
import "./styles.scss";

export interface ContactSummaryRowProps {
  contactInfo: ContactInformation;
  iconSrc?: string;
  onEdit?: (type?: "phone-number" | "email") => void;
  hideIcon?: boolean;
  hidePhoneNumberEdit?: boolean;
  hideEmailEdit?: boolean;
}

export const ContactSummaryRow = ({
  contactInfo,
  iconSrc,
  onEdit,
  hideIcon,
  hidePhoneNumberEdit = false,
  hideEmailEdit = false,
}: ContactSummaryRowProps) => {
  const { t } = useI18nContext();
  return (
    <div
      className={clsx("mobile-itinerary-cards-section", "contact-summary-row")}
    >
      <ReviewSummaryRow
        title={t("reviewContactInformation")}
        iconSrc={iconSrc}
        hideIcon={hideIcon}
      >
        <div className="contact-summary-row-content" data-dd-privacy="mask">
          <div className="contact-summary-row-description">
            {t("reviewContactInfoSubtitle")}
          </div>
          <div className="contact-summary-row-section contact-summary-row-phone">
            <div className="contact-summary-row-info">
              <p className="contact-summary-row-label">{t("phoneNumber")}</p>
              <p className="contact-summary-row-value">
                {`${contactInfo.countryCode} ${contactInfo.phoneNumber}`}
              </p>
            </div>
            {onEdit && !hidePhoneNumberEdit ? (
              <Slot
                id="edit"
                className="contact-summary-row-edit"
                onClick={() => onEdit("phone-number")}
              />
            ) : null}
          </div>
          <div className="contact-info-divider" />
          <div className="contact-summary-row-section contact-summary-row-email">
            <div className="contact-summary-row-info">
              <p className="contact-summary-row-label">{t("email")}</p>
              <p className="contact-summary-row-value">{contactInfo.email}</p>
            </div>
            {onEdit && !hideEmailEdit ? (
              <Slot
                id="edit"
                className="contact-summary-row-edit"
                onClick={() => onEdit("email")}
              />
            ) : null}
          </div>
        </div>
      </ReviewSummaryRow>
      <Divider className="mobile-review-flight-book-divider" />
    </div>
  );
};

export default ContactSummaryRow;
