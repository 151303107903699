import clsx from "clsx";
import { AmenityIcon } from "@hopper-b2b/ui";
import { Typography } from "@material-ui/core";
import { AmenityStatus } from "@b2bportal/lodging-api";
import styles from "./AmenitiesList.module.scss";

interface Props {
  amenities: AmenityStatus[];
  className?: string;
}

export function AmenitiesList({ className, amenities }: Props) {
  return (
    <ul className={clsx(styles.AmenitiesList, className)}>
      {amenities?.map(({ amenity, displayName }) => (
        <Typography key={displayName} component="li" variant="body2">
          <AmenityIcon amenity={amenity} alt="" />
          {displayName}
        </Typography>
      ))}
    </ul>
  );
}
