import { StateValue } from "xstate";
import {
  FlightContextV2,
  FlightSelectors,
  LodgingContext,
  LodgingSelectors,
  ParentState,
  getChildState,
  getNestedChildState,
} from "@hopper-b2b/checkout";
import { PaymentContext } from "./context";
import {
  CreditPaymentMethod,
  DebitPaymentMethod,
  NubankPaymentMethod,
} from "./api/getPaymentMethods";
import { LodgingMachineContext } from "../../../modules/lodging-checkout/types";

export const getPaymentMethods = ({ context }: { context: PaymentContext }) =>
  context[ParentState.payment].paymentMethods;

export const getCreditPaymentMethod = (state: { context: PaymentContext }) => {
  const paymentMethods = getPaymentMethods(state);
  return paymentMethods.find(
    (p) =>
      (p as CreditPaymentMethod).paymentMethodType ===
      NubankPaymentMethod.NuCredit
  ) as CreditPaymentMethod | undefined;
};

export const getCreditInstallments = (state: { context: PaymentContext }) => {
  const creditMethod = getCreditPaymentMethod(state);
  return creditMethod?.installmentPlans || [];
};

export const getMaximumCreditInstallment = (state: {
  context: PaymentContext;
}) => {
  const installmentPlans = getCreditInstallments(state);
  const installments =
    installmentPlans?.map?.((i) => i.numberOfInstallments) || [];
  return Math.max(...installments);
};

export const getDebitPaymentMethod = (state: { context: PaymentContext }) => {
  const paymentMethods = getPaymentMethods(state);
  return paymentMethods.find(
    (p) =>
      (p as DebitPaymentMethod).paymentMethodType ===
      NubankPaymentMethod.NuDebit
  );
};

export const getSelectedPaymentMethod = (state: {
  context: PaymentContext;
}) => {
  return state.context[ParentState.payment].selectedPaymentMethod;
};

export const getSelectedCreditInstallment = (state: {
  context: PaymentContext;
}) => state.context[ParentState.payment].selectedCreditInstallment;

export const getEnrollmentRedirectUrl = (state: { context: PaymentContext }) =>
  state.context[ParentState.payment].redirectUrl;

export const getIsLoading = (state: StateValue): boolean => {
  const childState = getChildState(state);
  const nestedChildState = getNestedChildState(state);

  switch (childState) {
    case "track":
    case "preAuthCheck":
      return true;

    case "selectCreditOrDebit":
      switch (nestedChildState) {
        case "fetch":
          return true;
        case "idle":
        default:
          return false;
      }

    case "confirm":
    case "credit":
    case "error":
    default:
      return false;
  }
};

export const getPaymentError = (state: { context: PaymentContext }) =>
  state.context[ParentState.payment].error;

export const getDestinationCityName = (state: { context: PaymentContext }) =>
  (state.context as LodgingMachineContext).lodgingShop
    ? LodgingSelectors.getLodgingCityName({
        context: state.context as unknown as LodgingContext,
      })
    : FlightSelectors.getDestinationCityName({
        context: state.context as unknown as FlightContextV2,
      });
