import { useState, useEffect } from "react";
import { FlightDetailsCard, MixedCabinToolTip } from "@hopper-b2b/ui";
import {
  getSliceIndex,
  getIsMixedClass,
  getPlusDays,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { FareDetails } from "@hopper-b2b/types";
import { Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { getEmptyRestrictionsText } from "@hopper-b2b/utilities";

import { FlightDetailsConnectorProps } from "./container";

interface IFlightDetailsProps extends FlightDetailsConnectorProps {
  isOutgoing: boolean;
  selectedFareId: string;
  onFareClick: (sliceId: string, fare?: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
}

enum ratings {
  BASIC,
  STANDARD,
  ENHANCED,
  PREMIUM,
  LUXURY,
}

export const FlightDetails = ({
  tripDetails,
  outgoingFareRating,
  isOutgoing,
  selectedFareId,
  onFareClick,
  onAlgomerchClick,
  rewardsKey,
  departureDate,
  returnDate,
  airports,
}: IFlightDetailsProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );

  useEffect(() => {
    if (selectedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find((f) => f.id === selectedFareId)
      );
    }
  }, [selectedFareId]);

  useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (!tripDetails) return null;
  const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket) {
      return {
        id: fare.id,
        message: t("combinationFlightWarning"),
        tooltipCopy: t("combinationFlightTooltip"),
      };
    } else {
      return null;
    }
  });

  const renderHeader = (
    isOutgoing: boolean,
    location: string,
    date: Date | null
  ) => {
    if (!location || !date) return undefined;
    return (
      <Typography variant="body1" className="flight-details-header">
        <I18nMarkup
          tKey={
            isOutgoing
              ? "flightShopReview.outboundCardHeader"
              : "flightShopReview.returnCardHeader"
          }
          replacements={{
            location,
            date: dayjs(date).format("ddd, MMM D"),
          }}
        />
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);

  return (
    <FlightDetailsCard
      className={"b2b"}
      selectedFareId={selectedFareId}
      tripDetails={tripDetails}
      outgoingFareRating={isOutgoing ? undefined : outgoingFareRating}
      isOutgoing={isOutgoing}
      getEmptyRestrictionsText={getEmptyRestrictionsText}
      onFareClick={(fareId: string) =>
        onFareClick(
          slice.id,
          tripDetails.fareDetails.find((f) => f.id === fareId)
        )
      }
      fareNotice={hackerFareNotice}
      onAlgomerchClick={onAlgomerchClick}
      rewardsKey={rewardsKey}
      isMobile={matchesMobile}
      plusDays={getPlusDays(slice!)}
      header={
        isOutgoing
          ? renderHeader(
              isOutgoing,
              airports[tripDetails.slices[firstSliceIndex].destinationCode]
                ? airports[tripDetails.slices[firstSliceIndex].destinationCode]
                    .cityName
                : tripDetails.slices[firstSliceIndex].destinationName,
              departureDate
            )
          : renderHeader(
              isOutgoing,
              airports[tripDetails.slices[secondSliceIndex].destinationCode]
                ? airports[tripDetails.slices[secondSliceIndex].destinationCode]
                    .cityName
                : tripDetails.slices[secondSliceIndex].destinationName,
              returnDate
            )
      }
      isMixedCabinClass={isMixedCabinClass}
    />
  );
};
