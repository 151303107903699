import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { ButtonWrap, Footer as GenericFooter } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import config from "../../utils/config";
import * as constants from "./constants";
import "./styles.scss";

export const B2BGenericFooter = ({
  className,
  openSupportChat,
}: {
  className?: string;
  openSupportChat?: () => void;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const renderLinks = () => {
    const renderLink = (texts: string[], urlLink: string) => (
      <ButtonWrap
        onClick={() => {
          // TODO: Are there links we need to render in the footer?
        }}
      >
        <div className="button-text-wrapper">
          {texts.map((text, index) => (
            <Typography key={index} className="button-text" variant="subtitle2">
              {text}
            </Typography>
          ))}
        </div>
      </ButtonWrap>
    );

    const links = [
      {
        texts: [t("termsAndConditions")],
        urlLink: constants.TERMS_AND_CONDITIONS_LINK,
      },
      { texts: [t("privacyPolicy")], urlLink: constants.PRIVACY_LINK },
    ];

    return (
      <div className="links-wrapper">
        {links.map((link, index) => {
          return (
            <Fragment key={index}>
              {renderLink(link.texts, link.urlLink)}
            </Fragment>
          );
        })}
        {openSupportChat && (
          <ButtonWrap onClick={openSupportChat}>
            <div className="button-text-wrapper">
              <Typography className="button-text" variant="subtitle2">
                {t("customerSupport")}
              </Typography>
            </div>
          </ButtonWrap>
        )}
      </div>
    );
  };

  return (
    <GenericFooter
      className={className}
      isMobile={matchesMobile}
      left={<p>{constants.LEGAL}</p>}
      right={<p className="app-version">{config.REACT_APP_VERSION}</p>}
      {...(matchesMobile ? { top: renderLinks() } : { center: renderLinks() })}
    />
  );
};
