import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  B2B_PORTAL_ENDSESSION_PATH,
  DISPLAY_COVID_BANNER,
} from "@hopper-b2b/types";
import { ActionLink, IconComponent, IconName } from "@hopper-b2b/ui";
import { LanguageSelect, useI18nContext } from "@hopper-b2b/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { PATH_FLIGHTS, PATH_HOME, PATH_TRIPS } from "../../utils/urlPaths";
import "./styles.scss";
import { HeaderLogos } from "../Header/components/MobileHeader";
import { FAQS_LINK } from "./constants";
import { useNavigate } from "@hopper-b2b/utilities";

interface IStyledMainNavLinkProps {
  label: string | JSX.Element;
  path: string;
}

interface ConnectedMainNavLinkProps extends IStyledMainNavLinkProps {
  onClose: () => void;
}

const StyledMainNavLink = ({
  label,
  path,
  onClose,
}: ConnectedMainNavLinkProps) => {
  const navigate = useNavigate();
  return (
    <ActionLink
      className={"b2b-main-nav-link"}
      content={<Typography variant="h3">{label}</Typography>}
      onClick={() => {
        navigate(path);
        onClose();
      }}
    />
  );
};

interface StyledSubNavLinkProps {
  onClose: () => void;
  label: string | JSX.Element;
  onClick: () => void;
}

const StyledSubNavLink = ({
  label,
  onClick,
  onClose,
}: StyledSubNavLinkProps) => {
  return (
    <ActionLink
      className={"b2b-sub-nav-link"}
      content={<Typography variant="h5">{label}</Typography>}
      onClick={() => {
        onClick();
        onClose();
      }}
    />
  );
};

interface IMobileHamburgerMenuProps {
  onClose: () => void;
  openSupportChat?: () => void;
}

const MobileHamburgerMenu = ({
  onClose,
  openSupportChat,
}: IMobileHamburgerMenuProps) => {
  const navigate = useNavigate();
  const { t, language, brand } = useI18nContext();

  const ConnectedMainStyledNavLink = (props: IStyledMainNavLinkProps) => (
    <StyledMainNavLink {...props} onClose={onClose} />
  );

  const headerLogoOnClick = () => {
    onClose();
    navigate(PATH_HOME);
  };

  return (
    <Box className="hamburger-menu">
      <Box className="hamburger-top-section">
        <div className={clsx("header-logo")}>
          <button
            className={clsx("header-logo-button")}
            onClick={headerLogoOnClick}
          >
            <HeaderLogos />
          </button>
        </div>
        <ActionLink
          className="hamburger-close-button"
          content={
            <IconComponent
              ariaLabel="Close button icon"
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          onClick={onClose}
        />
      </Box>
      <Box className="hamburger-mid-section">
        <ConnectedMainStyledNavLink
          label={
            <>
              <div className="book-flights-link">
                {t("mobileMenuBookFlights")}
              </div>
              <FontAwesomeIcon
                className="arrow-icon"
                icon={faChevronRight as IconProp}
              />
            </>
          }
          path={PATH_FLIGHTS}
        />
      </Box>
      <Box className="hamburger-bottom-section">
        <Box className="hamburger-sub-links">
          {/* TODO: Uncommented for Uber portal for My Trips testing purposes */}
          <StyledSubNavLink
            label={t("mobileMenuMyTrips")}
            onClick={() => {
              navigate(PATH_TRIPS);
            }}
            onClose={onClose}
          />
          <StyledSubNavLink
            label={t("faqCta")}
            onClick={() => window.open(FAQS_LINK, "_blank")}
            onClose={onClose}
          />
        </Box>
        <ActionLink
          className="hamburger-menu-logout"
          content={
            <Typography variant="h6">{t("mobileMenuLogOut")}</Typography>
          }
          onClick={() => {
            sessionStorage.removeItem(DISPLAY_COVID_BANNER);
            navigate(B2B_PORTAL_ENDSESSION_PATH);
          }}
        />
      </Box>
      <LanguageSelect
        defaultLocale={language}
        supportedLanguages={brand?.supportedLanguages}
      />
    </Box>
  );
};

export default MobileHamburgerMenu;
