import { ParentState } from "@hopper-b2b/checkout";
import { FlightPassengerSelectorsV2 } from "@hopper-b2b/checkout";
import { ViewPaymentProperties } from "@hopper-b2b/types";
import { sum } from "lodash-es";
import { State } from "xstate";
import { FlightMachineContext } from "../types";
import {
  getChooseTravelerProperties,
  getPricing,
  getSelectedTripDetails,
} from "./common";

type PaymentStateType = State<FlightMachineContext>;
type PaymentStateTypeWithoutValue = Pick<PaymentStateType, "context">;

export const getPaymentSessionToken = (state: PaymentStateTypeWithoutValue) => {
  return state.context[ParentState.payment].paymentSessionToken;
};

export const getPaymentQuoteToken = (state: PaymentStateTypeWithoutValue) => {
  return state.context[ParentState.payment].paymentQuoteToken;
};

export const getPaymentVisited = (state: PaymentStateTypeWithoutValue) =>
  state.context[ParentState.payment].visited;

export const getViewPaymentProperties = ({
  context,
}): ViewPaymentProperties => {
  const chooseTravelerProperties = getChooseTravelerProperties({ context });
  const tripPricing = getPricing({ context });
  const totalSelectedPassengers =
    FlightPassengerSelectorsV2.getAllSelectedPassengerIdsParent({
      context,
    }).length;
  const totalStops = sum(
    getSelectedTripDetails({ context }).slices.map((s) => s.stops)
  );

  return {
    ...chooseTravelerProperties,
    adult_price_total_usd: 0, // No USD prices for nubank
    base_total_usd: 0, // No USD prices for nubank
    price_per_pax_usd: 0, // No USD prices for nubank
    subtotal_usd: 0, // No USD prices for nubank
    total_price_usd: 0, // No USD prices for nubank
    charged_PQ_currency:
      tripPricing?.totalPricing.total.fiat.currencyCode || "BRL",
    total_price: tripPricing?.totalPricing.total.fiat.value || 0,
    pax_total: totalSelectedPassengers,
    selected_pax_total: totalSelectedPassengers,
    total_stops: totalStops,
  };
};
