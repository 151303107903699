import { CartQuoteState, ChildMachineConfig } from "../../../types";
import { ServiceTypes } from "./services";
import { ActionTypes } from "./actions";
import { CartQuoteEventType } from "./events";
import { GuardTypes } from "./guards";
import { CartState } from "../types";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: CartState.cartQuote,
  initial: CartQuoteState.route,
  preserveActionOrder: true,
  states: {
    [CartQuoteState.route]: {
      entry: ActionTypes.setCartQuoteCallStateInProcess,
      always: [
        {
          cond: GuardTypes.shouldDiscardCart,
          target: CartQuoteState.discard,
        },
        { target: CartQuoteState.schedule },
      ],
    },
    [CartQuoteState.discard]: {
      invoke: {
        src: ServiceTypes.discardQuotedCartService,
        onDone: {
          target: CartQuoteState.schedule,
          actions: ActionTypes.clearCartContext,
        },
        onError: {
          target: CartQuoteState.schedule,
          actions: ActionTypes.clearCartContext,
        },
      },
    },
    [CartQuoteState.schedule]: {
      invoke: {
        src: ServiceTypes.schedulePriceQuoteService,
        onDone: {
          actions: ActionTypes.setScheduleQuote,
          target: CartQuoteState.polling,
        },
        onError: {
          actions: [
            ActionTypes.setSchedulePriceQuoteError,
            ActionTypes.setCartQuoteCallStateFailed,
          ],
          target: CartQuoteState.error,
        },
      },
    },
    [CartQuoteState.polling]: {
      invoke: {
        src: ServiceTypes.pollPriceQuoteService,
        onDone: {
          actions: [
            ActionTypes.setPollQuote,
            ActionTypes.setSeatMapAvailable,
            ActionTypes.setCartQuoteCallStateSuccess,
          ],
          target: config.nextState,
        },
        onError: {
          actions: [
            ActionTypes.setPollQuoteError,
            ActionTypes.setCartQuoteCallStateFailed,
          ],
          target: CartQuoteState.error,
        },
      },
    },
    [CartQuoteState.error]: {
      ...(config.failedState ? { always: { target: config.failedState } } : {}),
    },
  },
  on: {
    [CartQuoteEventType.CLEAR_CART_QUOTE_ERROR]: {
      actions: ActionTypes.clearCartQuoteError,
    },
    [CartQuoteEventType.RETRY_QUOTE]: {
      target: `#${config.parentMachineId}.${CartState.cartQuote}.${CartQuoteState.route}`,
      actions: [ActionTypes.setQuoteRetries, ActionTypes.clearCartQuoteError],
    },
  },
});
