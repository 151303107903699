export enum NubankPaymentState {
  track = "track",
  preAuthCheck = "preAuthCheck",
  requestAuth = "requestAuth",
  selectCreditOrDebit = "selectCreditOrDebit",
  credit = "credit",
  review = "review",
  invalidFunds = "invalidFunds",
  error = "error",
}

export enum NubankPaymentChildState {
  fetch = "fetch",
  route = "route",
  idle = "idle",
  success = "success",
  failure = "failure",
  error = "error",
  loading = "loading",
}

export enum NupayPaymentError {
  QuoteIdMismatch = "QuoteIdMismatch",
  UnknownFailure = "UnknownFailure",
  OpenSessionFailure = "OpenSessionFailure",
  GetUserFailure = "GetUserFailure",
}
