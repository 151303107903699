import { useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { RadioOption } from "@hopper-b2b/types";
import { RadioDropdown } from "@hopper-b2b/ui";
import Chevron from "../../../assets/client/chevron.svg";
import { SelectWithHalfSheet } from "../../SelectWithHalfSheet";
import "./styles.scss";

export interface PassportCountrySelectProps {
  selected: RadioOption["value"] | null;
  options: RadioOption[];
  setOption: (option: RadioOption["value"]) => void;
}

const PassportCountrySelect = ({
  selected,
  options,
  setOption,
}: PassportCountrySelectProps) => {
  const { t } = useI18nContext();

  const passportCountryOptions = useMemo(
    () =>
      options.map((option) => {
        return { value: option.value, label: option?.label || null };
      }),
    [options]
  );

  const selectedRadioOptionLabel = useMemo(
    () => options.find((option) => option.value === selected)?.label || "",
    [options, selected]
  );

  return (
    <SelectWithHalfSheet
      label={t("countryOfIssue")}
      selected={selectedRadioOptionLabel as string}
      className="frequent-flyer-select traveler-info-field"
      chevronSrc={Chevron}
      modalContent={
        <RadioDropdown
          dropdownLabel={t("countryOfIssue")}
          options={passportCountryOptions}
          setOption={setOption}
          selected={selected}
          showDropdownContentOnly={true}
        />
      }
    />
  );
};

export default PassportCountrySelect;
