import { trackEvent } from "@hopper-b2b/api";
import { VIEW_PAYMENT } from "@hopper-b2b/types";
import { LodgingMachineContext } from "../../types";

export const trackViewPayment = (
  context: LodgingMachineContext,
  _event: unknown
) =>
  new Promise((resolve) => {
    const trackingProperties = {}; //TODO
    trackEvent({
      eventName: VIEW_PAYMENT,
      properties: trackingProperties,
    })
      .then(() => resolve(null))
      .catch((e) => resolve(null));
  });
