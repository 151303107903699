import { useMemo, Dispatch, SetStateAction } from "react";
import { DriverLicense } from "@hopper-b2b/types";
import { Box, Typography, TextField } from "@material-ui/core";
import { useI18nContext, useI18nCountry } from "@hopper-b2b/i18n";
import { getZipError } from "@hopper-b2b/utilities";
import {
  BannerSeverity,
  NotificationBanner,
  Icon,
  IconName,
} from "@hopper-b2b/ui";
import clsx from "clsx";

import { renderMenu } from "../../utils";
import emojiFlags from "emoji-flags";

interface IDriverLicenseSectionProps {
  className?: string;
  driverLicenseSectionTitle: string;
  driverLicense: DriverLicense;
  onSetLicense: Dispatch<SetStateAction<DriverLicense>>;
  isMissingDriverLicense: boolean;
  isMobile: boolean | undefined;
}

export const DriverLicenseSection = ({
  className,
  driverLicenseSectionTitle,
  driverLicense,
  onSetLicense,
  isMissingDriverLicense,
  isMobile,
}: IDriverLicenseSectionProps): JSX.Element => {
  const { t } = useI18nContext();
  const { getAllCountryNames } = useI18nCountry();

  const zipError = useMemo(() => {
    const error = getZipError(driverLicense.postCode, driverLicense.country);
    return {
      isError: Boolean(error),
      errorI18nKey: error ? (error as string) : "",
    };
  }, [driverLicense.postCode, driverLicense.country]);

  const countryOptions = useMemo(
    () =>
      Array.from(Object.entries(getAllCountryNames())).map(([code, label]) => {
        return {
          label,
          value: code,
          icon: (
            <span className="checkout-country-option-flag-icon">
              {emojiFlags.countryCode(code).emoji}
            </span>
          ),
        };
      }),
    [getAllCountryNames]
  );

  return (
    <Box className={clsx("driver-license-container", className)}>
      {isMissingDriverLicense && (
        <Box className="driver-license-banner">
          <NotificationBanner
            className={!isMobile ? "status-banner" : "mobile-status-banner"}
            label="Enter Driver's License details to select this driver."
            severity={BannerSeverity.WARNING}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
      <Box className="traveler-info-description">
        <Typography variant="subtitle1">{driverLicenseSectionTitle}</Typography>
      </Box>
      <form className="traveler-info-form">
        <TextField
          label="Driver's License Address"
          aria-label="Driver License Address"
          className={clsx("traveler-info-field", "driver-license-address")}
          name="address"
          value={driverLicense.street}
          onChange={(event) =>
            onSetLicense({ ...driverLicense, street: event.target.value })
          }
          required
          id="driver-license-address-input"
        />
        <TextField
          label="City"
          aria-label="Driver License city"
          className={clsx("traveler-info-field", "driver-license-city")}
          name="city"
          value={driverLicense.cityCode}
          onChange={(event) =>
            onSetLicense({ ...driverLicense, cityCode: event.target.value })
          }
          required
          id="driver-license-city-input"
        />
        <TextField
          aria-label="Driver License Nationality"
          label="Country"
          name="country"
          className={clsx("traveler-info-field", "country")}
          value={driverLicense.country}
          onChange={(event) =>
            onSetLicense({ ...driverLicense, country: event.target.value })
          }
          select
          required
          InputProps={{
            id: "driver-license-nationality-input",
          }}
          id="driver-license-nationality-input"
        >
          {renderMenu(countryOptions)}
        </TextField>
        <TextField
          aria-label="Driver License zip code"
          label="Zip Code"
          className={clsx("traveler-info-field", "driver-license-zip")}
          value={driverLicense.postCode}
          name="zip"
          required
          error={!!driverLicense.postCode && zipError.isError}
          helperText={
            driverLicense.postCode && zipError.isError
              ? `${t(zipError.errorI18nKey)}`
              : null
          }
          onChange={(event) =>
            onSetLicense({ ...driverLicense, postCode: event.target.value })
          }
          id="driver-license-zip-code-input"
        />
        <span
          role="alert"
          aria-live="assertive"
          id="post-code"
          className="error-code"
          style={
            driverLicense.postCode && zipError.isError
              ? {}
              : { display: "none" }
          }
        >
          {driverLicense.postCode && zipError.isError
            ? `${t(zipError.errorI18nKey)}`
            : null}
        </span>
      </form>
    </Box>
  );
};
