import { trackEvent } from "@hopper-b2b/api";

import {
  FlightPassengerSelectorsV2,
  PassengerErrorModalTypes,
  PassengerInformation,
  useCheckoutStateSelector as useSelector,
  useCheckoutState,
  FlightPassengerEventTypesV2,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ADD_FREQUENT_FLYER,
  CHOOSE_TRAVELER,
  IPerson,
  PersonId,
  SELECT_TRAVELERS,
} from "@hopper-b2b/types";
import { B2BLoadingPopup, ErrorPopup } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { UserContext } from "../../../../App";
import Warning from "../../../../assets/client/warning-triangle.svg";
import { PATH_FLIGHTS_SEARCH, apiConfig } from "../../../../utils/urlPaths";
import { TEvent } from "../../events";
import {
  getChooseTravelerProperties,
  getClientAssets,
  getCurrencyFromPricingInfo,
  getPricing,
} from "../../selectors";
import { FlightMachineContext } from "../../types";
import MobileInfoPopover from "../../../../components/MobileInfoPopover";

export const ConnectedPassengerInformation = (props) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const userContext = useContext(UserContext);
  const history = useHistory();

  const clientAssets = useSelector(getClientAssets);

  const [, send] = useCheckoutState<TEvent, FlightMachineContext>();
  const [
    showSelectedSearchPassengersDiffer,
    setShowSelectedSearchPassengersDiffer,
  ] = useState(false);

  const isLoading = useSelector(
    FlightPassengerSelectorsV2.getIsPassengerInformationLoading
  );

  const userPassengers = useSelector(
    FlightPassengerSelectorsV2.getUserPassengers
  );

  const allSelectedPassengerIds = useSelector(
    FlightPassengerSelectorsV2.getAllSelectedPassengerIds
  );
  const currentPassenger = useSelector(
    FlightPassengerSelectorsV2.getCurrentUserPassenger
  );
  const openPassengerFormModal = useSelector(
    FlightPassengerSelectorsV2.getOpenPassengerFormModal
  );
  const openPassengerPicker = useSelector(
    FlightPassengerSelectorsV2.getOpenPassengerPicker
  );

  const searchPassengerCount = useSelector(
    FlightPassengerSelectorsV2.getSearchPassengerNumber
  );

  const searchCountAndSelectedDiffer = useMemo(
    () => searchPassengerCount !== allSelectedPassengerIds.length,
    [allSelectedPassengerIds.length, searchPassengerCount]
  );

  // Infant Modal
  const currentInfantId = useSelector(
    FlightPassengerSelectorsV2.getCurrentInfantId
  );
  const isInfantModalLoading = useSelector(
    FlightPassengerSelectorsV2.getIsInfantSeatModalLoading
  );

  const chooseTravelerProperties = useSelector(getChooseTravelerProperties);
  const tripPricing = useSelector(getPricing);

  const tripCurrency = useSelector(getCurrencyFromPricingInfo);

  const travelerWorkflowTitles = {
    travelerInfoTitle: currentPassenger?.id
      ? t("passengerInformation.editPassengerInfoText")
      : matchesMobile
      ? t("passengerInformation.addPassengerInfoText")
      : t("passengerInformation.title"),
    travelerInfoHeader: currentPassenger?.id
      ? t("passengerInformation.editPassengerInfoText")
      : matchesMobile
      ? t("passengerInformation.addPassenger")
      : t("passengerInformation.title"),
    travelerInfoSubtitle: t("passengerInformation.passengerSubtitle"),
    travelerSelectSubtitle: t("passengerInformation.passengerSelectSubtitle"),
    noTravelersSelectSubtitle: t(
      "passengerInformation.noPassengerSelectSubtitle"
    ),
    frequentFlyerTitle: t("passengerInformation.frequentFlyerTitle"),
    additionalInfoTitle: t("passengerInformation.additionalInfo"),
    adultTitle: t("passengerInformation.adult"),
    childTitle: t("passengerInformation.child"),
    infantSeatTitle: t("passengerInformation.infant"),
    infantLapTitle: t("passengerInformation.infant"),
    addTravelers: t("passengerInformation.addPassengers"),
    buttonMessage: t("addNewPassenger"),
  };

  const handleCloseForm = () => {
    send({ type: FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER });
  };

  const handleContinue = () => {
    if (searchCountAndSelectedDiffer) {
      setShowSelectedSearchPassengersDiffer(true);
    } else {
      setShowSelectedSearchPassengersDiffer(false);
      send(FlightPassengerEventTypesV2.COMPLETE);
    }
  };

  const handleDeletePassenger = (personId: string) => {
    send({ type: FlightPassengerEventTypesV2.DELETE_PASSENGER, personId });
  };

  const handleEditClick = (passenger: IPerson) => {
    send({
      type: FlightPassengerEventTypesV2.SET_CURRENT_PASSENGER,
      passenger,
    });
    send(FlightPassengerEventTypesV2.OPEN_PASSENGER_FORM);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSelectPassenger = (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => {
    if (hasError) {
      send(FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR);
    }
    if (!allSelectedPassengerIds.includes(passengerId)) {
      trackEvent({
        eventName: CHOOSE_TRAVELER,
        properties: chooseTravelerProperties,
      });
      trackEvent({
        eventName: SELECT_TRAVELERS,
        properties: {
          num_travelers: allSelectedPassengerIds.length + 1,
        },
      });
    }
    send({
      type: FlightPassengerEventTypesV2.SELECT_PASSENGER,
      passengerId,
      singleTravelerWorkflow,
    });
  };

  const handleUpdatePassenger = useCallback(
    (passenger: IPerson, onUpdate?: boolean | undefined) => {
      const freqFlyerKeys = Object.keys(passenger.frequentFlyer);
      if (
        freqFlyerKeys.length > 0 &&
        passenger.frequentFlyer !==
          userPassengers.find((t) => t.id === passenger.id)?.frequentFlyer
      ) {
        trackEvent(
          {
            eventName: ADD_FREQUENT_FLYER,
            properties: {
              frequent_flyer_program:
                freqFlyerKeys.length > 0
                  ? freqFlyerKeys[freqFlyerKeys.length - 1]
                  : "",
            },
          },
          apiConfig
        );
      }
      if (!allSelectedPassengerIds.includes(passenger.id)) {
        trackEvent({
          eventName: CHOOSE_TRAVELER,
          properties: chooseTravelerProperties,
        });
      }
      send({
        type: FlightPassengerEventTypesV2.UPDATE_PASSENGER,
        person: passenger,
        onUpdate: !!onUpdate,
      });
    },
    [allSelectedPassengerIds, chooseTravelerProperties, send, userPassengers]
  );

  const handleSelectedSearchPassengersDifferClose = useCallback(() => {
    setShowSelectedSearchPassengersDiffer(false);
  }, []);

  const handleSelectedSearchPassengersDifferOnContinue = useCallback(() => {
    send(FlightPassengerEventTypesV2.COMPLETE);
  }, [send]);

  const handleClickAddTraveler = () => {
    send({
      type: FlightPassengerEventTypesV2.SET_CURRENT_PASSENGER,
      passenger: {} as IPerson,
    });
    send(FlightPassengerEventTypesV2.OPEN_PASSENGER_FORM);
  };

  const handleOpenPassengerPicker = () => {
    send(FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER);
  };

  const handleInfantPickerModalClose = () => {
    send(FlightPassengerEventTypesV2.CLEAR_CURRENT_INFANT_ID);
  };

  const handleInfantPickerModalContinue = (seatType: "OwnSeat" | "OnLap") => {
    send({
      type: FlightPassengerEventTypesV2.ON_INFANT_MODAL_CONTINUE,
      seatType,
    });
    handleInfantPickerModalClose();
  };

  const headerTitle =
    openPassengerFormModal && currentPassenger?.id
      ? t("editTraveler")
      : openPassengerFormModal
      ? t("passengerInformation.addPassenger")
      : t("passengers");

  const hasError = useSelector(
    FlightPassengerSelectorsV2.getPassengerErrorOpen
  );

  return (
    <>
      <PassengerInformation
        isLoading={isLoading}
        userPassengers={userPassengers}
        userInfo={userContext?.sessionInfo?.userInfoResponse}
        selectedPassengerIds={allSelectedPassengerIds}
        currentPassenger={currentPassenger}
        openPassengerFormModal={openPassengerFormModal}
        openPassengerPicker={openPassengerPicker}
        handleCloseForm={handleCloseForm}
        handleContinue={handleContinue}
        handleDeletePassenger={handleDeletePassenger}
        handleEditClick={handleEditClick}
        handleGoBack={handleGoBack}
        handleSelectPassenger={handleSelectPassenger}
        handleUpdatePassenger={handleUpdatePassenger}
        handleClickAddTraveler={handleClickAddTraveler}
        travelerWorkflowTitles={travelerWorkflowTitles}
        handleOpenPassengerPicker={handleOpenPassengerPicker}
        currentInfantId={currentInfantId}
        handleInfantPickerModalContinue={handleInfantPickerModalContinue}
        handleInfantPickerModalClose={handleInfantPickerModalClose}
        isInfantModalLoading={isInfantModalLoading}
        tripPricing={tripPricing}
        tripCurrency={tripCurrency}
        clientAssets={clientAssets}
        isMobile={matchesMobile}
        headerTitle={headerTitle}
        ErrorBanner={<ConnectedErrorModal />}
        selectionScreenHeaderElement={t("passengers")}
        hasError={hasError}
        loadingComponent={<B2BLoadingPopup open={isLoading} />}
        ctaEnabledByDefault
        nationalityRequired
        {...props}
      />
      <MobileInfoPopover
        className="selected-search-passengers-differ-modal"
        isOpen={showSelectedSearchPassengersDiffer}
        onClose={handleSelectedSearchPassengersDifferClose}
        title={t("checkoutWarning.searchPassengerNumDifferentFromSearch.title")}
        subtitle={t(
          "checkoutWarning.searchPassengerNumDifferentFromSearch.subtitle"
        )}
        primaryButtonAction={handleSelectedSearchPassengersDifferOnContinue}
        primaryButtonText={t("continue")}
        secondaryButtonText={t("modifyPassengersButtonText")}
        secondaryButtonAction={handleSelectedSearchPassengersDifferClose}
      />
    </>
  );
};

export const ConnectedErrorModal = () => {
  const { t } = useI18nContext();
  const history = useHistory();

  const [, send] = useCheckoutState<TEvent, FlightMachineContext>();

  const open = useSelector(FlightPassengerSelectorsV2.getPassengerErrorOpen);
  const passengerError = useSelector(
    FlightPassengerSelectorsV2.getPassengerError
  );
  const infantOnlyError = useSelector(
    FlightPassengerSelectorsV2.getInfantOnlyError
  );

  const clearError = useCallback(
    () => send(FlightPassengerEventTypesV2.CLEAR_PASSENGER_INFORMATION_ERROR),
    [send]
  );

  const searchAgain = useCallback(() => {
    clearError();
    history.push(PATH_FLIGHTS_SEARCH);
  }, [clearError, history]);

  const openPassengerPicker = useCallback(() => {
    send(FlightPassengerEventTypesV2.OPEN_PASSENGER_PICKER);
    clearError();
  }, [clearError, send]);

  const openPassportForm = () =>
    send(FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM);

  const errorModalProps = useMemo(() => {
    const type = passengerError?.type;

    if (infantOnlyError) {
      return {
        modal: false,
        type: "warning",
        title: t("checkoutError.invalidPassenger"),
        subtitle: t("checkoutError.checkInMinimumAgeNotMetSubtitle"),
        errorType: "infantOnly",
        primaryButtonText: t("checkoutError.updateTraveler"),
        primaryOnClick: openPassengerPicker,
      };
    }
    switch (type) {
      case PassengerErrorModalTypes.TripPricing:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.UpdatePassenger:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.FetchPassengers:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.ValidatePassengers:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.NoPassengers:
        return {
          title: t("checkoutError.invalidPassenger"),
          subtitle: t("checkoutError.noPassengersSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.DeletePassenger:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.ValidateAndTripPricing:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
      case PassengerErrorModalTypes.ADTRequired: // todo: NoAdultPassenger
        return {
          modal: false,
          type: "warning",
          icon: Warning,
          title: t("checkoutError.noAdultPassengerTitle"),
          subtitle: t("checkoutError.noAdultPassengerSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.INSNotAllowed: // todo: SeatedInfantsUnsupported
        return {
          title: t("checkoutError.seatedInfantsUnsupportedTitle"),
          subtitle: t("checkoutError.seatedInfantsUnsupportedSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.InSufficientADTCount: // todo: TooManyLapInfants
        return {
          title: t("checkoutError.tooManyLapInfantsTitle"),
          subtitle: t("checkoutError.tooManyLapInfantsSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.UnaccompaniedMinor: // todo: TooManyLapInfants
        return {
          title: t("checkoutError.tooManyLapInfantsTitle"),
          subtitle: t("checkoutError.tooManyLapInfantsSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.TooManyPassengers: // todo: TooManyPassengers
        return {
          title: t("checkoutError.tooManyPassengersTitle"),
          subtitle: t("checkoutError.tooManyPassengersSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.InfantIsMinor: // todo: LapInfantTooOld
        return {
          title: t("checkoutError.lapInfantTooOldTitle"),
          subtitle: t("checkoutError.lapInfantTooOldSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: openPassengerPicker,
        };
      case PassengerErrorModalTypes.SearchPassengerNumNotReached:
        return {
          title: t("checkoutError.searchPassengerNumNotReachedTitle"),
          subtitle: t("checkoutError.searchPassengerNumNotReachedSubtitle"),
          errorType: type,
          primaryButtonText: t("checkoutError.searchPassengerNumNotReachedCta"),
          primaryOnClick: openPassengerPicker,
        };
      default:
        return {
          modal: true,
          title: t("checkoutError.genericErrorTitle"),
          subtitle: t("checkoutError.genericErrorSubtitle"),
          errorType: "default",
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
        };
    }
  }, [
    infantOnlyError,
    openPassengerPicker,
    passengerError?.type,
    searchAgain,
    t,
  ]);

  if (
    passengerError?.type === PassengerErrorModalTypes.PassportValidationFail
  ) {
    openPassportForm();
    return null;
  }

  return open && errorModalProps.modal ? (
    <ErrorPopup open={open} {...errorModalProps} />
  ) : null;
};
