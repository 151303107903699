import { useHistory } from "react-router";

import { CallState } from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CartQuoteErrorModal,
  CartQuoteEventType,
  cartQuoteSelectors,
  LodgingSelectors,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import {
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { ErrorScreen } from "../../../../components/ErrorScreen";
import { LoadingScreen } from "../../../../components/Slots";
import { TEvent } from "../../events";
import { LodgingError, LodgingErrorEnum } from "@b2bportal/lodging-api";
import { PATH_HOTELS_SEARCH } from "../../../../utils/urlPaths";
import { useCallback } from "react";

export const ConnectedCartQuoteComponent = () => {
  const { t } = useI18nContext();

  const loadingSteps = [
    {
      title: t("pollQuoteLoading.step1Title"),
    },
    {
      title: t("pollQuoteLoading.step2Title"),
    },
    {
      title: t("pollQuoteLoading.step3Title"),
    },
  ];

  const callState = useSelector(cartQuoteSelectors.getCartQuoteCallState);
  const errorState = useSelector(cartQuoteSelectors.getOpenCartQuoteErrorModal);

  if (errorState) {
    return <MappedErrorScreen />;
  }

  return (
    <LoadingScreen
      loading={callState === CallState.InProcess}
      averageLoadingTime={getEnvVariables("pollQuoteLoadingTime") as number}
      className="cart-quote-loading-popup"
      loadingSteps={loadingSteps}
    />
  );
};

export const MappedErrorScreen = () => {
  const { t } = useI18nContext();
  const history = useHistory();
  const send = useCheckoutSend<TEvent>();
  const cartError = useSelector(cartQuoteSelectors.getCartQuoteError);
  const allowedQuoteRetry = useSelector(cartQuoteSelectors.getAllowQuoteRetry);
  const availabilityLink = useSelector(LodgingSelectors.getAvailabilityLink);

  const clearError = () => send(CartQuoteEventType.CLEAR_CART_QUOTE_ERROR);
  const searchAgain = useCallback(() => {
    clearError();
    history.push(availabilityLink || PATH_HOTELS_SEARCH);
  }, [availabilityLink, clearError, history]);

  const updateTravelers = () => {
    send(CartQuoteEventType.OPEN_PASSENGER_PICKER);
    clearError();
  };
  const retryQuote = () => {
    send(CartQuoteEventType.RETRY_QUOTE);
  };

  const DEFAULT_ERROR_MODEL_PROPS = {
    title: t("checkoutError.genericErrorTitle"),
    subtitle: t("checkoutError.genericErrorSubtitle"),
    className: "error",
    ...(allowedQuoteRetry
      ? {
          primaryButtonText: t("checkoutError.hotelTryAgain"),
          primaryOnClick: retryQuote,
          secondaryButtonText: t("checkoutError.findNewHotel"),
          secondaryOnClick: searchAgain,
        }
      : {
          primaryButtonText: t("checkoutError.findNewHotel"),
          primaryOnClick: searchAgain,
        }),
  };

  const getLodgingError = (lodgingError: LodgingError) => {
    switch (lodgingError.LodgingError) {
      case LodgingErrorEnum.CheckInMinimumAgeNotMet:
      case LodgingErrorEnum.MinimumCheckInAgeNotMet:
        return {
          title: t("checkoutError.hotelCheckInMinimumAgeNotMetTitle"),
          subtitle: t("checkoutError.hotelCheckInMinimumAgeNotMetSubtitle"),
          primaryButtonText: t("checkoutError.updateTraveler"),
          primaryOnClick: updateTravelers,
          className: "missing",
        };
      case LodgingErrorEnum.PaymentFailed:
        return {
          title: t("checkoutError.hotelPaymentFailedTitle"),
          subtitle: t("checkoutError.hotelPaymentFailedSubtitle"),
          primaryButtonText: t("checkoutError.tryAgain"),
          primaryOnClick: retryQuote,
          className: "warn",
        };
      case LodgingErrorEnum.InvalidQuote:
        return {
          title: t("checkoutError.hotelInvalidQuoteTitle"),
          subtitle: t("checkoutError.hotelInvalidQuoteSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "timeout",
        };
      case LodgingErrorEnum.RateNotAvailable:
        return {
          title: t("checkoutError.hotelRateNotAvailableTitle"),
          subtitle: t("checkoutError.hotelRateNotAvailableSubtitle"),
          primaryButtonText: t("checkoutError.searchAgain"),
          primaryOnClick: searchAgain,
          className: "error",
        };
      case LodgingErrorEnum.LackOfInventory:
      case LodgingErrorEnum.OfferNotFound:
      case LodgingErrorEnum.Unavailable:
        return {
          title: t("checkoutError.hotelLackOfInventoryTitle"),
          subtitle: t("checkoutError.hotelLackOfInventorySubtitle"),
          primaryButtonText: t("checkoutError.selectAnotherRoom"),
          primaryOnClick: searchAgain,
          className: "warn",
        };

      case LodgingErrorEnum.LodgingErrorCode:
      case LodgingErrorEnum.ProductNotFound:
      case LodgingErrorEnum.ProviderNotDefined:
      case LodgingErrorEnum.QuoteExpired:
      case LodgingErrorEnum.ServiceError:
      case LodgingErrorEnum.Unspecified:
      case LodgingErrorEnum.ClientNotDefined:
      default:
        // default cover LodgingError that should not occur in PriceQuote
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getProductError = (error: ProductError) => {
    switch (error.value.type) {
      case Product.Hotel: {
        const lodgingHotel = error.value.value as LodgingError;
        return getLodgingError(lodgingHotel);
      }
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };

  const getPaymentError = (_head: PaymentError) => {
    return DEFAULT_ERROR_MODEL_PROPS;
  };

  const getErrorModalProps = (
    type?: CartQuoteErrorModal,
    data?: PurchaseError[]
  ) => {
    switch (type) {
      case CartQuoteErrorModal.FailedPolling: {
        const head = data?.[0]; // todo: we probably want to have some sort of priority order on errors?
        switch (head?.Error) {
          case PurchaseErrorEnum.NoAvailability:
            // todo: No Availability should only be a product error
            return {
              title: t("checkoutError.noAvailabilityTitle"),
              subtitle: t("checkoutError.noAvailabilitySubtitle"),
              primaryButtonText: t("checkoutError.searchAgain"),
              primaryOnClick: searchAgain,
            };
          case PurchaseErrorEnum.PaymentError:
            return getPaymentError(head as PaymentError);
          case PurchaseErrorEnum.ProductError: // todo. Missing `Error` attribute
            return getProductError(head as ProductError);
          default:
            // default case covers
            // - PurchaseErrorEnum.AuthError
            // - PurchaseErrorEnum.ExpiredFulfillToken
            // - PurchaseErrorEnum.ExpiredQuoteToken
            // - PurchaseErrorEnum.FraudAutoReject
            // - PurchaseErrorEnum.InActivity
            // - PurchaseErrorEnum.InvalidSession
            // - PurchaseErrorEnum.NoPaymentsProvided
            // - PurchaseErrorEnum.NonDistinctPayments
            // - PurchaseErrorEnum.ErrorCode (because this isn't standardized enough yet)
            return DEFAULT_ERROR_MODEL_PROPS;
        }
      }
      default:
        return DEFAULT_ERROR_MODEL_PROPS;
    }
  };
  const errorModalProps = getErrorModalProps(
    cartError?.type,
    cartError?.data as PurchaseError[]
  );

  return <ErrorScreen {...errorModalProps} onBack={updateTravelers} />;
};
