import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RewardsAccount } from "@hopper-b2b/types";

import "./styles.scss";
import { RadioDropdown } from "../RadioDropdown";
import { getRewardText } from "@hopper-b2b/utilities";
import { getCapOneCardIcon } from "../utilities/paymentHelpers";
import { useI18nCurrency } from "@hopper-b2b/i18n";

interface RewardsAccountSelectionTexts {
  buttonText: string;
  rewardsBalanceText: string;
  valuedAtText: string;
}

export interface IRewardsAccountSelectionProps {
  className?: string;
  selectedAccountReferenceId: string | null;
  setSelectedAccountReferenceId: (
    selectedAccountReferenceId: string | null
  ) => void;
  rewardsAccounts: RewardsAccount[];
  isMobile?: boolean;
  texts?: RewardsAccountSelectionTexts;
  popoverClassName?: string;
}

const getCardInfoText = (account: RewardsAccount) => {
  return `${account.productDisplayName}...${account.lastFour}`;
};

export const RewardsAccountSelection = (
  props: IRewardsAccountSelectionProps
) => {
  const {
    className,
    selectedAccountReferenceId,
    setSelectedAccountReferenceId,
    rewardsAccounts,
    isMobile,
    texts,
    popoverClassName,
  } = props;

  const { formatFiatCurrency } = useI18nCurrency();

  const selectedAccount = rewardsAccounts.find(
    (rewardsAccount) =>
      rewardsAccount.accountReferenceId === selectedAccountReferenceId
  );

  if (!selectedAccountReferenceId || !selectedAccount) {
    return null;
  }

  const renderRewardsBalance = () => {
    return (
      <Box className="rewards-account-balance-container">
        <Typography className="balance-text" variant="body1">
          {texts?.rewardsBalanceText ?? "Rewards Balance: "}
          <span className="highlighted">
            {getRewardText({ reward: selectedAccount.rewardsBalance })}
          </span>
          {/* TODO: revisit this logic once we know what are the possible rewardsBalance.currency values */}
          {selectedAccount?.rewardsBalance?.currency !== "$" && (
            <>
              {" or "}
              <span className="highlighted">
                {formatFiatCurrency(selectedAccount.rewardsCashEquivalent)}
              </span>
              {" for travel"}
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const renderDropDownButton = () => {
    return (
      <RadioDropdown
        buttonClassName="rewards-account-selection-button"
        popoverClassName={clsx(
          "rewards-account-selection-popover",
          popoverClassName
        )}
        dropdownLabel={texts?.buttonText ?? "Change card"}
        options={rewardsAccounts.map((rewardsAccount) => {
          return {
            value: rewardsAccount.accountReferenceId,
            ariaLabel: `${rewardsAccount?.accountDisplayName}, ${
              texts?.rewardsBalanceText || `Rewards Balance`
            }: ${rewardsAccount?.rewardsBalance?.value} ${
              rewardsAccount?.rewardsBalance?.currency
            }`,
            label: (
              <RewardsAccountOption
                account={rewardsAccount}
                rewardsBalanceText={texts?.rewardsBalanceText}
                className={popoverClassName}
              />
            ),
          };
        })}
        selected={selectedAccountReferenceId}
        setOption={setSelectedAccountReferenceId}
        radioLabelPlacement="start"
        groupAriaLabel="choose a card to use"
        buttonAriaLabel={`${
          selectedAccount?.accountDisplayName
            ? selectedAccount?.accountDisplayName + ", "
            : ""
        }change card`}
        optionProps={{
          "data-dd-action-name": "Switch default card", //edit card data on user interaction in Datadog
        }}
      />
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        <Box className="rewards-balance-section">{renderRewardsBalance()}</Box>
        <Box className="rewards-account-card-section">
          <Box className="card-icon-section">
            {getCapOneCardIcon(
              selectedAccount.cardArtUrl,
              "rewards-account-icon"
            )}
          </Box>
          <Box className="card-info-and-button-section">
            <Typography className="card-info-text" variant="body1">
              {getCardInfoText(selectedAccount)}
            </Typography>
            {renderDropDownButton()}
          </Box>
        </Box>
      </>
    );
  };

  const renderMobileView = () => {
    return (
      <>
        <Box className="mobile-rewards-balance-section">
          {renderRewardsBalance()}
        </Box>
        <Box className="mobile-rewards-account-button-section">
          <Box className="rewards-account-button-wrapper">
            <Box className="rewards-account-button-container">
              <Typography className="card-info-text" variant="body1">
                {getCardInfoText(selectedAccount)}
              </Typography>
              {renderDropDownButton()}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      className={clsx(
        "rewards-account-selection-root",
        { mobile: isMobile },
        className
      )}
    >
      <Box className="rewards-account-selection-container">
        {isMobile && renderMobileView()}
        {!isMobile && renderDesktopView()}
      </Box>
    </Box>
  );
};

interface IRewardsAccountOptionProps {
  account: RewardsAccount;
  rewardsBalanceText?: string;
  className?: string;
}

export const RewardsAccountOption = (props: IRewardsAccountOptionProps) => {
  const { account, rewardsBalanceText, className } = props;

  return (
    <Box className={clsx("rewards-account-radio-option-root", className)}>
      <Box className="rewards-account-radio-option-container">
        <Box className="card-icon-section">
          {getCapOneCardIcon(account.cardArtUrl, "rewards-account-option-icon")}
        </Box>
        <Box className="card-info-section">
          <Typography className="card-info-text" variant="body1">
            {getCardInfoText(account)}
          </Typography>
          <Typography className="card-balance-text" variant="body2">
            {rewardsBalanceText ?? "Rewards Balance: "}
            <span className="highlighted">
              {getRewardText({ reward: account.rewardsBalance })}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
