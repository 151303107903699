import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CfarExerciseProgress,
  getFlightSummaryInfo,
  getReturnSlice,
} from "@hopper-b2b/types";
import {
  B2BLoadingPopup,
  ErrorPopup as AlertModal,
  Icon,
  IconName,
} from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getEnvVariables,
  useDeviceTypes,
  useTenantIcons,
  SupportContext,
} from "@hopper-b2b/utilities";
import {
  discardFlightCfarCancellationQuote,
  resetCfarExerciseState,
  setOpenModal,
} from "../../../../pages/TripsList/actions/actions";
import {
  getAirlinesMap,
  getAirportMap,
  getCfarCancellationQuote,
  getCfarCancellationQuoteV1,
  getCfarExerciseProgress,
  getFlightCfarExerciseError,
  getCfarExerciseQuoteError,
  getOpenModal,
} from "../../../../pages/TripsList/reducer/selectors";
import { CfarDetails, CfarProgressCta, FlightTripCard } from "./components";
import "./styles.scss";
import {
  AirCfarExerciseFailureEnum,
  CipherText,
} from "@b2bportal/air-cfar-api/lib/api";
import {
  getCfarExerciseErrorContent,
  ICfarActionButtonProps,
  ICfarExerciseErrorContentSubtitle,
} from "./utils";

export interface ICfarExerciseProps {
  flight: BookedFlightItineraryWithDepartureTime;
  onComplete?: () => void;
  enableTopLeftIcon?: boolean;
}

export const CfarExercise = ({
  flight,
  onComplete,
  enableTopLeftIcon,
}: ICfarExerciseProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const showError = useSelector(getFlightCfarExerciseError);
  const icons = useTenantIcons();

  const cfarExerciseProgress = useSelector(getCfarExerciseProgress);
  const airportMap = useSelector(getAirportMap);
  const airlineMap = useSelector(getAirlinesMap);
  const cfarQuote = useSelector(getCfarCancellationQuote);
  const cfarQuoteV1 = useSelector(getCfarCancellationQuoteV1);
  const cfarExerciseError = useSelector(getCfarExerciseQuoteError);
  const openModal = useSelector(getOpenModal);
  const { generalSupportChat } = useContext(SupportContext);
  const exerciseErrorContent = getCfarExerciseErrorContent({
    cfarExerciseError,
    openModal,
    i18nContext: t,
    generalSupportChat,
    cfarExerciseProgress,
    onClose: onComplete,
  });
  const { title, subtitle, primaryButton, secondaryButton } =
    exerciseErrorContent ?? {};

  const [selectedCfarQuoteId, setSelectedCfarQuoteId] = useState(null);
  useEffect(() => {
    // this is only needed for V0.
    // V1 sets the quoteId in CfarDetails.tsx (for single outcome), or upon radio selection (for multi outcome)
    if (cfarQuote) {
      setSelectedCfarQuoteId(cfarQuote.quoteId);
    }
  }, [cfarQuote]);

  const loading =
    cfarExerciseProgress === CfarExerciseProgress.Pending ||
    cfarExerciseProgress === CfarExerciseProgress.Loading;

  useEffect(() => {
    return () => {
      dispatch(discardFlightCfarCancellationQuote());
      dispatch(resetCfarExerciseState());
    };
  }, [dispatch]);

  const departureFlightSummary = useMemo(
    () => getFlightSummaryInfo(true, flight, airportMap, airlineMap),
    [flight, airlineMap, airportMap]
  );
  const returnFlightSummary = useMemo(
    () => getFlightSummaryInfo(false, flight, airportMap, airlineMap),
    [flight, airlineMap, airportMap]
  );

  const airlineNames = useMemo(
    () =>
      returnFlightSummary
        ? [departureFlightSummary.airlineName, returnFlightSummary.airlineName]
        : [departureFlightSummary.airlineName],
    [departureFlightSummary, returnFlightSummary]
  );

  const loadingMessage = () => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Loading:
        return t("cfarExercise.loadingMessage");
      case CfarExerciseProgress.Pending:
      default:
        return t("cfarExercise.pendingMessage");
    }
  };

  const onClose = () => {
    dispatch(setOpenModal({ type: null, selectedItinerary: null }));
    dispatch(resetCfarExerciseState());
  };

  const onCloseAndComplete = () => {
    onClose();
    if (onComplete) {
      onComplete();
    }
  };

  const Alert = ({
    mobile,
    showError,
    cfarExerciseProgress,
    title,
    subtitle,
    onClose,
    enableTopLeftIcon,
    cfarExerciseError,
    primaryButton,
    secondaryButton,
  }: {
    mobile: boolean;
    showError: boolean;
    cfarExerciseProgress: CfarExerciseProgress;
    title: string;
    subtitle: ICfarExerciseErrorContentSubtitle;
    onClose: () => void;
    enableTopLeftIcon: boolean;
    cfarExerciseError: AirCfarExerciseFailureEnum;
    primaryButton: ICfarActionButtonProps;
    secondaryButton: ICfarActionButtonProps;
  }) => {
    return (
      <AlertModal
        reverse
        fullScreen={mobile}
        open={
          showError || cfarExerciseProgress === CfarExerciseProgress.Completed
        }
        title={title}
        subtitle={subtitle}
        centered={!matchesMobile}
        onClose={onClose}
        topRightButton={
          <div onClick={() => onCloseAndComplete()}>
            <Icon name={IconName.CloseBlack} />
          </div>
        }
        topLeftButton={
          enableTopLeftIcon &&
          cfarExerciseError == AirCfarExerciseFailureEnum.Void ? (
            <Icon name={IconName.Repayments} />
          ) : undefined
        }
        primaryButtonText={primaryButton?.text}
        primaryOnClick={() => {
          primaryButton?.actions?.forEach((individualAction) =>
            dispatch(individualAction())
          );
          primaryButton?.nonActions?.forEach((nonAction) => nonAction());
        }}
        secondaryButtonText={secondaryButton?.text}
        secondaryOnClick={() => {
          secondaryButton?.actions.forEach((individualAction) =>
            dispatch(individualAction())
          );
          secondaryButton?.nonActions.forEach((nonAction) => nonAction());
        }}
        wideButton={cfarExerciseError === AirCfarExerciseFailureEnum.Void}
      />
    );
  };

  if (cfarExerciseProgress === CfarExerciseProgress.Completed) {
    return (
      <Alert
        mobile={matchesMobile}
        showError={showError}
        cfarExerciseProgress={cfarExerciseProgress}
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        enableTopLeftIcon={enableTopLeftIcon}
        cfarExerciseError={cfarExerciseError}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
      />
    );
  }

  // Repayments is not the best fallback icon, but it's what we have now
  const fallbackIcon = icons.cfarWhite == null;

  return (
    <>
      {loading ? (
        <B2BLoadingPopup
          open
          fullScreen={true}
          message={loadingMessage()}
          popupSize="mobile"
        />
      ) : (
        <Box className="mobile-cfar-exercise">
          <Box className="mobile-cfar-exercise--header">
            <FontAwesomeIcon
              icon={faChevronLeft as IconProp}
              onClick={() => {
                onComplete ? onComplete() : history.goBack();
              }}
            />
          </Box>
          {/* Top Section */}
          <div className="cfar-details">
            {!matchesMobile && (
              <div className="cfar-details--header">
                {fallbackIcon ? (
                  <Icon name={IconName.Repayments} />
                ) : (
                  <img
                    src={icons.cfarWhite}
                    alt=""
                    className="cfar-exercise-icon"
                  />
                )}
                <span className="cfar-details--title">{t("AirCfar")}</span>
              </div>
            )}

            <div className="cfar-details--title">
              {t("cfarExercise.confirmCancellation")}
            </div>
            {cfarQuoteV1 ? (
              <CfarDetails
                cfarExerciseProgress={cfarExerciseProgress}
                selectedQuoteId={selectedCfarQuoteId}
                cfarQuoteV1={cfarQuoteV1}
                airlineNames={airlineNames}
                onSelectQuoteId={setSelectedCfarQuoteId}
              />
            ) : null}
          </div>
          {/* Middle Section */}
          <FlightTripCard
            flight={flight}
            flightSummary={departureFlightSummary}
            isDeparture={true}
          />
          {getReturnSlice(flight.bookedItinerary) ? (
            <FlightTripCard
              flight={flight}
              flightSummary={returnFlightSummary}
              isDeparture={false}
            />
          ) : null}

          <div className="cfar-details-cancellation-information">
            <span style={{ fontWeight: 800 }}>
              {t("cfarExercise.cancellationInformation.title")}
            </span>
            <ul>
              <li>
                {t("cfarExercise.cancellationInformation.expirationDuration")}
              </li>
              <li>{t("cfarExercise.cancellationInformation.allPassengers")}</li>
              <li>{t("cfarExercise.cancellationInformation.refunds")}</li>
              <li>{t("cfarExercise.cancellationInformation.email")}</li>
              <li>{t("cfarExercise.cancellationInformation.questions")}</li>
            </ul>
          </div>
          {/* Bottom Section */}
          <CfarProgressCta
            done={onComplete}
            cfarExerciseProgress={cfarExerciseProgress}
            quoteId={selectedCfarQuoteId}
            disableFloatingButton={getEnvVariables("clientName") !== "uber"}
            useCfarV1={!!cfarQuoteV1 && !cfarQuote}
            secondaryCta={!matchesMobile ? onCloseAndComplete : undefined}
          />
        </Box>
      )}
      <Alert
        mobile={matchesMobile}
        showError={showError}
        cfarExerciseProgress={cfarExerciseProgress}
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        enableTopLeftIcon={enableTopLeftIcon}
        cfarExerciseError={cfarExerciseError}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
      />
    </>
  );
};
