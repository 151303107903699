import { useMemo } from "react";
import { Typography } from "@material-ui/core";

import {
  Header as GenericHeader,
  HeaderHomePageRightSection,
  HeaderMenuLinkType,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { HeaderLogos } from "../MobileHeader";
import { PATH_HOME, UNPROTECTED_PATHS } from "../../../../utils/urlPaths";
import { FAQS_LINK } from "../../constants";
import "./styles.scss";
import { useLocation } from "react-router-dom-v5-compat";

interface IHeaderProps {
  openSupportChat?: () => void;
}

// TODO: Nubank may have a desktop view in the future.
const DesktopHeader = ({ openSupportChat }: IHeaderProps) => {
  return (
    <GenericHeader
      className={"b2b nubank-generic-header"}
      fullWidth
      left={
        <a href={PATH_HOME}>
          <HeaderLogos />
        </a>
      }
      toolbarClassName="b2b"
      right={<NubankHeaderHomePageRightSection />}
    />
  );
};

interface INubankHeaderHomePageRightSectionProps {
  displayOnlyIcon?: boolean;
}

const NubankHeaderHomePageRightSection = (
  props: INubankHeaderHomePageRightSectionProps
) => {
  const location = useLocation();
  const { t, brand } = useI18nContext();
  const { displayOnlyIcon, ...routerProps } = props;

  const links = useMemo(
    () => [
      {
        type: HeaderMenuLinkType.Faq,
        selected: false,
        content: <Typography>{t("faq")}</Typography>,
        onClick: () => window.open(FAQS_LINK, "_blank"),
        dropdownContent: null,
        displayOnlyIcon: displayOnlyIcon,
      },
    ],
    [displayOnlyIcon, t]
  );

  const getLinks = () => {
    return UNPROTECTED_PATHS.includes(location.pathname)
      ? links.slice(0, 2)
      : links;
  };

  return (
    <HeaderHomePageRightSection
      links={getLinks()}
      {...routerProps}
      className="b2b"
      supportedLanguages={brand.supportedLanguages}
    />
  );
};

export default DesktopHeader;
