import { Box } from "@material-ui/core";
import clsx from "clsx";

import { FlightShopStep } from "../../reducer";
import DesktopFlightShopHeader from "./components/DesktopFlightShopHeader";
import MobileFlightShopHeader from "./components/MobileFlightShopHeader";
import { FlightShopHeaderConnectorProps } from "./container";
import "./styles.scss";
import { Slot } from "@hopper-b2b/ui";

export interface IFlightShopHeaderProps extends FlightShopHeaderConnectorProps {
  isMobile: boolean;
  isMediumDesktop?: boolean;
  isInPriceFreezePurchase?: boolean;
  isInSimilarFlights?: boolean;
  onFiltersClick?: () => void;
}

export const FlightShopHeader = ({
  currentStep,
  isOneWay,
  destination,
  origin,
  hasChosenDepartureFlight,
  isMobile,
  isMediumDesktop,
  awaitingRefetch,
  isInPriceFreezePurchase,
  isInSimilarFlights,
  onFiltersClick,
}: IFlightShopHeaderProps) => {
  if (isMobile && currentStep === FlightShopStep.FareDetails) {
    return null;
  }
  return (
    <Box
      className={clsx("flight-shop-header-root", {
        mobile: isMobile,
        "medium-desktop": isMediumDesktop,
      })}
    >
      {currentStep === FlightShopStep.ReviewItinerary && (
        <Slot id="flight-shop-review-itinerary-large-header" />
      )}
      <Box
        className={clsx("flight-shop-header-container", {
          reviewItinerary: currentStep === FlightShopStep.ReviewItinerary,
        })}
      >
        {!isMobile ? (
          <DesktopFlightShopHeader
            isInSimilarFlights={isInSimilarFlights}
            hasChosenDepartureFlight={hasChosenDepartureFlight}
            currentStep={currentStep}
            isInPriceFreezePurchase={isInPriceFreezePurchase}
          />
        ) : (
          <MobileFlightShopHeader
            origin={origin}
            destination={destination}
            currentStep={currentStep}
            hasChosenDepartureFlight={hasChosenDepartureFlight}
            awaitingRefetch={awaitingRefetch}
            isOneWay={isOneWay}
            onFiltersClick={onFiltersClick}
          />
        )}
      </Box>
    </Box>
  );
};
