import {
  LodgingData,
  LodgingMediaAssetEnum,
  PriceQuoteV2,
  RoomInfo,
} from "@b2bportal/lodging-api";
import * as Base from "./component";
import {
  LodgingSelectors,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import dayjs from "dayjs";

export const HotelSummary = () => {
  const fromDate = useSelector(LodgingSelectors.getHotelFromDate);
  const untilDate = useSelector(LodgingSelectors.getHotelUntilDate);
  const selectedLodging = useSelector(LodgingSelectors.getSelectedLodging);
  const selectedRoom = useSelector(LodgingSelectors.getSelectedRoom);
  const guestCount = useSelector(LodgingSelectors.getGuestCount);
  const selectedRoomMediaUrl = useSelector(
    LodgingSelectors.getSelectedRoomMediaUrl
  );

  const lodgingProduct = useSelector(
    LodgingSelectors.getQuotedLodgingProduct
  ) as PriceQuoteV2;

  const rehydratedLodging: Partial<LodgingData> = {
    name: lodgingProduct.hotelName,
    media: [
      {
        url: selectedRoomMediaUrl,
        LodgingMediaAsset: LodgingMediaAssetEnum.NonResizableImage,
      },
    ],
  };

  const rehydratedRoom: Partial<RoomInfo> = {
    name: lodgingProduct.roomName,
    media: [
      {
        url: selectedRoomMediaUrl,
        LodgingMediaAsset: LodgingMediaAssetEnum.NonResizableImage,
      },
    ],
    beds: { description: lodgingProduct.bedType?.[0] || "", bedTypes: [] },
    maxAdults: 2,
  };

  const isRefreshed = !selectedLodging || !selectedRoom;

  const lodging = isRefreshed ? rehydratedLodging : selectedLodging.lodging;
  const room = isRefreshed ? rehydratedRoom : selectedRoom.roomInfo;

  return (
    <Base.HotelSummary
      fromDate={dayjs(fromDate)}
      untilDate={dayjs(untilDate)}
      lodging={lodging}
      room={room}
      guestCount={guestCount}
    />
  );
};
