import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { IconName } from "../../Icon";
import { IconComponent } from "../../IconComponent";
import { MobileFloatingButton } from "../../MobileFloatingButton";
import { MobileFloatingSection } from "../../MobileFloatingSection";
import "./styles.scss";

export interface IReviewItineraryFloatingSectionProps {
  onContinue: () => void;
  handlePriceFreezeButtonClicked: () => void;
  priceFreezeAvailable: boolean;
  imgSrc?: string;
  priceString?: string;
  primaryButtonText?: string;
}

export const ReviewItineraryFloatingSection = (
  props: IReviewItineraryFloatingSectionProps
) => {
  const { t } = useI18nContext();
  const {
    onContinue,
    handlePriceFreezeButtonClicked,
    priceFreezeAvailable,
    imgSrc,
    priceString,
    primaryButtonText,
  } = props;

  return priceFreezeAvailable ? (
    <MobileFloatingSection
      className="review-itinerary-button-container"
      description={
        priceString ? (
          <div className="review-itinerary-button-container-description">
            <div className="review-itinerary-button-container-description-icon">
              {imgSrc ? (
                <img src={imgSrc} alt="Price freeze lock icon" />
              ) : (
                <IconComponent
                  ariaLabel="Price freeze lock icon"
                  name={IconName.Lock}
                />
              )}
            </div>
            <div className="review-itinerary-button-container-description-content">
              <p className="title">
                {t("reviewItinerary.priceFreezeDescriptionTitle", {
                  price: priceString,
                })}
              </p>
              <p className="subtitle">
                {t("reviewItinerary.priceFreezeDescriptionSubtitle")}
              </p>
            </div>
          </div>
        ) : null
      }
      primaryButton={{
        children: primaryButtonText || t("reviewItinerary.continueBooking"),
        onClick: onContinue,
        className: clsx(
          "review-itinerary-button",
          "review-itinerary-button-continue"
        ),
        wrapperClassName: "review-itinerary-button-wrapper",
      }}
      secondaryButton={{
        children: t("reviewItinerary.freezePrice"),
        onClick: handlePriceFreezeButtonClicked,
        className: clsx(
          "review-itinerary-button",
          "review-itinerary-button-freeze"
        ),
        wrapperClassName: "review-itinerary-button-wrapper",
      }}
    />
  ) : (
    <MobileFloatingButton
      className={clsx(
        "review-itinerary-button",
        "review-itinerary-button-continue"
      )}
      wrapperClassName="review-itinerary-button-container"
      onClick={onContinue}
      wide
    >
      {primaryButtonText || t("reviewItinerary.continueBooking")}
    </MobileFloatingButton>
  );
};
