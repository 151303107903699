import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import { notifySuccessfulAuthPath } from "./paths";

export const notifySuccessfulAuth = (apiConfig?: IApiConfig): Promise<null> =>
  new Promise((resolve, reject) => {
    axiosInstance
      .post(notifySuccessfulAuthPath)
      .then((res) => {
        const responseBody = res.data;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });
