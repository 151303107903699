import { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  FlightSummaryPanel,
  MobilePopoverCard,
  ActionButton,
} from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState, TripCategory } from "@hopper-b2b/types";
import clsx from "clsx";

import { FlightShopStep } from "../../reducer";
import { OriginalPriceFreezeInfoConnectorProps } from "./container";
import "./styles.scss";

type IOriginalPriceFreezeInfoProps = OriginalPriceFreezeInfoConnectorProps;

export const OriginalPriceFreezeInfo = (
  props: IOriginalPriceFreezeInfoProps
) => {
  const {
    tripCategory,
    flightShopStep,
    flightSummary,
    frozenPrice,
    fetchSimilarFlightsCallState,
  } = props;
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [showChooseSimilarFlightPopover, setShowChooseSimilarFlightPopover] =
    useState<boolean>(false);

  const renderChooseSimilarFlightCopy = () => {
    return (
      <>
        <Typography
          className="title"
          variant="h4"
          dangerouslySetInnerHTML={{
            __html: matchesMobile
              ? t("originalPriceFreeze.similarFlightTitle")
              : `<strong>${t(
                  "originalPriceFreeze.similarFlightTitle"
                )}</strong>`,
          }}
        />
        <Typography className="subtitle" variant="body2">
          {t("originalPriceFreeze.similarFlightSubtitle")}
        </Typography>
      </>
    );
  };

  const onClosePopover = () => setShowChooseSimilarFlightPopover(false);

  useEffect(() => {
    if (fetchSimilarFlightsCallState === CallState.Success) {
      setShowChooseSimilarFlightPopover(true);
    }
  }, [fetchSimilarFlightsCallState]);

  return (
    <>
      <Box
        className={clsx("original-price-freeze-info-root", {
          mobile: matchesMobile,
        })}
      >
        <Box className="original-price-freeze-info-container">
          <Box className="similar-flight-description-section">
            {renderChooseSimilarFlightCopy()}
          </Box>
          {(flightSummary || (frozenPrice && tripCategory)) && (
            <Box className="original-price-freeze-details-section">
              {flightSummary && (
                <Box className="unavailable-flight-details-section">
                  <Box className="unavailable-flight-details-wrapper">
                    <Typography
                      className="unavailable-flight-title"
                      variant="h4"
                    >
                      {t(
                        flightShopStep === FlightShopStep.ChooseReturn
                          ? "originalPriceFreeze.unavailableReturnTitle"
                          : "originalPriceFreeze.unavailableOutboundTitle"
                      )}
                    </Typography>
                    <FlightSummaryPanel
                      className="original-price-freeze-flight-summary"
                      {...flightSummary}
                      isMobile={matchesMobile}
                    />
                  </Box>
                </Box>
              )}
              {frozenPrice && tripCategory && (
                <Box className="frozen-price-details-section">
                  <Box className="frozen-price-details-wrapper">
                    <Typography className="title" variant="body1">
                      {t("originalPriceFreeze.title")}
                    </Typography>
                    <Typography className="price" variant="body1">
                      <strong>
                        {t("originalPriceFreeze.priceDetailsTitle")}
                      </strong>
                    </Typography>
                    <Typography className="details" variant="body2">
                      {t(
                        tripCategory === TripCategory.ROUND_TRIP
                          ? "originalPriceFreeze.roundTripCategory"
                          : "originalPriceFreeze.oneWayTripCategory"
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {matchesMobile && showChooseSimilarFlightPopover && (
        <MobilePopoverCard
          open={showChooseSimilarFlightPopover}
          onClose={onClosePopover}
          className={"mobile-choose-similar-flight-copy-popover-root"}
          contentClassName={
            "mobile-choose-similar-flight-copy-popover-content-wrapper"
          }
          bottomButton={
            <ActionButton
              className={clsx(
                "choose-similar-flight-continue-button-root",
                "b2b"
              )}
              buttonClassName="continue-button"
              onClick={onClosePopover}
              message={t("continue")}
              defaultStyle={"h4r-primary"}
            />
          }
          centered={true}
        >
          <Box className="mobile-choose-similar-flight-copy-wrapper">
            {renderChooseSimilarFlightCopy()}
          </Box>
        </MobilePopoverCard>
      )}
    </>
  );
};
