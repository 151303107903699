import { IdEnum, RegionType, Suggestion } from "@b2bportal/air-shopping-api";
import { Autocomplete, AutocompleteProps } from "../../common";
import { fetchLocations } from "./fetchLocations";

export type FlightLocationAutoCompleteProps = Omit<
  AutocompleteProps<Suggestion>,
  "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
>;

export const FlightLocationAutoComplete = ({
  id,
  defaultValue,
  label,
  disabled,
  onChange,
  onOpen,
  icon,
  endIcon,
  additionalSearchControl,
  enableOpenOnFocus,
  className,
}: FlightLocationAutoCompleteProps) => (
  <Autocomplete
    id={id}
    className={className}
    paperClassName={`${id}-autocomplete-results`}
    defaultValue={defaultValue}
    label={label}
    disabled={disabled}
    onChange={onChange}
    fetch={(apiConfig, search, callback) =>
      fetchLocations(apiConfig, search, callback, additionalSearchControl)
    }
    sortOptions={sortLocations}
    groupBy={groupLocations}
    getOptionLabel={getOptionLabel}
    getGroupLabelKey={getGroupLabelKey}
    icon={icon}
    endIcon={endIcon}
    onOpen={onOpen}
    enableOpenOnFocus={enableOpenOnFocus}
  />
);

const groupLocations = (value?: Suggestion): RegionType | null =>
  value?.id?.Id === IdEnum.Flight ? value?.id?.code?.regionType : null;

const sortLocations = (options?: Suggestion[]): Suggestion[] => {
  const cities = [];
  const airports = [];

  options?.forEach((suggestion: Suggestion) => {
    const group = groupLocations(suggestion);
    if (group !== null) {
      group === RegionType.city
        ? cities.push(suggestion)
        : airports.push(suggestion);
    }
  });

  return [...cities, ...airports];
};

const getOptionLabel = (value?: Suggestion) => value?.label;
const getGroupLabelKey = (group: string) => `searchControl.${group}`;
