import { GordianSeat } from "@b2bportal/air-seats-api";
import { GordianSeatSegment } from "@hopper-b2b/types";
import { HopperSeatTotal } from "./context";

export enum SeatEventTypes {
  CLEAR_SEAT_ERROR = "CLEAR_SEAT_ERROR",
  SELECT_SEATS = "SELECT_SEATS",
  SKIP_SEAT_SELECTION = "SKIP_SEAT_SELECTION",
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
}

export interface ClearSeatError {
  type: SeatEventTypes.CLEAR_SEAT_ERROR;
}

export interface SelectSeat {
  type: SeatEventTypes.SELECT_SEATS;
  seats: Array<GordianSeat>;
  seatSegments: Array<GordianSeatSegment>;
  selectedSeatTotal?: HopperSeatTotal;
}

export interface SkipSeatSelection {
  type: SeatEventTypes.SKIP_SEAT_SELECTION;
}

export interface SeatSelectionNextEvent {
  type: SeatEventTypes.NEXT;
}

export interface SeatSelectionPreviousEvent {
  type: SeatEventTypes.PREVIOUS;
}

export type SeatEvents =
  | ClearSeatError
  | SelectSeat
  | SkipSeatSelection
  | SeatSelectionNextEvent
  | SeatSelectionPreviousEvent;
