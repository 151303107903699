import { useCallback } from "react";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";
import "./styles.scss";
import { ActionButton } from "@hopper-b2b/ui-core";

export interface ITripCategoryOption {
  label: string;
  subtitle: string;
  category: TripCategory;
  iconSrc: string;
}

export interface IFlightCategoryPickerProps {
  setTripCategory: (tripCategory: TripCategory) => void;
  category: TripCategory;
  icons: any; // TODO: update type
  onClose: () => void;
  classes?: string[];
  className?: string;
}
export const FlightCategoryPicker = ({
  setTripCategory,
  category,
  icons,
  onClose,
  classes = [],
}: IFlightCategoryPickerProps) => {
  const { t } = useI18nContext();

  const TRIP_TYPES: ITripCategoryOption[] = [
    {
      label: t("oneWay"),
      subtitle: t("oneWayDescription"),
      category: TripCategory.ONE_WAY,
      iconSrc: icons[TripCategory.ONE_WAY],
    },
    {
      label: t("roundTrip"),
      subtitle: t("roundTripDescription"),
      category: TripCategory.ROUND_TRIP,
      iconSrc: icons[TripCategory.ROUND_TRIP],
    },
  ];

  const getLabel = useCallback(
    (label: string, subtitle: string, category: string, iconSrc: string) => {
      return (
        <div className={clsx("trip-category-label-container", category)}>
          <div className="trip-category-label-icon">
            {iconSrc ? (
              <img src={iconSrc} alt={`${label} icon`} />
            ) : (
              <IconComponent
                className="mobile-flight-search-trip-category-icon"
                name={
                  category === TripCategory.ONE_WAY
                    ? IconName.ArrowRight
                    : IconName.Arrows
                }
              />
            )}
          </div>
          <div>
            <div className="trip-category-label">{label}</div>
            <div className="trip-category-subtitle">{subtitle}</div>
          </div>
        </div>
      );
    },
    []
  );

  const renderRadios = () =>
    TRIP_TYPES.map(({ label, subtitle, category, iconSrc }) => (
      <FormControlLabel
        key={category}
        value={category}
        label={getLabel(label, subtitle, category, iconSrc)}
        labelPlacement="start"
        control={
          <Radio
            className="trip-category-radio"
            icon={<span className="radio-icon" />}
            checkedIcon={
              <span className={clsx("radio-icon", "checked-radio-icon")} />
            }
          />
        }
      />
    ));

  const handleTripCategoryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTripCategory(e.target.value as TripCategory);
    },
    [setTripCategory]
  );

  return (
    <div className="flight-category-picker">
      <div className="flight-category-picker-title">
        {t("tripCategory.modalTitle")}
      </div>
      <FormControl className={clsx("trip-category-form", ...classes)}>
        <RadioGroup
          defaultValue={TripCategory.ROUND_TRIP}
          onChange={handleTripCategoryChange}
          className="trip-category-radio-group"
          value={category}
        >
          {renderRadios()}
        </RadioGroup>
        <Box m={3} className="flight-category-picker-cta-container">
          <ActionButton
            className="flight-category-picker-cta"
            onClick={onClose}
            message={t("tripCategory.cta")}
            fullWidth
          />
        </Box>
      </FormControl>
    </div>
  );
};
