import { connect, ConnectedProps } from "react-redux";
import { TripCategory } from "@hopper-b2b/types";
import { IStoreState } from "../../../..../../../../../reducers/types";
import {
  getFareclassOptionFilter,
  getMaxPriceFilter,
} from "../../../../../search/reducer";
import { FlightShopStep } from "../../../../reducer";
import {
  flightInfoSelector,
  flightFareDetailsSelector,
  flightShopProgressSelector,
  selectedTripSelector,
} from "../../../../reducer/selectors";
import { getSelectedAccountReferenceId } from "../../../../../rewards/reducer";
import { FlightListInfo } from "./component";

export interface IFlightListInfoProps {
  tripId: string;
  onFareClick: (fareId: string, sliceId: string) => void;
}

const mapStateToProps = (state: IStoreState, props: IFlightListInfoProps) => ({
  tripSummary: flightInfoSelector(state, props.tripId),
  isReturn: flightShopProgressSelector(state) === FlightShopStep.ChooseReturn,
  fareDetails: flightFareDetailsSelector(state, props.tripId),
  outgoingFareRating: selectedTripSelector(state).outgoingFareRating,
  isRoundTrip: TripCategory.ROUND_TRIP === state.flightSearch.tripCategory,
  maxPriceFilter: getMaxPriceFilter(state),
  fareClassFilter: getFareclassOptionFilter(state),
  rewardsKey: getSelectedAccountReferenceId(state) ?? undefined,
  onFareClick: props.onFareClick,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListInfoConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightListInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightListInfo);
