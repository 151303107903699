import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { DateRangePicker, PickerType, Slot } from "@hopper-b2b/ui";
import "./styles.scss";
import { IMonthBucket, TripCategory } from "@hopper-b2b/types";
import { hasHTSConnectSupport, useTenantIcons } from "@hopper-b2b/utilities";

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}

export interface IMonthAndDatePickerProps {
  viewType: MonthAndDatePickerType;
  header?: JSX.Element | string;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setStartDate?: (value: Date | null) => void;
  setEndDate?: (value: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
  tripCategory: TripCategory;
  priceTags?: string[];
  currency: string;
  months?: IMonthBucket[];
  startDateLabel?: string;
  endDateLabel?: string;
  startDateIcon?: React.ReactElement;
  endDateIcon?: React.ReactElement;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  overrideScrollOnStart?: boolean;
  isDateUnavailable?: (date: Date, selectingReturnDate: boolean) => boolean;
  showPriceRangeTags?: boolean;
}

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const {
    startDate,
    endDate,
    priceTags = [],
    // currency,
    setStartDate,
    setEndDate,
    viewType,
    header,
    focusedMonthIndex,
    setFocusedMonthIndex,
    className,
    tripCategory,
    currency = "",
    months,
    startDateLabel,
    endDateLabel,
    startDateIcon,
    endDateIcon,
    minAllowedDate = dayjs().toDate(),
    maxAllowedDate = dayjs().add(1, "year").toDate(),
    overrideScrollOnStart,
    isDateUnavailable,
    showPriceRangeTags = true,
  } = props;
  const isHTSConnect = hasHTSConnectSupport();
  const tenantIcons = useTenantIcons();

  const pickerType =
    tripCategory === TripCategory.ROUND_TRIP
      ? PickerType.RANGE
      : PickerType.DAY;

  return (
    <>
      {header && (
        <Box className="month-and-date-picker-header-container">
          <Typography component="h2" variant="body2">
            {header}
          </Typography>
        </Box>
      )}
      <Slot id="search-calendar-banner" />
      <DateRangePicker
        hideSelectedDateIcon={!isHTSConnect}
        className={className}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
        months={months}
        showPriceRangeTags={showPriceRangeTags}
        priceTags={priceTags}
        currency={currency ?? ""}
        startDate={startDate}
        endDate={endDate}
        startDateIcon={startDateIcon}
        endDateIcon={endDateIcon}
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        assets={isHTSConnect ? tenantIcons : undefined}
        {...(viewType === MonthAndDatePickerType.Horizontal ||
        viewType === MonthAndDatePickerType.Column
          ? { setStartDate, setEndDate, pickerType }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Column
          ? { focusedMonthIndex, columnView: true }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Group
          ? { setFocusedMonthIndex, groupView: true }
          : undefined)}
        overrideScrollOnStart={overrideScrollOnStart}
        isDateUnavailable={isDateUnavailable}
      />
    </>
  );
};
