const config = {
  ENV: import.meta.env.MODE,
  googleMapsApiKey: import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_VERSION: import.meta.env.VITE_REACT_APP_VERSION,
  MOCK: import.meta.env.VITE_MOCK === "true" ?? false,
  SIFT_ACCOUNT: import.meta.env.VITE_SIFT_ACCOUNT,
  RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_KEY,
  googleIdentityClientId: import.meta.env.VITE_GOOGLE_IDENTITY_CLIENT_ID,
  NUBANK_URL: "", // used to post messages to webview/iframe like uber
  ENCRYPT_SALT: import.meta.env.VITE_ENCRYPT_SALT,
  ENCRYPT_IV: import.meta.env.VITE_ENCRYPT_IV,
  KUSTOMER_CHAT_SUPPORT: import.meta.env.VITE_KUSTOMER_CHAT_SUPPORT,
  KUSTOMER_API_KEY: import.meta.env.VITE_KUSTOMER_API_KEY,
  KUSTOMER_BRAND_ID: import.meta.env.VITE_KUSTOMER_BRAND_ID,
};

export default config;
