import { useI18nContext } from "@hopper-b2b/i18n";
import { Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useCallback } from "react";
import { useHistory } from "react-router";
import styles from "./Breadcrumbs.module.scss";
import { ReactComponent as BackIcon } from "./back.svg";
import { ReactComponent as DividerIcon } from "./divider.svg";

export interface BreadcrumbStep {
  key: string;
  label: string;
  onClick?: () => void;
  isSelected: boolean;
  visited?: boolean;
  hide?: boolean;
  disabled?: boolean;
}

interface BreadcrumbsProps {
  className?: string;
  steps: Array<BreadcrumbStep>;
  showBack?: boolean;
}

const isStepDisabled = (step: BreadcrumbStep) => {
  return !(step.visited || step.isSelected);
};

export const Breadcrumbs = ({
  className,
  steps,
  showBack,
}: BreadcrumbsProps) => {
  const filteredSteps = steps.filter((step) => !step.hide);
  return (
    <Grid
      container
      alignItems="center"
      className={clsx("breadcrumbs", className)}
      spacing={1}
    >
      {showBack && (
        <Grid item>
          <BackButton />
        </Grid>
      )}
      {filteredSteps.map((step, index) => (
        <Fragment key={step.key}>
          <Grid item>
            <Button
              onClick={step.onClick}
              disabled={step.disabled || isStepDisabled(step)}
              size="small"
            >
              <Typography
                className={clsx({
                  [styles.selected]: step.isSelected,
                  selected: step.isSelected,
                })}
                color={step.isSelected ? "textPrimary" : "textSecondary"}
                variant="inherit"
              >
                {step.label}
              </Typography>
            </Button>
          </Grid>
          {index < filteredSteps.length - 1 && (
            <Grid item>
              <DividerIcon className={styles.icon} />
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

const BackButton = () => {
  const history = useHistory();
  const { t } = useI18nContext();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Button
      className={clsx(styles.backButton, "breadcrumbs-back-btn")}
      variant="outlined"
      size="small"
      startIcon={<BackIcon className={styles.backIcon} />}
      onClick={goBack}
    >
      <Typography variant="inherit" color="primary">
        {t?.("back")}
      </Typography>
    </Button>
  );
};
