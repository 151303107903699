import { useMemo } from "react";
import clsx from "clsx";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { removeTimezone } from "@hopper-b2b/utilities";
import { IFlightCardProps } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightCardFields,
  Airline,
  StopSummary,
  FlightDuration,
  AlgomerchTags,
  ItemPrice,
} from "@hopper-b2b/ui";
import { ReactComponent as ChevronDown } from "../../../assets/client/chevron-down.svg";
import styles from "./styles.module.scss";

export const NubankFlightCard = (props: IFlightCardProps) => {
  const {
    duration,
    currentPriceText,
    departureTime,
    arrivalTime,
    airports,
    brandName,
    primaryCarrier,
    airlineName,
    tags,
    tagsLimit,
    onClickTag,
    fiat,
    originCode,
    destinationCode,
    additionalInfo,
    isRoundTrip,
  } = props;

  const { formatFiatCurrency, t, language: locale } = useI18nContext();
  const { layoverDuration } = additionalInfo;

  // cashback is a standard 1%, always
  const cashbackAmount = formatFiatCurrency(
    { ...fiat, value: Math.floor(fiat.value * 0.01) },
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );

  const departureTimeFormatted = useMemo(
    () =>
      formatDateTime(
        new Date(removeTimezone(departureTime)),
        locale,
        DateTimeFormatStyle.ShortTime
      ),
    [departureTime]
  );

  const arrivalTimeFormatted = useMemo(
    () =>
      formatDateTime(
        new Date(removeTimezone(arrivalTime)),
        locale,
        DateTimeFormatStyle.ShortTime
      ),
    [arrivalTime]
  );

  const description = useMemo(
    () => (isRoundTrip ? t("roundTripCategoryTotal") : t("tripTotal")),
    [isRoundTrip]
  );

  return (
    <FlightCardFields className={styles.nubankFlightCard}>
      <div className="flight-card-top-row tags">
        <div className="flight-card-cash-back">
          <p>{t("amountCashBack", { amount: cashbackAmount })}</p>
        </div>
        {tags.length > 0 ? (
          <AlgomerchTags
            tags={tags}
            tagsLimit={tagsLimit}
            onClickTag={onClickTag}
          />
        ) : null}
      </div>
      <div className="flight-card-middle-row">
        <div className="flight-card-left">
          <div className="flight-card-timing">
            <div className="flight-card-timing-details departure">
              <span className="flight-card-timing-time">
                {departureTimeFormatted}
              </span>
              <span className="flight-card-timing-airline-code">
                {originCode}
              </span>
            </div>
            <div className={"flight-card-timing-bar"}>
              {airports?.length > 0 ? (
                <div
                  className={clsx("flight-card-timing-bar stops", {
                    oneStop: airports?.length === 1,
                    multipleStops: airports?.length > 1,
                  })}
                ></div>
              ) : null}
            </div>
            <div className="flight-card-timing-details arrival">
              <span className="flight-card-timing-time">
                {arrivalTimeFormatted}
              </span>
              <span className="flight-card-timing-airline-code">
                {destinationCode}
              </span>
            </div>
          </div>
        </div>
        <div className="flight-card-right">
          <ItemPrice current={currentPriceText} />
          <span className="trip-total-label">{description}</span>
        </div>
      </div>
      <div className="flight-card-bottom-row">
        <div className="flight-details">
          <Airline
            className="flight-details-icon"
            code={primaryCarrier}
            name={airlineName}
            iconOnly
          />
          <div className="flight-details-content">
            <div className="flight-details-content-row duration-info">
              <FlightDuration duration={duration} />
              <div className="separator"></div>
              <StopSummary
                airports={airports}
                separator=":"
                maxAirports={3}
                layoverDuration={
                  airports?.length === 1 ? layoverDuration : null
                }
              />
            </div>
            <div className="flight-details-content-row airline-info">
              <p className="airline-name">{airlineName}</p>
              <div className="separator"></div>
              {brandName ? <p className="fare-brand">{brandName}</p> : ""}
            </div>
          </div>
        </div>
        <ChevronDown
          className={clsx("chevron", { expanded: additionalInfo?.isExpanded })}
        />
      </div>
    </FlightCardFields>
  );
};
