import { FormLabel, Radio } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";

export const RadioListItem = <T extends object>({
  isSelected,
  isDisabled,
  value,
  onSelect,
  id,
  label,
}: {
  isSelected: boolean;
  isDisabled?: boolean;
  value: T;
  onSelect: (value: T, id?: string) => void;
  id: string;
  label: React.ReactNode;
}) => {
  return (
    <div
      className={clsx(styles.radioListItem, "radio-form", {
        selected: isSelected,
        disabled: isDisabled,
      })}
    >
      <Radio
        className="radio-button"
        checked={isSelected}
        value={value}
        onChange={() => onSelect(value, id)}
        id={id}
        disabled={isDisabled}
      />
      <FormLabel
        htmlFor={id}
        className={clsx("radio-content-label", { disabled: isDisabled })}
      >
        <p className="label">{label}</p>
      </FormLabel>
    </div>
  );
};
