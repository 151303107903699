/* eslint-disable prefer-const */
import {
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CHFAR_ID_QUERY_PARAM,
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  FLIGHT_SHOP_TYPE,
  FlightShopType,
  ITripTerminus,
  PRICE_FREEZE_ID_QUERY_PARAM,
  SliceStopCountFilter,
  TripCategory,
  TripDetails,
  WATCH_ENTRY,
  UTM_SOURCE_QUERY_PARAM,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { put, putResolve, select } from "redux-saga/effects";

import {
  LocationQuery,
  LocationQueryEnum,
  LocationResponse,
} from "@b2bportal/air-shopping-api";
import { fetchLocationAutocomplete, trackEvent } from "@hopper-b2b/api";
import * as H from "history";
import queryStringParser from "query-string";
import { fetchTripDetails as fetchDetails } from "../../../api/v1/shop/fetchTripDetails";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { PATH_SHOP } from "../../../utils/urlPaths";
import { getCfarOfferQuoteId } from "../../cfar/reducer";
import { getChfarOfferQuoteId } from "../../chfar/reducer";
import { actions as searchActions } from "../../search/actions";
import {
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getDestination,
  getDestinationType,
  getFareclassOptionFilter,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getOrigin,
  getOriginType,
  getReturnDate,
  getStopsOptionFilter,
  getTripCategory,
} from "../../search/reducer/selectors";
import { actions } from "../actions";
import {
  IFlightShopOverwriteQueryParams,
  IPopulateFlightShopQueryParams,
} from "../actions/actions";
import {
  flightShopProgressSelector,
  flightShopTypeSelector,
  getChfarId,
  selectedTripSelector,
} from "../reducer/selectors";
import {
  IFlightShopParsedQuery,
  parseQueryString,
  pushToPathWithExistingQueryParams,
} from "../utils/parseQueryString";
import { MINIMUM_ADULT_COUNT } from "../../search/reducer";

const isRoundtrip = (tripCategory: TripCategory): boolean =>
  tripCategory === TripCategory.ROUND_TRIP;

export function* populateFlightShopQueryParamsSaga(
  setUpQueryParamsAction: IPopulateFlightShopQueryParams
) {
  const {
    history,
    prevPath,
    useHistoryPush,
    forceQueryUpdate,
    newQueryParams,
  } = setUpQueryParamsAction;
  const state: IStoreState = yield select();
  yield populateFlightShopQueryParametersFromState({
    state,
    history,
    prevPath,
    useHistoryPush,
    forceQueryUpdate,
    newQueryParams,
  });
}

export function* populateFlightShopQueryParametersFromState({
  state,
  history,
  prevPath,
  useHistoryPush,
  forceQueryUpdate,
  newQueryParams,
  isV2,
}: {
  state: IStoreState;
  history: H.History;
  prevPath?: string;
  useHistoryPush?: boolean;
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
  isV2?: boolean;
}) {
  const queryString = parseQueryString(history);
  let {
    departureDate,
    returnDate,
    origin,
    destination,
    originType,
    destinationType,
    tripCategory,
    stopsOption,
    noLCC,
    flightShopProgress,
    flightShopType,
    cfarOfferQuoteId,
    chfarOfferQuoteId,
    chfarId,
    infantsInSeatCount,
    infantsOnLapCount,
    adultsCount,
    childrenCount,
  } = getExistingStateVariables(state);

  const apiConfig = state[flightApiConfigStoreKey];
  const selectedTrip = selectedTripSelector(state);
  if (
    selectedTrip.tripId &&
    ((!origin && !queryString.origin) ||
      (!destination && !queryString.destination))
  ) {
    const tripDetails: TripDetails = yield fetchDetails(
      selectedTrip.tripId,
      apiConfig
    );
    yield populateStateFromTripDetails(tripDetails, state);
  }

  const newFlightShopType =
    newQueryParams?.flightShopType ??
    queryString?.flightShopType ??
    flightShopType;

  switch (newFlightShopType) {
    case FlightShopType.PRICE_FREEZE_EXERCISE:
      yield putResolve(
        actions.setFlightShopType(
          !isV2 ? FlightShopType.PRICE_FREEZE_EXERCISE : FlightShopType.DEFAULT
        )
      );
      break;
    case FlightShopType.CHFAR_EXERCISE:
      yield putResolve(
        actions.setFlightShopType(FlightShopType.CHFAR_EXERCISE)
      );
      break;
    default:
      // note: reset flight shop type to default when it's not given in newQueryParams nor queryString
      yield putResolve(
        actions.setFlightShopType(newFlightShopType ?? FlightShopType.DEFAULT)
      );
      break;
  }

  if (queryString[CHFAR_ID_QUERY_PARAM] && !chfarId) {
    yield putResolve(actions.setChfarId(queryString[CHFAR_ID_QUERY_PARAM]));
  }

  // note: updating flight shop progress state prior to updating URL query is mandatory
  const newFlightShopProgress =
    newQueryParams?.flightShopProgress ?? queryString?.flightShopProgress;
  if (newFlightShopProgress !== undefined) {
    yield putResolve(actions.setFlightShopProgress(newFlightShopProgress));
  }

  if (forceQueryUpdate || stateDiffersFromQueryParams(state, queryString)) {
    tripCategory = tripCategory || queryString.tripCategory;
    departureDate = departureDate || queryString.departureDate;
    returnDate = returnDate || queryString.returnDate;
    stopsOption = stopsOption || queryString.stopsOption;
    noLCC = noLCC || queryString.noLCC;
    flightShopProgress = flightShopProgress || queryString.flightShopProgress;
    cfarOfferQuoteId =
      cfarOfferQuoteId || (queryString.cfarOfferQuoteId as string);
    chfarOfferQuoteId =
      chfarOfferQuoteId || (queryString.chfarOfferQuoteId as string);
    const queryStringFlightShopType = queryString.flightShopType;
    const priceFreezeId = queryString.priceFreezeId;
    const newQuery = {
      tripCategory: tripCategory || queryString.tripCategory,
      // if origin/destination does not exist we should use the values from queryparams
      origin: !origin ? queryString.origin : origin?.id?.code?.code,
      destination: !destination
        ? queryString.destination
        : destination?.id?.code?.code,
      originType: !origin
        ? queryString.originType
        : origin?.id?.code?.regionType,
      destinationType: !destination
        ? queryString.destinationType
        : destination?.id?.code?.regionType,
      departureDate: departureDate && dayjs(departureDate).format("YYYY-MM-DD"),
      returnDate:
        isRoundtrip(tripCategory) && returnDate
          ? dayjs(returnDate).format("YYYY-MM-DD")
          : undefined,
      stopsOption:
        // note: when origin/destination does not exist, it has to be a page refresh;
        // do not overwrite stopsOption in the query param when it happens.
        !origin || !destination ? queryString.stopsOption : stopsOption,
      noLCC,
      flightShopProgress,
      infantsOnLapCount: infantsOnLapCount || queryString.infantsOnLapCount,
      infantsInSeatCount: infantsInSeatCount || queryString.infantsInSeatCount,
      childrenCount: childrenCount || queryString.childrenCount,
      adultsCount: adultsCount || queryString.adultsCount,
      [FLIGHT_SHOP_TYPE]: queryStringFlightShopType || flightShopType,
      [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: queryString.cfarOfferQuoteId,
      [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]: queryString.chfarOfferQuoteId,
      [CHFAR_ID_QUERY_PARAM]: chfarId || queryString[CHFAR_ID_QUERY_PARAM],
      [UTM_SOURCE_QUERY_PARAM]: queryString[UTM_SOURCE_QUERY_PARAM],
      ...newQueryParams,
    };
    const newSimilarFlightsQuery = {
      flightShopProgress,
      [FLIGHT_SHOP_TYPE]: queryStringFlightShopType,
      [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeId,
      ...newQueryParams,
    };
    const isSimilarFlights =
      newFlightShopType === FlightShopType.PRICE_FREEZE_EXERCISE;

    const location = {
      pathname: PATH_SHOP,
      search: queryStringParser.stringify(
        isSimilarFlights ? newSimilarFlightsQuery : newQuery
      ),
    };

    // the populateFlightShopQueryParams action is sometimes called from places where a "prevPath" state needs to be set
    // (e.g.: FlightsBook - when it's going back to FlightShop)
    if (prevPath) {
      location["state"] = { prevPath };
    }

    if (useHistoryPush) {
      history.push(location);
    } else {
      history.replace(location);
    }
  }

  // note: when isFromFlightWatch is passed from FlightWatch, remove it since it's no longer needed
  if (queryString.isFromFlightWatch) {
    trackEvent(
      {
        eventName: WATCH_ENTRY,
        properties: {},
      },
      apiConfig
    );
    pushToPathWithExistingQueryParams(
      history,
      PATH_SHOP,
      {
        isFromFlightWatch: undefined,
      },
      true
    );
  }

  return { ...queryString, ...newQueryParams };
}

export function getExistingStateVariables(state: IStoreState) {
  const fareclassOptionFilter = getFareclassOptionFilter(state);
  const noLCC =
    !fareclassOptionFilter.basic &&
    fareclassOptionFilter.luxury &&
    fareclassOptionFilter.enhanced &&
    fareclassOptionFilter.premium &&
    fareclassOptionFilter.standard;
  return {
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    originType: getOriginType(state),
    destinationType: getDestinationType(state),
    tripCategory: getTripCategory(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    stopsOption: getStopsOptionFilter(state),
    flightShopProgress: flightShopProgressSelector(state),
    flightShopType: flightShopTypeSelector(state),
    cfarOfferQuoteId: getCfarOfferQuoteId(state),
    chfarOfferQuoteId: getChfarOfferQuoteId(state),
    chfarId: getChfarId(state),
    noLCC,
  };
}

export function stateDiffersFromQueryParams(
  state: IStoreState,
  parsedQueryString: IFlightShopParsedQuery
) {
  const {
    departureDate,
    returnDate,
    origin,
    destination,
    originType,
    destinationType,
    tripCategory,
    stopsOption,
    noLCC,
    flightShopProgress,
    flightShopType,
    chfarId,
    infantsInSeatCount,
    infantsOnLapCount,
    adultsCount,
    childrenCount,
  } = getExistingStateVariables(state);

  const diffTripCategory =
    !tripCategory ||
    (tripCategory && parsedQueryString.tripCategory !== tripCategory);

  const diffOrigin =
    !origin || (origin && parsedQueryString.origin !== origin.id.code.code);
  const diffDestination =
    !destination ||
    (destination && parsedQueryString.destination !== destination.id.code.code);
  const diffOriginType =
    !originType ||
    (originType && parsedQueryString.originType !== origin.id.code.regionType);
  const diffDestinationType =
    !destinationType ||
    (destinationType &&
      parsedQueryString.destinationType !== destination.id.code.regionType);
  const diffDepartureDate =
    departureDate &&
    !dayjs(parsedQueryString.departureDate).isSame(dayjs(departureDate));
  const diffReturnDate =
    returnDate &&
    parsedQueryString.returnDate &&
    isRoundtrip(tripCategory) &&
    !dayjs(parsedQueryString.returnDate).isSame(dayjs(returnDate));
  /**
   * ANY_NUMBER is default value so we cannot check for undefined.
   * Instead we check if destination is undefined for page refresh senario.
   **/
  const diffStopOptions =
    stopsOption !== SliceStopCountFilter.ANY_NUMBER ||
    (destination && parsedQueryString.stopsOption !== stopsOption);

  const diffNoLCC = parsedQueryString.noLCC !== noLCC;

  const diffFlightShopProgress =
    parsedQueryString.flightShopProgress !== flightShopProgress;

  const diffFlightShopType =
    parsedQueryString.flightShopType !== flightShopType;

  const diffChfarId = parsedQueryString[CHFAR_ID_QUERY_PARAM] !== chfarId;

  const diffInfantsInSeatCount =
    parsedQueryString.infantsInSeatCount !== infantsInSeatCount;
  const diffInfantsOnLapCount =
    parsedQueryString.infantsOnLapCount !== infantsOnLapCount;
  const diffAdultsCount = parsedQueryString.adultsCount !== adultsCount;
  const diffChildrenCount = parsedQueryString.childrenCount !== childrenCount;

  // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
  return (
    !!(
      diffTripCategory ||
      diffOrigin ||
      diffDestination ||
      diffOriginType ||
      diffDestinationType ||
      diffReturnDate ||
      diffDepartureDate ||
      diffStopOptions ||
      diffNoLCC ||
      diffFlightShopProgress ||
      diffFlightShopType ||
      diffChfarId ||
      diffInfantsInSeatCount ||
      diffInfantsOnLapCount ||
      diffAdultsCount ||
      diffChildrenCount
    ) && !parsedQueryString.isFromFlightWatch
  );
}

function* populateStateFromTripDetails(
  tripDetails: TripDetails,
  state: IStoreState
) {
  const { departureDate, returnDate, origin, destination } =
    getExistingStateVariables(state);
  if (!origin || !destination) {
    const { correspondingOrigin, correspondingDestination } =
      yield fetchOriginDestination(
        tripDetails.slices[0].originCode,
        tripDetails.slices[0].destinationCode
      );
    yield put(searchActions.setOrigin(correspondingOrigin));
    yield put(searchActions.setDestination(correspondingDestination));

    yield put(
      searchActions.setOriginType(correspondingOrigin.id.code.regionType)
    );
    yield put(
      searchActions.setDestinationType(
        correspondingDestination.id.code.regionType
      )
    );
  }
  if (!departureDate) {
    yield put(
      searchActions.setDepartureDate(
        dayjs(tripDetails.slices[0].departureTime).toDate()
      )
    );
  }
  if (!returnDate && tripDetails.slices.length > 1) {
    yield put(
      searchActions.setReturnDate(
        dayjs(tripDetails.slices[1].departureTime).toDate()
      )
    );
  }
}

function* fetchOriginDestination(origin: string, destination: string) {
  const originRequestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: origin,
  };
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  const { categories: originCategories }: LocationResponse =
    yield fetchLocationAutocomplete(originRequestBody, apiConfig);
  const correspondingOrigin = originCategories
    .flatMap((category) => category.results)
    .find((result) => result.id.code.code === origin) as ITripTerminus;

  const destinationRequestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: destination,
  };

  const { categories: destinationCategories }: LocationResponse =
    yield fetchLocationAutocomplete(destinationRequestBody, apiConfig);
  const correspondingDestination = destinationCategories
    .flatMap((category) => category.results)
    .find((result) => result.id.code.code === destination) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
