import { connect, ConnectedProps } from "react-redux";
import {
  createWatchCallStateSelector,
  isWatchingSelector,
  listWatchCallStateSelector,
  predictionSelector,
} from "../../reducer/selectors";
import { FlightPriceWatchEntryCard } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { hasUnsupportedPredictionFilters } from "../../../search/reducer";
import { actions } from "../../actions";
import { CallState } from "@hopper-b2b/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    isWatching: isWatchingSelector(state),
    hasUnsupportedPredictionFilters: hasUnsupportedPredictionFilters(state),
    prediction: predictionSelector(state),
    createWatchCallState: createWatchCallStateSelector(state),
    listWatchCallState: listWatchCallStateSelector(state),
  };
};

const mapDispatchToProps = {
  createWatch: (email: string) => actions.createWatch(email),
  deleteWatch: () => actions.deleteWatch(),
  setCreateWatchCallState: (callState: CallState) =>
    actions.setCreateWatchCallState(callState),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceWatchEntryCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightPriceWatchEntryCard = connector(
  FlightPriceWatchEntryCard
);
