import { ChildMachineConfig, ParentState } from "@hopper-b2b/checkout";
import { NubankPaymentServiceTypes } from "./services";
import { NubankPaymentGuardTypes } from "./guards";
import { NubankPaymentActionTypes } from "./actions";
import { NubankPaymentChildState, NubankPaymentState } from "./types";

const NubankPaymentMachineID = "nubank-payment-machine";

export const getNubankPaymentMachine = ({
  parentMachineId,
  nextState,
}: ChildMachineConfig) => ({
  id: NubankPaymentMachineID,
  initial: NubankPaymentState.track,
  states: {
    [NubankPaymentState.track]: {
      invoke: {
        src: NubankPaymentServiceTypes.trackViewPayment,
        onDone: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.preAuthCheck}`,
        onError: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.preAuthCheck}`,
      },
    },
    [NubankPaymentState.preAuthCheck]: {
      initial: NubankPaymentChildState.fetch,
      states: {
        [NubankPaymentChildState.fetch]: {
          invoke: {
            src: NubankPaymentServiceTypes.checkAuthStatusService,
            onDone: {
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.preAuthCheck}.${NubankPaymentChildState.route}`,
              actions: NubankPaymentActionTypes.setPreAuthCheck,
            },
            onError: {
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.error}`,
              actions: NubankPaymentActionTypes.setPreAuthError,
            },
          },
        },
        [NubankPaymentChildState.route]: {
          always: [
            {
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.selectCreditOrDebit}`,
              cond: NubankPaymentGuardTypes.hasUserGivenPermission,
            },
            {
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}`,
            },
          ],
        },
      },
    },
    [NubankPaymentState.requestAuth]: {
      // Component will handle hand off to nubank and return to portal
      // The only way to enter either of the states is through the cart resume steps.
      // If the url params has enrollment=success, the resume step sends the user to the requestAuth.success
      // If the url params has enrollment=failure, the resume step sends the user to the requestAuth.failure
      initial: NubankPaymentChildState.idle,
      states: {
        [NubankPaymentChildState.idle]: {},
        [NubankPaymentChildState.loading]: {},
        [NubankPaymentChildState.success]: {
          invoke: {
            src: NubankPaymentServiceTypes.notifySuccessfulEnrollment,
            onDone: {
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.selectCreditOrDebit}`,
              actions: NubankPaymentActionTypes.setHasAuth,
            },
            onError: {
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.error}`,
              actions: NubankPaymentActionTypes.setRequestAuthError,
            },
          },
        },
        [NubankPaymentChildState.failure]: {
          // TODO: Uncomment when failure flow is ready. Current failure flow acts like a user triggered cancellation of enrollment
          // invoke: {
          //   src: NubankPaymentServiceTypes.notifyFailedEnrollment,
          //   onDone: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.error}`,
          //   onError: {
          //     target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.error}`,
          //     actions: NubankPaymentActionTypes.setRequestAuthError,
          //   },
          // },
          // TODO: Remove on event when above code is uncommented
          on: {
            TRY_AGAIN: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.preAuthCheck}`,
          },
        },
        [NubankPaymentChildState.error]: {
          on: {
            TRY_AGAIN: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.preAuthCheck}`,
          },
        },
      },
      on: {
        GO_TO_LOADING: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.requestAuth}.${NubankPaymentChildState.loading}`,
      },
    },
    [NubankPaymentState.selectCreditOrDebit]: {
      initial: NubankPaymentChildState.fetch,
      states: {
        [NubankPaymentChildState.fetch]: {
          invoke: {
            src: NubankPaymentServiceTypes.fetchPaymentMethodsService,
            onDone: {
              actions: NubankPaymentActionTypes.setPaymentMethods,
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.selectCreditOrDebit}.${NubankPaymentChildState.idle}`,
            },
            onError: {
              actions: NubankPaymentActionTypes.setFetchPaymentMethodsError,
              target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.error}`,
            },
          },
        },
        [NubankPaymentChildState.idle]: {},
      },
      on: {
        SELECT_CREDIT: {
          target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.credit}`,
          actions: NubankPaymentActionTypes.setCreditPaymentMethod,
        },
        SELECT_DEBIT: {
          actions: NubankPaymentActionTypes.setDebitPaymentMethod,
        },
      },
    },
    [NubankPaymentState.credit]: {
      on: {
        SELECT_INSTALLMENT: {
          actions: NubankPaymentActionTypes.setCreditInstallment,
        },
        GO_TO_CREDIT_REVIEW: {
          target: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.review}`,
        },
      },
    },
    [NubankPaymentState.review]: {
      on: {
        GO_TO_PAYMENT_METHOD: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.selectCreditOrDebit}`,
        GO_TO_CREDIT: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.credit}`,
      },
    },
    [NubankPaymentState.invalidFunds]: {},
    [NubankPaymentState.error]: {
      on: {
        TRY_AGAIN: `#${parentMachineId}.${ParentState.payment}.${NubankPaymentState.preAuthCheck}`,
      },
    },
  },
  on: {
    NEXT: {
      actions: NubankPaymentActionTypes.setFulfillRequestPayments,
      target: nextState,
    },
  },
});
