import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getDestination,
  getOrigin,
  getTripCategory,
  getDepartureDateBucketsLoading,
  getMobileSearchProgress,
  getDepartureDate,
  getReturnDate,
  getRenderSearchInput,
  getCalendarVisited,
  hasSelectedDates,
  viewCalendarEventProperties,
} from "../../reducer";
import {
  fetchDepartureCalendar,
  populateSearchUrlParams,
  setCalendar,
  setCalendarVisited,
  setDepartureDate,
  setMobileSearchProgress,
  setRenderSearchInput,
  setReturnDate,
} from "../../actions/actions";
import { MobileFlightSearchControl } from "./component";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setCurrentStep: setMobileSearchProgress,
  setRenderSearchInput,
  populateSearchUrlParams,
  setDepartureDate,
  setReturnDate,
  setCalendarVisited,
};
const mapStateToProps = (state: IStoreState) => ({
  originCode: getOrigin(state)?.id.code.code,
  destinationCode: getDestination(state)?.id.code.code,
  origin: getOrigin(state),
  destination: getDestination(state),
  tripCategory: getTripCategory(state),
  departureDateBucketsLoading: getDepartureDateBucketsLoading(state),
  currentStep: getMobileSearchProgress(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  renderSearchInput: getRenderSearchInput(state),
  calendarVisited: getCalendarVisited(state),
  hasSelectedDates: hasSelectedDates(state),
  trackingProperties: viewCalendarEventProperties(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFlightSearchControl = withRouter(
  connector(MobileFlightSearchControl)
);
