import {
  FlightSelectors,
  useCheckoutStateSelector as useSelector,
} from "@hopper-b2b/checkout";
import "./styles.scss";
import { SharedFlightCtaSection } from "../../SharedFlightCtaSection";
import { getFlightTotalInPrices } from "../../../modules/flight-checkout/selectors";

export interface ICheckoutCtaSectionProps {
  onClick: () => void;
  buttonDisabled?: boolean;
  showCombinedTotal?: boolean;
}

const FlightCheckoutCtaSection = ({
  onClick,
  buttonDisabled = false,
  showCombinedTotal = false,
}: ICheckoutCtaSectionProps) => {
  const fareDetails = useSelector(FlightSelectors.getSelectedFareDetails);
  const tripCategory = useSelector(FlightSelectors.getTripCategoryParent);
  const flightTotalInPrices = useSelector(getFlightTotalInPrices);

  const totalPrice = showCombinedTotal
    ? flightTotalInPrices.fiat
    : fareDetails?.paxPricings?.[0]?.pricing?.total?.fiat || {
        value: 0,
        currencyCode: "BRL",
        currencySymbol: "R$",
      };

  const discountedPrice =
    fareDetails?.paxPricings?.[0]?.pricing?.totalWithDiscount?.fiat;

  return (
    <SharedFlightCtaSection
      discountedPrice={discountedPrice}
      totalPrice={totalPrice}
      tripCategory={tripCategory}
      onClick={onClick}
      buttonDisabled={buttonDisabled}
      showCombinedTotal={showCombinedTotal}
    />
  );
};

export default FlightCheckoutCtaSection;
