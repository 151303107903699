/* eslint-disable prefer-const */
import { call, put, putResolve, select } from "redux-saga/effects";

import {
  LocationQueryEnum,
  LocationResponse,
} from "@b2bportal/air-shopping-api";
import {
  FetchIataCodeLookupResponse,
  fetchChfarFlights,
  fetchIataLocation,
  fetchLocationAutocomplete,
  trackEvent,
} from "@hopper-b2b/api";
import {
  AIR_ENTRY,
  AirEntryProperties,
  FlightShopType,
  IIdFlight,
  LocationQuery,
  OfferWithSuggested,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { fetchTripSummaries as fetchFlights } from "../../../api/v3/shop/fetchTripSummaries";
import Logger from "../../../helpers/Logger";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { PATH_HOME } from "../../../utils/urlPaths";
import { actions as searchActions } from "../../search/actions";
import { setMobileSearchProgress } from "../../search/actions/actions";
import { MobileFlightSearchStep } from "../../search/reducer";
import { actions } from "../actions";
import { IShopParams } from "../actions/actions";
import {
  getAirEntryProperties,
  getPriceFreezeOfferWithSuggested,
  maxFlightPriceSelectorV2,
} from "../reducer/selectors";
import { IFlightShopParsedQuery } from "../utils/parseQueryString";
import {
  getExistingStateVariables,
  populateFlightShopQueryParametersFromState,
} from "./populateShopQueryParamsSaga";

const shouldRedirect = ({
  origin,
  destination,
  departureDate,
  tripCategory,
  returnDate,
  stopsOption,
}: any) =>
  !origin ||
  !destination ||
  !departureDate ||
  !stopsOption ||
  (tripCategory === TripCategory.ROUND_TRIP && !returnDate);

export function* fetchTripSummariesV3(
  action: actions.IFetchTripSummariesV3
): Generator<any, any, any> {
  try {
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    } = yield call(setUpFlightShopParams, action);
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
    };

    const chfarId = state.flightShop.chfarId;
    const isChfarFlightShop =
      state.flightShop.flightShopType === FlightShopType.CHFAR_EXERCISE &&
      chfarId;
    const response = isChfarFlightShop
      ? yield fetchChfarFlights(
          requestBody,
          chfarId,
          action.isMobile,
          apiConfig
        )
      : yield fetchFlights(requestBody, action.isMobile, apiConfig);

    const properties: AirEntryProperties | null = yield select(
      getAirEntryProperties
    );
    yield trackEvent(
      {
        eventName: AIR_ENTRY,
        properties,
      },
      apiConfig
    );

    if (response.Response === "Failure") {
      yield put(actions.setTripSummariesError());
      yield put(actions.setPredictionError());
    } else {
      // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
      // related selectors prior to that of the prediction
      yield putResolve(actions.setFlights(response.value.flights));
      yield putResolve(
        actions.setPriceFreezeOffer(response.value.priceFreezeOffer)
      );
      yield putResolve(actions.setPrediction(response.value.prediction));

      const priceFreeze: OfferWithSuggested | null = yield select(
        getPriceFreezeOfferWithSuggested
      );
      if (priceFreeze) {
        yield put(actions.fetchTripDetails(priceFreeze.cheapestTrip.tripId));
      }
      const maxPriceFlight: number = yield select(maxFlightPriceSelectorV2);
      yield put(searchActions.setMaxPriceFilter(maxPriceFlight));

      yield put(searchActions.setAwaitingRefetch(false));
    }
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}

function* setUpFlightShopParams({
  history,
  isIataEnabled,
}: actions.IFetchTripSummariesV3) {
  const state: IStoreState = yield select();

  let {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  } = getExistingStateVariables(state);

  const parsedQueryString: IFlightShopParsedQuery =
    yield populateFlightShopQueryParametersFromState({
      state,
      history,
      isV2: true,
    });

  if (
    !origin ||
    !destination ||
    !departureDate ||
    (tripCategory === TripCategory.ROUND_TRIP && !returnDate) ||
    // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
    parsedQueryString.isFromFlightWatch
  ) {
    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(parsedQueryString, isIataEnabled);
    [
      origin,
      destination,
      departureDate,
      returnDate,
      tripCategory,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    ] = [
      correspondingOrigin,
      correspondingDestination,
      parsedQueryString.departureDate,
      parsedQueryString.returnDate,
      parsedQueryString.tripCategory,
      parsedQueryString.adultsCount,
      parsedQueryString.childrenCount,
      parsedQueryString.infantsInSeatCount,
      parsedQueryString.infantsOnLapCount,
      parsedQueryString.stopsOption as SliceStopCountFilter,
      parsedQueryString.noLCC,
    ];

    // If we are missing the data we need from both the state and the query params
    // we should redirect the user home.
    if (
      shouldRedirect({
        departureDate,
        origin,
        destination,
        returnDate,
        tripCategory,
        stopsOption,
      })
    ) {
      yield putResolve(
        setMobileSearchProgress(MobileFlightSearchStep.LocationSearch)
      );
      history.push(PATH_HOME);
      return;
    }
    // Order here matters because of the reducer structure.
    // Set trip category resets the returnDate field.
    yield putResolve(searchActions.setTripCategory(tripCategory));
    yield putResolve(searchActions.setOrigin(origin));
    yield putResolve(searchActions.setDestination(destination));
    yield putResolve(searchActions.setDepartureDate(departureDate));
    yield putResolve(searchActions.setReturnDate(returnDate));
    yield putResolve(searchActions.setStopsOption(stopsOption));
    yield putResolve(
      searchActions.setPassengerCounts({
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
      })
    );
    yield putResolve(
      searchActions.setFareclassOptionFilter({
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      })
    );
  }

  return {
    departureDate,
    returnDate,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  };
}

function* fetchOriginDestination(
  parsedQueryString: IFlightShopParsedQuery,
  isIataEnabled?: boolean
) {
  const correspondingOrigin = yield fetchLocation(
    parsedQueryString.origin,
    Boolean(isIataEnabled)
  );
  const correspondingDestination = yield fetchLocation(
    parsedQueryString.destination,
    Boolean(isIataEnabled)
  );
  return { correspondingDestination, correspondingOrigin };
}

function* fetchLocation(locationQuery: string, isIataCodeLookup: boolean) {
  if (isIataCodeLookup) {
    const locationResult: FetchIataCodeLookupResponse = yield fetchIataLocation(
      {
        iataCode: locationQuery,
      }
    );
    return locationResult.airport || locationResult.city;
  } else {
    const requestBody: LocationQuery = {
      l: locationQuery,
      LocationQuery: LocationQueryEnum.Label,
    };
    const autocompleteResults: LocationResponse =
      yield fetchLocationAutocomplete(requestBody);
    const locationMatch =
      autocompleteResults.categories[0].results.find(
        (o) => (o.id as IIdFlight).code.code === locationQuery
      ) ||
      autocompleteResults.categories[1].results.find(
        (o) => (o.id as IIdFlight).code.code === locationQuery
      );
    return locationMatch;
  }
}
