import { TripCategory } from "@hopper-b2b/types";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { actions as shopActions } from "../../../search/actions";
import {
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getTripCategory,
  IFlightNumberFilter,
  initialFilterOptions,
} from "../../../search/reducer";
import {
  fetchTripDetails,
  fetchTripSummariesV3,
  ISetChosenOutgoingSliceParams,
  ISetChosenReturnSliceParams,
  setChosenOutgoingSlice,
  setChosenReturnSlice,
  setOpenCalendarModal,
} from "../../actions/actions";
import { FlightShopStep } from "../../reducer";
import {
  flightInfoSelector,
  flightShopProgressSelector,
  getSelectedOutgoingSlicePropertiesV2,
  getSelectedReturnSlicePropertiesV2,
  getViewedForecastPropertiesForV2,
  hasSetNonFareclassFiltersSelector,
  maxFlightPriceSelector,
  orderedAndFilteredFlightIdsSelector,
  selectedTripSelector,
  tripDetailsLoadingSelector,
  tripIdsByReturnSliceSelector,
  tripSummariesErrorSelector,
  tripSummariesLoadingSelector,
} from "../../reducer/selectors";
import { FlightListContainer } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const flightInfoById = (tripId: string) => flightInfoSelector(state, tripId);

  return {
    flightShopProgress: flightShopProgressSelector(state),
    selectedTrip: selectedTripSelector(state),
    tripSummariesLoading: tripSummariesLoadingSelector(state),
    tripDetailsLoading: tripDetailsLoadingSelector(state),
    hasTripSummariesError: tripSummariesErrorSelector(state),
    sortedFlightIds: orderedAndFilteredFlightIdsSelector(state),
    tripIdsByReturnSlice: tripIdsByReturnSliceSelector(state),
    isReturn: flightShopProgressSelector(state) === FlightShopStep.ChooseReturn,
    isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
    hasAppliedFareClassFilter: getHasSetFareClassFilter(state),
    hasAppliedNonFareclassFilter: hasSetNonFareclassFiltersSelector(state),
    flightInfoById,
    viewedForecastProperties: getViewedForecastPropertiesForV2(state),
    selectedOutgoingSliceProperties:
      getSelectedOutgoingSlicePropertiesV2(state),
    selectedReturnSliceProperties: getSelectedReturnSlicePropertiesV2(state),
    maxFlightPrice: maxFlightPriceSelector(state),
    fareClassOptionFilter: getFareclassOptionFilter(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTripSummariesV3: (history: History, isMobile: boolean) =>
      dispatch(fetchTripSummariesV3(history, isMobile)),
    setChosenOutgoingSlice: (
      chosenOutGoingSliceParams: ISetChosenOutgoingSliceParams
    ) => dispatch(setChosenOutgoingSlice(chosenOutGoingSliceParams)),
    setChosenReturnSlice: (
      chosenReturnSliceParams: ISetChosenReturnSliceParams
    ) => dispatch(setChosenReturnSlice(chosenReturnSliceParams)),
    fetchTripDetails: (tripId: string) => dispatch(fetchTripDetails(tripId)),
    setFlightNumberFilter: (flightNumberFilter: IFlightNumberFilter[]) =>
      dispatch(shopActions.setFlightNumberFilter(flightNumberFilter)),
    setAirportFilter: (airportFilter: string[]) =>
      dispatch(shopActions.setAirportFilter(airportFilter)),
    setOpenCalendarModal: (open: boolean) =>
      dispatch(setOpenCalendarModal(open)),
    resetAll: (maxPrice: number) => {
      dispatch(
        shopActions.setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
      // TODO: add apiConfig here
      dispatch(shopActions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(
        shopActions.setAirlineFilter(initialFilterOptions.airlineFilter)
      );
      dispatch(shopActions.setMaxPriceFilter(maxPrice));
      dispatch(
        shopActions.setAirportFilter(initialFilterOptions.airportFilter)
      );
      dispatch(
        shopActions.setFlightNumberFilter(
          initialFilterOptions.flightNumberFilter
        )
      );
      dispatch(
        shopActions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        shopActions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        shopActions.setReturnDepartureTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        shopActions.setReturnArrivalTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FlightListContainer));
