import { useEffect } from "react";
import { RewardsAccountSelection as AccountSelection } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";

import { RewardsAccountSelectionConnectorProps } from "./container";

export interface IRewardsAccountSelectionProps
  extends RewardsAccountSelectionConnectorProps {
  className?: string;
  popoverClassName?: string;
}

export const RewardsAccountSelection = (
  props: IRewardsAccountSelectionProps
) => {
  const {
    className,
    rewardsAccounts,
    selectedAccountReferenceId,
    // fetchRewardsAccounts,
    setSelectedAccountReferenceId,
    popoverClassName,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  // useEffect(() => {
  //   fetchRewardsAccounts();
  // }, []);

  return (
    <AccountSelection
      className={className}
      selectedAccountReferenceId={selectedAccountReferenceId}
      setSelectedAccountReferenceId={setSelectedAccountReferenceId}
      rewardsAccounts={rewardsAccounts}
      isMobile={matchesMobile}
      popoverClassName={popoverClassName}
    />
  );
};
