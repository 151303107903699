import {
  PaymentLineItemAncillaryCredit,
  PaymentLineItemAncillaryCreditEnum,
  PaymentLineItemEnum,
  PaymentLineItemTravelWallet,
  PaymentLineItemTravelWalletEnum,
} from "@b2bportal/air-booking-api";
import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { ISummaryLineItem, PaymentTypeEnum } from "@hopper-b2b/types";
import { FlightPricingLineItem } from "@hopper-b2b/ui";
import { createSelector } from "@reduxjs/toolkit";

import { IStoreState } from "../../../reducers/types";
import {
  flightsSelector,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../shop/reducer";
import {
  IPaymentInfo,
  ON_LAP_KEY,
} from "../components/PriceBreakdownStep/component";
import {
  getDepartureSliceFromTravelItinerary,
  getOutboundSelection,
  getReturnSelection,
  getReturnSliceFromTravelItinerary,
} from "../reducer/selectors";
import { getPricingInfo } from "../utils";
import {
  getAncillariesPayment,
  getChangeFee,
  getIsFtc,
  getItinerary,
  getItineraryPaxPricing,
  getOriginalExchangeFee,
  getPassengers,
  getPayments,
  getSeatPayments,
  getSubTotalPayment,
  getTravelCredit,
  getWaiveRebookingFee,
} from "./itinerary";

export const getOutboundSlice = (state: IStoreState) => {
  const itinerary = getItinerary(state);
  const outboundSelection = getOutboundSelection(state);
  const shoppedTrip = selectedTripSelector(state);
  const flights = flightsSelector(state);
  const { outgoingSliceId } = shoppedTrip;

  if (outboundSelection === ExchangeActionEnum.Keep && itinerary) {
    return getDepartureSliceFromTravelItinerary(
      itinerary.bookedItinerary.travelItinerary
    );
  } else if (outgoingSliceId) {
    return flights.slices[outgoingSliceId];
  }

  return null;
};

export const getReturnSlice = (state: IStoreState) => {
  const itinerary = getItinerary(state);
  const returnSelection = getReturnSelection(state);
  const shoppedTrip = selectedTripSelector(state);
  const flights = flightsSelector(state);
  const { returnSliceId } = shoppedTrip;

  if (returnSelection === ExchangeActionEnum.Keep && itinerary) {
    return getReturnSliceFromTravelItinerary(
      itinerary.bookedItinerary.travelItinerary
    );
  } else if (returnSliceId) {
    return flights.slices[returnSliceId];
  }

  return null;
};

export const getShoppedFareId = createSelector(
  selectedTripSelector,
  (shoppedTrip) => shoppedTrip.returnFareId ?? shoppedTrip.outgoingFareId
);

export const getShoppedFareDetails = (state: IStoreState) => {
  const shoppedFare = getShoppedFareId(state);
  const shoppedTrip = selectedTripSelector(state);
  const tripDetails = tripDetailsSelector(state, shoppedTrip.tripId);

  if (tripDetails) {
    return tripDetails.fareDetails.find((fd) => fd.id === shoppedFare);
  }

  return null;
};

/**
 * @description The previous flight cost broken down into line items
 */
export const getPrevPayments = createSelector(
  getAncillariesPayment,
  getPayments,
  getSeatPayments,
  getSubTotalPayment,
  (ancillaryPayments, payments, seatPayments, subtotalPayment) => {
    const paymentInfo: IPaymentInfo = {
      cardInfo: "", // intentionally left blank (not displayed in mobile views)
      pfCredit: 0,
      prevPayments: [],
    };
    let prevAirfareAmt = 0;
    let prevAirfareCurrency = "";

    if (subtotalPayment) {
      const { currencyCode, value } = subtotalPayment.fiat;

      prevAirfareAmt = value;
      prevAirfareCurrency = currencyCode;
    }

    if (seatPayments?.length) {
      let seatCurrency = "";
      const seatAmt = seatPayments.reduce((acc, seat) => {
        seatCurrency = seat.currency;

        return acc + seat.price;
      }, 0);

      if (seatAmt > 0 && seatCurrency) {
        paymentInfo.prevPayments.push({
          amount: seatAmt,
          currency: seatCurrency,
          label: "exchangeable.priceBreakdown.seats",
        });
      }
    }

    if (ancillaryPayments?.length) {
      ancillaryPayments.forEach((ancil) => {
        const {
          kind,
          premium: {
            fiat: { currencyCode, value },
          },
        } = ancil;
        const label = `exchangeable.ancillaries.${kind}`;

        if (value > 0 && currencyCode && label) {
          paymentInfo.prevPayments.push({
            label,
            amount: value,
            currency: currencyCode,
          });
        }
      });
    }

    if (payments?.length) {
      payments.forEach((pmnt) => {
        const { PaymentLineItem: type } = pmnt;
        let amount = 0,
          currency = "",
          label = "";

        switch (type) {
          case PaymentLineItemEnum.AncillaryCredit: {
            ({
              AncillaryCredit: label,
              amount: { amount, currency },
            } = pmnt as PaymentLineItemAncillaryCredit);

            if (
              label === PaymentLineItemAncillaryCreditEnum.PriceFreezeCredit
            ) {
              paymentInfo.pfCredit = amount;
              /*
               * subtotal includes price freeze credit already so it needs to be
               * added back in to get the total airfare value
               */
              prevAirfareAmt += amount;
              amount *= -1; // price freeze credit is a deduction in prev payment
              label = "exchangeable.ancillaries.priceFreezeCredit";
            }
            break;
          }
          // case PaymentLineItemEnum.Rewards: {
          //   ({ accountDisplayName } = pmnt as PaymentLineItemRewards);
          //   break;
          // }
          case PaymentLineItemEnum.TravelWallet: {
            ({
              TravelWallet: label,
              amount: { amount, currency },
            } = pmnt as PaymentLineItemTravelWallet);

            if (label === PaymentLineItemTravelWalletEnum.TravelWalletCredit) {
              label = "exchangeable.ancillaries.walletCredit";
            } else if (
              label === PaymentLineItemTravelWalletEnum.TravelWalletOffer
            ) {
              label = "exchangeable.ancillaries.walletOffer";
            }

            amount *= -1; // wallet credits/offers are deductions
            break;
          }
          // case PaymentLineItemEnum.UserCard: {
          //   const { cardNumberDisplay = "" } = pmnt as PaymentLineItemUserCard;

          //   cardNumberLastFour = last(cardNumberDisplay.split("-")) ?? "";
          //   break;
          // }
          default:
        }

        if (amount !== 0 && currency && label) {
          paymentInfo.prevPayments.push({ amount, currency, label });
        }
      });
    }

    if (prevAirfareAmt > 0 && prevAirfareCurrency) {
      paymentInfo.prevPayments.unshift({
        amount: prevAirfareAmt,
        currency: prevAirfareCurrency,
        label: "exchangeable.priceBreakdown.prevFlightCredit",
      });
    }

    return paymentInfo;
  }
);

/**
 * @description The new flight cost per passenger
 */
export const getNewPayments = createSelector(
  getChangeFee,
  getItineraryPaxPricing,
  getOriginalExchangeFee,
  getPassengers,
  getPrevPayments,
  getShoppedFareDetails,
  getWaiveRebookingFee,
  (
    exchangeFee,
    itinPaxPricing,
    ogExchangeFee,
    passengers,
    prevPaymentInfo,
    shoppedFareDetails,
    waiveRebookingFee
  ) => {
    const items: FlightPricingLineItem[] = [];

    if (passengers) {
      const { alone, withLapInfants } = passengers;
      let pfCreditAmt = 0;

      if (prevPaymentInfo.pfCredit) {
        const numTravelers = alone.length + withLapInfants.length;

        pfCreditAmt = prevPaymentInfo.pfCredit / numTravelers;
      }

      for (let i = 0; i < alone.length; i += 1) {
        const {
          person: { givenName, surname },
          type,
        } = alone[i];
        const pricingInfo = getPricingInfo(
          itinPaxPricing,
          type,
          shoppedFareDetails
        );
        const changeFee = waiveRebookingFee ? ogExchangeFee : exchangeFee;
        const changeFeeAmt = waiveRebookingFee ? undefined : changeFee.amount;

        items.push({
          baseAmount: pricingInfo.base + pfCreditAmt,
          changeFee: changeFeeAmt,
          lineTitle: `${givenName} ${surname}`,
          taxesAndFees: pricingInfo.tax,
        });
      }

      for (let i = 0; i < withLapInfants.length; i += 1) {
        const {
          adult: {
            person: { givenName: adtFirstName, surname: adtSurname },
            type,
          },
          infant: {
            person: { givenName: infFirstName, surname: infSurname },
          },
        } = withLapInfants[i];
        const adultName = `${adtFirstName} ${adtSurname}`;
        const infantName = `${infFirstName} ${infSurname}`;
        const pricingInfo = getPricingInfo(
          itinPaxPricing,
          type,
          shoppedFareDetails
        );
        const changeFee = waiveRebookingFee ? ogExchangeFee : exchangeFee;
        const changeFeeAmt = waiveRebookingFee ? undefined : changeFee.amount;

        items.push(
          {
            baseAmount: pricingInfo.base + pfCreditAmt,
            changeFee: changeFeeAmt,
            lineTitle: adultName,
            taxesAndFees: pricingInfo.tax,
          },
          {
            baseAmount: 0,
            changeFee: changeFeeAmt,
            lineTitle: `${infantName} ${ON_LAP_KEY}`, // marker for component to replace with actual copy
            taxesAndFees: 0,
          }
        );
      }
    }

    return items;
  }
);

/**
 * @description The final cost of the new flight minus the prev flight cost
 */
export const getSummaryItems = createSelector(
  getIsFtc,
  getNewPayments,
  getPrevPayments,
  getTravelCredit,
  (isFTC, newPayments, prevPaymentInfo, travelCredit) => {
    const { prevPayments } = prevPaymentInfo;
    const summaryItems: ISummaryLineItem[] = [];
    const shoppedSubtotal = newPayments.reduce((sum, payment) => {
      const { baseAmount = 0, changeFee = 0, taxesAndFees = 0 } = payment;

      return sum + baseAmount + changeFee + taxesAndFees;
    }, 0);

    summaryItems.push({
      fiatPrice: {
        currencyCode: "",
        currencySymbol: "",
        value: shoppedSubtotal,
      },
      label: `exchangeable.priceBreakdown.${isFTC ? "total" : "subtotal"}`,
      type: "custom",
    });

    if (isFTC && travelCredit) {
      const { amount, currency } = travelCredit.credit;

      summaryItems.push({
        fiatPrice: {
          currencyCode: currency,
          currencySymbol: "",
          value: -1 * amount,
        },
        label: "exchangeable.priceBreakdown.ftcApplied",
        markdownType: PaymentTypeEnum.FiatAmountInfo,
        type: "markdown",
      });
    } else if (!isFTC && prevPayments.length) {
      const prevFlightLabel = "exchangeable.priceBreakdown.prevFlightCredit";
      const airfareValue = prevPayments.find(
        (p) => p.label === prevFlightLabel
      );

      if (airfareValue) {
        const { amount, currency } = airfareValue;

        summaryItems.push({
          fiatPrice: {
            currencyCode: currency,
            currencySymbol: "",
            value: -1 * amount,
          },
          label: prevFlightLabel,
          type: "custom",
        });
      }
    }

    // BOPS-501: Temp fix until FTC CFAR change fees are sent by TRVK
    // if (false && policyHasCfar) {
    //   const { currency } = ogExchangeFee;
    //   const { current: totalChangeFee } = totalChangeFeeRef;

    //   items.push({
    //     fiatPrice: {
    //       currencyCode: currency,
    //       currencySymbol: CurrencyFormatters.getSymbol(currency),
    //       value: -1 * totalChangeFee,
    //     },
    //     icon: IconName.CheckShieldBlue,
    //     label: t('exchangeable.priceBreakdown.cfar'),
    //     rewardsPrice: newRewardsPrice(-1 * totalChangeFee),
    //     type: "custom",
    //   });

    //   total -= totalChangeFee;
    // }

    return summaryItems;
  }
);
