import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  BookingType,
  IvrEncodeReq,
  IvrEncodeRes,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

import { bookingSupportIdPath } from "../paths";

async function getBookingSupportId(
  bookingId: string,
  product: BookingType,
  vipSupport?: boolean
) {
  const req: IvrEncodeReq = {
    bookingId,
    product,
    vipSupport,
  };

  try {
    const res = await axiosInstance.post<IvrEncodeRes>(
      bookingSupportIdPath,
      req
    );

    if ("encodedCode" in res.data) {
      return res.data.encodedCode;
    }

    throw res.data.error;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
}

export default getBookingSupportId;
