import { ReactNode, useEffect } from "react";
import { ActionLink } from "@hopper-b2b/ui";
import "./styles.scss";
import { useNavigate } from "react-router-dom-v5-compat";
import { PATH_HOME } from "../../../utils/urlPaths";

export const FlightSearchMobileHeaderLeftContent = ({
  content,
  onClick,
  searchStateStep,
}: {
  className: string;
  onClick: () => void;
  content: ReactNode;
  searchStateStep?: number;
}) => {
  // Slot is placed in: libs/flights/src/modules/search/components/MobileFlightSearchControl/component.tsx
  // searchStateStep -> MobileFlightSearchStep enum value
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (searchStateStep === 0) {
      navigate(PATH_HOME);
    } else {
      onClick();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  return (
    <ActionLink
      className="mobile-flight-search-header-go-back"
      content={content}
      onClick={handleGoBack}
    />
  );
};
