import { Box, Chip, Typography } from "@material-ui/core";

import styles from "./ProvidedBy.module.scss";
import logo from "../../../assets/hopper-logo.svg";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { capitalize } from "lodash-es";

export const ProvidedBy = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  return matchesMobile ? (
    <Chip
      className={styles.providedBy}
      label={
        <>
          <Typography color="textSecondary" className={styles.text}>
            {capitalize(t("providedBy"))}
          </Typography>
          <img src={logo} alt="Hopper-logo" />
        </>
      }
    />
  ) : (
    <Box className={styles.providedByDesktop}>
      <Typography color="textSecondary" className={styles.text}>
        {t("providedBy")}
      </Typography>
      <img src={logo} alt="Hopper-logo" />
    </Box>
  );
};
