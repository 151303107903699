import { useI18nContext } from "@hopper-b2b/i18n";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { Button, Typography } from "@material-ui/core";
import { PropsWithChildren, useMemo, useState } from "react";
import "./styles.scss";
import {
  PredictionCopy,
  PricePrediction,
  Prices,
  Dealness,
} from "@b2bportal/air-shopping-api";
import clsx from "clsx";
import {
  ButtonWrap,
  Icon,
  IconComponent,
  IconName,
  PredictionForecast,
  PriceForecast,
  PricePredictionCard,
} from "@hopper-b2b/ui";
import { TripCategory } from "@hopper-b2b/types";

export interface PredictionDetails {
  predictionCopy?: PredictionCopy;
  dealness?: Dealness;
  lowestPrice?: Prices;
  pricePrediction?: PricePrediction;
}

interface DesktopPricePredictionProps extends PropsWithChildren {
  predictionDetails: PredictionDetails;
  tripCategory: TripCategory;
  className?: string;
}

export const DesktopPricePrediction = ({
  predictionDetails,
  className,
  tripCategory,
}: DesktopPricePredictionProps) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const PRICE_FORECAST_TITLES = [
    { title: t("priceForecast.greatTitle"), dealness: Dealness.great },
    { title: t("priceForecast.goodTitle"), dealness: Dealness.good },
    { title: t("priceForecast.fairTitle"), dealness: Dealness.fair },
    { title: t("priceForecast.waitTitle"), dealness: Dealness.wait },
  ];

  const [showForecast, setShowForecast] = useState(false);

  const isCommbank = getEnvVariables("clientName") === "commbank-au";

  const getForecastedPrice = () => {
    if (predictionDetails.dealness) {
      const key =
        predictionDetails.dealness === Dealness.wait ? "minPrice" : "maxPrice";
      const displayText = predictionDetails.pricePrediction[key]?.display || "";
      if (displayText) {
        return (
          <Typography className="content" variant="h4">
            {displayText}
          </Typography>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const recommendationBody = useMemo(() => {
    return isCommbank
      ? predictionDetails?.predictionCopy?.recommendationBody
          .join(" ")
          .replace("Hopper", "Commbank Travel")
      : predictionDetails?.predictionCopy?.recommendationBody.join(" ");
  }, [isCommbank, predictionDetails?.predictionCopy?.recommendationBody]);

  return (
    <>
      <div className={clsx("desktop-price-prediction-container", className)}>
        <div className="desktop-price-prediction">
          <div className="prediction-container">
            {predictionDetails?.lowestPrice && (
              <div className="lowest-price-container">
                <p className="lowest-price-label">
                  {t("pricePrediction.currentPrice")}
                </p>
                <div className="lowest-price-description">
                  <p className="lowest-price">
                    <span className="lowest-price-number">
                      {formatFiatCurrency(predictionDetails?.lowestPrice.fiat, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>{" "}
                    {tripCategory === TripCategory.ROUND_TRIP
                      ? t("roundTripPerTraveler").toLowerCase()
                      : t("oneWayPerTraveler").toLowerCase()}
                  </p>
                </div>
              </div>
            )}
            {predictionDetails?.predictionCopy ? (
              <div className="recommendation-container">
                {/* <h6
                  className="recommendation-title"
                  dangerouslySetInnerHTML={{
                    __html: recommendationTitle,
                  }}
                /> */}
                <p
                  className="recommendation-body"
                  dangerouslySetInnerHTML={{
                    __html: recommendationBody,
                  }}
                />
              </div>
            ) : null}
            <PriceForecast
              items={PRICE_FORECAST_TITLES}
              dealness={predictionDetails.dealness}
            />
            <ButtonWrap
              className="view-price-forecast-button"
              tabIndex={0}
              aria-label={t("pricePrediction.ariaLabel")}
              aria-expanded={showForecast}
              onClick={() => setShowForecast(!showForecast)}
            >
              <Typography className="view-price-forecast-copy" variant="button">
                {t("pricePrediction.ariaLabel")}
                <Icon
                  name={IconName.Dropdown}
                  className={
                    showForecast ? "transformed-dropdown-icon" : "dropdown-icon"
                  }
                />
              </Typography>
            </ButtonWrap>
          </div>
          <div className="watch-container">
            <div className="watch-copy">
              <p className="price-watch-title">
                {t("flightSearchLoading.step3Title")}
              </p>
              <p className="price-watch-label">{t("priceWatch.title")}</p>
              <p className="price-watch-copy">{t("priceWatch.copy")}</p>
            </div>
            <div className="price-watch-button-container">
              <Button className="price-watch-button">
                <IconComponent
                  className="price-watch-icon"
                  name={IconName.B2BWatch}
                />
                {t("priceWatch.watchThisTrip")}
              </Button>
            </div>
          </div>
        </div>
        {showForecast ? (
          <div className="desktop-prediction-forecast-container">
            {predictionDetails && !!getForecastedPrice() && (
              <PricePredictionCard
                className="desktop-forecasted-price"
                title={
                  predictionDetails.dealness === Dealness.wait
                    ? t("pricePrediction.forecastedLowest")
                    : t("pricePrediction.forecastedHighest")
                }
                icon={
                  <IconComponent
                    className={
                      predictionDetails.dealness === Dealness.wait
                        ? "forecasted-lowest-icon"
                        : "forecasted-highest-icon"
                    }
                    name={
                      predictionDetails.dealness === Dealness.wait
                        ? IconName.DecreaseReviewed
                        : IconName.IncreasedReviewed
                    }
                  />
                }
                content={getForecastedPrice()}
                subtitle={
                  tripCategory === TripCategory.ROUND_TRIP
                    ? t("roundTripPerTraveler")
                    : t("oneWayPerTraveler")
                }
                isForecastedPrice
              />
            )}
            {predictionDetails?.predictionCopy?.intervals && (
              <PredictionForecast
                className="desktop"
                intervals={predictionDetails.predictionCopy.intervals}
              />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};
