// TODO: change redirect urls
export const INELIGIBLE_ACCOUNT_REDIRECT_URL = "/";
export const BAD_STATUS_REDIRECT_URL = "/";
export const INVALID_SESSION_REDIRECT = "/";
export const LOGOUT_REDIRECT = "/";
export const START_SESSION_LOADING_MESSAGE = "Logging in";
export const INVALID_SESSION_LOADING_MESSAGE = "Invalid session";
export const LOGOUT_LOADING_MESSAGE = "Logging out";

export const SESSION_TOKEN_PARAM = "code";
export const IMPERSONATE_USER_PARAM = "impersonate";
export const REDIRECT_URI_PARAM = "redirect_uri";
export const INITIATOR_PARAM = "initiator";

export const IMPERSONATE_COOKIE_NAME = "nubank_user_impersonation_active";
