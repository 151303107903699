import {
  AirRestrictionStatus,
  DullesAmenityCanExist,
  DullesUta,
  FareDetails,
} from "@hopper-b2b/types";
import { Box, Typography } from "@material-ui/core";
import { useMemo, useState } from "react";
import Amenity from "./Amenity";
import { IRestrictionProps } from "./Restriction";
import { TFunction } from "i18next";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { IFareNotice } from "../component";
import { ReactComponent as ChevronDown } from "../../../../assets/client/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../../../assets/client/chevron-up.svg";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface ISelectedFareRestrictionsProps {
  selectedFare: FareDetails;
  allFareDetails: FareDetails[];
  fareNotice?: IFareNotice[];
  index: number;
  isOutgoing: boolean;
  isMobile?: boolean;
  getEmptyRestrictionsText: (
    fareRating: number | undefined,
    translate: TFunction
  ) => string;
}

export const isDescriptionShort = (description: string, isMobile: boolean) => {
  if (isMobile) {
    // note: assuming each character is roughly 6px; it's considered short if it's less than 170px.
    return description.length * 6 < 170;
  } else {
    // note: assuming each character is roughly 6px; it's considered short if it's less than 270px.
    return description.length * 6 < 270;
  }
};

const SelectedFareRestrictions = ({
  selectedFare,
  allFareDetails,
  isOutgoing,
  isMobile,
  getEmptyRestrictionsText,
}: ISelectedFareRestrictionsProps) => {
  const { t } = useI18nContext();
  const [showAllAmenities, setShowAllAmenities] = useState<boolean>(
    allFareDetails.length < 2
  );
  const fareIndex = selectedFare.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );
  const fareSlice = selectedFare.slices[fareIndex];

  const handleSeeMoreOnClick = () => setShowAllAmenities(!showAllAmenities);

  const restrictions: IRestrictionProps[] = [];
  restrictions.push(
    ...(fareSlice.amenitiesUtas?.utas.utas.map((uta: DullesUta) => {
      const restriction: IRestrictionProps = {
        // TODO: the assessment field should probably be an enum
        // Dulles for Uber is giving us incorrect info for several categories (hack)
        symbol:
          uta.category === "boarding-priority" &&
          ["fee", "restriction"].includes(uta.assessment)
            ? AirRestrictionStatus.GENERIC
            : uta.category === "carry-on-allowance" &&
              uta.assessment === "restriction"
            ? AirRestrictionStatus.GENERIC
            : uta.category === "cancellation" &&
              uta.headline === "Non-refundable" // !!! translated strings will not match this copy
            ? AirRestrictionStatus.GENERIC
            : uta.assessment === "benefit"
            ? AirRestrictionStatus.INCLUDED
            : uta.assessment === "fee"
            ? AirRestrictionStatus.PURCHASABLE
            : uta.assessment === "restriction"
            ? AirRestrictionStatus.UNAVAILABLE
            : AirRestrictionStatus.GENERIC,
        // TODO: probably need a different type for category
        description: `${uta.description}`,
      };

      return restriction;
    }) ?? [])
  );

  if (fareSlice.amenitiesUtas?.amenities) {
    restrictions.push(
      ...Object.keys(fareSlice.amenitiesUtas.amenities).map((key) => {
        const amenitity: DullesAmenityCanExist = (
          fareSlice.amenitiesUtas?.amenities as any
        )?.[key];
        const symbol =
          amenitity.exists === "yes"
            ? AirRestrictionStatus.INCLUDED
            : amenitity.exists === "no"
            ? AirRestrictionStatus.UNAVAILABLE
            : AirRestrictionStatus.GENERIC;
        return {
          symbol: symbol,
          description: `${t(`amenitiesCategories.${key}`)} - ${
            amenitity.info.displayText
          }`,
        };
      })
    );
  }

  const amenitiesCountToShow = useMemo(() => {
    // note: if the last item is long, only show N-1 amenities; otherwise, show N amenities; N = restrictionsCount.
    const getCount = (restrictionsCount: number) => {
      if (restrictions.length >= restrictionsCount)
        return isDescriptionShort(
          restrictions[restrictionsCount - 1].description,
          isMobile
        )
          ? restrictionsCount
          : restrictionsCount - 1;
      else return restrictionsCount - 1;
    };

    switch (allFareDetails.length) {
      case 2:
        // note: if the last item is long, only show 6 amenities; otherwise, show 7 amenities.
        return getCount(7);
      case 3:
        // note: if the last item is long, only show 3 amenities; otherwise, show 4 amenities.
        return getCount(4);
      default:
        return 3;
    }
  }, [allFareDetails.length, isMobile, restrictions]);

  if (restrictions) {
    return (
      <Box
        className={clsx(
          "amenities flight-card-mini",
          styles.restrictionAndAmenities
        )}
      >
        {restrictions.length === 0 && (
          <Typography
            variant="body1"
            className="trip-fare-empty-restriction-text"
          >
            {getEmptyRestrictionsText(fareSlice.fareShelf?.rating, t)}
          </Typography>
        )}

        {restrictions?.map((r, i) =>
          showAllAmenities || amenitiesCountToShow > i ? (
            <Amenity key={`Amenity-${i}`} restriction={r} />
          ) : null
        )}

        {restrictions.length > amenitiesCountToShow ? (
          <ButtonWrap
            className="show-more"
            onClick={handleSeeMoreOnClick}
            tabIndex={0}
          >
            <div className="show-container">
              {showAllAmenities ? (
                <>
                  {t("showLess")}
                  <ChevronUp className="chevron-up" />
                </>
              ) : (
                <>
                  {t("showMore")}
                  <ChevronDown className="chevron-down" />
                </>
              )}
            </div>
          </ButtonWrap>
        ) : null}
      </Box>
    );
  }
  return null;
};

export default SelectedFareRestrictions;
